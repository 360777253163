import { useMutation } from '@apollo/client';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import {
  UpdateCurationContentPackBadgesToSp2Mutation,
  UpdateCurationContentPackBadgesToSp2MutationVariables,
} from '../../../../../../__gqltypes__';
import mutations from '../../../utils/mutations';

type Props = {
  sp2CurationPlaylistId: string;
  badges: (string | null)[] | null;
  availableBadges: string[];
  disabled: boolean;
};

export default function SP2BadgeFields({ sp2CurationPlaylistId, badges, availableBadges, disabled }: Props) {
  const [updateBadges, { loading }] = useMutation<
    UpdateCurationContentPackBadgesToSp2Mutation,
    UpdateCurationContentPackBadgesToSp2MutationVariables
  >(mutations.UPDATE_CURATION_CONTENT_PACK_SP2_BADGES);

  const updateField = async (badge0: string | null, badge1: string | null) => {
    if (disabled) {
      return;
    }

    await updateBadges({ variables: { input: { sp2CurationPlaylistId, badges: [badge0, badge1] } } });
  };

  const _badges = badges ?? [];

  return (
    <div>
      Primary Badge:
      <span>
        {loading ? (
          'Updating...'
        ) : (
          <DropdownButton
            disabled={disabled}
            as="span"
            id="dropdown-item-button"
            size="sm"
            title={_badges[0] ?? 'Choose a badge'}
          >
            {availableBadges.map((f) => (
              <Dropdown.Item key={f} as="button" onClick={() => updateField(f, _badges[1])}>
                {f}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        )}
      </span>
      <br />
      Secondary Badge:
      <span>
        {loading ? (
          'Updating...'
        ) : (
          <DropdownButton
            disabled={disabled}
            as="span"
            id="dropdown-item-button"
            className="m-2"
            size="sm"
            title={_badges[1] ?? 'Choose a badge'}
          >
            {availableBadges.map((f) => (
              <Dropdown.Item key={f} as="button" onClick={() => updateField(_badges[0], f)}>
                {f}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        )}
      </span>
    </div>
  );
}
