import { useEffect, useRef } from 'react';
import { FormCheck } from 'react-bootstrap';

export enum Variant {
  AVG = 'AVG',
  P95 = 'P95',
  P99 = 'P99',
  MAX = 'MAX',
  COUNT = 'COUNT',
  PREVIOUS_AVG = 'PREVIOUS_AVG',
  PREVIOUS_COUNT = 'PREVIOUS_COUNT',
}

const variants: Record<Variant, { borderWidth: number; label: string; previous?: boolean }> = {
  [Variant.AVG]: { borderWidth: 5, label: 'Average of execution times' },
  [Variant.COUNT]: { borderWidth: 5, label: 'Number of requests' },
  [Variant.P95]: { borderWidth: 2, label: 'P95: 95% of requests take less than t ms' },
  [Variant.P99]: { borderWidth: 1.5, label: 'P99: 99% of requests take less than t ms' },
  [Variant.MAX]: { borderWidth: 1, label: 'maximum execution time' },
  [Variant.PREVIOUS_AVG]: { borderWidth: 3, label: 'Average of execution times (comparison period)', previous: true },
  [Variant.PREVIOUS_COUNT]: { borderWidth: 3, label: 'Number of requests (comparison period)', previous: true },
};

interface LegendItemProps {
  variant: Variant;
  color: string;
  checkDisabled?: boolean;
  checkValue: boolean;
  checkOnChange?: (checked: boolean) => void;
}

export default function LegendItem({
  variant,
  color,
  checkDisabled = false,
  checkValue,
  checkOnChange,
}: LegendItemProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (canvasRef.current) {
      const context = canvasRef.current.getContext('2d');
      if (!context) {
        return;
      }

      context.clearRect(0, 0, 30, 20);
      context.translate(0.5, 0.5);

      context.lineWidth = variants[variant].borderWidth;
      context.strokeStyle = color;
      if (variants[variant].previous) {
        context.setLineDash([5, 3]);
      }

      context.moveTo(0, 10);
      context.lineTo(30, 10);
      context.stroke();

      context.setLineDash([]);
      context.beginPath();
      context.arc(15, 10, (2 / 3) * variants[variant].borderWidth, 0, 2 * Math.PI);
      context.stroke();
    }
  }, [canvasRef, color, variant]);

  return (
    <div className="d-flex">
      <canvas ref={canvasRef} width={30} height={20} className="mt-auto mb-auto mr-2" />
      <div className="mr-auto">{variants[variant].label}</div>
      <FormCheck
        onChange={(e) => checkOnChange?.(e.currentTarget.checked)}
        checked={checkValue}
        disabled={checkDisabled}
        style={{ accentColor: color }}
      />
    </div>
  );
}
