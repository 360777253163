import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import queries from '../../../utils/queries';
import './ContentModerationLyricsModal.css';

type Props = {
  title?: string | null;
  artist?: string | null;
  show: boolean;
  onHide: () => void;
};

const ContentModerationLyricsModal = ({ title, artist, show, onHide }: Props) => {
  const { data, loading } = useQuery(queries.GET_LYRICS, { variables: { title, artist }, skip: !title && !artist });

  const songId = data?.lyrics?.lyricsData ? data.lyrics.lyricsData.id : null;
  const embedScriptUrl = data?.lyrics?.lyricsData ? data.lyrics.lyricsData.embedScriptUrl : null;

  // since we are loading an external js file (from Genius), we load it in an iframe to bypass security restrictions
  useEffect(()=>{
    const iframe = document.querySelector('iframe');

    if (iframe?.id === 'lyricsContent') {
      iframe?.contentWindow?.document.open();
      iframe?.contentWindow?.document.write(`
        <style>
          .rg_embed {
            width: 100%;
          }
        </style>
        <div id='rg_embed_link_${songId}' className='rg_embed_link' data-song-id=${songId} />
        <script crossorigin src='${embedScriptUrl}'></script>
      `);
      iframe?.contentWindow?.document.close();
    }
  }, [songId, embedScriptUrl]);

  if (!artist && !title) {
    return null;
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          {title} by {artist}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Spinner animation="border" />
        ) : (
          <div id="lyrics">
            {data.lyrics.lyricsData ? (
              <>
                <a href={data.lyrics.lyricsData.url} target="_blank" rel="noreferrer">Source</a>
                <iframe id='lyricsContent' title='Lyrics content' />
              </>
            ) : 'Lyrics not found'}
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ContentModerationLyricsModal;
