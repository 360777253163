import { useLazyQuery } from '@apollo/client';
import _ from 'lodash';
import { ChangeEvent, KeyboardEvent, useMemo, useState } from 'react';
import { AutocompleteSongQuery, AutocompleteSongQueryVariables, HurdleType } from '../../../../../__gqltypes__';
import { AUTOCOMPLETE_SONG } from './graphql';
import { SimpleBarGraph } from './SimpleBarGraph';

function getTrigrams(s: string): [number, string][] {
  return Array.from({ length: s.length - 2 }, (_v, i) => [i, `${s[i]}${s[i + 1]}${s[i + 2]}`]);
}

function getTrigramsAround(sInput: string, j: number) {
  const sBeforeINormalized = normalizeForSearch(sInput.substring(0, j));

  const s = normalizeForSearch(sInput);
  const i = sBeforeINormalized.length;

  const s1 = i >= s.length - 2 ? null : `${s[i]}${s[i + 1]}${s[i + 2]}`;
  const s2 = i <= 0 || i >= s.length - 1 ? null : `${s[i - 1]}${s[i]}${s[i + 1]}`;
  const s3 = i <= 1 ? null : `${s[i - 2]}${s[i - 1]}${s[i]}`;
  return [s1, s2, s3].filter((x) => x);
}

function getCommonTrigramsLetters(search: string, result: string) {
  const trigramsSearch = getTrigrams(search).map(([i, t]) => t);
  return Array.from(
    { length: result.length },
    (_v, i) => _.intersection(getTrigramsAround(result, i), trigramsSearch).length > 0
  );
}

function removeDiacritics(text: string): string {
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

function removeNonAlphaNumeric(text: string, replacer = ''): string {
  return text.replace(/\W/g, replacer);
}

function normalizeForSearch(text: string): string {
  return removeNonAlphaNumeric(removeDiacritics(_.toLower(_.trim(text))));
}

export default function Autocompletion() {
  const start = useMemo(() => window.performance.now(), []);
  const [search, setSearch] = useState<string>('');
  const [calls, setCalls] = useState<{ pressed: number; responseReceived: number; nextPressed: number | null }[]>([]);
  const [autocomplete, { data }] = useLazyQuery<AutocompleteSongQuery, AutocompleteSongQueryVariables>(
    AUTOCOMPLETE_SONG
  );

  const onPress = async (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    setSearch(_.lowerCase(value).replace(/[\W_0-9]+/g, ''));

    const pressed = window.performance.now();
    await autocomplete({ variables: { input: { search: value || '', limit: 5, type: HurdleType.DEFAULTSONG } } });
    const responseReceived = window.performance.now();

    setCalls((pCalls) => {
      if (pCalls.length === 0) {
        return [{ pressed: pressed - start, responseReceived: responseReceived - pressed, nextPressed: null }];
      }
      return [
        ...pCalls.slice(0, pCalls.length - 1),
        { ...pCalls[pCalls.length - 1], nextPressed: pressed - start - pCalls[pCalls.length - 1].pressed },
        { pressed: pressed - start, responseReceived: responseReceived - pressed, nextPressed: null },
      ];
    });
  };

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', marginRight: 30 }}>
        <div style={{ flex: 1 }}>
          <h2>Research</h2>
          <textarea
            onChange={onPress}
            style={{
              width: 'calc(100% - 30px)',
              margin: '10px 10px 10px 0',
              height: 'calc(100% - 70px)',
              borderRadius: '5px',
              backgroundColor: '#ddd',
              border: 'none',
              padding: '10px',
            }}
          />
        </div>
        <div style={{ flex: 1 }}>
          <h2>Results</h2>
          <div className="mt-3 mb-3" style={{ height: '250px' }}>
            {data?.hurdleAutoComplete.items.map((res) => {
              const titleLettersInTrigrams = getCommonTrigramsLetters(search, res.title);
              const artistLettersInTrigrams = getCommonTrigramsLetters(search, res.artist);
              return (
                <div key={res.id}>
                  {res.title
                    .split('')
                    .map((l, i) => (titleLettersInTrigrams[i] ? <b style={{ color: '#37b8cc' }}>{l}</b> : l))}
                  {` ; `}
                  {res.artist
                    .split('')
                    .map((l, i) => (artistLettersInTrigrams[i] ? <b style={{ color: '#37b8cc' }}>{l}</b> : l))}
                </div>
              );
            })}
          </div>
        </div>

        <div style={{ flex: 1 }}>
          <h2>Monitoring</h2>
          <SimpleBarGraph data={calls.map((c) => c.responseReceived)} steps={10} unit="ms" />
        </div>
      </div>
      <div style={{ flex: 1 }}>
        <h2>Monitoring</h2>
        <div
          style={{ height: 'calc(100vh - 200px)', overflow: 'auto', marginLeft: '50%', transform: 'translateX(-50%)' }}
        >
          <table>
            <tr>
              <th style={{ border: 'solid 1px gray' }}>Key pressed</th>
              <th style={{ border: 'solid 1px gray' }}>Response received</th>
              <th style={{ border: 'solid 1px gray' }}>Next key pressed</th>
            </tr>
            {calls.map((call, i) => (
              <tr key={call.pressed}>
                <td style={{ border: 'solid 1px gray' }}>{call.pressed.toFixed(1)}ms</td>
                <td style={{ border: 'solid 1px gray' }}>{call.responseReceived.toFixed(1)}ms</td>
                <td style={{ border: 'solid 1px gray' }}>{call.nextPressed?.toFixed(1)}ms</td>
              </tr>
            ))}
          </table>
        </div>
      </div>
    </div>
  );
}
