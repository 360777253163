import { useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { Badge, Button, Card, Col, Nav, Row, Spinner, Tab } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import moment from 'moment';
import {
  CreateSaleEventInput,
  GetLiveOpsSaleEventConfigsQuery,
  RedisCacheModel,
  CreateSaleEventMutation,
  CreateSaleEventMutationVariables,
  GetAppShopDiamondsSalesQuery,
  GetAppCoinsSalesQuery,
  GetAppBoostsSalesQuery,
  GetAppShopDiamondsSalesQueryVariables,
  SaleEventConfig,
} from '../../../../../__gqltypes__';
import { LoadingLogo } from '../../../devtools/components/Modal';
import FlushButton from '../../../devtools/screens/Cache/FlushButton';
import { AppSalesConfig, TargetedProduct } from './typedef';
import { SaleEventData } from './components/SaleEventData';
import AddSaleEventModal from './components/AddSaleEventModal';
import {
  APP_SHOP_DIAMONDS_SALES,
  APP_COINS_SALES,
  APP_BOOSTS_SALES,
  CREATE_SALE_EVENT,
  LIVEOPS_SALE_EVENT_CONFIGS_QUERY,
} from './graphql';

const baseURL = '/sp3/liveops/sale-events';

const vanityDiscounts = [
  { name: '10%', value: 10 },
  { name: '20%', value: 20 },
  { name: '30%', value: 30 },
  { name: '40%', value: 40 },
  { name: '50%', value: 50 },
  { name: '60%', value: 60 },
  { name: '70%', value: 70 },
  { name: '80%', value: 80 },
];

function SaleEvents() {
  /**
   * GETTING ENV DATA
   */
  const { id: saleEventId } = useParams<{ id: string }>();
  const history = useHistory();

  /**
   * GRAPHQL
   */
  // SaleEvents Fetching logic
  const { data: saleEventsData, loading: fetchingLoading } = useQuery<GetLiveOpsSaleEventConfigsQuery>(
    LIVEOPS_SALE_EVENT_CONFIGS_QUERY,
    {
      fetchPolicy: 'no-cache',
    }
  );

  // SaleEvent mutation Logic
  // creating a new saleEvent
  const [createSaleEvent, { loading: createSaleEventLoading }] = useMutation<
    CreateSaleEventMutation,
    CreateSaleEventMutationVariables
  >(CREATE_SALE_EVENT);

  // Fetch SaleEvents config
  const shopDiamondsSales =
    useQuery<GetAppShopDiamondsSalesQuery, GetAppShopDiamondsSalesQueryVariables>(APP_SHOP_DIAMONDS_SALES).data?.app
      .shopDiamondsSales ?? [];
  const coinsSales = useQuery<GetAppCoinsSalesQuery>(APP_COINS_SALES).data?.app.coinsSales ?? [];
  const boostsSales = useQuery<GetAppBoostsSalesQuery>(APP_BOOSTS_SALES).data?.app.boostsSales ?? [];
  const stickersSales = vanityDiscounts.map((discount) => {
    return {
      id: `sticker.1.${discount.value}`,
      amount: 1,
      offerPercentage: discount.value,
    };
  });
  const framesSales = vanityDiscounts.map((discount) => {
    return {
      id: `frame.1.${discount.value}`,
      amount: 1,
      offerPercentage: discount.value,
    };
  });
  const vinylsSales = vanityDiscounts.map((discount) => {
    return {
      id: `vinyl.1.${discount.value}`,
      amount: 1,
      offerPercentage: discount.value,
    };
  });

  const saleConfigs = [
    ...shopDiamondsSales,
    ...coinsSales,
    ...boostsSales,
    ...stickersSales,
    ...framesSales,
    ...vinylsSales,
  ];

  // Takes an array of saleConfigs, and return a list of all targeted products without duplicates
  function getTargetedProducts() {
    const targetedProducts: TargetedProduct[] = [];
    const seenProductNames: Set<string> = new Set();

    for (const saleConfig of saleConfigs) {
      const targetedProductId = saleConfig.id.split('.').slice(0, -1).join('.');

      if (!seenProductNames.has(targetedProductId)) {
        targetedProducts.push({
          id: targetedProductId,
          amount: saleConfig.amount,
          saleConfigs: saleConfigs.filter((item) => item.id.includes(targetedProductId)),
          boostType: 'boostType' in saleConfig ? saleConfig.boostType : undefined,
        });
        seenProductNames.add(targetedProductId);
      }
    }
    return targetedProducts;
  }

  const appSalesConfig: AppSalesConfig = {
    saleConfigs,
    targetedProducts: getTargetedProducts() ?? [],
  };

  const loading = fetchingLoading || createSaleEventLoading;

  /**
   * STATE
   */
  /**
   * @type {[SaleEventConfig[], Function]} Loading
   */
  const [saleEvents, setSaleEvents] = React.useState<SaleEventConfig[]>([]);
  const [showAddModal, setShowAddModal] = React.useState(false);

  /**
   * Helpers function
   */

  const isSaleEventSelected = useCallback(() => {
    return !_.isEmpty(saleEventId) && saleEvents.find(({ id }) => id === saleEventId);
  }, [saleEventId, saleEvents]);

  const selectSaleEvent = useCallback(
    (newSaleEventId) => {
      history.push(`${baseURL}/${newSaleEventId}`);
    },
    [history]
  );

  // SaleEvent data methods
  const handleUpsertSaleEvent = (saleEvent: SaleEventConfig) => {
    // Remove the updated saleEvent from the list if it was already in and add it with the updated value
    const otherSaleEvents = saleEvents.filter(({ id }) => id !== saleEvent.id);
    const totalSaleEvents = [...otherSaleEvents, saleEvent];
    // Then sort the array
    setSaleEvents(_.orderBy(totalSaleEvents, 'startDate', 'desc'));
  };

  const handleRemoveSaleEvent = (_saleEventId: string) => {
    const remainingSaleEvents = saleEvents.filter(({ id }) => id !== _saleEventId);
    setSaleEvents(_.orderBy(remainingSaleEvents, 'startDate', 'desc'));
  };

  // SaleEvent modal methods
  const handleAddSale = (saleEventInput: CreateSaleEventInput) => {
    setShowAddModal(false);
    createSaleEvent({ variables: { input: saleEventInput } })
      // @ts-ignore
      .then(({ data: { createSaleEvent: createSaleEventData } }) => {
        const newSaleEvent = createSaleEventData.saleEvent;
        handleUpsertSaleEvent(newSaleEvent);
        selectSaleEvent(newSaleEvent.id);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  /**
   * STATE UPDATE LOGIC
   */

  // Saving queried saleEvents
  useEffect(() => {
    if (saleEventsData && saleEventsData.liveOps) {
      const _specialEventData = _.orderBy(saleEventsData.liveOps.saleEventConfigs, 'startDate', 'desc');
      // @ts-ignore
      setSaleEvents(_specialEventData);
    }
  }, [saleEventsData]);

  // Preselecting SaleEvent if user lands on this page for the first time
  useEffect(() => {
    if (saleEventId === ':id') {
      selectSaleEvent('');
    }
    if (!isSaleEventSelected() && saleEvents.length > 0) {
      selectSaleEvent(saleEvents[0].id);
    }
  }, [isSaleEventSelected, selectSaleEvent, saleEventId, saleEvents]);

  /**
   * RENDERING
   */

  return (
    <>
      <LoadingLogo show={fetchingLoading} />

      {isSaleEventSelected() && (
        // @ts-ignore
        <Tab.Container className="h-100" activeKey={saleEventId} onSelect={selectSaleEvent}>
          <Row className="mb-5 h-100">
            <Col className="col-2">
              <Nav.Item className="mb-3">
                {loading ? (
                  <Spinner animation="border" />
                ) : (
                  <>
                    <Button className="w-100" variant="success" onClick={() => setShowAddModal(true)}>
                      <i className="fas fa-plus mr-2" />
                      create Sale Event
                    </Button>
                  </>
                )}
              </Nav.Item>
              <Nav.Item className="mb-3">
                <FlushButton model={RedisCacheModel.SALE_EVENT} />
              </Nav.Item>
              <Nav variant="pills">
                {saleEvents.map((saleEvent) => (
                  <Nav.Item key={saleEvent.id} className="w-100 position-relative">
                    <Nav.Link
                      eventKey={saleEvent.id}
                      className={`d-flex justify-content-between text-break align-items-center ${
                        saleEvent.isHardCoded ? 'liveops-nav-link-old' : ''
                      }`}
                    >
                      <span>{saleEvent.name}</span>
                      <Badge
                        className="m-1"
                        pill
                        variant={
                          saleEvent.status === 'DRAFT'
                            ? 'danger'
                            : moment(saleEvent.startDate).isAfter(moment.now())
                            ? 'warning'
                            : moment(saleEvent.stopDate).isBefore(moment.now())
                            ? 'secondary'
                            : 'success'
                        }
                      >
                        {saleEvent.status === 'DRAFT'
                          ? 'Inactive'
                          : moment(saleEvent.startDate).isAfter(moment.now())
                          ? 'Pending'
                          : moment(saleEvent.stopDate).isBefore(moment.now())
                          ? 'Expired'
                          : 'Running'}
                      </Badge>
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Col>

            <Col className="col-10">
              <Tab.Content className="h-100">
                <Card>
                  <Card.Body>
                    <SaleEventData
                      // @ts-ignore
                      appSalesConfig={appSalesConfig}
                      saleEventId={saleEventId}
                      onUpsertSaleEvent={handleUpsertSaleEvent}
                      onRemoveSaleEvent={handleRemoveSaleEvent}
                    />
                  </Card.Body>
                </Card>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      )}

      <AddSaleEventModal
        show={(!loading && saleEvents.length === 0) || showAddModal}
        loading={createSaleEventLoading}
        onHide={() => setShowAddModal(false)}
        onAdd={handleAddSale}
      />
    </>
  );
}

export default SaleEvents;
