import { useMutation } from '@apollo/client';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { Col } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import ListGroup from 'react-bootstrap/ListGroup';
import Media from 'react-bootstrap/Media';
import LazyLoad from 'react-lazyload';
import {
  CurationContentPackCustomField,
  CurationContentPackQuery,
  CurationSongDdexSearchQuery,
} from '../../../../__gqltypes__';
import PlaylistSongPopup from '../popups/PlaylistSongPopup';
import UnmatchedSongInfoPopup from '../popups/UnmatchedSongInfoPopup';
import mutations from '../utils/mutations';
import ExplicitIcon from './ExplicitIcon';
import PlayButton from './PlayButton';
import QuestionableIcon from './QuestionableIcon';
import './SongList.css';
import ToggleButtonSelector, { ToggleButtonOption } from './ToggleButtonSelector';
import AddingDDEXSongs from '../screens/PlaylistInfo/components/AddingDDEXSongs';

type CurationContentPack = CurationContentPackQuery['curationContentPack'];
type CurationSong = Extract<CurationContentPack['contentItems'][0], { __typename: 'CurationSong' }>;
type CurationSongInput = Extract<CurationContentPack['contentItems'][0], { __typename: 'CurationSongInput' }>;
type DDEXSong = CurationSongDdexSearchQuery['curationSongDDEXSearch'][0];

const ImageLazyLoadPlaceholder = () => {
  return <div className="placeholder">&nbsp;</div>;
};

/**
 * Creates a new list with the new order given
 */
const reorder = <T,>(list: T[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

type ContentItem = CurationSongInput | CurationSong;

type ItemProps = {
  index: number;
  hidden: boolean;
  isDragDisabled: boolean;
  forcedFields: CurationContentPackCustomField[];
  onSelectSong: (song: ContentItem) => void;
  song: ContentItem;
};

type WHITELIST_TOGGLE = 'NONE' | 'WHITELISTED' | 'BLACKLISTED' | 'APPROXIMATED' | 'CLEAN' | 'MISSING';

const SongListItem = memo(({ song, onSelectSong, forcedFields, index, hidden, isDragDisabled }: ItemProps) => {
  if (hidden) {
    return null;
  }

  if (song.__typename === 'CurationSongInput') {
    return (
      <Draggable draggableId={song.id} index={index - 1} isDragDisabled={isDragDisabled}>
        {(provided) => (
          <ListGroup.Item
            variant="dark"
            className="listgroup-small muted"
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <Media>
              <div className="mr-3">{index}.</div>
              <Media.Body onClick={() => onSelectSong(song)}>
                {`${song.title} - ${song.artist}`}
                <Badge pill variant="dark" className="ml-1">
                  Not found
                </Badge>
              </Media.Body>
            </Media>
          </ListGroup.Item>
        )}
      </Draggable>
    );
  }

  let userDisplay: string | null = null;

  if (forcedFields && forcedFields.length) {
    if (forcedFields.includes(CurationContentPackCustomField.FULL_CUSTOM) && !!song.playlistCustomDisplay) {
      const { artist, title } = JSON.parse(song.playlistCustomDisplay);
      userDisplay = `${title || song.displayTitle || song.title} - ${artist}`;
    } else if (
      forcedFields.includes(CurationContentPackCustomField.CUSTOM) &&
      song.playlistCustomDisplay !== undefined
    ) {
      userDisplay = song.playlistCustomDisplay;
    } else if (forcedFields.includes(CurationContentPackCustomField.MOVIE_TITLE) && song.movieTitle !== undefined) {
      userDisplay = song.movieTitle;
    } else if (forcedFields.includes(CurationContentPackCustomField.JESTER_TITLE) && song.jesterTitle !== undefined) {
      userDisplay = song.jesterTitle;
    } else if (forcedFields.includes(CurationContentPackCustomField.LEAD_SINGER) && song.leadSinger !== undefined) {
      userDisplay = song.leadSinger;
    } else if (forcedFields.includes(CurationContentPackCustomField.TITLE)) {
      userDisplay = song.displayTitle || song.title;
    } else if (forcedFields.includes(CurationContentPackCustomField.ARTIST)) {
      userDisplay = song.displayArtist || song.artist;
    }
  }

  const badMatch = song.songInput.matchType === 'SIMILARITY' || song.songInput.matchType === 'TITLE_ARTIST';

  const isArtistNameDifferent =
    // eslint-disable-next-line
    // @ts-ignore
    song.songInput?.artist && song.songInput?.artist.toLowerCase() !== song.artist.toLowerCase();

  return (
    <Draggable draggableId={song.id} index={index - 1} isDragDisabled={isDragDisabled}>
      {(provided) => (
        <ListGroup.Item
          className="listgroup-small"
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <Media>
            <div className="mr-3">{index}.</div>
            <LazyLoad height={25} overflow placeholder={<ImageLazyLoadPlaceholder />}>
              <Image src={song.picture} />
            </LazyLoad>
            <Media.Body onClick={() => onSelectSong(song)}>
              {`${song.displayTitle || song.title} - ${song.displayArtist || song.artist}`}
              {(forcedFields ?? []).includes(CurationContentPackCustomField.CUSTOM_SAMPLE) ? (
                <span style={{ borderRadius: 8 }} className="mx-2 px-2 border border-secondary text-secondary">
                  Custom
                  <PlayButton sample={song.playlistCustomSample} />
                </span>
              ) : (
                <PlayButton sample={song.defaultSample} />
              )}

              <ExplicitIcon explicit={song.isExplicit} />
              <QuestionableIcon questionable={song.questionableStatus || null} />

              {song.state !== 'WHITELISTED' && (
                <Badge pill variant="danger">
                  {song.state.toLowerCase()}
                </Badge>
              )}
              {song.isInvalid && (
                <Badge pill variant="danger">
                  invalid
                </Badge>
              )}
              {userDisplay && (
                <Badge pill variant="info">
                  {userDisplay}
                </Badge>
              )}
              {!song.isDDEXSong && (
                <Badge pill variant="warning">
                  DDEX MISSING
                </Badge>
              )}
              {isArtistNameDifferent && (
                <Badge pill variant="info">
                  different ddex name
                </Badge>
              )}
              {badMatch && (
                <Badge pill variant="warning">
                  Approximate match
                </Badge>
              )}
            </Media.Body>
          </Media>
        </ListGroup.Item>
      )}
    </Draggable>
  );
});

function shouldDisplaySong(song: any, whitelistedToggle: WHITELIST_TOGGLE) {
  switch (whitelistedToggle) {
    case 'NONE':
      return true;
    case 'APPROXIMATED':
      return song.songInput?.matchType === 'SIMILARITY' || song.songInput?.matchType === 'TITLE_ARTIST';
    case 'MISSING':
      return song.__typename === 'CurationSongInput';
    case 'BLACKLISTED':
      return song.state === 'BLACKLISTED';
    case 'WHITELISTED':
      return song.state === 'WHITELISTED';
    case 'CLEAN':
      return song.state === 'WHITELISTED' && !song.isExplicit && song.questionableStatus !== 'QUESTIONABLE';
    default:
      return true;
  }
}

type Props = {
  addingDDEXSongs: boolean;
  onAddDDEXSongs: (songs: DDEXSong[]) => void;
  curationContentPack: CurationContentPack;
  onDownloadCSV: () => Promise<void>;
  downloadingCSV: boolean;
  onRemoveBatchSongs: (value: string[]) => Promise<void>;
  selectedSongId?: string | null | undefined;
};

function SongList({
  addingDDEXSongs,
  onAddDDEXSongs,
  curationContentPack,
  onDownloadCSV,
  downloadingCSV,
  onRemoveBatchSongs,
  selectedSongId,
}: Props) {
  const [whitelistedToggle, setWhitelistedToggle] = useState<WHITELIST_TOGGLE>('NONE');

  const [contentItems, setContentItems] = useState(curationContentPack.contentItems);
  const [selectedSong, setSelectedSong] = useState<ContentItem | null>(null);

  const [reorderContentItems] = useMutation(mutations.REORDER_SONGS_FROM_CURATION_CONTENT_PACK);
  const {
    total,
    whitelistedOnly,
    blacklistedOnly,
    blacklistedSongInputIds,
    approximatedOnly,
    cleanOnly,
    missingOnly,
    missingSongInputIds,
  } = useMemo(() => {
    /* eslint-disable @typescript-eslint/no-shadow */
    const total = contentItems.length;
    const whitelistedOnly = contentItems.filter((s) => shouldDisplaySong(s, 'WHITELISTED')).length;
    const _blacklistedOnly = contentItems.filter((s) => shouldDisplaySong(s, 'BLACKLISTED'));
    const blacklistedSongInputIds = _blacklistedOnly.map((s) => (s as CurationSong).songInput?.id).filter((s) => !!s);
    const blacklistedOnly = _blacklistedOnly.length;
    const approximatedOnly = contentItems.filter((s) => shouldDisplaySong(s, 'APPROXIMATED')).length;
    const cleanOnly = contentItems.filter((s) => shouldDisplaySong(s, 'CLEAN')).length;
    const _missingOnly = contentItems.filter((s) => shouldDisplaySong(s, 'MISSING'));
    const missingSongInputIds = _missingOnly.map((s) => (s as CurationSongInput).id);
    const missingOnly = _missingOnly.length;
    /* eslint-enable @typescript-eslint/no-shadow */

    return {
      total,
      whitelistedOnly,
      blacklistedOnly,
      blacklistedSongInputIds,
      approximatedOnly,
      cleanOnly,
      missingOnly,
      missingSongInputIds,
    };
  }, [contentItems]);

  useEffect(() => {
    setContentItems(curationContentPack.contentItems);
    // selectedSongId is set, we force display the popup
    if (curationContentPack.contentItems && selectedSongId) {
      const forceDisplayContentItem = curationContentPack.contentItems.find((i) =>
        'id' in i ? i.id === selectedSongId : false
      );
      if (forceDisplayContentItem) {
        setSelectedSong(forceDisplayContentItem as ContentItem);
      }
    }
  }, [curationContentPack, selectedSongId]);

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(contentItems, result.source.index, result.destination.index);
    setContentItems(items);
    const input = {
      id: curationContentPack.id,
      // items is a mix of Song and SongInputs, so we need to get the right id
      songInputIds: items.map((c) => {
        return c.__typename === 'CurationSongInput' ? c.id : (c as CurationSong).songInput.id;
      }),
    };
    reorderContentItems({
      variables: { input, id: curationContentPack.id },
    });
  };

  const handleTabChange = (val: string) => setWhitelistedToggle(val as WHITELIST_TOGGLE);

  const handleSelectSong = useCallback((song) => {
    setSelectedSong(song);
  }, []);

  const handleModalClose = () => {
    setSelectedSong(null);
  };

  const handlePrevious = () => {
    if (selectedSong) {
      const filteredContentItems = contentItems.filter((song) => shouldDisplaySong(song, whitelistedToggle));
      // @ts-ignore
      const index = filteredContentItems.findIndex((i) => i.id === selectedSong.id);
      if (index > 0) {
        setSelectedSong(filteredContentItems[index - 1] as ContentItem);
      } else {
        setSelectedSong(null);
      }
    }
  };

  const handleNext = () => {
    if (selectedSong) {
      const filteredContentItems = contentItems.filter((song) => shouldDisplaySong(song, whitelistedToggle));
      // @ts-ignore
      const index = filteredContentItems.findIndex((i) => i.id === selectedSong.id);
      if (index > -1) {
        setSelectedSong(filteredContentItems[index + 1] as ContentItem);
      } else {
        setSelectedSong(null);
      }
    }
  };

  return (
    <>
      <Form inline className="justify-content-between mb-2 mt-3">
        <Col>
          <ToggleButtonSelector value={whitelistedToggle} onChange={handleTabChange} defaultValue="NONE">
            <ToggleButtonOption value="NONE">All ({total})</ToggleButtonOption>
            <ToggleButtonOption value="WHITELISTED">Whitelisted Only ({whitelistedOnly})</ToggleButtonOption>
            <ToggleButtonOption value="BLACKLISTED">Blacklisted Only ({blacklistedOnly})</ToggleButtonOption>
            <ToggleButtonOption value="APPROXIMATED">Approximate Only ({approximatedOnly})</ToggleButtonOption>
            <ToggleButtonOption value="CLEAN">Clean/Whitelisted Only ({cleanOnly})</ToggleButtonOption>
            <ToggleButtonOption value="MISSING">Missing Only ({missingOnly})</ToggleButtonOption>
          </ToggleButtonSelector>
        </Col>
        <Col>
          <Button className="mt-2 mt-xl-0" onClick={onDownloadCSV} disabled={downloadingCSV}>
            <i className="fa fa-download mr-2" />
            {downloadingCSV ? 'Downloading' : 'Download CSV'}
          </Button>
          <AddingDDEXSongs loading={addingDDEXSongs} onSubmit={onAddDDEXSongs} />
        </Col>
      </Form>
      {whitelistedToggle === 'BLACKLISTED' && (
        <Col className="mb-4">
          <Button
            variant="danger"
            className="mt-2"
            onClick={() => {
              onRemoveBatchSongs(blacklistedSongInputIds);
            }}
            disabled={blacklistedOnly === 0 || curationContentPack.state !== 'VALIDATING'}
          >
            <i className="fas fa-trash mr-2" />
            Remove all Blacklisted songs
          </Button>
        </Col>
      )}
      {whitelistedToggle === 'MISSING' && (
        <Col className="mb-4">
          <Button
            variant="danger"
            className="mt-2"
            onClick={() => {
              onRemoveBatchSongs(missingSongInputIds);
            }}
            disabled={missingOnly === 0 || curationContentPack.state !== 'VALIDATING'}
          >
            <i className="fas fa-trash mr-2" />
            Remove all Missing songs
          </Button>
        </Col>
      )}

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <ListGroup variant="flush" className="w-100 mb-2" {...provided.droppableProps} ref={provided.innerRef}>
              {contentItems.map((song, index) => (
                <SongListItem
                  key={
                    'songInput' in song
                      ? song.songInput?.id ?? song.id
                      : // @ts-ignore
                        song.id
                  }
                  index={index + 1}
                  song={song as ContentItem}
                  onSelectSong={handleSelectSong}
                  forcedFields={curationContentPack.selectedCustomFields}
                  hidden={!shouldDisplaySong(song, whitelistedToggle)}
                  isDragDisabled={curationContentPack.state !== 'VALIDATING'}
                />
              ))}
              {provided.placeholder}
            </ListGroup>
          )}
        </Droppable>
      </DragDropContext>
      <PlaylistSongPopup
        show={selectedSong?.__typename === 'CurationSong'}
        songId={selectedSong?.id}
        songInputId={(selectedSong as CurationSong)?.songInput?.id}
        contentPack={curationContentPack}
        onHide={handleModalClose}
        onSelectPrevious={handlePrevious}
        onSelectNext={handleNext}
      />
      <UnmatchedSongInfoPopup
        show={selectedSong?.__typename === 'CurationSongInput'}
        songInput={selectedSong as CurationSongInput}
        contentPack={curationContentPack}
        onHide={handleModalClose}
        onSelectPrevious={handlePrevious}
        onSelectNext={handleNext}
      />
    </>
  );
}

export default SongList;
