import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { LevelConfigUserFeatureUnlocked } from '../../../../../../__gqltypes__';

const features = [
  LevelConfigUserFeatureUnlocked.ARTIST_SELECTION,
  LevelConfigUserFeatureUnlocked.TOURNAMENT,
  LevelConfigUserFeatureUnlocked.LIVE_MULTIPLAYER,
  LevelConfigUserFeatureUnlocked.TEXT_CHAT,
  LevelConfigUserFeatureUnlocked.MONTHLY_PASS,
  LevelConfigUserFeatureUnlocked.TEAM,
  LevelConfigUserFeatureUnlocked.LIVE_SHOW,
];

export default function FeatureSelector({ onChange }: { onChange: (value: string) => void }) {
  const [featuresSelected, setFeaturesSelected] = useState<LevelConfigUserFeatureUnlocked[]>([]);

  return (
    <>
      {features.map((feature) => (
        <Form.Check
          key={feature}
          type="switch"
          id={`unlocked${feature}`}
          label={`Users has unlocked ${feature}`}
          className="mt-3"
          checked={featuresSelected.includes(feature)}
          onChange={(e) => {
            const { checked } = e.target;
            setFeaturesSelected((pFeatures) => {
              const newFeatures = checked ? [...pFeatures, feature] : pFeatures.filter((f) => f !== feature);
              onChange(newFeatures.join(';'));
              return newFeatures;
            });
          }}
        />
      ))}
    </>
  );
}
