import { useApolloClient, gql } from '@apollo/client';
import GraphiQL from 'graphiql';
import React from 'react';
import './GraphiQLView.css';

const GraphiQLView = () => {
  const client = useApolloClient();

  const introspectionProvider = (query) => {
    const _query = gql`
      ${query.query}
    `;

    if (query.query.trim().match(/mutation( )?{/)) {
      return client
        .mutate({ mutation: _query, variables: query.variables, fetchPolicy: 'no-cache' })
        .then((result) => result);
    }
    return client
      .query({ query: _query, variables: query.variables, fetchPolicy: 'no-cache' })
      .then((result) => result);
  };

  return <GraphiQL fetcher={introspectionProvider} />;
};

export default GraphiQLView;
