import { DocumentNode } from 'graphql';
import _ from 'lodash';
import moment from 'moment';
import {
  BetweenAggregatedMetricsInput,
  MetricOrderBy,
  MetricTimestamp,
  MetricType,
  PreviousAggregatedMetricsInput,
  RequestBetweenAggregatedMetricsInput,
  RequestPreviousAggregatedMetricsInput,
  ServerEnv,
  ServerMode,
} from '../../../../../../__gqltypes__';
import {
  InteractiveMonitoringPlaygroundOptions,
  NumberOfTimestamps,
  RequestTrackingMethod,
  TimeSelectionMethod,
} from './formOptions';
import * as MonitoringQueries from '../graphql';
import { MonitoringVariables } from './utils';

export type QueriesPair = {
  essentials: DocumentNode;
  full: DocumentNode;
};
const QueriesByMethod: Record<TimeSelectionMethod, Record<RequestTrackingMethod, QueriesPair>> = {
  [TimeSelectionMethod.BETWEEN]: {
    [RequestTrackingMethod.MOST_IMPORTANT]: {
      essentials: MonitoringQueries.MONITORING_BETWEEN,
      full: MonitoringQueries.FULL_MONITORING_BETWEEN,
    },
    [RequestTrackingMethod.SPECIFIC]: {
      essentials: MonitoringQueries.MONITORING_SPECIFIC_REQUESTS_BETWEEN,
      full: MonitoringQueries.FULL_MONITORING_SPECIFIC_REQUESTS_BETWEEN,
    },
  },
  [TimeSelectionMethod.PREVIOUS]: {
    [RequestTrackingMethod.MOST_IMPORTANT]: {
      essentials: MonitoringQueries.MONITORING_PREVIOUS,
      full: MonitoringQueries.FULL_MONITORING_PREVIOUS,
    },
    [RequestTrackingMethod.SPECIFIC]: {
      essentials: MonitoringQueries.MONITORING_SPECIFIC_REQUESTS_PREVIOUS,
      full: MonitoringQueries.FULL_MONITORING_SPECIFIC_REQUESTS_PREVIOUS,
    },
  },
};

const NumberOfTimestampsOption = {
  LAST_LIVESHOW_1: 1, 
  LAST_LIVESHOW_2: 2,
  LAST_LIVESHOW_3: 3,
  LAST_LIVESHOW_4: 4,
  LAST_LIVESHOW_5: 5,
  LAST_12_HOURS: 12,
  LAST_24_HOURS: 24,
  LAST_72_HOURS: 72,
  LAST_168_HOURS: 168,
  LAST_7_DAYS: 7,
  LAST_14_DAYS: 14,
  LAST_30_DAYS: 30,
  LAST_90_DAYS: 90,
  LAST_4_WEEKS: 4,
  LAST_12_WEEKS: 12,
  LAST_52_WEEKS: 52,
  LAST_6_MONTHS: 6,
  LAST_12_MONTHS: 12,
  LAST_24_MONTHS: 24,
  LAST_60_MONTHS: 60,
  LAST_4_QUARTERS: 4,
  LAST_8_QUARTERS: 8,
  LAST_20_QUARTERS: 20,
  LAST_5_YEARS: 5,
};

type TimingOptions =
  | { start: string; end: string }
  | { maxNumberOfTimestamps: number; skipTimestamps: number | undefined };
type BaseOptions = { timestamp: MetricTimestamp; type: MetricType; mode: ServerMode; env: ServerEnv };

// Helpers to build options
function prepareMinutelyLiveShowOptions(start: string | undefined, end: string | undefined, maxNumberOfTimestamps: NumberOfTimestamps): {liveShowStart: string, liveShowEnd: string} {
    const nowMoment = moment();
    const nowMoment2130 = nowMoment.clone().utc().set({ h: 21, m: 30, s: 0, ms: 0 });

    const daysAgo = NumberOfTimestampsOption[maxNumberOfTimestamps] - 1;
    const startMoment = nowMoment2130.isBefore(nowMoment) ? nowMoment.clone() : nowMoment2130.clone().subtract(1, 'd');
    if (daysAgo > 0) {
      startMoment.subtract(daysAgo, 'd');
    }

    const liveShowStart = startMoment.toISOString();
    const liveShowEnd = startMoment.clone().add(1, 'h').toISOString();
    return {liveShowStart, liveShowEnd};
}

function prepareDatesOptions(start: string | undefined, end: string | undefined, compareSkip?: number): TimingOptions {
  const areDatesValid = moment(start).isValid && moment(end).isValid;
  if (!start || !end || !areDatesValid) {
    throw new Error('Invalid dates');
  }
  if (compareSkip) {
    const diff = moment(end).diff(start);
    return {
      start: moment(start)
        .subtract(diff * compareSkip)
        .toISOString(),
      end: moment(start)
        .subtract(diff * compareSkip)
        .toISOString(),
    };
  }
  return { start, end };
}

function prepareMostImportantOptions(
  otherOptions: TimingOptions & BaseOptions,
  orderQuery: MetricOrderBy | 'ALL',
  maxNumberOfRequests: number,
  includeSubscriptions?: boolean
): BetweenAggregatedMetricsInput | PreviousAggregatedMetricsInput {
  if (orderQuery === 'ALL') return otherOptions;
  return {
    ...otherOptions,
    orderQuery,
    maxNumberOfRequests,
    filterOutSubscriptions: !includeSubscriptions,
  };
}
function prepareSpecificOptions(
  otherOptions: TimingOptions & BaseOptions,
  onlyRequestNames: string[]
): RequestBetweenAggregatedMetricsInput | RequestPreviousAggregatedMetricsInput {
  if (onlyRequestNames.length < 1) {
    throw new Error('Missing request names');
  }
  return { ...otherOptions, onlyRequestNames };
}

function preparePreviousOptions(maxNumberOfTimestamps: NumberOfTimestamps, compareSkip?: number): TimingOptions {
  return {
    maxNumberOfTimestamps: NumberOfTimestampsOption[maxNumberOfTimestamps],
    skipTimestamps: compareSkip ? compareSkip * NumberOfTimestampsOption[maxNumberOfTimestamps] : undefined,
  };
}

/**
 * From a set of options constructed by the playground or retrieved from url,
 * get graphql query and options.
 *
 * If the options are invalid, return an error and the query/options are null
 *
 * @param options
 * @returns
 */
export default function buildRequest(playgroundOptions: InteractiveMonitoringPlaygroundOptions):
  | {
      query: QueriesPair;
      variables: MonitoringVariables;
      comparisonQuery?: QueriesPair;
      getComparisonVariables?: (foundNames: string[]) => MonitoringVariables;
      error: null;
    }
  | { query: null; variables: null; error: string } {
  const {
    timeSelection,
    requestTracking,
    maxNumberOfRequests,
    maxNumberOfTimestamps,
    start,
    end,
    sortMethod,
    requestNames,
    compare,
    compareSkip,
    timestamp,
    type,
    mode,
    includeSubscriptions,
    env,
  } = playgroundOptions;
  const timeSelectionFixed = timestamp === MetricTimestamp.MINUTELY ? TimeSelectionMethod.BETWEEN : timeSelection;
  const query = QueriesByMethod[timeSelectionFixed][playgroundOptions.requestTracking];
  const comparisonQuery = compare ? QueriesByMethod[timeSelectionFixed].SPECIFIC : undefined;

  try {
    const beta = process.env.REACT_APP_NAMESPACE === 'beta';
    const prod = process.env.REACT_APP_NAMESPACE === 'default' || process.env.REACT_APP_NAMESPACE === 'prod';
    const defaultEnv = prod ? ServerEnv.PROD : beta ? ServerEnv.BETA : ServerEnv.TEST;

    const baseOptions: BaseOptions = { timestamp, type, mode: mode || ServerMode.API, env: env ?? defaultEnv };
    
    let startFixed = start;
    let endFixed = end;
    if (timestamp === MetricTimestamp.MINUTELY) {
      const {liveShowStart, liveShowEnd} = prepareMinutelyLiveShowOptions(start, end, maxNumberOfTimestamps);
      startFixed = liveShowStart;
      endFixed = liveShowEnd;
    }

    const timingOptions =
    timeSelectionFixed === TimeSelectionMethod.BETWEEN
        ? prepareDatesOptions(startFixed, endFixed)
        : preparePreviousOptions(maxNumberOfTimestamps);
    const options =
      requestTracking === RequestTrackingMethod.MOST_IMPORTANT
        ? prepareMostImportantOptions(
            { ...timingOptions, ...baseOptions },
            sortMethod,
            maxNumberOfRequests,
            includeSubscriptions
          )
        : prepareSpecificOptions({ ...timingOptions, ...baseOptions }, requestNames);

    if (compare) {
      const compareTimingOptions =
      timeSelectionFixed === TimeSelectionMethod.BETWEEN
          ? prepareDatesOptions(startFixed, endFixed, compareSkip || 1)
          : preparePreviousOptions(maxNumberOfTimestamps, compareSkip || 1);

      return {
        query,
        variables: { input: options },
        comparisonQuery,
        getComparisonVariables: (foundNames) => {
          return { input: prepareSpecificOptions({ ...compareTimingOptions, ...baseOptions }, foundNames) };
        },
        error: null,
      };
    }

    return { query, variables: { input: options }, error: null };
  } catch (error) {
    return { query: null, variables: null, error: (error as Error).message };
  }
}
