import React, { Children, cloneElement, useState } from 'react';
import { Accordion, ListGroup, ToggleButton } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
// Bootstrap
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
// Hooks
import useWidgets from '../../controllers/useWidgets';
import DataTable from '../../models/DataTable';
import CustomModal from '../CustomModal';
import LineChart from './charts/LineChart';
import CostDisplay from './CostDisplay';
import ErrorDisplay from './ErrorDisplay';
import SectionDisplay from './SectionDisplay';
import './WidgetContainer.css';

const SectionDisplayType = React.createElement(SectionDisplay).type;
const LineChartDisplayType = React.createElement(LineChart).type;

const WidgetCard = ({ child, data, handleZoom, downloadCSV }) => {
  const { id } = child.props;
  const [averageChecked, setAverageChecked] = useState(false);
  const [averageReleaseChecked, setAverageReleaseChecked] = useState(false);
  const [rollingAverageChecked, setRollingAverageChecked] = useState(false);
  const [trendlineChecked, setTrendlineChecked] = useState(false);

  return (
    <Card className="mb-3 mt-2 mx-4">
      <Row className="mb-3">
        <Col>
          {cloneElement(child, {
            data,
            displayAverage: averageChecked,
            displayAverageRelease: averageReleaseChecked,
            displayRollingAverage: rollingAverageChecked,
            displayTrendline: trendlineChecked,
          })}
        </Col>
      </Row>
      <Row className="justify-content-around">
        <Button size="sm" className="mb-3" onClick={handleZoom(id)}>
          <i className="fas fa-search-plus mr-2" />
          Zoom
        </Button>
        <Button size="sm" className="mb-3" onClick={downloadCSV(id)}>
          <i className="fa fa-download mr-2" />
          Download CSV
        </Button>
      </Row>
      {child.type === LineChartDisplayType && (
        <Accordion>
          <Card>
            <Accordion.Toggle as={Card.Header} className="cursor-pointer" eventKey="0">
              Display Options
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <ListGroup variant="flush">
                <ListGroup.Item className="p-0 px-4">
                  <ToggleButton
                    block
                    size="lg"
                    id="toggle-check1"
                    type="checkbox"
                    value="1"
                    checked={averageChecked}
                    variant={averageChecked ? 'primary' : 'outline-primary'}
                    onChange={(e) => setAverageChecked(e.currentTarget.checked)}
                  >
                    &nbsp; Display Average
                  </ToggleButton>
                  <ToggleButton
                    block
                    size="lg"
                    id="toggle-check1"
                    type="checkbox"
                    value="1"
                    checked={averageReleaseChecked}
                    variant={averageReleaseChecked ? 'primary' : 'outline-primary'}
                    onChange={(e) => setAverageReleaseChecked(e.currentTarget.checked)}
                  >
                    &nbsp; Display Average Per Release
                  </ToggleButton>
                </ListGroup.Item>
                <ListGroup.Item className="p-0 px-4">
                  <ToggleButton
                    block
                    size="lg"
                    id="toggle-check2"
                    type="checkbox"
                    value="1"
                    checked={rollingAverageChecked}
                    variant={rollingAverageChecked ? 'primary' : 'outline-primary'}
                    onChange={(e) => setRollingAverageChecked(e.currentTarget.checked)}
                  >
                    &nbsp; Display Moving Average (3 Days)
                  </ToggleButton>
                </ListGroup.Item>
                <ListGroup.Item className="p-0 px-4">
                  <ToggleButton
                    block
                    size="lg"
                    id="toggle-check3"
                    type="checkbox"
                    value="1"
                    checked={trendlineChecked}
                    variant={trendlineChecked ? 'primary' : 'outline-primary'}
                    onChange={(e) => setTrendlineChecked(e.currentTarget.checked)}
                  >
                    &nbsp; Display Trendline
                  </ToggleButton>
                </ListGroup.Item>
              </ListGroup>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      )}
    </Card>
  );
};

const WidgetContainer = ({ children, queryData, loading, errors, metadataList, notes }) => {
  const { processedData, downloadCSV, processingLoading } = useWidgets(children, queryData);
  const [showModal, setShowModal] = useState(false);
  const [zoomId, setZoomId] = useState(null);
  const handleZoom = (id) => () => {
    setZoomId(id);
    setShowModal(true);
  };
  const onCloseModal = () => setShowModal(false);
  return (
    <>
      {loading || processingLoading ? (
        <Row className="justify-content-center">
          <Spinner animation="border" />
        </Row>
      ) : (
        <>
          <Row className="justify-content-center">
            <Col>
              <ErrorDisplay errors={errors} requestNumber={Object.keys(queryData).length} hideOnSuccess />
              <CostDisplay metadata={metadataList} />
            </Col>
          </Row>
          {notes && (
            <Row className="justify-content-center">
              <Col>
                <Card className="p-3 m-2">
                  <h1>Notes</h1>
                  <div>{notes}</div>
                </Card>
              </Col>
            </Row>
          )}

          <Row className="justify-content-center">
            {Children.map(children, (child) => {
              const { id } = child.props;
              if (child.type === SectionDisplayType) {
                return (
                  <Col md={12} className="text-center">
                    {cloneElement(child, child.props)}
                  </Col>
                );
              }
              const data = processedData[id] || queryData[id] || new DataTable();
              return <WidgetCard child={child} data={data} handleZoom={handleZoom} downloadCSV={downloadCSV} />;
            })}
          </Row>
        </>
      )}
      {zoomId !== null && (
        <CustomModal show={showModal} onClose={onCloseModal} title={zoomId}>
          {cloneElement(
            Children.toArray(children).find((child) => child.props.id === zoomId),
            {
              data: processedData[zoomId] || queryData[zoomId],
            }
          )}
        </CustomModal>
      )}
    </>
  );
};

export default WidgetContainer;
