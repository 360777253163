import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { Accordion, Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import {
  FlushRedisCacheForOneUserMutation,
  FlushRedisCacheForOneUserMutationVariables,
  RefreshUserRedisCacheMutation,
  RefreshUserRedisCacheMutationVariables,
  UserRelatedRedisModel,
} from '../../../../../../__gqltypes__';
import { FLUSH_REDIS_CACHE_FOR_ONE_USER_MUTATION, FLUSH_USER_CACHE_MUTATION } from '../../../graphql';

type FlushButtonProps = {
  userId: string;
  model: UserRelatedRedisModel;
  label?: string;
};

function FlushButton({ model, label, userId }: FlushButtonProps) {
  const [flushCache, { loading: flushCacheLoading }] = useMutation<
    FlushRedisCacheForOneUserMutation,
    FlushRedisCacheForOneUserMutationVariables
  >(FLUSH_REDIS_CACHE_FOR_ONE_USER_MUTATION, {
    variables: { input: { userId, model } },
    onCompleted: (data) => {
      console.log(data);
      // Do not block the thread (just notify the admin)
      setTimeout(() => alert(`User ${model} cache has been flushed`), 100);
    },
  });

  const handleFlushCache = () => {
    if (window.confirm(`Are you sure you want to flush the Redis cache ${model} for user ${userId}?`)) {
      flushCache();
    }
  };

  if (!label) {
    return (
      <>
        {flushCacheLoading ? (
          <Spinner animation="border" />
        ) : (
          <Button className="w-100" variant="danger" onClick={handleFlushCache}>
            <>
              <i className="fas fa-trash mr-2" />
              flush cache
            </>
          </Button>
        )}
      </>
    );
  }

  return (
    <div className="d-flex mt-2" style={{ width: 500 }}>
      <div className="my-auto">{label}:</div>
      <div style={{ flex: 1 }} />
      <div>
        {flushCacheLoading ? (
          <Spinner animation="border" />
        ) : (
          <Button style={{ width: 200 }} variant="danger" onClick={handleFlushCache}>
            <>
              <i className="fas fa-trash mr-2" />
              flush cache
            </>
          </Button>
        )}
      </div>
    </div>
  );
}

type Props = {
  userId: string;
};

export const CacheDisplay = ({ userId }: Props) => {
  const [expanded, setExpanded] = useState(false);

  const [flushUserCache, { loading: flushUserCacheLoading }] = useMutation<
    RefreshUserRedisCacheMutation,
    RefreshUserRedisCacheMutationVariables
  >(FLUSH_USER_CACHE_MUTATION, {
    onCompleted: (data) => {
      console.log(data);
      alert(`User with id "${data.refreshUserRedisCache.user.id}" recreated in the cache!`);
    },
  });

  const handleFlushUserCache = () => {
    flushUserCache({ variables: { input: { userId } } });
  };

  return (
    <Card className="mb-4">
      <Card.Body>
        <Accordion>
          <Accordion.Toggle as={Card.Title} variant="link" eventKey="0" onClick={() => setExpanded(!expanded)}>
            <div style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}>
              Caches
              <Button variant="link">
                {!expanded && <i className="fas fa-chevron-down" />}
                {expanded && <i className="fas fa-chevron-up" />}
              </Button>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <>
              <div className="d-flex mt-2" style={{ width: 500 }}>
                <div className="my-auto">User cache:</div>
                <div style={{ flex: 1 }} />
                <div>
                  {flushUserCacheLoading ? (
                    <Spinner animation="border" />
                  ) : (
                    <Button style={{ width: 200 }} onClick={handleFlushUserCache}>
                      <>
                        <i className="fas fa-sync mr-2" />
                        Refresh cache
                      </>
                    </Button>
                  )}
                </div>
              </div>

              <FlushButton userId={userId} model={UserRelatedRedisModel.SUBSCRIPTION_UTIL} label="Subscription util" />
              <FlushButton userId={userId} model={UserRelatedRedisModel.SYSTEM_EVENT} label="System events (chat)" />
              <FlushButton userId={userId} model={UserRelatedRedisModel.UNREAD_COUNT} label="Unread count (chat)" />
            </>
          </Accordion.Collapse>
        </Accordion>
      </Card.Body>
    </Card>
  );
};
