import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Card, Col, Form, Nav, Row, Spinner, Tab } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';

import {
  GenerateHurdleQuizzesMutation,
  GenerateHurdleQuizzesMutationVariables,
  HurdleCalendarQuery,
  HurdleCalendarQueryVariables,
  HurdleType,
  UpdateHurdleQuizMutation,
} from '../../../../../__gqltypes__';
import PlayButton from '../../../curation/components/PlayButton';
import HurdleQuizData from './components/HurdleQuizData';
import { GENERATE_HURDLE_QUIZZES, GET_HURDLE_CALENDAR } from './graphql';

const baseURL = '/sp3/liveops/hurdle-quiz';

type UpdatedHurdleQuiz = UpdateHurdleQuizMutation['updateHurdleQuiz']['hurdleQuiz'];

/**
 * Component/Page to generate or edit Hurdle quizzes
 * @returns
 */
const HurdleQuiz = () => {
  /**
   * ENV DATA
   */
  const { id, month } = useParams<{ id: string; month: string }>();
  const history = useHistory();

  const momentInMonth = moment(month).isValid() ? moment(month) : moment();
  const monthId = momentInMonth.format('YYYY-MM');

  /**
   * GRAPHQL
   */
  const {
    loading: fetchHurdleCalendarLoading,
    data: hurdleCalendarData,
    refetch: refetchCalendar,
  } = useQuery<HurdleCalendarQuery, HurdleCalendarQueryVariables>(GET_HURDLE_CALENDAR, {
    variables: {
      input: {
        type: HurdleType.DEFAULTSONG,
        startDate: momentInMonth.startOf('month').toDate(),
        endDate: momentInMonth.endOf('month').toDate(),
      },
    },
  });
  const hurdleQuizzes = hurdleCalendarData?.hurdleCalendar.hurdleQuizzes ?? [];
  const hurdleQuizId = id ?? hurdleQuizzes.find((quiz) => moment().isSame(quiz.startDate, 'day'))?.id;

  const [
    generateHurdleQuizzes,
    { loading: generateHurdleQuizzesLoading, error: generateHurdleQuizzesError, reset: resetQuizGeneration },
  ] = useMutation<GenerateHurdleQuizzesMutation, GenerateHurdleQuizzesMutationVariables>(GENERATE_HURDLE_QUIZZES, {
    onError: () => {},
    onCompleted: () => {
      refetchCalendar();
    },
  });

  /**
   * HELPERS
   */
  const selectHurdleQuiz = useCallback(
    (newHurdleQuizId) => {
      history.push(`${baseURL}/${monthId}/${newHurdleQuizId}`);
    },
    [history, monthId]
  );

  const monthIds = (): string[] => {
    const startingFeatureMonth = moment('2022-11');
    const limitMonth = moment().add(1, 'year');

    const nbOfMonths = limitMonth.diff(startingFeatureMonth, 'month') + 1;

    return _.range(nbOfMonths).map((months) => moment(startingFeatureMonth).add(months, 'months').format('YYYY-MM'));
  };

  const handleChangeMonthId = (event: React.ChangeEvent<HTMLInputElement>) => {
    resetQuizGeneration();
    history.push(`${baseURL}/${event.target.value}`);
  };

  const tryToGenerateHurdleQuizzes = async () => {
    if (monthId) {
      generateHurdleQuizzes({
        variables: {
          input: {
            type: HurdleType.DEFAULTSONG,
            startDate: moment(monthId).startOf('month').toDate(),
            endDate: moment(monthId).endOf('month').toDate(),
          },
        },
      });
    }
  };

  /**
   * RENDERING
   */
  return (
    <>
      {
        // @ts-ignore
        <Tab.Container className="h-100" activeKey={hurdleQuizId} onSelect={(e) => selectHurdleQuiz(e)}>
          <Row className="mb-5 h-100">
            <Col
              style={{
                maxHeight: 'calc(100vh - 5.875rem)',
                overflow: 'scroll',
              }}
              className="col-5"
            >
              <Nav.Item className="mb-2">
                {generateHurdleQuizzesLoading ? (
                  <Spinner animation="border" />
                ) : (
                  <>
                    <Form.Control value={monthId ?? ''} onChange={handleChangeMonthId} as="select">
                      {monthIds().map((mId) => (
                        <option key={mId} value={mId}>
                          {moment(mId).format('MMMM YYYY')}
                        </option>
                      ))}
                    </Form.Control>
                    <Button
                      className="mt-1 w-100"
                      disabled={fetchHurdleCalendarLoading}
                      variant="success"
                      onClick={() => tryToGenerateHurdleQuizzes()}
                    >
                      <i className="fas fa-calendar-day mr-2" />
                      Generate quizzes
                    </Button>
                  </>
                )}
              </Nav.Item>
              {generateHurdleQuizzesError && (
                <Alert variant="danger">
                  {(generateHurdleQuizzesError?.graphQLErrors?.[0]?.extensions?.details ?? []).map((line: string) =>
                    line.startsWith('* ') ? (
                      <li key={line} className="ml-3">
                        {line.slice(2)}
                      </li>
                    ) : (
                      <p key={line}>{line}</p>
                    )
                  )}
                </Alert>
              )}
              <Nav variant="pills">
                {fetchHurdleCalendarLoading && <Spinner animation="border" />}
                {hurdleQuizzes.map((hurdleQuiz, index) => (
                  <Nav.Item key={`${hurdleQuiz.id}-${index}date`} className="w-100 text-center position-relative">
                    <Nav.Link
                      eventKey={hurdleQuiz.id}
                      className={`justify-content-center ${
                        moment(hurdleQuiz.startDate).isBefore(moment())
                          ? 'liveops-nav-link-old'
                          : 'liveops-nav-link-current'
                      }`}
                    >
                      <Row>
                        <Col className="col-10">
                          {moment(hurdleQuiz.startDate).format('DD/MM/YYYY')} -{' '}
                          {moment(hurdleQuiz.startDate).format('LT')}
                          <br />
                          {`${hurdleQuiz.question?.song?.displayTitle} - ${hurdleQuiz.question?.song?.displayArtist}`}
                        </Col>
                        <Col className="col-2" style={{ fontSize: '25px' }}>
                          {hurdleQuiz.question?.asset && (
                            <PlayButton
                              sample={hurdleQuiz.question.asset}
                              displayTimestamp
                              startAt={hurdleQuiz.question.offset}
                            />
                          )}
                        </Col>
                      </Row>
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Col>
            <Col
              style={{
                maxHeight: 'calc(100vh - 5.875rem)',
                overflow: 'scroll',
              }}
              className="col-7"
            >
              <Tab.Content className="h-100">
                <Card>
                  <Card.Body>
                    {hurdleQuizId && hurdleQuizId !== ':id' && (
                      <HurdleQuizData
                        key={hurdleQuizId}
                        hurdleQuizId={hurdleQuizId}
                        refetchCalendar={refetchCalendar}
                      />
                    )}
                  </Card.Body>
                </Card>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      }
    </>
  );
};

export default HurdleQuiz;
