export type SUPPORTED_LOCALE =
  | 'de-DE'
  | 'en-AU'
  | 'en-CA'
  | 'en-GB'
  | 'en-US'
  | 'es-AR'
  | 'es-ES'
  | 'es-MX'
  | 'fr-FR'
  | 'it-IT'
  | 'nl-NL'
  | 'pl-PL'
  | 'pt-BR'
  | 'sv-SE'
  | 'tr-TR';

export const FLAGS: Record<SUPPORTED_LOCALE, string> = {
  'de-DE': '🇩🇪',
  'en-AU': '🇦🇺',
  'en-CA': '🇨🇦',
  'en-GB': '🇬🇧',
  'en-US': '🇺🇸',
  'es-AR': '🇦🇷',
  'es-ES': '🇪🇸',
  'es-MX': '🇲🇽',
  'fr-FR': '🇫🇷',
  'it-IT': '🇮🇹',
  'nl-NL': '🇳🇱',
  'pl-PL': '🇵🇱',
  'pt-BR': '🇧🇷',
  'sv-SE': '🇸🇪',
  'tr-TR': '🇹🇷',
};

export const ALL_LOCALES: SUPPORTED_LOCALE[] = [
  'de-DE',
  'en-AU',
  'en-CA',
  'en-GB',
  'en-US',
  'es-AR',
  'es-ES',
  'es-MX',
  'fr-FR',
  'it-IT',
  'nl-NL',
  'pl-PL',
  'pt-BR',
  'sv-SE',
  'tr-TR',
];

export const displayLocaleWithFlag = (locale: SUPPORTED_LOCALE) => {
  return `${FLAGS[locale]} ${locale}`;
};
