import { HTMLAttributes } from 'react';
import Badge from 'react-bootstrap/Badge';
import { CurationContentPackCustomField } from '../../../../__gqltypes__';
import EditComponent from './EditComponent';
import PlayButton from './PlayButton';
import UploadSong, { CurationSong, SongSubmitFn } from './UploadSong';

export type StringSubmitFn = (value: string) => Promise<void>;

type StringProps = {
  field: string | undefined | null;
  fieldType: Exclude<
    CurationContentPackCustomField,
    CurationContentPackCustomField.INTRO_SAMPLE | CurationContentPackCustomField.CUSTOM_SAMPLE
  >;
  forcedFields: CurationContentPackCustomField[];
  onSubmit: StringSubmitFn;
  song?: CurationSong;
};

type SubmitPayloadProps = {
  field: string | undefined | null;
  fieldType: CurationContentPackCustomField.INTRO_SAMPLE | CurationContentPackCustomField.CUSTOM_SAMPLE;
  forcedFields: CurationContentPackCustomField[];
  onSubmit: SongSubmitFn;
  song?: CurationSong;
};

type Props = (StringProps | SubmitPayloadProps) & Omit<HTMLAttributes<HTMLElement>, 'onSubmit'>;

export default function CustomField({ field, fieldType, forcedFields, onSubmit, song }: Props) {
  if (!forcedFields || !forcedFields.includes(fieldType)) {
    return null;
  }

  switch (fieldType) {
    case CurationContentPackCustomField.CUSTOM_SAMPLE:
      return (
        <div>
          <hr />
          {'Playlist Custom Sample: '}
          <PlayButton sample={field} />
          <UploadSong className="ml-4" onSubmit={onSubmit as SongSubmitFn} title="Edit Custom Sample" song={song} />
          {!field && (
            <Badge className="m-1" pill variant="danger">
              invalid
            </Badge>
          )}
        </div>
      );
    case CurationContentPackCustomField.INTRO_SAMPLE:
      return (
        <div>
          <hr />
          {'Intro: '}
          <PlayButton sample={field} />
          <UploadSong onSubmit={onSubmit as SongSubmitFn} title="Edit Custom Sample" song={song} />
          {!field && (
            <Badge className="m-1" pill variant="danger">
              invalid
            </Badge>
          )}
        </div>
      );
    case CurationContentPackCustomField.MOVIE_TITLE:
      return (
        <div>
          {'Movie Title: '}
          <EditComponent onSubmit={onSubmit as StringSubmitFn} value={field} />
          {!field && (
            <Badge className="m-1" pill variant="danger">
              invalid
            </Badge>
          )}
        </div>
      );
    case CurationContentPackCustomField.LEAD_SINGER:
      return (
        <div>
          {'Lead Singer: '}
          <EditComponent onSubmit={onSubmit as StringSubmitFn} value={field} />
          {!field && (
            <Badge className="m-1" pill variant="danger">
              invalid
            </Badge>
          )}
        </div>
      );

    case CurationContentPackCustomField.JESTER_TITLE:
      return (
        <div>
          {'Jester Title: '}
          <EditComponent onSubmit={onSubmit as StringSubmitFn} value={field} />
          {!field && (
            <Badge className="m-1" pill variant="danger">
              invalid
            </Badge>
          )}
        </div>
      );

    case CurationContentPackCustomField.COMPOSER:
      return (
        <div>
          {'Composer: '}
          <EditComponent onSubmit={onSubmit as StringSubmitFn} value={field} />
          {!field && (
            <Badge className="m-1" pill variant="danger">
              invalid
            </Badge>
          )}
        </div>
      );

    case CurationContentPackCustomField.CUSTOM:
      return (
        <div>
          {'Playlist custom display: '}
          <EditComponent onSubmit={onSubmit as StringSubmitFn} value={field} />
          {!field && (
            <Badge className="m-1" pill variant="danger">
              invalid
            </Badge>
          )}
        </div>
      );
    case CurationContentPackCustomField.FULL_CUSTOM: {
      const { title, artist } = field ? JSON.parse(field) : { title: null, artist: null };

      const onSubmitTitle = (_title: string) => {
        if (!field) {
          // @ts-ignore
          return onSubmit(JSON.stringify({ title: _title }));
        }
        // @ts-ignore
        return onSubmit(JSON.stringify({ ...JSON.parse(field), ...{ title: _title } }));
      };
      const onSubmitArtist = (_artist: string) => {
        if (!field) {
          // @ts-ignore
          return onSubmit(JSON.stringify({ artist: _artist }));
        }
        // @ts-ignore
        return onSubmit(JSON.stringify({ ...JSON.parse(field), ...{ artist: _artist } }));
      };

      return (
        <div>
          {'Playlist custom title display: '}
          <EditComponent onSubmit={onSubmitTitle as StringSubmitFn} value={title} />
          <br />
          {'Playlist custom artist display: '}
          <EditComponent onSubmit={onSubmitArtist as StringSubmitFn} value={artist} />
          {!field && (
            <Badge className="m-1" pill variant="danger">
              invalid
            </Badge>
          )}
        </div>
      );
    }
    default:
      break;
  }
  return null;
}
