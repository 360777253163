import moment from 'moment';
import * as React from 'react';
import { Button, Col, Modal, ModalProps, Row, Spinner } from 'react-bootstrap';
import DateTimePicker from 'react-datetime';
import { applyChangeIfDateValid, validIfSameDayOrAfter } from '../../../../../../utils/datepicker';

type Props = {
  onCopy: (startDate: number | Date) => void;
  loading: boolean;
} & ModalProps;

const CopyLiveShowModal = ({ show, onHide, onCopy, loading }: Props) => {
  const [startDate, setStartDate] = React.useState(moment().startOf('minute').add(6, 'minutes').toDate());
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Copy the show</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col xs={3}>Start date</Col>
          <Col xs={6}>
            <DateTimePicker
              onChange={applyChangeIfDateValid(setStartDate)}
              isValidDate={validIfSameDayOrAfter}
              initialValue={startDate}
            />
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        {!loading && (
          <Button variant="secondary" onClick={onHide}>
            Cancel
          </Button>
        )}
        {loading ? (
          <Spinner animation="border" />
        ) : (
          <Button variant="primary" onClick={() => onCopy(startDate)}>
            Copy show
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default CopyLiveShowModal;
