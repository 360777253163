import { ROLE } from '../../../../../../config';
import { ViewMap } from '../../../../../../types';
import ABTestSpecificStudies from './ABTestSpecificStudies';
import AppInstallABTest from './AppInstallABTest';
import Demographics from './Demographics';
import Economy from './Economy';
import Festival from './Festival';
import Tuneology from './Tuneology';
import GameDeepDive from './GameDeepDive';
import Marketing from './Marketing';
import Performance from './Performance';
import Playlists from './Playlists';
import SongPopParty from './SongPopParty';
import Usage from './Usage';
import UserABTest from './UserABTest';

// const TempContainer = (Cont) => {
//   if (process.env.REACT_APP_NAMESPACE === 'test') {
//     return Cont;
//   }
//   return () => <div>Dashboards disabled until costs are under control</div>;
// };

const views: { sp3: ViewMap } = {
  sp3: {
    Usage: {
      component: Usage,
      permission: [ROLE.ADMIN, ROLE.DEVELOPER, ROLE.DASHBOARD],
      title: 'Usage',
    },
    AppInstallABTest: {
      component: AppInstallABTest,
      permission: [ROLE.ADMIN, ROLE.DEVELOPER, ROLE.DASHBOARD],
      title: 'AppInstallABTest',
    },
    UserABTest: {
      component: UserABTest,
      permission: [ROLE.ADMIN, ROLE.DEVELOPER, ROLE.DASHBOARD],
      title: 'UserABTest',
    },
    ABTestSpecificStudies: {
      component: ABTestSpecificStudies,
      permission: [ROLE.ADMIN, ROLE.DEVELOPER, ROLE.DASHBOARD],
      title: 'ABTestSpecificStudies',
    },
    Economy: {
      component: Economy,
      permission: [ROLE.ADMIN, ROLE.DEVELOPER, ROLE.DASHBOARD],
      title: 'Economy',
    },
    GameDeepDive: {
      component: GameDeepDive,
      permission: [ROLE.ADMIN, ROLE.DEVELOPER, ROLE.DASHBOARD],
      title: 'Game Deep Dive',
    },
    Performance: {
      component: Performance,
      permission: [ROLE.ADMIN, ROLE.DEVELOPER, ROLE.DASHBOARD],
      title: 'Performance',
    },
    SongPopParty: {
      component: SongPopParty,
      permission: [ROLE.ADMIN, ROLE.DEVELOPER, ROLE.DASHBOARD],
      title: 'SongPopParty',
    },
    Demographics: {
      component: Demographics,
      permission: [ROLE.ADMIN, ROLE.DEVELOPER, ROLE.DASHBOARD],
      title: 'Demographics',
    },
    Playlists: {
      component: Playlists,
      permission: [ROLE.ADMIN, ROLE.DEVELOPER, ROLE.DASHBOARD],
      title: 'Playlists',
    },
    Marketing: {
      component: Marketing,
      permission: [ROLE.ADMIN, ROLE.DEVELOPER, ROLE.DASHBOARD],
      title: 'Marketing',
    },
    Festival: {
      component: Festival,
      permission: [ROLE.ADMIN, ROLE.DEVELOPER, ROLE.DASHBOARD],
      title: 'Festival',
    },
    Tuneology: {
      component: Tuneology,
      permission: [ROLE.ADMIN, ROLE.DEVELOPER, ROLE.DASHBOARD],
      title: 'Tuneology',
    },
  },
};

export default views;
