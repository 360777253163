import { gql } from '@apollo/client';
import { MISSION_FRAGMENT } from '../../components/apolloQueries';

/**
 * FRAGMENT
 */
const SPECIAL_EVENT_FRAGMENT = gql`
  fragment SpecialEventFragment on SpecialEvent {
    id
    name
    description
    startDate
    endDate
    isDraft
    assetsConfig {
      primaryColor
      secondaryColor
      secondaryDarkColor
      buttonColor
      contrast
      contrastGradient1
      contrastGradient2
      headerImage
      backgroundImage
      icon
    }
    configWarnings
    missions {
      id
      sequentialMissions {
        ...MissionFragment
      }
    }
    tournaments {
      id
      startDate
    }
    quizLiveShows {
      id
      startDate
    }
    contentPacks {
      id
      name
      popularity
      explicitContentStatus
    }
    milestones {
      id
      score
      rewards {
        ... on Currency {
          amount
          __typename
        }
        ... on CurrencyCategoryFragment {
          amount
          category {
            id
            icon
            name
          }
        }
        ... on CurrencyBoost {
          amount
          boostType
          __typename
        }
        ... on Item {
          __typename
          id
        }
        ... on MonthlyBuff {
          __typename
          multiplier
          buffType
        }
        ... on ContentPackReward {
          __typename
          contentPack {
            id
            name
          }
        }
      }
    }
    plusMilestones {
      id
      score
      rewards {
        ... on Currency {
          amount
          __typename
        }
        ... on CurrencyCategoryFragment {
          amount
          category {
            id
            icon
            name
          }
        }
        ... on CurrencyBoost {
          amount
          boostType
          __typename
        }
        ... on Item {
          __typename
          id
        }
        ... on MonthlyBuff {
          __typename
          multiplier
          buffType
        }
        ... on ContentPackReward {
          __typename
          contentPack {
            id
            name
          }
        }
      }
    }
    rankGroups {
      id
      minRank
      maxRank
      rewards {
        ... on Currency {
          amount
          __typename
        }
        ... on CurrencyCategoryFragment {
          amount
          category {
            id
            icon
            name
          }
        }
        ... on CurrencyBoost {
          amount
          boostType
          __typename
        }
        ... on Item {
          __typename
          id
        }
        ... on MonthlyBuff {
          __typename
          multiplier
          buffType
        }
        ... on ContentPackReward {
          __typename
          contentPack {
            id
            name
          }
        }
      }
    }
  }

  ${MISSION_FRAGMENT}
`;

/**
 * QUERIES
 */
export const GET_SPECIAL_EVENTS = gql`
  query specialEvents($pagination: PaginationParams) {
    liveOps {
      specialEvents(pagination: $pagination) {
        totalCount
        hasMore
        list {
          id
          startDate
          name
        }
      }
    }
  }
`;

export const GET_SPECIAL_EVENT = gql`
  query specialEvent($specialEventId: ID!) {
    specialEvent(specialEventId: $specialEventId) {
      ...SpecialEventFragment
    }
  }
  ${SPECIAL_EVENT_FRAGMENT}
`;

/**
 * MUTATION
 */
export const UPDATE_SPECIAL_EVENT = gql`
  mutation updateSpecialEvent($input: UpdateSpecialEventInput!) {
    updateSpecialEvent(input: $input) {
      specialEvent {
        ...SpecialEventFragment
      }
    }
  }
  ${SPECIAL_EVENT_FRAGMENT}
`;

export const UPLOAD_SPECIAL_EVENT_ASSET = gql`
  mutation updateSpecialEventAsset($input: UploadSpecialEventAssetInput!) {
    uploadSpecialEventAsset(input: $input) {
      specialEvent {
        ...SpecialEventFragment
      }
    }
  }
  ${SPECIAL_EVENT_FRAGMENT}
`;

export const UPDATE_SPECIAL_EVENT_MILESTONES = gql`
  mutation updateSpecialEventMilestones($input: UpdateSpecialEventMilestonesInput!) {
    updateSpecialEventMilestones(input: $input) {
      specialEvent {
        ...SpecialEventFragment
      }
    }
  }
  ${SPECIAL_EVENT_FRAGMENT}
`;

export const UPDATE_SPECIAL_EVENT_PLUS_MILESTONES = gql`
  mutation updateSpecialEventPlusMilestones($input: UpdateSpecialEventPlusMilestonesInput!) {
    updateSpecialEventPlusMilestones(input: $input) {
      specialEvent {
        ...SpecialEventFragment
      }
    }
  }
  ${SPECIAL_EVENT_FRAGMENT}
`;

export const UPDATE_SPECIAL_EVENT_RANKGROUPS = gql`
  mutation updateSpecialEventRankGroups($input: UpdateSpecialEventRankGroupsInput!) {
    updateSpecialEventRankGroups(input: $input) {
      specialEvent {
        ...SpecialEventFragment
      }
    }
  }
  ${SPECIAL_EVENT_FRAGMENT}
`;

export const UPDATE_SPECIAL_EVENT_CONTENT_PACKS = gql`
  mutation updateSpecialEventContentPacks($input: UpdateSpecialEventContentPacksInput!) {
    updateSpecialEventContentPacks(input: $input) {
      specialEvent {
        ...SpecialEventFragment
      }
    }
  }
  ${SPECIAL_EVENT_FRAGMENT}
`;

export const CREATE_SPECIAL_EVENT = gql`
  mutation createSpecialEvent($input: CreateSpecialEventInput!) {
    createSpecialEvent(input: $input) {
      specialEvent {
        ...SpecialEventFragment
      }
    }
  }
  ${SPECIAL_EVENT_FRAGMENT}
`;

export const REMOVE_SPECIAL_EVENT = gql`
  mutation removeSpecialEvent($input: RemoveSpecialEventInput!) {
    removeSpecialEvent(input: $input) {
      isRemoved
    }
  }
`;

export const INIT_SPECIAL_EVENT_MISSIONS = gql`
  mutation initSpecialEventMissions($input: InitSpecialEventMissionsInput!) {
    initSpecialEventMissions(input: $input) {
      missions {
        id
        sequentialMissions {
          ...MissionFragment
        }
      }
    }
  }
  ${MISSION_FRAGMENT}
`;

export const UPDATE_SPECIAL_EVENT_MISSIONS = gql`
  mutation updateSpecialEventMissions($input: UpdateSpecialEventMissionsInput!) {
    updateSpecialEventMissions(input: $input) {
      missions {
        id
        sequentialMissions {
          ...MissionFragment
        }
      }
    }
  }
  ${MISSION_FRAGMENT}
`;

export const ADD_SPECIAL_EVENT_CONTENT_PACK = gql`
  mutation addSpecialEventContentPack($input: AddSpecialEventContentPackInput!) {
    addSpecialEventContentPack(input: $input) {
      specialEvent {
        id
        name
      }
      contentPack {
        id
        name
      }
    }
  }
`;

export const REMOVE_SPECIAL_EVENT_CONTENT_PACK = gql`
  mutation removeSpecialEventContentPack($input: RemoveSpecialEventContentPackInput!) {
    removeSpecialEventContentPack(input: $input) {
      specialEvent {
        id
        name
      }
      contentPack {
        id
        name
      }
    }
  }
`;
