import { sortBy } from 'lodash';
import * as React from 'react';

import { CurationSongDatabaseSearchQuery, ItunesSong } from '../../../../../../__gqltypes__';

type CurationSongInput = CurationSongDatabaseSearchQuery['curationSongDatabaseSearch'][0];

const SORTING = {
  TITLE: (song: ItunesSong) => song.title,
  ARTIST: (song: ItunesSong) => song.artist,
  ALBUM: (song: ItunesSong) => song.album,
  LABEL: (song: ItunesSong) => song.label + song.subLabel,
  WHITELISTED: (song: ItunesSong) => Number(!song.isWhitelisted),
};

const SONG_INPUT_SORTING = {
  TITLE: ({ curationSong: song }: CurationSongInput) => song?.title,
  ARTIST: ({ curationSong: song }: CurationSongInput) => song?.artist,
  ALBUM: ({ curationSong: song }: CurationSongInput) => song?.album,
  LABEL: ({ curationSong: song }: CurationSongInput) => (song ? song.label + song.subLabel : null),
  WHITELISTED: ({ curationSong: song }: CurationSongInput) => song?.state === 'WHITELISTED',
};

type State<T> = {
  orderedSongs: T[];
  orderBy: string | null;
  ascending: boolean;
  isSongInput: boolean;
};

const reducer = (state: State<any>, { type, payload }: { type: string; payload: any }) => {
  if (type === 'RESET') {
    return {
      orderBy: null,
      ascending: true,
      // @ts-ignore
      orderedSongs: payload,
      isSongInput: state.isSongInput,
    };
  }

  if (payload === state.orderBy) {
    return {
      orderedSongs: state.orderedSongs.slice().reverse(),
      ascending: !state.ascending,
      orderBy: state.orderBy,
      isSongInput: state.isSongInput,
    };
  }

  return {
    // @ts-ignore
    orderedSongs: sortBy(state.orderedSongs, state.isSongInput ? SONG_INPUT_SORTING[payload] : SORTING[payload]),
    ascending: true,
    orderBy: payload,
    isSongInput: state.isSongInput,
  };
};

export default <T>(isSongInput = false) => {
  const [{ orderedSongs, orderBy, ascending }, changeOrder] = React.useReducer<React.Reducer<State<T>, any>>(reducer, {
    orderedSongs: [],
    orderBy: null,
    ascending: true,
    isSongInput,
  });

  const handleChangeOrder = (key: 'TITLE' | 'ARTIST' | 'ALBUM' | 'LABEL' | 'WHITELISTED') => {
    changeOrder({ type: 'SORT', payload: key });
  };

  return { orderedSongs, orderBy, ascending, changeOrder, handleChangeOrder };
};
