import { SlicingClause } from './slicingClauses';

export const FILTERS_URL = '/sql/filters';

/**
 * Object containing the fields of a filter.
 * The keys must match with the ids of the components of the slicer used for the dashboard
 * @typedef {Record<string, string>} FilterFields
 */
type FilterFields = Record<string, string>;

/**
 * A filter object, used to define queries
 * @typedef {Object} Filter
 * @property {string} path The path of the sql file
 * @property {string} join The type of join (inner join, left outer join...)
 * @property {string} query Instead of giving the path to a sql file, the query can be defined here
 * @property {FilterFields} fields If a path to a file is given,
 * these are the fields to fill the Mustache template
 * @property {string} name The name of the filter query
 * @property {boolean} hasDayJoin If the filter need to be joined
 * on the day in addition to the userId
 */
export type Filter = {
  path?: string;
  join: string;
  query?: string;
  fields: FilterFields;
  name: string;
  hasDayJoin: boolean;
};

/**
 * A recursive object describing the organisation of the nested filters
 * @typedef {Object} FilterTree
 * @property {FilterTree | Filter} left
 * @property {FilterTree | Filter} right
 */

type FilterTree = {
  left: FilterTree | Filter;
  right?: FilterTree | Filter;
};

/**
 * Creates a join sql statement to append to the base query
 * @param {Filter} filter The filter object
 * @param {string} previousTableName The name of the table to join on
 * @param {boolean} dayJoin If `true`, the join will be on the day in addition to the user ID
 */
const designJoinQuery = (filter: Filter, previousTableName: string, dayJoin: boolean) => {
  let result = `${filter.join} (
        ${filter.query}
    ) ${filter.name}
    on ${previousTableName}.userId = ${filter.name}.userId`;
  if (dayJoin) {
    result = `${result} and ${previousTableName}.day = ${filter.name}.day`;
  }
  return result;
};

/**
 * Creates a suitable tree structure that describes the way the filters are nested
 * @param {Record<string, Filter>} filters Object containing filters
 */
const createFilterStructure = (filters: Record<string, Filter>) => {
  let filterArray: (Filter | FilterTree)[] = [];
  Object.values(filters).forEach((filter) => {
    if (filter.join === 'left outer join') {
      filterArray.push(filter);
    } else {
      filterArray.unshift(filter);
    }
  });

  while (filterArray.length > 2) {
    const newFilterArray = [];
    for (let i = 0; i < filterArray.length; i += 2) {
      if (filterArray[i + 1]) {
        newFilterArray.push({
          left: filterArray[i],
          right: filterArray[i + 1],
        });
      } else {
        newFilterArray.push({
          left: filterArray[i],
        });
      }
    }
    filterArray = newFilterArray;
  }

  if (filterArray.length === 1) {
    return {
      left: filterArray[0],
    };
  }
  return {
    left: filterArray[0],
    right: filterArray[1],
  };
};

/**
 * Function to merge two filters into one
 * @param {Filter} left The left filter
 * @param {Filter} right The right filter
 */
const mergeFilters = (left: Filter, right: Filter): Filter => {
  let query = `select ${left.name}.userId userId, ${left.hasDayJoin ? left.name : right.name}.day day`;

  Object.keys(left.fields).forEach((leftField) => {
    query = query.concat(`{{${leftField}}}`);
  });

  Object.keys(right.fields).forEach((rightField) => {
    query = query.concat(`{{${rightField}}}`);
  });

  query = query.concat(`
  from (
    ${left.query}
  ) ${left.name}
  ${designJoinQuery(right, left.name, left.hasDayJoin && right.hasDayJoin)}`);

  const newFilter = {
    join: left.join,
    name: left.name + right.name,
    fields: { ...left.fields, ...right.fields },
    query,
    hasDayJoin: left.hasDayJoin || right.hasDayJoin,
  };
  return newFilter;
};

/**
 * Assembles all the filter queries in one query
 * @param {FilterTree} filterTree The filter structure created by `createFilterStructure`
 * @see createFilterStructure
 */
const assembleSubqueries = (filterTree: FilterTree): Filter => {
  const { left, right } = filterTree;

  if (!right) {
    // The tree only has one branch (happens when the number of filters is odd)
    return left as Filter;
  }

  if (!('left' in left)) {
    // The children are filters => we merge them
    return mergeFilters(left, right as Filter);
  }

  // Both the left and the right children are trees => recursion
  return assembleSubqueries({
    left: assembleSubqueries(left),
    right: assembleSubqueries(right as FilterTree),
  });
};

/**
 * Returns the default filters
 */
export const DEFAULT_FILTERS: Record<string, Filter> = {
  dau: {
    path: `${FILTERS_URL}/dauQuery.sql`,
    name: 'dau',
    join: 'left outer join',
    fields: {
      appVersion: ', appVersion',
      daysSinceSp2Install: ', daysSinceSp2Install',
      country: ', country',
      platform: ', platform_os',
    },
    hasDayJoin: true,
  },
  dataloaded: {
    path: `${FILTERS_URL}/dataloadedQuery.sql`,
    name: 'dataloaded',
    join: 'left outer join',
    fields: { userType: ', newSp2UserCategory', centsSpent: ', sessionStartNbCentsSpentLifetime' },
    hasDayJoin: true,
  },
  vip: {
    path: `${FILTERS_URL}/vipQuery.sql`,
    name: 'vip',
    join: 'left outer join',
    fields: { vipStatus: ', vipStatus' },
    hasDayJoin: true,
  },
  party: {
    path: `${FILTERS_URL}/partyQuery.sql`,
    name: 'party',
    join: 'left outer join',
    fields: { partyPlayer: ', lastPartyDay' },
    hasDayJoin: true,
  },
  source: {
    path: `${FILTERS_URL}/sourceQuery.sql`,
    name: 'source',
    join: 'left outer join',
    fields: { sourceRef: ', source_ref' },
    hasDayJoin: false,
  },
};

/**
 * Creates a sql statement to append to the main query based on a group of filters
 * @param {{ [id: string]: Filter}} filters The filters to create the query from
 */
export const createFilterSubquery = (filters: Record<string, Filter>) => {
  const filterStructure = createFilterStructure(filters);
  const finalFilter = assembleSubqueries(filterStructure);
  const query = finalFilter ? designJoinQuery(finalFilter, 'main', finalFilter.hasDayJoin) : '';
  return query;
};

/**
 * Gets the paths of the filter subqueries that are useful according to `slicing`
 * @param {Record<string, Filter>} filters The filter object
 * @param {Record<string, SlicingClause>} slicing The slicing object
 */
export const getFilterSubqueriesPaths =
  (filters: Record<string, Filter>, slicing: Record<string, SlicingClause>) =>
  (input: Record<string, string>): Record<string, string> => {
    const filterSubqueriesPaths: Record<string, string> = {};
    if (filters) {
      Object.keys(filters).forEach((key) => {
        const filter = filters[key];

        let needed = false;
        Object.keys(filter.fields).forEach((field) => {
          if (slicing[field]) {
            const value = input[field];

            // if we selected "all", value would be undefined
            if (!value) {
              delete filter.fields[field];
            } else if (filter.path) {
              filterSubqueriesPaths[key] = filter.path;
              needed = true;
            }
          } else {
            delete filter.fields[field];
          }
        });
        // eslint-disable-next-line no-param-reassign
        if (!needed) delete filters[key];
      });
    }
    return filterSubqueriesPaths;
  };

/**
 * Creates a sql statement to append to the query based on the input data from the slicers
 * @param {Record<string, SlicingClause>} slicing The slicing object
 */
export const buildFilters =
  (slicing: Record<string, SlicingClause>) =>
  (input: Record<string, string>, selector: string, noDay = false) => {
    const clauses: string[] = [];

    Object.keys(slicing).forEach((key) => {
      const slicer = slicing[key];
      const value = input[key];
      if (value) {
        let clause = slicer(value);
        if (clause) {
          if (noDay) {
            clause = clause.replace(/main\.day/g, 'day');
          }
          clauses.push(clause);
        }
      }
    });

    if (clauses.length > 0) {
      return `${selector}  ${clauses.join(' and ')}`;
    }

    return '';
  };
