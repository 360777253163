import { useQuery } from '@apollo/client';
import { useCallback, useMemo, useState } from 'react';
import { Button, Form, ListGroup, Modal, Spinner } from 'react-bootstrap';
import { GetContentPackListQuery, GetContentPackListsQuery } from '../../../../../__gqltypes__';
import queries from '../../utils/queries';

type ContentPackList = NonNullable<GetContentPackListQuery["contentPackList"]>["contentPacks"]
type Props = {
  doImport: (list:ContentPackList) => void;
};

const ImportContentPackList = ({ doImport }: Props) => {
  const { data: listsData } = useQuery<GetContentPackListsQuery>(queries.GET_CONTENT_PACK_LISTS, {
    fetchPolicy: 'no-cache',
  });
  const lists = useMemo(() => {
    if (!listsData) {
      return null;
    }
    return listsData.contentPackLists.list.map((list) => ({
      id: list.contentPackListId,
      name: list.name,
    }));
  }, [listsData]);

  const [importing, setImporting] = useState(false);
  const askImport = useCallback(() => setImporting(true), [setImporting]);
  const cancelImport = useCallback(() => setImporting(false), [setImporting]);

  const [curListId, setCurListId] = useState('');

  const { data: curListData, loading: loadingList } = useQuery<GetContentPackListQuery>(queries.GET_CONTENT_PACK_LIST, {
    skip: !curListId,
    fetchPolicy: 'cache-and-network',
    variables: { id: curListId },
  });
  const curList = useMemo(() => curListData?.contentPackList?.contentPacks ?? [], [curListData]);

  const importCurList = useCallback(() => {
    doImport(curList);
    setImporting(false);
  }, [curList, doImport]);

  return (
    <>
      <Button variant="info" onClick={askImport} className="ml-1">
        <i className="fas fa-download mr-2" />
        Import from predefined list
      </Button>

      <Modal show={importing} onHide={cancelImport} className="cp-list">
        <Modal.Header closeButton>
          <Modal.Title>Import from predefined list</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Control as="select" value={curListId} onChange={(e) => setCurListId(e.target.value)}>
              <option value="">Choose a content pack list</option>
              {lists &&
                lists.map((list) => (
                  <option key={list.id} value={list.id}>
                    {list.name}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>
          {curListId !== '' &&
            (loadingList ? (
              <div className="p-3">
                <Spinner animation="border" />
              </div>
            ) : (
              <ListGroup className="mb-3">
                {curList.length > 0 ? (
                  curList.map((pack) => (
                    // @ts-ignore
                    <ListGroup.Item key={pack.id} value={pack.id} className="py-1 px-2">
                      {pack.name}
                    </ListGroup.Item>
                  ))
                ) : (
                  <ListGroup.Item className="py-1 px-2" variant="dark">
                    This list contains no content packs.
                  </ListGroup.Item>
                )}
              </ListGroup>
            ))}
          <p>
            Warning: This will <b>replace</b> the current content packs. To revert, refresh the page without clicking
            &quot;Save&quot;.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cancelImport}>
            Cancel
          </Button>
          <Button variant="info" type="submit" onClick={importCurList} disabled={curList === undefined}>
            Import
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ImportContentPackList;
