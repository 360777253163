import { MutationHookOptions, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import ListGroup from 'react-bootstrap/ListGroup';
import Media from 'react-bootstrap/Media';
import Row from 'react-bootstrap/Row';
import { LinkContainer } from 'react-router-bootstrap';
import type {
  ContentPacksQuery,
  CreateCurationContentPackByArtistsMutation,
  CreateCurationContentPackByArtistsMutationVariables,
  CreateCurationContentPackByCsvMutation,
  CreateCurationContentPackByCsvMutationVariables,
  CreateCurationContentPackByUrlMutation,
  CreateCurationContentPackByUrlMutationVariables,
  CreateCurationContentPackFromSp2Mutation,
  CreateCurationContentPackFromSp2MutationVariables,
} from '../../../../../__gqltypes__';
import Loader from '../../components/Loader';
import Pagination from '../../components/Pagination';
import PlaylistsSidebar from '../../components/PlaylistsSidebar';
import PlaylistState from '../../components/PlaylistState';
import SearchBar from '../../components/SearchBar';
import AddPlaylistPopup from '../../popups/AddPlaylistPopup';
import { AddPlaylistValues } from '../../popups/utils';
import { downloadCsvFile } from '../../utils/csvExport';
import mutations from '../../utils/mutations';
import queries from '../../utils/queries';
import { InputType } from '../PlaylistInfo/utils';
import './Playlists.css';

const createByUrlOptions: MutationHookOptions<
  CreateCurationContentPackByUrlMutation,
  CreateCurationContentPackByUrlMutationVariables
> = {
  // @ts-ignore
  update: (proxy, { data: { createCurationContentPackByUrl } }) => {
    // const cached = proxy.readQuery({ query: queries.CURATION_CONTENT_PACKS, variables: { offset: 0 } });
    // console.log('cached', cached, createCurationContentPackByUrl);
    // cached.curation.contentPacks.list = [
    //   createCurationContentPackByUrl.curationContentPack,
    //   ...cached.curation.contentPacks.list,
    // ]; // push to front!
    // console.log('pushing', cached);
    // cached.curation.contentPacks.totalCount++;
    // console.log('writing', cached);
    // proxy.writeQuery({ query: queries.CURATION_CONTENT_PACKS, data: cached, variables: { offset: 0 } });
  },
};

const createByCsvOptions: MutationHookOptions<
  CreateCurationContentPackByCsvMutation,
  CreateCurationContentPackByCsvMutationVariables
> = {
  // @ts-ignore
  update: (proxy, { data: { createCurationContentPackByCSV } }) => {
    // const cached = proxy.readQuery({ query: queries.CURATION_CONTENT_PACKS, variables: { offset: 0 } });
    // push to front!
    // cached.curation.contentPacks.list.unshift(createCurationContentPackByCSV.curationContentPack);
    // cached.curation.contentPacks.totalCount++;
    // proxy.writeQuery({ query: queries.CURATION_CONTENT_PACKS, data: cached });
  },
};

const createFromSp2Options: MutationHookOptions<
  CreateCurationContentPackFromSp2Mutation,
  CreateCurationContentPackFromSp2MutationVariables
> = {
  // @ts-ignore
  update: (proxy, { data: { createCurationContentPackFromSp2 } }) => {
    // const cached = proxy.readQuery({ query: queries.CURATION_CONTENT_PACKS, variables: { offset: 0 } });
    // push to front!
    // cached.curation.contentPacks.list.unshift(createCurationContentPackFromSp2.curationContentPack);
    // cached.curation.contentPacks.totalCount++;
    // proxy.writeQuery({ query: queries.CURATION_CONTENT_PACKS, data: cached });
  },
};

const createByArtistsOptions: MutationHookOptions<
  CreateCurationContentPackByArtistsMutation,
  CreateCurationContentPackByArtistsMutationVariables
> = {
  // @ts-ignore
  update: (proxy, { data: { createCurationContentPackByArtists } }) => {
    // const cached = proxy.readQuery({ query: queries.CURATION_CONTENT_PACKS, variables: { offset: 0 } });
    // push to front!
    // cached.curation.contentPacks.list.unshift(createCurationContentPackByArtists.curationContentPack);
    // cached.curation.contentPacks.totalCount++;
    // proxy.writeQuery({ query: queries.CURATION_CONTENT_PACKS, data: cached });
  },
};

type Props = {
  match: { params: { id: string; version: string } };
  history: any;
};

const Playlists = ({ match, history }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [loadingCsvExport, setLoadingCsvExport] = useState(false);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  console.log('match', params);

  const [page, setPage] = useState(parseInt(params.page ?? 1, 10));

  const {
    loading: contentPacksLoading,
    data: contentPacksData,
    refetch: refetchContentPacks,
  } = useQuery<ContentPacksQuery>(queries.CURATION_CONTENT_PACKS, {
    variables: { offset: (page - 1) * 100 },
    notifyOnNetworkStatusChange: true,
  });
  const [
    fetchContentPacksByName,
    {
      loading: contentPacksByNameLoading,
      data: contentPacksByNameData,
      refetch: refetchContentPacksByName,
      called: contentPacksByNameCalled,
    },
  ] = useLazyQuery(queries.CURATION_CONTENT_PACKS_BY_NAME, {
    notifyOnNetworkStatusChange: true,
  });
  const { refetch: fetchCsvExport } = useQuery(queries.CONTENT_PACKS_CSV_EXPORT, {
    skip: true,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  const [createCurationContentPackByUrl, { loading: createByUrlLoading }] = useMutation<
    CreateCurationContentPackByUrlMutation,
    CreateCurationContentPackByUrlMutationVariables
  >(mutations.CREATE_CURATION_CONTENT_PACK_BY_URL, createByUrlOptions);
  const [createCurationContentPackByCSV, { loading: createByCsvLoading }] = useMutation<
    CreateCurationContentPackByCsvMutation,
    CreateCurationContentPackByCsvMutationVariables
  >(mutations.CREATE_CURATION_CONTENT_PACK_BY_CSV, createByCsvOptions);
  const [createCurationContentPackFromSp2, { loading: createFromSp2Loading }] = useMutation<
    CreateCurationContentPackFromSp2Mutation,
    CreateCurationContentPackFromSp2MutationVariables
  >(mutations.CREATE_CURATION_CONTENT_PACK_FROM_SP2, createFromSp2Options);
  const [createCurationContentPackByArtists, { loading: createByArtistsLoading }] = useMutation<
    CreateCurationContentPackByArtistsMutation,
    CreateCurationContentPackByArtistsMutationVariables
  >(mutations.CREATE_CURATION_CONTENT_PACK_BY_ARTISTS, createByArtistsOptions);

  const mutationLoading = createByCsvLoading || createByUrlLoading || createFromSp2Loading || createByArtistsLoading;

  const openSpotifyPlaylist = () => setShowModal(true);
  const closeSpotifyPlaylist = () => setShowModal(false);

  const onAddPlaylist = (inputType: keyof typeof InputType, payload: AddPlaylistValues) => {
    console.log('adding playlist', payload, inputType);
    closeSpotifyPlaylist();

    if (mutationLoading) {
      return;
    }

    if (inputType === 'SPOTIFY' && payload && 'spotifyURI' in payload) {
      createCurationContentPackByUrl({ variables: { input: payload } });
    } else if (inputType === 'CSV' && payload && 'file' in payload) {
      createCurationContentPackByCSV({ variables: { input: payload } });
    } else if (inputType === 'SP2' && payload && 'sp2PlaylistId' in payload) {
      createCurationContentPackFromSp2({ variables: { input: payload } });
    } else if (inputType === 'SPOTIFY_ARTISTS' && payload && 'artistIds' in payload) {
      createCurationContentPackByArtists({ variables: { input: payload } });
    }
  };

  const onClickPagination = (index: number) => {
    const offset = (index - 1) * 100;
    refetchContentPacks({ offset });
    setPage(index);
    history.push({
      pathname: '/sp3/playlist/',
      search: `?page=${index}`,
    });

    console.log('page', index);
  };

  const onSearchPlaylist = (searchInput: string) => {
    if (searchInput.length > 0) {
      setIsSearching(true);
      if (contentPacksByNameCalled) {
        refetchContentPacksByName({ name: searchInput });
      } else {
        fetchContentPacksByName({ variables: { name: searchInput } });
      }
    } else {
      setIsSearching(false);
      handleRefresh();
    }
  };

  const loading = contentPacksLoading || contentPacksByNameLoading;

  const handleRefresh = () => {
    if (!loading) {
      refetchContentPacks();
    }
  };

  const handleCsvExport = () => {
    if (!loadingCsvExport) {
      setLoadingCsvExport(true);
      fetchCsvExport().then(({ data }) => {
        setLoadingCsvExport(false);
        downloadCsvFile(data.curation.contentPackCsvExport.content, 'playlist_summary');
      });
    }
  };

  let contentPacks = contentPacksData?.curation?.contentPacks?.list ?? [];
  if (isSearching) {
    contentPacks = contentPacksByNameData ? contentPacksByNameData.curationContentPacksByName : [];
  }

  const maxPage = contentPacksData?.curation?.contentPacks
    ? contentPacksData.curation.contentPacks.totalCount % 100 === 0
      ? Math.floor(contentPacksData.curation.contentPacks.totalCount / 100)
      : Math.floor(contentPacksData.curation.contentPacks.totalCount / 100) + 1
    : 1;

  return (
    <>
      <Row className="justify-content-between mb-2">
        <Col xl={4}>
          <h1>Playlists</h1>
          <div className="d-flex justify-content-between">
            <Button disabled={loading} onClick={handleRefresh}>
              <i className="fas fa-redo mr-2" />
              Refresh
            </Button>
            <Button className="ml-4" disabled={loading || loadingCsvExport} onClick={handleCsvExport}>
              <i className="fa fa-download mr-2" />
              {loadingCsvExport ? 'Generating CSV file...' : 'Download playlist summary CSV'}
            </Button>
          </div>
        </Col>
        <Col className="d-flex mb-2 align-items-baseline mt-4 mt-xl-0">
          <SearchBar placeholder="Playlist or artist to search" onSubmit={onSearchPlaylist} />
          <Form inline>
            <Button variant="success" disabled={mutationLoading} onClick={openSpotifyPlaylist}>
              {mutationLoading ? 'Adding...' : 'Add'}
            </Button>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col xl={4}>
          <PlaylistsSidebar />
        </Col>
        <Col>
          {loading ? (
            <Loader />
          ) : (
            <ListGroup variant="flush">
              {contentPacks.map((playlist) => (
                <LinkContainer key={playlist.id} to={`/sp3/playlist/${playlist.originId}`}>
                  <ListGroup.Item className="listgroup-small" action>
                    <Media>
                      <Image src={playlist.picture} />
                      <Media.Body>
                        {playlist.name}
                        <PlaylistState state={playlist.state || null} sp2Only={!!playlist.sp2Only} />
                      </Media.Body>
                    </Media>
                  </ListGroup.Item>
                </LinkContainer>
              ))}
            </ListGroup>
          )}
        </Col>
      </Row>

      {!isSearching && (
        <div className="d-flex justify-content-end mt-4">
          <Pagination active={page} num={maxPage} onClick={onClickPagination} />
        </div>
      )}

      <AddPlaylistPopup show={showModal} onHide={closeSpotifyPlaylist} onAddPlaylist={onAddPlaylist} />
    </>
  );
};

export default Playlists;
