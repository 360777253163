import { useLazyQuery } from '@apollo/client';
import { useState } from 'react';

import {
  CurationSongDatabaseSearchQuery,
  CurationSongDatabaseSearchQueryVariables,
  CurationSongDdexSearchQuery,
  CurationSongDdexSearchQueryVariables,
  CurationSongSearchTerm,
} from '../../../../../../__gqltypes__';
import Loader from '../../../components/Loader';
import queries from '../../../utils/queries';
import CurationSongList from './CurationSongList';
import DdexSongList from './DdexSongList';
import SearchForm, { SearchType } from './SearchForm';

type CurationSongDatabaseSearch = CurationSongDatabaseSearchQuery['curationSongDatabaseSearch'][0];
type CurationSongDDEXSearch = CurationSongDdexSearchQuery['curationSongDDEXSearch'][0];

type Props = {
  actionButton?: string;
  defaultAlbum?: string | null;
  defaultArtist?: string | null;
  defaultEndYear?: number | null;
  defaultIsrc?: string | null;
  defaultLabel?: string | null;
  defaultStartYear?: number | null;
  defaultSubLabel?: string | null;
  defaultTitle?: string | null;
  defaultType?: typeof SearchType[keyof typeof SearchType] | null;
  songSearchState?: null | 'SHOW' | 'SHOW_DDEX' | 'LOADING';
  onSearchTriggered?: (type: typeof SearchType[keyof typeof SearchType], term: CurationSongSearchTerm) => void;
  onSongSelected?: (
    song: CurationSongDatabaseSearch | CurationSongDDEXSearch,
    type: typeof SearchType[keyof typeof SearchType]
  ) => void;
};

/**
 * onSearchTriggered should only be used as a callback to get info on the current search query
 *  and should not handle any search logic.
 * Search logic is already handled by this component.
 */
export default function SongSearchContent({
  actionButton,
  defaultAlbum,
  defaultArtist,
  defaultEndYear,
  defaultIsrc,
  defaultLabel,
  defaultStartYear,
  defaultSubLabel,
  defaultTitle,
  defaultType,
  songSearchState,
  onSearchTriggered,
  onSongSelected,
}: Props) {
  const [searchSp3Songs, { loading: loadingSp3Songs, data: sp3SongsData }] = useLazyQuery<
    CurationSongDatabaseSearchQuery,
    CurationSongDatabaseSearchQueryVariables
  >(queries.SONG_DATABASE_SEARCH, { fetchPolicy: 'network-only' });
  const [searchDDEXSongs, { loading: loadingDDEXSongs, data: ddexSongsData }] = useLazyQuery<
    CurationSongDdexSearchQuery,
    CurationSongDdexSearchQueryVariables
  >(queries.SONG_DDEX_SEARCH, { fetchPolicy: 'network-only' });

  const [lastSearchType, setLastSearchType] = useState(SearchType.Database);

  const handleSearch = (
    type: typeof SearchType[keyof typeof SearchType],
    term: CurationSongSearchTerm,
    includesContentPacks = false
  ) => {
    setLastSearchType(type);

    if (type === SearchType.Database) {
      searchSp3Songs({ variables: { term, includesContentPacks: !!includesContentPacks } });
    } else if (type === SearchType.DDEX) {
      searchDDEXSongs({ variables: { term } });
    }

    if (onSearchTriggered) {
      onSearchTriggered(type, term);
    }
  };

  const loading = loadingSp3Songs || loadingDDEXSongs;

  return (
    <>
      <SearchForm
        defaultAlbum={defaultAlbum}
        defaultArtist={defaultArtist}
        defaultEndYear={defaultEndYear}
        defaultIsrc={defaultIsrc}
        defaultLabel={defaultLabel}
        defaultStartYear={defaultStartYear}
        defaultSubLabel={defaultSubLabel}
        defaultTitle={defaultTitle}
        defaultType={defaultType}
        onSearch={handleSearch}
        songSearchState={songSearchState}
      />
      <div>
        {loading ? (
          <Loader />
        ) : lastSearchType === SearchType.Database && sp3SongsData ? (
          <CurationSongList songInputs={sp3SongsData.curationSongDatabaseSearch} />
        ) : lastSearchType === SearchType.DDEX && ddexSongsData ? (
          <DdexSongList
            songs={ddexSongsData.curationSongDDEXSearch}
            onSelectSong={onSongSelected ? (s: CurationSongDDEXSearch) => onSongSelected(s, 'DDEX') : undefined}
            actionButton={actionButton}
          />
        ) : null}
      </div>
    </>
  );
}
