import { useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import SelectDropdown from '../../../components/SelectDropdown';
import BadgeForm from './BadgeForm';
import { LoadingLogo } from '../../../../devtools/components/Modal';
import {
  DELETE_BADGE,
  GET_CONTENT_CATEGORIES,
  SPECIAL_BADGE_UNLOCK_LEVELUP_REQ_CONFIG,
  UPDATE_BADGE,
} from '../../../components/apolloQueries';
import {
  BadgeType,
  type BadgeConfig,
  type ContentCategoriesQuery,
  BadgeProgressTaskType,
} from '../../../../../../__gqltypes__';
import DeleteBadgeModal from './DeleteBadgeModal';

type Props = {
  item: BadgeConfig;
  refetch: () => void;
};

export default function BadgeView({ item, refetch }: Props) {
  const [itemData, setItemData] = useState(item);
  const { data } = useQuery<ContentCategoriesQuery>(GET_CONTENT_CATEGORIES);
  const { data: badgeReqConfigs } = useQuery(SPECIAL_BADGE_UNLOCK_LEVELUP_REQ_CONFIG);

  const badgeLevelUpReqConfig = badgeReqConfigs?.liveOps?.badgeLevelUpReqConfig ?? [];
  const badgeUnlockReqConfig = badgeReqConfigs?.liveOps?.badgeUnlockReqConfig ?? [];

  useEffect(() => {
    setItemData(item);
  }, [item]);
  const [updateBadge, { loading }] = useMutation(UPDATE_BADGE, {
    onCompleted: () => refetch(),
  });
  const history = useHistory();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [deleteBadge] = useMutation(DELETE_BADGE, {
    onCompleted: () => refetch(),
  });

  const details = JSON.parse(itemData?.details ?? '{}');

  const handleUpdate = () => {
    updateBadge({
      variables: {
        input: {
          id: itemData?.id,
          name: itemData?.name,
          unlockReq: itemData?.unlockReq,
          levelUpReq: itemData?.levelUpReq,
          details: itemData?.details,
        },
      },
    });
  };

  const handleDelete = () => {
    deleteBadge({ variables: { input: { id: itemData?.id } } });
    history.push('/sp3/liveops/items/badges');
    setShowDeleteModal(false);
  };

  const handleAddLevel = () => {
    if (!details.levelDetails) {
      details.levelDetails = [];
    }
    if (itemData?.type === BadgeType.CATEGORY) {
      details.levelDetails.push({
        level: details.levelDetails.length,
        asset: null,
        fragmentsCost: null,
        metaCost: {
          BRONZE: 0,
          SILVER: 0,
          GOLD: 0,
        },
      });
    } else {
      details.levelDetails.push({
        level: details.levelDetails.length,
        asset: null,
        value: 0,
        extra: null,
      });
    }
    setItemData({ ...itemData, details: JSON.stringify(details) });
    updateBadge({
      variables: {
        input: {
          id: itemData?.id,
          name: itemData?.name,
          unlockReq: itemData?.unlockReq,
          levelUpReq: itemData?.levelUpReq,
          details: JSON.stringify(details),
        },
      },
    });
  };

  const handleChangeBadgeReq = (e: any, prop: string) => {
    setItemData({ ...itemData, [prop]: e.target.value });
  };

  const handleDeleteLevel = () => {
    details.levelDetails.pop();
    setItemData({ ...itemData, details: JSON.stringify(details) });
  };

  const iterate = (root: Record<string, string> | string, acc: string[]) => {
    if (_.isString(root)) {
      if (root.startsWith('https')) {
        acc.push(root);
      }
      return;
    }
    if (_.isArray(root)) {
      root.forEach((r) => iterate(r, acc));
      return;
    }

    if (!_.isObject(root)) {
      return;
    }

    const val = Object.values(root);
    val.forEach((v) => iterate(v, acc));
  };

  const handleValidateJsonItem = (f: Blob): Promise<string[]> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = async (evt) => {
        let jsonObj = {};
        const validationErrors = [];
        try {
          // @ts-ignore
          jsonObj = JSON.parse(evt.target.result);
        } catch (e) {
          console.error('not valid JSON file', e);
          validationErrors.push('not valid JSON file');
        }

        const acc: string[] = [];
        iterate(jsonObj, acc);
        for (const url of acc) {
          try {
            const response = await fetch(url);
            if (response.status !== 200) {
              validationErrors.push(`${url} not found`);
            }
          } catch (e) {
            validationErrors.push(`${url} not found`);
          }
        }
        resolve(validationErrors);
      };

      reader.readAsText(f);
    });
  };

  const { contentCategories } = data ? data.app : { contentCategories: [] };
  const options: Record<string, { value: React.ReactNode; searchValue: string }> = {};
  contentCategories.forEach((category) => {
    options[category.id] = {
      value: (
        <>
          <Image roundedCircle className="img-profile mr-3" src={category.icon} style={{ width: 20, height: 20 }} />
          {category.name}
        </>
      ),
      searchValue: category.name,
    };
  });

  return (
    <>
      <Card className="mt-3">
        <LoadingLogo show={loading} />
        <DeleteBadgeModal
          name={itemData?.name}
          show={showDeleteModal && !loading}
          onHide={() => setShowDeleteModal(false)}
          onConfirm={handleDelete}
        />
        <Card.Header className="text-center d-flex justify-content-between">
          <h4>{itemData?.name}</h4>
          <div>
            <Button className="mr-3" variant="danger" onClick={() => setShowDeleteModal(true)}>
              Delete
            </Button>
            <Button onClick={handleUpdate}>Save</Button>
          </div>
        </Card.Header>
        <Card.Body style={{ height: '100%' }}>
          <Form>
            <Form.Group>
              <Form.Label>Id</Form.Label>
              <Form.Control placeholder={itemData?.id} disabled />
            </Form.Group>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                placeholder=""
                value={itemData?.name}
                onChange={(e) => setItemData({ ...itemData, name: e.target.value })}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Acquisition</Form.Label>
              <Form.Control as="select" value={itemData?.type} disabled>
                {Object.keys(BadgeType).map((type) => (
                  <option value={type} key={type}>
                    {type}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            {itemData?.type === BadgeType.CATEGORY ? (
              <Col>
                <SelectDropdown
                  title="Select fragment currency"
                  options={options}
                  value={details.categoryId ?? null}
                  onSelect={(v) =>
                    setItemData({
                      ...itemData,
                      details: JSON.stringify({ ...details, categoryId: v }),
                    })
                  }
                />
              </Col>
            ) : (
              <Form.Control
                as="select"
                value={details.progressTaskType}
                onChange={(e) =>
                  setItemData({
                    ...itemData,
                    details: JSON.stringify({ ...details, progressTaskType: e.target.value }),
                  })
                }
              >
                {Object.keys(BadgeProgressTaskType).map((type) => (
                  <option value={type} key={type}>
                    {type}
                  </option>
                ))}
              </Form.Control>
            )}
            {itemData?.type === BadgeType.PROGRESS && (
              <>
                <Form.Group className="mt-3">
                  <Form.Label>Unlock Requirements</Form.Label>
                  <Form.Control
                    as="select"
                    value={itemData?.unlockReq ?? ''}
                    onChange={(e) => handleChangeBadgeReq(e, 'unlockReq')}
                  >
                    {['', ...badgeUnlockReqConfig]?.map((type: string) => (
                      <option value={type} key={type}>
                        {type}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Level Up Requirements</Form.Label>
                  <Form.Control
                    as="select"
                    value={itemData?.levelUpReq ?? ''}
                    onChange={(e) => handleChangeBadgeReq(e, 'levelUpReq')}
                  >
                    {['', ...badgeLevelUpReqConfig]?.map((type: string) => (
                      <option value={type} key={type}>
                        {type}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </>
            )}
            <hr />
          </Form>
          {details.levelDetails?.map((level: any) => (
            <BadgeForm
              upgrade={level}
              itemData={itemData}
              setItemData={setItemData}
              key={itemData.id + level.level}
              onValidate={handleValidateJsonItem}
            />
          ))}
          <br />
          <div className="d-flex justify-content-between">
            <Button variant="success" onClick={handleAddLevel}>
              <i className="fas fa-plus-circle mr-2" />
              Add an upgrade
            </Button>
            <Button variant="danger" onClick={handleDeleteLevel}>
              <i className="fas fa-minus-circle mr-2" /> Remove an upgrade
            </Button>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}
