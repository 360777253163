import moment from 'moment';
import { levels, servers } from './config';
import parseMessage from './parser';
import { LiveShowLog } from './types';

export default function LiveShowLogRow({
  log,
  search,
  onDisplayLogDetails,
}: {
  log: LiveShowLog & { id: number };
  search: string;
  onDisplayLogDetails: (log: LiveShowLog) => void;
}) {
  const icon = levels[log.level as keyof typeof levels]?.colored ?? <>{log.level}</>;
  const server = servers[log.source as keyof typeof servers]?.colored ?? (
    <span style={{ fontWeight: 'bold' }}>{log.source}</span>
  );
  const hasData = Object.entries(log.extraData).length > 0;

  const renderSearch = (parts: { text: string; search: boolean }[]) =>
    parts.map((t) => (
      <span key={t.text} className={t.search ? 'logSearch' : ''}>
        {t.text}
      </span>
    ));

  return (
    <div className="logRow">
      <div style={{ width: 50 }}>
        <span>{icon}</span>
      </div>
      <div style={{ width: 120 }}>{moment(log.timestamp).format('HH:mm:ss.SSS')}</div>
      <div style={{ width: 70 }}>{server}</div>
      <div style={{ flex: 1 }}>
        {parseMessage(log.message, search).map((part) => {
          if (part.type === 'title') {
            return <u key={part.id}>{renderSearch(part.parts)}</u>;
          }
          const colors = {
            info: '#eef',
            user: '#ccf',
            pod: '#fcc',
            constant: '#fde3c9',
            id: '#ddd',
          };
          if (Object.keys(colors).includes(part.type)) {
            return (
              <span
                key={part.id}
                className="logCode"
                style={{ backgroundColor: colors[part.type as keyof typeof colors] }}
              >
                {renderSearch(part.parts)}
              </span>
            );
          }
          if (part.type === 'number') {
            return (
              <span key={part.id} className="logCodeNumber">
                {renderSearch(part.parts)}
              </span>
            );
          }
          return <span key={part.id}>{renderSearch(part.parts)}</span>;
        })}
      </div>
      {hasData && (
        <div>
          <span role="button" tabIndex={0} onClick={() => onDisplayLogDetails(log)}>
            <i className="fas fa-info-circle" style={{ fontSize: '0.95rem' }} />
          </span>
        </div>
      )}
    </div>
  );
}
