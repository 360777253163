import React from 'react';
import { Button, Dropdown, DropdownButton, Form, Modal } from 'react-bootstrap';
import { ModeratedContentItemNoteType } from '../../../../../../__gqltypes__';
import { NOTE_REASON } from './utils';

type Props = {
  title: string;
  show: boolean;
  onHide: () => void;
  onUpdate: (note: string, noteType: ModeratedContentItemNoteType) => void;
  forcedNoteType?: ModeratedContentItemNoteType;
};

const ContentModerationNoteModal = ({ title, show, onHide, onUpdate, forcedNoteType }: Props) => {
  const [noteType, setNoteType] = React.useState<ModeratedContentItemNoteType | null>(forcedNoteType ?? null);
  const [note, setNote] = React.useState<string | undefined>(undefined);

  const handleNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => setNote(event.target.value);

  const onSave = () => {
    // if both set
    if (!!note && !!noteType) {
      onUpdate(note, noteType);
    } else if (!noteType) {
      alert('Reason must not be empty');
    } else {
      alert('Note must not be empty');
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!forcedNoteType && (
          <DropdownButton
            as="span"
            id="dropdown-item-button"
            size="sm"
            title={NOTE_REASON.find(({ key }) => key === noteType)?.value ?? 'Reason'}
            disabled={!!forcedNoteType}
          >
            {NOTE_REASON.map(({ key, value }) => (
              <Dropdown.Item
                key={key}
                as="button"
                active={noteType === key}
                onClick={() => {
                  setNoteType(key as ModeratedContentItemNoteType);
                }}
              >
                {value}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        )}
        <Form.Control
          className="mt-4"
          as="textarea"
          rows={3}
          value={note}
          onChange={handleNoteChange}
          placeholder={note}
        />
        <Modal.Footer>
          <Button onClick={onSave}>Save</Button>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
};

export default ContentModerationNoteModal;
