import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { Image, Pagination, Table } from 'react-bootstrap';
import LazyLoad from 'react-lazyload';
import { HurdleCatalogItemListQuery, HurdleSongItem } from '../../../../../__gqltypes__';
import PlayButton from '../../components/PlayButton';
import { ClipboardButton } from '../SongSearch/components/ClipboardButton';
import CatalogItemActions from './CatalogItemActions';

const ImagePlaceholder = () => {
  return <div className="placeholder">&nbsp;</div>;
};

type CatalogItem = HurdleCatalogItemListQuery['hurdleCatalogItemList']['list'][0];
type SongItem = Extract<CatalogItem['item'], { __typename: 'HurdleSongItem' }>;

function SongRow({
  contentItem,
  catalogItem,
  refresh,
  isHighlighted,
}: {
  contentItem: SongItem;
  catalogItem: CatalogItem;
  refresh: () => void;
  isHighlighted: boolean;
}) {
  const ref = useRef<HTMLTableRowElement>(null);
  const [highlighted, setHighlighted] = useState(false);

  useEffect(() => {
    if (!isHighlighted || !ref.current) return () => {};

    const rows = Array.from(ref.current.parentElement?.children ?? []) as HTMLTableRowElement[];
    const rowsAbove = rows.map((e) => e.clientHeight);
    const indexOf = rows.indexOf(ref.current);

    const scroll = _.sum(rowsAbove.slice(0, indexOf));
    ref.current.parentElement?.parentElement?.scrollTo(0, scroll);

    setHighlighted(true);
    const timeout = setTimeout(() => {
      setHighlighted(false);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [isHighlighted]);

  return (
    <tr ref={ref} className={highlighted ? 'bg-info' : ''} style={{ transition: highlighted ? 'all 0.3s' : 'all 3s' }}>
      <td style={{ width: '15%' }}>
        <ClipboardButton title={`${catalogItem.id.slice(0, 4)} ...`} textToCopy={catalogItem.id} />{' '}
        <ClipboardButton title={`${contentItem.id.slice(0, 4)} ...`} textToCopy={contentItem.id} />
      </td>
      <td style={{ width: '15%' }}>{catalogItem.categoryId}</td>
      <td style={{ width: '10%' }}>
        {contentItem.song ? (
          <LazyLoad height={25} overflow placeholder={<ImagePlaceholder />}>
            <Image height={25} src={contentItem.song.picture} />
          </LazyLoad>
        ) : (
          <ImagePlaceholder />
        )}
      </td>
      <td style={{ width: '20%' }}>{contentItem.song?.title ?? 'Unknown'}</td>
      <td style={{ width: '20%' }}>{contentItem.song?.artist ?? 'Unknown'}</td>
      <td style={{ width: '10%', textAlign: 'center' }}>
        {catalogItem.excludeFromAutogeneration ? (
          <i className="far fa-times-circle text-danger" />
        ) : (
          <i className="fas fa-check text-success" />
        )}
        {` `}
        {catalogItem.excludeFromCheck ? (
          <i className="fas fa-unlock text-danger" />
        ) : (
          <i className="fas fa-lock text-success" />
        )}
      </td>
      <td style={{ width: '5%' }}>
        {contentItem.asset && (
          <PlayButton sample={catalogItem.item.asset} displayTimestamp startAt={catalogItem.offset} />
        )}
      </td>
      <td style={{ width: '5%' }}>
        <CatalogItemActions catalogItem={catalogItem} onUpdate={refresh} />
      </td>
    </tr>
  );
}

function LoadingRows() {
  return (
    <>
      {_.range(ITEMS_PER_PAGE).map((i) => (
        <div
          key={i}
          style={{
            height: 50,
            backgroundColor: '#ddd',
            margin: '3px 0px',
            borderRadius: 5,
            animation: 'blink 2s linear infinite',
          }}
        />
      ))}
    </>
  );
}

const ITEMS_PER_PAGE = 50;

export default function PaginatedCatalogItems({
  page,
  refetch,
  loading,
  hurdleCatalogItemList,
  totalCount,
  rank,
}: {
  page: number;
  loading: boolean;
  refetch: (page: number, force?: boolean) => void;
  hurdleCatalogItemList: CatalogItem[];
  totalCount: number;
  rank: number | null;
}) {
  const paginationNumberOfPages = totalCount ? Math.ceil(totalCount / ITEMS_PER_PAGE) : 5;
  const empty = !loading && totalCount === 0;

  return (
    <>
      <Pagination>
        <Pagination.First disabled={empty} />
        <Pagination.Prev disabled={empty} />
        {!empty && (
          <>
            <Pagination.Item active={page === 1} onClick={() => refetch(1)}>
              {1}
            </Pagination.Item>
            {page > 4 && <Pagination.Ellipsis />}

            {_.range(Math.max(page - 2, 2), Math.min(page + 3, paginationNumberOfPages)).map((number) => (
              <Pagination.Item key={number} active={number === page} onClick={() => refetch(number)}>
                {number}
              </Pagination.Item>
            ))}

            {page < paginationNumberOfPages - 3 && <Pagination.Ellipsis />}
            <Pagination.Item active={paginationNumberOfPages === page} onClick={() => refetch(paginationNumberOfPages)}>
              {paginationNumberOfPages}
            </Pagination.Item>
          </>
        )}

        <Pagination.Next disabled={empty} />
        <Pagination.Last disabled={empty} />
      </Pagination>
      {!hurdleCatalogItemList && 'Loading...'}

      <Table>
        <tr>
          <td style={{ width: '15%' }}>Catalog item id, Content item id</td>
          <td style={{ width: '15%' }}>Category</td>
          <td style={{ width: '10%' }}>Picture</td>
          <td style={{ width: '20%' }}>Title</td>
          <td style={{ width: '20%' }}>Artist</td>
          <td style={{ width: '10%' }}>Auto-generate, Protected </td>
          <td style={{ width: '5%' }}>Play </td>
          <td style={{ width: '5%' }}>Update </td>
        </tr>
      </Table>
      <div style={{ overflow: 'scroll' }}>
        {!loading && hurdleCatalogItemList.length === 0 && (
          <div
            style={{
              height: 50,
              backgroundColor: '#ddd',
              borderRadius: 5,
              display: 'flex',
            }}
          >
            <div className="m-auto">Catalog is empty</div>
          </div>
        )}

        {loading ? (
          <LoadingRows />
        ) : (
          <Table>
            {hurdleCatalogItemList.map((item, i) => {
              if (item.item !== null) {
                return (
                  <SongRow
                    contentItem={item.item}
                    catalogItem={item}
                    refresh={() => refetch(page, true)}
                    isHighlighted={rank !== null && rank === (page - 1) * 50 + i + 1}
                  />
                );
              }
              return null;
            })}
          </Table>
        )}
      </div>
    </>
  );
}
