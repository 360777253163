import DashboardContainer from '../../../../../../utils/dashboards/components/DashboardContainer';
// import { SimpleDateSlicing } from '../../../../../../utils/dashboards/components/slicers';
import { DefaultSlicing } from '../../../../../../utils/dashboards/components/slicers';
import { SectionDisplay } from '../../../../../../utils/dashboards/components/widgets';
import LineChart from '../../../../../../utils/dashboards/components/widgets/charts/LineChart';
// import PieChart from '../../../../../../utils/dashboards/components/widgets/charts/PieChart';
// import BarChart from '../../../../../../utils/dashboards/components/widgets/charts/BarChart';
// import BaseTable from '../../../../../../utils/dashboards/components/widgets/tables/BaseTable';
import WidgetContainer from '../../../../../../utils/dashboards/components/widgets/WidgetContainer';
import Processing from '../../../../../../utils/dashboards/models/Processing';

const queries = {
  installsPerPlatform: { fillZeroes: true },
  installsBySource: { fillZeroes: true },
  CRInstallToRegister: { fillZeroes: true },
  CRInstallToRegisterPerPlatform: { fillZeroes: true },
  retention: { fillZeroes: true },
  retentionD7MovingAvg: { fillZeroes: true },
  ltvIAP: { fillZeroes: true },
  ltvAdsDisplayedD7: { fillZeroes: true },
  ltvAdsRevenueD7: { fillZeroes: true },
  ltvToDate: { fillZeroes: true },
  gamesPlayedD7: { fillZeroes: true },
  dauPerPlatform: { fillZeroes: true },
  adsPerDAU: { fillZeroes: true },
  adsRevenuePerDAU: { fillZeroes: true },
  avgCPMs: { fillZeroes: true },
  gamesPerDAU: { fillZeroes: true },
  subscriberCR: { fillZeroes: true },
  revenue: { fillZeroes: true },
  revenueMonthlySplit: { fillZeroes: true },
  subscriberCount: { fillZeroes: true },
  subscriberDAU: { fillZeroes: true },
  revenueByType: { fillZeroes: true },
  revenueByStore: { fillZeroes: true },
  adsRevenueByType: { fillZeroes: true },
  arpdau: { fillZeroes: true },
  arpdauMonthlySplit: { fillZeroes: true },
  arpdauByType: { fillZeroes: true },
  arpdauPerPlatform: { fillZeroes: true },
  interstitialAdDisplaysPerNetworkPerDAU: { fillZeroes: true },
  rewardedAdDisplaysPerNetworkPerDAU: { fillZeroes: true },
  fillRatePerAdType: { fillZeroes: true },
  fillRatePerPlatform: { fillZeroes: true },
};

const Usage = () => (
  <DashboardContainer queries={queries} id="usage" title="Usage">
    <DefaultSlicing id="DefaultSlicing" />
    <WidgetContainer id="WidgetContainer">
      <SectionDisplay notes="New Users study" />
      <LineChart
        id="installsPerPlatform"
        title="New App Installs"
        processing={Processing.pivot('installsPerPlatform', 'platform_os', 'nb_installs', 'date')}
      />
      <LineChart
        id="installsBySource"
        title="New App Installs By Source"
        processing={Processing.pivot('installsBySource', 'source', 'nb_installs', 'date')}
      />
      <LineChart id="CRInstallToRegister" title="CR Install to Register" percentage />
      <LineChart
        id="CRInstallToRegisterPerPlatform"
        title="CR Install to Register per Platform"
        processing={Processing.pivot('CRInstallToRegisterPerPlatform', 'platform_os', 'CR_install_to_register', 'date')}
        percentage
      />
      <LineChart id="retention" title="Retention of registered users" percentage />
      <LineChart
        id="retentionD7MovingAvg"
        title="Weekly Moving Average of Retention D7 of registered users"
        percentage
      />
      <LineChart id="ltvIAP" title="LTV IAP + Subscription D1 and D7 of registered users" />
      <LineChart
        id="ltvAdsDisplayedD7"
        title="LTV Ads Displayed D7 of registered users (interstitial + rewarded ads)"
      />
      <LineChart id="ltvAdsRevenueD7" title="LTV Ads Revenue D7 of registered users (interstitial + rewarded ads)" />
      <LineChart id="ltvToDate" title="Total LTV To Date of registered users (interstitial + rewarded ads + IAP + Subscription)" />
      <LineChart id="gamesPlayedD7" title="Number of Games Played at D7 of registered users" />
      <SectionDisplay notes="All Users study" />
      <LineChart
        id="dauPerPlatform"
        title="DAU"
        processing={Processing.pivot('dauPerPlatform', 'platform_os', 'nb_dau', 'date')}
      />
      <LineChart
        id="adsPerDAU"
        title="Ads Per DAU"
        processing={Processing.pivot('adsPerDAU', 'ad_type', 'adpdau', 'date')}
      />
      <LineChart
        id="interstitialAdDisplaysPerNetworkPerDAU"
        title="Interstitial Ads Display Per Network Per DAU"
        processing={Processing.pivot('interstitialAdDisplaysPerNetworkPerDAU', 'network', 'adpdau', 'date')}
      />
      <LineChart
        id="rewardedAdDisplaysPerNetworkPerDAU"
        title="Rewarded Ads Display Per Network Per DAU"
        processing={Processing.pivot('rewardedAdDisplaysPerNetworkPerDAU', 'network', 'adpdau', 'date')}
      />
      <LineChart
        id="fillRatePerAdType"
        title="Fill Rate per Ad Type"
        processing={Processing.pivot('fillRatePerAdType', 'ad_type', 'fill_rate', 'date')}
        percentage
      />
      <LineChart
        id="fillRatePerPlatform"
        title="Fill Rate per Platform"
        processing={Processing.pivot('fillRatePerPlatform', 'platform_os', 'fill_rate', 'date')}
        percentage
      />
      <LineChart
        id="gamesPerDAU"
        title="Number of Games played per DAU by type of games"
        processing={Processing.pivot('gamesPerDAU', 'type', 'games_per_dau', 'date')}
      />
      <SectionDisplay notes="Monetization study" />
      <LineChart id="subscriberCR" title="Subscription Convertion Rate D7 and D37 in %" percentage />
      <LineChart
        id="subscriberCount"
        title="Subscriber count per day"
        processing={Processing.pivot('subscriberCount', 'vip_type', 'nb_users', 'date')}
      />
      <LineChart
        id="subscriberDAU"
        title="DAU per Subscriber users per platform"
        processing={Processing.pivot('subscriberDAU', 'userType', 'nb_dau', 'date')}
      />
      <LineChart id="revenue" title="Revenue (IAP + Subscription + Ads)" />
      <LineChart id="revenueMonthlySplit" title="Revenue (IAP + Subscription + Ads) with annual subscription split per month" />
      <LineChart
        id="revenueByType"
        title="Revenue (IAP + Subscription) by type with annual subscription split per month"
        processing={Processing.pivot('revenueByType', 'productType', 'revenue', 'date')}
      />
      <LineChart
        id="revenueByStore"
        title="Revenue (IAP + Subscription) by store with annual subscription split per month"
        processing={Processing.pivot('revenueByStore', 'store', 'revenue', 'date')}
      />
      <LineChart
        id="adsRevenueByType"
        title="Ads Revenue By Type"
        processing={Processing.pivot('adsRevenueByType', 'ad_type', 'ads_revenue', 'date')}
      />
      <LineChart id="arpdau" title="ARPDAU (IAP + Subscription)" />
      <LineChart id="arpdauMonthlySplit" title="ARPDAU (IAP + Subscription) with annual subscription split per month" />
      <LineChart
        id="arpdauByType"
        title="ARPDAU (IAP + Subscription) by type with annual subscription split per month"
        processing={Processing.pivot('arpdauByType', 'productType', 'arpdau', 'date')}
      />
      <LineChart
        id="arpdauPerPlatform"
        title="ARPDAU (IAP + Subscription) Per Platform with annual subscription split per month"
        processing={Processing.pivot('arpdauPerPlatform', 'os_name', 'arpdau', 'date')}
      />
      <LineChart
        id="adsRevenuePerDAU"
        title="Ads Revenue Per DAU"
        processing={Processing.pivot('adsRevenuePerDAU', 'ad_type', 'adrpdau', 'date')}
      />
      <LineChart
        id="avgCPMs"
        title="Average CPM per Ad Type"
        processing={Processing.pivot('avgCPMs', 'ad_type', 'avg_CPM', 'date')}
      />
    </WidgetContainer>
  </DashboardContainer>
);

export default Usage;
