import React from 'react';

import BaseSelectorSlicer from '../components/BaseSelectorSlicer';
import defaultUserType from '../../../models/slicers/defaultUserType.json';
import { slicerPropTypes } from '../slicerPropTypes';

const options = Object.keys(defaultUserType.queries).map((key) => ({
  text: key,
  value: key,
}));

const DefaultUserTypeSlicer = ({ id, value, onChange }) => (
  <BaseSelectorSlicer id={id} value={value} onChange={onChange} options={options} />
);

DefaultUserTypeSlicer.propTypes = slicerPropTypes;

DefaultUserTypeSlicer.defaultProps = {
  id: defaultUserType.defaultId,
  onChange: () => {},
};

export default DefaultUserTypeSlicer;