export const WorldWideTerritoryCodes = [
  'CA', // Canada
  'US', // United States
  'AT', // Austria
  'BE', // Belgium
  'HR', // Croatia
  'CZ', // Czech Republic
  'DK', // Denmark
  'FI', // Finland
  'FR', // France
  'DE', // Germany
  'GR', // Greece
  'HU', // Hungary
  'IE', // Ireland
  'IT', // Italy
  'LU', // Luxembourg
  'NL', // Netherlands
  'NO', // Norway
  'PL', // Poland
  'PT', // Portugal
  'RO', // Romania
  'ES', // Spain
  'SE', // Sweden
  'CH', // Switzerland
  'TR', // Turkey
  'UA', // Ukraine
  'GB', // United Kingdom
  'IN', // India
  'IL', // Israel
  'ZA', // South Africa
  'AR', // Argentina
  'BR', // Brazil
  'CL', // Chile
  'CO', // Colombia
  'MX', // Mexico
  'PE', // Peru
  'AU', // Australia
  'ID', // Indonesia
  'MY', // Malaysia
  'NZ', // New Zealand
  'PH', // Philippines
  'SG', // Singapore
  'TW', // Taiwan
  'TH', // Thailand
];
