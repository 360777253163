import { AppRouteType } from '../../types';
import { UserRole } from '../../__gqltypes__';
import admRoutes from './admin/routes';
import analyticsRoutes from './analytics/routes';
import crtRoutes from './curation/routes';
import devRoutes from './devtools/routes';
import liveopsRoutes from './liveops/routes';

const routes: AppRouteType[] = [
  {
    name: 'Curation',
    sideRoutes: crtRoutes,
    permission: [UserRole.ADMIN, UserRole.CURATOR, UserRole.CONTENT_MODERATOR],
  },
  { name: 'Admin', sideRoutes: admRoutes, permission: [UserRole.ADMIN] },
  { name: 'DevTools', sideRoutes: devRoutes, permission: [UserRole.ADMIN] },
  { name: 'Analytics', sideRoutes: analyticsRoutes, permission: [UserRole.ADMIN] },
  { name: 'LiveOps', sideRoutes: liveopsRoutes, permission: [UserRole.ADMIN, UserRole.CURATOR] },
];

export default routes;
