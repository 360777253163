import { useLazyQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import { useState } from 'react';
import { Accordion, Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import {
  ChangeAvailableDateYirMutation,
  ChangeAvailableDateYirMutationVariables,
  GetAllYearInReviewQuery,
  UserSearchType,
  YearInReview,
} from '../../../../../../__gqltypes__';
import { GET_ALL_YEAR_IN_REVIEW, CHANGE_AVAILABLE_DATE_YIR } from '../../../graphql';

const COLUMNS = ['Year', 'Available date', 'Seen date', 'Change available date'];

const YearInReviewDisplay = ({ userId }: { userId: string }) => {
  const [runQuery, queryResult] = useLazyQuery<GetAllYearInReviewQuery>(GET_ALL_YEAR_IN_REVIEW, {
    variables: {
      userId,
      type: UserSearchType.BY_ID,
    },
    fetchPolicy: 'no-cache',
  });
  const [expanded, setExpanded] = useState(false);
  const [availableDate, setAvailableDate] = useState(() => moment().format('YYYY-MM-DD'));
  const [activeYearInReview, setActiveYearInReview] = useState<YearInReview>();

  const [showChangeDateModal, setShowChangeDateModal] = useState<boolean>(false);

  const [changeAvailableDateYiR, { loading: changeDateLoading }] = useMutation<
    ChangeAvailableDateYirMutation,
    ChangeAvailableDateYirMutationVariables
  >(CHANGE_AVAILABLE_DATE_YIR, {
    onCompleted: (data) => {
      queryResult.refetch();
    },
  });

  const changeAvailableDate = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    changeAvailableDateYiR({
      variables: {
        userId,
        year: activeYearInReview?.year ?? moment().year(),
        date: availableDate,
      },
    });
    setShowChangeDateModal(false);
  };

  return (
    <Card className="mb-4">
      <Card.Body>
        <Accordion>
          <Accordion.Toggle as={Card.Title} variant="link" eventKey="0" onClick={() => setExpanded(!expanded)}>
            <div
              style={{
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              Year in Review
              <Button variant="link">
                {!expanded && <i className="fas fa-chevron-down" />}
                {expanded && <i className="fas fa-chevron-up" />}
              </Button>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  runQuery();
                }}
                className="m-2"
              >
                <div className="d-flex justify-content-end">
                  <Button type="submit" size="sm" disabled={queryResult.loading || changeDateLoading}>
                    Re-fetch
                  </Button>
                </div>
              </Form>
              {(queryResult.loading || changeDateLoading) && (
                <div className="m-2">
                  <Spinner animation="border" />
                </div>
              )}
              {queryResult.error && <div className="m-1 text-danger">{queryResult.error.message}</div>}
              {queryResult.data?.user?.getAllYearInReview && (
                <div className="table-responsive" style={{ maxHeight: '100vh' }}>
                  <div>
                    <Table striped bordered size="sm" id="transactionsTable">
                      <thead>
                        <tr>
                          {COLUMNS.map((column) => (
                            <th key={column}>{column}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {queryResult.data.user?.getAllYearInReview?.map((yir) => (
                          <tr>
                            <td key={COLUMNS[0]}>{yir.year}</td>
                            <td key={COLUMNS[1]}>
                              {yir.available ? moment(yir.available).format('YYYY-MM-DD') : 'Not set'}
                            </td>
                            <td key={COLUMNS[2]}>{yir.seen ? moment(yir.seen).format('YYYY-MM-DD') : 'Not seen'}</td>
                            <td key={COLUMNS[3]}>
                              <Button
                                size="sm"
                                disabled={changeDateLoading || moment().year() - yir.year > 1}
                                onClick={() => {
                                  setAvailableDate(moment(yir.available).format('YYYY-MM-DD'));
                                  setActiveYearInReview(yir);
                                  setShowChangeDateModal(true);
                                }}
                              >
                                Change available date
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Modal show={showChangeDateModal} centered={true}>
                      <div style={{ display: 'flex', flexDirection: 'column', padding: '20px' }}>
                        <h3 style={{ textAlign: 'center' }}>Change available date</h3>
                        <p>
                          Change available date for upcoming Year in Review in <b>{activeYearInReview?.year}</b>
                        </p>
                        <Form onSubmit={changeAvailableDate} className="m-2">
                          <div className="d-flex">
                            <Col className="pl-0">
                              <Form.Control
                                type="date"
                                size="sm"
                                value={availableDate}
                                onChange={(e) => setAvailableDate(e.target.value)}
                              />
                            </Col>
                            <Button type="submit" size="sm" className="px-4" disabled={changeDateLoading}>
                              Change
                            </Button>
                          </div>
                        </Form>
                        <Button
                          className="mt-2 bg-dark"
                          size="sm"
                          disabled={changeDateLoading}
                          onClick={() => setShowChangeDateModal(false)}
                        >
                          Close
                        </Button>
                      </div>
                    </Modal>
                  </div>
                </div>
              )}
            </>
          </Accordion.Collapse>
        </Accordion>
      </Card.Body>
    </Card>
  );
};

export default YearInReviewDisplay;
