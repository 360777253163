import { Route } from 'react-router';
import { Redirect, Switch, useRouteMatch } from 'react-router-dom';
import { ViewMap } from '../../types';

type Props = {
  views: { [key: string]: ViewMap };
};

const ViewsProvider = ({ views }: Props) => {
  const { path } = useRouteMatch();

  const split = path.split('/');
  const app = split[1];

  if (!views[app]) return null;
  const viewsMap = views[app];
  const viewNames = Object.keys(viewsMap);

  const routes = viewNames.map((id) => {
    return <Route key={id} path={`${path}/${id}`} component={viewsMap[id].component} />;
  });

  return (
    <Switch>
      {routes}
      <Redirect to={`${path}/${viewNames[0]}`} />
    </Switch>
  );
};

export default ViewsProvider;
