import { useCallback, useState } from 'react';
import { Button } from 'react-bootstrap';
import { CurationContentPacksByNameQuery } from '../../../../../../__gqltypes__';
import SearchPlaylistPopup from '../../../popups/SearchPlaylistPopup';

type CurationContentPack = CurationContentPacksByNameQuery["curationContentPacksByName"][0];
type Props = {
  title?: string;
  add: (pack: CurationContentPack) => void;
};

const SPPAddPack = ({ title = 'Add pack to list', add }: Props) => {
  const [adding, setAdding] = useState(false);
  const askAdd = useCallback(() => setAdding(true), [setAdding]);
  const cancelAdd = useCallback(() => setAdding(false), [setAdding]);

  const doAdd = (pack: CurationContentPack) => {
    add(pack);
    setAdding(false);
  };

  return (
    <>
      <Button variant="success" onClick={askAdd}>
        <i className="fas fa-plus mr-2" />
        {title}
      </Button>

      <SearchPlaylistPopup show={!!adding} onHide={cancelAdd} onSelectPlaylist={doAdd} />
    </>
  );
};

export default SPPAddPack;
