import moment from 'moment';
import { useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import { Accordion, Alert, Tooltip } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import './UserSubscriptionLogDisplay.css';

import {
  ActiveUserSubscriptionUpdateLogsQuery,
  ActiveUserSubscriptionUpdateLogsQueryVariables,
  ApplyUserSubscriptionUpdateLogMutation,
  ApplyUserSubscriptionUpdateLogMutationVariables,
} from '../../../../../../__gqltypes__';
import { APPLY_USER_SUBSCRIPTION_UPDATE_LOG, GET_ACTIVE_USER_SUBSCRIPTION_UPDATE_LOGS } from '../../../graphql';
import { ClipboardButton } from '../../../../curation/screens/SongSearch/components/ClipboardButton';

type Props = {
  userId: string;
};

function UserSubscriptionLog({
  log: { store, environment, originalTransactionId, autoRenew, cancelled, cancelledReason, expires, created, sku },
  onUpdate,
  loading,
}: {
  log: ActiveUserSubscriptionUpdateLogsQuery['activeUserSubscriptionUpdateLogs']['logs'][0];
  onUpdate: () => void;
  loading: boolean;
}) {
  return (
    <div className="UserSubscriptionLogDisplay_log">
      <div style={{ width: '10%' }}>
        <div>{store}</div>
        <div>{environment}</div>
      </div>

      <div style={{ width: '15%' }}>
        <div>{moment(created).format('YYYY-MM-DD HH:mm')}</div>
      </div>

      <div style={{ width: '15%', color: '#a00' }}>
        <div>{moment(expires).format('YYYY-MM-DD HH:mm')}</div>
      </div>

      <div style={{ width: '25%' }}>
        <b>{sku}</b>
      </div>

      <div style={{ width: '10%' }}>
        {autoRenew && <div>Auto Renew</div>}
        {cancelled && <div>Cancelled</div>}
        {cancelledReason && <div>{cancelledReason}</div>}
      </div>

      <div style={{ width: '15%' }}>
        <ClipboardButton title={`${originalTransactionId.slice(0, 8)}...`} textToCopy={originalTransactionId} />
      </div>

      <div style={{ width: '10%', padding: 10 }}>
        <Button onClick={onUpdate} disabled={loading} size="sm">
          Apply this log again
        </Button>
      </div>
    </div>
  );
}

function UserSubscriptionLogs({ userId }: { userId: string }) {
  const { data } = useQuery<ActiveUserSubscriptionUpdateLogsQuery, ActiveUserSubscriptionUpdateLogsQueryVariables>(
    GET_ACTIVE_USER_SUBSCRIPTION_UPDATE_LOGS,
    { variables: { input: { userId } } }
  );
  const [update, { loading }] = useMutation<
    ApplyUserSubscriptionUpdateLogMutation,
    ApplyUserSubscriptionUpdateLogMutationVariables
  >(APPLY_USER_SUBSCRIPTION_UPDATE_LOG);

  return (
    <>
      {data?.activeUserSubscriptionUpdateLogs?.logs ? (
        <div>
          <h5>Active subscriptions according to logs:</h5>

          <div className="UserSubscriptionLogDisplay_log">
            <div style={{ width: '10%' }}>store/env</div>
            <div style={{ width: '15%' }}>created</div>
            <div style={{ width: '15%' }}>expires</div>
            <div style={{ width: '25%' }}>Sku</div>
            <div style={{ width: '10%' }} />
            <div style={{ width: '15%' }}>Original transaction id</div>
            <div style={{ width: '10%' }}>Apply again</div>
          </div>
          {(data?.activeUserSubscriptionUpdateLogs?.logs ?? []).map((log) => (
            <UserSubscriptionLog
              key={log.logId}
              log={log}
              onUpdate={() => update({ variables: { input: { userId, logId: log.logId } } })}
              loading={loading}
            />
          ))}
        </div>
      ) : (
        <div>This user has no log to be applied</div>
      )}
    </>
  );
}

export const UserSubscriptionLogsDisplay = ({ userId }: Props) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Card className="mb-4">
      <Card.Body>
        <Accordion>
          <Accordion.Toggle as={Card.Title} variant="link" eventKey="0" onClick={() => setExpanded(!expanded)}>
            <div style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}>
              User subscription active logs
              <Button variant="link">
                {!expanded && <i className="fas fa-chevron-down" />}
                {expanded && <i className="fas fa-chevron-up" />}
              </Button>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            {expanded ? <UserSubscriptionLogs userId={userId} /> : <></>}
          </Accordion.Collapse>
        </Accordion>
      </Card.Body>
    </Card>
  );
};
