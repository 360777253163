export default function drawStar(color: string, canvas: HTMLCanvasElement) {
  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

  const r1 = 4;
  const r2 = 10;
  const cx = 10;
  const cy = 10;
  const n = 5;

  ctx.fillStyle = color;
  for (let i = 0; i < n; i++) {
    ctx.beginPath();
    ctx.moveTo(cx + r2 * Math.cos((2 * Math.PI * i) / n), cy + r2 * Math.sin((2 * Math.PI * i) / n));
    ctx.lineTo(cx + r1 * Math.sin((2 * Math.PI * i) / n), cy - r1 * Math.cos((2 * Math.PI * i) / n));
    ctx.lineTo(cx - r1 * Math.sin((2 * Math.PI * i) / n), cy + r1 * Math.cos((2 * Math.PI * i) / n));
    ctx.closePath();
    ctx.fill();
  }
}
