import React from 'react';
import moment from 'moment';

// Slicers
import SlicerContainer from './SlicerContainer';
import DateSlicer from './components/DateSlicer';

// PropTypes
import { defaultSlicingPropTypes } from './slicerPropTypes';

const SimpleDateSlicing = ({ defaultStartDate, defaultEndDate, onRefresh }) => (
  <SlicerContainer onRefresh={onRefresh}>
    <DateSlicer name="DateSlicer" id="startDate" title="Start Date" defaultValue={defaultStartDate} />
    <DateSlicer name="DateSlicer" id="endDate" title="End Date" defaultValue={defaultEndDate} />
  </SlicerContainer>
);

SimpleDateSlicing.propTypes = defaultSlicingPropTypes;

SimpleDateSlicing.defaultProps = {
  defaultStartDate: moment().subtract(15, 'days').format('YYYY-MM-DD'),
  defaultEndDate: moment().subtract(2, 'day').format('YYYY-MM-DD'),
};

export default SimpleDateSlicing;
