import { useMutation } from '@apollo/client';
import { Button } from 'react-bootstrap';
import { FlushHurdleCacheMutation, FlushHurdleCacheMutationVariables } from '../../../../../__gqltypes__';
import Loader from '../../components/Loader';
import mutations from '../../utils/mutations';

export default function FlushHurdleCacheButton() {
  const [flush, { loading }] = useMutation<FlushHurdleCacheMutation, FlushHurdleCacheMutationVariables>(
    mutations.FLUSH_HURDLE_CACHE
  );

  return (
    <>
      If there is issue of synchronization of autocompletion with catalog, this might help:

      <Button disabled={loading} onClick={() => flush()} variant="danger" className="mt-3">
        {loading ? "Flushing..." : "Flush cache"}
      </Button>
    </>
  );
}
