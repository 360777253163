import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

export const TopBanner = ({ onSearchID, data, enableDate = false }) => {
  const [input, setInput] = useState(data && data.inputID ? data.inputID : '');
  const [date, setDate] = useState(null);
  const [wrongInput, setWrongInput] = useState(false);
  const handleInputChange = (event) => setInput(event.target.value);
  const handleDateChange = (event) => setDate(event.target.value);
  const handleSearch = () => {
    setWrongInput(!onSearchID(input, date));
  };
  const handleKeyPressed = (target) => {
    if (target.charCode === 13) {
      target.preventDefault();
      handleSearch();
    }
  };
  return (
    <Card className="shadow mb-4">
      <Card.Body>
        <Row>
          {onSearchID && (
            <Col>
              <Form>
                <Form.Group as={Row} className="d-flex justify-content-around">
                  <Col sm="9">
                    <Form.Control
                      value={input}
                      type="text"
                      placeholder="Enter session ID, user ID, instance ID"
                      onChange={handleInputChange}
                      onKeyPress={handleKeyPressed}
                    />
                    {enableDate && <Form.Control value={date} type="datetime-local" onChange={handleDateChange} />}
                  </Col>
                  <Button onClick={handleSearch}>Search</Button>
                </Form.Group>
              </Form>
            </Col>
          )}
        </Row>
        <Row>
          {data && (
            <>
              <Col sm="6">
                <h4>
                  Input ID:
                  {data.inputID}
                </h4>
              </Col>
              <Col sm="2">
                <h4>
                  Type:
                  {data.type}
                </h4>
              </Col>
              {data.date && (
                <Col sm="4">
                  <h4>
                    Date:
                    {`${data.date}`}
                  </h4>
                </Col>
              )}
            </>
          )}
        </Row>
        {wrongInput && (
          <Alert className="mb-1" variant="warning">
            Wrong Input format. Make sure your input is an uuid or and uuid starting with &apos;S-&apos;, &apos;A-&apos;
            or &apos;R-&apos;.
          </Alert>
        )}
      </Card.Body>
    </Card>
  );
};
