import moment from 'moment';
import React from 'react';
import { IconTypes } from './config';

export const idToTimestamp = (id) => {
  /*
  Parse the id to use the uuidv1 after the first letter identifier to return the date the uuid has been generated at.
  https://en.wikipedia.org/wiki/Universally_unique_identifier
  */
  const uuidArray = id.split('-');
  const timeString = [uuidArray[3].substring(1), uuidArray[2], uuidArray[1]].join('');
  return Math.floor((parseInt(timeString, 16) - 122192928000000000) / 10000);
};

export const parseTimestamp = (timestamp) => {
  if (timestamp === undefined || timestamp === null) return '';
  // The event_timestamp in the logs can be in micro (length = 16) or millisecond (length = 13)
  if (typeof timestamp === 'string') {
    timestamp = moment(timestamp).valueOf();
  }
  const date = timestamp.toString().length === 16 ? moment(parseFloat(timestamp / 1000)) : moment(timestamp);
  return `${date.format('HH:mm:ss.SSS')}`;
};

// Compare the timestamp and handle different timestamp format (micro or milliseconds)
export const compareTimestamps = (a, b) =>
  Number(a.event_timestamp.toString().slice(0, 13)) - Number(b.event_timestamp.toString().slice(0, 13));

export const extractUnique = (table, label) => {
  const res = [];
  table.forEach((row) => {
    if (!res.includes(row[label])) {
      res.push(row[label]);
    }
  });
  return res;
};

export const extractUniqueUsersIds = (logs) => {
  const userSetLogs = logs
    .filter((log) => log.event_name === 'user_data_loaded')
    .map((log) => log.event_params.user_id);
  const result = [];
  userSetLogs.forEach((userID) => {
    if (!result.includes(userID)) {
      result.push(userID);
    }
  });
  return result;
};

export const idToDate = (id) => moment(idToTimestamp(id)).format('YYYYMMDD');

export const isValidID = (id) => {
  let test = false;
  if (id.match(/^([U,A,S,R]-|)[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i)) {
    test = true;
  }
  return test;
};

export const parseID = (input) => {
  const areMultipleSessionIds = input.includes('_');
  if (areMultipleSessionIds) {
    return 'liveSessionID';
  }
  const split = input.split('-');
  if (split.length === 6) {
    switch (split[0]) {
      case 'A':
        return 'installID';
      case 'S':
        return 'sessionID';
      case 'R':
        return 'runtimeID';
      default:
        break;
    }
  } else if (split.length === 5) {
    return 'userID';
  }
  return undefined;
};

export const idToRoute = (input) => {
  const areMultipleSessionIds = input.includes('_');
  if (areMultipleSessionIds) {
    return 'livesession';
  }

  const split = input.split('-');
  if (split.length === 6) {
    switch (split[0]) {
      case 'A':
        return 'sessions';
      case 'S':
        return 'session';
      case 'R':
        return 'runtime';
      default:
        break;
    }
  } else if (split.length === 5) {
    return 'sessions';
  } else if (split.length === 2 && split[0] === 'A') {
    return 'sessions';
  }
  return undefined;
};

export const getBasePath = (url) => url.split('/').slice(0, -2).join('/');

export const groupApolloEvents = (logs, addLiveDebugEvents = false) => {
  const groupedLogs = [];
  const liveDebugEvents = [];
  const findOperationIndex = (name, timestamp) => {
    let result = -1;
    groupedLogs.forEach((log, index) => {
      // To improve
      if (name === log.event_params.name && Math.abs(timestamp - log.event_timestamp) < 4000) {
        result = index;
      }
    });
    return result;
  };
  const apolloOperationLogs = logs.filter((log) => log.event_name.includes('apollo'));
  apolloOperationLogs.forEach((log) => {
    const { event_params: eventParams, event_timestamp: date } = log;
    const index = findOperationIndex(eventParams.name, date);
    let paramsToAdd = {};
    switch (log.event_name) {
      case 'apollo_operation_skipped':
        paramsToAdd = { skipped_date: log.event_timestamp };
        break;
      case 'apollo_operation_sent':
        paramsToAdd = { sent_date: log.event_timestamp };
        break;
      case 'apollo_operation_received':
        paramsToAdd = { received_date: log.event_timestamp, runtime_id: eventParams.runtime_id };
        break;
      case 'apollo_operation_answered':
        paramsToAdd = eventParams.response_data
          ? {
              answered_date: log.event_timestamp,
              response_data: eventParams.response_data,
              back_duration: eventParams.duration,
            }
          : { answered_date: log.event_timestamp, back_duration: eventParams.duration };
        if (eventParams.name === 'onUserToUserQuizLiveRoundUpdated') {
          const roundUpdateData = JSON.parse(eventParams.response_data);
          liveDebugEvents.push({
            event_timestamp: log.event_timestamp,
            event_name: 'debug_live_state',
            event_params: {
              state: roundUpdateData.onUserToUserQuizLiveRoundUpdated.state,
              round_user_state: roundUpdateData.onUserToUserQuizLiveRoundUpdated.currentRound.userState,
              round_opponent_state: roundUpdateData.onUserToUserQuizLiveRoundUpdated.currentRound.opponentState,
            },
          });
        }
        break;
      case 'apollo_operation_response':
        paramsToAdd = { response_date: log.event_timestamp, request_duration: eventParams.duration };
        break;
      case 'apollo_operation_failed':
        paramsToAdd = { failed_date: log.event_timestamp, message: eventParams.message ?? eventParams.response_data };
        break;
      default:
        break;
    }
    if (eventParams?.session_id) {
      paramsToAdd.session_id = eventParams?.session_id;
    }
    if (index >= 0) {
      Object.assign(groupedLogs[index].event_params, paramsToAdd);
      groupedLogs[index].event_timestamp = log.event_timestamp;
    } else {
      groupedLogs.push({
        event_timestamp: log.event_timestamp,
        event_name: 'apollo_operation',
        event_params: { name: log.event_params.name, type: log.event_params.type, ...paramsToAdd },
      });
    }
  });
  let res = logs.filter((log) => !log.event_name.includes('apollo')).concat(groupedLogs);
  if (addLiveDebugEvents) {
    res = res.concat(liveDebugEvents);
  }
  return res;
};

// Generate and download a JSON file based on the povided value
export const handleDowload = (value, fileName) => {
  const blob = new Blob([value], { type: 'text/plain' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.download = fileName;
  link.href = url;
  link.click();
};

export const SourceIcon = ({ iconType }) => {
  switch (iconType) {
    case IconTypes.isBackend:
      return <i className="fas fa-server m-2" />;
    case IconTypes.isCrash:
      return <i className="fas fa-exclamation-circle m-2" />;
    case IconTypes.isIOS:
      return <i className="fab fa-apple m-2" />;
    case IconTypes.isAndroid:
      return <i className="fab fa-android m-2" />;
    case IconTypes.isWindows:
      return <i className="fab fa-windows m-2" />;
    case IconTypes.isApollo:
      return <i className="fas fa-rocket m-2" />;
    default:
      return <i className="fas fa-mobile-alt m-2" />;
  }
};
