import { useQuery } from '@apollo/client/react/hooks/useQuery';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';

import queries from '../../../../curation/utils/queries';
import { FilledMissionTaskInput } from '../typedef';
import { ParameterTypes } from '../tasks';
import { TaskParameter } from './TaskParameter';
import {
  ContentPacksQuery,
  ContentPacksQueryVariables,
  GetCategoriesQuery,
  GetTasksQuery,
  MissionTaskType,
} from '../../../../../../__gqltypes__';

type MissionTasksParam = GetTasksQuery['liveOps']['missionTasks'][0]['params'][0];
type MissionTaskParamType = Extract<MissionTasksParam, { __typename: 'MissionParamType' }>;
type MissionTaskParamInt = Extract<MissionTasksParam, { __typename: 'MissionParamInt' }>;
type Props = {
  task: FilledMissionTaskInput;
  show: boolean;
  handleClose: () => void;
  handleConfirm: (task: FilledMissionTaskInput) => void;
};

function EditTaskModal({ task, show, handleClose, handleConfirm }: Props) {
  const [currentTask, setCurrentTask] = useState<FilledMissionTaskInput | null>(null);
  // getting the list of available contentPack and categories to display suggestion
  const { loading: contentPacksLoading, data: contentPacksData } = useQuery<
    ContentPacksQuery,
    ContentPacksQueryVariables
  >(queries.CURATION_CONTENT_PACKS);
  const { loading: categoriesLoading, data: categoriesData } = useQuery<GetCategoriesQuery>(queries.GET_CATEGORIES);
  const { loading: tasksLoading, data: tasksData } = useQuery<GetTasksQuery>(queries.GET_TASKS);
  const suggestionsLoading = contentPacksLoading || categoriesLoading || tasksLoading;

  /**
   * Background Suggestions Pooling
   * Data used to offer suggestions in selector.
   */

  const { availableTasks, availableTaskTypes } = useMemo(() => {
    if (tasksData != null) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const tasks: Record<
        MissionTaskType,
        {
          params: Array<MissionTasksParam['__typename']>;
          paramsDescriptions: Array<string>;
          description: string | null;
        }
      > = {};

      tasksData.liveOps.missionTasks.forEach((missionTask) => {
        tasks[missionTask.type] = {
          params: missionTask.params.map(
            (param) => (param as MissionTaskParamType).typeName as MissionTaskParamInt['__typename']
          ),
          paramsDescriptions: missionTask.paramsDescriptions,
          description: missionTask.description || null,
        };
      });

      return { availableTasks: tasks, availableTaskTypes: Object.keys(tasks).sort() };
    }
    return {
      availableTasks: null,
      availableTaskTypes: null,
    };
  }, [tasksData]);

  const availableCategories = useMemo(() => {
    if (categoriesData != null) {
      return _.orderBy(
        categoriesData.app.contentCategories.map((category) => ({
          id: category.id,
          name: category.name,
        })),
        'name',
        'asc'
      );
    }

    return null;
  }, [categoriesData]);

  // getting All available contentPacks
  const availableContentPacks = useMemo(() => {
    if (contentPacksData != null) {
      return _.orderBy(
        contentPacksData.curation.contentPacks.list.map((contentPack) => ({
          id: contentPack.id,
          name: contentPack.name,
        })),
        'name',
        'asc'
      );
    }
    return null;
  }, [contentPacksData]);

  /**
   * Task update Logic
   */

  /**
   * Creating the default parameter corresponding to the given type
   */
  const defaultParameterValue = useCallback(
    (
      parameterType: keyof typeof ParameterTypes,
      _availableContentPacks: {
        id: string;
        name: string;
      }[],
      _availableCategories: {
        id: string;
        name: string;
      }[]
    ) => {
      switch (parameterType) {
        // null allow using loading values
        case ParameterTypes.MissionParamContentPack:
          return _availableContentPacks[0].id;
        case ParameterTypes.MissionParamContentCategory:
          return _availableCategories[0].id;
        case ParameterTypes.MissionParamFloat:
        case ParameterTypes.MissionParamInt:
        case ParameterTypes.MissionParamHour24:
          return 0;
        case ParameterTypes.MissionParamSong:
        case ParameterTypes.MissionParamString:
          return '';
        default:
          throw new Error(`unhandled parameterType${parameterType.toString()}`);
      }
    },
    []
  );

  // Detect input change
  useEffect(() => {
    if (task != null) {
      setCurrentTask(task);
    }
  }, [task]);

  /**
   * Update current taks type and create default parameters value accordingly
   */
  const handleTaskTypeSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedTaskType = event.target.value as MissionTaskType;
    // same type reselected, nothing to change
    if (
      currentTask == null ||
      availableTasks == null ||
      availableContentPacks == null ||
      availableCategories == null ||
      selectedTaskType === currentTask.type
    ) {
      return;
    }

    setCurrentTask({
      ...currentTask,
      type: selectedTaskType,
      params: availableTasks[selectedTaskType].params.map((parameterType) =>
        defaultParameterValue(parameterType, availableContentPacks, availableCategories)
      ),
    });
  };

  /**
   * Update current task parameter
   */
  const updateChangedParameter = async (index: number, value: number | string) => {
    // if a contentPack parameter has changed, content pack must me refetched first
    if (availableTasks == null || currentTask == null) {
      return;
    }

    setCurrentTask({
      ...currentTask,
      params: [...currentTask.params.slice(0, index), value, ...currentTask.params.slice(index + 1)],
    });
  };

  return show && currentTask != null ? (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Edit Task</Modal.Title>
      </Modal.Header>

      {currentTask == null ||
      availableCategories == null ||
      availableContentPacks == null ||
      availableTasks == null ||
      availableTaskTypes == null ? (
        <div className="p-3">
          <Spinner animation="border" />
        </div>
      ) : (
        <Modal.Body>
          <Form.Group>
            <Form.Label>Task Type: {availableTasks[currentTask.type].description}</Form.Label>
            <Form.Control as="select" onChange={handleTaskTypeSelect} value={currentTask.type}>
              {availableTaskTypes.map((availableTask) => (
                <option key={`edit-task-modal-${availableTask}`} value={availableTask}>
                  {availableTask}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          {currentTask.type === 'GUESS_SONG' ? (
            <p className="p-3 mb-2 bg-warning text-dark">
              Please enter song IDs corresponding to the song, separated by commas (&quot;,&quot;)
            </p>
          ) : null}

          {availableTasks[currentTask.type].params.length === 0 ? (
            <span> No parameter expected </span>
          ) : (
            <>
              <h4> Parameters </h4>
              {availableTasks[currentTask.type].params.map((parameterType, index) => (
                <TaskParameter
                  name={availableTasks[currentTask.type].paramsDescriptions[index]}
                  key={`param-type-${index}`}
                  type={parameterType}
                  value={currentTask.params[index]}
                  availableCategories={availableCategories}
                  availableContentPacks={availableContentPacks}
                  onChange={(value) => updateChangedParameter(index, value)}
                />
              ))}
            </>
          )}
        </Modal.Body>
      )}

      <Modal.Footer>
        <Button className="float-right" disabled={suggestionsLoading} onClick={() => handleConfirm(currentTask)}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  ) : null;
}

export default EditTaskModal;
