import _ from 'lodash';
import { InputType } from '../screens/PlaylistInfo/utils';
import { parseSpotifyArtistId, parseSpotifyPlaylistId } from '../utils/spotifyIds';

export const getFirstArtist = (artist: string) => {
  if (artist.includes('Featuring ')) {
    return artist.split('Featuring ')[0];
  }
  if (artist.includes(' & ')) {
    return artist.split(' &')[0];
  }
  return artist;
};

export type AddPlaylistValues =
  | null
  | { file: GQL_Upload }
  | { spotifyURI: string }
  | { artistIds: string[]; includeExtras: boolean }
  | { sp2PlaylistId: string };

export const onUpdatePlaylistOptionChosen = <T extends string>(
  event: React.FormEvent<HTMLFormElement>,
  inputType: T,
  onAddPlaylist: (type: T, values: AddPlaylistValues) => void
) => {
  event.preventDefault();
  const target = event.target as HTMLFormElement;

  if (inputType === InputType.CSV) {
    const { files } = target.elements.namedItem('csvPlaylistFile') as HTMLInputElement;
    if (!files || files.length === 0) {
      alert('No file loaded');
    } else {
      const file = files[0];
      console.log(file);
      onAddPlaylist(inputType, { file });
    }
  } else if (inputType === InputType.SPOTIFY) {
    const { value } = target.elements.namedItem('spotifyPlaylistURI') as HTMLInputElement;
    const spotifyId = parseSpotifyPlaylistId(value);

    if (!spotifyId) {
      alert('Invalid Spotify URI or URL!');
    } else {
      onAddPlaylist(inputType, { spotifyURI: `spotify:playlist:${spotifyId}` });
    }
  } else if (inputType === InputType.SPOTIFY_ARTISTS) {
    const { value } = target.elements.namedItem('artistIds') as HTMLInputElement;
    const { checked: includeExtras } = target.elements.namedItem('includeExtras') as HTMLInputElement;
    onAddPlaylist(inputType, {
      artistIds: _.compact(value.split(',').map(parseSpotifyArtistId)),
      includeExtras,
    });
  } else if (inputType === InputType.SP2) {
    const { value: sp2PlaylistId } = target.elements.namedItem('sp2id') as HTMLInputElement;
    onAddPlaylist(inputType, { sp2PlaylistId });
  } else if (inputType === InputType.NEW_VERSION) {
    onAddPlaylist(inputType, null);
  }
};
