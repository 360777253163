import { useMutation } from '@apollo/client';
import type {
  CurationContentPackQuery,
  ForceSongInputMatchingMutation,
  ForceSongInputMatchingMutationVariables,
} from '../../../../../__gqltypes__';
import mutations from '../../utils/mutations';
import queries from '../../utils/queries';

type CurationContentPack = CurationContentPackQuery['curationContentPack'];
type SuggestedCurationSong = ForceSongInputMatchingMutation['forceSongInputMatching']['suggestedSong'];
type CurationSong = Extract<CurationContentPack['contentItems'][0], { __typename: 'CurationSong' }>;
type Props = {
  songInputId: string | null;
  songId: string | null;
  contentPack: CurationContentPack;
};

export default ({
  songInputId,
  songId,
  contentPack,
}: Props): [SuggestedCurationSong, (manualMatchingId: string, manualMatchingType: string) => Promise<boolean>] => {
  const [forceSongInputMatching, { data }] = useMutation<
    ForceSongInputMatchingMutation,
    ForceSongInputMatchingMutationVariables
  >(mutations.FORCE_SONG_INPUT_MATCHING, {
    update: (cache, res) => {
      const variables = {
        id: contentPack.id,
      };
      const newMatch = res.data?.forceSongInputMatching.curationSong;
      if (newMatch) {
        cache.updateQuery(
          {
            query: queries.CURATION_CONTENT_PACK,
            variables,
          },
          (cachedData) => {
            if (cachedData) {
              const indexToChange = songId
                ? (cachedData.curationContentPack.contentItems as Array<CurationSong>).findIndex(
                    (song) => song.id === songId && song.songInput?.id === songInputId
                  )
                : (cachedData.curationContentPack.contentItems as Array<CurationSong>).findIndex(
                    (songInput) => songInput.id === songInputId
                  );
              if (indexToChange > -1) {
                const copiedItems = [...cachedData.curationContentPack.contentItems];
                copiedItems.splice(indexToChange, 1, newMatch);
                const updatedCachedData = {
                  curationContentPack: { ...cachedData.curationContentPack, ...{ contentItems: copiedItems } },
                };
                return updatedCachedData;
              }
            }
            return cachedData;
          }
        );
      } else {
        console.error('useManualMatch, cached is null');
      }
    },
  });

  const suggestedSong = data?.forceSongInputMatching.suggestedSong;

  const handleSubmit = (manualMatchingId: string, manualMatchingType: string) => {
    if (!manualMatchingType || !songInputId) {
      return Promise.resolve(false);
    }

    const variables: ForceSongInputMatchingMutationVariables = { songInputId, contentPackId: contentPack.id };

    if (manualMatchingType === 'SP3') {
      variables.songId = manualMatchingId;
    } else if (manualMatchingType === 'DDEX') {
      variables.ddexSongId = manualMatchingId;
    } else {
      variables.itunesId = Number(manualMatchingId);
    }

    return forceSongInputMatching({ variables }).then((res) => {
      return !!res.data?.forceSongInputMatching.curationSong;
    });
  };
  return [suggestedSong, handleSubmit];
};
