import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Col, Dropdown, DropdownButton, Image, Modal, Row, Spinner, Table } from 'react-bootstrap';
import {
  BatchContentModerationListQuery,
  BatchContentModerationListQueryVariables,
  ModerateContentItemMutation,
  ModerateContentItemMutationVariables,
  ModeratedContentItemNoteType,
  ModeratedContentItemState,
  ModerationContentItemsQuery,
  ModerationContentItemsQueryVariables,
  SetModeratorToContentPackMutation,
  SetModeratorToContentPackMutationVariables,
  UpdateLocaleForModerationContentItemMutation,
  UpdateLocaleForModerationContentItemMutationVariables,
} from '../../../../../__gqltypes__';
import CustomPagination from '../../components/Pagination';
import { ALL_LOCALES, displayLocaleWithFlag, SUPPORTED_LOCALE } from '../../utils/locales';
import mutations from '../../utils/mutations';
import queries from '../../utils/queries';
import ContentItem from './components/ContentModerationContentItem';
import ContentModerationLyricsModal from './components/ContentModerationLyricsModal';
import ContentModerationNoteModal from './components/ContentModerationNoteModal';
import { getLeftToDoForPlaylists, getTotalLeftToDo } from './components/utils';
import './ContentModeration.css';

type ModeratorPack = BatchContentModerationListQuery['batchContentModerationList']['list'][0]['packs'][0];

const CONTENT_PAGE_SIZE = 100;

// @ts-ignore
export default ({ history, location }) => {
  const urlParams = Object.fromEntries(new URLSearchParams(window.location.search).entries()); // {locale, playlistId, page}
  /** REACT STATES */
  const [moderatorLocale, setModeratorLocale] = useState<SUPPORTED_LOCALE>(
    (urlParams.locale as SUPPORTED_LOCALE) ?? 'en-US'
  );
  const [moderatorPack, setModeratorPack] = useState<ModeratorPack | null>(null);
  const [page, setPage] = useState(parseInt(urlParams.page ?? 1, 10)); // page is 1-based
  const [showQuestionableModal, setShowQuestionableModal] = useState<string | null>(null);
  const [showErrorModal, setShowErrorModal] = useState<string | null>(null);
  const [showLocaleModal, setShowLocaleModal] = useState<string | null>(null);
  const [showLyricsModal, setShowLyricsModal] = useState<{ title: string; artist: string } | null>(null);
  const [showToBeReviewedOnly, setShowToBeReviewedOnly] = useState<boolean>(false);
  const [showBigPicture, setShowBigPicture] = useState<string | null>(null);

  /** GQL QUERIES */
  // get current batch first
  const { data: batchData, loading: batchLoading } = useQuery<
    BatchContentModerationListQuery,
    BatchContentModerationListQueryVariables
  >(queries.GET_BATCH_MODERATION_LIST, {
    variables: { pagination: { limit: 1, offset: 0 } },
  });
  const [refetchStatsQuery] = useLazyQuery<BatchContentModerationListQuery, BatchContentModerationListQueryVariables>(
    queries.GET_BATCH_MODERATION_STATS,
    {
      variables: { pagination: { limit: 1, offset: 0 } },
      fetchPolicy: 'network-only',
    }
  );

  // then get a list of 100 content items for the selected playlist
  const { data, loading, refetch } = useQuery<ModerationContentItemsQuery, ModerationContentItemsQueryVariables>(
    queries.GET_MODERATION_CONTENT_ITEM,
    {
      variables: {
        batchId: batchData?.batchContentModerationList?.list?.[0]?.id ?? '',
        locale: moderatorLocale,
        packId: moderatorPack?.curationContentPack.originId,
        pagination: { limit: CONTENT_PAGE_SIZE, offset: (page - 1) * CONTENT_PAGE_SIZE },
      },
      skip: !moderatorPack,
      onCompleted: () => {
        updateUrl({ playlistId: moderatorPack?.curationContentPack.originId, locale: moderatorLocale, page });
      },
    }
  );

  /** GQL MUTATIONS */
  const [moderateContentItemFn] = useMutation<ModerateContentItemMutation, ModerateContentItemMutationVariables>(
    mutations.MODERATE_CONTENT_ITEM
  );
  const [moderateContentItemLocale] = useMutation<
    UpdateLocaleForModerationContentItemMutation,
    UpdateLocaleForModerationContentItemMutationVariables
  >(mutations.UPDATE_CONTENT_ITEM_LOCALE);
  const [setModeratorToContentPackFn] = useMutation<
    SetModeratorToContentPackMutation,
    SetModeratorToContentPackMutationVariables
  >(mutations.SET_MODERATOR_TO_CONTENT_PACK);

  /** REACT EFFECTS/CALLBACKS */
  useEffect(() => {
    if (batchData && urlParams.playlistId) {
      const matchingPlaylist = batchData.batchContentModerationList.list[0].packs.find(
        ({ curationContentPack }) => curationContentPack.originId === urlParams.playlistId
      );
      if (matchingPlaylist) {
        setModeratorPack(matchingPlaylist);
      }
    }
  }, [batchData?.batchContentModerationList?.list[0]?.id, urlParams.playlistId]);

  // Updating url on search to make url sharing easier
  const updateUrl = useCallback(
    (term) => {
      const updatedTerms = { ...term };
      console.log(updatedTerms);
      const queryString = Object.keys(updatedTerms)
        .filter((param) => updatedTerms[param] !== '' && updatedTerms[param] != null)
        .map((param) => `${param}=${updatedTerms[param] ?? ''}`)
        .join('&');

      history.push(`${window.location.pathname}?${queryString}`);
    },
    [history]
  );

  /** GQL HANDLERS */

  const onStatusUpdateWithModal = (id: string, state: ModeratedContentItemState) => {
    if (state === ModeratedContentItemState.QUESTIONABLE) {
      onQuestionableUpdate(id);
    } else if (state === ModeratedContentItemState.CLEAN || state === ModeratedContentItemState.EXPLICIT) {
      onStatusUpdate(id, state);
    } else if (state === ModeratedContentItemState.ERROR) {
      onErrorReportUpdate(id);
    }
  };
  const onStatusUpdate = (
    id: string,
    state: ModeratedContentItemState,
    note?: string,
    noteType?: ModeratedContentItemNoteType
  ) => {
    const variables: ModerateContentItemMutationVariables = { input: { id, state } };
    if (note) {
      variables.input.note = note;
    }
    if (noteType) {
      variables.input.noteType = noteType;
    }

    moderateContentItemFn({
      variables,
    }).then(() => {
      refetchStatsQuery();
    });

    if (!moderatorPack?.moderator) {
      setModeratorToContentPackFn({
        variables: {
          input: {
            batchId: batchData?.batchContentModerationList.list[0].id ?? '',
            packId: moderatorPack?.curationContentPack.originId ?? '',
          },
        },
      });
    }

    if (!moderatorPack?.moderator) {
      setModeratorToContentPackFn({
        variables: {
          input: {
            batchId: batchData?.batchContentModerationList.list[0].id ?? '',
            packId: moderatorPack?.curationContentPack.originId ?? '',
          },
        },
      });
    }

    handleClose();
  };

  const changeLocale = (_locale: string) => {
    setModeratorLocale(_locale as SUPPORTED_LOCALE);
  };

  /** MODAL HANDLERS */
  const onErrorReportUpdate = (id: string) => {
    setShowErrorModal(id);
  };
  const onQuestionableUpdate = (id: string) => {
    setShowQuestionableModal(id);
  };
  const onLocaleUpdate = (id: string) => {
    setShowLocaleModal(id);
  };
  const onShowLyrics = (title: string, artist: string) => {
    setShowLyricsModal({ title, artist });
  };

  const handleClose = () => {
    setShowLocaleModal(null);
    setShowErrorModal(null);
    setShowQuestionableModal(null);
    setShowLyricsModal(null);
  };

  const updateContentItemLocale = async (id: string | null, newLocale: string) => {
    if (id) {
      await moderateContentItemLocale({ variables: { input: { id, locale: newLocale } } });
    }
    handleClose();
  };

  const onClickPagination = (index: number) => {
    setPage(index);
  };

  const onShowBigPicture = (picture: string | null) => {
    setShowBigPicture(picture);
  };

  /** VARIABLES */
  const batchContent = batchData?.batchContentModerationList.list[0];

  const moderationContentItemsList = (data?.moderationContentItems?.list ?? []).filter(
    (l) => l.locale === moderatorLocale
  );
  const totalSongsToReview = data?.moderationContentItems?.totalCount ?? 0;
  const maxPage = Math.ceil((totalSongsToReview ?? 0) / CONTENT_PAGE_SIZE);

  const orderedPacks = useMemo(() => {
    return _.sortBy(batchContent?.packs ?? [], 'curationContentPack.name');
  }, [batchContent?.packs.length]);

  if (batchLoading) {
    return (
      <div className="p-3">
        <Spinner animation="border" />
      </div>
    );
  }

  return (
    <>
      <Row className="justify-content-between mb-2">
        <Col lg={4}>
          <h1>Song Moderation</h1>
        </Col>
      </Row>
      <Card>
        <Card.Body>
          <Row>
            <Col>
              Locale: {displayLocaleWithFlag(moderatorLocale)} ({getTotalLeftToDo(batchContent?.stats, moderatorLocale)}
              )
              <DropdownButton as="span" id="dropdown-item-button" className="m-2" size="sm" title="Change locale">
                {ALL_LOCALES.map((f) => (
                  <Dropdown.Item key={f} as="button" onClick={() => changeLocale(f)}>
                    {displayLocaleWithFlag(f)} ({getTotalLeftToDo(batchContent?.stats, f)})
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              Playlist: {moderatorPack?.curationContentPack.name} (
              {getLeftToDoForPlaylists(
                batchContent?.stats,
                moderatorLocale,
                moderatorPack?.curationContentPack.originId
              )}
              )
              <DropdownButton as="span" id="dropdown-item-button" className="m-2" size="sm" title="Change Playlist">
                {orderedPacks.map((p) => (
                  <Dropdown.Item key={p.id} as="button" onClick={() => setModeratorPack(p)}>
                    {p.curationContentPack.name}
                    {!!p.moderator && <span> - {p.moderator?.emailCredential}</span>} (
                    {getLeftToDoForPlaylists(batchContent?.stats, moderatorLocale, p.curationContentPack.originId)})
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {loading && <Spinner animation="border" />}
      {data && (
        <>
          <Row className="mb-4 mt-2">
            <Col>
              <Card>
                <Card.Body>
                  In order to present our music catalog to a wide audience, we need to mark any songs with content that
                  would be inappropriate for kids aged 4 years old as &quot;questionable&quot;. This will only hide the
                  song on devices where the rating is appropriate for kids, but not for adults. We are using the
                  following criteria to judge whether a song would be considered questionable.
                  <br />
                  <br />
                  <b>Explicit language in audio sample:</b>
                  <ul>
                    <li>
                      Extreme vulgarity (&quot;shit&quot;, &quot;fuck&quot;, the N-word, etc or any variants thereof)
                    </li>
                    <li>Mild vulgarity (&quot;damn&quot;, &quot;hell&quot;, &quot;ass&quot;, &quot;bitch&quot; etc)</li>
                    <li>
                      If a curse word is censored but still audible (via backmasking, pitching, or partial muting), it
                      should be marked as questionable.
                    </li>
                    <li>
                      If a curse word is completely muted, and the rest of the audio sample has nothing else
                      objectionable, it should be marked as clean.
                    </li>
                  </ul>
                  You can find a comprehensive but not complete list of problematic words at the following link:{' '}
                  <a href="/sp3/playlist/moderation/words">List of banned words</a>
                  <br />
                  <br />
                  <b>Inappropriate content in audio sample:</b>
                  <ul>
                    <li>Sexual dialogue</li>
                    <li>Alcohol consumption</li>
                    <li>Drug use (including tobacco/cigarettes)</li>
                    <li>Gang violence</li>
                    <li>Guns (including sound effects of gunshots)</li>
                    <li>Abuse (physical, mental, sexual)</li>
                  </ul>
                  Please make sure you listen to the 30 second audio sample in its entirety. Links to lyrics on a third
                  party site are included, but they may not always be 100% accurate; it is necessary to verify by
                  listening. If a song has objectionable content in its lyrics outside of the section that is audible in
                  the 30 second sample, as long as it does not appear in the audio sample, it is fine to mark as clean.
                  <br />
                  <br />
                  We also need to make sure that song names, artist names, and album covers have no objectionable
                  content as per the criteria above. If an album cover includes text or visual depictions of any of the
                  inappropriate content triggers, please mark it as questionable. In general, if you have even a mild
                  concern or are unsure that a song&apos;s content would be inappropriate for a young child of 4 years,
                  please be strict in your judgment and mark the song questionable.
                  <br />
                  <br />
                  If you notice an error with the song in its audio sample, title, or artist name, please do not mark
                  the song as &quot;questionable&quot;, but instead use the &quot;Report Error&quot; button, and
                  describe what is wrong. Some examples of errors include:
                  <ul>
                    <li>Audio sample does not match the artist and title</li>
                    <li>Audio sample is silent in the first several seconds</li>
                    <li>Spelling error in artist name or song title</li>
                    <li>
                      Song does not appear to match the theme of the playlist (ie, a techno song in a classic rock
                      playlist)
                    </li>
                  </ul>
                  When reporting an error, please include if you believe the song would be considered clean or
                  questionable after having the error fixed.
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Table striped bordered className="mb-4">
            <thead>
              <tr>
                <th>Picture</th>
                <th>IDs</th>
                <th>Title/Artist</th>
                <th>Sample</th>
                <th>
                  Action{' '}
                  <i
                    role="button"
                    tabIndex={0}
                    className={`fas fa-filter ${showToBeReviewedOnly ? 'activated-filter' : 'disabled-filter'}`}
                    onClick={() => setShowToBeReviewedOnly((prevValue) => !prevValue)}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {moderationContentItemsList
                .filter((item) =>
                  showToBeReviewedOnly ? item.state === ModeratedContentItemState.TO_BE_REVIEWED : true
                )
                .map((moderationContentItem) => (
                  <ContentItem
                    key={moderationContentItem.id}
                    item={moderationContentItem}
                    onStatusUpdate={onStatusUpdateWithModal}
                    onLocaleUpdate={onLocaleUpdate}
                    onShowLyrics={onShowLyrics}
                    onShowBigPicture={onShowBigPicture}
                  />
                ))}
            </tbody>
          </Table>
          <div className="d-flex justify-content-end">
            <CustomPagination active={page} num={maxPage} onClick={onClickPagination} />
          </div>
        </>
      )}

      <ContentModerationNoteModal
        show={!!showQuestionableModal}
        onHide={handleClose}
        title="Explain why it's Questionable"
        onUpdate={(note, noteType) =>
          showQuestionableModal &&
          onStatusUpdate(showQuestionableModal, ModeratedContentItemState.QUESTIONABLE, note, noteType)
        }
      />
      <ContentModerationNoteModal
        show={!!showErrorModal}
        onHide={handleClose}
        title="Explain why it's an Error"
        forcedNoteType={ModeratedContentItemNoteType.ERROR}
        onUpdate={(note, noteType) =>
          showErrorModal && onStatusUpdate(showErrorModal, ModeratedContentItemState.ERROR, note, noteType)
        }
      />
      <Modal show={!!showBigPicture} onHide={() => onShowBigPicture(null)}>
        <Modal.Header closeButton />

        <Modal.Body>
          <Image width={450} src={showBigPicture ?? undefined} />
        </Modal.Body>
      </Modal>
      <ContentModerationLyricsModal
        show={!!showLyricsModal}
        onHide={handleClose}
        title={showLyricsModal?.title}
        artist={showLyricsModal?.artist}
      />

      <Modal show={!!showLocaleModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update song locale</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Select a new locale:
          <DropdownButton as="span" id="dropdown-item-button" className="m-2" size="sm" title="Change locale">
            {ALL_LOCALES.map((f) => (
              <Dropdown.Item key={f} as="button" onClick={() => updateContentItemLocale(showLocaleModal, f)}>
                {displayLocaleWithFlag(f)}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </Modal.Body>
      </Modal>
    </>
  );
};
