import { useQuery } from '@apollo/client';
import { useCallback } from 'react';
import { Col, Nav, Row, Spinner, Tab } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import { BatchContentModerationListQuery, BatchContentModerationListQueryVariables } from '../../../../../__gqltypes__';
import queries from '../../utils/queries';
import AdminContentModerationBatch from './components/AdminContentModerationBatch';
import AdminContentModerationCreateBatch from './components/AdminContentModerationCreateBatch';

const AdminContentModeration = () => {
  // fetch data
  const { data, loading, refetch } = useQuery<
    BatchContentModerationListQuery,
    BatchContentModerationListQueryVariables
  >(queries.GET_BATCH_MODERATION_LIST, {
    variables: { pagination: { limit: 20, offset: 0 } },
  });
  const history = useHistory();
  // parse id params from url
  const { id: currentBatchId } = useParams<{ id: string }>();
  const setActiveList = useCallback(
    (listId) => {
      const pathName = window.location.pathname;
      let originPath = pathName;
      if (!pathName.endsWith('admin-moderation')) {
        const elmts = pathName.split('/');
        elmts.pop();
        originPath = elmts.join('/');
      }
      history.push(`${originPath}/${listId}`);
    },
    [history]
  );

  const list = data?.batchContentModerationList.list ?? [];

  return loading ? (
    <div className="p-4">
      <Spinner animation="border" />
    </div>
  ) : (
    // @ts-ignore
    <Tab.Container className="h-100" activeKey={currentBatchId} onSelect={setActiveList}>
      <Row className="mb-5 h-100 cp-list">
        <Col>
          <Nav.Item className="mb-3">
            <AdminContentModerationCreateBatch refetchLists={refetch} setActiveList={setActiveList} />
          </Nav.Item>
          <Nav variant="pills">
            {list.map((b) => (
              <Nav.Item key={b.id} className="w-100 text-center position-relative">
                <Nav.Link eventKey={b.id} className="justify-content-center">
                  {b.name}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Col>
        <Col className="col-9">
          <Tab.Content className="h-100">
            {currentBatchId && <AdminContentModerationBatch batchId={currentBatchId} />}
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
};

export default AdminContentModeration;
