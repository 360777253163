import React from 'react';
import { ToggleButtonGroupProps } from 'react-bootstrap';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';

type Props = {
  value: string;
  children: React.ReactElement[];
  defaultValue: string;
  className?: string;
} & Pick<ToggleButtonGroupProps<string>, 'onChange'>;

const ToggleButtonSelector = ({ value, onChange, children, defaultValue, className }: Props) => {
  return (
    <ToggleButtonGroup
      className={className}
      type="radio"
      name="options"
      defaultValue={defaultValue}
      onChange={onChange}
    >
      {React.Children.map(children, (child) =>
        // @ts-ignore
        React.cloneElement(child, { variant: value === child.props.value ? 'primary' : 'outline-primary' })
      )}
    </ToggleButtonGroup>
  );
};

export default ToggleButtonSelector;

export const ToggleButtonOption = ({
  value,
  children,
  ...rest
}: {
  value: string;
  children: (number | string | React.ReactElement)[] | (number | string | React.ReactElement);
}) => (
  <ToggleButton value={value} {...rest}>
    {children}
  </ToggleButton>
);
