import { useApolloClient } from '@apollo/client';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { CreateContentPackListMutation, CreateContentPackListMutationVariables } from '../../../../../__gqltypes__';
import mutations from '../../utils/mutations';

type Props = {
  refetchLists: () => Promise<unknown>;
  setActiveList: (listId: string) => void;
};

const CreateContentPackList = ({ refetchLists, setActiveList }: Props) => {
  const client = useApolloClient();

  const [creating, setCreating] = useState(false);
  const askCreate = useCallback(() => setCreating(true), [setCreating]);
  const cancelCreate = useCallback(() => setCreating(false), [setCreating]);

  const nameFieldRef = useRef<HTMLInputElement>(null);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  useEffect(() => {
    if (creating && nameFieldRef.current !== null) {
      nameFieldRef?.current.focus();
    } else {
      setName('');
      setDescription('');
    }
  }, [creating]);

  const [submitting, setSubmitting] = useState(false);

  const doCreate = useCallback(
    async (e) => {
      e.preventDefault();
      setSubmitting(true);
      let res;
      try {
        res = await client.mutate<CreateContentPackListMutation, CreateContentPackListMutationVariables>({
          mutation: mutations.CREATE_CONTENT_PACK_LIST,
          variables: {
            input: {
              name,
              description,
              contentPackIds: [],
            },
          },
        });
      } catch (err) {
        console.error(err);
        setSubmitting(false);
        return;
      }
      setCreating(false);
      await refetchLists();
      setSubmitting(false);
      if (res.data) {
        setActiveList(res.data.createContentPackList.contentPackListId);
      }
    },
    [refetchLists, setActiveList, client, name, description]
  );

  return (
    <>
      <Button className="w-100" variant="success" onClick={askCreate}>
        <i className="fas fa-plus mr-2" />
        New content pack list
      </Button>

      <Modal show={creating} onHide={cancelCreate}>
        <Modal.Header closeButton>
          <Modal.Title>Create content pack list</Modal.Title>
        </Modal.Header>
        <Form onSubmit={doCreate}>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control autoFocus value={name} onChange={(e) => setName(e.target.value)} ref={nameFieldRef} />
              <span>This must be unique.</span>
            </Form.Group>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control value={description} onChange={(e) => setDescription(e.target.value)} />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={cancelCreate} disabled={submitting}>
              Cancel
            </Button>
            <Button variant="success" type="submit" disabled={submitting}>
              {submitting && <Spinner as="span" animation="border" size="sm" className="mr-2" />}
              Create
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default CreateContentPackList;
