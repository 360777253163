import _ from 'lodash';

class TrendLineFitter {
  count = 0;

  sumX = 0;

  sumX2 = 0;

  sumXY = 0;

  sumY = 0;

  minx = 1e100;

  maxx = -1e100;

  maxy = -1e100;

  add(x: number, y: number) {
    this.count++;
    this.sumX += x;
    this.sumX2 += x * x;
    this.sumXY += x * y;
    this.sumY += y;
    if (x < this.minx) this.minx = x;
    if (x > this.maxx) this.maxx = x;
    if (y > this.maxy) this.maxy = y;
  }

  f(x: number) {
    const det = this.count * this.sumX2 - this.sumX * this.sumX;
    const offset = (this.sumX2 * this.sumY - this.sumX * this.sumXY) / det;
    const scale = (this.count * this.sumXY - this.sumX * this.sumY) / det;
    return offset + x * scale;
  }

  f0() {
    const det = this.count * this.sumX2 - this.sumX * this.sumX;
    const offset = (this.sumX2 * this.sumY - this.sumX * this.sumXY) / det;
    const scale = (this.count * this.sumXY - this.sumX * this.sumY) / det;

    //  Get x when y = 0
    const xo = -offset / scale;
    return xo;
  }

  scale() {
    const det = this.count * this.sumX2 - this.sumX * this.sumX;
    const scale = (this.count * this.sumXY - this.sumX * this.sumY) / det;

    return scale;
  }
}

export function trendline(dataset: any) {
  const fitter = new TrendLineFitter();
  for (let i = 0; i < dataset.data.length; i++) {
    fitter.add(i, dataset.data[i]);
  }
  const res = [];
  for (let i = 0; i < dataset.data.length; i++) {
    res.push(fitter.f(i));
  }
  return res;
}

export function rollingAverage(dataset: any, rollbackDays = 3) {
  // perform a 3 pt rolling average.
  const values = dataset.data;

  const rollData = [...values];
  if (rollbackDays === 0) return rollData;

  for (let i = 1; i < values.length; i++) {
    let tmp = 0;
    for (let j = i; j > i - rollbackDays; j--) {
      tmp += rollData[j] ?? rollData[i];
    }
    rollData[i] = tmp / rollbackDays;
  }

  return rollData;
}

export function average(dataset: any, segment?: [number, number]) {
  let values: number[] = dataset.data;
  if (segment) {
    values = values.slice(segment[0], segment[1]);
  }
  return (
    values.reduce((a: number, b: number | null) => (_.isNull(b) ? a : a + b), 0) /
    values.filter((a) => !_.isNull(a)).length
  );
}
