import { gql } from '@apollo/client';

export const BASIC_SONG_INFO_FRAGMENT = gql`
  fragment BasicSongInfo on CurationSong {
    isrc
    title
    artist
    album
    albumYear
    label
    subLabel
    defaultSample
    picture
    state
    isExplicit
    questionableStatus
    isDDEXSong
  }
`;

export const DDEX_BASIC_SONG_INFO_FRAGMENT = gql`
  fragment DDEXBasicSongInfo on DDEXSong {
    id
    label
    subLabel
    isrc
    title
    artist
    album
    albumId
    releaseDate
    isWhitelisted
    artwork
    sample
    isExplicit
  }
`;

export const ITUNES_BASIC_SONG_INFO_FRAGMENT = gql`
  fragment ItunesBasicSongInfo on ItunesSong {
    id
    title
    artist
    album
    albumId
    albumArtwork
    albumYear
    isWhitelisted
    parentalAdvisory
    sample
    isrc
    label
    subLabel
  }
`;

export const SONG_DETAILS_FRAGMENT = gql`
  fragment SongDetails on CurationSong {
    externalLinks {
      itunes {
        us
        gb
        fr
        br
        de
        es
      }
      spotify {
        us
        gb
        fr
        br
        de
        es
      }
    }
  }
`;

export const SONG_CUSTOM_FIELDS_FRAGMENT = gql`
  fragment SongCustomFields on CurationSong {
    displayTitle
    displayArtist
    movieTitle
    jesterTitle
    leadSinger
    introSample
    composer
  }
`;

export const HURDLE_CATALOG_ITEM_FRAGMENT = gql`
  fragment HurdleCatalogItemFragment on HurdleCatalogItem {
    id
    categoryId
    excludeFromAutogeneration
    excludeFromCheck
    offset
    item {
      ... on HurdleSongItem {
        id
        asset
        song {
          id
          title
          artist
          picture
        }
      }
    }
    addedAt
    removed
    offset
  }
`;
