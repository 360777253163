import * as React from 'react';
import { Button, Col, Form, Modal, ModalProps, Row } from 'react-bootstrap';
import { BadgeType } from '../../../../../../__gqltypes__';

type Props = {
  loading: boolean;
  onAdd: (name: string, type: BadgeType) => void;
} & ModalProps;

const CreateBadgeModal = ({ show, onHide, onAdd, loading }: Props) => {
  const [type, setType] = React.useState<BadgeType>(BadgeType.CATEGORY);

  const [name, setName] = React.useState('');

  const handleSubmit = () => {
    if (!name.length) {
      alert('Please, enter valid name');
      return;
    }
    onAdd(name, type);
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Body>
        <Row>
          <Col xs={3}>Name</Col>
          <Col xs={6}>
            <Form.Control
              name="diamondPrize"
              required
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={3}>Type</Col>
          <Col xs={6}>
            {/* @ts-ignore */}
            <Form.Control required name="type" as="select" value={type} onChange={(e) => setType(e.target.value)}>
              {Object.keys(BadgeType).map((type) => (
                <option value={type} key={type}>
                  {type}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Row>
        <Row className="mt-3 pl-1">
          <Button disabled={loading} onClick={handleSubmit}>
            Add
          </Button>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default CreateBadgeModal;
