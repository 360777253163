import { useApolloClient } from '@apollo/client';
import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ListGroup, Modal, ModalProps, Spinner } from 'react-bootstrap';
import { DdexJobLogsQuery } from '../../../../../../__gqltypes__';
import { GET_DDEX_JOB_LOGS } from '../graphql';

const LOGS_PER_PAGE = 100;

type DdexJobLog = DdexJobLogsQuery['analytics']['ddexJobLogs']['list'][0];
type DetailsProps = {
  log: DdexJobLog | null;
} & ModalProps;

const DDEXJobLogDetails = ({ onHide, show, log }: DetailsProps) => {
  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Log details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <pre className="w-auto">{JSON.stringify(log, null, 2)}</pre>
      </Modal.Body>
    </Modal>
  );
};

const DDEXJobLogs = ({ jobId }: { jobId: string }) => {
  const client = useApolloClient();
  const [pageCount, setPageCount] = useState(1);
  const [pages, setPages] = useState<DdexJobLog[][]>([]);

  const [loading, setLoading] = useState(false);
  const loadNextPage = useCallback(async () => {
    console.log('loadNextPage called');
    if (loading || pages.length >= pageCount) {
      return;
    }
    const pageIdx = pages.length;
    console.log(`Loading page ${pageIdx}...`);
    setLoading(true);
    const res = await client.query<DdexJobLogsQuery>({
      query: GET_DDEX_JOB_LOGS,
      variables: {
        jobId,
        pagination: {
          limit: LOGS_PER_PAGE,
          offset: pageIdx * LOGS_PER_PAGE,
        },
      },
    });
    const page = res.data.analytics.ddexJobLogs;
    const newPageCount = Math.ceil(page?.totalCount / LOGS_PER_PAGE);
    if (pageCount !== newPageCount) {
      setPageCount(newPageCount);
    }
    setPages([...pages, page.list]);
    setLoading(false);
    console.log(`Loaded page ${pageIdx}:`, page);
  }, [client, loading, pageCount, pages, jobId]);

  const scrollableRef = useRef(null);
  const guardRef = useRef<HTMLAnchorElement>(null);
  useEffect(() => {
    const interObs = new IntersectionObserver(
      (entries) => {
        if (entries.some((entry) => entry.isIntersecting)) {
          loadNextPage();
        }
      },
      {
        root: scrollableRef.current,
      }
    );
    // @ts-ignore
    interObs.observe(guardRef.current);
    return () => interObs.disconnect();
  }, [loadNextPage, scrollableRef, guardRef]);

  const [selectedLog, setSelectedLog] = useState<DdexJobLog | null>(null);
  const [showLogDetails, setShowLogDetails] = useState(false);
  const doShowLogData = (log: DdexJobLog) => {
    setSelectedLog(log);
    setShowLogDetails(true);
  };

  return (
    <div className="mb-4">
      <p>Logs</p>
      <div
        ref={scrollableRef}
        style={{
          borderRadius: '0.35rem',
          fontSize: '0.85rem',
          height: '20em',
          overflowY: 'scroll',
          border: '1px solid rgba(0, 0, 0, 0.125)',
          resize: 'vertical',
        }}
      >
        <ListGroup variant="flush">
          {pages.flatMap((page, pageIdx) =>
            page.map((log: DdexJobLog, logIdx: number) => {
              const variant = { error: 'danger', warn: 'warning' }[log.level];
              const color = { debug: 'text-gray-600', info: 'text-primary' }[log.level];
              const date = new Date(log.timestamp);
              const hasData = log.extraData ? Object.entries(log.extraData).length > 0 : false;
              const key = pageIdx * LOGS_PER_PAGE + logIdx;
              return (
                <ListGroup.Item key={key} variant={variant} className={`mb-0 p-1 text-monospace ${color ?? ''}`}>
                  <b title={date.toISOString()}>[{moment(date).format('MMM D HH:mm')}]</b>{' '}
                  <b>{/* [{log.source}]  */}</b>
                  {log.message}{' '}
                  {hasData && (
                    // @ts-ignore
                    <span role="button" tabIndex="0" onClick={() => doShowLogData(log)}>
                      <i className="fas fa-info-circle" style={{ fontSize: '0.95rem' }} />
                    </span>
                  )}
                </ListGroup.Item>
              );
            })
          )}
          <ListGroup.Item key="guard" ref={guardRef} variant="dark" className="mb-0 p-2">
            {pages.length < pageCount ? (
              <span>Loading logs... {loading && <Spinner size="sm" animation="border" />}</span>
            ) : (
              'End of logs.'
            )}
          </ListGroup.Item>
        </ListGroup>
      </div>
      <hr />
      <DDEXJobLogDetails show={showLogDetails} onHide={() => setShowLogDetails(false)} log={selectedLog} />
    </div>
  );
};

export default DDEXJobLogs;
