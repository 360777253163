import React, { cloneElement } from 'react';
import Container from 'react-bootstrap/Container';
import useDashboard from '../controllers/useDashboard';
import { QueryTemplates } from '../models/FilterManager';
import { DEFAULT_FILTERS, Filter } from '../services/filters';

type Props = {
  children?: JSX.Element;
  queries: QueryTemplates;
  id: string;
  filters: Record<string, Filter>;
  title?: string;
};

const DashboardContainer = ({ children, queries, id, filters = DEFAULT_FILTERS, title }: Props) => {
  const { loading, data, onRefresh, errors, metadataList } = useDashboard(queries, id, filters);
  return (
    <div id="wrapper" className="h-100">
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <Container className="text-center">
            <h1>{title || id}</h1>
          </Container>
          <Container>
            {React.Children.map(children, (child) => {
              if (child?.props.id && child.props.id === 'WidgetContainer') {
                return cloneElement(child, {
                  loading,
                  queryData: data,
                  onRefresh,
                  metadataList,
                  errors,
                });
              }
              if (child?.props.id && (child.props.id === 'SlicerContainer' || child.props.id.includes('Slicing'))) {
                return cloneElement(child, { onRefresh });
              }
              return child;
            })}
          </Container>
        </div>
      </div>
    </div>
  );
};

export default DashboardContainer;
