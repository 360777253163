/* eslint-disable @typescript-eslint/no-non-null-assertion */
import _ from 'lodash';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import { useEffect, useReducer, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Tab from 'react-bootstrap/Tab';
import Table from 'react-bootstrap/Table';
import {
  GetMonthlyPassesQuery,
  InitMonthlyPassMissionsMutation,
  InitMonthlyPassMissionsMutationVariables,
  ItemAvailability,
  LevelConfigMonthlyPassInput,
  LevelRewardInput,
  LevelRewardInputType,
  UpdateMonthlyPassMissionsMutation,
  UpdateMonthlyPassMissionsMutationVariables,
  UpdateMonthlyPassMutation,
  UpdateMonthlyPassMutationVariables,
  UpdateTeamHeatIterationMilestonesMutation,
  UpdateTeamHeatIterationMilestonesMutationVariables,
} from '../../../../../__gqltypes__';
import { LoadingLogo } from '../../../devtools/components/Modal';
import Chip from '../../components/Chip';
import EditRewardsModal from '../../components/EditRewardsModal';
import EditValue from '../../components/EditValue';
import SheetUploader from '../../components/SheetUploader';
import {
  CREATE_MONTHLY_PASS,
  GET_MONTHLY_PASSES,
  INIT_MONTHLY_PASS_MISSIONS,
  UPDATE_MONTHLY_PASS,
  UPDATE_MONTHLY_PASS_MISSIONS,
  UPDATE_TEAM_HEAT_ITERATION,
} from '../../components/apolloQueries';
import monthDataReducer, { tierName } from '../../components/monthDataReducer';
import { MonthlyPassMissionsData } from './MonthlyPassMissionsData';
import { RankGroup, RankGroupValue } from '../Tournament/components/RankGroups';
import { getReward } from '../Tournament/utils';
import { TeamHeatIteration, TeamHeatIterationValue } from './components/TeamHeatIteration';

type MonthlyPass = GetMonthlyPassesQuery['liveOps']['monthlyPasses']['list'][0];
type LevelConfigMonthlyPass = MonthlyPass['levels'][0];
type BasicReward = LevelConfigMonthlyPass['basicReward'];
type PlusReward = NonNullable<LevelConfigMonthlyPass['plusReward']>;

const Month = ({ monthData }: { monthData: { id: string; month: number; year: number } }) => (
  <Nav.Item style={{ width: '100%' }} className="text-center">
    <Nav.Link eventKey={monthData.id}>
      {`${moment()
        .month(monthData.month - 1)
        .format('MMMM')} 
        ${monthData.year}`}
    </Nav.Link>
  </Nav.Item>
);

const MonthData = ({ monthData, refetch }: { monthData: MonthlyPass; refetch: () => void }) => {
  const [updateMonthlyPassFn, { loading: updateMonthLoading }] = useMutation<
    UpdateMonthlyPassMutation,
    UpdateMonthlyPassMutationVariables
  >(UPDATE_MONTHLY_PASS, {
    onCompleted: () => {
      console.log('it worked');
      refetch();
    },
    onError: () => {
      console.log('uh oh');
    },
  });
  const [initMissions, { loading: initMissionsLoading }] = useMutation<
    InitMonthlyPassMissionsMutation,
    InitMonthlyPassMissionsMutationVariables
  >(INIT_MONTHLY_PASS_MISSIONS, {
    onCompleted: () => {
      refetch();    },
  });

  const [updateMissions, { loading: updateMissionsLoading }] = useMutation<
    UpdateMonthlyPassMissionsMutation,
    UpdateMonthlyPassMissionsMutationVariables
  >(UPDATE_MONTHLY_PASS_MISSIONS, {
    onCompleted: () => {
      refetch();
    },
  });

  const [updateTeamHeatIteration, { loading: updateTeamHeatLoading }] = useMutation<
  UpdateTeamHeatIterationMilestonesMutation,
  UpdateTeamHeatIterationMilestonesMutationVariables
  >(UPDATE_TEAM_HEAT_ITERATION, {
    onCompleted: () => {
      refetch();
    },
  });

  const sanitizeRankGroups = (rankGroups: RankGroupValue[]): RankGroupValue[] => {
    const sanitizedRankGroups = rankGroups.map((rankGroup) => {
      return {
        ...rankGroup,
        rewards: rankGroup.rewards.map((reward) => getReward(reward))
      };
    });
    return sanitizedRankGroups;
  };

  const sanitizeTeamHeatIterations = (
    iterations:  Array<TeamHeatIterationValue | null>
  ): Array<TeamHeatIterationValue> => {
    const tmpIt = [...iterations];
    for (let i = 0; i < 9; ++i) {
      if (!tmpIt[i]) {
        tmpIt[i] = { milestonesReward: new Array<any>(4)};
      }
    }
    const sanitizedIterations = tmpIt.map((it) => {
        if (it) {
          return {
            milestonesReward: it.milestonesReward.map((reward) => reward && getReward(reward))
          };
        }
        return {
          milestonesReward: new Array<any>(4)
        };
      });
    return sanitizedIterations;
  };

  const sanitizeLevels = (levels: LevelConfigMonthlyPass[]): LevelConfigMonthlyPassInput[] => {
    const getType = (type: BasicReward['__typename'] | PlusReward['__typename']): LevelRewardInputType | null => {
      switch (type) {
        case 'CurrencyCoin':
          return LevelRewardInputType.CURRENCY_COIN;
        case 'CurrencyDiamond':
          return LevelRewardInputType.CURRENCY_DIAMOND;
        case 'CurrencyTournamentTicket':
          return LevelRewardInputType.CURRENCY_TOURNAMENT_TICKET;
        case 'CurrencyBoost':
          return LevelRewardInputType.CURRENCY_BOOST;
        case 'CurrencyCategoryFragment':
          return LevelRewardInputType.CURRENCY_CATEGORY_FRAGMENT;
        case 'Sticker':
          return LevelRewardInputType.STICKER;
        case 'ProfileFrame':
          return LevelRewardInputType.PROFILE_FRAME;
        case 'AppSkin':
          return LevelRewardInputType.APP_SKIN;
        case 'MonthlyBuff':
          return LevelRewardInputType.MONTHLY_BUFF;
        case 'ContentPackReward':
          return LevelRewardInputType.CONTENT_PACK_REWARD;
        case 'CurrencyMetaFragmentBronze':
          return LevelRewardInputType.CURRENCY_META_FRAGMENT_BRONZE;
        case 'CurrencyMetaFragmentSilver':
          return LevelRewardInputType.CURRENCY_META_FRAGMENT_SILVER;
        case 'CurrencyMetaFragmentGold':
          return LevelRewardInputType.CURRENCY_META_FRAGMENT_GOLD;
        default:
          return null;
      }
    };
    const getReward = (reward: BasicReward | PlusReward): LevelRewardInput | null => {
      switch (reward.__typename) {
        case 'CurrencyCoin':
        case 'CurrencyDiamond':
        case 'CurrencyTournamentTicket':
        case 'CurrencyCategoryFragment':
        case 'CurrencyMetaFragmentBronze':
        case 'CurrencyMetaFragmentSilver':
        case 'CurrencyMetaFragmentGold':
          return {
            itemId: null,
            type: getType(reward.__typename)!,
            amount: reward.amount,
          };
        case 'CurrencyBoost':
          return {
            itemId: reward.boostType,
            type: getType(reward.__typename)!,
            amount: reward.amount,
          };
        case 'MonthlyBuff':
          return {
            itemId: reward.buffType,
            type: getType(reward.__typename)!,
            amount: reward.multiplier,
          };
        case 'AppSkin':
        case 'ProfileFrame':
        case 'Sticker':
          return {
            itemId: reward.id,
            type: getType(reward.__typename)!,
            amount: 1,
          };
        case 'ContentPackReward':
          return {
            itemId: reward.contentPack.id,
            type: getType(reward.__typename)!,
            amount: 1,
          };
        default:
          return null;
      }
    };
    const newLevels = levels.map((level) => ({
    level: level.level,
      starts: moment.utc('2020-01-02').unix(),
      xpToNextLevel: level.xpToNextLevel,
      basicReward: getReward(level.basicReward)!,
      plusReward: level.plusReward ? getReward(level.plusReward) : null,
    }));
    return newLevels;
  };
  const [data, dispatch] = useReducer(monthDataReducer, {
    id: monthData.id,
    levels: sanitizeLevels(monthData.levels),
    rankGroups: sanitizeRankGroups(monthData.teamRankGroups),
    teamHeatIteration: sanitizeTeamHeatIterations(monthData.teamHeatIterations),
  });

  const [showEditRewardModal, setShowEditRewardModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedTier, setSelectedTier] = useState<tierName | null>(null);
  const [selectedLevel, setSelectedLevel] = useState<number | null>(null);
  const [selectedReward, setSelectedReward] = useState<LevelRewardInput | null>(null);

  const handleEdit = (tier: tierName, reward: LevelRewardInput, level: number) => {
    dispatch({
      type: 'editReward',
      level,
      tier,
      reward,
    });
  };
  const handleDelete = (tier: tierName, reward: LevelRewardInput, level: number) => {
    console.log('handleDelete', tier, reward, level);
    dispatch({
      type: 'deleteReward',
      level,
      tier,
      // reward,
    });
  };
  const handleClose = () => {
    setShowEditRewardModal(false);
  };
  const handleAddLevel = () => {
    dispatch({ type: 'addLevel' });
  };
  const handleRemoveLevel = () => {
    dispatch({ type: 'removeLevel' });
  };
  const handleXpChange = (xp: string, level: number) => {
    dispatch({ type: 'editXp', level, xp });
  };
  const handleChangeRankGroups = (newRankgroups: RankGroupValue[]) => {
    dispatch({ type: 'changedRankGroup', rankGroups: newRankgroups});
  };
  const handleChangeTeamIteration = (newTeamHeatIteration: Array<TeamHeatIterationValue>) => {
    dispatch({ type: 'changeTeamHeatIteration', teamHeatIteration: newTeamHeatIteration});
  };
  const handleSave = (newReward: LevelRewardInput) => {
    if (selectedTier != null && selectedLevel != null) {
      handleEdit(selectedTier, newReward, selectedLevel);
      setShowEditRewardModal(false);
    }
  };

  const handleUpload = () => {
    updateMonthlyPassFn({
      variables: {
        input: {
          id: monthData.id,
          levels: data.levels,
          rankGroups: data.rankGroups.map((rankGroup) => {
            return {
              minRank: _.toNumber(rankGroup.minRank),
              maxRank: _.toNumber(rankGroup.maxRank),
              rewards: rankGroup.rewards.map((reward) => ({
                type: reward.type,
                amount: _.toNumber(reward.amount),
                itemId: reward.itemId,
              }))
            };
          }),
        },
      },
    });
  };

  const handleUploadTeamHeat = () => {
    updateTeamHeatIteration({
      variables: {
        input: {
          id: monthData.id,
          milestonesReward: data.teamHeatIteration
          .map(val => val.milestonesReward.map(reward => reward)),
        }
      }
    });
  };

  const handleShow = (edit: boolean, tier: tierName, level: number, reward: LevelRewardInput | null) => {
    setSelectedReward(reward);
    setSelectedLevel(level);
    setSelectedTier(tier);
    setEditMode(edit);
    setShowEditRewardModal(true);
  };

  return (
    <>
      <LoadingLogo show={updateMonthLoading} />
      {monthData && (
        <>
          <Tab.Pane className="h-100" eventKey={data.id}>
            <EditRewardsModal
              isEdit={editMode}
              show={showEditRewardModal}
              tier={selectedTier}
              level={selectedLevel}
              reward={selectedReward}
              handleClose={handleClose}
              handleSave={handleSave}
              disableCurrencySpecialEvent
              liveOpsAvailabilities={[ItemAvailability.EARN]}
            />
            <Card className="mt-3 mb-3">
              <Card.Body className="text-center">
                <Card.Title>
                  Pass for month {monthData.month.toString().padStart(2, '0')} of {monthData.year}
                </Card.Title>
                {data && (
                  <Table bordered responsive hover>
                    <thead className="text-center">
                      <tr>
                        <th>Levels</th>
                        <th>XP to next level</th>
                        <th>Basic</th>
                        <th>Plus</th>
                      </tr>
                    </thead>
                    <tbody className="text-center">
                      {data.levels.map((levelData, lvl) => (
                        <tr key={lvl}>
                          <td style={{ verticalAlign: 'middle' }}>
                            Lvl
                            {levelData.level}
                          </td>
                          <td style={{ verticalAlign: 'middle' }}>
                            {lvl !== data.levels.length - 1 && (
                              <EditValue
                                name="XP"
                                value={levelData.xpToNextLevel}
                                handleValueChange={(xp) => handleXpChange(xp, lvl)}
                              />
                            )}
                          </td>
                          {Object.values(tierName).map((tier) => (
                            <td style={{ verticalAlign: 'middle' }} key={tier}>
                              {levelData[tier] ? (
                                <Chip
                                  reward={levelData[tier]!}
                                  key={tier + levelData[tier]}
                                  canDelete={tier !== 'basicReward'}
                                  btnAction={() => handleDelete(tier, levelData[tier]!, lvl)}
                                  globalAction={() => handleShow(true, tier, lvl, levelData[tier]!)}
                                />
                              ) : (
                                <Chip
                                  isAdd
                                  outlined
                                  globalAction={() => handleShow(false, tier, lvl, null)}
                                  btnAction={() => handleShow(false, tier, lvl, null)}
                                />
                              )}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
                <Button variant="success" style={{ alignSelf: 'center' }} onClick={handleAddLevel}>
                  Add a level
                </Button>
                <Button variant="danger" style={{ alignSelf: 'center', marginLeft: '1em' }} onClick={handleRemoveLevel}>
                  Remove a level
                </Button>
                <Button style={{ alignSelf: 'center', marginLeft: '1em' }} onClick={handleUpload}>
                  Save Changes
                </Button>
              </Card.Body>
            </Card>
            {monthData && monthData.missions ? (
              <MonthlyPassMissionsData
                monthId={monthData.monthId}
                refetch={refetch}
                monthlyPassMissions={monthData.missions}
                loading={updateMissionsLoading}
                onSave={(input) => {
                  updateMissions({ variables: { input } });
                }}
              />
            ) : (
              <Button
                size="sm"
                className="mb-2"
                disabled={initMissionsLoading}
                onClick={() =>
                  initMissions({ variables: { input: { monthId: monthData.monthId, forceReset: false } } })
                }
              >
                Generate missions
              </Button>
            )}
            <RankGroup 
              disabled={false}
              enableContentPack={true}
              rankgroups={data.rankGroups}
              onChangedRankgroups={handleChangeRankGroups}
              onUpload={handleUpload}
            />
            <TeamHeatIteration
              loading={updateTeamHeatLoading}
              iterationData={data.teamHeatIteration}
              onChangeIteration={handleChangeTeamIteration}
              onUpload={handleUploadTeamHeat}
            />
          </Tab.Pane>
        </>
      )}
    </>
  );
};
const MonthlyPassView = () => {
  const [createMonthlyPass, { loading: createMonthLoading }] = useMutation(CREATE_MONTHLY_PASS);

  const { data, loading, refetch } = useQuery<GetMonthlyPassesQuery>(GET_MONTHLY_PASSES, {
    fetchPolicy: 'no-cache',
  });

  const [months, setMonths] = useState<MonthlyPass[] | null>(null);
  const [activeMonth, setActiveMonth] = useState<string | null>(null);

  useEffect(() => {
    if (data) {
      const monthsData = data.liveOps.monthlyPasses.list;
      if (monthsData.length > 0) {
        setMonths(monthsData);
        const key = monthsData[0].id;
        setActiveMonth(key);
      }
    }
  }, [data]);

  const handleCreate = () => {
    createMonthlyPass().then(() => refetch());
  };

  return (
    <>
      <LoadingLogo show={loading} />
      {data && activeMonth && months && (
        <Tab.Container activeKey={activeMonth} onSelect={(e) => setActiveMonth(e)}>
          <Row className="mb-5" style={{ height: '100%' }}>
            <Col>
              <h1 className="text-center">Monthly Pass</h1>
              <Nav.Item className="mb-3">
                {createMonthLoading || loading ? (
                  <Spinner animation="border" />
                ) : (
                  <Button className="w-100" variant="success" onClick={handleCreate}>
                    Add a new monthly pass
                  </Button>
                )}
              </Nav.Item>
              <Nav variant="pills">
                {months.map((month) => (
                  <Month monthData={month} key={month.id} />
                ))}
              </Nav>
            </Col>
            <Col className="col-10">
              <Tab.Content style={{ height: '100%' }}>
                {months.map((month) => (
                  <MonthData refetch={refetch} monthData={month} key={month.id} />
                ))}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      )}
      {data && !activeMonth && (
        <div>
          <p> There is no monthly pass for now, use the button below to upload a monthlypass sheet </p>
          <SheetUploader type="MONTHLY" refetch={refetch} />
        </div>
      )}
    </>
  );
};

export default MonthlyPassView;
