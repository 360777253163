import { useMutation } from '@apollo/client';
import React, { useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { LoadingLogo } from '../../devtools/components/Modal';
import { UPLOAD_SHEET } from './apolloQueries';

type Props = {
  type: string;
  refetch: () => void;
  id?: string;
};

const SheetUploader = ({ type, refetch, id }: Props) => {
  const fileInput = useRef(null);
  const [choosenFile, setChoosenFile] = useState<File | null>(null);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChoosenFile((event.target.files ?? [])[0]);
  };
  const [uploadSheet, { loading: UploadSheetLoading }] = useMutation(UPLOAD_SHEET, {
    onCompleted: () => {
      refetch();
      setChoosenFile(null);
    },
    onError: () => {
      console.error('SheetUploader::uploadSheet failed');
      setChoosenFile(null);
    },
  });
  const handleClick = () => {
    if (fileInput.current) {
      (fileInput.current as HTMLButtonElement)?.click();
    }
  };
  const handleUpload = () => {
    uploadSheet({
      variables: {
        input: {
          file: choosenFile,
          type,
          ...(id != null && { id }),
        },
      },
    });
  };
  return (
    <>
      <LoadingLogo show={UploadSheetLoading} />
      {!choosenFile && (
        <Button className="w-100" onClick={handleClick}>
          Choose a {type} sheet
        </Button>
      )}
      <input type="file" ref={fileInput} onChange={handleChange} style={{ display: 'none' }} />
      {choosenFile && (
        <Button className="w-100" onClick={handleUpload} variant="info">
          Click to Upload
          {choosenFile.name}
        </Button>
      )}
    </>
  );
};

export default SheetUploader;
