import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { RouteComponentProps, withRouter } from 'react-router';
import { useLocation } from 'react-router-dom';
import { DNS_MAP } from '../config';
import { UserRole } from '../__gqltypes__';
import { KEY_TO_NAME } from './config';

export type DisplayedProfile = {
  role?: string;
  photoURL?: string | null;
  displayName: string | null;
  picture?: string | null;
};

type Props = {
  app: keyof typeof KEY_TO_NAME;
  visible: boolean;
  profile: DisplayedProfile;
  onSidebarToggle: () => void;
  expanded: boolean;
  getPageNameFromRoute: (route: string) => void;
  role: UserRole;
};

const Topbar = ({
  app,
  visible,
  profile,
  onSidebarToggle,
  expanded,
  role,
  getPageNameFromRoute,
}: RouteComponentProps & Props) => {
  const version = (process.env.REACT_APP_NAMESPACE || '').toUpperCase();
  const location = useLocation();

  const handleToggle = () => onSidebarToggle();

  return (
    <>
      <Navbar bg="light" className="navbar-expand bg-white topbar mb-0 fixed-top shadow">
        <Nav>
          <Nav.Item>
            <div className="d-flex align-items-center">
              <div
                role="button"
                tabIndex={0}
                className={`bg-${expanded ? 'secondary' : 'light'} text-${
                  expanded ? 'light' : 'secondary'
                } rounded mr-3 p-1 pl-2 pr-2 text-center`}
                onClick={handleToggle}
              >
                <i className="fa fa-bars" />
              </div>
              {getPageNameFromRoute(window.location.pathname)}
            </div>
          </Nav.Item>
        </Nav>
        <Nav className="ml-auto align-items-center">
          {process.env.REACT_APP_COMPILE_TIMESTAMP && (
            <div className="mx-2 text-muted text-xs d-none d-sm-block text-center">
              Last update: {new Date(process.env.REACT_APP_COMPILE_TIMESTAMP).toLocaleString()}
            </div>
          )}
          <Nav.Item>
            {visible ? (
              <DropdownButton
                id="dropdown-basic-button"
                title={version}
                defaultValue={version}
                disabled={role === UserRole.CONTENT_MODERATOR}
              >
                {Object.entries(DNS_MAP).map((key) => (
                  <Dropdown.Item
                    key={key.toString()}
                    href={`http://${key[1]}${location.pathname}${location.search}${location.hash}`}
                  >
                    {key[0].toUpperCase()}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            ) : (
              <span>{app}</span>
            )}
          </Nav.Item>
          <div className="topbar-divider d-none d-sm-block" />
          <Nav.Item>
            <span>{app}</span>
          </Nav.Item>
          <div className="topbar-divider d-none d-sm-block" />

          <Nav.Item>
            <Nav.Link>
              <div className="d-lg-inline">
                <span className="mr-2 d-block text-gray-600 small">{profile.displayName}</span>
                <span className="mr-2 d-block text-gray-600 small">{profile.role}</span>
              </div>
              <Image roundedCircle className="img-profile" src={profile.picture || profile.photoURL || ''} />
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Navbar>
    </>
  );
};

export default withRouter(Topbar);
