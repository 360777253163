import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Table from 'react-bootstrap/Table';
import { Sp2PlaylistsQuery } from '../../../../../../__gqltypes__';
import { SelectedPlaylists } from '../sp2Reducer';

type Sp2PlaylistsItem = NonNullable<Sp2PlaylistsQuery['sp2Playlists']>['playlists'][0];

type Props = {
  playlists: Sp2PlaylistsItem[];
  selectedPlaylists: SelectedPlaylists;
  allSelected: boolean;
  missingOnly: boolean;
  onToggleSelected: (pls: Sp2PlaylistsItem) => void;
  onToggleSelectedAll: () => void;
};

export default function PlaylistTable({
  playlists,
  selectedPlaylists,
  allSelected,
  missingOnly,
  onToggleSelected,
  onToggleSelectedAll,
}: Props) {
  return (
    <Table hover striped color="white" size="sm">
      <thead>
        <tr>
          <th>
            <Form.Check
              onChange={onToggleSelectedAll}
              checked={!!allSelected}
              ref={(input: HTMLInputElement) => {
                if (input) {
                  const partSelected =
                    allSelected &&
                    !!playlists &&
                    Object.keys(selectedPlaylists).length < playlists.filter((x) => !x?.contentPackId).length;
                  input.indeterminate = partSelected;
                }
              }}
            />
          </th>
          <th> </th>
          <th>Name</th>
          <th>Released</th>
          <th>Last committed in SP2</th>
          <th>Last SP3 import/export</th>
          <th>SP3 state</th>
        </tr>
      </thead>
      <tbody>
        {playlists.map(
          (playlist) =>
            !(missingOnly && playlist.contentPackId) && (
              <tr key={playlist.id}>
                <td width="30">
                  <Form.Check
                    onChange={() => onToggleSelected(playlist)}
                    checked={
                      !!playlist.productionPlaylistId &&
                      !!selectedPlaylists[playlist.productionPlaylistId] &&
                      !playlist.contentPackId
                    }
                    disabled={!!playlist.contentPackId}
                  />
                </td>
                <td width="30">{playlist.artwork && <Image src={playlist.artwork} width="25" height="25" />}</td>
                <td>{playlist.name}</td>
                <td>{playlist.releasedAt && new Date(playlist.releasedAt * 1000).toLocaleString()}</td>
                <td>{playlist.lastCommittedAt && new Date(playlist.lastCommittedAt * 1000).toLocaleString()}</td>
                <td>{playlist.exportedAt && new Date(playlist.exportedAt).toLocaleString()}</td>
                <td>{playlist.state}</td>
              </tr>
            )
        )}
      </tbody>
    </Table>
  );
}
