import defaultBackendModule from '../models/slicers/defaultBackendModule.json';
import defaultPartyPlayers from '../models/slicers/defaultPartyPlayers.json';
import defaultPlatform from '../models/slicers/defaultPlatform.json';
import defaultUserType from '../models/slicers/defaultUserType.json';
import defaultContinent from '../models/slicers/continent.json';
import defaultAccountLinked from '../models/slicers/accountlinked.json';
// import defaultVipStatus from '../models/slicers/defaultVipStatus.json';
// import defaultIsSpender from '../models/slicers/defaultIsSpender.json';

export type SlicingClause = (value: string) => string;
export type BaseSlicingClause = (...params: any[]) => SlicingClause;

/**
 * @typedef {(...params: any[]) => (value: string) => string} SlicingClause
 */

const dateClause = (key: string, visible: boolean) => (value: string) => visible ? `${key} = "${value}"` : '';

// const minDateClause = (key: string, visible) => (value: string) => (visible ? `${key} >= "${value}"` : '');

const minDateClause = (key: string, relation: string) => (value: string) => `${key} ${relation || '>='} "${value}"`;

// clauses is a list of key -> query filter to execute
const valuesClause = (clauses: Record<string, string>) => (value: string) => {
  return clauses[value];
};

const textClause = (key: string, relation: string) => (value: string) => `${key} ${relation || '='} "${value}"`;

const minTextClause = (key: string, relation: string) => (value: string) => `${key} ${relation || '>='} "${value}"`;

const numberClause = (key: string, relation: string) => (value: string) => `${key} ${relation || '='} ${value}`;

const textListClause = (key: string) => (value: string) => {
  if (value.trim().length === 0) {
    return `${key} is null`;
  }
  const valueList = value
    .split(',')
    .map((v) => `"${v.trim()}"`)
    .join(', ');
  return `${key} in (${valueList})`;
};

const rangeClause = (key: string) => (values: string) =>
  `${values.split('-')[0]} <= ${key} and ${key} <= ${values.split('-')[1]}`;

const multipleSelectionClause = (key: string) => (values: string) => {
  if (values.includes('all') || values.includes('All') || values.includes('ALL')) {
    return '';
  }
  if (Array.isArray(values)) {
    return `${key} in (${values.map((val) => `"${val}"`).join(', ')})`;
  }
  return `${key} = "${values}"`;
};

export const baseSlicersClauses: Record<string, BaseSlicingClause> = {
  BaseSelectorSlicer: valuesClause,
  DateSlicer: dateClause,
  minDateSlicer: minDateClause,
  DateTimeSlicer: dateClause,
  MultipleSelectionSlicer: multipleSelectionClause,
  NumberSlicer: numberClause,
  RangeSlicer: rangeClause,
  TextSlicer: textClause,
  minTextSlicer: minTextClause,
  TextListSlicer: textListClause,
};

export const defaultSlicersClauses: Record<string, SlicingClause> = {
  DefaultBackendModuleSlicer: valuesClause(defaultBackendModule.queries), // not used
  DefaultPartyPlayersSlicer: valuesClause(defaultPartyPlayers.queries), // not used
  DefaultPlatformSlicer: valuesClause(defaultPlatform.queries),
  DefaultUserTypeSlicer: valuesClause(defaultUserType.queries), // not used
  DefaultVipStatusSlicer: multipleSelectionClause('user_subscription_tier'),
  DefaultCountrySlicer: multipleSelectionClause('country'),
  DefaultContinentSlicer: valuesClause(defaultContinent.queries),
  DefaultSourceSlicer: multipleSelectionClause('install_source'),
  DefaultIsSpenderSlicer: multipleSelectionClause('is_spender'),
  DefaultAccountLinkedSlicer: multipleSelectionClause('is_linked'),
};
