import React from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

type Props = {
  id: string;
  value: string;
  onChange: (value: string | null) => void;
  defaultValue?: string;
  title?: string;
};

const TextSlicer = ({ id, value, onChange, defaultValue, title }: Props) => (
  <Form.Group as={Row} controlId={id}>
    <Form.Label column>{title ?? id}</Form.Label>
    <Col>
      <Form.Control type="text" value={value || defaultValue} onChange={(e) => onChange(e.target.value || null)} />
    </Col>
  </Form.Group>
);

export default TextSlicer;
