import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { AppType } from '../types';
import { UserRole } from '../__gqltypes__';

type Props = {
  apps: AppType[];
  onSelectApp: (type: AppType) => void;
  appRole: UserRole;
};

const AppSelection = ({ apps, onSelectApp, appRole }: Props) => (
  <Container>
    <Card className="o-hidden border-0 shadow-lg my-5">
      <Card.Body className="p-0">
        <Row>
          <Col className="col-lg-9">
            <div className="p-5">
              <Card.Title>Tool Selection</Card.Title>
              <hr />
              {apps
                .filter((app) => app.permission.includes(appRole))
                .map((app) => (
                  <Button
                    key={app.name}
                    type="button"
                    variant={app.color || 'primary'}
                    className="mr-4 mt-4"
                    onClick={() => onSelectApp(app)}
                  >
                    {app.name}
                  </Button>
                ))}
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  </Container>
);

export default AppSelection;
