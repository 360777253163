import React from 'react';
import Badge from 'react-bootstrap/Badge';
import { SongQuestionableStatus } from '../../../../__gqltypes__';

export default function QuestionableIcon({ questionable }: { questionable: SongQuestionableStatus | null }) {
  if (!questionable || questionable !== SongQuestionableStatus.QUESTIONABLE) return null;
  return (
    <Badge className="m-1" variant="warning">
      Q
    </Badge>
  );
}
