import { BatchContentModerationListQuery } from '../../../../../../__gqltypes__';

export const NOTE_REASON = [
  {
    key: 'ARTIST',
    value: 'Artist name',
  },
  {
    key: 'TITLE',
    value: 'Track name',
  },
  {
    key: 'LYRICS',
    value: 'Lyrics',
  },
  {
    key: 'ALBUM',
    value: 'Album Cover',
  },
];

type BatchContentModerationStats = BatchContentModerationListQuery['batchContentModerationList']['list'][0]['stats'];

export const getTotalLeftToDo = (stats: BatchContentModerationStats | undefined, locale: string): number | null => {
  if (!stats || stats.length === 0) return null;
  const packStats = stats.filter((s) => s.locale === locale);
  if (packStats.length === 0) return null;

  const totalCount = packStats.reduce((acc, val) => acc + val.totalCount, 0);
  const totalDone = packStats.reduce((acc, val) => acc + val.totalDone, 0);

  return totalCount - totalDone;
};

export const getLeftToDoForPlaylists = (
  stats: BatchContentModerationStats | undefined,
  locale: string,
  packId: string | undefined
): number | null => {
  if (!stats || stats.length === 0 || !packId) return null;

  const packStats = stats.find((s) => s.locale === locale && s.id.split('.')[2] === packId) ?? null;
  if (!packStats) return null;
  return packStats.totalCount - packStats.totalDone;
};
