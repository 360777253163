import { useHistory } from 'react-router';
import InteractiveMonitoringSelector from './components/InteractiveMonitoringSelector';
import Monitor from './components/Monitor';
import { decodeUrl } from './logic/buildUrl';

export default function Monitoring() {
  const history = useHistory();
  const {
    location: { search },
  } = history;

  const { validatedDecoded, key } = decodeUrl(search);

  return (
    <div className="p-1">
      <InteractiveMonitoringSelector initialValues={validatedDecoded} expand={search.length <= 1} />
      <Monitor search={search} key={key} />
    </div>
  );
}
