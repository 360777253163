import { gql } from '@apollo/client';

/**
 * FRAGMENTS
 */
export const GET_SONG_QUERY = gql`
  query contentItems($songIds: [ID!]!) {
    getSongSampleByIds(songIds: $songIds) {
      id
      song {
        id
        displayTitle
        displayArtist
        title
        artist
        picture
      }
    }
  }
`;
