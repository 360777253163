import { Badge, Dropdown, DropdownButton } from 'react-bootstrap';
import { ALL_LOCALES, displayLocaleWithFlag, SUPPORTED_LOCALE } from '../../../../curation/utils/locales';

interface LocaleSelectorProps {
  locale: SUPPORTED_LOCALE | null;
  setLocale: (locale: SUPPORTED_LOCALE | null) => void;
}

export default function LocaleSelector({ locale, setLocale }: LocaleSelectorProps) {
  const handleSetLocale = (e: SUPPORTED_LOCALE) => setLocale(e);
  const handleRemoveLocale = () => setLocale(null);

  return (
    <>
      <DropdownButton as="span" id="dropdown-item-button" size="sm" title="Use Locale">
        {ALL_LOCALES.map((f) => (
          <Dropdown.Item key={f} as="button" onClick={() => handleSetLocale(f)}>
            {displayLocaleWithFlag(f)}
          </Dropdown.Item>
        ))}
      </DropdownButton>
      {locale && (
        <Badge pill variant="success" className="tag-pill ml-2">
          {displayLocaleWithFlag(locale)}
          <i role="link" tabIndex={0} className="fas fa-times m-1" onClick={handleRemoveLocale} />
        </Badge>
      )}
    </>
  );
}
