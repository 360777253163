import React from 'react';
import { Image } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

type LevelSelectProps = {
  item: { id: string; nextLevelItems: unknown[]; level: number };
  onSelect: (number: number) => void;
};

const LevelSelect = ({ item, onSelect }: LevelSelectProps) => {
  const options = [];
  for (let i = 1; i <= item.nextLevelItems.length + 1; i++) {
    options.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }

  // @ts-ignore
  const onChange = (e) => onSelect(e.target.value);

  return (
    <Form.Group className="d-flex mb-3 align-items-baseline">
      <Form.Label className="mr-2">Level: </Form.Label>
      <Form.Control as="select" value={item.level ?? 1} onChange={onChange}>
        {options}
      </Form.Control>
    </Form.Group>
  );
};

type Props<I, T> = {
  items: I[];
  type: T;
  onDelete: (item: I, type: T) => void;
  onChangeItemLevel: (item: I, type: T) => void;
};
type GenericItem = {
  id: string;
  name: string;
  level: number;
  asset?: string;
  iconAsset?: string;
  nextLevelItems: { level: number; asset?: string }[];
};
type StickerItem = GenericItem & { asset: string; nextLevelItems: never[] };
type ProfileFrameItem = GenericItem & { asset: string; nextLevelItems: { level: number; asset: string }[] };
type AppskinItem = GenericItem & { iconAsset: string; nextLevelItems: { level: number }[] };

/**
 * Extract a .png filename from a sticker, a profileFrame or an appskin
 *    - For a sticker, there is one "item.asset"
 *    - For a profileFrame, there is a general asset of level1, and one asset by other level
 *    - For an appskin, there is a single preview icon called "item.iconAsset"
 */
const extractPreviewIcon = (item: StickerItem | ProfileFrameItem | AppskinItem): string | null => {
  const { iconAsset } = item;
  if (iconAsset) {
    return iconAsset.replace('.json', '.png');
  }

  const { asset: levelAsset } = item.nextLevelItems[item.level - 2] || {};
  if (levelAsset) {
    return levelAsset.replace('.json', '.png');
  }

  const { asset: mainAsset } = item;
  if (mainAsset) {
    return mainAsset.replace('.json', '.png');
  }

  return null;
};

export default function ItemsEditor({
  items,
  type,
  onDelete: onDeleteProps,
  onChangeItemLevel,
}: Props<StickerItem | ProfileFrameItem | AppskinItem, string>) {
  const onDelete = (key: string) => {
    const item = items.find((v) => v.id === key);
    if (item) {
      onDeleteProps(item, type);
    }
  };

  const onSelect = (level: number, key: string) => {
    const item = items.find((v) => v.id === key);
    if (item) {
      onChangeItemLevel({ ...item, level }, type);
    }
  };

  return (
    <>
      {items.length > 0 ? (
        <Row>
          {items.map((item) => (
            <Col key={item.id} md="auto" className="mb-4">
              <Card>
                <Card.Header>
                  <Image
                    roundedCircle
                    className="img-profile mr-3"
                    src={extractPreviewIcon(item) || ''}
                    style={{ width: 30, height: 30 }}
                  />
                  {item.name}
                </Card.Header>
                <Card.Body className="d-flex flex-column">
                  <LevelSelect item={item} onSelect={(level) => onSelect(level, item.id)} />
                  <Button variant="danger" onClick={() => onDelete(item.id)}>
                    Remove
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      ) : (
        <i>No stickers</i>
      )}
    </>
  );
}
