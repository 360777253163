import { useEffect, useRef } from 'react';
import drawStar from '../logic/ChartsController.ts/drawStar';

interface LegendItemProps {
  label: string;
  color: string;
}

export default function EventLegendItem({ label, color }: LegendItemProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const canvas2Ref = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (canvasRef.current) {
      const context = canvasRef.current.getContext('2d');
      if (!context) {
        return;
      }

      context.clearRect(0, 0, 30, 20);
      context.translate(0.5, 0.5);

      context.lineWidth = 2;
      context.strokeStyle = color;
      context.fillStyle = color;

      context.moveTo(10, 15);
      context.lineTo(20, 15);
      context.lineTo(15, 5);
      context.lineTo(10, 15);
      context.fill();
    }
  }, [canvasRef, color]);

  useEffect(() => {
    if (canvas2Ref.current) {
      drawStar(color, canvas2Ref.current);
    }
  }, [canvas2Ref, color]);

  return (
    <div className="d-flex">
      <canvas ref={canvasRef} width={30} height={20} className="mt-auto mb-auto mr-0" />
      <canvas ref={canvas2Ref} width={20} height={20} className="mt-auto mb-auto mr-3" />
      <div className="mr-auto">{label} (common/important)</div>
    </div>
  );
}
