import { useQuery } from '@apollo/client';
import moment from 'moment';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useRouteMatch } from 'react-router-dom';
import { LoadingLogo } from '../../components/Modal';
import { GET_SESSIONS, GET_USERS_FROM_SESSION } from '../../graphql';
import { InfoDisplay } from './components/InfoDisplay';
import { SessionDisplay } from './components/SessionDisplay';

const isUserID = (id) => id.split('-').length === 5;

export const SessionsView = () => {
  const match = useRouteMatch();
  const { id } = match.params;
  const [startDate, setStartDate] = useState(moment().subtract(7, 'days'));

  const { data, refetch, loading } = useQuery(GET_SESSIONS, {
    variables: isUserID(id) ? { userID: id, startDate } : { installID: id, startDate },
  });
  const { data: args } = useQuery(GET_USERS_FROM_SESSION, {
    variables: { properties: [id], type: 'BY_ID' },
    // prevent cache issue when coming from user view
    fetchPolicy: 'no-cache',
  });

  const refetchAndProcessLogs = (_startDate) => {
    refetch(isUserID(id) ? { userID: id, startDate: _startDate } : { installID: id, startDate: _startDate });
  };

  let processedLogs = [];
  if (data && data.analytics.appSessions) {
    if (data.analytics.appSessions.data.length > 0) {
      processedLogs = data.analytics.appSessions.data.map((log) => JSON.parse(log));
    }
  } else {
    console.log('No logs found for this input');
  }

  const handleLoadMore = () => {
    const newStartDate = moment(startDate).subtract(7, 'days');
    refetchAndProcessLogs(newStartDate);
    setStartDate(newStartDate);
  };

  const infoData = [];
  if (processedLogs && isUserID(id)) {
    infoData.push({
      title: 'Players',
      params: args
        ? args.users.map((user) => {
            const {
              id: _id,
              displayName,
              picture,
              subscriptionTier,
              phoneCredential,
              emailCredential,
              facebookCredential,
              appleCredential,
              googleCredential,
              userLevel,
              userLevelProgressToNextLevel,
              coinBalance,
              diamondBalance,
              basicTierContentPack: {
                contentPack: { name },
              },
              ABTestList,
            } = user;
            const account = {
              userId: _id,
              displayName,
              picture,
              subscriptionTier,
              phoneCredential,
              emailCredential,
              facebookCredential,
              appleCredential,
              googleCredential,
            };
            return {
              account,
              user: {
                userLevel,
                userLevelProgressToNextLevel,
                coinBalance,
                diamondBalance,
                basicTierContentPack: name,
              },
              abtests: ABTestList.map(({ id: abtestid, variation }) => ({ [abtestid]: variation })),
            };
          })
        : [{ error: 'No user found' }],
    });
  }

  return (
    <>
      <Row>
        <Col sm="8">
          <Card>
            <Card.Header className="m-0 font-weight-bold text-primary">
              {`Recent Sessions (last ${moment().diff(moment(startDate), 'days')} days)`}
            </Card.Header>
            <Card.Body>
              {processedLogs && processedLogs.length > 0
                ? processedLogs
                    .sort((a, b) => b.event_timestamp - a.event_timestamp)
                    .map(({ event_params: params }) => <SessionDisplay key={params.session_id} session={params} />)
                : !loading && <p>No log found, try loading more</p>}
              <div className="d-flex align-content-center">
                <Button onClick={handleLoadMore}>Load More</Button>
                <LoadingLogo className="mx-2" show={loading} />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <InfoDisplay data={infoData} />
      </Row>
    </>
  );
};
