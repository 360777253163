import React, { useMemo } from 'react';
import Card from 'react-bootstrap/Card';
import EditableParam from './EditableParam';

type Props<M> = {
  bank: M;
  onChange: (bank: M) => void;
};

export default function PiggyEditor({ bank, onChange }: Props<{ coins: string; diamonds: string }>) {
  const { coins, diamonds } = useMemo(
    () => ({
      coins: bank.coins,
      diamonds: bank.diamonds,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // @ts-ignore
  const handleChangeBank = (key: string) => (event) => {
    const { value } = event.target;
    if (onChange) {
      onChange({ ...bank, [key]: value });
    }
  };

  if (!bank) return null;

  return (
    <Card>
      <Card.Body>
        <EditableParam
          key="coins"
          title="coins"
          value={bank.coins}
          onChange={handleChangeBank('coins')}
          placeholder={coins}
        />
        <EditableParam
          key="diamonds"
          title="diamonds"
          value={bank.diamonds}
          onChange={handleChangeBank('diamonds')}
          placeholder={diamonds}
        />
      </Card.Body>
    </Card>
  );
}
