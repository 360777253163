import { RouteType } from '../../../types';
import ABTests from './screens/ABTests/ABTests';
import CustomizationItems from './screens/Items';
import CustomizationRoutes from './screens/Items/views';
import LiveShows from './screens/LiveShows';
import MonthlyPass from './screens/MonthlyPass/MonthlyPass';
import Tournament from './screens/Tournament/Tournament';
import SaleEvent from './screens/SaleEvent/SaleEvent';
import SpecialEvent from './screens/SpecialEvent/SpecialEvent';
import HurdleQuiz from './screens/HurdleQuiz/HurdleQuiz';
import ChainedOffer from './screens/ChainedOffer/ChainedOffer';

export const PREFIX = 'sp3';

const routes: RouteType[] = [
  {
    path: `/${PREFIX}/liveops/monthlypass`,
    name: 'Monthly Pass',
    component: MonthlyPass,
  },
  {
    path: `/${PREFIX}/liveops/items`,
    name: 'Customization Items',
    component: CustomizationItems,
    underRoutes: CustomizationRoutes,
  },
  {
    path: `/${PREFIX}/liveops/items/:id`,
    component: CustomizationItems,
  },
  {
    path: `/${PREFIX}/liveops/liveshows/:id?`,
    name: 'Live Shows',
    component: LiveShows,
  },
  {
    path: `/${PREFIX}/liveops/tournaments/:id?`,
    name: 'Tournaments',
    component: Tournament,
  },
  {
    path: `/${PREFIX}/liveops/specialevent/:id?`,
    name: 'Festivals',
    component: SpecialEvent,
  },
  {
    path: `/${PREFIX}/liveops/hurdle-quiz/:month?/:id?`,
    name: 'Tuneology quizzes',
    component: HurdleQuiz,
  },
  {
    path: `/${PREFIX}/liveops/abtests/:id?`,
    name: 'AB Tests',
    component: ABTests,
  },
  {
    path: `/${PREFIX}/liveops/sale-events/:id?`,
    name: 'Sale Events',
    component: SaleEvent,
  },
  {
    path: `/${PREFIX}/liveops/chained-offers/:id?`,
    name: 'Chained Offers',
    component: ChainedOffer,
  },
];

export default routes;
