import * as React from 'react';
import { useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import DateTimePicker from 'react-datetime';
import { applyChangeIfDateValid, validIfSameDayOrAfter } from '../../../../../../utils/datepicker';
import {
  AbTestAudienceType,
  AbTestStatus,
  GetAbTestsConfigsQuery,
  UpdateAbTestInput,
} from '../../../../../../__gqltypes__';
import { LoadingLogo } from '../../../../devtools/components/Modal';

type Props = {
  abtest: GetAbTestsConfigsQuery['liveOps']['ABTestConfigs'][0];
  onUpdate: (value: UpdateAbTestInput) => void;
  onDelete: (value: { id: string }) => void;
  loading: boolean;
};

export function ABTestData({ abtest, onUpdate, onDelete, loading }: Props) {
  const readonly = abtest?.isHardCoded;
  const handleSave = () => {};

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [errors, setErrors] = useState<string[]>([]);
  const [availableVariations, setAvailableVariations] = useState<{ name: string; percentage: number }[]>([]);
  const [newVariation, setNewVariation] = useState({ name: '', percentage: 0 });

  const [status, setStatus] = useState(abtest.status);
  const [audience, setAudience] = useState(abtest.audience);
  const [defaultVariation, setDefaultVariation] = useState(abtest.defaultVariation);
  const [description, setDescription] = useState(abtest.description);
  const [isNewAudienceOnly, setIsNewAudienceOnly] = useState(abtest.isNewAudienceOnly);
  const [startDate, setStartDate] = useState(abtest.startDate);
  const [winnerVariation, setWinnerVariation] = useState(abtest.winnerVariation ?? '');

  const resetMinutes = (date: any) => {
    const newDate = new Date(date);
    newDate.setMinutes(0);
    return newDate;
  };

  useEffect(() => {
    setDescription(abtest.description);
    setIsNewAudienceOnly(abtest.isNewAudienceOnly);
    setStatus(abtest.status);
    setAudience(abtest.audience);
    setDefaultVariation(abtest.defaultVariation);
    setDescription(abtest.description);
    setIsNewAudienceOnly(abtest.isNewAudienceOnly);
    setStartDate(resetMinutes(abtest.startDate));
    setWinnerVariation(abtest.winnerVariation ?? '');
    setAvailableVariations(
      abtest.variationsWithPercentage.map((variation) => {
        // Removing graphQl __typename
        // @ts-ignore
        delete variation.__typename;
        return variation;
      })
    );
  }, [abtest]);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    const newErrors = [];

    // Checking html forms constrains (text in number fields, etc)
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      return;
    }

    // Checking percentages
    const summedPercentages = availableVariations.reduce((sum, variation) => sum + variation.percentage, 0);
    if (summedPercentages > 100) {
      newErrors.push('Percentages sum is over 100, please, fix it');
    }

    // Checking not validated field
    if (newVariation.name.trim() !== '') {
      newErrors.push(
        `You did not validate the new variation '${newVariation.name}', please click on create button or clear the variation name field`
      );
    }

    // Checking master state
    if (status !== AbTestStatus.WINNER && winnerVariation !== '' && winnerVariation != null) {
      newErrors.push('Winner variation should be not null only if status = ABTestStatus.WINNER');
    }

    setErrors(newErrors);
    // errors need to be fixed
    if (newErrors.length > 0) {
      return;
    }

    const date = new Date(
      (typeof startDate === 'string' ? new Date(startDate) : startDate).toLocaleString('en-US', {
        timeZone: 'America/New_York',
      })
    );

    const updatedABTest = {
      id: abtest.id,
      variationsWithPercentage: availableVariations,
      status,
      audience,
      defaultVariation,
      description,
      isNewAudienceOnly,
      startDate: date,
      winnerVariation: winnerVariation === '' ? null : winnerVariation,
    };

    // @ts-ignore
    onUpdate(updatedABTest);
  };

  return loading ? (
    <LoadingLogo show />
  ) : (
    <>
      <Form noValidate onSubmit={handleSubmit}>
        {errors.map((error) => (
          <Alert key={error} variant="danger">
            {error}
          </Alert>
        ))}

        <Container className="pb-4">
          <Row className="mb-3">
            <Col md={4}>Id:</Col>
            <Col md={4}>{abtest.id}</Col>
            <Col className="d-flex justify-content-end">
              <Button
                className="mr-2"
                onClick={() => {
                  setShowDeleteModal(true);
                }}
                variant="danger"
                disabled={readonly}
              >
                <i className="fas fa-trash mr-2" />
                Delete
              </Button>
              {readonly || (
                <Button onClick={handleSave} variant="success" type="submit">
                  Save
                </Button>
              )}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={4}>Description:</Col>
            <Col>
              <Form.Group controlId="abtest-description">
                <Form.Control
                  disabled={readonly}
                  value={description ?? undefined}
                  as="textarea"
                  onChange={(event) => setDescription(event.currentTarget.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={4}>Start date:</Col>
            <Col md={5}>
              (the date is converted to the New York timezone)
              {readonly ? (
                new Date(startDate).toLocaleString()
              ) : (
                <DateTimePicker
                  dateFormat="YYYY-MM-DD"
                  timeFormat={true}
                  timeConstraints={{ minutes: { min: 0, max: 0, step: 0 } }}
                  // @ts-ignore
                  onChange={applyChangeIfDateValid(setStartDate)}
                  isValidDate={validIfSameDayOrAfter}
                  // @ts-ignore
                  value={startDate ?? undefined}
                />
              )}
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={4}>Is new Audience Only:</Col>
            <Col>
              <Form.Group controlId="abtest-isnewaudienceonly">
                <Form.Check
                  disabled={readonly}
                  checked={isNewAudienceOnly}
                  onChange={(event) => setIsNewAudienceOnly(event.currentTarget.checked)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={4}>Variations:</Col>
            <Col className="mb-3">
              <Row>
                {availableVariations.map((variation, index) => (
                  <Row key={`variation${index}`}>
                    <Col md={4} className="mb-3">
                      <Form.Control
                        disabled={readonly}
                        type="text"
                        value={variation.name}
                        onChange={(event) => {
                          const newName = event.currentTarget.value;

                          setAvailableVariations([
                            ...availableVariations.slice(0, index),
                            {
                              ...availableVariations[index],
                              name: newName,
                            },
                            ...availableVariations.slice(index + 1),
                          ]);
                        }}
                      />
                    </Col>
                    <Col md={4} className="mb-3">
                      <InputGroup>
                        <Form.Control
                          disabled={readonly}
                          type="number"
                          max={100}
                          min={0}
                          value={variation.percentage}
                          onChange={(event) => {
                            const newPercentage = event.currentTarget.value;
                            setAvailableVariations([
                              ...availableVariations.slice(0, index),
                              {
                                ...availableVariations[index],
                                percentage: Number(newPercentage),
                              },
                              ...availableVariations.slice(index + 1),
                            ]);
                          }}
                        />
                        <InputGroup.Text>%</InputGroup.Text>
                      </InputGroup>
                    </Col>
                    <Col md={4} className="mb-3">
                      <Button
                        className="mr-2"
                        onClick={() => {
                          setAvailableVariations([
                            ...availableVariations.slice(0, index),
                            ...availableVariations.slice(index + 1),
                          ]);
                        }}
                        variant="danger"
                        disabled={readonly}
                      >
                        <i className="fas fa-trash mr-2" />
                        Delete
                      </Button>
                    </Col>
                  </Row>
                ))}

                <Row>
                  <Col md={4} className="mb-3">
                    <Form.Control
                      disabled={readonly}
                      type="text"
                      value={newVariation.name}
                      onChange={(event) => {
                        const newName = event.currentTarget.value;
                        setNewVariation({ ...newVariation, name: newName });
                      }}
                    />
                  </Col>
                  <Col md={4} className="mb-3">
                    <InputGroup>
                      <Form.Control
                        disabled={readonly}
                        type="number"
                        max={100}
                        min={0}
                        value={newVariation.percentage}
                        onChange={(event) => {
                          const newPercentage = event.currentTarget.value;
                          setNewVariation({ ...newVariation, percentage: Number(newPercentage) });
                        }}
                      />
                      <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup>
                  </Col>
                  <Col md={4} className="mb-3">
                    <Button
                      className="mr-2"
                      onClick={() => {
                        setAvailableVariations(availableVariations.concat([{ ...newVariation }]));
                        setNewVariation({ name: '', percentage: 0 });
                      }}
                      variant="success"
                      disabled={readonly}
                    >
                      <i className="fas fa-plus mr-2" />
                      Create
                    </Button>
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={4}>Default variation:</Col>
            <Col>
              <Form.Group controlId="abtest-defaultvariation">
                <Form.Control
                  as="select"
                  disabled={readonly}
                  type="text"
                  value={defaultVariation ?? undefined}
                  onChange={(event) => setDefaultVariation(event.currentTarget.value)}
                >
                  {availableVariations.map((variation, index) => (
                    <option key={`default-variation${index.toString()}`} value={variation.name}>
                      {variation.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={4}>Winner variation:</Col>
            <Col>
              <Form.Group controlId="abtest-winnervariation">
                <Form.Control
                  as="select"
                  disabled={readonly}
                  type="text"
                  value={winnerVariation}
                  onChange={(event) => setWinnerVariation(event.currentTarget.value)}
                >
                  {/* @ts-ignore */}
                  {['', ...abtest.variationsWithPercentage].map(({ name }) => (
                    <option key={`winner-variation${name}`} value={name}>
                      {name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={4}>Status:</Col>
            <Col>
              <Form.Group controlId="abtest-status">
                <Form.Control
                  as="select"
                  disabled={readonly}
                  type="text"
                  value={status}
                  onChange={(event) => setStatus(event.currentTarget.value as AbTestStatus)}
                >
                  {Object.keys(AbTestStatus).map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={4}>Audience:</Col>
            <Col>
              <Form.Group controlId="abtest-audience">
                <Form.Control
                  as="select"
                  disabled={readonly}
                  type="text"
                  value={audience}
                  onChange={(event) => setAudience(event.currentTarget.value as AbTestAudienceType)}
                >
                  {Object.keys(AbTestAudienceType).map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
        </Container>
      </Form>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>AB Test Removal</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>This cannot be undone. Are you sure to want to remove this AB Test?</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              setShowDeleteModal(false);
              onDelete({ id: abtest.id });
            }}
          >
            Remove AB Test
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

//   variationsWithPercentage: [ABTestVariationInput!]!
