import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import NumberInput from '../../../../../utils/components/inputs/NumberInput';
import { UpdateHurdleCatalogItemMutation, UpdateHurdleCatalogItemMutationVariables } from '../../../../../__gqltypes__';
import PlayButton from '../../components/PlayButton';
import mutations from '../../utils/mutations';

export default function UpdateOffsetForm({
  update,
  loading,
  sample,
  currentOffset,
}: {
  update: (offset: number) => void;
  loading?: boolean;
  sample: string;
  currentOffset: number;
}) {
  const [value, setValue] = useState(currentOffset.toFixed(1));

  const handleUpdate = () => {
    update(parseFloat(value));
  };

  return (
    <>
      <h5 className="mb-3">Update offset</h5>
      <Row>
        <Col md={8}>Offset is currently {currentOffset.toFixed(1)}s</Col>
        <Col md={4} className="d-flex">
          <PlayButton
            sample={sample}
            displayTimestamp
            startAt={currentOffset}
          />
        </Col>
      </Row>
      <NumberInput value={value} setValue={setValue} title="Offset (seconds)" step={0.1} max={20} />
      {parseFloat(value) > 20 && <span className='small text-danger'>Max 20s</span>}
      <Button className="mt-3" disabled={currentOffset.toFixed(1) === value || parseFloat(value) > 20 || loading} onClick={handleUpdate}>
        Set offset to {value}s
      </Button>
    </>
  );
}
