import { CurationContentPackWithExternalLinksQuery } from '../../../../__gqltypes__';

type CurationContentPack = CurationContentPackWithExternalLinksQuery['curationContentPack'];
type ContentItem = CurationContentPack['contentItems'][0];

const COLUMNS = [
  { path: 'id', label: 'Song id' },
  { path: 'title', label: 'Title' },
  { path: 'artist', label: 'Artist' },
  { path: 'album', label: 'Album' },
  { path: 'albumYear', label: 'Album Year' },
  { path: 'isrc', label: 'ISRC' },
  { path: 'defaultSample', label: 'Sample URL' },
  { path: 'label', label: 'Label' },
  { path: 'subLabel', label: 'Sub-label' },
  { path: 'songInput.matchType', label: 'Match type' },
  { path: 'externalLinks.spotify.us', label: 'Spotify link' },
  { path: 'ddexSong.id', label: 'DDEX id' },
  { path: 'ddexSong.title', label: 'DDEX Title' },
  { path: 'ddexSong.artist', label: 'DDEX Artist' },
  { path: 'ddexSong.album', label: 'DDEX Album' },
  { path: 'ddexSong.albumId', label: 'DDEX Album id' },
  { path: 'ddexSong.isrc', label: 'DDEX ISRC' },
  { path: 'ddexSong.sample', label: 'DDEX Sample URL' },
  { path: 'ddexSong.label', label: 'DDEX Label' },
  { path: 'ddexSong.subLabel', label: 'DDEX Sub-label' },
];

function createCsv(songs: ContentItem[], addPrefix = false) {
  const csvPrefix = 'data:text/csv;charset=utf-8,';
  const header = `${COLUMNS.map((col) => col.label).join(',')}\n`;

  const rows = songs.map((song) => {
    const values = COLUMNS.map(({ path }) => {
      let value = song;

      path.split('.').some((key) => {
        // @ts-ignore
        value = value[key];
        return !value;
      });

      const strValue: string = value ? String(value) : '';

      if (strValue.includes(',')) {
        return `"${strValue.replaceAll('"', '""')}"`;
      }

      return strValue;
    });
    return values.join(',');
  });

  return `${addPrefix ? csvPrefix : ''}${header}${rows.join('\n')}`;
}

export function downloadCsvFile(content: string, filename: string) {
  const blob = new Blob([content], { type: 'text/csv;charset=utf-8' });

  const link = document.createElement('a');
  link.setAttribute('href', URL.createObjectURL(blob));
  link.setAttribute('download', `${filename}.csv`);

  document.body.appendChild(link);
  link.click();
  link.remove();
}

export function downloadSongsCsv(playlist: CurationContentPack) {
  const content = createCsv(playlist.contentItems);
  downloadCsvFile(content, playlist.name.replace(/\s/g, '_'));
}
