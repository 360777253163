import { useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import { Button, Modal, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { CurationContentPackQuery, SpecialEventsQuery } from '../../../../__gqltypes__';
import {
  ADD_SPECIAL_EVENT_CONTENT_PACK,
  GET_SPECIAL_EVENTS,
  REMOVE_SPECIAL_EVENT_CONTENT_PACK,
} from '../../liveops/screens/SpecialEvent/graphql';

type SpecialEvents = NonNullable<CurationContentPackQuery['curationContentPack']['contentPack']>['adminSpecialEvents'];

type Props = {
  contentPackId: string;
  show: boolean;
  onHide: () => void;
  specialEvents: SpecialEvents | null;
  onUpdateSpecialEvents: (data: SpecialEvents) => void;
};

const SpecialEventPopup = ({ contentPackId, show, onHide, specialEvents, onUpdateSpecialEvents }: Props) => {
  // Query
  const { data }: { data: SpecialEventsQuery | undefined; loading: boolean } = useQuery(GET_SPECIAL_EVENTS, {
    fetchPolicy: 'no-cache',
  });

  // Mutations
  const [addSpecialEventContentPack, { loading: addSpecialEventLoading }] = useMutation(
    ADD_SPECIAL_EVENT_CONTENT_PACK,
    {
      onCompleted: ({ addSpecialEventContentPack: _data }) => {
        let updatedSpecialEvents;
        if (!_.isNil(specialEvents)) {
          updatedSpecialEvents = [...specialEvents];
          updatedSpecialEvents.push(_data.specialEvent);
        } else {
          updatedSpecialEvents = [_data.specialEvent];
        }
        onUpdateSpecialEvents(updatedSpecialEvents);
      },
    }
  );
  const [removeSpecialEventContentPack, { loading: removeSpecialEventLoading }] = useMutation(
    REMOVE_SPECIAL_EVENT_CONTENT_PACK,
    {
      onCompleted: ({ removeSpecialEventContentPack: _data }) => {
        if (!_.isNil(specialEvents)) {
          const updatedSpecialEvents = [...specialEvents];
          const index = updatedSpecialEvents.findIndex(({ id }) => id === _data.specialEvent.id);
          updatedSpecialEvents.splice(index, 1);
          onUpdateSpecialEvents(updatedSpecialEvents);
        }
      },
    }
  );

  const onLink = (specialEventId: string) => {
    if (!_.isNil(specialEventId)) {
      addSpecialEventContentPack({
        variables: { input: { specialEventId, contentPackId } },
      });
    }
  };

  const onUnlink = (specialEventId: string) => {
    if (!_.isNil(specialEventId)) {
      removeSpecialEventContentPack({
        variables: { input: { specialEventId, contentPackId } },
      });
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      dialogClassName="modal-80w"
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Special Events</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {data && data.liveOps && data.liveOps.specialEvents.list.length > 0 ? (
          <Table bordered responsive hover>
            <thead className="text-center">
              <tr>
                <th>Id</th>
                <th>StartDate</th>
                <th>Unlink</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {data.liveOps.specialEvents.list.map((specialEvent, specialEventIndex) => (
                <tr key={specialEventIndex}>
                  <td className="align-middle">
                    <Link to={`/sp3/liveops/specialevent/${specialEvent.id}`}>
                      {specialEvent.name} ({specialEvent.id})
                    </Link>
                  </td>
                  <td className="align-middle">{new Date(specialEvent.startDate).toLocaleString()}</td>

                  <td className="p-0 align-middle text-center">
                    {specialEvents?.some(({ id }) => specialEvent.id === id) ? (
                      <Button
                        disabled={removeSpecialEventLoading}
                        variant="danger"
                        onClick={() => onUnlink(specialEvent.id)}
                        className="square-icon icon-sm"
                      >
                        <i className="fas fa-times" />
                      </Button>
                    ) : (
                      <Button
                        disabled={addSpecialEventLoading}
                        variant="primary"
                        onClick={() => onLink(specialEvent.id)}
                        className="square-icon icon-sm"
                      >
                        <i className="fas fa-plus" />
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <div>There is no Special Event</div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SpecialEventPopup;
