import React from 'react';
import BaseSelectorSlicer from '../components/BaseSelectorSlicer';
import { slicerPropTypes } from '../slicerPropTypes';

import source from '../../../models/slicers/source.json';

const options = source.map(({ name, code }) => ({
  text: name,
  value: code,
}));

const DefaultSourceSlicer = ({ id, value, onChange }) => (
  <BaseSelectorSlicer id={id} value={value} onChange={onChange} options={options} disableAll />
);

DefaultSourceSlicer.propTypes = slicerPropTypes;

DefaultSourceSlicer.defaultProps = {
  id: 'Install Source',
  onChange: () => {},
};

export default DefaultSourceSlicer;
