import React, { FormEvent, useEffect, useState } from 'react';
import { Modal, ModalProps } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import RadioSelector, { RadioSelectorOption } from '../components/RadioSelector';
import { AddPlaylistValues, onUpdatePlaylistOptionChosen } from './utils';

// TODO, use src/apps/sp3/curation/screens/PlaylistInfo/utils.ts
// and OMIT new version
enum InputType {
  SPOTIFY = 'SPOTIFY',
  CSV = 'CSV',
  SP2 = 'SP2',
  SPOTIFY_ARTISTS = 'SPOTIFY_ARTISTS',
}

type Props = {
  onAddPlaylist: (inputType: InputType, values: AddPlaylistValues) => void;
  show: boolean;
} & ModalProps;

const AddPlaylistPopup = ({ onAddPlaylist, ...rest }: Props) => {
  const [inputType, setInputType] = useState<InputType>(InputType.SPOTIFY);
  const [submitEnabled, setSubmitEnabled] = useState(false);

  const { show } = rest;

  useEffect(() => {
    setSubmitEnabled(false);
  }, [show]);

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    onUpdatePlaylistOptionChosen(event, inputType, onAddPlaylist);
  };

  return (
    <Modal {...rest} size="lg" aria-labelledby="contained-modal-title-vcenter" centered onSubmit={onSubmit}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add Playlist</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {/* @ts-ignore */}
          <RadioSelector title="Input type" value={inputType} onChange={setInputType}>
            <RadioSelectorOption value={InputType.CSV} label="Upload a CSV file" id="csv-input" />
            <RadioSelectorOption
              value={InputType.SPOTIFY}
              label="Provide a Spotify playlist URI or URL"
              id="spotify-uri-input"
            />
            <RadioSelectorOption
              value={InputType.SP2}
              label="Provide the production playlist ID of a SongPop 2 playlist"
              id="sp2-input"
            />
            <RadioSelectorOption
              value={InputType.SPOTIFY_ARTISTS}
              label="Provide one or more Spotify artist IDs/URLs/URIs (comma-separated)"
              id="auto-artist-type"
            />
          </RadioSelector>
          {inputType === InputType.CSV ? (
            <Form.Group controlId="csvPlaylistFile">
              <Form.Label>Upload a CSV file</Form.Label>
              <Form.Control type="file" accept=".csv" onChange={() => setSubmitEnabled(true)} />
            </Form.Group>
          ) : inputType === InputType.SPOTIFY ? (
            <Form.Group controlId="spotifyPlaylistURI">
              <Form.Label>Spotify Playlist URI or URL</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="spotify:user:billyjoelmusic:playlist:2WgQ96x6mlX6RMS2yuSmvp"
                onChange={() => setSubmitEnabled(true)}
              />
            </Form.Group>
          ) : inputType === InputType.SPOTIFY_ARTISTS ? (
            <>
              <Form.Group controlId="artistIds">
                <Form.Label>Spotify artist IDs/URLs/URIs (comma-separated)</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="7bcbShaqKdcyjnmv4Ix8j6,6M2wZ9GZgrQXHCFfjv46we,66CXWjxzNUsdJxJ2JdwvnR"
                  onChange={() => setSubmitEnabled(true)}
                />
              </Form.Group>

              <Form.Group controlId="includeExtras">
                <Form.Label>Include Extras </Form.Label>
                <Form.Check label="Add extra versions of the same songs (remix, live, etc )" />
              </Form.Group>
            </>
          ) : (
            <Form.Group controlId="sp2id">
              <Form.Label>SongPop 2 Production Playlist ID</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="5754333678665728"
                onChange={() => setSubmitEnabled(true)}
              />
            </Form.Group>
          )}
          <Button variant="primary" type="submit" disabled={!submitEnabled}>
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddPlaylistPopup;
