import { gql } from '@apollo/client';

/**
 * FRAGMENT
 */
export const TOURNAMENT_FRAGMENT = gql`
  fragment TournamentFragment on Tournament {
    id
    name
    description
    startDate
    endDate
    ticketCost
    category {
      id
    }
    assetsConfig {
      primaryColor
      secondaryColor
      secondaryDarkColor
      buttonColor
      characterImage
      headerImage
      contrast
      contrastGradient1
      contrastGradient2
    }
    contentPacks {
      id
      name
      popularity
      explicitContentStatus
    }
    adminSpecialEvent {
      id
      name
      startDate
      endDate
    }
    milestones {
      id
      score
      rewards {
        ... on Currency {
          amount
          __typename
        }
        ... on CurrencyCategoryFragment {
          amount
          category {
            id
            icon
            name
          }
        }
        ... on CurrencyBoost {
          amount
          boostType
          __typename
        }
        ... on Item {
          __typename
          id
        }
        ... on MonthlyBuff {
          __typename
          multiplier
          buffType
        }
      }
    }
    rankGroups {
      id
      minRank
      maxRank
      rewards {
        ... on Currency {
          amount
          __typename
        }
        ... on CurrencyCategoryFragment {
          amount
          category {
            id
            icon
            name
          }
        }
        ... on CurrencyBoost {
          amount
          boostType
          __typename
        }
        ... on Item {
          __typename
          id
        }
        ... on MonthlyBuff {
          __typename
          multiplier
          buffType
        }
      }
    }
  }
`;

/**
 * QUERIES
 */
export const GET_TOURNAMENTS = gql`
  query tournaments($limit: Int, $offset: Int) {
    liveOps {
      tournaments(limit: $limit, offset: $offset) {
        hasMore
        totalCount
        list {
          id
          startDate
        }
      }
    }
  }
`;

export const GET_TOURNAMENT = gql`
  query tournament($tournamentId: ID!) {
    tournament(tournamentId: $tournamentId) {
      ...TournamentFragment
    }
  }
  ${TOURNAMENT_FRAGMENT}
`;

/**
 * MUTATION
 */
export const UPDATE_TOURNAMENT = gql`
  mutation updateTournament($input: UpdateTournamentInput!) {
    updateTournament(input: $input) {
      tournament {
        ...TournamentFragment
      }
    }
  }
  ${TOURNAMENT_FRAGMENT}
`;

export const UPDATE_TOURNAMENT_MILESTONES = gql`
  mutation updateTournamentMilestones($input: UpdateTournamentMilestonesInput!) {
    updateTournamentMilestones(input: $input) {
      tournament {
        ...TournamentFragment
      }
    }
  }
  ${TOURNAMENT_FRAGMENT}
`;

export const UPDATE_TOURNAMENT_RANKGROUPS = gql`
  mutation updateTournamentRankGroups($input: UpdateTournamentRankGroupsInput!) {
    updateTournamentRankGroups(input: $input) {
      tournament {
        ...TournamentFragment
      }
    }
  }
  ${TOURNAMENT_FRAGMENT}
`;

export const UPDATE_TOURNAMENT_CONTENT_PACKS = gql`
  mutation updateTournamentContentPacks($input: UpdateTournamentContentPacksInput!) {
    updateTournamentContentPacks(input: $input) {
      tournament {
        ...TournamentFragment
      }
    }
  }
  ${TOURNAMENT_FRAGMENT}
`;

export const COPY_TOURNAMENT = gql`
  mutation copyTournament($input: CopyTournamentInput!) {
    copyTournament(input: $input) {
      tournament {
        ...TournamentFragment
      }
    }
  }
  ${TOURNAMENT_FRAGMENT}
`;

export const CREATE_TOURNAMENT = gql`
  mutation createTournament($input: CreateTournamentInput!) {
    createTournament(input: $input) {
      tournament {
        ...TournamentFragment
      }
    }
  }
  ${TOURNAMENT_FRAGMENT}
`;

export const REMOVE_TOURNAMENT = gql`
  mutation removeTournament($input: RemoveTournamentInput!) {
    removeTournament(input: $input) {
      isRemoved
    }
  }
`;

export const UPLOAD_HEADER_ASSET = gql`
  mutation uploadTournamentHeaderAsset($input: UploadAssetInput!) {
    uploadTournamentHeaderAsset(input: $input) {
      tournament {
        ...TournamentFragment
      }
    }
  }
  ${TOURNAMENT_FRAGMENT}
`;
export const UPLOAD_CHARACTER_ASSET = gql`
  mutation uploadTournamentCharacterAsset($input: UploadAssetInput!) {
    uploadTournamentCharacterAsset(input: $input) {
      tournament {
        ...TournamentFragment
      }
    }
  }
  ${TOURNAMENT_FRAGMENT}
`;

export const ADD_SPECIAL_EVENT_TOURNAMENT = gql`
  mutation addSpecialEventTournament($input: AddSpecialEventTournamentInput!) {
    addSpecialEventTournament(input: $input) {
      specialEvent {
        id
        name
      }
      tournament {
        id
        name
      }
    }
  }
`;

export const REMOVE_SPECIAL_EVENT_TOURNAMENT = gql`
  mutation removeSpecialEventTournament($input: RemoveSpecialEventTournamentInput!) {
    removeSpecialEventTournament(input: $input) {
      specialEvent {
        id
        name
      }
      tournament {
        id
        name
      }
    }
  }
`;
