import { useState } from 'react';
import { Card } from 'react-bootstrap';
import { useLocation } from 'react-router';
import { useQuery } from '@apollo/client';
import ImportTool from './ImportTool';
import PaginatedCatalogItems from './PaginatedCatalogItems';
import './Catalog.css';
import queries from '../../utils/queries';
import {
  HurdleCatalogItemListQuery,
  HurdleCatalogItemListQueryVariables,
  HurdleType,
} from '../../../../../__gqltypes__';
import FlushHurdleCacheButton from './FlushCache';

const ITEMS_PER_PAGE = 50;

export default function Catalog() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const rank = params.get('rank');
  const [page, setPage] = useState(rank !== null ? Math.floor((parseInt(rank, 10) - 1) / ITEMS_PER_PAGE) + 1 : 1);
  const { data, refetch, loading } = useQuery<HurdleCatalogItemListQuery, HurdleCatalogItemListQueryVariables>(
    queries.HURDLE_CATALOG_ITEM_LIST,
    {
      variables: {
        input: {
          limit: ITEMS_PER_PAGE,
          offset: ITEMS_PER_PAGE * (page - 1),
          type: HurdleType.DEFAULTSONG,
        },
      },
    }
  );

  const onRefetch = (newPage: number, force = false) => {
    if (newPage !== page || force) {
      refetch({
        input: { offset: ITEMS_PER_PAGE * (newPage - 1), limit: ITEMS_PER_PAGE, type: HurdleType.DEFAULTSONG },
      });
      setPage(newPage);
    }
  };

  return (
    <div className="d-flex">
      <div style={{ flex: 1 }}>
        <Card className="p-3">
          <ImportTool onCompleted={() => onRefetch(page, true)} />
        </Card>

        <Card className="p-3 mt-3">
          <FlushHurdleCacheButton />
        </Card>
      </div>

      <div style={{ width: '20px' }} />

      <div style={{ flex: 3 }}>
        <Card className="p-3" style={{ maxHeight: 'calc(100vh - 118px)' }}>
          <h4>Catalog</h4>
          <PaginatedCatalogItems
            page={page}
            refetch={onRefetch}
            loading={loading}
            hurdleCatalogItemList={data?.hurdleCatalogItemList?.list || []}
            totalCount={data?.hurdleCatalogItemList?.totalCount || 0}
            rank={rank !== null ? parseInt(rank) : null}
          />
        </Card>
      </div>
    </div>
  );
}
