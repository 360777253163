import React from 'react';
import DashboardContainer from '../../../../../../utils/dashboards/components/DashboardContainer';
// import { SimpleDateSlicing } from '../../../../../../utils/dashboards/components/slicers';
import { DefaultSlicing } from '../../../../../../utils/dashboards/components/slicers';
import { SectionDisplay } from '../../../../../../utils/dashboards/components/widgets';
import LineChart from '../../../../../../utils/dashboards/components/widgets/charts/LineChart';
// import PieChart from '../../../../../../utils/dashboards/components/widgets/charts/PieChart';
// import BarChart from '../../../../../../utils/dashboards/components/widgets/charts/BarChart';
import BaseTable from '../../../../../../utils/dashboards/components/widgets/tables/BaseTable';
import WidgetContainer from '../../../../../../utils/dashboards/components/widgets/WidgetContainer';
import Processing from '../../../../../../utils/dashboards/models/Processing';

const queries = {
  dailyFestivalStats: { fillZeroes: true },
  comparisonFestivalStats: { fillZeroes: true },
  rewardsClaimed: { fillZeroes: true },
};

const Festival = () => (
  <DashboardContainer queries={queries} id="festival" title="Festival">
    <DefaultSlicing id="DefaultSlicing" />
    <WidgetContainer id="WidgetContainer">
      <LineChart
        id="users_seeFestivalScreen"
        title="Users that see the Festival screen"
        processing={Processing.break('dailyFestivalStats', ['date', 'users_seeFestivalScreen'])}
      />
      <LineChart
        id="perc_users_seeFestivalScreen"
        title="Percentage of Users that see the Festival screen per DAU"
        processing={Processing.break('dailyFestivalStats', ['date', 'perc_users_seeFestivalScreen'])}
      />
      <LineChart
        id="users_engagingFestival"
        title="Users that engage with the Festival features (play Festival playlist, play Festival tournament and live show, claim Festival points)"
        processing={Processing.break('dailyFestivalStats', ['date', 'users_engagingFestival'])}
      />
      <LineChart
        id="perc_users_engagingFestival"
        title="Percentage of Users that engage with the Festival features per DAU"
        processing={Processing.break('dailyFestivalStats', ['date', 'perc_users_engagingFestival'])}
      />
      <LineChart
        id="avg_nb_MissionFinished"
        title="Average number of mission finished per users"
        processing={Processing.break('dailyFestivalStats', ['date', 'avg_nb_MissionFinished'])}
      />
      <LineChart
        id="avg_festivalPoints"
        title="Average festival points claimed per users"
        processing={Processing.break('dailyFestivalStats', ['date', 'avg_total_festivalPoints', 'avg_festival_points_UpgradePlaylist', 'avg_festival_points_MissionFinished', 'avg_festival_points_PlayLiveShow', 'avg_festival_points_PlayTournament'])}
      />
      <LineChart
        id="comparisonFestivalStats_ARPDAU"
        title="ARPDAU Comparison"
        processing={Processing.pivot('comparisonFestivalStats', 'userType', 'ARPDAU', 'date')}
      />
      <LineChart
        id="comparisonFestivalStats_gamesPlayedPerDAU"
        title="Games Played per DAU Comparison"
        processing={Processing.pivot('comparisonFestivalStats', 'userType', 'gamesPlayedPerDAU', 'date')}
      />
      <BaseTable
          id="rewardsClaimed"
          title="Users that claimed specific rewards per month."
        />
    </WidgetContainer>
  </DashboardContainer>
);

export default Festival;
