import { useEffect, useState } from 'react';
import { Badge, Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useLazyQuery } from '@apollo/client';

import { SearchType } from '../../../../../../__gqltypes__';
import { stringToEnum } from '../utils';
import { VANITY_ITEM_BY_ID_QUERY } from '../../../components/apolloQueries';
import { Item } from '../../Items/components/typedef';

enum HighPriorDailyItemTypeEnum {
  Sticker = 'Sticker',
  ProfileFrame = 'Frame',
  AppSkin = 'Vinyl',
}

type Props = {
  fetchedDeal: Partial<Item>;
  dealIndex: number;
  handleDealUpdate: (updatedDeal: Partial<Item>, dealIndex: number) => Partial<Item>;
  handleDealDelete: (dealIndex: number) => void;
};

export function DailyDeal({ fetchedDeal, dealIndex, handleDealUpdate, handleDealDelete }: Props) {
  const [isValidItemId, setIsValidItemId] = useState(false);

  const [fetchData] = useLazyQuery(VANITY_ITEM_BY_ID_QUERY, {
    onCompleted: ({ item }) => {
      if (item) {
        handleDealUpdate(item, dealIndex);
        setIsValidItemId(true);
      } else {
        setIsValidItemId(false);
      }
    },
  });

  useEffect(() => {
    if (fetchedDeal.id) {
      fetchData({
        variables: {
          property: fetchedDeal.id,
          type: SearchType.BY_ID,
        },
      });
    }
  }, [fetchData, fetchedDeal.id]);

  return (
    <tr>
      <td style={{ verticalAlign: 'middle' }}>
        <Form.Control disabled value={stringToEnum(fetchedDeal.__typename ?? '', HighPriorDailyItemTypeEnum)} />
      </td>
      <td className="d-flex align-items-center">
        <Form.Control
          placeholder="Item ID"
          value={fetchedDeal.id ?? ''}
          onChange={(e) => handleDealUpdate({ ...fetchedDeal, id: e.target.value }, dealIndex)}
          type="text"
        />
        {isValidItemId ? (
          <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip">Item id is correct.</Tooltip>}>
            <Badge variant="success">&#10003;</Badge>
          </OverlayTrigger>
        ) : (
          <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip">Wrong item id!</Tooltip>}>
            <Badge variant="danger">&#10007;</Badge>
          </OverlayTrigger>
        )}
      </td>
      <td>
        <Button
          onClick={() => {
            handleDealDelete(dealIndex);
          }}
          variant="danger"
        >
          <i className="fas fa-trash mr-2" />
        </Button>
      </td>
    </tr>
  );
}
