import React from 'react';
import Form, { FormProps } from 'react-bootstrap/Form';

type Props = {
  title: string;
  details?: string;
  value?: string | number;
} & FormProps;

const EditableParam = ({ title, value, onChange, placeholder, details }: Props) => (
  <Form.Group>
    <Form.Label>{details ? `${title} (${details})` : title}</Form.Label>
    {/* @ts-ignore */}
    <Form.Control value={value} placeholder={placeholder} onChange={onChange} />
  </Form.Group>
);

export default EditableParam;
