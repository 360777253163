import React from 'react';

import { DefaultSlicing } from '../../../../../../utils/dashboards/components/slicers';
import { SectionDisplay } from '../../../../../../utils/dashboards/components/widgets';
import WidgetContainer from '../../../../../../utils/dashboards/components/widgets/WidgetContainer';
import DashboardContainer from '../../../../../../utils/dashboards/components/DashboardContainer';
import LineChart from '../../../../../../utils/dashboards/components/widgets/charts/LineChart';
import BarChart from '../../../../../../utils/dashboards/components/widgets/charts/BarChart';
import BaseTable from '../../../../../../utils/dashboards/components/widgets/tables/BaseTable';
import StackedAreaChart from '../../../../../../utils/dashboards/components/widgets/charts/StackedAreaChart';
import Processing from '../../../../../../utils/dashboards/models/Processing';

const queries = {
  temp_subs_abtest_1: { fillZeroes: true },
  temp_subs_abtest_2: { fillZeroes: true },
  temp_subs_abtest_3: { fillZeroes: true },
  temp_subs_abtest_4: { fillZeroes: true },
  temp_subs_abtest_5: { fillZeroes: true },
  temp_subs_abtest_6: { fillZeroes: true },
  temp_subs_abtest_7: { fillZeroes: true },
  temp_subs_abtest_8: { fillZeroes: true },
  temp_subs_abtest_9: { fillZeroes: true },
  temp_subs_abtest_10: { fillZeroes: true },
  temp_subs_abtest_11: { fillZeroes: true },
  temp_subs_abtest_12: { fillZeroes: true },
  temp_subs_abtest_13: { fillZeroes: true },
  temp_subs_abtest_14: { fillZeroes: true },
};

const ABTestSpecificStudies = () => (
  <>
    <DashboardContainer queries={queries} id="ab_test_specific_studies" title="ABTest Specific Studies">
      <DefaultSlicing id="DefaultSlicing" />
      <WidgetContainer id="WidgetContainer">
      
        <SectionDisplay notes="Subscription AB Test 1 - Android only" />
        <BaseTable id="temp_subs_abtest_1" title="Temporary Widget on Subscription Duration AB Test since 2022-02-03 (Android only)" />
        <LineChart
          id="temp_subs_abtest_2_registrations"
          title="Installs per Days Since Install"
          processing={Processing.pivot('temp_subs_abtest_2', 'variation', 'registrations_total', 'days_since_install')}
        />
        <LineChart
          id="temp_subs_abtest_2_ltv"
          title="LTV per Days Since Install"
          processing={Processing.pivot('temp_subs_abtest_2', 'variation', 'ltv', 'days_since_install')}
        />
        <LineChart
          id="temp_subs_abtest_2_cr"
          title="Subscribers per Installs at Days Since Install"
          processing={Processing.pivot('temp_subs_abtest_2', 'variation', 'cr', 'days_since_install')}
        />

        <SectionDisplay notes="Subscription AB Test 2 - iOS only" />
        <BaseTable id="temp_subs_abtest_3" title="Temporary Widget on Subscription Duration AB Test since 2022-03-02 (iOS only)" />
        <LineChart
          id="temp_subs_abtest_4_registrations"
          title="Installs per Days Since Install"
          processing={Processing.pivot('temp_subs_abtest_4', 'variation', 'registrations_total', 'days_since_install')}
        />
        <LineChart
          id="temp_subs_abtest_4_ltv"
          title="LTV per Days Since Install"
          processing={Processing.pivot('temp_subs_abtest_4', 'variation', 'ltv', 'days_since_install')}
        />
        <LineChart
          id="temp_subs_abtest_4_cr"
          title="Subscribers per Installs at Days Since Install"
          processing={Processing.pivot('temp_subs_abtest_4', 'variation', 'cr', 'days_since_install')}
        />

        <SectionDisplay notes="Subscription AB Test 3 - Android & iOS" />
        <BaseTable id="temp_subs_abtest_5" title="Temporary Widget on Subscription Duration AB Test since 2022-03-30" />
        <LineChart
          id="temp_subs_abtest_6_registrations"
          title="Installs per Days Since Install"
          processing={Processing.pivot('temp_subs_abtest_6', 'variation', 'registrations_total', 'days_since_install')}
        />
        <LineChart
          id="temp_subs_abtest_6_ltv"
          title="LTV per Days Since Install"
          processing={Processing.pivot('temp_subs_abtest_6', 'variation', 'ltv', 'days_since_install')}
        />
        <LineChart
          id="temp_subs_abtest_6_cr"
          title="Subscribers per Installs at Days Since Install"
          processing={Processing.pivot('temp_subs_abtest_6', 'variation', 'cr', 'days_since_install')}
        />

        <SectionDisplay notes="Subscription AB Test 4 - AdFree subscription" />
        <BaseTable id="temp_subs_abtest_7" title="Temporary Widget on Subscription Duration AB Test since 2022-07-20" />
        <LineChart
          id="temp_subs_abtest_8_registrations"
          title="Installs per Days Since Install"
          processing={Processing.pivot('temp_subs_abtest_8', 'variation', 'registrations_total', 'days_since_install')}
        />
        <LineChart
          id="temp_subs_abtest_8_ltv"
          title="LTV per Days Since Install"
          processing={Processing.pivot('temp_subs_abtest_8', 'variation', 'ltv', 'days_since_install')}
        />
        <LineChart
          id="temp_subs_abtest_8_cr"
          title="Subscribers per Installs at Days Since Install"
          processing={Processing.pivot('temp_subs_abtest_8', 'variation', 'cr', 'days_since_install')}
        />

        <SectionDisplay notes="Subscription AB Test 5 - Weekly vs Monthly subscription" />
        <BaseTable id="temp_subs_abtest_9" title="Temporary Widget on Subscription Duration AB Test since 2022-08-15 (previously 2022-07-26)" />
        <LineChart
          id="temp_subs_abtest_10_registrations"
          title="Installs per Days Since Install"
          processing={Processing.pivot('temp_subs_abtest_10', 'variation', 'registrations_total', 'days_since_install')}
        />
        <LineChart
          id="temp_subs_abtest_10_ltv"
          title="LTV per Days Since Install"
          processing={Processing.pivot('temp_subs_abtest_10', 'variation', 'ltv', 'days_since_install')}
        />
        <LineChart
          id="temp_subs_abtest_10_cr"
          title="Subscribers per Installs at Days Since Install"
          processing={Processing.pivot('temp_subs_abtest_10', 'variation', 'cr', 'days_since_install')}
        />

        <SectionDisplay notes="Subscription AB Test 5 - Weekly vs Monthly subscription + Ads free" />
        <BaseTable id="temp_subs_abtest_11" title="Temporary Widget on Subscription Duration AB Test since 2022-08-15 (previously 2022-07-26)" />
        <LineChart
          id="temp_subs_abtest_12_registrations"
          title="Installs per Days Since Install"
          processing={Processing.pivot('temp_subs_abtest_12', 'variation', 'registrations_total', 'days_since_install')}
        />
        <LineChart
          id="temp_subs_abtest_12_ltv"
          title="LTV per Days Since Install"
          processing={Processing.pivot('temp_subs_abtest_12', 'variation', 'ltv', 'days_since_install')}
        />
        <LineChart
          id="temp_subs_abtest_12_cr"
          title="Subscribers per Installs at Days Since Install"
          processing={Processing.pivot('temp_subs_abtest_12', 'variation', 'cr', 'days_since_install')}
        />

        <SectionDisplay notes="Subscription AB Test 6- Free trial for everyone" />
        <BaseTable id="temp_subs_abtest_13" title="Temporary Widget on Subscription Duration AB Test since 2023-05-09" />
        <LineChart
          id="temp_subs_abtest_14_registrations"
          title="Installs per Days Since Install"
          processing={Processing.pivot('temp_subs_abtest_14', 'variation', 'registrations_total', 'days_since_install')}
        />
        <LineChart
          id="temp_subs_abtest_14_ltv"
          title="Subscription LTV per Days Since Install"
          processing={Processing.pivot('temp_subs_abtest_14', 'variation', 'ltv', 'days_since_install')}
        />
        <LineChart
          id="temp_subs_abtest_14_cr"
          title="Subscribers per Installs at Days Since Install"
          processing={Processing.pivot('temp_subs_abtest_14', 'variation', 'cr', 'days_since_install')}
        />

      </WidgetContainer>
    </DashboardContainer>
  </>
);

export default ABTestSpecificStudies;


