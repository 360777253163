import React from 'react';
import moment from 'moment';

// Slicers
import SlicerContainer from './SlicerContainer';
import DateSlicer from './components/DateSlicer';
import DefaultVipStatusSlicer from './default/DefaultVipStatusSlicer';

// PropTypes
import { defaultSlicingPropTypes } from './slicerPropTypes';

const EconomySlicing = ({ defaultStartDate, defaultEndDate, onRefresh }) => (
  <SlicerContainer onRefresh={onRefresh}>
    <DateSlicer name="DateSlicer" id="startDate" title="Start Date" defaultValue={defaultStartDate} />
    <DateSlicer name="DateSlicer" id="endDate" title="End Date" defaultValue={defaultEndDate} />
    <DefaultVipStatusSlicer name="DefaultVipStatusSlicer" />
  </SlicerContainer>
);

EconomySlicing.propTypes = defaultSlicingPropTypes;

EconomySlicing.defaultProps = {
  defaultStartDate: moment().subtract(15, 'days').format('YYYY-MM-DD'),
  defaultEndDate: moment().subtract(2, 'day').format('YYYY-MM-DD'),
};

export default EconomySlicing;
