import './MissionDisplay.css';

import React, { useCallback, useState } from 'react';
import { Accordion as AccordionBootstrap, Button, Card, Spinner, Table } from 'react-bootstrap';
import moment from 'moment';

import {
  AccordionProps,
  AchievementMissionsDisplayProps,
  AchievementMissionsProgressResultProps,
  Mission,
} from '../typedefs';

const AchievementMissionsDisplay: React.FC<AchievementMissionsDisplayProps> = ({ userId, missions }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Card className="mb-4">
      <Card.Body>
        <AccordionBootstrap>
          <AccordionBootstrap.Toggle as={Card.Title} variant="link" eventKey="0" onClick={() => setExpanded(!expanded)}>
            <div style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}>
              Achievement missions
              <Button variant="link">
                {!expanded && <i className="fas fa-chevron-down" />}
                {expanded && <i className="fas fa-chevron-up" />}
              </Button>
            </div>
          </AccordionBootstrap.Toggle>
          <AccordionBootstrap.Collapse eventKey="0">
            <>
              {missions ? (
                <>
                  <div className="mt-2" style={{ maxHeight: '50vh', overflowY: 'auto' }}>
                    <MissionProgressResults missions={missions} loading={false} />
                  </div>
                </>
              ) : (
                'No missions have been configured for this monthly pass'
              )}
            </>
          </AccordionBootstrap.Collapse>
        </AccordionBootstrap>
      </Card.Body>
    </Card>
  );
};

export default AchievementMissionsDisplay;

const MissionProgressResults: React.FC<AchievementMissionsProgressResultProps> = ({ missions, loading }) => {
  const displayEmptyArray = () => {
    return <div>Empty Array</div>;
  };

  const displayMissions = (data: Mission[]) => {
    return (
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Task name</th>
            <th>Reward coins</th>
            <th>Reward diamonds</th>
            <th>Progress</th>
            <th>Claimed date</th>
            <th>Claimed multiplier</th>
          </tr>
        </thead>
        <tbody>
          {data.map((mission: Mission) => (
            <tr key={mission.id}>
              <td>{mission?.mission?.tasks[0]?.type}</td>
              <td>{mission?.mission?.rewardCoins}</td>
              <td>{mission?.mission?.rewardDiamonds}</td>
              <td>
                {mission?.progress}/{mission.progressGoal}
              </td>
              <td>
                {mission?.claimedDate
                  ? moment.unix(mission?.claimedDate / 1000).format('MM/DD/YYYY HH:mm:ss')
                  : 'not available'}
              </td>
              <td>{mission?.claimedMultiplier ? mission?.claimedMultiplier : 'not available'}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  if (!missions) {
    return <div>No result</div>;
  }

  return <div>{missions.length > 0 ? displayMissions(missions) : displayEmptyArray()}</div>;
};

const Accordion: React.FC<AccordionProps> = ({ children, active }) => {
  return <div className={`mission_pannel_collapse ${active ? 'active' : ''}`}>{children}</div>;
};
