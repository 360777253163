import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import { ChoiceButtonGroupProps } from '../../../../../../utils/components/inputs/ChoiceButtonGroup';
import { ListOfNamesInputProps } from '../../../../../../utils/components/inputs/ListOfNamesInput';
import { NumberInputProps } from '../../../../../../utils/components/inputs/NumberInput';
import { StartEndInputProps } from '../../../../../../utils/components/inputs/StartEndInput';

export function useChoiceButtonGroupProps<T extends string = string>(
  choices: Partial<Record<T, { label: string }>>,
  defaultChoice?: T,
  disabledChoices?: Partial<Record<T, boolean>>
): Pick<ChoiceButtonGroupProps<T>, 'choices' | 'choice' | 'setChoice' | 'disabledChoices'> { 
  const [choice, setChoice] = useState<T>(defaultChoice || (Object.keys(choices)[0] as T));

  const setDefaultChoice = (choices: Partial<Record<T, { label: string }>>, defaultChoice?: T) => {
    if (defaultChoice && defaultChoice in choices) {
      setChoice(defaultChoice);
    } else {
      setChoice(Object.keys(choices)[0] as T);
    }
  };

  useEffect(() => {
    setDefaultChoice(choices, defaultChoice);
  }, [choices, defaultChoice]);

  useEffect(() => {
    if(disabledChoices?.[choice]) {
      setDefaultChoice(choices, defaultChoice);
    }
  }, [disabledChoices?.[choice]]);

  return { choices, choice, setChoice, disabledChoices };
}

export function useListOfNameProps(defaultListOfNames: string[]): {
  reset: (resetToDefault: boolean) => void;
  props: Omit<ListOfNamesInputProps, 'title'>;
} {
  const [currentValue, setCurrentValue] = useState('');
  const [names, setNames] = useState<string[]>(defaultListOfNames);

  const handleAddNames = (newNames: string[]) => {
    setNames((pNames) => _.uniq([...pNames, ...newNames]));
    setCurrentValue('');
  };

  const handleRemoveName = (name: string) => {
    setNames((pNames) => pNames.filter((n) => n !== name));
  };

  const handleReset = useCallback((resetToDefault: boolean) => {
    setNames(resetToDefault ? defaultListOfNames : []);
    setCurrentValue('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    reset: handleReset,
    props: {
      handleAddNames,
      handleRemoveName,
      currentValue,
      setCurrentValue,
      names,
    },
  };
}

export function useNumberInputProps(defaultValue?: number): Omit<NumberInputProps, 'title'> & { intValue: number } {
  const [value, setValue] = useState<string>(defaultValue?.toString() || '5');

  return { value, setValue, intValue: parseInt(value) };
}

export function useStartEnd(initialStart?: string, initialEnd?: string): StartEndInputProps {
  const [start, setStart] = useState<string | undefined>(initialStart);
  const [end, setEnd] = useState<string | undefined>(initialEnd);

  return { start, end, setStart, setEnd };
}
