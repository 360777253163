import { useCallback, useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { RouteComponentProps } from 'react-router-dom';
import { CurationSongDatabaseSearchQuery } from '../../../../../__gqltypes__';
import DatabaseSongPopup from '../../popups/DatabaseSongPopup';
import SongSearchContent from './components/SongSearchContent';

const baseURL = '/sp3/playlist/song-search';
const expectedUrlParams = ['album', 'artist', 'endYear', 'isrc', 'label', 'startYear', 'subLabel', 'title', 'type'];

type DefaultSearchParams = {
  album: string | null;
  artist: string | null;
  isrc: string | null;
  label: string | null;
  startYear: number | null;
  endYear: number | null;
  subLabel: string | null;
  title: string | null;
  type: string | null;
};

export default function SongSearch({ history, location }: RouteComponentProps<Record<string, never>>) {
  const [selectedSong, setSelectedSong] = useState<
    CurationSongDatabaseSearchQuery['curationSongDatabaseSearch'][0] | null
  >(null);
  const [defaultSearchParams, setDefaultParams] = useState<DefaultSearchParams>(
    // @ts-ignore
    ['type', ...expectedUrlParams].reduce((acc, value) => {
      // @ts-ignore
      acc[value] = null;
      return acc;
    }, {})
  );

  // Retrieving params from url to handle url sharing
  useEffect(() => {
    const incomingParams = new URLSearchParams(location.search);
    // @ts-ignore
    const sanitizedIncomingParams: DefaultSearchParams = expectedUrlParams.reduce((acc, urlParam) => {
      // @ts-ignore
      acc[urlParam] = incomingParams.get(urlParam) ?? null;
      return acc;
    }, {});

    setDefaultParams(sanitizedIncomingParams);
  }, [location.search, setDefaultParams]);

  // Updating url on search to make url sharing easier
  const handleSearchTriggered = useCallback(
    (type, term) => {
      const updatedTerms = { ...term, type };
      console.log(updatedTerms);
      const queryString = Object.keys(updatedTerms)
        .filter((param) => updatedTerms[param] !== '' && updatedTerms[param] != null)
        .map((param) => `${param}=${updatedTerms[param] ?? ''}`)
        .join('&');

      history.push(`${baseURL}?${queryString}`);
    },
    [history]
  );

  const handleSelectSong = useCallback((val) => {
    setSelectedSong(val);
  }, []);

  const handleCloseModal = useCallback(() => {
    setSelectedSong(null);
  }, []);

  return (
    <>
      <Row className="justify-content-between mb-2">
        <Col lg={4}>
          <h1>Song Search</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <SongSearchContent
            actionButton="Show Details"
            defaultAlbum={defaultSearchParams.album}
            defaultArtist={defaultSearchParams.artist}
            defaultEndYear={defaultSearchParams.endYear}
            defaultIsrc={defaultSearchParams.isrc}
            defaultLabel={defaultSearchParams.label}
            defaultStartYear={defaultSearchParams.startYear}
            defaultSubLabel={defaultSearchParams.subLabel}
            defaultTitle={defaultSearchParams.title}
            defaultType={defaultSearchParams.type}
            onSearchTriggered={handleSearchTriggered}
            onSongSelected={handleSelectSong}
          />
          <DatabaseSongPopup show={!!selectedSong} songId={selectedSong?.id} onHide={handleCloseModal} />
        </Col>
      </Row>
    </>
  );
}
