import { useMutation } from '@apollo/client';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Row from 'react-bootstrap/Row';
import {
  CurationContentPackCustomField,
  CurationContentPackQuery,
  UpdateCurationContentPackCustomFieldsMutation,
  UpdateCurationContentPackCustomFieldsMutationVariables,
} from '../../../../../../__gqltypes__';
import Tag from '../../../components/Tag';
import mutations from '../../../utils/mutations';

const ITEM_DISPLAY_NAME: Record<CurationContentPackCustomField, string> = {
  INTRO_SAMPLE: 'Intro Sample',
  MOVIE_TITLE: 'Movie Title',
  LEAD_SINGER: 'Lead Singer',
  JESTER_TITLE: 'Jester Title',
  COMPOSER: 'Composer',
  ARTIST: 'Artist',
  TITLE: 'Title',
  CUSTOM: 'Playlist Custom Title Display',
  FULL_CUSTOM: 'Playlist Custom Title/Artist Display',
  CUSTOM_SAMPLE: 'Playlist Custom Sample',
};

type Props = {
  curationContentPack: CurationContentPackQuery['curationContentPack'];
};

export default function ForcedFields({ curationContentPack }: Props) {
  const [updatePlaylistForcedFields, { loading }] = useMutation<
    UpdateCurationContentPackCustomFieldsMutation,
    UpdateCurationContentPackCustomFieldsMutationVariables
  >(mutations.UPDATE_CURATION_CONTENT_PACK_CUSTOM_FIELDS);

  const disabled = curationContentPack.state !== 'VALIDATING';

  const removeField = (field: CurationContentPackCustomField) => {
    if (disabled) {
      return;
    }

    const { selectedCustomFields, id } = curationContentPack;
    const fields = (selectedCustomFields || []).filter((f) => f !== field);
    updatePlaylistForcedFields({ variables: { input: { id, fields }, id } });
  };

  const addField = (field: CurationContentPackCustomField) => {
    if (disabled) {
      return;
    }

    const { selectedCustomFields, id } = curationContentPack;
    const fields = selectedCustomFields ? [...selectedCustomFields, field] : [field];
    updatePlaylistForcedFields({ variables: { input: { id, fields }, id } });
  };

  const selectedCustomFields = curationContentPack.selectedCustomFields || [];

  return (
    <>
      <Row className="mx-1">Forced Fields:</Row>
      <Row className="mx-2 mb-2">
        <span>
          {loading ? (
            'Updating...'
          ) : (
            <>
              {(Object.keys(ITEM_DISPLAY_NAME) as CurationContentPackCustomField[]).map((f) => (
                <Tag
                  key={f}
                  value={ITEM_DISPLAY_NAME[f]}
                  visible={selectedCustomFields.includes(f)}
                  onRemove={() => removeField(f)}
                  disabled={disabled}
                />
              ))}
              <DropdownButton
                disabled={disabled}
                as="span"
                id="dropdown-item-button"
                className="m-2"
                size="sm"
                title="Add Field"
              >
                {(Object.keys(ITEM_DISPLAY_NAME) as CurationContentPackCustomField[])
                  .filter((f) => !selectedCustomFields.includes(f))
                  .map((f) => (
                    <Dropdown.Item key={f} as="button" onClick={() => addField(f)}>
                      {ITEM_DISPLAY_NAME[f]}
                    </Dropdown.Item>
                  ))}
              </DropdownButton>
            </>
          )}
        </span>
      </Row>
    </>
  );
}
