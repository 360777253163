import React from 'react';
import Card from 'react-bootstrap/Card';
import moment from 'moment';
import BaseChart from '../../../../../../utils/dashboards/components/widgets/charts/BaseChart';

const parseTimestamp = (timestamp) => {
  // The event_timestamp in the logs can be in micro (length = 16) or millisecond (length = 13)
  const date = timestamp.toString().length === 16 ? moment(parseFloat(timestamp / 1000)) : moment(timestamp);
  return `${date.format('HH:mm:ss.SSS')}`;
};

export const GraphHistory = ({ logs }) => {
  const dataset = {
    label: 'total_connection',
    data: [],
    fill: false,
    borderColor: '#007fff',
  };
  const labels = [];
  const sorted = logs.sort((a, b) => a.event_timestamp - b.event_timestamp);
  sorted.forEach((log) => {
    if (log.event_params) {
      dataset.data.push(log.event_params.total_connection || 0);
      labels.push(parseTimestamp(log.event_timestamp));
    }
  });
  return (
    <Card className="shadow mb-4">
      <Card.Header className="m-0 font-weight-bold text-primary">History</Card.Header>
      <Card.Body className="p-0">
        <BaseChart
          id="GraphHistory"
          type="line"
          height={150}
          labels={labels}
          datasets={[dataset]}
          title="History"
          labelFormat="HH:mm:ss.SSS"
        />
      </Card.Body>
    </Card>
  );
};
