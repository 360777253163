import React from 'react';
import Badge from 'react-bootstrap/Badge';
import { CurationContentPackState } from '../../../../__gqltypes__';

type Props = {
  state: CurationContentPackState | null;
  showDefault?: boolean;
  sp2Only?: boolean;
};

export default function PlaylistState({ state, showDefault, sp2Only }: Props) {
  switch (state) {
    case 'CREATED':
      return (
        <Badge pill className="m-1" variant="primary">
          created
        </Badge>
      );
    case 'PROCESSING':
      return (
        <Badge pill className="m-1" variant="primary">
          processing
        </Badge>
      );
    case 'VALIDATING':
      return sp2Only ? (
        <Badge pill className="m-1" variant="warning">
          SP2 Only
        </Badge>
      ) : (
        <Badge pill className="m-1" variant="success">
          validating
        </Badge>
      );
    case 'OUTDATED':
      return (
        <Badge pill className="m-1" variant="dark">
          outdated
        </Badge>
      );

    case 'DELETED':
      return (
        <Badge pill className="m-1" variant="danger">
          deleted
        </Badge>
      );

    default:
      if (showDefault) {
        return (
          <Badge pill className="m-1" variant="success">
            {(state ?? '').toLowerCase()}
          </Badge>
        );
      }
      return null;
  }
}
