import { useMutation } from '@apollo/client';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Row from 'react-bootstrap/Row';
import {
  CurationContentPackQuery,
  UpdateCurationContentPackLocaleMutation,
  UpdateCurationContentPackLocaleMutationVariables,
} from '../../../../../../__gqltypes__';
import Tag from '../../../components/Tag';
import { ALL_LOCALES, displayLocaleWithFlag, SUPPORTED_LOCALE } from '../../../utils/locales';
import mutations from '../../../utils/mutations';

type Props = {
  curationContentPack: CurationContentPackQuery['curationContentPack'];
};

export default function PlaylistLocale({ curationContentPack }: Props) {
  const [updatePlaylistLocale, { loading }] = useMutation<
    UpdateCurationContentPackLocaleMutation,
    UpdateCurationContentPackLocaleMutationVariables
  >(mutations.UPDATE_CURATION_CONTENT_PACK_LOCALE);

  const disabled = curationContentPack.state !== 'VALIDATING';

  const removeLocale = () => {
    if (disabled) {
      return;
    }

    const { id } = curationContentPack;
    updatePlaylistLocale({ variables: { input: { id, locale: null } } });
  };

  const addLocale = (locale: string) => {
    if (disabled) {
      return;
    }

    const { id } = curationContentPack;
    updatePlaylistLocale({ variables: { input: { id, locale } } });
  };

  return (
    <>
      <Row className="mx-1">Locale:</Row>
      <Row className="mx-2 mb-2">
        <span>
          {loading ? (
            'Updating...'
          ) : curationContentPack.locale ? (
            <Tag
              value={displayLocaleWithFlag(curationContentPack.locale as SUPPORTED_LOCALE)}
              visible
              onRemove={removeLocale}
              disabled={disabled}
            />
          ) : (
            <DropdownButton
              disabled={disabled}
              as="span"
              id="dropdown-item-button"
              className="m-2"
              size="sm"
              title="Add Locale"
            >
              {ALL_LOCALES.map((f) => (
                <Dropdown.Item key={f} as="button" onClick={() => addLocale(f)}>
                  {displayLocaleWithFlag(f)}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          )}
        </span>
      </Row>
    </>
  );
}
