import React from 'react';
import { Col, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { FilledMissionTaskInput } from '../typedef';

const renderTooltip = ({ task, ...others }: { task: FilledMissionTaskInput; others?: unknown }) => (
  <Tooltip id="tooltip-params-task" {...others}>
    Parameters:
    <Col className="d-flex flex-column">
      {task.params.map((param) => (
        <div key={`tooltip-param-${param}`}>
          <hr />
          <span>{param}</span>
        </div>
      ))}
    </Col>
  </Tooltip>
);

type Props = {
  task: FilledMissionTaskInput;
  onClick: () => void;
  onDelete: () => void;
};

/**
 * A component displaying a button that sum up info about a monthly pass mission task
 */
const EditTask = ({ task, onClick, onDelete }: Props) => (
  <div className="chip px-3">
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={(props) => renderTooltip({ task, ...props })}
    >
      <span role="link" tabIndex={0} onClick={onClick}>
        {task.type}
      </span>
    </OverlayTrigger>

    <button type="button" className="button" onClick={onDelete}>
      ×
    </button>
  </div>
);

export default EditTask;
