import React from 'react';
import Badge from 'react-bootstrap/Badge';

export default function ExplicitIcon({ explicit }: { explicit: boolean }) {
  if (!explicit) return null;
  return (
    <Badge className="m-1" variant="danger">
      E
    </Badge>
  );
}
