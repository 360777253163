import moment from 'moment';
import {
  InteractiveMonitoringPlaygroundOptions,
  NumberOfTimestamps,
  RequestTrackingMethod,
  TimeSelectionMethod,
} from './formOptions';
import { MetricOrderBy, MetricTimestamp, MetricType, ServerEnv, ServerMode } from '../../../../../../__gqltypes__';

const defaultSearch =
  '?type=GqlRequest&timestamp=Hourly&timeSelection=PREVIOUS&requestTracking=MOST_IMPORTANT&maxNumberOfRequests=5&maxNumberOfTimestamps=LAST_72_HOURS&sortMethod=WORST_CUMULATED_TIME_SORT';

function hashCode(str: string) {
  // eslint-disable-next-line no-bitwise
  return Array.from(str).reduce((hash, char) => 0 | (31 * hash + char.charCodeAt(0)), 0);
}

/**
 *
 * @param options the options from the form used to chose the monitoring data to display
 * @returns a string ready to pe added to an url "type=..."
 */
export default function buildUrl(options: InteractiveMonitoringPlaygroundOptions): string {
  const search = Object.entries({
    ...options,
    requestNames: options.requestNames && options.requestNames.length > 0 ? options.requestNames.join('|') : null,
  })
    .filter(([_key, value]) => value !== null && value !== undefined)
    .map((keyValue) => keyValue.join('='))
    .join('&');

  return `${search}&key=${hashCode(search)}`;
}

function validateUrl(decoded: Record<string, string>): {
  errors: string[];
  validatedDecoded: InteractiveMonitoringPlaygroundOptions;
  key: string;
} {
  const validatedDecoded: Record<string, string | number | string[] | boolean> = {};
  const errors: string[] = [];

  // First, get the 4 always required fields
  const requiredFields = {
    type: (t: string) => (Object.values(MetricType) as string[]).includes(t),
    timestamp: (t: string) => (Object.values(MetricTimestamp) as string[]).includes(t),
    timeSelection: (t: string) => t in TimeSelectionMethod,
    requestTracking: (t: string) => t in RequestTrackingMethod,
  };
  Object.entries(requiredFields).forEach(([key, validator]) => {
    if (!decoded[key]) errors.push(`Missing field ${key}`);
    else if (!validator(decoded[key])) errors.push(`Invalid field ${key}`);
    else validatedDecoded[key] = decoded[key];
  });

  // Then get the mode
  const mode = decoded.mode ?? ServerMode.API;
  if (!(Object.values(ServerMode) as string[]).includes(mode)) {
    errors.push('Invalid server mode');
  } else if (mode === ServerMode.BACKGROUND && validatedDecoded.type === MetricType.GQLREQUEST) {
    errors.push('Background mode is incompatible with spanner request type');
  } else {
    validatedDecoded.mode = mode;
  }

  // Get the env
  const beta = process.env.REACT_APP_NAMESPACE === 'beta';
  const prod = process.env.REACT_APP_NAMESPACE === 'default' || process.env.REACT_APP_NAMESPACE === 'prod';
  const allowedEnvs = prod || beta ? [ServerEnv.PROD, ServerEnv.BETA] : [ServerEnv.TEST];
  const env = decoded.env ?? allowedEnvs[0];
  if (allowedEnvs.includes(env as ServerEnv)) {
    validatedDecoded.env = env;
  } else {
    // if we arrive on test from beta/prod, beta/prod unssuported => go to test
    // if we arrive on beta/prod from test, test unssuported => go to prod
    validatedDecoded.env = allowedEnvs[0];
  }

  // Then, either we fetch specific requests or we need to specify a sort method
  if (decoded.requestTracking === RequestTrackingMethod.SPECIFIC) {
    if (!decoded.requestNames || decoded.requestNames.split('|').length < 1) errors.push('Missing request names');
    else validatedDecoded.requestNames = decoded.requestNames.split('|');
  } else if (!decoded.sortMethod) errors.push('Missing sortMethod');
  else if (![...Object.keys(MetricOrderBy), 'ALL'].includes(decoded.sortMethod)) errors.push('Invalid sortMethod');
  else {
    validatedDecoded.sortMethod = decoded.sortMethod;
    validatedDecoded.includeSubscriptions = decoded.includeSubscriptions === 'true';
    if (decoded.sortMethod !== 'ALL' && !parseInt(decoded.maxNumberOfRequests, 10)) {
      errors.push('Missing maxNumberOfRequests');
    } else if (decoded.sortMethod !== 'ALL' && parseInt(decoded.maxNumberOfRequests, 10) <= 0) {
      errors.push('Invalid maxNumberOfRequests');
    } else validatedDecoded.maxNumberOfRequests = parseInt(decoded.maxNumberOfRequests, 10);
  }

  // Sqme idea for time selection, either it's start-end range, or it's some number of timestamps
  if (decoded.timeSelection === TimeSelectionMethod.BETWEEN) {
    if (!decoded.start) errors.push('Missing start date');
    else if (!moment(decoded.start).isValid) errors.push('Invalid start date');
    else validatedDecoded.start = decoded.start;

    if (!decoded.end) errors.push('Missing end date');
    else if (!moment(decoded.end).isValid) errors.push('Invalid end date');
    else validatedDecoded.end = decoded.end;
  } else if (!decoded.maxNumberOfTimestamps) errors.push('Missing maxNumberOfTimestamps');
  else if (!(decoded.maxNumberOfTimestamps in NumberOfTimestamps)) errors.push('Invalid maxNumberOfTimestamps');
  else validatedDecoded.maxNumberOfTimestamps = decoded.maxNumberOfTimestamps;

  // Finaly, if we get the compare tag, we need also a compare skip value
  validatedDecoded.compare = decoded.compare === 'true';
  if (validatedDecoded.compare) {
    if (!parseInt(decoded.compareSkip, 10)) errors.push('Missing compareSkip value');
    if (parseInt(decoded.compareSkip, 10) <= 0) errors.push('Invalid compareSkip value');
    validatedDecoded.compareSkip = parseInt(decoded.compareSkip, 10);
  }

  console.log(validatedDecoded);

  return {
    errors,
    validatedDecoded: validatedDecoded as InteractiveMonitoringPlaygroundOptions,
    key: decoded.key || `${Date.now}`,
  };
}

/**
 * @param search A search string from url (including "?") or an empty string ""
 * @returns Options ready to populate the playground, or if they are complete, to fetch the graph
 */
export function decodeUrl(search: string): {
  errors: string[];
  validatedDecoded: InteractiveMonitoringPlaygroundOptions;
  key: string;
} {
  const searchOrDefault = search.length > 1 ? search : defaultSearch;

  const decoded = Object.fromEntries(
    searchOrDefault
      .slice(1)
      .split('&')
      .map((keyvalue) => keyvalue.split('='))
  );

  return validateUrl(decoded);
}
