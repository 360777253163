import moment from 'moment';
import DateSlicer from './components/DateSlicer';
import RangeSlicer from './components/RangeSlicer';
import TextSlicer from './components/TextSlicer';
import DefaultPlatformSlicer from './default/DefaultPlatformSlicer';
// Slicers
import SlicerContainer from './SlicerContainer';
// PropTypes
import { defaultSlicingPropTypes } from './slicerPropTypes';

const ABTestSlicing = ({ appInstall, defaultStartDate, defaultEndDate, defaultUserLevel, onRefresh }) =>
  appInstall ? (
    <SlicerContainer onRefresh={onRefresh}>
      <DateSlicer name="DateSlicer" id="startDate" title="Start Date" defaultValue={defaultStartDate} />
      <DateSlicer name="DateSlicer" id="endDate" title="End Date" defaultValue={defaultEndDate} />
      <TextSlicer name="TextListSlicer" id="testId" title="Test Name" />
      <TextSlicer name="minTextSlicer" id="minimum_app_version" title="Minimum App Version" />
      <DateSlicer name="minDateSlicer" id="registration_date" title="Minimum Install Date" />
      <DefaultPlatformSlicer name="DefaultPlatformSlicer" />
    </SlicerContainer>
  ) : (
    <SlicerContainer onRefresh={onRefresh}>
      <DateSlicer name="DateSlicer" id="startDate" title="Start Date" defaultValue={defaultStartDate} />
      <DateSlicer name="DateSlicer" id="endDate" title="End Date" defaultValue={defaultEndDate} />
      <TextSlicer name="TextListSlicer" id="testId" title="Test Name" />
      <TextSlicer name="minTextSlicer" id="minimum_app_version" title="Minimum App Version" />
      <DateSlicer name="minDateSlicer" id="registration_date" title="Minimum Registration Date" />
      <RangeSlicer id="user_level" title="User Level" name="RangeSlicer" defaultValue={defaultUserLevel} />
      <DefaultPlatformSlicer name="DefaultPlatformSlicer" />
    </SlicerContainer>
  );

ABTestSlicing.propTypes = defaultSlicingPropTypes;

ABTestSlicing.defaultProps = {
  defaultStartDate: moment().subtract(15, 'days').format('YYYY-MM-DD'),
  defaultEndDate: moment().subtract(2, 'day').format('YYYY-MM-DD'),
  defaultUserLevel: '0-40',
  appInstall: false,
};

export default ABTestSlicing;
