import { useQuery } from '@apollo/client';
import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Media from 'react-bootstrap/Media';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { CurationSongDetailsQuery, CurationSongDetailsQueryVariables } from '../../../../__gqltypes__';
import ExplicitIcon from '../components/ExplicitIcon';
import Loader from '../components/Loader';
import PlayButton from '../components/PlayButton';
import QuestionableIcon from '../components/QuestionableIcon';
import queries from '../utils/queries';
import ExternalLinksTable from './PlaylistSongPopup/components/ExternalLinksTable';
import './PlaylistSongPopup/PlaylistSongPopup.css';

type Props = {
  show: boolean;
  songId?: string | null;
  onHide: () => void;
};

export default function DatabaseSongPopup({ show, songId, onHide }: Props) {
  const { loading, data } = useQuery<CurationSongDetailsQuery, CurationSongDetailsQueryVariables>(
    queries.CURATION_SONG_DETAILS,
    { variables: { id: songId ?? '' }, skip: !songId || !show }
  );

  const song = data?.curationContentItem;

  if (!songId || song?.__typename !== "CurationSong") return null;

  if (loading || !song || !song.externalLinks) {
    return (
      <Modal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Loader />
      </Modal>
    );
  }

  return (
    <Modal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="modal-title-buttons">
          <h3>Song Details</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-2">
          <Col>
            <Media>
              <Image src={song.picture} thumbnail className="artwork-popup" />
              <Media.Body>
                Title: {song.title}
                <br />
                Artist: {song.artist}
                <br />
                Album: {song.album}
                <br />
                Release year: {song.albumYear}
                <ExplicitIcon explicit={song.isExplicit} />
                <QuestionableIcon questionable={song.questionableStatus || null} />
              </Media.Body>
            </Media>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            {'Legal: '}
            <Badge pill variant={song.state === 'BLACKLISTED' ? 'danger' : 'success'}>
              {`${song.label} - ${song.subLabel}`}
            </Badge>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            Default Sample:&nbsp;
            <PlayButton sample={song.defaultSample} />
            <br />
            Movie Title: {song.movieTitle || 'N/A'}
            <br />
            Jester Title: {song.jesterTitle || 'N/A'}
            <br />
            Lead Singer: {song.leadSinger || 'N/A'}
            <br />
            Composer: {song.composer || 'N/A'}
            <br />
            Intro Sample:&nbsp;
            {song.introSample ? <PlayButton sample={song.introSample} /> : 'N/A'}
          </Col>
        </Row>
        <Row>
          <Col>
            <Alert variant="info">
              Song ID: {song.id}
              <br />
              ISRC: {song.isrc}
            </Alert>
          </Col>
        </Row>
        <Row>
          <Col>
            <ExternalLinksTable externalLinks={song.externalLinks} />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}
