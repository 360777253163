import { Children, ReactElement, useEffect, useReducer, useState } from 'react';

type WidgetQueryData = Record<string, any>;

type Action = {
  id: string;
  reset?: boolean;
  payload: any;
};

const reducer = (processedData: WidgetQueryData, action: Action) => {
  if (action.id) {
    return { ...processedData, [action.id]: action.payload };
  }
  if (action.reset) {
    return {};
  }
  return processedData;
};

const useWidgets = (children: ReactElement[], queryData: WidgetQueryData) => {
  const [processedData, changeProcessedData] = useReducer(reducer, {});
  const [processingLoading, setProcessingLoading] = useState(false);

  useEffect(() => {
    Children.forEach<ReactElement>(children, (child) => {
      const { id, processing } = child.props;
      if (processing) {
        setProcessingLoading(true);
        const payload = processing.applyTo(queryData);
        changeProcessedData({
          id,
          payload,
        });
        setProcessingLoading(false);
      }
    });
  }, [children, queryData]);

  const downloadCSV = (widgetId: string) => () => {
    let csv = processedData[widgetId] ? processedData[widgetId].exportAsCSV() : queryData[widgetId].exportAsCSV();
    if (csv == null) return;
    const filename = widgetId || 'chart-data.csv';
    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }
    const data = encodeURI(csv);
    const link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return {
    queryData,
    processedData,
    downloadCSV,
    processingLoading,
  };
};

export default useWidgets;
