import { useState } from 'react';
import { Button } from 'react-bootstrap';

import SearchDDEXSongsPopup from '../../../popups/SearchDDEXSongsPopup';
import { CurationSongDdexSearchQuery } from '../../../../../../__gqltypes__';

/**
 * A Component to select or clear DDEX songs
 */
type CurationSongDDEXSearch = CurationSongDdexSearchQuery['curationSongDDEXSearch'][0];
type Props = {
  loading: boolean;
  onSubmit: (songs: CurationSongDDEXSearch[]) => void;
};

export default function AddingDDEXSongs({ loading, onSubmit }: Props) {
  const [addingDDEXSongsModalShow, setAddingDDEXSongsModalShow] = useState<boolean>(false);

  const modalClose = () => setAddingDDEXSongsModalShow(false);
  const modalOpen = () => setAddingDDEXSongsModalShow(true);

  const handleConfirm = (updatedSongs: CurationSongDDEXSearch[]) => {
    onSubmit(updatedSongs);
    modalClose();
  };

  return (
    <>
      <Button disabled={loading} className="mt-2 mt-xl-0" onClick={modalOpen}>
        <span>{loading ? 'Adding more songs from DDEX...' : 'Add more songs from DDEX'}</span>
      </Button>

      <SearchDDEXSongsPopup show={addingDDEXSongsModalShow} onHide={modalClose} onConfirm={handleConfirm} />
    </>
  );
}
