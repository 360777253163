export const levels = {
  debug: {
    colored: <i className="fas fa-bug" style={{ color: '#3783f9' }} />,
    gray: <i className="fas fa-bug" style={{ color: '#aaa' }} />,
  },
  info: {
    colored: <i className="fas fa-info-circle" style={{ color: '#75a811' }} />,
    gray: <i className="fas fa-info-circle" style={{ color: '#aaa' }} />,
  },
  error: {
    colored: <i className="fas fa-times" style={{ color: '#a00' }} />,
    gray: <i className="fas fa-times" style={{ color: '#aaa' }} />,
  },
  warn: {
    colored: <i className="fas fa-exclamation-circle" style={{ color: '#dd7d00' }} />,
    gray: <i className="fas fa-exclamation-circle" style={{ color: '#aaa' }} />,
  },
};
export const servers = {
  api_server: {
    colored: <span style={{ color: '#a00', fontWeight: 'bold' }}>API</span>,
    gray: <span style={{ color: '#aaa', fontWeight: 'bold' }}>API</span>,
  },
  live_server: {
    colored: <span style={{ color: '#a747f1', fontWeight: 'bold' }}>LIVE</span>,
    gray: <span style={{ color: '#aaa', fontWeight: 'bold' }}>LIVE</span>,
  },
};
