/* eslint-disable @typescript-eslint/no-explicit-any */
// Disabling "explicit any" because following functions are general purposes helpers
import moment from 'moment';

export const extractUnique = (array: Array<any>) => {
  const counter: Record<any, boolean> = {};
  array.forEach((val ) => {
    counter[val] = true;
  });
  return Object.keys(counter);
};

export const isUnique = (array: Array<any>) => {
  const counter: Record<any, boolean> = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const val of array) {
    if (counter[val]) {
      return false;
    }
    counter[val] = true;
  }
  return true;
};

export const generateDateRangeIndexColumn = (from: moment.MomentInput, to: moment.MomentInput) => {
  let current = moment(from);
  const last = moment(to);
  const index = [current.format('YYYY-MM-DD')];
  while (current.isBefore(last)) {
    current = current.add(1, 'day');
    index.push(current.format('YYYY-MM-DD'));
  }
  return index;
};

export const generateIndex = (array: Array<any>) => {
  const index: Record<any, number> = {};
  array.forEach((elem, idx) => {
    index[elem] = idx;
  });
  return index;
};

export const generateRanges = (array: Array<any>, rangeNumber: number) => {
  const min = Math.min(...array);
  const max = Math.max(...array);
  const delta = Math.floor((max - min) / rangeNumber);
  const res = Array(rangeNumber)
    .fill(0)
    .map((_, index) => {
      if (index === rangeNumber - 1) {
        return [min + delta * index, max];
      }
      return [min + delta * index, min + delta * (index + 1)];
    });
  return res;
};