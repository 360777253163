/**
 * EVENTS DISPLAY
 */
export const EVENTS = {
  'Jun 1 2021': ['iOS: 1.5.0', 'Android: 1.5.0'],
  'Jun 17 2021': ['iOS: 1.5.1', 'Android: 1.5.1'],
  'Jun 28 2021': ['iOS: 1.5.2', 'Android: 1.5.2'],
  'Jul 6 2021': ['iOS: 1.5.3', 'Android: 1.5.3'],
  'Sep 20 2021': ['Apple: iOS 15'],
  'Aug 2 2021': ['iOS: 1.6.0', 'Android: 1.6.0'],
  'Aug 16 2021': ['iOS: 1.7.0', 'Android: 1.7.0'],
  'Sep 29 2021': ['iOS: 1.8.0', 'Android: 1.8.0'],
  'Oct 15 2021': ['iOS: 1.8.1', 'Android: 1.8.1'],
  'Oct 28 2021': ['iOS: 1.8.2', 'Android: 1.8.2'],
  'Nov 8 2021': ['iOS: 1.8.3', 'Android: 1.8.3'],
  'Nov 18 2021': ['Apple: iOS 15.1'],
  'Nov 22 2021': ['iOS: 1.9.0', 'Android: 1.9.0'],
  'Nov 30 2021': ['iOS: 1.9.1', 'Android: 1.9.1'],
  'Dec 7 2021': ['iOS: 1.9.2', 'Android: 1.9.2'],
  'Dec 13 2021': ['iOS: 1.9.3', 'Android: 1.9.3'],
  'Dec 20 2021': ['iOS: 1.9.4', 'Android: 1.9.4'],
  'Dec 21 2021': ['iOS: 1.9.5', 'Android: 1.9.5'],
  'Jan 17 2022': ['iOS: 1.10.0', 'Android: 1.10.0'],
  'Jan 27 2022': ['iOS: 1.10.1', 'Android: 1.10.1'],
  'Feb 1 2022': ['iOS: 1.10.2', 'Android: 1.10.2'],
  'Feb 8 2022': ['iOS: 1.10.3', 'Android: 1.10.3'],
  'Feb 17 2022': ['iOS: 1.10.4', 'Android: 1.10.4'],
  'Feb 24 2022': ['iOS: 1.10.5', 'Android: 1.10.5'],
  'Feb 28 2022': ['iOS: 1.10.6', 'Android: 1.10.6'],
  'Mar 7 2022': ['iOS: 1.11.0', 'Android: 1.11.0'],
  'Mar 11 2022': ['iOS: 1.11.1', 'Android: 1.11.1'],
  'Mar 25 2022': ['iOS: 1.12.0', 'Android: 1.12.0'],
  'Mar 29 2022': ['iOS: 1.12.1', 'Android: 1.12.1'],
  'Apr 4 2022': ['iOS: 1.12.2', 'Android: 1.12.2'],
  'Apr 11 2022': ['iOS: 1.12.3', 'Android: 1.12.3'],
  'Apr 18 2022': ['iOS: 1.12.4', 'Android: 1.12.4'],
  'Apr 28 2022': ['iOS: 1.12.5', 'Android: 1.12.5'],
  'May 4 2022': ['Android: 1.12.6'],
  'May 9 2022': ['iOS: 1.12.7', 'Android: 1.12.7'],
  'May 31 2022': ['iOS: 1.12.8', 'Android: 1.12.8'],
  'Jun 20 2022': ['iOS: 3.0.0', 'Android: 3.0.0'],
  'Jul 12 2022': ['iOS: 3.1.0', 'Android: 3.1.0'],
  'Jul 19 2022': ['iOS: 3.1.1', 'Android: 3.1.1'],
  'Jul 25 2022': ['iOS: 3.1.2', 'Android: 3.1.2'],
  'Aug 2 2022': ['iOS: 3.1.3', 'Android: 3.1.3'],
  'Aug 15 2022': ['iOS: 3.1.4', 'Android: 3.1.4'],
  'Aug 23 2022': ['iOS: 3.1.5', 'Android: 3.1.5'],
  'Sep 29 2022': ['iOS: 3.2.0', 'Android: 3.2.0'],
  'Oct 2 2022': ['iOS: 3.2.1', 'Android: 3.2.1'],
  'Nov 7 2022': ['iOS: 3.3.0', 'Android: 3.3.0'],
  'Nov 8 2022': ['Android: 3.3.1', 'Android: 3.3.2'],
  'Nov 9 2022': ['iOS: 3.3.2'],
  'Nov 11 2022': ['Android: 3.3.3'],
  'Nov 13 2022': ['iOS: 3.3.3'],
  'Nov 21 2022': ['Android: 3.4.0', 'Android: 3.4.1', 'iOS: 3.4.0'],
  'Dec 5 2022': ['Android: 3.4.2', 'iOS: 3.4.2'],
  'Dec 22 2022': ['Android: 3.4.3', 'iOS: 3.4.3'],
  'Jan 9 2023': ['Android: 3.5.0', 'iOS: 3.5.0'],
  'Jan 13 2023': ['Android: 3.5.1', 'iOS: 3.5.1'],
  'Jan 19 2023': ['Android: 3.5.2', 'iOS: 3.5.2'],
  'Jan 23 2023': ['Android: 3.5.3', 'iOS: 3.5.3'],
  'Feb 6 2023': ['Android: 3.6.0', 'iOS: 3.6.0'],
  'Feb 16 2023': ['Android: 3.7.0', 'iOS: 3.7.0'],
  'Mar 6 2023': ['Android: 3.7.1', 'iOS: 3.7.1'],
  'Mar 13 2023': ['Android: 3.7.2'],
  'Apr 24 2023': ['Android: 3.7.3', 'iOS: 3.7.3'],
  'May 8 2023': ['Android: 3.7.4', 'iOS: 3.7.4'],
  'May 22 2023': ['Android: 3.7.5', 'iOS: 3.7.5'],
  'May 30 2023': ['Android: 3.8.0'],
  'Jul 26 2023': ['Android: 3.8.1', 'iOS: 3.8.1'],
  'Aug 17 2023': ['Android: 3.8.2', 'iOS: 3.8.2'],
  'Aug 21 2023': ['Android: 3.8.3'],
  'Sep 18 2023': ['Android: 3.9.0', 'iOS: 3.9.0'],
  'Oct 24 2023': ['Android: 3.10.0', 'iOS: 3.10.0'],
};
