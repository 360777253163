import { gql } from '@apollo/client';
import { HURDLE_CATALOG_ITEM_FRAGMENT } from '../../../curation/utils/fragments';

/**
 * FRAGMENTS
 */
const HURDLE_QUESTION_FRAGMENT = gql`
  fragment HurdleQuestionFragment on HurdleQuestion {
    id
    asset
    offset
    song {
      id
      displayTitle
      displayArtist
    }
  }
`;

export const HURDLE_QUIZ_FRAGMENT = gql`
 fragment HurdleQuizFragment on HurdleQuiz {
    id
    startDate
    endDate
    namespace
    categoryId
    type
    question {
      ...HurdleQuestionFragment
    }
    catalogItemId
 }
 ${HURDLE_QUESTION_FRAGMENT}
`;

/**
 * QUERIES
 */
export const GET_HURDLE_CALENDAR = gql`
  query hurdleCalendar($input: HurdleCalendarInput!) {
    hurdleCalendar(input: $input) {
      hurdleQuizzes {
        ...HurdleQuizFragment
      }
    }
  }
  ${HURDLE_QUIZ_FRAGMENT}
`;

export const GET_HURDLE_QUIZ = gql`
  query hurdleQuiz($input: HurdleQuizInput!) {
    hurdleQuiz(input: $input) {
      hurdleQuiz {
        ...HurdleQuizFragment
      }
    }
  }
  ${HURDLE_QUIZ_FRAGMENT}
`;

export const GET_HURDLE_CATALOG_ITEM = gql`
  query hurdleCatalogItem($input: HurdleCatalogItemInput!) {
    hurdleCatalogItem(input: $input) {
      catalogItem {
        ...HurdleCatalogItemFragment
      }
    }
  }
  ${HURDLE_CATALOG_ITEM_FRAGMENT}
`;

/**
 * MUTATIONS
 */
export const UPDATE_HURDLE_QUIZ = gql`
  mutation updateHurdleQuiz($input: UpdateHurdleQuizInput!) {
    updateHurdleQuiz(input: $input) {
      hurdleQuiz {
        ...HurdleQuizFragment
      }
    }
  }
  ${HURDLE_QUIZ_FRAGMENT}
`;

export const GENERATE_HURDLE_QUIZZES = gql`
  mutation generateHurdleQuizzes($input: GenerateHurdleQuizzesInput!) {
    generateHurdleQuizzes(input: $input) {
      hurdleQuizzes {
        ...HurdleQuizFragment
      }
    }
  }
  ${HURDLE_QUIZ_FRAGMENT}
`;

export const VALIDATE_HURDLE_QUIZ_CONFIG = gql`
  query validateHurdleQuizConfig($input: ValidateHurdleQuizConfigInput!) {
    validateHurdleQuizConfig(input: $input) {
      isValid
      messages
    }
  }
`;
