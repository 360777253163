class Processing {
  static CHAIN_RESULT = 'processingChainResult_5489059238708';

  constructor(func, ids) {
    this.ids = ids;
    this.rawFunc = func;
    this.func = (dataTables) => {
      if (this.ids.every((id) => !!dataTables[id])) {
        try {
          const result = func(dataTables);
          return result;
        } catch (e) {
          console.error(e);
          return undefined;
        }
      }
      return undefined;
    };
  }

  applyTo(dataTables) {
    return this.func(dataTables);
  }

  static chain(...processings) {
    const ids = {};
    processings.forEach((processing) => {
      processing.ids.forEach((id) => {
        ids[id] = true;
      });
    });
    delete ids[this.CHAIN_RESULT];

    const func = (dataTables) => {
      processings.forEach((processing) => {
        dataTables[this.CHAIN_RESULT] = processing.rawFunc(dataTables);
      });

      const result = dataTables[this.CHAIN_RESULT];
      delete dataTables[this.CHAIN_RESULT];
      return result;
    };
    return new Processing(func, Object.keys(ids));
  }

  static chainWithName(...namedProcessings) {
    const artificialIds = {};
    const ids = {};
    namedProcessings.forEach(({ processing, name }) => {
      artificialIds[name] = true;
      processing.ids.forEach((id) => {
        ids[id] = true;
      });
    });

    Object.keys(artificialIds).forEach((id) => {
      delete ids[id];
    });

    const func = (dataTables) => {
      let result;
      namedProcessings.forEach(({ processing, name }) => {
        result = processing.rawFunc(dataTables);
        if (name) {
          dataTables[name] = result;
        }
      });

      Object.keys(artificialIds).forEach((id) => {
        delete dataTables[id];
      });
      return result;
    };
    return new Processing(func, Object.keys(ids));
  }

  static join(id1, id2) {
    const func = (dataTables) => dataTables[id1].join(dataTables[id2]);
    return new Processing(func, [id1, id2]);
  }

  static joinFirst(id1, id2) {
    const func = (dataTables) => dataTables[id1].joinFirst(dataTables[id2]);
    return new Processing(func, [id1, id2]);
  }

  static divide(id1, id2, colsToDivide, otherCols, newLabels) {
    const func = (dataTables) => dataTables[id1].divide(dataTables[id2], colsToDivide, otherCols, newLabels);
    return new Processing(func, [id1, id2]);
  }

  static add(id1, id2, colsToAdd, otherCols, newLabels) {
    const func = (dataTables) => dataTables[id1].add(dataTables[id2], colsToAdd, otherCols, newLabels);
    return new Processing(func, [id1, id2]);
  }

  static sumColumns(id, baseColumn, colsToSum, newLabel) {
    const func = (dataTables) => dataTables[id].sumColumns(baseColumn, colsToSum, newLabel);
    return new Processing(func, [id]);
  }

  static pivot(id, pivotColumn, dataColumn, newIndexCol = null, fillZero = true) {
    const func = (dataTables) => dataTables[id].pivot(pivotColumn, dataColumn, newIndexCol, fillZero);
    return new Processing(func, [id]);
  }

  static transpose(id, pivotColumn, dataColumn) {
    const func = (dataTables) => dataTables[id].transpose(pivotColumn, dataColumn);
    return new Processing(func, [id]);
  }

  static rangeCount(id, rangeColumn, pivotColumn = null, rangeNumber = 10, filter = null) {
    const func = (dataTables) => dataTables[id].rangeCount(rangeColumn, pivotColumn, rangeNumber, filter);
    return new Processing(func, [id]);
  }

  static aggregate(id, type, columns) {
    const func = (dataTables) => dataTables[id].aggregate(type, columns);
    return new Processing(func, [id]);
  }

  static cumulate(id) {
    const func = (dataTables) => dataTables[id].cumulate();
    return new Processing(func, [id]);
  }

  static concat(id, ...otherIds) {
    const func = (dataTables) => {
      const idsToConcat = [];
      otherIds.forEach((otherId) => {
        if (dataTables[otherId]) {
          idsToConcat.push(otherId);
        }
      });
      return dataTables[id].concat(...idsToConcat.map((idToConcat) => dataTables[idToConcat]));
    };
    return new Processing(func, [id]);
  }

  static sort(id, sortColumns) {
    const func = (dataTables) => dataTables[id].sort(sortColumns);
    return new Processing(func, [id]);
  }

  static filter(id, column, value) {
    const func = (dataTables) => dataTables[id].filter(column, value);
    return new Processing(func, [id]);
  }

  static filterAroundTime(id, timestamp) {
    const func = (dataTables) => dataTables[id].filterAroundTime(timestamp);
    return new Processing(func, [id]);
  }

  static cleanZeros(id) {
    const func = (dataTables) => dataTables[id].cleanZeros();
    return new Processing(func, [id]);
  }

  static completeWithZeros(id, columns) {
    const func = (dataTables) => dataTables[id].completeWithZeros(columns);
    return new Processing(func, [id]);
  }

  static filterDays(id, days, keepLast = false) {
    const func = (dataTables) => dataTables[id].filterDays(days, keepLast);
    return new Processing(func, [id]);
  }

  static indexToString(id, newIndexLabel) {
    const func = (dataTables) => dataTables[id].indexToString(newIndexLabel);
    return new Processing(func, [id]);
  }

  static colorPartialData(id, column, endDate) {
    const func = (dataTables) => dataTables[id].colorPartialData(column, endDate);
    return new Processing(func, [id]);
  }

  static normalize(id) {
    const func = (dataTables) => dataTables[id].normalize();
    return new Processing(func, [id]);
  }

  static variation(id, otherId) {
    const func = (dataTables) => dataTables[id].variation(dataTables[otherId]);
    return new Processing(func, [id, otherId]);
  }

  static percentage(id) {
    const func = (dataTables) => dataTables[id].percentage();
    return new Processing(func, [id]);
  }

  static isolate(id, columnToIsolate, newName) {
    const func = (dataTables) => dataTables[id].isolate(columnToIsolate, newName);
    return new Processing(func, [id]);
  }

  static rotate(id, labels) {
    const func = (dataTables) => dataTables[id].rotate(labels);
    return new Processing(func, [id]);
  }

  static cast(id, columnsToCast, type) {
    const func = (dataTables) => dataTables[id].cast(columnsToCast, type);
    return new Processing(func, [id]);
  }

  static break(id, columns, newLabels) {
    const func = (dataTables) => dataTables[id].break(columns, newLabels);
    return new Processing(func, [id]);
  }
}

export default Processing;
