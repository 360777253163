import { gql } from '@apollo/client';

export const AUTOCOMPLETE_SONG = gql`
  query autocompleteSong($input: HurdleAutoCompleteInput!) {
    hurdleAutoComplete(input: $input) {
      items {
        ... on SongItemSuggestion {
          id
          title
          artist
          catalogItemId
        }
      }
    }
  }
`;
