import * as React from 'react';
import { Button, Col, Form, Modal, ModalProps, Row } from 'react-bootstrap';

type Props = {
  onAdd: (value: { name: string }) => void;
  loading: boolean;
} & ModalProps;
function AddSaleEventModal({ show, onHide, onAdd, loading }: Props) {
  // @ts-ignore
  const handleSubmit = (event) => {
    // Prevent trigger other forms
    event.preventDefault();
    event.nativeEvent.stopImmediatePropagation();

    const { value } = event.target.elements.namedItem('saleEventName');

    onAdd({ name: value });
  };

  /**
   * VIEW
   */
  return (
    <Modal show={show} onHide={onHide} onSubmit={handleSubmit}>
      <Modal.Body>
        <Form>
          <Row>
            <Col xs={4}>SaleEvent Name</Col>
            <Col xs={6}>
              <Form.Group controlId="saleEventName">
                <Form.Control required type="text" />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-3 pl-1">
            <Button disabled={loading} type="submit">
              Create
            </Button>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default AddSaleEventModal;
