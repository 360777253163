import React from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
// PropTypes
import { slicerPropTypes } from '../slicerPropTypes';

const NumberSlicer = ({ id, value, onChange, title }) => (
  <Form.Group as={Row} controlId={id}>
    <Form.Label column>{title ?? id}</Form.Label>
    <Col>
      <Form.Control type="number" value={value} onChange={(e) => onChange(e.target.value || null)} />
    </Col>
  </Form.Group>
);

NumberSlicer.propTypes = slicerPropTypes;

NumberSlicer.defaultProps = {
  onChange: () => {},
};

export default NumberSlicer;
