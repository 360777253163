// eslint-disable-next-line @typescript-eslint/ban-ts-comment
import { useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import { useState } from 'react';
import { Dropdown, DropdownButton, Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import {
  GetUsersQuery,
  GetUsersQueryVariables,
  UpdateAccountRoleMutation,
  UpdateAccountRoleMutationVariables,
  UpdateUserRoleInput,
  UserRole,
} from '../../../../__gqltypes__';
import Tag from '../../curation/components/Tag';
import { ROLES_LIST, UPDATE_ROLE } from '../graphql';
import UpdateAppRoleModal from '../popups/UpdateAppRoleModal';

const AppRole = ({ role, onDelete, ...rest }: { role: UserRole; onDelete: () => void }) => (
  <Tag {...rest} visible value={role} onRemove={onDelete} />
);

const RoleRow = ({
  user,
  onAdd,
  onDelete,
}: {
  user: { emailCredential?: string | null; displayName: string; role: UserRole };
  onAdd: ({ email, role }: { email?: string; role?: UserRole }) => void;
  onDelete: ({ email }: { email: string }) => void;
}) => {
  const { emailCredential } = user;
  const handleDelete = () => {
    if (emailCredential) onDelete({ email: emailCredential });
  };
  return (
    <tr>
      <td>{user.emailCredential || 'No email credentials'}</td>
      <td>{user.displayName}</td>
      <td>
        <>
          <AppRole key={user.role} role={user.role} onDelete={handleDelete} />
          <Button
            className="ml-2"
            variant="primary"
            size="sm"
            onClick={() => onAdd({ email: user.emailCredential || undefined, role: user.role })}
          >
            Update
          </Button>
        </>
      </td>
    </tr>
  );
};

export default function AdminUsers(): JSX.Element | null {
  const [displayModal, setDisplayModal] = useState<{ email?: string; appId?: string; role?: UserRole } | null>(null);

  const [filteredRole, setFilteredRole] = useState(UserRole.ADMIN);

  const { data, loading, refetch } = useQuery<GetUsersQuery, GetUsersQueryVariables>(ROLES_LIST, {
    variables: { role: filteredRole },
  });
  const [updateRole] = useMutation<UpdateAccountRoleMutation, UpdateAccountRoleMutationVariables>(UPDATE_ROLE);

  const handleOpenEmptyPopup = () => setDisplayModal({});
  const handleOpenPrefilledPopup = ({ email, appId, role }: { email?: string; appId?: string; role?: UserRole }) =>
    setDisplayModal({ email, appId, role });

  const onDelete = ({ email }: { email: string }) => handleUpdate({ email, role: UserRole.USER });

  const handleUpdate = async (input: UpdateUserRoleInput): Promise<void> => {
    await updateRole({ variables: { input } }).then(() => refetch({ role: filteredRole }));
  };

  const handleClose = () => setDisplayModal(null);


  const { usersWithRoles } = (data?.liveOps || { usersWithRoles: [] });


  const sortedUsers = _.sortBy(usersWithRoles, 'emailCredential');


  return (
    <div>
      <div className="d-flex justify-content-end py-2">
        <Button variant="primary" onClick={handleOpenEmptyPopup}>
          Add User
        </Button>
      </div>

      <Card className="mb-4">
        <Card.Header>Roles
        <DropdownButton
              size="sm"
              as="span"
              className="mx-2"
              defaultValue={filteredRole}
              title={filteredRole}
              onSelect={(value) => {
                if (value) {
                  setFilteredRole(value as UserRole);
                  refetch({role:(value as UserRole)});
                }
              }}
            >
              {[UserRole.ADMIN, UserRole.CONTENT_MODERATOR, UserRole.CURATOR].map((role) => (
                <Dropdown.Item eventKey={role} key={`dropdown-type-${role}`} active={filteredRole === role}>
                  {role}
                </Dropdown.Item>
              ))}
            </DropdownButton>

        
        </Card.Header>
        <Card.Body className="pt-0">
          {loading && (<Spinner className="m-2" animation="border"/>)}
          {!loading && (
            <Table responsive>
              <thead>
                <tr>
                  <th>User</th>
                  <th>Name</th>
                  <th>Role</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {sortedUsers.map((user) => (
                  <RoleRow key={user.id} user={user} onAdd={handleOpenPrefilledPopup} onDelete={onDelete} />
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <th>User</th>
                  <th>Name</th>
                  <th>Role</th>
                  <th />
                </tr>
              </tfoot>
            </Table>
          )}
        </Card.Body>
      </Card>
      {!!displayModal && (
        <Modal show={!!displayModal} onHide={handleClose}>
          <UpdateAppRoleModal
            {...displayModal}
            searchString={displayModal.email || ''}
            updateRole={handleUpdate}
            onHide={handleClose}
          />
        </Modal>
      )}
    </div>
  );
}
