import { useMutation } from '@apollo/client';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Row from 'react-bootstrap/Row';
import {
  CurationContentPackQuery,
  UpdateCurationContentPackCategoriesMutation,
  UpdateCurationContentPackCategoriesMutationVariables,
} from '../../../../../../__gqltypes__';
import Tag from '../../../components/Tag';
import mutations from '../../../utils/mutations';

const ITEM_DISPLAY_NAME = {
  'decades.pre1950': 'Pre 1950s',
  'decades.1950': '50s',
  'decades.1960': '60s',
  'decades.1970': '70s',
  'decades.1980': '80s',
  'decades.1990': '90s',
  'decades.2000': '2000s',
  'decades.2010': '2010s',
  'decades.2020': '2020s',
  blues: 'Blues',
  classical: 'Classical',
  country: 'Country',
  dance: 'Dance',
  disco: 'Disco',
  electronic: 'Electronic',
  expert: 'Expert',
  jester: 'Jester',
  family: 'Family',
  folk: 'Folk',
  indie: 'Indie',
  jazz: 'Jazz',
  latin: 'Latin',
  love: 'Love Or Not',
  mix: 'Mixtapes',
  metal: 'Metal',
  'movies-tv': 'Soundtrack',
  'new-age': 'New Age',
  pop: 'Pop',
  punk: 'Punk',
  praise: 'Praise',
  'hip-hop': 'Hip Hop',
  reggae: 'Reggae',
  rnb: 'R&B',
  rock: 'Rock',
  'top-hits': 'Top Hits',
  workout: 'Workout',
  world: 'World',
};

type Props = {
  curationContentPack: CurationContentPackQuery['curationContentPack'];
};

export default function CategoryFields({ curationContentPack }: Props) {
  const categories = (curationContentPack.categories || []).map(({ id }) => id);
  const disabled = curationContentPack.state !== 'VALIDATING';

  const [updatePlaylistCategories, { loading }] = useMutation<
    UpdateCurationContentPackCategoriesMutation,
    UpdateCurationContentPackCategoriesMutationVariables
  >(mutations.UPDATE_CURATION_CONTENT_PACK_CATEGORIES);

  const removeField = (field: string) => {
    if (disabled) {
      return;
    }

    const newCategories = categories.filter((f) => f !== field);
    updatePlaylistCategories({ variables: { input: { id: curationContentPack.id, categories: newCategories } } });
  };

  const addField = (field: string) => {
    if (disabled) {
      return;
    }

    const newCategories = [...categories, field];
    updatePlaylistCategories({ variables: { input: { id: curationContentPack.id, categories: newCategories } } });
  };

  return (
    <>
      <Row className="mx-1">Categories:</Row>
      <Row className="mx-2 mb-2">
        <span>
          {loading ? (
            'Updating...'
          ) : (
            <>
              {(Object.keys(ITEM_DISPLAY_NAME) as Array<keyof typeof ITEM_DISPLAY_NAME>).map((f) => (
                <Tag
                  key={f}
                  value={ITEM_DISPLAY_NAME[f]}
                  visible={categories.includes(f)}
                  onRemove={() => removeField(f)}
                  disabled={disabled}
                />
              ))}
              <DropdownButton
                disabled={disabled}
                as="span"
                id="dropdown-item-button"
                className="m-2"
                size="sm"
                title="Add Category"
              >
                {(Object.keys(ITEM_DISPLAY_NAME) as Array<keyof typeof ITEM_DISPLAY_NAME>)
                  .filter((f) => !categories.includes(f))
                  .map((f) => (
                    <Dropdown.Item key={f} as="button" onClick={() => addField(f)}>
                      {ITEM_DISPLAY_NAME[f]}
                    </Dropdown.Item>
                  ))}
              </DropdownButton>
            </>
          )}
        </span>
      </Row>
    </>
  );
}
