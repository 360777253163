import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
// Bootstrap
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
// Hooks
import useSlicer from '../../controllers/useSlicer';

const SlicerContainer = ({ children, onRefresh }) => {
  const { values, handleChangeValues, handleRefresh } = useSlicer(children, onRefresh);
  return (
    <Card className="m-2">
      <Card.Body>
        <Form>
          {React.Children.map(children, (child) => {
            const { id } = child.props;
            return React.cloneElement(child, {
              value: values[id],
              onChange: (val) => handleChangeValues({ [id]: val }),
            });
          })}
          <Row className="justify-content-center">
            <Button onClick={() => handleRefresh()}>
              <i className="fas fa-redo mr-2" />
              Refresh
            </Button>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default SlicerContainer;
