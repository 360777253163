import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import './assets/SelectDropdown.css';

type Props = {
  title: string;
  options: Record<string, any>;
  value: string | null;
  onSelect: (value: string | null) => void;
};

export default function SelectDropdown({ title, options, value, onSelect: onSelectProps }: Props) {
  const [selectedOption, setSelectedOption] = useState<string | null>(value ?? null);
  const [search, setSearch] = useState('');

  const onSelect = (_value: string | null) => {
    setSelectedOption(_value);
    onSelectProps(_value);
  };

  useEffect(() => {
    setSelectedOption(value);
  }, [value]);

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => setSearch(event.currentTarget.value);

  const optionsToDisplay = Object.keys(options).filter((key) =>
    options[key].searchValue.toLowerCase().match(new RegExp(search.toLowerCase()))
  );

  if (_.isEmpty(options)) {
    return null;
  }

  return (
    <Dropdown onSelect={onSelect} drop="up">
      <Dropdown.Toggle variant="light" style={{ width: '100%', textAlign: 'left' }}>
        {selectedOption ? options[selectedOption].value : value ? options[value].value : title}
      </Dropdown.Toggle>
      <Dropdown.Menu className="w-100">
        <Dropdown.ItemText style={{ width: '100%' }}>
          <Form.Control placeholder="Search" onChange={onSearch} />
        </Dropdown.ItemText>
        <Dropdown.Divider />
        {optionsToDisplay.map((key, index) => (
          <Dropdown.Item key={index} eventKey={key}>
            {options[key].value}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
