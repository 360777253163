import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// PropTypes
import { slicerPropTypes } from '../slicerPropTypes';

type Props = {
  id: string;
  value: string | number | string[] | undefined;
  onChange?: (value: string | null) => void;
  defaultValue: string | number | string[] | undefined;
  title: string;
};

const DateSlicer = ({ id, value, onChange, defaultValue, title }: Props) => (
  <Form.Group as={Row} controlId={id}>
    <Form.Label column>{title}</Form.Label>
    <Col>
      <Form.Control
        type="date"
        value={value || defaultValue}
        onChange={(e) => {
          if (onChange) {
            onChange(e.target.value || null);
          }
        }}
      />
    </Col>
  </Form.Group>
);

DateSlicer.propTypes = slicerPropTypes;

DateSlicer.defaultProps = {
  onChange: () => {},
};

export default DateSlicer;
