import { useMutation, useLazyQuery, useQuery } from '@apollo/client';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Spinner, Table, Card, Button, Form, Accordion as AccordionBootstrap } from 'react-bootstrap';
import moment from 'moment';

import {
  AccordionProps,
  Mission,
  MonthlyMissionsDisplayProps,
  MonthlyMissionsProgressResultProps,
  SelectProps,
} from '../typedefs';
import { GET_MONTH_ID_LIST, RESET_MISSION_PROGRESS, SHOW_MISSION_PROGRESS } from '../../../graphql';

import './MissionDisplay.css';

const MonthlyMissionsDisplay: React.FC<MonthlyMissionsDisplayProps> = ({ userId, missions }) => {
  const [resetMissionProgress, { loading: resetLoading }] = useMutation(RESET_MISSION_PROGRESS);
  const [showMissionProgress, { data, loading: showMissionLoading }] = useLazyQuery(SHOW_MISSION_PROGRESS);

  const [getMonthIdList, { data: monthIds }] = useLazyQuery(GET_MONTH_ID_LIST, {
    variables: { input: { userId } },
  });

  const [monthIdSelected, selectMonthId] = useState('');
  const [expanded, setExpanded] = useState(false);
  const [perviousMonthIdSelected, selectPreviousMonthId] = useState<null | string>(null);
  const [collapse, activeCollapse] = useState(false);

  useEffect(() => {
    if (userId !== undefined) {
      getMonthIdList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useEffect(() => {
    if (monthIds !== undefined && monthIds?.app?.getUserMonthIdList?.list?.length > 0) {
      selectMonthId(monthIds.app.getUserMonthIdList.list[0]);
    }
  }, [monthIds]);

  const reset = useCallback(() => {
    resetMissionProgress({
      variables: {
        input: {
          missionSetUserId: missions.id,
        },
      },
    });
  }, [resetMissionProgress, missions?.id]);
  return (
    <Card className="mb-4">
      <Card.Body>
        <AccordionBootstrap>
          <AccordionBootstrap.Toggle as={Card.Title} variant="link" eventKey="0" onClick={() => setExpanded(!expanded)}>
            <div style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}>
              Monthly pass missions
              <Button variant="link">
                {!expanded && <i className="fas fa-chevron-down" />}
                {expanded && <i className="fas fa-chevron-up" />}
              </Button>
            </div>
          </AccordionBootstrap.Toggle>
          <AccordionBootstrap.Collapse eventKey="0">
            <>
              <Select
                monthIds={monthIds?.app.getUserMonthIdList.list}
                monthIdSelected={monthIdSelected}
                selectMonthId={selectMonthId}
              />
              {missions ? (
                <>
                  <div className="d-flex justify-content-end align-items-baseline mb-3">
                    <Button
                      disabled={monthIds?.app.getUserMonthIdList.list.length === 0}
                      size="sm"
                      onClick={() => {
                        showMissionProgress({
                          variables: { input: { monthId: monthIdSelected, userId } },
                        });
                        if (monthIdSelected === perviousMonthIdSelected) {
                          activeCollapse(!collapse);
                        } else {
                          activeCollapse(true);
                        }
                        selectPreviousMonthId(monthIdSelected);
                      }}
                    >
                      Show progress
                    </Button>
                  </div>

                  {/* reset button actually disabled */}
                  {/* <Button size="sm" variant="danger" onClick={reset} disabled={resetLoading || !missions}>
              Reset progress
            </Button> */}

                  <Accordion active={collapse}>
                    <div className="mt-2" style={{ maxHeight: '50vh', overflowY: 'auto' }}>
                      <MissionProgressResults
                        missions={data?.app?.getUserMonthlyPass?.userMonthlyPass?.allMissions}
                        loading={showMissionLoading}
                      />
                    </div>
                  </Accordion>
                </>
              ) : (
                'No missions have been configured for this monthly pass'
              )}
            </>
          </AccordionBootstrap.Collapse>
        </AccordionBootstrap>
      </Card.Body>
    </Card>
  );
};

export default MonthlyMissionsDisplay;

const Select: React.FC<SelectProps> = ({ monthIds, monthIdSelected, selectMonthId }) => {
  if (monthIds === undefined || monthIds?.length === 0) {
    return <div>There is no monthly mission progress available for this user</div>;
  }
  return (
    <Form.Control
      value={monthIdSelected}
      onChange={(e) => {
        selectMonthId(e.target.value);
      }}
      as="select"
      className="mt-3 mb-2"
    >
      {monthIds.map((monthId) => (
        <option key={monthId} value={monthId}>
          {monthId}
        </option>
      ))}
    </Form.Control>
  );
};

const MissionProgressResults: React.FC<MonthlyMissionsProgressResultProps> = ({ missions, loading }) => {
  const displayEmptyArray = () => {
    return <div>Empty Array</div>;
  };

  const displayMissions = (data: Mission[]) => {
    return (
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Task name</th>
            <th>Start</th>
            <th>End</th>
            <th>Reward xp</th>
            <th>Progress</th>
            <th>Claimed date</th>
            <th>Claimed multiplier</th>
          </tr>
        </thead>
        <tbody>
          {data.map((mission: Mission) => (
            <tr key={mission.id}>
              <td>{mission?.mission?.tasks[0]?.type}</td>
              <td>{moment(mission?.mission?.start).format('YYYY-MM-DD')}</td>
              <td>{moment(mission?.mission?.end).format('YYYY-MM-DD')}</td>
              <td>{mission?.mission?.rewardXp}</td>
              <td>
                {mission?.progress}/{mission.progressGoal}
              </td>
              <td>
                {mission?.claimedDate ? moment(mission?.claimedDate).format('YYYY-MM-DD hh:mm:ss a') : 'not claimed'}
              </td>
              <td>{mission?.claimedMultiplier ? (mission.claimedMultiplier > 1 ? 'X' : '-') : 'not claimed'}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  const dailyMissions = () => {
    return (
      <>
        <h3>Daily missions</h3>
        {missions?.dailyMissions.length > 0 ? displayMissions(missions.dailyMissions) : displayEmptyArray()}
      </>
    );
  };

  const monthlyMissions = () => {
    return (
      <>
        <h3>Monthly missions</h3>
        {missions?.monthlyMissions.length > 0 ? displayMissions(missions.monthlyMissions) : displayEmptyArray()}
      </>
    );
  };

  const extraMissions = () => {
    return (
      <>
        <h3>Extra missions</h3>
        {missions.extraMissions.length > 0 ? displayMissions(missions.extraMissions) : displayEmptyArray()}
      </>
    );
  };

  if (loading) {
    return <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />;
  }

  if (!missions) {
    return <div>No result</div>;
  }

  return (
    <div>
      {dailyMissions()}
      <br />
      {monthlyMissions()}
      <br />
      {extraMissions()}
    </div>
  );
};

const Accordion: React.FC<AccordionProps> = ({ children, active }) => {
  return <div className={`mission_pannel_collapse ${active ? 'active' : ''}`}>{children}</div>;
};
