import moment from 'moment';
import React from 'react';
import DateSlicer from './components/DateSlicer';
import RangeSlicer from './components/RangeSlicer';
import TextSlicer from './components/TextSlicer';
import DefaultContinentSlicer from './default/DefaultContinentSlicer';
import DefaultCountrySlicer from './default/DefaultCountrySlicer';
import DefaultIsSpenderSlicer from './default/DefaultIsSpenderSlicer';
import DefaultPlatformSlicer from './default/DefaultPlatformSlicer';
import DefaultSourceSlicer from './default/DefaultSourceSlicer';
import DefaultAccountLinkedSlicer from './default/DefaultAccountLinkedSlicer';
import DefaultVipStatusSlicer from './default/DefaultVipStatusSlicer';
// Slicers
import SlicerContainer from './SlicerContainer';
// PropTypes
import { defaultSlicingPropTypes } from './slicerPropTypes';

const DefaultSlicing = ({ defaultStartDate, defaultEndDate, onRefresh }) => (
  <SlicerContainer onRefresh={onRefresh}>
    <DateSlicer name="DateSlicer" id="startDate" defaultValue={defaultStartDate} />
    <DateSlicer name="DateSlicer" id="endDate" defaultValue={defaultEndDate} />
    <DefaultPlatformSlicer name="DefaultPlatformSlicer" />
    <DefaultCountrySlicer name="DefaultCountrySlicer" />
    <DefaultContinentSlicer name="DefaultContinentSlicer" />
    <DefaultSourceSlicer name="DefaultSourceSlicer" />
    <DefaultAccountLinkedSlicer name="DefaultAccountLinkedSlicer" />
    <TextSlicer id="app_version" title="App Version" name="TextSlicer" />
    <DefaultIsSpenderSlicer name="DefaultIsSpenderSlicer" />
    <DefaultVipStatusSlicer name="DefaultVipStatusSlicer" />
    <RangeSlicer id="user_level" title="User Level" name="RangeSlicer" />
    <RangeSlicer id="days_since_install" title="Days Since Install" name="RangeSlicer" />
  </SlicerContainer>
);

DefaultSlicing.propTypes = defaultSlicingPropTypes;

DefaultSlicing.defaultProps = {
  defaultStartDate: moment().subtract(15, 'days').format('YYYY-MM-DD'),
  defaultEndDate: moment().subtract(2, 'day').format('YYYY-MM-DD'),
};

export default DefaultSlicing;
