import { Button } from 'react-bootstrap';
import {
  CurationContentPackCustomField,
  CurationContentPackQuery,
  UpdateCurationSongInputType,
  UpdateCurationSongSampleInputType,
} from '../../../../../../__gqltypes__';
import CustomField from '../../../components/CustomField';
import EditComponent from '../../../components/EditComponent';
import PlayButton from '../../../components/PlayButton';
import UploadSong, { CurationSong, SubmitPayload } from '../../../components/UploadSong';

type CurationContentPack = CurationContentPackQuery['curationContentPack'];

const FALLBACKS = ['US', 'GB', 'ES', 'FR', 'IT'];

const fetchItunesInfo = async (song: CurationSong, countryCode = 'US'): Promise<string | null> => {
  const response = await fetch(
    `https://itunes.apple.com/lookup?id=${song.itunesId}&entity=song&country=${countryCode}`
  );
  const result = await response.json();
  if (result.results && result.results.length > 0) {
    return result.results[0].previewUrl;
  }
  const countryIndex = FALLBACKS.indexOf(countryCode);
  if (countryIndex < FALLBACKS.length - 1) {
    // fallback on different country code (if any)
    console.log('fallback on', FALLBACKS[countryIndex + 1]);
    return fetchItunesInfo(song, FALLBACKS[countryIndex + 1]);
  }
  console.warn(
    'error',
    `https://itunes.apple.com/lookup?id=${song.itunesId}&entity=song&country=${countryCode}`,
    'failed'
  );
  return null;
};

type Props = {
  contentPack: CurationContentPack;
  song: CurationSong;
  onUpdateCurationSong: (value: string, type: UpdateCurationSongInputType) => Promise<unknown>;
  onUpdateCurationSongSample: (payload: SubmitPayload, type: UpdateCurationSongSampleInputType) => Promise<unknown>;
  onUpdatePlaylistCustomDisplay: (value: string) => Promise<unknown>;
};

export default function EditCustomFields({
  contentPack,
  song,
  onUpdateCurationSong,
  onUpdateCurationSongSample,
  onUpdatePlaylistCustomDisplay,
}: Props) {
  const updateCurationSongHandlerFactory = (type: UpdateCurationSongInputType) => async (value: string) => {
    onUpdateCurationSong(value, type);
  };
  const updateCurationSongSampleHandlerFactory =
    (type: UpdateCurationSongSampleInputType) => async (payload: SubmitPayload) =>
      onUpdateCurationSongSample(payload, type);
  const updatePlaylistCustomDisplayHandler = async (value: string) => {
    onUpdatePlaylistCustomDisplay(value);
  };

  return (
    <>
      <div>
        Default Sample:
        <PlayButton sample={song.defaultSample} />
        <UploadSong
          className="ml-4"
          onSubmit={async (payload) => {
            onUpdateCurationSongSample(payload, UpdateCurationSongSampleInputType.DEFAULT);
          }}
          title="Update default sample"
          song={song}
        />
        <Button
          className="ml-4"
          size="sm"
          as="label"
          variant="info"
          onClick={() => {
            fetchItunesInfo(song).then((previewUrl: string | null) => {
              if (previewUrl) {
                onUpdateCurationSongSample({ url: previewUrl }, UpdateCurationSongSampleInputType.DEFAULT);
              }
            });
          }}
        >
          <i className="mr-2 fab fa-itunes-note" />
          Refresh from iTunes
        </Button>
        <hr />
      </div>
      <div>
        <div>
          Display title:&nbsp;
          <EditComponent
            className="font-weight-bold"
            onSubmit={updateCurationSongHandlerFactory(UpdateCurationSongInputType.DISPLAY_TITLE)}
            value={song.displayTitle}
          />
        </div>
        <div>
          Display artist:&nbsp;
          <EditComponent
            className="font-weight-bold"
            value={song.displayArtist}
            onSubmit={updateCurationSongHandlerFactory(UpdateCurationSongInputType.DISPLAY_ARTIST)}
          />
        </div>
        <CustomField
          field={song.introSample}
          song={song}
          onSubmit={updateCurationSongSampleHandlerFactory(UpdateCurationSongSampleInputType.INTRO)}
          fieldType={CurationContentPackCustomField.INTRO_SAMPLE}
          forcedFields={contentPack.selectedCustomFields}
        />
        <CustomField
          field={song.playlistCustomSample}
          song={song}
          onSubmit={updateCurationSongSampleHandlerFactory(UpdateCurationSongSampleInputType.CUSTOM)}
          fieldType={CurationContentPackCustomField.CUSTOM_SAMPLE}
          forcedFields={contentPack.selectedCustomFields}
        />

        <CustomField
          field={song.movieTitle}
          onSubmit={updateCurationSongHandlerFactory(UpdateCurationSongInputType.MOVIE)}
          className="font-weight-bold"
          fieldType={CurationContentPackCustomField.MOVIE_TITLE}
          forcedFields={contentPack.selectedCustomFields}
        />
        <CustomField
          field={song.leadSinger}
          onSubmit={updateCurationSongHandlerFactory(UpdateCurationSongInputType.LEAD_SINGER)}
          className="font-weight-bold"
          fieldType={CurationContentPackCustomField.LEAD_SINGER}
          forcedFields={contentPack.selectedCustomFields}
        />
        <CustomField
          field={song.jesterTitle}
          onSubmit={updateCurationSongHandlerFactory(UpdateCurationSongInputType.JESTER)}
          className="font-weight-bold"
          fieldType={CurationContentPackCustomField.JESTER_TITLE}
          forcedFields={contentPack.selectedCustomFields}
        />
        <CustomField
          field={song.composer}
          onSubmit={updateCurationSongHandlerFactory(UpdateCurationSongInputType.COMPOSER)}
          className="font-weight-bold"
          fieldType={CurationContentPackCustomField.COMPOSER}
          forcedFields={contentPack.selectedCustomFields}
        />
        <CustomField
          field={song.playlistCustomDisplay}
          onSubmit={updatePlaylistCustomDisplayHandler}
          className="font-weight-bold"
          fieldType={CurationContentPackCustomField.CUSTOM}
          forcedFields={contentPack.selectedCustomFields}
        />
        <CustomField
          field={song.playlistCustomDisplay}
          onSubmit={updatePlaylistCustomDisplayHandler}
          className="font-weight-bold"
          fieldType={CurationContentPackCustomField.FULL_CUSTOM}
          forcedFields={contentPack.selectedCustomFields}
        />
      </div>
    </>
  );
}
