import { useQuery } from '@apollo/client';
import { useCallback, useEffect, useMemo } from 'react';
import { Col, Nav, Row, Spinner, Tab } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import { GetContentPackListsQuery } from '../../../../../__gqltypes__';
import queries from '../../utils/queries';
import ContentPackList from './ContentPackList';
import CreateContentPackList from './CreateContentPackList';

const baseUrl = '/sp3/playlist/contentpacklists';

const ContentPackListList = () => {
  const { data, loading, refetch } = useQuery<GetContentPackListsQuery>(queries.GET_CONTENT_PACK_LISTS, {
    fetchPolicy: 'no-cache',
  });
  const lists = useMemo(() => {
    if (!data) {
      return null;
    }
    return data.contentPackLists.list.map((list) => ({
      id: list.contentPackListId,
      name: list.name,
    }));
  }, [data]);

  const { id: curListId } = useParams<{ id: string }>();
  const history = useHistory();

  const setActiveList = useCallback(
    (listId) => {
      history.push(`${baseUrl}/${listId}`);
    },
    [history]
  );

  useEffect(() => {
    if (curListId && lists && !lists.find((list) => list.id === curListId)) {
      setActiveList(lists.length > 0 ? lists[0].id : '');
    }
  }, [lists, curListId, setActiveList]);

  return loading ? (
    <div className="p-4">
      <Spinner animation="border" />
    </div>
  ) : (
    // @ts-ignore
    <Tab.Container className="h-100" activeKey={curListId} onSelect={setActiveList}>
      <Row className="mb-5 h-100 cp-list">
        <Col>
          <Nav.Item className="mb-3">
            <CreateContentPackList refetchLists={refetch} setActiveList={setActiveList} />
          </Nav.Item>
          <Nav variant="pills">
            {(lists ?? []).map((list) => (
              <Nav.Item key={list.id} className="w-100 text-center position-relative">
                <Nav.Link eventKey={list.id} className="justify-content-center">
                  {list.name}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Col>
        <Col className="col-9">
          <Tab.Content className="h-100">
            {curListId && <ContentPackList listId={curListId} refetchLists={refetch} />}
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
};

export default ContentPackListList;
