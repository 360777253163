import { useReducer } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Modal } from 'react-bootstrap';
import Loader from '../../components/Loader';
import PlaylistTable from './components/PlaylistTable';
import Sp2SearchForm from './components/Sp2SearchForm';
import sp2Reducer, { filterSelected } from './sp2Reducer';
import mutations from '../../utils/mutations';
import queries from '../../utils/queries';
import {
  CreateCurationContentPackFromSp2MultiMutation,
  CreateCurationContentPackFromSp2MultiMutationVariables,
  Sp2PlaylistsQuery,
  Sp2PlaylistsQueryVariables,
} from '../../../../../__gqltypes__';

type Sp2PlaylistsItem = NonNullable<Sp2PlaylistsQuery['sp2Playlists']>['playlists'][0];

const Sp2Playlists = () => {
  const DEFAULT_PAGE_SIZE = 50;
  const { loading, data, refetch } = useQuery<Sp2PlaylistsQuery, Sp2PlaylistsQueryVariables>(queries.SP2_PLAYLISTS, {
    variables: { input: { pageSize: DEFAULT_PAGE_SIZE } },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  const [queueImports] = useMutation<
    CreateCurationContentPackFromSp2MultiMutation,
    CreateCurationContentPackFromSp2MultiMutationVariables
  >(mutations.SP2_IMPORT_BATCH);

  const [{ startDate, endDate, selectedPlaylists, allSelected, pageSize, importState, missingOnly }, dispatch] =
    useReducer(sp2Reducer, {
      startDate: '',
      endDate: '',
      selectedPlaylists: {},
      allSelected: false,
      pageSize: DEFAULT_PAGE_SIZE,
      importState: 'NONE',
      missingOnly: false,
    });

  if (!(data && data.sp2Playlists)) {
    return <Loader />;
  }

  const result = data.sp2Playlists;
  const queryChanged =
    (startDate && startDate !== result.startDate) ||
    (endDate && endDate !== result.endDate) ||
    pageSize !== result.pageSize;
  const numSelected = Object.values(selectedPlaylists).reduce((total, val) => total + (val ? 1 : 0), 0);

  const clearSelection = () => dispatch({ type: 'SET_SELECTED_ALL', allSelected: false, selectedPlaylists: {} });

  const onNextPage = () => {
    clearSelection();
    refetch({
      input: {
        startDate: result.startDate,
        endDate: result.endDate,
        cursor: result.nextCursor || '',
        pageSize: result.pageSize,
      },
    });
  };

  const onRefreshPage = () => {
    clearSelection();
    refetch({
      input: {
        startDate: result.startDate,
        endDate: result.endDate,
        cursor: result.currentCursor || '',
        pageSize: result.pageSize,
      },
    });
  };

  const onSubmit = () => {
    clearSelection();
    refetch({ input: { startDate, endDate, pageSize } });
  };

  const onToggleMissingOnly = () => {
    dispatch({ type: 'SET_MISSING_ONLY', missingOnly: !missingOnly });
  };

  const onSetStart = (start: string) => {
    dispatch({ type: 'SET_START', startDate: start });
  };

  const onSetEnd = (end: string) => {
    dispatch({ type: 'SET_END', endDate: end });
  };

  const onSetPageSize = (size: number) => {
    dispatch({ type: 'SET_PAGE_SIZE', pageSize: size });
  };

  const onToggleSelected = (pls: Sp2PlaylistsItem) => {
    if (pls && pls.productionPlaylistId) {
      const newSelectedState = !selectedPlaylists[pls.productionPlaylistId] && !pls.contentPackId;
      dispatch({ type: 'SET_SELECTED', selectedPlaylists: { [pls.productionPlaylistId]: newSelectedState } });
    }
  };

  const onToggleSelectedAll = () => {
    const newAllSelected = !allSelected;
    const newSelectedPlaylists =
      result.playlists && newAllSelected
        ? Object.fromEntries(
            result.playlists.filter((x) => x && !x.contentPackId).map((x) => [x?.productionPlaylistId, true])
          )
        : {};
    dispatch({ type: 'SET_SELECTED_ALL', allSelected: newAllSelected, selectedPlaylists: newSelectedPlaylists });
  };

  const onImport = () => {
    dispatch({ type: 'SET_IMPORT_STATE', importState: 'CONFIRM' });
  };

  const onConfirmImport = () => {
    clearSelection();
    queueImports({ variables: { input: { sp2PlaylistIds: Object.keys(filterSelected(selectedPlaylists)) } } });
    dispatch({ type: 'SET_IMPORT_STATE', importState: 'IMPORTING' });
  };

  const closePopup = () => {
    dispatch({ type: 'SET_IMPORT_STATE', importState: 'NONE' });
  };

  const closePopupAndRefresh = () => {
    closePopup();
    onRefreshPage();
  };

  return (
    <div>
      <Row>
        <h1>SP2 Playlists</h1>
      </Row>
      <Row>
        <Sp2SearchForm
          startDate={startDate || result.startDate}
          endDate={endDate || result.endDate}
          pageSize={pageSize || result.pageSize || DEFAULT_PAGE_SIZE}
          loading={loading}
          numSelected={numSelected}
          missingOnly={missingOnly}
          onToggleMissingOnly={onToggleMissingOnly}
          onSetStart={onSetStart}
          onSetEnd={onSetEnd}
          onSetPageSize={onSetPageSize}
          onSubmit={onSubmit}
          onImport={onImport}
        />
        <Col>
          {result.nextCursor && !loading && (
            <Button onClick={onNextPage} disabled={queryChanged}>
              Next page
            </Button>
          )}
          {!loading && (
            <Button onClick={onRefreshPage} variant="success" className="float-right">
              Refresh page
            </Button>
          )}
          {loading ? (
            <Loader />
          ) : (
            <PlaylistTable
              playlists={result.playlists}
              selectedPlaylists={selectedPlaylists}
              allSelected={allSelected}
              missingOnly={missingOnly}
              onToggleSelected={onToggleSelected}
              onToggleSelectedAll={onToggleSelectedAll}
            />
          )}
        </Col>
      </Row>
      {importState === 'CONFIRM' && (
        <Modal show={importState === 'CONFIRM'} onHide={closePopup}>
          <Modal.Header closeButton>
            <Modal.Title>Import</Modal.Title>
          </Modal.Header>
          <Modal.Body>Import {numSelected} playlist(s) from SP2?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closePopup}>
              Cancel
            </Button>
            <Button variant="primary" onClick={onConfirmImport}>
              Import
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {importState === 'IMPORTING' && (
        <Modal show={importState === 'IMPORTING'}>
          <Modal.Header>
            <Modal.Title>Importing</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Playlists will be imported in the background. You can refresh this view to check progress, or check back
            later
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={closePopupAndRefresh}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default Sp2Playlists;
