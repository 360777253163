/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';

import Alert from 'react-bootstrap/Alert';

const ErrorDisplay = ({ errors, requestNumber, hideOnSuccess }) => {
  const errorNumber = Object.values(errors).length;
  const successRatio = `${requestNumber - errorNumber} / ${requestNumber}`;
  return !hideOnSuccess || errorNumber > 0 ? (
    <Alert variant={errorNumber > 0 ? 'danger' : 'success'}>
      <p>{successRatio} sql requests succeded.</p>
      {errorNumber > 0 ? (
        Object.keys(errors).map((id) => (
          <div key={id}>
            <h4>Request: {id}</h4>
            <p>Error: {errors[id].message}</p>
          </div>
        ))
      ) : (
        <p>No errors.</p>
      )}
    </Alert>
  ) : null;
};

export default ErrorDisplay;
