import { useLazyQuery, useQuery } from '@apollo/client';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Badge, Card, Col, Nav, OverlayTrigger, Row, Spinner, Tab, Tooltip } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import {
  DdexJobQuery,
  DdexJobsQuery,
  JobState,
} from '../../../../../__gqltypes__';
import { LoadingLogo } from '../../../devtools/components/Modal';
import './components/StatusIndicator.css';
import DDEXJobData from './DDEXJobData';
import { GET_DDEX_JOB, GET_DDEX_JOBS } from './graphql';

type DDexJobListItem = DdexJobsQuery['ddexJobs']['list'][0];
const baseURL = '/sp3/playlist/ddex-jobs';

const getShortLabelName = (label: string) => {
  switch (label) {
    case 'Sony Music Entertainment':
      return 'SME';
    case 'Universal Music Group':
      return 'UMG';
    case 'The Orchard Enterprises':
      return 'Orchard';
    case 'Warner Music Group':
      return 'WMG';
    default:
      return label;
  }
};

const DDEXJobs = () => {
  const {
    data: ddexJobsData,
    fetchMore,
    loading: loadingDDEXJobsData,
  } = useQuery<DdexJobsQuery>(GET_DDEX_JOBS, {
    fetchPolicy: 'no-cache',
  });

  const [fetchDDEXJob] = useLazyQuery<DdexJobQuery>(GET_DDEX_JOB);

  const [ddexJobs, setDDEXJobs] = useState<DDexJobListItem[]>([]);

  const [pageCount, setPageCount] = useState(1);
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [lastElementRef, setLastElementRef] = useState<HTMLDivElement | null>(null);

  const { id: ddexJobId } = useParams<{ id: string }>();
  const history = useHistory();
  const selectDDEXJob = (newJobId: string) => {
    history.push(`${baseURL}/${newJobId}`);
  };

  const isJobSelected = () => !_.isEmpty(ddexJobId) && ddexJobs.find(({ jobId }) => jobId === ddexJobId);

  const tryToFetchDDEXJobs = async () => {
    if (ddexJobId) {
      const res = await fetchDDEXJob({ variables: { jobId: ddexJobId } });

      const ddexJob = res.data?.ddexJob;

      if (!_.isNil(ddexJob)) {
        const { jobId, created, label, nbExecutions, path, state } = ddexJob;

        const _updatedDDEXJobs = [{ jobId, created, label, nbExecutions, path, state }, ...ddexJobs];
        // @ts-ignore
        setDDEXJobs(_updatedDDEXJobs);
        // @ts-ignore
        selectDDEXJob(jobId);
      } else {
        // @ts-ignore
        selectDDEXJob(ddexJobs[0].jobId);
      }
    } else {
      selectDDEXJob(ddexJobs[0].jobId);
    }
  };

  useEffect(() => {
    if (ddexJobId === ':id') {
      selectDDEXJob('');
    }
    if (!isJobSelected() && ddexJobs.length > 0) {
      // If ddexJobId from url is not yet loaded in the ddexJob list (left menu)
      // We try to add this one to the list
      tryToFetchDDEXJobs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ddexJobId]);

  useEffect(() => {
    if (ddexJobsData && ddexJobsData.ddexJobs) {
      const _ddexJobsData = _.orderBy(ddexJobsData.ddexJobs.list, 'created', 'desc');
      setDDEXJobs(_ddexJobsData);
    }
  }, [ddexJobsData]);

  useEffect(() => {
    if (ddexJobsData && ddexJobsData.ddexJobs && !isJobSelected() && ddexJobs.length > 0) {
      tryToFetchDDEXJobs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ddexJobsData, ddexJobs]);

  const getStateStatus = (jobState: JobState) => {
    let variant;
    switch (jobState) {
      case JobState.DONE:
        variant = 'success';
        break;
      case JobState.COPY_XML:
      case JobState.IN_PROGRESS:
      case JobState.PARSING_XML:
      case JobState.TASKS_CREATED:
      case JobState.XML_PARSED:
        variant = 'info';
        break;
      case JobState.FAILED:
        variant = 'warning';
        break;
      case JobState.PAUSED:
        variant = 'danger';
        break;
      default:
        variant = 'secondary';
    }
    return (
      <>
        <OverlayTrigger overlay={<Tooltip id="tooltip-scheduling-status">Job state</Tooltip>}>
          <Badge pill variant={variant} className={`text-${variant} scheduling-status-indicator`}>
            .
          </Badge>
        </OverlayTrigger>
      </>
    );
  };

  /**
   * Pagination
   */
  const ddexJobsLimit = 20;
  const maxPage = ddexJobsData?.ddexJobs.list
    ? ddexJobsData.ddexJobs.totalCount % ddexJobsLimit === 0
      ? Math.floor(ddexJobsData.ddexJobs.totalCount / ddexJobsLimit)
      : Math.floor(ddexJobsData.ddexJobs.totalCount / ddexJobsLimit) + 1
    : 1;

  const observer = useRef(
    new IntersectionObserver((entries) => {
      const first = entries[0];
      if (first.isIntersecting) {
        setPageCount((no) => no + 1);
      }
    })
  );

  const loadNextPage = async () => {
    setPaginationLoading(true);
    console.log('loadNext page');
    const res = await fetchMore({
      variables: {
        pagination: { offset: ddexJobsLimit * (pageCount - 1), limit: ddexJobsLimit },
      },
    });
    console.log('res fetchMore', res);
    const data = res.data.ddexJobs.list;
    const _updatedDDEXJobs = _.uniqBy([...ddexJobs, ...data], 'jobId');

    setDDEXJobs(_updatedDDEXJobs);
    setPaginationLoading(false);
  };

  useEffect(() => {
    if (pageCount - 1 <= maxPage && pageCount > 1) {
      loadNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCount]);

  useEffect(() => {
    const currentElement = lastElementRef;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElementRef]);

  /**
   * RENDERING
   */

  return (
    <>
      <LoadingLogo show={loadingDDEXJobsData} />
      {isJobSelected() && (
        // @ts-ignore
        <Tab.Container style={{ height: '100%' }} activeKey={ddexJobId} onSelect={(e) => selectDDEXJob(e)}>
          <Row className="mb-5" style={{ height: '100%' }}>
            <Col
              style={{
                maxHeight: 'calc(100vh - 5.875rem)',
                overflow: 'scroll',
              }}
            >
              <Nav variant="pills">
                {ddexJobs.map((ddexJob, index) => (
                  <Nav.Item
                    ref={index === ddexJobs.length - 1 && !paginationLoading ? setLastElementRef : null}
                    key={`${ddexJob.jobId}-${index}`}
                    style={{ width: '100%' }}
                    className="text-center position-relative"
                  >
                    <Nav.Link
                      eventKey={ddexJob.jobId}
                      className={`justify-content-center ${
                        moment(ddexJob.created).isBefore(moment()) ? 'liveops-nav-link-old' : 'liveops-nav-link-current'
                      }`}
                    >
                      <b> {getShortLabelName(ddexJob.label)}</b>
                      <br />
                      {moment(ddexJob.created).format('DD/MM/YYYY')}
                      <br />
                      {moment(ddexJob.created).format('LT')}
                    </Nav.Link>
                    {getStateStatus(ddexJob.state)}
                  </Nav.Item>
                ))}
                {paginationLoading && <Spinner animation="border" />}
              </Nav>
            </Col>

            <Col
              style={{
                maxHeight: 'calc(100vh - 5.875rem)',
                overflow: 'scroll',
              }}
              className="col-10"
            >
              <Tab.Content style={{ height: '100%' }}>
                <Card>
                  <Card.Body>
                    <DDEXJobData jobId={ddexJobId} />
                  </Card.Body>
                </Card>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      )}
    </>
  );
};

export default DDEXJobs;
