import React from 'react';

import BaseSelectorSlicer from '../components/BaseSelectorSlicer';
import defaultPartyPlayers from '../../../models/slicers/defaultPartyPlayers.json';
import { slicerPropTypes } from '../slicerPropTypes';

const options = Object.keys(defaultPartyPlayers.queries).map((key) => ({
  text: key,
  value: key,
}));

const DefaultPartyPlayersSlicer = ({ id, value, onChange }) => (
  <BaseSelectorSlicer id={id} value={value} onChange={onChange} options={options} />
);

DefaultPartyPlayersSlicer.propTypes = slicerPropTypes;

DefaultPartyPlayersSlicer.defaultProps = {
  id: defaultPartyPlayers.defaultId,
  onChange: () => {},
};

export default DefaultPartyPlayersSlicer;
