import PropTypes from 'prop-types';
// import DataTable from '../../models/DataTable';
import Processing from '../../models/Processing';

export const widgetPropTypes = {
  id: PropTypes.string.isRequired,
  height: PropTypes.string,
  width: PropTypes.string,
  // data: PropTypes.instanceOf(DataTable).isRequired,
  options: PropTypes.object,
  processing: PropTypes.instanceOf(Processing),
  title: PropTypes.string,
};

export const widgetDefaultProps = {
  processing: undefined,
  height: undefined,
  width: undefined,
  options: undefined,
  title: undefined,
};

export const baseChartPropTypes = {
  id: PropTypes.string.isRequired,
  height: PropTypes.string,
  width: PropTypes.string,
  type: PropTypes.string.isRequired,
  labels: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  datasets: PropTypes.arrayOf(PropTypes.object).isRequired,
  options: PropTypes.object,
  title: PropTypes.string,
  labelFormat: PropTypes.string,
};

export const baseChartDefaultProps = {
  height: '500',
  width: '500',
  options: {},
  title: undefined,
};
