import { useMutation } from '@apollo/client';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Button, Card, Row } from 'react-bootstrap';

import {
  InitSpecialEventMissionsMutation,
  InitSpecialEventMissionsMutationVariables,
  UpdateSpecialEventMissionsMutation,
  UpdateSpecialEventMissionsMutationVariables,
  SpecialEventQuery,
  SpecialEventFragmentFragment,
} from '../../../../../../__gqltypes__';
import { LoadingLogo } from '../../../../devtools/components/Modal';
import { MonthlyPassMissionTable } from '../../MonthlyPass/MonthlyPassMissionTable';
import { getTypeAndValueFromTaskParameter } from '../../MonthlyPass/tasks';
import { FilledMissionInput } from '../../MonthlyPass/typedef';
import { INIT_SPECIAL_EVENT_MISSIONS, UPDATE_SPECIAL_EVENT_MISSIONS } from '../graphql';

type GroupedUpdateSpecialEventMissionsInput = {
  id: string;
  sequentialMissions: FilledMissionInput[][];
};
type SpecialEventFragmentMissions = NonNullable<SpecialEventFragmentFragment["missions"]>;
type Props = {
  specialEventId: string;
  missions: SpecialEventFragmentMissions;
  startDate: Date;
  endDate: Date;
  refetch: () => void;
};

const SpecialEventMissions = ({ specialEventId, missions, refetch, startDate, endDate }: Props) => {
  /**
   * @type {[Missions[], Function]}
   */

  // Mutations
  const [initMissions, { loading: initMissionsLoading }] = useMutation<
    InitSpecialEventMissionsMutation,
    InitSpecialEventMissionsMutationVariables
  >(INIT_SPECIAL_EVENT_MISSIONS, {
    onCompleted: () => {
      refetch();
    },
  });

  const [updateMissions, { loading: updateMissionsLoading }] = useMutation<
    UpdateSpecialEventMissionsMutation,
    UpdateSpecialEventMissionsMutationVariables
  >(UPDATE_SPECIAL_EVENT_MISSIONS);

  // useState
  const [specialEventMissions, setSpecialEventMissions] = useState<GroupedUpdateSpecialEventMissionsInput | null>(null);

  const handleMissionsSave = () => {
    if (_.isNil(specialEventMissions)) {
      return;
    }
    const stringifyMissionTasksParams = (mission: FilledMissionInput) => {
      return {
        ...mission,
        tasks: mission.tasks.map((task) => ({ ...task, params: task.params.map((param) => param.toString()) })),
      };
    };
    // flattening of sequentialMissions is needed before returning the updated missions
    const flattenedMissions = {
      missionSetId: specialEventMissions.id,
      sequentialMissions: specialEventMissions.sequentialMissions.flat().map(stringifyMissionTasksParams),
    };
    updateMissions({ variables: { input: flattenedMissions } });
  };

  useEffect(() => {
    const setAndSanitizeMissions = (newMissions: SpecialEventFragmentMissions) => {
      if (!_.isNil(newMissions)) {
        const sanitizedMissions = {
          id: newMissions.id,
          sequentialMissions: newMissions.sequentialMissions.map((mission) => [missionToFilledMissionInput(mission)]),
        };
        setSpecialEventMissions(sanitizedMissions);
      } else {
        setSpecialEventMissions(null);
      }
    };

    setAndSanitizeMissions(missions);
  }, [missions]);

  function missionToFilledMissionInput(
    mission: NonNullable<NonNullable<SpecialEventQuery['specialEvent']>['missions']>['sequentialMissions'][0]
  ): FilledMissionInput {
    return {
      rewardXp: mission.rewardXp,
      requiredTier: mission.requiredTier,
      tasks: mission.tasks.map((task) => ({
        type: task.type,
        params: task.params.map((param) => getTypeAndValueFromTaskParameter(param).value),
      })),
    };
  }

  const handleSequentialMissionsUpdate = (_missions: FilledMissionInput[][]) => {
    if (!_.isNil(specialEventMissions)) {
      setSpecialEventMissions({
        id: specialEventMissions.id,
        sequentialMissions: _missions,
      });
    }
  };

  return (
    <>
      {updateMissionsLoading ? (
        <LoadingLogo show />
      ) : (
        <Card className="mt-3 mb-3">
          <Card.Body className="text-center">
            <Card.Title>Missions</Card.Title>
            {specialEventMissions ? (
              <MonthlyPassMissionTable
                loading={updateMissionsLoading}
                hasGroups={false}
                isTeamMissions={false}
                missions={specialEventMissions.sequentialMissions}
                onChange={handleSequentialMissionsUpdate}
              />
            ) : (
              <Button
                size="sm"
                className="mb-2"
                disabled={initMissionsLoading}
                onClick={() => initMissions({ variables: { input: { specialEventId, forceReset: false } } })}
              >
                Generate missions
              </Button>
            )}
          </Card.Body>
          <Card.Footer>
            <Row className="justify-content-between">
              <Card.Title>Special Event missions</Card.Title>
              <Button onClick={handleMissionsSave}>
                <i className="fas fa-save mr-2" />
                Save Missions
              </Button>
            </Row>
          </Card.Footer>
        </Card>
      )}
    </>
  );
};

export default SpecialEventMissions;
