import { useMutation } from '@apollo/client';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Row from 'react-bootstrap/Row';
import { GetFramesQuery } from '../../../../../../__gqltypes__';
import { LoadingLogo } from '../../../../devtools/components/Modal';
import { UPLOAD_ASSET } from '../../../components/apolloQueries';
import AssetDisplay from './AssetDisplay';
import DragUpload from './DragUpload';
import { Item, OnValidateItem, SetItemData } from './typedef';

type Props = {
  upgrade: GetFramesQuery["liveOps"]["profileFrames"]["list"][0]["nextLevelItems"][0]
  itemData: Item;
  setItemData: SetItemData;
  isNewUpgrade: boolean;
  refetch: () => void;
  onValidate?: OnValidateItem;
};

export default function UpgradeForm({ upgrade, itemData, setItemData, isNewUpgrade, refetch, onValidate }: Props) {
  const [uploadAsset, { loading: uploadLoading }] = useMutation(UPLOAD_ASSET, {
    onCompleted: () => refetch(),
  });

  const handleUpload = (file: Blob) => {
    uploadAsset({
      variables: {
        input: {
          file,
          id: itemData.id,
          level: upgrade.level,
        },
      },
    });
  };

  // @ts-ignore
  const handleAmountChange = (e) => {
    const nextLevelItems = itemData.nextLevelItems.map((u) =>
      u.id === upgrade.id ? { ...upgrade, cost: { amount: e.target.value } } : u
    );
    // @ts-ignore
    setItemData({ ...itemData, nextLevelItems });
  };
  const isVinyl = itemData.__typename === 'AppSkin';

  return (
    <Form>
      <hr />
      <h5>
        Item level
        {upgrade.level}
      </h5>
      <LoadingLogo show={uploadLoading} />
      {isNewUpgrade ? (
        <>
          <div>To add an asset for the new level, save the item first.</div>
          <br />
        </>
      ) : (
        <>
          <DragUpload onUpload={handleUpload} onValidate={onValidate} />
          <Form.Group>
            <Form.Label>Asset</Form.Label>
            <Form.Control value={upgrade.asset} disabled />
          </Form.Group>
          {!isVinyl && <AssetDisplay assetUrl={upgrade.asset} />}
        </>
      )}
      <Row>
        <Col>
          <p>Amount: </p>
        </Col>
        <Col md={10}>
          <Form.Group>
            <FormControl value={upgrade.cost?.amount ?? undefined} onChange={handleAmountChange} />
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
}
