import moment from 'moment';
import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

export const SessionOverview = ({
  frontNumber,
  debugNumber,
  backNumber,
  crashNumber,
  userNumber,
  sessionDate,
  errors,
}) => {
  const paramsMap = {
    App: { value: `${frontNumber ?? 0} events, ${debugNumber ?? 0} debug`, icon: 'text-white fas fa-mobile-alt m-2' },
    Backend: { value: `${backNumber ?? 0} logs`, icon: 'text-white fas fa-server m-2' },
    Crash: { value: `${crashNumber ?? 0} issues`, icon: 'text-white fas fa-exclamation-circle m-2' },
    'Session Date': { value: moment(sessionDate).format('LL'), icon: 'text-white fas fa-calendar-alt m-2' },
    'User Count': { value: userNumber ?? 0, icon: 'text-white fas fa-users m-2' },
  };

  return (
    <Card>
      <Card.Header className="m-0 font-weight-bold text-primary">Overview</Card.Header>
      <Card.Body>
        <Row className="mb-2">
          {Object.keys(paramsMap).map((key) => (
            <Col key={key} className="align-items-center">
              <div className="d-flex flex-row align-items-center">
                <div
                  className="d-flex align-items-center justify-content-center rounded-circle bg-primary mr-2"
                  style={{ width: 40, height: 40 }}
                >
                  <i className={paramsMap[key].icon} />
                </div>
                <div>
                  <h5 className="text-xs font-weight-bold text-primary text-uppercase mb-1">{key}</h5>
                  {paramsMap[key].value}
                </div>
              </div>
            </Col>
          ))}
        </Row>
        {errors.length > 0 &&
          errors.map((error, index) => (
            <Row key={index}>
              <Col>
                <Alert className="mb-1" variant="danger">
                  {JSON.stringify(error)}
                </Alert>
              </Col>
            </Row>
          ))}
      </Card.Body>
    </Card>
  );
};
