// @ts-ignore
import Chart from 'chart.js';
import _ from 'lodash';
import moment, { unitOfTime } from 'moment';
import { MutableRefObject } from 'react';
import { ImportantEventType, MetricTimestamp, MetricType } from '../../../../../../../__gqltypes__';
import colors from '../../../../../../../utils/dashboards/models/widgets/colors.json';
import { tickValue } from './utils';
import { eventColors } from '../../../ImportantEvents/utils';
import drawStar from './drawStar';
import { SliderController } from './SliderController';

type Series = {
  [requestName: string]: {
    index: number;
    measures: {
      name: string;
      borderWidth: number;
      displayOn: DisplayOn;
      previous?: boolean;
      alpha?: string;
      data: { x: Date; y: number }[];
    }[];
  };
};

export enum DisplayOn {
  ALWAYS = 'ALWAYS', // showm on hover, focus, nothing
  HOVER = 'HOVER', // shown on hover, focus
  FOCUS = 'FOCUS', // shown on focus
}

type ChartControllerOptions = {
  timestamp: MetricTimestamp;
  slider: MutableRefObject<SliderController | null>;
  title: string;
  type: MetricType;
  currentTimeBoundaries: { start: string; end: string };
  previousTimeBoundaries?: { start: string; end: string };
  events: { datetime: Date; description: string; type: ImportantEventType; eventKey: string; important: boolean }[];
  onFocusOrBlurEvent: (focus: boolean, eventKey: string) => void;
};

const Units: Record<MetricTimestamp, string> = {
  [MetricTimestamp.HOURLY]: 'hour',
  [MetricTimestamp.DAILY]: 'day',
  [MetricTimestamp.WEEKLY]: 'week',
  [MetricTimestamp.MONTHLY]: 'month',
  [MetricTimestamp.QUARTERLY]: 'quarter',
  [MetricTimestamp.YEARLY]: 'year',
  [MetricTimestamp.MINUTELY]: 'minute',
};

type Dataset = {
  label: string;
  requestName: string;
  requestIndex: number;
  data: { x: Date; y: number }[];
  borderWidth: number;
  displayOn: DisplayOn;
  previous: boolean;
  alpha?: string;
};

type ChartElement = {
  _chart: Chart;
  _index: number;
  _datasetIndex: number;
};

export default class ChartController {
  private timeChart: Chart;

  private isFocused = false;

  private countChart: Chart;

  private eventsChart: Chart;

  private highligtedRequestName: string | null = null;

  /**
   * Datasets are in the chart, but this is a more complete representation
   * needed when updating the graph
   */
  private datasets: Record<'time' | 'count', Dataset[]> = { time: [], count: [] };

  private eventDatasets: {
    type: ImportantEventType;
    label: string;
    color: string;
    important: boolean;
    pointStyle: HTMLCanvasElement | string;
    data: { x: Date; y: number; description: string; eventKey: string; focused: boolean }[];
  }[] = [];

  constructor(
    private series: Record<'time' | 'count', Series>,
    private canvasRefs: Record<'time' | 'count' | 'events', MutableRefObject<HTMLCanvasElement | null>>,
    private options: ChartControllerOptions
  ) {
    this.createDatasets('time');
    this.createDatasets('count');
    this.createEventDatasets();
    this.initPositionners();

    this.timeChart = this.createChart('time');
    this.countChart = this.createChart('count');
    this.eventsChart = this.createEventChart();
    this.adjustPaddings();
    this.adjustTicks();
  }

  /**
   * Compute the best number of timestamp per unit in the time scale (int number of timestamp, width > 20px)
   */
  getTimeStepSize() {
    const duration = moment.duration(
      moment(this.options.currentTimeBoundaries.end).diff(moment(this.options.currentTimeBoundaries.start))
    );
    const numberOfTimestamps = duration.as(
      this.options.timestamp === MetricTimestamp.QUARTERLY
        ? 'month'
        : (Units[this.options.timestamp] as unitOfTime.Base)
    );
    const width = this.timeChart.chartArea.right - this.timeChart.chartArea.left;
    return Math.ceil((numberOfTimestamps * 20) / width);
  }

  /**
   * Ensure that every graph get the same x-grid
   */
  adjustTicks() {
    const stepSize = this.getTimeStepSize();

    this.eventsChart.options.scales.xAxes[0].time.stepSize = stepSize;
    this.timeChart.options.scales.xAxes[0].time.stepSize = stepSize;
    this.countChart.options.scales.xAxes[0].time.stepSize = stepSize;

    this.countChart.update();
    this.timeChart.update();
    this.eventsChart.update();
  }

  /**
   * Clear the two referencies to the charts. Needed before each redraw
   */
  destroy() {
    this.timeChart.destroy();
    this.countChart.destroy();
    this.eventsChart.destroy();
  }

  /**
   * Draw a dashed vertical line at given x position on give graph
   */
  drawVerticalLine(x: number, color: string, lineWidth: number, chart: Chart) {
    const { ctx, chartArea } = chart;
    if (!ctx) return;
    ctx.save();
    ctx.strokeStyle = color;
    ctx.lineWidth = lineWidth;
    ctx.setLineDash([5, 5]);
    ctx.beginPath();
    ctx.moveTo(x, chartArea.top);
    ctx.lineTo(x, chartArea.bottom);
    ctx.stroke();
    ctx.restore();
  }

  /**
   * Display time boundaries as a subtitle
   */
  private getSubtitle() {
    const { start, end } = this.options.currentTimeBoundaries;
    const main = `between ${moment(start).format('YYYY-MM-DD HH:mm')} and  ${moment(end).format('YYYY-MM-DD HH:mm')}`;
    if (!this.options.previousTimeBoundaries) return main;

    const { start: startPrevious, end: endPrevious } = this.options.previousTimeBoundaries;
    const previous = `compared to ${moment(startPrevious).format('YYYY-MM-DD HH:mm')} and  ${moment(endPrevious).format(
      'YYYY-MM-DD HH:mm'
    )}`;
    return `${main}, ${previous}`;
  }

  /**
   * Helper for the constructor to transform the series input into datasets representation, suited for chartjs
   */
  private createDatasets(graph: 'time' | 'count') {
    this.datasets[graph] = Object.entries(this.series[graph]).flatMap(([requestName, dataset]) =>
      dataset.measures.map((measure) => ({
        label: `${requestName} - ${measure.name}`,
        requestName,
        requestIndex: dataset.index,
        data: measure.data.map(({ x, y }) => ({ x, y: Math.round(y) })),
        borderWidth: measure.borderWidth,
        displayOn: measure.displayOn,
        previous: measure.previous === true,
        alpha: measure.alpha,
      }))
    );
  }

  private initPositionners() {
    Chart.Tooltip.positioners.bottom = (items: unknown) => {
      const pos = Chart.Tooltip.positioners.average(items);

      // Happens when nothing is found
      if (pos === false) {
        return false;
      }

      return {
        x: pos.x,
        y: this.eventsChart.chartArea.bottom,
        xAlign: 'center',
        yAlign: 'bottom',
      };
    };
  }

  private createEventDatasets() {
    this.eventDatasets = _.sortBy(Object.entries(_.groupBy(this.options.events, 'label')), 'label').flatMap(
      ([label, eventsByType], index) =>
        Object.entries(_.groupBy(eventsByType, 'important')).map(([important, events]) => ({
          label: `${important === 'true' ? ' ☆ ' : ''}${label}`,
          type: events[0].type,
          important: important === 'true',
          color: `${eventColors[events[0].type]}${important === 'true' ? 'ff' : 'aa'}`,
          pointStyle: important === 'true' ? this.createStarPointCanvas(eventColors[events[0].type]) : 'triangle',
          data: _.sortBy([...events], 'datetiem').map((event) => ({
            x: event.datetime,
            y: 0,
            eventKey: event.eventKey,
            description: event.description,
            focused: event.important,
          })),
        }))
    );
  }

  /**
   * Build the x axis(es). There is 2 axis when comparing two periods
   */
  private getXAxes(graph: 'time' | 'count' | 'events') {
    const currentAxis = {
      id: 'current',
      type: 'time',
      time: { unit: Units[this.options.timestamp] },
      ticks: {
        display: graph === 'time',
        min: this.options.currentTimeBoundaries.start,
        max: this.options.currentTimeBoundaries.end,
      },
    };
    if (!this.options.previousTimeBoundaries) return [currentAxis];
    return [
      currentAxis,
      {
        id: 'previous',
        type: 'time',
        time: { unit: Units[this.options.timestamp] },
        ticks: {
          display: graph === 'time',
          min: this.options.previousTimeBoundaries.start,
          max: this.options.previousTimeBoundaries.end,
        },
      },
    ];
  }

  /**
   * Create the y axes, one for each graph
   */
  private getYAxes(graph: 'time' | 'count' | 'events') {
    if (graph === 'events') {
      return [
        {
          type: 'linear',
          ticks: { display: false, min: -0.5, max: 4 },
          gridLines: { display: false },
          scaleLabel: {
            labelString: 'Events',
            display: true,
          },
        },
      ];
    }
    return [
      {
        type: 'linear',
        ticks: { min: 0, max: this.getMax(graph), callback: tickValue },
        scaleLabel: {
          labelString:
            graph === 'count'
              ? 'Number of requests'
              : this.options.type === MetricType.SPANNERRESPONSESIZE
              ? 'Size of response (B)'
              : 'Execution time (ms)',
          display: true,
        },
      },
    ];
  }

  /**
   * The scales of request count and executionn time are not necessarly the same. To keep the horiwontal scale aligned
   * we need to add some padding to the y-scale that is too narrow. Each digit takes 7px, so we can addapt using that
   */
  private adjustPaddings() {
    const max1 = this.timeChart.options.scales.yAxes[0].ticks.max;
    const max2 = this.countChart.options.scales.yAxes[0].ticks.max;

    const digitsDiff = Math.ceil(Math.log10(max1)) - Math.ceil(Math.log10(max2));
    const pxDiff = digitsDiff * 7;

    this.timeChart.options.scales.yAxes[0].ticks.padding = Math.max(-pxDiff, 0);
    this.countChart.options.scales.yAxes[0].ticks.padding = Math.max(pxDiff, 0);
    this.eventsChart.options.layout.padding.left =
      5 + Math.max(Math.ceil(Math.log10(max1)), Math.ceil(Math.log10(max2))) * 7;

    this.timeChart.update();
    this.countChart.update();
    this.eventsChart.update();

    this.options.slider?.current?.adjustPaddings(this.eventsChart.options.layout.padding.left + 32);
  }

  /**
   * Compute the maximum value for the y axis, considering datasets matchin options
   * @param onlyDisplay DisplayOn[]: only datasets with these levels will be considered (default = all except focus)
   * @param onlyRequest string: only datasets related to this request will be considered (default = all requests)
   */
  private getMax(
    graph: 'time' | 'count',
    onlyDisplay: DisplayOn[] = [DisplayOn.ALWAYS, DisplayOn.HOVER],
    onlyRequest?: string
  ) {
    const dataSource = this.datasets[graph]
      .filter(
        (dataset) =>
          onlyDisplay.includes(dataset.displayOn) && (onlyRequest === undefined || dataset.requestName === onlyRequest)
      )
      .flatMap((dataset) => dataset.data.map((data) => data.y));
    const max = _.max(dataSource) || 1;
    const rounder = 10 ** (Math.floor(Math.log10(max)) - 1);
    return Math.ceil(max / rounder) * rounder;
  }

  /**
   * Create the chart (either time or count) using chart js
   */
  private createChart(graph: 'time' | 'count'): Chart | undefined {
    if (this.canvasRefs[graph].current === null) return undefined;

    const ctx = (this.canvasRefs[graph].current as HTMLCanvasElement).getContext('2d');

    const chart = new Chart(ctx, {
      type: 'line',
      fill: false,
      responsive: true,
      data: {
        datasets: this.datasets[graph].map((dataset) => ({
          label: dataset.label,
          tension: 0.1,
          borderColor: `${colors[dataset.requestIndex % colors.length]}${dataset.alpha || 'FF'}`,
          backgroundColor: `#00000000`,
          data: dataset.data,
          hidden: dataset.displayOn !== DisplayOn.ALWAYS,
          borderDash: dataset.previous ? [5, 3] : [],
          pointRadius: (dataset.borderWidth * 2) / 3,
          borderWidth: dataset.borderWidth,
          xAxisID: dataset.previous ? 'previous' : 'current',
        })),
      },
      options: {
        onResize: graph === 'time' ? () => this.adjustTicks : null,
        maintainAspectRatio: false,
        legend: { display: false },
        scales: { xAxes: this.getXAxes(graph), yAxes: this.getYAxes(graph) },
        animation: { duration: 0 },
        hover: { animationDuration: 0 },
        responsiveAnimationDuration: 0,
        tooltips: {
          callbacks: {
            label: (item: { datasetIndex: number; index: number }) => {
              const dataset = this.datasets[graph][item.datasetIndex];
              return `  ${dataset.requestName}`;
            },
            footer: (items: { datasetIndex: number; index: number }[]) => {
              if (items.length < 1) return '';
              const requestName = this.datasets[graph][items[0].datasetIndex].requestName;
              return Object.values(this.series)
                .flatMap((serie) =>
                  (serie[requestName]?.measures || [])
                    .filter((measure) => items[0].index < measure.data.length)
                    .map((measure) => `  * ${measure.name}: ${measure.data[items[0].index].y}`)
                )
                .join('\n');
            },
          },
          bodyFontStyle: 'bold',
          bodySpacing: 18,
          footerFontStyle: 'normal',
        },
      },
      plugins: [
        {
          id: 'highlightRequest',
          beforeEvent: (c: Chart) => {
            if (this.isFocused) {
              return;
            }
            const activeDatasetIndex = this.getChart(graph)?.tooltip?._active?.[0]?._datasetIndex;
            const activeDatasetName = this.datasets[graph][activeDatasetIndex]?.requestName;
            if (this.highligtedRequestName !== activeDatasetName) {
              this.revertHighlightRequest();
            }
            if (activeDatasetName !== undefined) {
              this.highlightRequest(activeDatasetName);
            }
          },
        },
        {
          id: 'redrawVerticalLines',
          beforeDraw: (c: Chart) => {
            this.redrawVerticalLines(c);

            // Warning: synchronize in 1 direction only or it will be an infinite loop:
            // update(time) -> beforeDraw(time) -> update(count) -> beforeDraw(time) -> update(count)
            if (graph === 'time') this.getChart('count')?.update();
          },
        },
      ],
    });

    return chart;
  }

  private createStarPointCanvas(color: string) {
    const canvas = document.createElement('CANVAS') as HTMLCanvasElement;
    canvas.width = 20;
    canvas.height = 20;
    drawStar(color, canvas);
    return canvas;
  }

  createEventChart(): Chart | undefined {
    if (this.canvasRefs.events.current === null) return undefined;

    const ctx = (this.canvasRefs.events.current as HTMLCanvasElement).getContext('2d');

    return new Chart(ctx, {
      type: 'line',
      fill: false,
      responsive: true,
      data: {
        datasets: this.eventDatasets.map((ds) => ({
          label: ds.label,
          borderColor: ds.color,
          backgroundColor: `#00000000`,
          pointRadius: 5,
          pointStyle: ds.pointStyle,
          pointBackgroundColor: ds.color,
          data: ds.data,
        })),
      },
      options: {
        showLines: false,
        title: {
          display: true,
          text: [this.options.title, this.getSubtitle()],
        },
        maintainAspectRatio: false,
        legend: { display: false },
        scales: { xAxes: this.getXAxes('events'), yAxes: this.getYAxes('events') },
        animation: { duration: 0 },
        hover: { animationDuration: 0, intersect: true, mode: 'nearest' },
        responsiveAnimationDuration: 0,
        tooltips: {
          callbacks: {
            label: (item: { datasetIndex: number; index: number }) => {
              const label = this.eventDatasets[item.datasetIndex]?.label;
              return label || 'Event';
            },
            afterLabel: (item: { datasetIndex: number; index: number }) => {
              const description = this.eventDatasets[item.datasetIndex]?.data?.[item.index]?.description;
              return description || '';
            },
          },
          position: 'bottom',
          bodyFontStyle: 'normal',
          bodySpacing: 2,
          footerFontStyle: 'normal',
        },
        onClick: (e: PointerEvent, elements: ChartElement[]) => {
          if (elements.length < 1) return;
          const element = this.eventDatasets[elements[0]._datasetIndex]?.data?.[elements[0]._index];
          if (!element) return;
          const { focused } = element;
          this.eventDatasets[elements[0]._datasetIndex].data[elements[0]._index].focused = !focused;
          this.options.onFocusOrBlurEvent(!focused, element.eventKey);
        },
      },
      plugins: [
        {
          id: 'highlightEvent',
          beforeEvent: (c: Chart) => {
            if (this.isFocused) {
              return;
            }
            const activeEventDatasetIndex = this.eventsChart?.tooltip?._active?.[0]?._datasetIndex;
            const activeEventIndex = this.eventsChart?.tooltip?._active?.[0]?._index;
            const activeEvent = this.eventDatasets[activeEventDatasetIndex]?.data?.[activeEventIndex];
            const x = this.eventsChart?.tooltip?._active?.[0]?._view?.x;
            const important = this.eventDatasets[activeEventDatasetIndex]?.important;
            const color = this.eventDatasets[activeEventDatasetIndex]?.color;

            if (activeEvent && x && color) {
              this.drawVerticalLine(x, color, important ? 3 : 2, this.getChart('time'));
              this.drawVerticalLine(x, color, important ? 3 : 2, this.getChart('count'));
            } else {
              this.getChart('time').update();
            }
          },
        },
      ],
    });
  }

  private redrawVerticalLines(chart: Chart) {
    if (!this.eventsChart) return;
    // draw lines for each focused event
    this.eventDatasets.forEach((ds) =>
      ds.data
        .filter((event) => event.focused)
        .forEach((event) => {
          const { left, min, max, width } = this.eventsChart.scales.current;
          const x = left + (width * (event.x.valueOf() - min)) / (max - min);
          this.drawVerticalLine(x, ds.color, ds.important ? 3 : 2, chart);
        })
    );
  }

  /**
   * Helper to access the chart instance using the key "time" or "count"
   */
  private getChart(graph: 'time' | 'count' | 'events') {
    return graph === 'time' ? this.timeChart : graph === 'count' ? this.countChart : this.eventsChart;
  }

  /**
   * Highlight all graphs related to a given request, either for the time or count graph
   * @see highlightRequest
   * @param requestName the name of the hightlighted request
   * @param display the levels of display to show (by defaul all except focus-only datasets)
   * @param hideOthers should we hide other datasets?
   */
  private highlightRequestFor1Graph(
    graph: 'time' | 'count',
    requestName: string,
    display: DisplayOn[] = [DisplayOn.ALWAYS, DisplayOn.HOVER],
    hideOthers = false
  ) {
    const chart = this.getChart(graph);
    this.datasets[graph].forEach((ds, index) => {
      if (ds.requestName !== requestName) {
        if (hideOthers) {
          chart.data.datasets[index].hidden = true;
        }
        return;
      }
      chart.data.datasets[index].hidden = !display.includes(ds.displayOn);
      if (ds.displayOn === DisplayOn.ALWAYS) {
        chart.data.datasets[index].borderWidth = 2 + ds.borderWidth;
      }
    });
    chart.update();
  }

  /**
   * Go back to normal, with only main datasets shown, either for the time or count graph
   * @see revertHighlightRequest
   * @see highlightRequestFor1Graph
   */
  private revertHighlightRequestFor1Graph(graph: 'time' | 'count') {
    const chart = this.getChart(graph);
    this.datasets[graph].forEach((ds, index) => {
      chart.data.datasets[index].hidden = ds.displayOn !== DisplayOn.ALWAYS;
      chart.data.datasets[index].borderWidth = ds.borderWidth;
    });
    chart.update();
  }

  /**
   * Highlight all graphs related to a given request on both graphs
   *
   * @param requestName the name of the hightlighted request
   * @param display the levels of display to show (by defaul all except focus-only datasets)
   * @param hideOthers should we hide other datasets?
   */
  private highlightRequest(requestName: string, display?: DisplayOn[], hideOthers?: boolean) {
    if (!this.timeChart || !this.countChart) return;
    this.highligtedRequestName = requestName;
    this.highlightRequestFor1Graph('time', requestName, display, hideOthers);
    this.highlightRequestFor1Graph('count', requestName, display, hideOthers);
  }

  /**
   * Go back to normal, with only main datasets shown, for both graphs
   * @see highlightRequest
   */
  private revertHighlightRequest() {
    if (!this.timeChart || !this.countChart || this.highligtedRequestName === null) return;
    this.revertHighlightRequestFor1Graph('time');
    this.revertHighlightRequestFor1Graph('count');
    this.highligtedRequestName = null;
  }

  /**
   * Focus an event and show a vertical line
   */
  public focusOrBlurEvent(type: ImportantEventType, important: boolean, eventKey: string, focus: boolean) {
    const dataset = this.eventDatasets.find((ds) => ds.type === type && ds.important === important);
    if (!dataset) return;
    const event = dataset.data.find((evt) => evt.eventKey === eventKey);
    if (!event) return;
    event.focused = focus;
    this.countChart.update();
    this.timeChart.update();
  }
}
