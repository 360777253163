import React, { useMemo } from 'react';
import colors from '../../../models/widgets/colors.json';
import { widgetDefaultProps, widgetPropTypes } from '../widgetPropTypes';
import BaseChart from './BaseChart';

const PieChart = ({ id, height, width, data, options, title }) => {
  const [datasets, labels] = useMemo(() => {
    const { data: dataToDisplay } = data.labelDataSplit();
    const labl = Object.keys(dataToDisplay);
    const dst = [
      {
        data: labl.map((label) => dataToDisplay[label][0] || 0),
        backgroundColor: colors.slice(0, labl.length),
      },
    ];
    return [dst, labl];
  }, [data.data]);

  return (
    <BaseChart
      id={id}
      type="pie"
      height={height}
      width={width}
      options={{
        ...options,
        scales: {
          yAxes: [
            {
              gridLines: {
                display: false,
                drawBorder: false,
              },
              ticks: {
                display: false,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
                drawBorder: false,
              },
              ticks: {
                display: false,
              },
            },
          ],
        },
      }}
      labels={labels}
      datasets={datasets}
      title={title}
    />
  );
};

PieChart.propTypes = widgetPropTypes;
PieChart.defaultProps = widgetDefaultProps;

export default PieChart;
