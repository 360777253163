import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { TopBanner } from './components/TopBanner';
import LiveSessionView from './LiveSessionView';
import { getBasePath, idToRoute, parseID } from './models';
import { RuntimeView } from './RuntimeView';
import { SessionsView } from './SessionsView';
import { SessionView } from './SessionView';

const useSessions = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const [inputText, setInputText] = useState(null);
  const [inputType, setInputType] = useState(null);

  useEffect(() => {
    const id = window.location.pathname.split('/').pop();
    loadID(id);
  }, [match]);

  const loadID = (id) => {
    const key = parseID(id);
    if (key) {
      setInputType(key.substring(0, key.length - 2));
      setInputText(id);
    }
  };

  const onSearch = (id, date = null) => {
    const route = idToRoute(id);
    if (route) {
      let link = '';
      if (match.path.split('/').length > 4) {
        link = `${getBasePath(match.path)}/${route}/${id}`;
      } else {
        link = `${match.path}/${route}/${id}`;
      }
      if (date && moment(date).isValid()) {
        link = link.concat(`?t=${moment(date).valueOf()}`);
      }
      history.push(link);
      return true;
    }
    return false;
  };

  return {
    inputText,
    inputType,
    onSearch,
    match,
  };
};

const InputView = () => {
  const { inputText, inputType, onSearch, match } = useSessions();

  return (
    <>
      <TopBanner
        onSearchID={onSearch}
        data={inputText ? { inputID: inputText, type: inputType } : null}
        enableDate={inputType === 'runtime'}
      />
      <Switch>
        <Route path={`${match.path}/livesession/:ids`} component={() => <LiveSessionView />} />
        <Route path={`${match.path}/session/:id`} component={() => <SessionView />} />
        <Route path={`${match.path}/sessions/:id`} component={() => <SessionsView />} />
        <Route path={`${match.path}/runtime/:id`} component={() => <RuntimeView />} />
        <Route
          path={match.path}
          component={() => (
            <span>
              <i className="fas fa-search m-2" />
              <i>Search for user, session, instance ...</i>
            </span>
          )}
        />
      </Switch>
    </>
  );
};

export default InputView;
