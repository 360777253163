import React from 'react';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { Link, useRouteMatch } from 'react-router-dom';
import { handleDowload, idToRoute, parseTimestamp, SourceIcon } from '../models';

const styles = {
  buttonsGroup: {
    marginRight: '1em',
  },
};

const ApolloRow = ({ date, icon, eventName, buttons, border = true, className }) => (
  <tr className={className} style={border ? { borderBottom: '1px solid #e3e6f0' } : {}}>
    <td>
      <SourceIcon iconType={icon} />
    </td>
    <td>{parseTimestamp(date)}</td>
    <td>{eventName}</td>
    {buttons && (
      <td>
        <div>
          {buttons.map(({ onClick, title }) => (
            <Button key={title} style={styles.buttonsGroup} size="sm" onClick={onClick}>
              {title}
            </Button>
          ))}
        </div>
      </td>
    )}
  </tr>
);

export const ApolloOperationLog = ({ params, showModal }) => {
  const match = useRouteMatch();
  const {
    skipped_date: skippedDate,
    sent_date: sentDate,
    received_date: receivedDate,
    answered_date: answeredDate,
    response_date: responseDate,
    failed_date: failedDate,
    message: failedMessage,
  } = params;
  return (
    <>
      <span className="mr-4">
        <i className="far fa-arrow-alt-circle-right mr-2" />
        Type: {params.type || 'Not found'}
      </span>
      {params.request_duration && (
        <span className="mr-4">
          <i className="far fa-clock mr-2" /> Request duration:
          {params.request_duration} ms
        </span>
      )}
      <span>
        <i className="fas fa-cogs mr-2" />
        Computation duration: {params.back_duration || 'Not found'} ms
      </span>
      {params.runtime_id && (
        <span className="mr-4">
          <i className="fas fa-server mr-2" /> Runtime ID:
          <Link
            className="text-decoration-none p-0 m-0 text-left"
            to={`${match.path.split('/').slice(0, -2).join('/')}/${idToRoute(params.runtime_id)}/${params.runtime_id}`}
          >
            {params.runtime_id}
          </Link>
        </span>
      )}
      <Table className="mb-0" borderless>
        <tbody>
          {skippedDate && <ApolloRow date={skippedDate} eventName="Request Skipped" />}
          {sentDate && <ApolloRow date={sentDate} eventName="Request Started" />}
          {receivedDate && <ApolloRow date={receivedDate} icon="backend" eventName="Request Received" />}
          {answeredDate && (
            <ApolloRow
              date={answeredDate}
              icon="backend"
              eventName="Request Answered"
              buttons={[
                { onClick: () => showModal(params.response_data), title: 'Show Data' },
                { onClick: () => handleDowload(params.response_data, 'response_data.json'), title: 'Download JSON' },
              ]}
            />
          )}
          {responseDate && <ApolloRow date={responseDate} eventName="Request Responded" border={false} />}
          {failedDate && (
            <ApolloRow
              className="border-left-danger"
              date={failedDate}
              eventName="Request Failed"
              border={false}
              buttons={[{ onClick: () => showModal(failedMessage), title: 'Fail Message' }]}
            />
          )}
        </tbody>
      </Table>
    </>
  );
};
