import moment from 'moment';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

type Props = {
  show: boolean;
  onHide: () => void;
  onGenerateReport: (month: number, year: number, appId: string) => void;
  appIds: string[];
};

const GenerateReportPopup = ({ show, onHide, onGenerateReport, appIds }: Props) => {
  const [isInvalid, setIsInvalid] = useState(false);

  const yearOptions = [];
  const monthOptions = [];

  const now = moment();

  for (let i = 2020; i <= now.year(); i++) {
    yearOptions.push(<option key={i}>{i}</option>);
  }
  for (let i = 1; i <= 12; i++) {
    monthOptions.push(
      <option key={i} value={i}>
        {moment()
          .set('month', i - 1)
          .format('MMMM')}
      </option>
    );
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const target = event.target as HTMLFormElement;

    const year = parseInt((target.elements.namedItem('formYear') as HTMLInputElement).value, 10);
    const month = parseInt((target.elements.namedItem('formMonth') as HTMLInputElement).value, 10);
    const appId = (target.elements.namedItem('formApp') as HTMLInputElement).value;

    const _now = moment();
    const date = moment(`${year}-${month}-1`, 'YYYY-M-D');
    if (date.isSameOrAfter(_now, 'month')) {
      event.stopPropagation();
      setIsInvalid(true);
      return;
    }

    setIsInvalid(false);
    onGenerateReport(month, year, appId);
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onSubmit={onSubmit}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="modal-title-buttons">
          <h3>Generate Listen Count Report</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Row>
            <Form.Group as={Col} controlId="formYear">
              <Form.Label>Year</Form.Label>
              <Form.Control as="select" defaultValue={now.year()} isInvalid={isInvalid}>
                {yearOptions}
              </Form.Control>
              <Form.Control.Feedback type="invalid" tooltip>
                Can&apos;t schedule a report for a month and a year that is not lower than the current date
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="formMonth">
              <Form.Label>Month</Form.Label>
              <Form.Control as="select" defaultValue={now.month() + 1} isInvalid={isInvalid}>
                {monthOptions}
              </Form.Control>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="formApp">
              <Form.Label>App</Form.Label>
              <Form.Control as="select" defaultValue="All">
                {appIds.map((appId) => (
                  <option key={appId}>{appId}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form.Row>
          <div className="d-flex justify-content-end mt-4">
            <Button variant="primary" type="submit">
              Generate Report
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default GenerateReportPopup;
