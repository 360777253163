import { Form } from 'react-bootstrap';

export interface NumberInputProps {
  title: string;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  step?: number;
  max?: number;
}

export default function NumberInput({ title, value, setValue, step, max }: NumberInputProps) {
  return (
    <Form.Group className="mt-3">
      <Form.Label>{title}</Form.Label>
      <Form.Control type="number" value={value} onChange={(e) => setValue(e.target.value)} step={step} max={max} />
    </Form.Group>
  );
}
