import { useMutation } from '@apollo/client';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';

import { SearchUserQuery, SpecialEventScoreDetailType } from '../../../../../../__gqltypes__';
import { UPDATE_USER_SPECIAL_EVENT_POINTS } from '../../../graphql';

type Props = {
  userId: string;
  specialEventId: string | undefined;
  scoreDetails?: NonNullable<NonNullable<SearchUserQuery['user']>['currentSpecialEvent']>['meScoreDetails'];
};

const FestivalProgressDisplay = ({ userId, specialEventId, scoreDetails }: Props) => {
  const [expanded, setExpanded] = useState(false);
  const [contentPackPoints, setContentPackPoints] = useState(0);
  const [liveShowPoints, setLiveShowPoints] = useState(0);
  const [missionPoints, setMissionPoints] = useState(0);
  const [tournamentPoints, setTournamentPoints] = useState(0);

  const [updateUserSpecialEventPoints, { loading: updatePointsLoading }] = useMutation(
    UPDATE_USER_SPECIAL_EVENT_POINTS
  );

  useEffect(() => {
    if (!_.isNil(scoreDetails)) {
      scoreDetails.forEach(({ score, type }) => {
        switch (type) {
          case SpecialEventScoreDetailType.CONTENT_PACK_LEVEL_UP:
            setContentPackPoints(score);
            break;
          case SpecialEventScoreDetailType.LIVE_SHOW:
            setLiveShowPoints(score);
            break;
          case SpecialEventScoreDetailType.MISSION:
            setMissionPoints(score);
            break;
          case SpecialEventScoreDetailType.TOURNAMENT:
            setTournamentPoints(score);
            break;
          default:
            break;
        }
      });
    }
  }, [scoreDetails]);

  const handleChange = (value: number, type: SpecialEventScoreDetailType) => {
    switch (type) {
      case SpecialEventScoreDetailType.CONTENT_PACK_LEVEL_UP:
        setContentPackPoints(value);
        break;
      case SpecialEventScoreDetailType.LIVE_SHOW:
        setLiveShowPoints(value);
        break;
      case SpecialEventScoreDetailType.MISSION:
        setMissionPoints(value);
        break;
      case SpecialEventScoreDetailType.TOURNAMENT:
        setTournamentPoints(value);
        break;
      default:
        break;
    }
  };

  const handleSave = () => {
    if (!_.isNil(specialEventId)) {
      const userScoreDetails = [
        {
          type: SpecialEventScoreDetailType.CONTENT_PACK_LEVEL_UP,
          score: Number(contentPackPoints),
        },
        {
          type: SpecialEventScoreDetailType.LIVE_SHOW,
          score: Number(liveShowPoints),
        },
        {
          type: SpecialEventScoreDetailType.MISSION,
          score: Number(missionPoints),
        },
        {
          type: SpecialEventScoreDetailType.TOURNAMENT,
          score: Number(tournamentPoints),
        },
      ];

      updateUserSpecialEventPoints({ variables: { input: { specialEventId, userId, userScoreDetails } } });
    }
  };

  return (
    <Card className="mb-4">
      <Card.Body>
        <Accordion>
          <Accordion.Toggle as={Card.Title} variant="link" eventKey="0" onClick={() => setExpanded(!expanded)}>
            <div style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}>
              Current Festival points
              <Button variant="link">
                {!expanded && <i className="fas fa-chevron-down" />}
                {expanded && <i className="fas fa-chevron-up" />}
              </Button>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <>
              {!_.isNil(specialEventId) ? (
                <Form>
                  <Form.Group>
                    <Form.Label>CONTENT PACK LEVEL UP</Form.Label>
                    <Form.Control
                      type="number"
                      value={contentPackPoints}
                      // @ts-ignore
                      onChange={(e) => handleChange(e.target.value, SpecialEventScoreDetailType.CONTENT_PACK_LEVEL_UP)}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>LIVE SHOW</Form.Label>
                    <Form.Control
                      type="number"
                      value={liveShowPoints}
                      // @ts-ignore
                      onChange={(e) => handleChange(e.target.value, SpecialEventScoreDetailType.LIVE_SHOW)}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>MISSION</Form.Label>
                    <Form.Control
                      type="number"
                      value={missionPoints}
                      // @ts-ignore
                      onChange={(e) => handleChange(e.target.value, SpecialEventScoreDetailType.MISSION)}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>TOURNAMENT</Form.Label>
                    <Form.Control
                      type="number"
                      value={tournamentPoints}
                      // @ts-ignore
                      onChange={(e) => handleChange(e.target.value, SpecialEventScoreDetailType.TOURNAMENT)}
                    />
                  </Form.Group>
                </Form>
              ) : (
                <div>No active Festival</div>
              )}
              {!_.isNil(specialEventId) && (
                <div className="d-flex justify-content-end align-items-baseline mb-3">
                  <Button disabled={updatePointsLoading} variant="primary" onClick={handleSave}>
                    Save Progress
                  </Button>
                </div>
              )}
            </>
          </Accordion.Collapse>
        </Accordion>
      </Card.Body>
    </Card>
  );
};

export default FestivalProgressDisplay;
