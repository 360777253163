import Badge from 'react-bootstrap/Badge';
import Card from 'react-bootstrap/Card';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { useRouteMatch } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';
import { CurationContentPackQuery } from '../../../../../../__gqltypes__';

type Props = {
  curationContentPack: CurationContentPackQuery["curationContentPack"];
};

export default function PlaylistVersion({ curationContentPack }: Props) {
  const match = useRouteMatch<{ id: string; version: string }>();

  return (
    <Card className="mt-2">
      <Card.Body>
        <div>
          {'Playlist version: '}
          {match.params.version || curationContentPack.version === 1 ? (
            <>
              <Badge pill variant="info">
                {`Version: ${curationContentPack.version}`}
              </Badge>
              <br />
            </>
          ) : (
            <>
              <DropdownButton size="sm" title={`Version: ${curationContentPack.version}`}>
                {Array.from(Array(curationContentPack.version - 1), (x, index) => index + 1).map((i) => (
                  <LinkContainer key={i} to={`/sp3/playlist/${curationContentPack.originId}/${i}`}>
                    <Dropdown.Item>{`v${i}`}</Dropdown.Item>
                  </LinkContainer>
                ))}
              </DropdownButton>
              Origin ID: {curationContentPack.originId}
            </>
          )}
        </div>
      </Card.Body>
    </Card>
  );
}
