import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { Badge, Button, Card, Col, Modal, Nav, OverlayTrigger, Row, Spinner, Tab, Tooltip } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';

import {
  QuizLiveShowQuery,
  QuizLiveShowsQuery,
  RemoveLiveShowMutation,
  RemoveLiveShowMutationVariables,
  StopCurrentLiveShowMutation,
  UpsertQuizLiveShowMutation,
  UpsertQuizLiveShowInput,
  UpsertQuizLiveShowMutationVariables,
  CopyQuizLiveShowMutation,
} from '../../../../../__gqltypes__';
import { LoadingLogo } from '../../../devtools/components/Modal';
import AddLiveShowModal from './components/AddLiveShowModal';
import './components/StatusIndicator.css';
import {
  GET_QUIZ_LIVE_SHOW,
  GET_QUIZ_LIVE_SHOWS,
  REMOVE_LIVE_SHOW,
  STOP_CURRENT_LIVE_SHOW,
  UPSERT_QUIZ_LIVE_SHOW,
} from './graphql';
import LiveShowData, { getRoundStatus, RoundStatus } from './LiveShowData';

const baseURL = '/sp3/liveops/liveshows';

type QuizLiveShow = QuizLiveShowsQuery['liveOps']['quizLiveShows']['list'][0];
type UpsertedLiveShow = UpsertQuizLiveShowMutation['upsertQuizLiveShow'];
type CopiedQuizLiveShow = CopyQuizLiveShowMutation['copyQuizLiveShow'];

type LiveShowColorType = {
  color: string;
  backgroundColor: string;
  fontWeight?: string;
  borderColor?: string;
  borderWidth?: string;
};

const LiveShows = () => {
  const [addLiveShow, { loading: addLiveShowLoading }] = useMutation<
    UpsertQuizLiveShowMutation,
    UpsertQuizLiveShowMutationVariables
  >(UPSERT_QUIZ_LIVE_SHOW);
  const [removeLiveShow, { loading: removeLiveShowLoading }] = useMutation<
    RemoveLiveShowMutation,
    RemoveLiveShowMutationVariables
  >(REMOVE_LIVE_SHOW);
  const [stopCurrentShow] = useMutation<StopCurrentLiveShowMutation>(STOP_CURRENT_LIVE_SHOW, {
    onCompleted: ({ stopCurrentLiveShow: { isStopped } }) => {
      alert(
        isStopped
          ? 'Show successfully stopped!'
          : 'Failed to stop show. Maybe the liveshow server is down or there is no ongoing show'
      );
    },
  });

  const {
    data: liveShowsData,
    fetchMore,
    loading: loadingLiveShowsData,
  } = useQuery<QuizLiveShowsQuery>(GET_QUIZ_LIVE_SHOWS, {
    fetchPolicy: 'no-cache',
  });

  const [fetchLiveShow] = useLazyQuery<QuizLiveShowQuery>(GET_QUIZ_LIVE_SHOW);

  const [showAddModal, setShowAddModal] = React.useState(false);
  const [showStopModal, setShowStopModal] = React.useState(false);

  const [liveShows, setLiveShows] = React.useState<QuizLiveShow[]>([]);

  const [pageCount, setPageCount] = React.useState(1);
  const [paginationLoading, setPaginationLoading] = React.useState(false);
  const [lastElementRef, setLastElementRef] = React.useState<HTMLDivElement | null>(null);

  const { id: liveShowId } = useParams<{ id: string }>();
  const history = useHistory();
  const selectLiveShow = (newShowId: string) => {
    history.push(`${baseURL}/${newShowId}`);
  };

  const isShowSelected = () => !_.isEmpty(liveShowId) && liveShows.find(({ id }) => id === liveShowId);

  const tryToFetchLiveShows = async () => {
    if (liveShowId) {
      const res = await fetchLiveShow({ variables: { showId: liveShowId } });

      const quizLiveShow = res?.data?.quizLiveShow;

      if (!_.isNil(quizLiveShow)) {
        const { id, startDate, lastUpdateDate, lastSchedule, rounds } = quizLiveShow;

        const _updatedQuizLiveShows = [{ id, startDate, lastUpdateDate, lastSchedule, rounds }, ...liveShows];
        // @ts-ignore
        setLiveShows(_updatedQuizLiveShows);
        // @ts-ignore
        selectLiveShow(id);
      } else {
        // @ts-ignore
        selectLiveShow(liveShows[0].id);
      }
    } else {
      selectLiveShow(liveShows[0].id);
    }
  };

  React.useEffect(() => {
    if (liveShowId === ':id') {
      selectLiveShow('');
    }
    if (!isShowSelected() && liveShows.length > 0) {
      // If liveShowId from url is not yet loaded in the quizLiveShow list (left menu)
      // We try to add this one to the list
      tryToFetchLiveShows();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liveShowId]);

  React.useEffect(() => {
    if (liveShowsData && liveShowsData.liveOps) {
      const _liveShowsData = _.orderBy(liveShowsData.liveOps.quizLiveShows.list, 'startDate', 'desc');
      setLiveShows(_liveShowsData);
    }
  }, [liveShowsData]);

  React.useEffect(() => {
    if (liveShowsData && liveShowsData.liveOps && !isShowSelected() && liveShows.length > 0) {
      tryToFetchLiveShows();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liveShowsData, liveShows]);

  const handleUpsertShow = (quizLiveShow: UpsertedLiveShow) => {
    // Remove the updated live show from the list if it was already in and add it with the updated value
    const otherLiveShows = liveShows.filter(({ id }) => id !== quizLiveShow.id);
    const totalLiveShows = [...otherLiveShows, { id: quizLiveShow.id, startDate: quizLiveShow.startDate }];
    // Then sort the array
    // @ts-ignore
    setLiveShows(_.orderBy(totalLiveShows, 'startDate', 'desc'));
  };

  const handleRemoveLiveShow = (quizLiveShowId: string) => async () => {
    const res = await removeLiveShow({
      variables: {
        input: { showId: liveShowId },
      },
    });
    if (res?.data?.removeLiveShow?.isRemoved) {
      setLiveShows((currentLiveShows) => currentLiveShows.filter(({ id }) => id !== quizLiveShowId));
    }
    if (!_.isNil(res?.data?.removeLiveShow?.isUnscheduled) && !res.data?.removeLiveShow.isUnscheduled) {
      alert('Failed to unschedule the show on the live show server!');
    }
  };

  const handleAdd = (liveShowData: UpsertQuizLiveShowInput) => {
    setShowAddModal(false);
    addLiveShow({ variables: { input: liveShowData } })
      // @ts-ignore
      .then(({ data: { upsertQuizLiveShow } }) => {
        handleUpsertShow(upsertQuizLiveShow);
        selectLiveShow(upsertQuizLiveShow.id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleShowCopied = (liveShow: CopiedQuizLiveShow) => {
    // @ts-ignore
    handleUpsertShow(liveShow);
    selectLiveShow(liveShow.id);
  };

  const getScheduledStatus = (liveShow: QuizLiveShow) => {
    // @ts-ignore
    const { startDate, lastUpdateDate } = liveShow;
    // @ts-ignore
    const isShowScheduled = liveShow.lastSchedule?.isCurrentlyScheduled;
    if (moment(startDate).isSameOrBefore(moment())) {
      return <></>;
    }
    let variant = 'danger';
    if (isShowScheduled) {
      // The show has been scheduled
      variant = 'success';
    } else if (moment(lastUpdateDate).isAfter(moment().subtract(5, 'minutes'))) {
      // The show has not been scheduled yet but it has been updated in the last 5 minutes: we mark it as pending
      variant = 'warning';
    }
    return (
      <>
        <OverlayTrigger overlay={<Tooltip id="tooltip-scheduling-status">Scheduling status</Tooltip>}>
          <Badge pill variant={variant} className={`text-${variant} scheduling-status-indicator`}>
            .
          </Badge>
        </OverlayTrigger>
      </>
    );
  };

  const getColors = (liveShow: QuizLiveShow): LiveShowColorType => {
    // @ts-ignore
    const statusList = liveShow?.rounds?.map((round) =>
      getRoundStatus({
        encryptedRoundAssetUrl: round.encryptedRoundAssetUrl,
        // @ts-ignore
        liveShowLastUpdateDate: liveShow.lastUpdateDate,
      })
    );
    const statusColor = statusList?.includes(RoundStatus.WRONG)
      ? 'red'
      : statusList?.includes(RoundStatus.PENDING)
      ? 'orange'
      : 'green';
    if (liveShowId === liveShow.id) {
      return { backgroundColor: statusColor, color: 'white', fontWeight: 'bold' };
    }
    const properties: LiveShowColorType = { color: statusColor, backgroundColor: '#0000' };
    if (statusColor === 'red') {
      properties.fontWeight = 'bold';
      properties.borderColor = 'red';
      properties.borderWidth = '4px';
    }
    return properties;
  };

  /**
   * Pagination
   */
  const quizLiveShowsLimit = 20;
  const maxPage = liveShowsData?.liveOps.quizLiveShows.list
    ? liveShowsData.liveOps.quizLiveShows.totalCount % quizLiveShowsLimit === 0
      ? Math.floor(liveShowsData.liveOps.quizLiveShows.totalCount / quizLiveShowsLimit)
      : Math.floor(liveShowsData.liveOps.quizLiveShows.totalCount / quizLiveShowsLimit) + 1
    : 1;

  const observer = React.useRef(
    new IntersectionObserver((entries) => {
      const first = entries[0];
      if (first.isIntersecting) {
        setPageCount((no) => no + 1);
      }
    })
  );

  const loadNextPage = async () => {
    setPaginationLoading(true);
    const res = await fetchMore({
      variables: {
        pagination: { offset: quizLiveShowsLimit * (pageCount - 1), limit: quizLiveShowsLimit },
      },
    });
    const data = res.data.liveOps.quizLiveShows.list;
    const _updatedQuizLiveShows = _.uniqBy([...liveShows, ...data], 'id');

    setLiveShows(_updatedQuizLiveShows);
    setPaginationLoading(false);
  };

  React.useEffect(() => {
    if (pageCount - 1 <= maxPage && pageCount > 1) {
      loadNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCount]);

  React.useEffect(() => {
    const currentElement = lastElementRef;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElementRef]);

  /**
   * RENDERING
   */

  return (
    <>
      <LoadingLogo show={loadingLiveShowsData} />
      {isShowSelected() && (
        // @ts-ignore
        <Tab.Container style={{ height: '100%' }} activeKey={liveShowId} onSelect={(e) => selectLiveShow(e)}>
          <Row className="mb-5" style={{ height: '100%' }}>
            <Col
              style={{
                maxHeight: 'calc(100vh - 5.875rem)',
                overflow: 'scroll',
              }}
            >
              <Nav.Item className="mb-3">
                {loadingLiveShowsData || addLiveShowLoading ? (
                  <Spinner animation="border" />
                ) : (
                  <>
                    <Button className="w-100" variant="success" onClick={() => setShowAddModal(true)}>
                      <i className="fas fa-calendar-day mr-2" />
                      Schedule a new live show
                    </Button>
                    {process.env.REACT_APP_NAMESPACE !== 'prod' && (
                      <Button className="w-100 mt-2" variant="outline-danger" onClick={() => setShowStopModal(true)}>
                        <i className="fas fa-trash mr-2" />
                        Stop current show
                      </Button>
                    )}
                  </>
                )}
              </Nav.Item>
              <Nav variant="pills">
                {liveShows.map((liveShow, index) => (
                  <Nav.Item
                    ref={index === liveShows.length - 1 && !paginationLoading ? setLastElementRef : null}
                    key={`${liveShow.id}-${index}`}
                    style={{ width: '100%' }}
                    className="text-center position-relative"
                  >
                    <Nav.Link
                      eventKey={liveShow.id}
                      className={`justify-content-center ${
                        moment(liveShow.startDate).isBefore(moment())
                          ? 'liveops-nav-link-old'
                          : 'liveops-nav-link-current'
                      }`}
                    >
                      {moment(liveShow.startDate).format('DD/MM/YYYY')}
                      <br />
                      {moment(liveShow.startDate).format('LT')} ({liveShow.playerCount})
                      <br />
                    </Nav.Link>
                    {getScheduledStatus(liveShow)}
                  </Nav.Item>
                ))}
                {paginationLoading && <Spinner animation="border" />}
              </Nav>
            </Col>
            {removeLiveShowLoading ? (
              <Spinner animation="border" />
            ) : (
              <Col
                style={{
                  maxHeight: 'calc(100vh - 5.875rem)',
                  overflow: 'scroll',
                }}
                className="col-10"
              >
                <Tab.Content style={{ height: '100%' }}>
                  <Card>
                    <Card.Body>
                      <LiveShowData
                        showId={liveShowId}
                        onUpsertShow={handleUpsertShow}
                        removeLiveShow={handleRemoveLiveShow(liveShowId)}
                        onShowCopied={handleShowCopied}
                      />
                    </Card.Body>
                  </Card>
                </Tab.Content>
              </Col>
            )}
          </Row>
        </Tab.Container>
      )}
      <AddLiveShowModal
        // (!loading && liveShows.length === 0) means there is no future live show,
        //  so the user cannot close the modal in this case
        show={(!loadingLiveShowsData && liveShows.length === 0) || showAddModal}
        loading={addLiveShowLoading}
        onHide={() => setShowAddModal(false)}
        onAdd={handleAdd}
      />
      <Modal show={showStopModal} onHide={() => setShowStopModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Stop the current</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>This cannot be undone. Are you sure to want to stop the current show?</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowStopModal(false)}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              stopCurrentShow();
              setShowStopModal(false);
            }}
          >
            Stop show
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LiveShows;
