// A modal displayed to choose settings of the next Tournament

import { useQuery } from '@apollo/client';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, ModalProps, Row } from 'react-bootstrap';
import DateTimePicker from 'react-datetime';
import type { ContentCategoriesQuery } from '../../../../../../__gqltypes__';
import { applyChangeIfDateValid } from '../../../../../../utils/datepicker';
import { GET_CONTENT_CATEGORIES } from '../../../components/apolloQueries';

type Props = {
  loading: boolean;
  onAdd: (input: { categoryId: string; startDate: number }) => void;
} & ModalProps;

const AddTournamentModal = ({ show, onHide, onAdd, loading }: Props) => {
  /**
   * GRAPHQL
   */
  const { data } = useQuery<ContentCategoriesQuery>(GET_CONTENT_CATEGORIES);
  /**
   * STATE
   */
  const contentCategories = data ? data.app.contentCategories.filter((c) => c.id !== 'tournament') : [];
  const [categoryId, setCategoryId] = useState<string | void>();
  const [startDate, setStartDate] = useState(moment().startOf('minute').add(6, 'minutes').toDate());

  /**
   * STATE UPDATE LOGIC
   */
  // Auto select first contentCategories on update
  useEffect(() => {
    if (data?.app?.contentCategories) {
      setCategoryId(data.app.contentCategories[0].id);
    }
  }, [data]);

  // @ts-ignore
  const handleChangeCategoryId = (event) => {
    setCategoryId(event.target.value);
  };

  // HELPER METHODS
  const sanitizeDate = () => setStartDate((rawDate) => moment(rawDate).startOf('minute').toDate());

  const handleSubmit = () => {
    sanitizeDate();
    const yesterday = moment().subtract(1, 'days');
    if (!categoryId) {
      alert(`Select a category`);
      return;
    }

    onAdd({
      categoryId,
      // @ts-ignore
      startDate,
    });
  };

  /**
   * VIEW
   */
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Body>
        <Row>
          <Col xs={3}>CategoryId</Col>
          <Col xs={6}>
            <Form.Control
              name="categoryId"
              required
              as="select"
              value={categoryId ?? undefined}
              onChange={handleChangeCategoryId}
            >
              {contentCategories.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Row>
        <Row>
          <Col xs={3}>Start date</Col>
          <Col xs={6}>
            <DateTimePicker onChange={applyChangeIfDateValid(setStartDate)} initialValue={startDate} />
          </Col>
        </Row>
        <Row className="mt-3 pl-1">
          <Button disabled={loading} onClick={handleSubmit}>
            Add
          </Button>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default AddTournamentModal;
