import { gql } from '@apollo/client';

export const GET_DASHBOARD_DATA = gql`
  query getDashboardData($sqlQueries: [AnalyticsDashboardQueriesInput!]!) {
    analytics {
      id
      dashboardQueries(queries: $sqlQueries) {
        id
        data
        error
        metadata
      }
    }
  }
`;
