import { ApolloProvider, gql } from '@apollo/client';
import firebase from 'firebase';
import { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { client, updateToken } from './apollo';
import sp3routes from './apps/sp3/routes';
import AppSelection from './layout/AppSelection';
import Auth from './layout/Auth';
import Layout from './layout/Layout';
import NoAccess from './layout/NoAccess';
import { DisplayedProfile } from './layout/Topbar';
import { AppType, RouteType } from './types';
import { AdmMeQuery, UserRole } from './__gqltypes__';

require('./assets/css/sb-admin-2.css');

// Hardcoded for the moment
// TODO AppRouteType is very similar! (from src/apps/sp3/routes)
const apps: Array<AppType> = [
  {
    name: 'Curation',
    route: '/sp3/playlist/',
    contentModeratorRoute: '/sp3/playlist/moderation',
    color: 'primary',
    permission: [UserRole.ADMIN, UserRole.CURATOR, UserRole.CONTENT_MODERATOR],
  },
  { name: 'Admin', route: '/sp3/admin/user/', color: 'warning', permission: [UserRole.ADMIN] },
  { name: 'DevTools', route: '/sp3/devtools/table', color: 'success', permission: [UserRole.ADMIN] },
  { name: 'Analytics', route: '/sp3/analytics/dashboards', color: 'info', permission: [UserRole.ADMIN] },
  {
    name: 'LiveOps',
    route: '/sp3/liveops/monthlypass',
    color: 'outline-danger',
    permission: [UserRole.ADMIN, UserRole.CURATOR],
  },
];

const app = 'SP3';

function getAllRoutes(role: UserRole) {
  const routes = [sp3routes];
  let allRoutes: RouteType[] = [];
  // TODO - remove route if role doesn't have permission
  routes.forEach((appRoutes) =>
    appRoutes
      .filter((ar) => ar.permission.includes(role))
      .forEach((route) => {
        allRoutes = allRoutes.concat(
          route.sideRoutes.filter((sr) => (sr.permission ?? [UserRole.ADMIN]).includes(role))
        );
      })
  );
  return allRoutes;
}

const ROLE_QUERY = gql`
  query admMe {
    me {
      id
      role
      displayName
      picture
    }
  }
`;

const App = (): JSX.Element => {
  const [signedIn, setSignedIn] = useState(false);
  const [loadingRoles, setLoadingRoles] = useState(false);
  const [profile, setProfile] = useState<DisplayedProfile | null>(null);
  // To update once several apps will be on admin
  const [role, setRole] = useState(UserRole.USER);
  const [appSelected, setAppSelected] = useState<AppType | null>(null);

  const onSignedIn = ({ googleUser, authToken }: { googleUser: firebase.User; authToken: string }) => {
    console.log('on signed in');

    updateToken(authToken);

    setSignedIn(true);
    setProfile(googleUser);
    setLoadingRoles(true);

    client.query<AdmMeQuery>({ query: ROLE_QUERY }).then(({ data }) => {
      console.log('admin data', data);

      setLoadingRoles(false);
      setRole(data.me.role);
      setProfile(data.me);
    });
  };

  if (!signedIn) {
    return (
      <ApolloProvider client={client}>
        <Auth onSignedIn={onSignedIn} />
      </ApolloProvider>
    );
  }

  if (loadingRoles) {
    return (
      <Container>
        <Card className="o-hidden border-0 shadow-lg my-5">
          <Card.Body className="p-4">Loading config</Card.Body>
        </Card>
      </Container>
    );
  }

  const appRole = role;

  if (!appRole || appRole === UserRole.BANNED || appRole === UserRole.USER) {
    return <NoAccess />;
  }

  if (!appSelected && window.location.pathname.split('/').length < 4) {
    return <AppSelection onSelectApp={(newApp) => setAppSelected(newApp)} apps={apps} appRole={appRole} />;
  }

  return (
    <ApolloProvider client={client}>
      <Router>
        <Switch>
          <Layout role={role} routes={sp3routes} app={app} profile={profile}>
            <Switch>
              <Redirect
                exact
                from="/"
                to={
                  appSelected
                    ? role === UserRole.CONTENT_MODERATOR
                      ? appSelected.contentModeratorRoute ?? appSelected.route
                      : appSelected.route
                    : '/sp3/playlist/'
                }
              />
              <Redirect
                exact
                from="/sp3/"
                to={
                  appSelected
                    ? role === UserRole.CONTENT_MODERATOR
                      ? appSelected.contentModeratorRoute ?? appSelected.route
                      : appSelected.route
                    : '/sp3/playlist/'
                }
              />
              {getAllRoutes(role).map((route) => (
                <Route key={route.path} path={route.path} exact={!!route.exact} component={route.component} />
              ))}
              <Route>
                <NoAccess />
              </Route>
            </Switch>
          </Layout>
        </Switch>
      </Router>
    </ApolloProvider>
  );
};

export default App;
