import { useQuery } from '@apollo/client';
import _ from 'lodash';
import { useMemo, useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { LiveShowLogsQuery } from '../../../../../../__gqltypes__';
import { GET_LIVE_SHOW_LOGS } from '../graphql';
import { levels, servers } from './config';
import LiveShowLogRow from './consoleLogRow';
import { LiveShowLog } from './types';
import './console.css';

export default function LiveShowLogsConsole({
  showId,
  totalNumberOfLogs,
  onDisplayLogDetails,
}: {
  showId: string;
  totalNumberOfLogs: number;
  onDisplayLogDetails: (log: LiveShowLog) => void;
}) {
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState({ servers: {}, levels: {} });
  const { data, loading, refetch } = useQuery<LiveShowLogsQuery>(GET_LIVE_SHOW_LOGS, {
    variables: {
      showId,
      pagination: {
        limit: totalNumberOfLogs,
        offset: 0,
      },
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (d) => {
      const logs = d.analytics?.liveShowLogs?.list ?? [];
      const serverNames = _.uniq(logs.map(({ source }) => source));
      const levelNames = _.uniq(logs.map(({ level }) => level));
      setFilter({
        servers: Object.fromEntries(serverNames.map((server) => [server, true])),
        levels: Object.fromEntries(levelNames.map((server) => [server, true])),
      });
    },
  });

  const dataWithKeys = useMemo(() => {
    return (data?.analytics?.liveShowLogs?.list ?? []).map((log, i) => ({ ...log, id: i }));
  }, [data?.analytics?.liveShowLogs?.list]);

  const updateSearch = _.debounce((value: string) => {
    console.log(Date.now(), value);
    setSearch(value);
  }, 300);

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Log details</Modal.Title>
        <div style={{ flex: 1, display: 'flex', margin: 'auto 30px auto auto' }}>
          <div style={{ flex: 1 }} />
          {Object.keys(filter.servers).map((server) => (
            <Button
              key={server}
              size="sm"
              variant="light"
              style={{ margin: 2, backgroundColor: '#eee' }}
              onClick={() =>
                setFilter((pFilters) => ({
                  ...pFilters,
                  servers: {
                    ...pFilters.servers,
                    [server]: !pFilters.servers[server as keyof typeof pFilters.servers],
                  },
                }))
              }
            >
              {filter.servers[server as keyof typeof filter.servers]
                ? servers[server as keyof typeof servers].colored ?? server
                : servers[server as keyof typeof servers].gray ?? <span style={{ color: '#aaa' }}>{server}</span>}
            </Button>
          ))}
          {Object.keys(filter.levels).map((level) => (
            <Button
              key={level}
              size="sm"
              variant="light"
              style={{ margin: 2, backgroundColor: '#eee' }}
              onClick={() =>
                setFilter((pFilters) => ({
                  ...pFilters,
                  levels: { ...pFilters.levels, [level]: !pFilters.levels[level as keyof typeof pFilters.levels] },
                }))
              }
            >
              {filter.levels[level as keyof typeof filter.levels]
                ? levels[level as keyof typeof levels].colored ?? level
                : levels[level as keyof typeof levels].gray ?? <span style={{ color: '#aaa' }}>{level}</span>}
            </Button>
          ))}
          <Form.Control
            // value={search}
            onChange={(e) => updateSearch(e.target.value)}
            placeholder="Filter (e.g. text)"
            size="sm"
            style={{ maxWidth: '30%', marginLeft: 15 }}
          />
          <Button size="sm" style={{ marginLeft: 10 }} onClick={() => refetch()} disabled={loading}>
            {loading ? <Spinner size="sm" animation="border" className="mr-2" /> : <i className="fas fa-sync mr-2" />}
            refetch
          </Button>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="logRowHeader">
          <div style={{ width: 50 }}>Level</div>
          <div style={{ width: 120 }}>Time</div>
          <div style={{ width: 70 }}>Server</div>
          <div style={{ flex: 1 }}>Message</div>
        </div>
        <div style={{ overflow: 'auto', height: '80vh' }}>
          {loading
            ? _.range(50).map((i) => (
                <div
                  key={i}
                  style={{
                    height: 35,
                    backgroundColor: '#ddd',
                    margin: '3px 0px',
                    borderRadius: 5,
                    animation: 'blink 2s linear infinite',
                  }}
                />
              ))
            : (dataWithKeys ?? [])
                .filter(
                  (log) =>
                    log.message.includes(search) &&
                    filter.levels[log.level as keyof typeof filter['levels']] &&
                    filter.servers[log.source as keyof typeof filter['servers']]
                )
                .map((log) => (
                  <LiveShowLogRow
                    log={log}
                    key={`${log.id}`}
                    search={search}
                    onDisplayLogDetails={onDisplayLogDetails}
                  />
                ))}
        </div>
      </Modal.Body>
    </>
  );
}
