import { useMutation, useQuery } from '@apollo/client';
import { useCallback, useState } from 'react';
import { Badge, Button, Card, Col, Form, Image, Modal, Row, Spinner, Table } from 'react-bootstrap';
import {
  AdminModerateContentItemMutation,
  AdminModerateContentItemMutationVariables,
  BatchContentModerationErrorContentItemsQuery,
  BatchContentModerationErrorContentItemsQueryVariables,
  BatchContentModerationQuery,
  BatchContentModerationQueryVariables,
  BatchContentModerationState,
  CurationContentPacksByNameQuery,
  EndBatchContentModerationMutation,
  EndBatchContentModerationMutationVariables,
  ModeratedContentItemState,
  StartBatchContentModerationMutation,
  StartBatchContentModerationMutationVariables,
  UpdateBatchContentModerationMutation,
  UpdateBatchContentModerationMutationVariables,
} from '../../../../../../__gqltypes__';
import CustomPagination from '../../../components/Pagination';
import mutations from '../../../utils/mutations';
import queries from '../../../utils/queries';
import ContentModerationLyricsModal from '../../ContentModeration/components/ContentModerationLyricsModal';
import SPPAddPack from '../../SppContent/components/SPPAddPack';
import AdminContentModerationContentItem from './AdminContentModerationContentItem';

type BatchContentModerationStats = BatchContentModerationQuery['batchContentModeration']['stats'];
type CurationContentPack = CurationContentPacksByNameQuery['curationContentPacksByName'][0];

const mergeStatsByLocale = (stats: BatchContentModerationStats): BatchContentModerationStats => {
  const mergedObj: Record<string, BatchContentModerationStats> = {}; // map locale -> array stats

  for (const stat of stats) {
    if (!mergedObj[stat.locale]) {
      mergedObj[stat.locale] = [stat];
    } else {
      mergedObj[stat.locale].push(stat);
    }
  }

  return Object.values(mergedObj).map((statsList) =>
    statsList.reduce(
      (acc, value) => {
        acc.totalCount += value.totalCount;
        acc.totalDone += value.totalDone;
        return acc;
      },
      {
        __typename: statsList[0].__typename,
        id: `${statsList[0].id}.merged`,
        locale: statsList[0].locale,
        totalDone: 0,
        totalCount: 0,
      }
    )
  );
};

type Props = {
  batchId: string;
};

const QUESTIONABLE_CONTENT_PAGE_SIZE = 50;

const AdminContentModerationBatch = ({ batchId }: Props) => {
  const [page, setPage] = useState(1); // page is 1-based
  const [showLyricsModal, setShowLyricsModal] = useState<{ title: string; artist: string } | null>(null);
  const [waitingForMutation, setWaitingForMutation] = useState(false);
  const [showBigPicture, setShowBigPicture] = useState<string | null>(null);

  const { data, loading } = useQuery<BatchContentModerationQuery, BatchContentModerationQueryVariables>(
    queries.GET_BATCH_MODERATION,
    {
      variables: { id: batchId },
    }
  );

  const { data: moderatedContentData, loading: moderatedContentLoading } = useQuery<
    BatchContentModerationErrorContentItemsQuery,
    BatchContentModerationErrorContentItemsQueryVariables
  >(queries.GET_BATCH_MODERATION_ERROR_CONTENT_ITEMS, {
    variables: {
      id: batchId,
      limit: QUESTIONABLE_CONTENT_PAGE_SIZE,
      offset: (page - 1) * QUESTIONABLE_CONTENT_PAGE_SIZE,
    },
  });

  const [updatePackIds] = useMutation<
    UpdateBatchContentModerationMutation,
    UpdateBatchContentModerationMutationVariables
  >(mutations.UPDATE_BATCH_CONTENT_MODERATION);

  const [startModeration] = useMutation<
    StartBatchContentModerationMutation,
    StartBatchContentModerationMutationVariables
  >(mutations.START_BATCH_CONTENT_MODERATION, {
    variables: { input: { id: batchId } },
  });

  const [endModeration] = useMutation<EndBatchContentModerationMutation, EndBatchContentModerationMutationVariables>(
    mutations.END_BATCH_CONTENT_MODERATION,
    {
      variables: { input: { id: batchId } },
    }
  );

  const [adminModerateItem] = useMutation<AdminModerateContentItemMutation, AdminModerateContentItemMutationVariables>(
    mutations.ADMIN_MODERATE_CONTENT_ITEM
  );

  const updateBatchPackIds = useCallback(
    async (packIds) => {
      await updatePackIds({
        variables: {
          input: {
            id: batchId,
            packIds,
          },
        },
      });
    },
    [batchId, updatePackIds]
  );

  const onAdd = ({ originId }: CurationContentPack) => {
    const currentIds = (data?.batchContentModeration.curationContentPacks ?? []).map(({ originId: id }) => id);
    currentIds.push(originId);
    updateBatchPackIds(currentIds);
  };

  const onAdminModerate = (id: string, adminState: ModeratedContentItemState) => {
    adminModerateItem({ variables: { input: { id, state: adminState } } });
  };

  const onClickPagination = (index: number) => {
    setPage(index);
  };

  const onShowLyrics = (title: string, artist: string) => {
    setShowLyricsModal({ title, artist });
  };

  const onShowBigPicture = (picture: string | null) => {
    setShowBigPicture(picture);
  };

  const handleClose = () => {
    setShowLyricsModal(null);
  };

  const canStartModeration = data?.batchContentModeration.state === BatchContentModerationState.CREATING;
  const canEndModeration = data?.batchContentModeration.state === BatchContentModerationState.IN_PROGRESS;
  const maxPage = Math.ceil(
    (moderatedContentData?.batchContentModeration.errorReportedContentItems.totalCount ?? 0) /
      QUESTIONABLE_CONTENT_PAGE_SIZE
  );

  return (
    <Card className="cp-list">
      <Card.Body>
        {loading ? (
          <div className="p-3">
            <Spinner animation="border" />
          </div>
        ) : (
          <>
            <Form className="mb-5">
              <Form.Group as={Row}>
                <Form.Label column sm={2}>
                  BatchId
                </Form.Label>
                <Col sm="5">
                  <Form.Control plaintext readOnly defaultValue={batchId} />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm={2}>
                  Name
                </Form.Label>
                <Col sm="5">
                  <Form.Control plaintext readOnly defaultValue={data?.batchContentModeration.name} />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm={2}>
                  State
                </Form.Label>
                <Col sm="5">
                  <Badge pill variant="success" className="mr-4">
                    {data?.batchContentModeration.state}
                  </Badge>

                  {canStartModeration && (
                    <Button
                      variant="success"
                      disabled={waitingForMutation}
                      onClick={() => {
                        setWaitingForMutation(true);
                        startModeration().finally(() => {
                          setWaitingForMutation(false);
                        });
                      }}
                    >
                      Start Moderation
                    </Button>
                  )}
                  {canEndModeration && (
                    <Button
                      variant="success"
                      disabled={waitingForMutation}
                      onClick={() => {
                        setWaitingForMutation(true);
                        endModeration().finally(() => {
                          setWaitingForMutation(false);
                        });
                      }}
                    >
                      End Moderation
                    </Button>
                  )}
                  {waitingForMutation && <Spinner animation="border" />}
                </Col>
              </Form.Group>
            </Form>
            <Table bordered hover>
              <thead>
                <tr>
                  <th className="text-center">#</th>
                  <th>Picture</th>
                  <th>Name</th>
                  <th># Reviewed / # Total</th>
                  <th>Complete</th>
                </tr>
              </thead>
              <tbody>
                {data?.batchContentModeration.curationContentPacks.map((cp, idx) => {
                  const packStats = data?.batchContentModeration.stats.filter(
                    (s) => s.id.split('.')[2] === cp.originId
                  );

                  const totalDone = packStats.reduce((acc, val) => {
                    return acc + val.totalDone;
                  }, 0);
                  const totalCount = packStats.reduce((acc, val) => {
                    return acc + val.totalCount;
                  }, 0);
                  const isComplete = canStartModeration ? false : totalDone === totalCount;

                  return (
                    <tr key={`${cp.id}.${idx}`}>
                      <td className="text-center">{idx + 1}</td>
                      <td>
                        {/** @ts-ignore */}
                        <Image width={100} src={cp.picture} />
                      </td>
                      <td>
                        <a href={`/sp3/playlist/${cp.originId}`}>{cp.name}</a>
                      </td>
                      <td>
                        {canStartModeration ? '-' : totalDone} / {canStartModeration ? '-' : totalCount}
                      </td>
                      <td>{isComplete && <i className="fas fa-check text-success mr-1" />}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {canStartModeration && (
              <div className="text-center">
                <SPPAddPack add={onAdd} />
              </div>
            )}
            {canEndModeration && (
              <>
                <hr />
                <h4>Stats</h4>

                <Table bordered hover>
                  <thead>
                    <tr>
                      <th className="text-center">#</th>
                      <th>Locale</th>
                      <th># Reviewed</th>
                      <th># Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {mergeStatsByLocale(data?.batchContentModeration.stats).map((stats, idx) => (
                      <tr key={stats.id}>
                        <td className="text-center">{idx + 1}</td>
                        <td>{stats.locale}</td>
                        <td>{stats.totalDone}</td>
                        <td>{stats.totalCount}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <hr />
                <h4>Moderators</h4>

                <Table bordered hover>
                  <thead>
                    <tr>
                      <th className="text-center">#</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th># done</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.batchContentModeration.moderators.map((moderator, idx) => (
                      <tr key={moderator.id}>
                        <td className="text-center">{idx + 1}</td>
                        <td>{moderator.user.displayName}</td>
                        <td>{moderator.user.emailCredential}</td>
                        <td>{moderator.totalDone}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                <hr />
                <h4>Error Reported</h4>
                <Table bordered hover>
                  <thead>
                    <tr>
                      <th>Picture</th>
                      <th>Ids</th>
                      <th>Title/Artist/Playlist</th>
                      <th>Sample</th>
                      <th>Moderator Note</th>
                      <th>Admin Review</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(moderatedContentData?.batchContentModeration.errorReportedContentItems.list ?? []).map(
                      (moderationContentItem) => (
                        <AdminContentModerationContentItem
                          key={moderationContentItem.id}
                          item={moderationContentItem}
                          moderator={moderationContentItem.moderator?.emailCredential ?? undefined}
                          onStatusUpdate={onAdminModerate}
                          onShowLyrics={onShowLyrics}
                          onShowBigPicture={onShowBigPicture}
                        />
                      )
                    )}
                  </tbody>
                </Table>
                <div className="d-flex justify-content-end mt-4">
                  <CustomPagination active={page} num={maxPage} onClick={onClickPagination} />
                </div>
              </>
            )}
          </>
        )}
        <Modal show={!!showBigPicture} onHide={() => onShowBigPicture(null)}>
          <Modal.Header closeButton />

          <Modal.Body>
            <Image width={450} src={showBigPicture ?? undefined} />
          </Modal.Body>
        </Modal>

        <ContentModerationLyricsModal
          show={!!showLyricsModal}
          onHide={handleClose}
          title={showLyricsModal?.title}
          artist={showLyricsModal?.artist}
        />
      </Card.Body>
    </Card>
  );
};

export default AdminContentModerationBatch;
