import _ from 'lodash';
import { FormEvent } from 'react';
import { Badge, Button, CloseButton, Form, InputGroup } from 'react-bootstrap';

export interface ListOfNamesInputProps {
  title: string;
  handleAddNames: (newNames: string[]) => void;
  handleRemoveName: (name: string) => void;
  currentValue: string;
  setCurrentValue: React.Dispatch<React.SetStateAction<string>>;
  names: string[];
  autocompleteNames?: string[];
  allowAddByModel?: boolean;
}

export function ListOfNamesInput({
  title,
  handleAddNames,
  handleRemoveName,
  currentValue,
  setCurrentValue,
  names,
  autocompleteNames = [],
  allowAddByModel = false,
}: ListOfNamesInputProps) {
  const typedValue = (currentValue || '').split('.')[0];
  const possibleModels = _.uniq(
    autocompleteNames.filter((s) => s.startsWith(typedValue)).map((s) => s.split('.')[0])
  ).sort();

  const addAllLabel =
    typedValue === ''
      ? 'Start typing to select queries by model'
      : possibleModels.length === 0
      ? `No model starting with ${typedValue}`
      : `Add all for model ${possibleModels[0]}`;

  const handleAddOne = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleAddNames([currentValue]);
  };

  const handleAddAll = () => {
    handleAddNames(autocompleteNames.filter((s) => s.startsWith(`${typedValue}.`)));
    setCurrentValue('');
  };

  return (
    <form onSubmit={handleAddOne}>
      <Form.Group className="mb-2 mt-3" controlId="formBasicPassword">
        <Form.Label>{title}</Form.Label>

        <InputGroup className="mb-3">
          <Form.Control
            value={currentValue}
            onChange={(e) => setCurrentValue(e.target.value)}
            list="autocomplete-names"
          />
          <datalist id="autocomplete-names">
            {autocompleteNames.map((value) => (
              <option value={value} key={value} />
            ))}
          </datalist>

          <Button className="ml-3" variant="primary" type="submit">
            Add query
          </Button>
          {allowAddByModel && (
            <Button
              className="ml-3"
              variant="primary"
              onClick={handleAddAll}
              disabled={typedValue === '' || possibleModels.length === 0}
            >
              {addAllLabel}
            </Button>
          )}
        </InputGroup>

        <div className="row">
          {names.map((name) => (
            <Badge pill variant="primary" key={name} className="d-flex mr-2 mt-1">
              <span className="mt-auto mb-auto mr-1 ml-1">{name}</span>{' '}
              <CloseButton onClick={() => handleRemoveName(name)} />
            </Badge>
          ))}
        </div>
      </Form.Group>
    </form>
  );
}
