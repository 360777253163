import { gql } from '@apollo/client';

const DDEX_JOB_FRAGMENT = gql`
  fragment DDEXJobFragment on DDEXJob {
    jobId
    created
    label
    nbExecutions
    path
    state
  }
`;

const DDEX_TASK_FRAGMENT = gql`
  fragment DDEXTaskFragment on DDEXTask {
    jobId
    taskId
    nbExecutions
    originalAssetUrl
    newAssetUrl
    state
    type
  }
`;

export const GET_DDEX_JOB = gql`
  query ddexJob($jobId: ID!) {
    ddexJob(jobId: $jobId) {
      ...DDEXJobFragment
    }
  }
  ${DDEX_JOB_FRAGMENT}
`;

export const GET_DDEX_JOBS = gql`
  query ddexJobs($pagination: PaginationParams) {
    ddexJobs(pagination: $pagination) {
      hasMore
      totalCount
      list {
        ...DDEXJobFragment
      }
    }
  }
  ${DDEX_JOB_FRAGMENT}
`;

export const GET_DDEX_JOB_TASKS = gql`
  query ddexJobTasks($jobId: ID!) {
    ddexJobTasks(jobId: $jobId) {
      ...DDEXTaskFragment
    }
  }
  ${DDEX_TASK_FRAGMENT}
`;

export const GET_DDEX_JOB_LOGS = gql`
  query ddexJobLogs($jobId: ID!, $pagination: PaginationParams) {
    analytics {
      ddexJobLogs(jobId: $jobId, pagination: $pagination) {
        totalCount
        list {
          timestamp
          message
          label
          jobId
          taskId
          level
          extraData
        }
      }
    }
  }
`;
