import React, { useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import { useRouteMatch } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';
import { AppRouteType, RouteType } from '../types';
import { UserRole } from '../__gqltypes__';
import './sidetoolbar.css';

const SideBarItem = ({ sideRoutes, role }: { sideRoutes: RouteType[]; role: UserRole }) => {
  const underRoutes = sideRoutes.find((sr) => !!sr.underRoutes);
  const match = useRouteMatch(underRoutes?.path ?? '');

  const [show, setShow] = useState(!!match);
  const handleShow = () => setShow(!show);
  return (
    <Nav className="overflow-auto">
      {sideRoutes
        .filter((r) => !!r.name)
        .filter((r) => (r.permission ?? [UserRole.ADMIN]).includes(role))
        .map((route) =>
          route.underRoutes ? (
            <div key={route.path}>
              <LinkContainer activeClassName="active" to={route.path}>
                <Nav.Item as="li">
                  <Nav.Link onClick={handleShow}>{route.name}</Nav.Link>
                </Nav.Item>
              </LinkContainer>
              <div className={`collapse${show ? ' show' : ''} m-2`}>
                <div className="bg-white collapse-inner rounded animated--grow-in">
                  {Object.keys(route.underRoutes.sp3).map((dashboard) => (
                    <LinkContainer activeClassName="active" key={dashboard} to={`${route.path}/${dashboard}`}>
                      <Nav.Item as="li">
                        <Nav.Link className="text-dark sidetoolbar w-100" href={`${route.path}/${dashboard}`}>
                          {route.underRoutes?.sp3[dashboard].title ?? dashboard}
                        </Nav.Link>
                      </Nav.Item>
                    </LinkContainer>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <LinkContainer key={route.path} activeClassName="active" exact={route.exact} to={route.href ?? route.path}>
              <Nav.Item as="li">
                <LinkContainer to={route.href ?? route.path}>
                  <Nav.Link>{route.name}</Nav.Link>
                </LinkContainer>
              </Nav.Item>
            </LinkContainer>
          )
        )}
    </Nav>
  );
};

const compStyles = {
  main: { height: '100%' },
  sidebarOverride: { minHeight: '0', height: '100%' },
};

const SideToolbar = ({ routes, role }: { routes: AppRouteType[]; role: UserRole }) => {
  const children = routes
    .filter((route) => window.location.pathname.split('/')[2] === route.sideRoutes[0].path.split('/')[2])
    .filter((route) => route.permission.includes(role))
    .map(({ name, sideRoutes }) => <SideBarItem key={name} sideRoutes={sideRoutes} role={role} />);

  return (
    <div style={compStyles.main}>
      <ul className="navbar-nav bg-info sidebar sidebar-dark accordion fixed-left" style={compStyles.sidebarOverride}>
        <span className="sidebar-brand d-flex align-items-center justify-content-center">
          <div className="sidebar-brand-icon rotate-n-15">
            <i className="fas fa-cogs" />
          </div>
          <div className="sidebar-brand-text mx-3">SubTools</div>
        </span>
        <hr className="sidebar-divider" />
        {children}
      </ul>
    </div>
  );
};

export default SideToolbar;
