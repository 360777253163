import _ from 'lodash';
import moment from 'moment';

export function applyChangeIfDateValid(applyMethod: ((value: Date) => any) | ((value: moment.Moment) => any)) {
  return (date: string | moment.Moment) => {
    if (!_.isString(date)) {
      // @ts-ignore
      applyMethod(date);
    }
  };
}

export function validIfSameDayOrAfter(selectedDate: moment.Moment) {
  return selectedDate.isSameOrAfter(moment().startOf('day'));
}
