import { useCallback, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useMutation, useQuery } from '@apollo/client';
import { Alert, Button, Card, Col, Container, Form, Modal, Row, Spinner, Table } from 'react-bootstrap';
import DateTimePicker from 'react-datetime';
import { applyChangeIfDateValid, validIfSameDayOrAfter } from '../../../../../../utils/datepicker';
import {
  DeleteSaleEventMutation,
  DeleteSaleEventMutationVariables,
  GetAppShopBundlesSalesQuery,
  GetAppShopBundlesSalesQueryVariables,
  GetLiveOpsSaleEventConfigQuery,
  GetLiveOpsSaleEventConfigQueryVariables,
  PurchaseLimitEnum,
  SaleBundleConfig,
  SaleConfig,
  SaleEventConfig,
  SaleEventStatus,
  SaleTagType,
  UpdateSaleBundleInput,
  UpdateSaleEventMutation,
  UpdateSaleEventMutationVariables,
  UpdateSaleInput,
  UserSubscriptionTier,
} from '../../../../../../__gqltypes__';
import { LoadingLogo } from '../../../../devtools/components/Modal';
import { SaleRow } from './Sale';
import { AppSalesConfig } from '../typedef';
import {
  APP_SHOP_BUNDLES_SALES,
  DELETE_SALE_EVENT,
  LIVEOPS_SALE_EVENT_CONFIG_QUERY,
  UPDATE_SALE_EVENT,
  UPLOAD_SALE_EVENT_ASSET,
} from '../graphql';
import { getSaleDiscount, stringToEnum } from '../utils';
import AssetDisplay from '../../Items/components/AssetDisplay';
import DragUpload from '../../Items/components/DragUpload';
import { BundleRow } from './Bundle';
import { DailyDeal } from './DailyDeals';
import { Item } from '../../Items/components/typedef';

type Props = {
  saleEventId: string;
  appSalesConfig: AppSalesConfig;
  // updateSaleState: (saleEventId: string, saleId: string, updatedSale: SaleConfig) => void;
  onUpsertSaleEvent: (value: SaleEventConfig) => void;
  onRemoveSaleEvent: (_saleEventId: string) => void;
};

export enum SaleEventAssetType {
  BACKGROUND = 'BACKGROUND',
  CHARACTER = 'CHARACTER',
}

export enum BUNDLE_TAGS {
  NEW = 'saleEvent.saleTags.NEW',
  SALE = 'saleEvent.saleTags.SALE',
  DEAL = 'saleEvent.saleTags.DEAL',
  BONUS = 'saleEvent.saleTags.BONUS',
  SPECIAL = 'saleEvent.saleTags.SPECIAL',
  BIG_DEAL = 'saleEvent.saleTags.BIG DEAL',
  BEST_VALUE = 'saleEvent.saleTags.BEST VALUE',
}

export enum BUNDLE_NAMES {
  LARGE = 'saleEvent.saleBundles.offer3',
  MEDIUM = 'saleEvent.saleBundles.offer2',
  SMALL = 'saleEvent.saleBundles.offer1',
}

const getEnumKeyByValue = (objEnum: Record<string, string>, val: string) => {
  return Object.keys(objEnum)[Object.values(objEnum).indexOf(val)];
};

export function SaleEventData({ saleEventId, appSalesConfig, onUpsertSaleEvent, onRemoveSaleEvent }: Props) {
  /**
   * State
   */
  // Currently displayed specialEvent
  /**
   * @type {[SaleEventConfig, Function]} Currently displayed SaleEvent
   */
  const [name, setName] = useState('');
  const [status, setStatus] = useState(SaleEventStatus.DRAFT);
  const [filterSubscriptionTier, setFilterSubscriptionTier] = useState<UserSubscriptionTier | undefined>(
    UserSubscriptionTier.BASIC
  );
  const [filterUserIds, setFilterUserIds] = useState<string>('');
  const [filterUserMinCreationDate, setFilterUserMinCreationDate] = useState<Date | undefined>();
  const [filterUserMaxCreationDate, setFilterUserMaxCreationDate] = useState<Date | undefined>();
  const [filterAppVersion, setFilterAppVersion] = useState<string | undefined>();
  const [filterUserLevel, setFilterUserLevel] = useState<number | undefined>();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [stopDate, setStopDate] = useState<Date | null>(null);
  const [priority, setPriority] = useState<number>(1);
  const [readonly, setReadonly] = useState(true);
  const [sales, setSales] = useState<Array<SaleConfig & { itemId?: string }>>([]);
  const [bundles, setBundles] = useState<SaleBundleConfig[]>([]);
  const [deals, setDeals] = useState<Partial<Item>[]>([]);
  // pop-up state management ----------
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showBackgroundImageDeletionWarning, setShowBackgroundImageDeletionWarning] = useState(false);
  const [showCharacterImageDeletionWarning, setShowCharacterImageDeletionWarning] = useState(false);

  const [assetsConfig, setAssetsConfig] = useState<
    NonNullable<GetLiveOpsSaleEventConfigQuery['liveOps']['saleEventConfig']>['assetsConfig']
  >({
    __typename: 'SaleEventAssetsConfig',
  });

  /**
   * FETCHING
   */
  const {
    data,
    loading: loadingSaleEvent,
    refetch,
  } = useQuery<GetLiveOpsSaleEventConfigQuery, GetLiveOpsSaleEventConfigQueryVariables>(
    LIVEOPS_SALE_EVENT_CONFIG_QUERY,
    {
      fetchPolicy: 'no-cache',
      variables: { saleEventId },
    }
  );

  const bundleSales =
    useQuery<GetAppShopBundlesSalesQuery, GetAppShopBundlesSalesQueryVariables>(APP_SHOP_BUNDLES_SALES).data?.liveOps
      .bundleSales ?? [];

  const [updateSaleEvent, { loading: updateLoading }] = useMutation<
    UpdateSaleEventMutation,
    UpdateSaleEventMutationVariables
  >(UPDATE_SALE_EVENT, {
    onCompleted: ({ updateSaleEvent: _updateSaleEvent }) => {
      const updatedSaleEvent = _updateSaleEvent.saleEvent;
      // @ts-ignore
      onUpsertSaleEvent(updatedSaleEvent);
      setShowBackgroundImageDeletionWarning(false);
      setShowCharacterImageDeletionWarning(false);
      refetch();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const [deleteSaleEvent, { loading: deleteLoading }] = useMutation<
    DeleteSaleEventMutation,
    DeleteSaleEventMutationVariables
  >(DELETE_SALE_EVENT, {
    onCompleted: ({ deleteSaleEvent: _deleteSaleEvent }) => {
      if (!_deleteSaleEvent.saleEventIdDeleted) {
        alert("ERROR: Couldn't remove the current specialEvent");
        return;
      }
      onRemoveSaleEvent(saleEventId);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const [uploadBackgroundAsset, { loading: uploadBackgroundLoading }] = useMutation(UPLOAD_SALE_EVENT_ASSET, {
    onCompleted: ({ uploadSaleEventAsset: { saleEvent } }) => {
      setAssetsConfig({ ...assetsConfig, backgroundImage: saleEvent.assetsConfig.backgroundImage });
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const [uploadCharacterAsset, { loading: uploadCharacterLoading }] = useMutation(UPLOAD_SALE_EVENT_ASSET, {
    onCompleted: ({ uploadSaleEventAsset: { saleEvent } }) => {
      setAssetsConfig({ ...assetsConfig, characterImage: saleEvent.assetsConfig.characterImage });
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const loading = loadingSaleEvent || updateLoading || deleteLoading;

  const resetMinutes = (date: any) => {
    const newDate = new Date(date);
    newDate.setMinutes(0);
    return newDate;
  };

  function userIdsStringToArray(songIdsString: string): string[] | undefined {
    if (typeof songIdsString === 'string' && songIdsString.trim().length > 0) {
      return songIdsString.split(',').map((id) => id.trim());
    }
    return undefined;
  }

  function userIdsArrayToString(userIdsArray: string[] | string): string {
    if (Array.isArray(userIdsArray)) {
      return userIdsArray.join(', ');
    }
    return userIdsArray;
  }

  const handleBackgroundImageUpload = useCallback(
    (file) => {
      uploadBackgroundAsset({
        variables: {
          input: {
            file,
            saleEventId,
            type: SaleEventAssetType.BACKGROUND,
          },
        },
      });
    },
    [saleEventId, uploadBackgroundAsset]
  );

  const handleCharacterImageUpload = useCallback(
    (file) => {
      uploadCharacterAsset({
        variables: {
          input: {
            file,
            saleEventId,
            type: SaleEventAssetType.CHARACTER,
          },
        },
      });
    },
    [saleEventId, uploadCharacterAsset]
  );

  /**
   * STATE AUTO UPDATE FUNCTIONS
   */
  // set current specialEvent to the one fetched
  useEffect(() => {
    if (data && data.liveOps.saleEventConfig) {
      const fetchedSaleEvent = data.liveOps.saleEventConfig;
      setName(fetchedSaleEvent.name);
      setStatus(fetchedSaleEvent.status);
      setFilterSubscriptionTier(fetchedSaleEvent.filterSubscriptionTier ?? undefined);
      setFilterUserMinCreationDate(
        fetchedSaleEvent.filterUserMinCreationDate
          ? resetMinutes(fetchedSaleEvent.filterUserMinCreationDate)
          : undefined
      );
      setFilterUserMaxCreationDate(
        fetchedSaleEvent.filterUserMaxCreationDate
          ? resetMinutes(fetchedSaleEvent.filterUserMaxCreationDate)
          : undefined
      );
      setFilterAppVersion(fetchedSaleEvent.filterAppVersion ?? undefined);
      setFilterUserLevel(fetchedSaleEvent.filterUserLevel ?? undefined);
      setFilterUserIds(userIdsArrayToString(fetchedSaleEvent.filterUserIds ?? []));
      setStartDate(resetMinutes(fetchedSaleEvent.startDate));
      setStopDate(resetMinutes(fetchedSaleEvent.stopDate));
      setPriority(fetchedSaleEvent.priority);
      setDeals(fetchedSaleEvent.dailyItems as Partial<Item>[]);
      setSales(
        fetchedSaleEvent.sales.map((sale) =>
          ['vinyl', 'frame', 'sticker'].includes((sale.saleProductId as string)?.split('.')[0])
            ? {
                ...sale,
                itemId: sale.targetedProductId ?? undefined,
                targetedProductId: sale.saleProductId?.split('.').slice(0, 2).join('.'),
              }
            : sale
        )
      );
      setBundles(
        fetchedSaleEvent.packs.map((pack) => ({
          ...pack,
          name: pack.name ? getEnumKeyByValue(BUNDLE_NAMES, pack.name) : undefined,
          tag: pack.tag ? getEnumKeyByValue(BUNDLE_TAGS, pack.tag) : undefined,
        }))
      );
      setReadonly(fetchedSaleEvent.isHardCoded);
      setAssetsConfig(fetchedSaleEvent.assetsConfig);
    }
  }, [data]);

  /**
   * CHANGES HANDLERS FUNCTIONS
   */

  const handleSave = (newBundles?: SaleBundleConfig[]) => {
    const updateSales: UpdateSaleInput[] = sales.map((sale) => ({
      id: sale.id,
      name: sale.name,
      ruleMaximumNumberOfClaim:
        !sale.purchaseLimitType || sale.purchaseLimitType === PurchaseLimitEnum.UNLIMITED
          ? null
          : sale.ruleMaximumNumberOfClaim,
      purchaseLimitType: sale.purchaseLimitType,
      saleProductId: sale.itemId
        ? `${sale.targetedProductId}.${sale.offerPercentage}.${sale.oldPrice}`
        : sale.saleProductId,
      startDate: sale.startDate,
      stopDate: sale.stopDate,
      tag: sale.tag,
      targetedProductId: sale.itemId ?? sale.targetedProductId,
    })) as UpdateSaleInput[];
    const updateBundles: UpdateSaleBundleInput[] = (newBundles ?? bundles).map((bundle) => ({
      id: bundle.id,
      name: bundle.name ? stringToEnum(bundle.name, BUNDLE_NAMES) : undefined,
      ruleMaximumNumberOfClaim: bundle.ruleMaximumNumberOfClaim,
      purchaseLimitType: bundle.purchaseLimitType,
      backgroundImage: bundle.backgroundImage,
      skuId: bundle.skuId,
      offerPercentage: bundle.offerPercentage,
      displayTimer: bundle.displayTimer,
      tag: !bundle.tag || bundle.tag === 'None' ? undefined : stringToEnum(bundle.tag, BUNDLE_TAGS),
      items: bundle.items.map((item) => {
        return { itemId: item.itemId, type: item.type, amount: item.amount };
      }),
    })) as UpdateSaleBundleInput[];

    updateSaleEvent({
      variables: {
        input: {
          id: saleEventId,
          name,
          startDate,
          stopDate,
          priority,
          status,
          sales: updateSales,
          packs: updateBundles,
          dailyItems: deals.map((deal) => deal.id) as string[],
          filterSubscriptionTier,
          filterUserMinCreationDate,
          filterUserMaxCreationDate,
          filterUserIds: userIdsStringToArray(filterUserIds),
          filterAppVersion,
          filterUserLevel,
          assetsConfig: {
            backgroundImage: assetsConfig.backgroundImage,
            characterImage: assetsConfig.characterImage,
          },
        },
      },
    });
  };

  const handleDeleteSaleEvent = () => {
    deleteSaleEvent({
      variables: {
        input: {
          id: saleEventId,
        },
      },
    });
  };

  const handleChangeName = useCallback((event) => {
    setName(event.target.value);
  }, []);

  const handleChangePlayerIds = useCallback((event) => {
    setFilterUserIds(event.target.value);
  }, []);

  const handleChangeAppVersion = useCallback((event) => {
    setFilterAppVersion(event.target.value);
  }, []);

  const handleChangeUserLevel = useCallback((event) => {
    setFilterUserLevel(!Number.isNaN(+event.target.value) ? +event.target.value : undefined);
  }, []);

  const handleChangePriority = useCallback((event) => {
    setPriority(+event.target.value);
  }, []);

  const handleChangeStatus = useCallback((event) => {
    setStatus(event.currentTarget.value as SaleEventStatus);
  }, []);

  const handleChangeSubscriberStatus = useCallback((event) => {
    setFilterSubscriptionTier(
      event.currentTarget.value === 'All' ? undefined : (event.currentTarget.value as UserSubscriptionTier)
    );
  }, []);

  const handleAddSale = () => {
    const initialTargetedProduct = appSalesConfig.targetedProducts[0];
    const newSale: SaleConfig = {
      __typename: 'SaleConfig',
      id: uuid(),
      oldPrice: -1,
      newPrice:
        'cost' in initialTargetedProduct.saleConfigs[0] &&
        typeof initialTargetedProduct.saleConfigs[0].cost !== 'number'
          ? initialTargetedProduct.saleConfigs[0].cost?.amount
          : -1,
      offerPercentage: getSaleDiscount(initialTargetedProduct.id),
      saleProductId: initialTargetedProduct.saleConfigs[0].id,
      targetedProductId: initialTargetedProduct.id,
      purchaseLimitType: PurchaseLimitEnum.UNLIMITED,
      ruleMaximumNumberOfClaim: undefined,
      tag: SaleTagType.SALE,
    };
    setSales((oldSales) => [...oldSales, newSale]);
  };

  const handleAddDeal = () => {
    const newDeal: Partial<Item> = {
      id: '',
    };
    setDeals((oldDeals) => [...oldDeals, newDeal]);
  };

  const handleAddBundle = () => {
    const newBundle: SaleBundleConfig = {
      __typename: 'SaleBundleConfig',
      id: uuid(),
      skuId: bundleSales[0].id,
      offerPercentage: null,
      backgroundImage: null,
      displayTimer: true,
      items: [],
      purchaseLimitType: PurchaseLimitEnum.PER_SALE,
      ruleMaximumNumberOfClaim: 1,
    };
    setBundles((oldBundles) => {
      const newBundles = [...oldBundles, newBundle];
      handleSave(newBundles);
      return newBundles;
    });
  };

  const handleSaleUpdate = (updatedSale: SaleConfig) => {
    setSales((oldSales) => {
      const newSales = oldSales.map((sale) => {
        if (sale.id === updatedSale.id) {
          return updatedSale;
        }
        return sale;
      });
      return newSales;
    });
    return updatedSale;
  };

  const handleDealUpdate = (updatedDeal: Partial<Item>, index: number) => {
    setDeals((oldDeals) => {
      const newDeals = [...oldDeals];
      newDeals.splice(index, 1, updatedDeal);
      return newDeals;
    });
    return updatedDeal;
  };

  const handleBundleUpdate = (updatedBundle: SaleBundleConfig) => {
    setBundles((oldBundles) => {
      const newBundles = oldBundles.map((bundle) => {
        if (bundle.id === updatedBundle.id) {
          return updatedBundle;
        }
        return bundle;
      });
      return newBundles;
    });
    return updatedBundle;
  };

  const handleSaleDelete = (deleteSale: SaleConfig) => {
    setSales((oldSales) => oldSales.filter((sale) => sale.id !== deleteSale.id));
    return deleteSale;
  };

  const handleDealDelete = (index: number) => {
    setDeals((oldDeals) => {
      const newDeals = [...oldDeals];
      newDeals.splice(index, 1);
      return newDeals;
    });
  };

  const handleBundleDelete = (deleteBundle: SaleBundleConfig) => {
    setBundles((oldBundles) => oldBundles.filter((bundle) => bundle.id !== deleteBundle.id));
    return deleteBundle;
  };

  return loading ? (
    <LoadingLogo show />
  ) : (
    <>
      <Container className="pb-4">
        <Row className="mb-3">
          <Col md={3}>Id:</Col>
          <Col md={5}>{saleEventId}</Col>
          <Col className="d-flex justify-content-end">
            <Button
              className="mr-2"
              onClick={() => {
                setShowDeleteModal(true);
              }}
              variant="danger"
              disabled={readonly}
            >
              <i className="fas fa-trash mr-2" />
              Delete
            </Button>
            {readonly || (
              <Button onClick={() => handleSave()} variant="success" type="submit">
                Save
              </Button>
            )}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={3}>Name:</Col>
          <Col md={5}>
            <Form.Control disabled={readonly} value={name} onChange={handleChangeName} type="text" />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={3}>Start date:</Col>
          <Col md={5}>
            {readonly ? (
              startDate?.toLocaleString()
            ) : (
              <DateTimePicker
                dateFormat="YYYY-MM-DD"
                timeFormat={true}
                timeConstraints={{ minutes: { min: 0, max: 0, step: 0 } }}
                // @ts-ignore
                onChange={applyChangeIfDateValid(setStartDate)}
                isValidDate={validIfSameDayOrAfter}
                // @ts-ignore
                value={startDate ?? undefined}
              />
            )}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={3}>Stop date:</Col>
          <Col md={5}>
            {readonly ? (
              stopDate?.toLocaleString()
            ) : (
              <DateTimePicker
                dateFormat="YYYY-MM-DD"
                timeFormat={true}
                timeConstraints={{ minutes: { min: 0, max: 0, step: 0 } }}
                // @ts-ignore
                onChange={applyChangeIfDateValid(setStopDate)}
                isValidDate={validIfSameDayOrAfter}
                // @ts-ignore
                value={stopDate ?? undefined}
              />
            )}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={3}>Priority:</Col>
          <Col md={2}>
            <Form.Group controlId="sale-event-status">
              <Form.Control
                as="select"
                disabled={readonly}
                type="text"
                value={priority}
                onChange={handleChangePriority}
              >
                {Array.from({ length: 10 }, (_, i) => i + 1).map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={3}>Status:</Col>
          <Col md={2}>
            <Form.Group controlId="sale-event-status">
              <Form.Control as="select" disabled={readonly} type="text" value={status} onChange={handleChangeStatus}>
                {Object.keys(SaleEventStatus).map((option) => (
                  <option key={option} value={stringToEnum(option, SaleEventStatus)}>
                    {option}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <hr />
        <h4>Matchers:</h4>

        <Row className="mb-3">
          <Col md={3}>Minimum Registration Date:</Col>
          <Col md={5}>
            {readonly ? (
              filterUserMinCreationDate?.toLocaleString()
            ) : (
              <DateTimePicker
                dateFormat="YYYY-MM-DD"
                timeFormat={true}
                timeConstraints={{ minutes: { min: 0, max: 0, step: 0 } }}
                // @ts-ignore
                onChange={applyChangeIfDateValid(setFilterUserMinCreationDate)}
                // @ts-ignore
                value={filterUserMinCreationDate ?? undefined}
              />
            )}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={3}>Maximum Registration Date:</Col>
          <Col md={5}>
            {readonly ? (
              filterUserMaxCreationDate?.toLocaleString()
            ) : (
              <DateTimePicker
                dateFormat="YYYY-MM-DD"
                timeFormat={true}
                timeConstraints={{ minutes: { min: 0, max: 0, step: 0 } }}
                // @ts-ignore
                onChange={applyChangeIfDateValid(setFilterUserMaxCreationDate)}
                // @ts-ignore
                value={filterUserMaxCreationDate ?? undefined}
              />
            )}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={3}>Min App Version:</Col>
          <Col md={5}>
            <Form.Control type="text" disabled={readonly} value={filterAppVersion} onChange={handleChangeAppVersion} />
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={3}>Min User Level:</Col>
          <Col md={5}>
            <Form.Control type="text" disabled={readonly} value={filterUserLevel} onChange={handleChangeUserLevel} />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={3}>User Subscription Status:</Col>
          <Col md={2}>
            <Form.Group controlId="sale-event-subscriber-status">
              <Form.Control
                as="select"
                disabled={readonly}
                type="text"
                value={filterSubscriptionTier}
                onChange={handleChangeSubscriberStatus}
              >
                <option>All</option>
                {Object.keys(UserSubscriptionTier).map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={3}>
            User Ids: <br />
            (separated by commas &quot;,&quot;)
          </Col>
          <Col md={8}>
            <Form.Control
              as="textarea"
              disabled={readonly}
              value={filterUserIds.toString()}
              onChange={handleChangePlayerIds}
            />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <Form.Label> Background Image </Form.Label>
            {assetsConfig && assetsConfig.backgroundImage && <AssetDisplay assetUrl={assetsConfig.backgroundImage} />}
            <DragUpload onUpload={handleBackgroundImageUpload} />
            <LoadingLogo show={uploadBackgroundLoading} />
            <Button
              disabled={readonly}
              className="mt-2"
              onClick={() => {
                setAssetsConfig({ ...assetsConfig, backgroundImage: '' });
                setShowBackgroundImageDeletionWarning(true);
              }}
              variant="danger"
            >
              <i className="fas fa-trash mr-2" />
              Delete
            </Button>
            {showBackgroundImageDeletionWarning && (
              <Alert variant="danger">Click on Save to confirm the deletion of an image</Alert>
            )}
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <Form.Label> Character Image </Form.Label>
            {assetsConfig && assetsConfig.characterImage && <AssetDisplay assetUrl={assetsConfig.characterImage} />}
            <DragUpload onUpload={handleCharacterImageUpload} />
            <LoadingLogo show={uploadCharacterLoading} />
            <Button
              disabled={readonly}
              className="mt-2"
              onClick={() => {
                setAssetsConfig({ ...assetsConfig, characterImage: '' });
                setShowCharacterImageDeletionWarning(true);
              }}
              variant="danger"
            >
              <i className="fas fa-trash mr-2" />
              Delete
            </Button>
            {showCharacterImageDeletionWarning && (
              <Alert variant="danger">Click on Save to confirm the deletion of an image</Alert>
            )}
          </Col>
        </Row>
        <hr />
        <Card className="mt-3 mb-3">
          <Card.Header className="text-center">
            <Row className="justify-content-between">
              <Card.Title>High Priority Daily Items</Card.Title>
              <Button disabled={readonly} variant="success" className="align-self-center" onClick={handleAddDeal}>
                Add an Item
              </Button>
              <Button disabled={readonly} className="align-self-center ml-1" onClick={() => handleSave()}>
                Save Changes
              </Button>
            </Row>
          </Card.Header>
          <Card.Body>
            {loading || sales == null ? (
              <Spinner animation="border" />
            ) : (
              <>
                <Table>
                  <thead className="text-center">
                    <tr>
                      <th>Product Type</th>
                      <th>Item ID</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {deals.map((deal, dealIndex: number) => (
                      <DailyDeal
                        key={`${deal.id}-${dealIndex}`}
                        dealIndex={dealIndex}
                        fetchedDeal={deal}
                        handleDealUpdate={handleDealUpdate}
                        handleDealDelete={handleDealDelete}
                      />
                    ))}
                  </tbody>
                </Table>
                <hr />
              </>
            )}
          </Card.Body>
        </Card>
        <hr />
        <Card className="mt-3 mb-3">
          <Card.Header className="text-center">
            <Row className="justify-content-between">
              <Card.Title>Sales</Card.Title>
              <Button disabled={readonly} variant="success" className="align-self-center" onClick={handleAddSale}>
                Add a Sale
              </Button>
              <Button disabled={readonly} className="align-self-center ml-1" onClick={() => handleSave()}>
                Save Changes
              </Button>
            </Row>
          </Card.Header>
          <Card.Body>
            {loading || sales == null ? (
              <Spinner animation="border" />
            ) : (
              <>
                <Table>
                  <thead className="text-center">
                    <tr>
                      <th>Target Product</th>
                      <th>Purchase Limits</th>
                      <th>Discount</th>
                      <th>Old Price</th>
                      <th>New Price</th>
                      {/* <th>Tag</th> Not implemented yet */}
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {sales.map((sale, saleIndex: number) => (
                      <SaleRow
                        key={`${sale.id}-${saleIndex}`}
                        fetchedSale={sale}
                        readonly={readonly}
                        appSalesConfig={appSalesConfig}
                        handleSaleUpdate={handleSaleUpdate}
                        handleSaleDelete={handleSaleDelete}
                      />
                    ))}
                  </tbody>
                </Table>
                <hr />
              </>
            )}
          </Card.Body>
        </Card>
        <hr />
        <Card className="mt-3 mb-3">
          <Card.Header className="text-center">
            <Row className="justify-content-between">
              <Card.Title>Sale Bundles</Card.Title>
              <Button disabled={readonly} variant="success" className="align-self-center" onClick={handleAddBundle}>
                Add a Sale Bundle
              </Button>
              <Button disabled={readonly} className="align-self-center ml-1" onClick={() => handleSave()}>
                Save Changes
              </Button>
            </Row>
          </Card.Header>
          <Card.Body>
            {loading || sales == null ? (
              <Spinner animation="border" />
            ) : (
              <>
                <Table>
                  <tbody className="text-center">
                    {bundles &&
                      bundles.map((bundle, bundleIndex: number) => (
                        <BundleRow
                          key={`${bundle.id}-${bundleIndex}`}
                          bundleSales={bundleSales}
                          fetchedBundle={bundle}
                          bundleIndex={bundleIndex}
                          saleEventId={saleEventId}
                          handleBundleUpdate={handleBundleUpdate}
                          handleBundleDelete={handleBundleDelete}
                        />
                      ))}
                  </tbody>
                </Table>
                <hr />
              </>
            )}
          </Card.Body>
        </Card>
      </Container>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>SaleEvent Removal</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>This cannot be undone. Are you sure to want to remove this Sale Event?</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              setShowDeleteModal(false);
              handleDeleteSaleEvent();
            }}
          >
            Remove Sale Event
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
