import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import _ from 'lodash';
import colors from '../../../../../../utils/dashboards/models/widgets/colors.json';
import RequestCard from './RequestCard';
import { ImportantEvent, MetricTimestamp, MetricType } from '../../../../../../__gqltypes__';
import ChartController, { DisplayOn } from '../logic/ChartsController.ts/ChartsController';
import { AdditionalMeasureName } from '../logic/utils';
import EventLegendItem from './EventLegendItem';
import { eventColors } from '../../ImportantEvents/utils';
import ImportantEventCard from '../../ImportantEvents/components/ImportantEventCard';
import { SliderController } from '../logic/ChartsController.ts/SliderController';

type PartialNumericAggregatedData = {
  datetime: Date;
  average: number;
  P95: number;
  P99: number;
  P100: number;
  count: number;
  std: number;
};
interface Event extends ImportantEvent {
  datetime: Date;
  label: string;
}
type NumericField = Exclude<keyof PartialNumericAggregatedData, 'datetime'>;
type Serie = {
  title: string;
  bytesProcessed: number;
  start: string;
  end: string;
  timestamp: MetricTimestamp;
  datasets: { label: string; data: PartialNumericAggregatedData[] }[];
};
type TimeLineGraphProps = {
  serie: Serie;
  previousSerie: Serie | null;
  events: Event[];
  type: MetricType;
};

/**
 * Create series for each metric we are interested in, with some options as if it should be always displayed,
 * or the line width, ...
 *
 * @param serie
 * @param previousSerie
 * @returns
 */
function prepareDatasets({
  serie,
  previousSerie,
  onlyRequest,
  additionalMeasures = [],
}: {
  serie: Serie;
  previousSerie: Serie | null;
  onlyRequest: string | null;
  additionalMeasures?: AdditionalMeasureName[];
}) {
  const getSerie = (
    dataset: PartialNumericAggregatedData[],
    field: NumericField,
    borderWidth: number,
    displayOn: DisplayOn = DisplayOn.ALWAYS,
    previous = false
  ) => {
    const data = dataset.map((dataPoint) => ({ x: dataPoint.datetime, y: Math.round(dataPoint[field]) }));
    return { name: `${field}${previous ? ' - previous' : ''}`, data, borderWidth, displayOn, previous };
  };

  const getPreviousSerie = (
    label: string,
    field: NumericField,
    borderWidth: number,
    displayOn: DisplayOn = DisplayOn.ALWAYS
  ) => {
    const data = previousSerie?.datasets?.find((ds) => ds.label === label)?.data || [];
    return getSerie(data, field, borderWidth, displayOn, true);
  };

  const countSeries = serie.datasets.map(({ label, data }, index) => [
    label,
    { index, measures: [getSerie(data, 'count', 3), getPreviousSerie(label, 'count', 1, DisplayOn.ALWAYS)] },
  ]);

  const timeSeries = serie.datasets.map(({ label, data }, index) => [
    label,
    {
      index,
      measures: [
        getSerie(data, 'average', 3),
        getPreviousSerie(label, 'average', 1),
        ...(additionalMeasures.includes(AdditionalMeasureName.P95) ? [getSerie(data, 'P95', 2)] : []),
        ...(additionalMeasures.includes(AdditionalMeasureName.P99) ? [getSerie(data, 'P99', 1.5)] : []),
        ...(additionalMeasures.includes(AdditionalMeasureName.P100) ? [getSerie(data, 'P100', 1)] : []),
      ],
    },
  ]);

  return {
    time: Object.fromEntries(onlyRequest ? timeSeries.filter(([label]) => label === onlyRequest) : timeSeries),
    count: Object.fromEntries(onlyRequest ? countSeries.filter(([label]) => label === onlyRequest) : countSeries),
  };
}

/**
 * A component to render 2 graphs: execution time and number of request, function of the date
 * and to react when user hover, focus, ... any of the requests
 */
export default function TimeLineGraph({ serie, previousSerie, events, type }: TimeLineGraphProps) {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const canvas2Ref = useRef<HTMLCanvasElement | null>(null);
  const canvas3Ref = useRef<HTMLCanvasElement | null>(null);
  const canvas4Ref = useRef<HTMLCanvasElement | null>(null);
  const graphRef = useRef<ChartController | null>(null);
  const sliderRef = useRef<SliderController | null>(null);

  const [focusedGraph, setFocusedRequest] = useState<string | null>(null);
  const [focusedEvents, setFocusedEvents] = useState<string[]>([]);
  const [additionalMeasures, setAdditionalMeasures] = useState<AdditionalMeasureName[]>([]);
  const [timeSelection, setTimeSelection] = useState<{ start: Date; end: Date }>({
    start: moment(serie.start).toDate(),
    end: moment(serie.end).toDate(),
  });

  const handleFocusEvent = (eventKey: string) => setFocusedEvents((pFocusedEvents) => [...pFocusedEvents, eventKey]);
  const handleBlurEvent = (eventKey: string) =>
    setFocusedEvents((pFocusedEvents) => pFocusedEvents.filter((evtKey) => evtKey !== eventKey));

  const preparedDatasets = useMemo(() => {
    return prepareDatasets({ serie, previousSerie, onlyRequest: focusedGraph, additionalMeasures });
  }, [serie, previousSerie, focusedGraph, additionalMeasures]);

  useEffect(() => {
    if (canvasRef.current && canvas4Ref.current) {
      if (graphRef.current !== null) {
        graphRef.current.destroy();
      }
      if (sliderRef.current !== null) {
        sliderRef.current.destroy();
      }

      sliderRef.current = new SliderController({
        canvas: canvas4Ref,
        currentTimeBoundaries: { start: serie.start, end: serie.end },
        onSelectionChange: setTimeSelection,
      });

      graphRef.current = new ChartController(
        preparedDatasets,
        { time: canvasRef, count: canvas2Ref, events: canvas3Ref },
        {
          timestamp: serie.timestamp,
          slider: sliderRef,
          title: serie.title,
          type,
          currentTimeBoundaries: { start: serie.start, end: serie.end },
          previousTimeBoundaries: previousSerie ? { start: previousSerie.start, end: previousSerie.end } : undefined,
          events,
          onFocusOrBlurEvent: (focus, eventKey) => {
            console.log(focus, eventKey);
            if (focus) handleFocusEvent(eventKey);
            else handleBlurEvent(eventKey);
          },
        }
      );

      return () => {
        sliderRef.current?.destroy();
      };
    }
    return () => {};
  }, [serie, previousSerie, canvasRef, events, preparedDatasets, type]);

  return (
    <Row>
      <Col sm="8">
        <div className="w-100 m-2 position-relative" style={{ height: '100px' }}>
          <canvas ref={canvas3Ref} />
        </div>
        <div className="w-100 m-2 position-relative" style={{ height: 'calc(50vh - 140px)' }}>
          <canvas ref={canvasRef} />
        </div>
        <div className="w-100 m-2 position-relative" style={{ height: 'calc(20vh - 30px)' }}>
          <canvas ref={canvas2Ref} />
        </div>
        <div className="w-100 m-2 position-relative" style={{ height: '50px' }}>
          <canvas ref={canvas4Ref} />
        </div>
      </Col>
      <Col sm="4">
        <div className="w-100" style={{ height: 'calc(70vh - 100px + 1.5rem)' }}>
          <Tab.Container defaultActiveKey="requests" id="legend-tab">
            <Row className="m-0">
              <Nav variant="pills" defaultActiveKey="requests">
                <Nav.Item>
                  <Nav.Link eventKey="requests">Requests</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="events">Events</Nav.Link>
                </Nav.Item>
              </Nav>
            </Row>
            <Tab.Content>
              <Tab.Pane eventKey="requests">
                <div
                  className="w-100 mt-4 pr-3 position-relative overflow-auto"
                  style={{ maxHeight: 'calc(70vh - 170px + 1.5rem)', height: 'calc(70vh - 100px + 1.5rem)' }}
                >
                  {' '}
                  {serie.datasets.map((dataset, index) => (
                    <RequestCard
                      key={dataset.label}
                      color={colors[index % colors.length]}
                      requestDataset={dataset}
                      isPrevious={previousSerie !== null}
                      timeSelection={timeSelection}
                      type={type}
                      onFocus={() => {
                        setFocusedRequest(dataset.label);
                        setAdditionalMeasures([AdditionalMeasureName.P95]);
                      }}
                      onBlur={() => {
                        setFocusedRequest(null);
                        setAdditionalMeasures([]);
                      }}
                      additionalMeasuresActivated={additionalMeasures}
                      onCheckMeasureChanges={(name: AdditionalMeasureName, checked: boolean) => {
                        if (checked) {
                          setAdditionalMeasures((pAdditionalMeasures) => [
                            ...pAdditionalMeasures.filter((n) => n !== name),
                            name,
                          ]);
                        } else {
                          setAdditionalMeasures((pAdditionalMeasures) => pAdditionalMeasures.filter((n) => n !== name));
                        }
                      }}
                      focus={focusedGraph === dataset.label}
                    />
                  ))}{' '}
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="events">
                <div
                  className="w-100 mt-4 pr-3 position-relative overflow-auto"
                  style={{ maxHeight: 'calc(70vh - 170px + 1.5rem)', height: 'calc(70vh - 100px + 1.5rem)' }}
                >
                  {events.map((event) => (
                    <ImportantEventCard
                      event={event}
                      fontSize="small"
                      open={focusedEvents.includes(event.eventKey)}
                      onOpen={() => {
                        handleFocusEvent(event.eventKey);
                        graphRef.current?.focusOrBlurEvent(event.type, event.important, event.eventKey, true);
                      }}
                      onClose={() => {
                        handleBlurEvent(event.eventKey);
                        graphRef.current?.focusOrBlurEvent(event.type, event.important, event.eventKey, false);
                      }}
                    />
                  ))}
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
        <div className="w-100 position-relative" style={{ maxHeight: '100px' }}>
          {_.uniq(events.map((event) => event.type))
            .sort()
            .map((eventType, index) => (
              <EventLegendItem label={eventType} color={eventColors[eventType]} />
            ))}
        </div>
      </Col>
    </Row>
  );
}
