import React, { HTMLAttributes, useState } from 'react';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Form from 'react-bootstrap/Form';
import './EditComponent.css';

type Props = {
  type?: string | null;
  inline?: boolean | undefined;
  disabled?: boolean | undefined;
  value: number | string | undefined | null;
  onSubmit: (value: string) => Promise<void>;
} & Omit<HTMLAttributes<HTMLFormElement>, 'onSubmit'>;

export default function EditComponent({ type, inline, className, value, disabled, onSubmit }: Props) {
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setIsLoading] = useState(false);

  const setEditMode = () => {
    if (!disabled) {
      setIsEditing(true);
    } else {
      alert('Only validating playlists can be updated. Please use the Update Button');
    }
  };

  const onReset = () => setIsEditing(false);

  const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { value: _value } = event.currentTarget.elements.namedItem('controlId') as HTMLInputElement;
    setIsLoading(true);
    onSubmit(_value).then(() => {
      setIsLoading(false);
      setIsEditing(false);
    });
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement> | React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      event.stopPropagation();
    }
  };

  if (isEditing) {
    return (
      <Form inline={inline} onReset={onReset} onSubmit={handleOnSubmit}>
        <Form.Group controlId="controlId">
          {type === 'textarea' ? (
            <Form.Control
              className={className}
              as="textarea"
              defaultValue={value ?? undefined}
              onKeyDown={handleKeyDown}
            />
          ) : (
            <Form.Control className={className} defaultValue={value ?? undefined} onKeyDown={handleKeyDown} />
          )}
        </Form.Group>
        <ButtonToolbar>
          <Button className="m-1" disabled={loading} variant="outline-primary" type="reset">
            Cancel
          </Button>
          <Button className="m-1" disabled={loading} variant="primary" type="submit">
            {loading ? 'Updating...' : 'Update'}
          </Button>
        </ButtonToolbar>
      </Form>
    );
  }

  return (
    <span
      onClick={setEditMode}
      className={!className ? 'edit-span' : `edit-span ${className}`}
      role="button"
      tabIndex={0}
    >
      {value}
      <i className="far fa-edit m-2 edit-btn" />
    </span>
  );
}
