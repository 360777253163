import { useMutation } from '@apollo/client';
import _ from 'lodash';
import { useState } from 'react';
import { Accordion, Alert } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';

import { ChoiceButtonGroup } from '../../../../../../utils/components/inputs/ChoiceButtonGroup';
import {
  LevelConfigUserFeatureUnlocked,
  RetentionSendingType,
  SendRetentionReminderMutation,
  SendRetentionReminderMutationVariables,
} from '../../../../../../__gqltypes__';
import { SUPPORTED_LOCALE } from '../../../../curation/utils/locales';
import { SEND_RETENTION_REMINDER } from '../../../graphql';
import FeatureSelector from './FeaturesSelector';
import LocaleSelector from './LocaleSelector';

const NotificationResultAlert = ({
  notificationString,
  onClose,
  variant,
}: {
  notificationString: string | null;
  onClose: () => void;
  variant?: 'danger' | 'success';
}) => {
  if (!notificationString) return null;

  return (
    <Alert className="mt-4" variant={variant || 'success'} onClose={onClose} dismissible>
      <Alert.Heading>Sendings:</Alert.Heading>
      <pre>{notificationString}</pre>
    </Alert>
  );
};

const fields = {
  ftueLapsedBeforeTurnRoundBack: ['contentPackId', 'opponentId', 'userToUserId'],
  regularSoMuchMore: ['playerLevel'],
  welcomeSPPlus: [],
  pendingTurns: ['numberOfPendingTurns'],
  goodbye: [
    'playerLever',
    'songsGuessed',
    'opponentsNumber',
    'favoritePlaylistId',
    'favoritePlaylistCount',
    'favoriteOpponentId',
    'favoriteOpponentCount',
  ],
  contentPacks: ['contentPacksPlayedByUser', 'categoryIds', 'earnedContentPackIds'],
  allContent: [],
};

const ImplementedReminders = {
  [RetentionSendingType.FTUELAPSEDBEFORETURNROUNDBACK1PUSH]: {
    label: 'retention ftue lapsed notif 1h',
    group: 0,
    fields: fields.ftueLapsedBeforeTurnRoundBack,
  },
  [RetentionSendingType.FTUELAPSEDBEFORETURNROUNDBACK1MAIL]: {
    label: 'retention ftue lapsed email 1h',
    group: 0,
    fields: fields.ftueLapsedBeforeTurnRoundBack,
  },
  [RetentionSendingType.FTUELAPSEDBEFORETURNROUNDBACK2PUSH]: {
    label: 'retention ftue lapsed notif 1d',
    group: 0,
    fields: fields.ftueLapsedBeforeTurnRoundBack,
  },
  [RetentionSendingType.FTUELAPSEDBEFORETURNROUNDBACK2MAIL]: {
    label: 'retention ftue lapsed email 1d',
    group: 0,
    fields: fields.ftueLapsedBeforeTurnRoundBack,
  },
  [RetentionSendingType.FTUELAPSEDBEFORETURNROUNDBACK3PUSH]: {
    label: 'retention ftue lapsed notif 3d',
    group: 0,
    fields: fields.ftueLapsedBeforeTurnRoundBack,
  },
  [RetentionSendingType.FTUELAPSEDBEFORETURNROUNDBACK3MAIL]: {
    label: 'retention ftue lapsed email 3d',
    group: 0,
    fields: fields.ftueLapsedBeforeTurnRoundBack,
  },
  [RetentionSendingType.WELCOMENOTSUBSCRIBER]: {
    label: 'retention welcome not subscriber email 10m',
    group: 1,
    fields: fields.welcomeSPPlus,
  },
  [RetentionSendingType.WELCOMESUBSCRIBER]: {
    label: 'retention welcome subscriber email 10m',
    group: 1,
    fields: fields.welcomeSPPlus,
  },
  [RetentionSendingType.REGULARLAPSEDMAILSTREAK]: {
    label: 'Regular lapsed email 1d',
    group: 2,
    fields: fields.regularSoMuchMore,
  },
  [RetentionSendingType.REGULARLAPSEDMAILPENDINGTURNS]: {
    label: 'Regular lapsed email 2d',
    group: 3,
    fields: fields.pendingTurns,
  },
  [RetentionSendingType.REGULARLAPSEDNOTIFPENDINGTURNS]: {
    label: 'Regular lapsed notification 2d',
    group: 3,
    fields: fields.pendingTurns,
  },
  [RetentionSendingType.REGULARLAPSEDMAILCONTENTPACKS]: {
    label: 'Regular lapsed email 3d',
    group: 4,
    fields: fields.contentPacks,
  },
  [RetentionSendingType.REGULARLAPSEDNOTIFCONTENTPACKS]: {
    label: 'Regular lapsed notification 3d',
    group: 4,
    fields: fields.contentPacks,
  },
  [RetentionSendingType.REGULARLAPSEDMAILSOMUCHMORE]: {
    label: 'Regular lapsed email 5d',
    group: 5,
    fields: fields.regularSoMuchMore,
  },
  [RetentionSendingType.REGULARLAPSEDNOTIFSOMUCHMORE]: {
    label: 'Regular lapsed notification 5d',
    group: 5,
    fields: fields.regularSoMuchMore,
  },
  [RetentionSendingType.REGULARLAPSEDMAILNOADS]: {
    label: 'Regular lapsed email 7d',
    group: 6,
    fields: fields.pendingTurns,
  },
  [RetentionSendingType.REGULARLAPSEDNOTIFNOADS]: {
    label: 'Regular lapsed notification 7d',
    group: 6,
    fields: fields.pendingTurns,
  },
  [RetentionSendingType.REGULARLAPSEDMAILALLCONTENT]: {
    label: 'Regular lapsed email 14d',
    group: 7,
    fields: fields.allContent,
  },
  [RetentionSendingType.REGULARLAPSEDNOTIFALLCONTENT]: {
    label: 'Regular lapsed notification 14d',
    group: 7,
    fields: fields.allContent,
  },
  [RetentionSendingType.REGULARLAPSEDMAILLASTCHANCE]: {
    label: 'Regular lapsed email end of month',
    group: 8,
    fields: fields.pendingTurns,
  },
  [RetentionSendingType.REGULARLAPSEDNOTIFMONTHEND]: {
    label: 'Regular lapsed notification end of month',
    group: 8,
    fields: fields.pendingTurns,
  },
  [RetentionSendingType.REGULARLAPSEDMAILGOODBYE]: {
    label: 'Regular lapsed email goodbye',
    group: 9,
    fields: fields.goodbye,
  },
};

function isError(message: string): boolean {
  try {
    const res = JSON.parse(message);
    return !!res.errors;
  } catch (error) {
    return true;
  }
}

const RetentionToolDisplay = ({ userId }: { userId: string }) => {
  const [sendReminder, { data: alert, loading: sendReminderLoading, reset }] = useMutation<
    SendRetentionReminderMutation,
    SendRetentionReminderMutationVariables
  >(SEND_RETENTION_REMINDER);

  const [expanded, setExpanded] = useState(false);
  const [doNotMock, setDoNotMock] = useState(false);
  const [forceTemplateProps, setForceTemplateProps] = useState(false);
  const [templateProps, setTemplateProps] = useState<Record<string, string>>({});
  const [saveSending, setSaveSending] = useState(false);
  const [forcedLocale, setForcedLocale] = useState<null | SUPPORTED_LOCALE>(null);

  const [sendingType, setSendingType] = useState<RetentionSendingType>(
    RetentionSendingType.FTUELAPSEDBEFORETURNROUNDBACK1PUSH
  );

  const isSoMuchMoreType =
    sendingType === RetentionSendingType.REGULARLAPSEDNOTIFSOMUCHMORE ||
    sendingType === RetentionSendingType.REGULARLAPSEDMAILSOMUCHMORE;

  const handleSendNotification = () => {
    const input = {
      type: sendingType,
      userId,
      userLang: forcedLocale,
      templateData: forceTemplateProps || isSoMuchMoreType ? templateProps : undefined,
      doNotMockTemplateData: doNotMock,
    };
    sendReminder({ variables: { input } });
  };

  const onAlertClose = () => {
    reset();
  };

  return (
    <Card className="mb-4">
      <Card.Body>
        <Accordion>
          <Accordion.Toggle as={Card.Title} variant="link" eventKey="0" onClick={() => setExpanded(!expanded)}>
            <div style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}>
              Retention Testing Tool
              <Button variant="link">
                {!expanded && <i className="fas fa-chevron-down" />}
                {expanded && <i className="fas fa-chevron-up" />}
              </Button>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <>
              <LocaleSelector locale={forcedLocale} setLocale={setForcedLocale} />

              <ChoiceButtonGroup
                choices={ImplementedReminders}
                choice={sendingType}
                title="Sending type"
                setChoice={setSendingType}
              />

              <Form.Check
                type="switch"
                id="saveSending"
                label="Allow to modify user to send the notification as sent"
                className="mt-3"
                checked={saveSending}
                onChange={(e) => setSaveSending(e.target.checked)}
              />

              <Form.Check
                type="switch"
                id="doNotMock"
                label="Do not mock the template data: user should be in the right state"
                className="mt-3"
                checked={doNotMock}
                onChange={(e) => setDoNotMock(e.target.checked)}
              />

              <Form.Check
                type="switch"
                id="forceTemplateProps"
                label="Force some props to be passed to the template"
                className="mt-3"
                checked={(!doNotMock && forceTemplateProps) || isSoMuchMoreType}
                disabled={doNotMock || isSoMuchMoreType}
                onChange={(e) => setForceTemplateProps(e.target.checked)}
              />

              {forceTemplateProps &&
                sendingType in ImplementedReminders &&
                !isSoMuchMoreType &&
                ImplementedReminders[sendingType as keyof typeof ImplementedReminders].fields.map((field) => (
                  <Form.Group className="mt-3">
                    <Form.Label>{field}</Form.Label>
                    <Form.Control
                      value={templateProps[field] || ''}
                      onChange={(e) => {
                        const { value } = e.target;
                        setTemplateProps((pTemplateProps) => ({ ...pTemplateProps, [field]: value }));
                      }}
                    />
                  </Form.Group>
                ))}

              {isSoMuchMoreType && (
                <FeatureSelector
                  onChange={(value) => {
                    setTemplateProps((pTemplateProps) => ({ ...pTemplateProps, unlockedFeatures: value }));
                  }}
                />
              )}

              <br />
              <Button disabled={sendReminderLoading} onClick={handleSendNotification} className="mt-2">
                {sendReminderLoading ? 'Sending...' : 'Send reminder'}
              </Button>
              {alert?.sendRetentionReminder && (
                <NotificationResultAlert
                  notificationString={alert.sendRetentionReminder}
                  onClose={onAlertClose}
                  variant={isError(alert.sendRetentionReminder) ? 'danger' : 'success'}
                />
              )}
            </>
          </Accordion.Collapse>
        </Accordion>
      </Card.Body>
    </Card>
  );
};

export default RetentionToolDisplay;
