import { ApolloClient, ApolloLink, split } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { RetryLink } from '@apollo/client/link/retry';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import { EventEmitter } from 'fbemitter';
import { sha256 } from 'js-sha256';
import { getURIs } from './config';
import possibleTypes from './possibleTypes.json';

export const emitter = new EventEmitter();

let token: string | null = null;

const defaultHeaders = {
  version: '1.2.0',
  variation: 'test',
  deviceId: 'browser',
  systemName: 'browser',
  deviceTimezone: 'America/New_York',
  appId: 'SP3', // Hardcoded for now
  systemVersion: '10.0', // Hardcoded for now
};

export function updateToken(_token: string) {
  token = _token;
}

export function updateApp(app: string) {
  defaultHeaders.appId = app;
}

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    ...defaultHeaders,
    authorization: token ? `Bearer ${token}` : '',
  },
}));

const { uri, wsUri } = getURIs();

const httpLink = createUploadLink({ uri });
const wsLink = createPersistedQueryLink({ sha256 }).concat(
  new WebSocketLink({
    uri: wsUri,
    options: {
      connectionParams: () => ({ ...defaultHeaders, token }),
      lazy: true,
      reconnect: true,
    },
  })
);

const interfaceLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink as any as ApolloLink
);

// Retry curationContentPackByOriginId that can sometimes be too long?
const retryLink = new RetryLink({
  attempts: (count, operation, error) => {
    return !!error && operation.operationName === 'curationContentPackByOriginId' && count < 2;
  },
});

const adaptedPossibleTypes = possibleTypes.__schema.types.reduce((acc, type) => {
  if (type.possibleTypes) {
    acc[type.name] = type.possibleTypes.map((subtype) => subtype.name);
  }
  return acc;
}, {} as Record<string, string[]>);

adaptedPossibleTypes.LevelRewardInput = [
  'CURRENCY_COIN',
  'CURRENCY_DIAMOND',
  'CURRENCY_TOURNAMENT_TICKET',
  'CURRENCY_BOOST',
  'CURRENCY_CATEGORY_FRAGMENT',
  'STICKER',
  'PROFILE_FRAME',
  'APP_SKIN',
  'MONTHLY_BUFF',
  'CURRENCY_SPECIAL_EVENT',
];

export const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
        );
        // // display error too!
        emitter.emit('error', graphQLErrors);
      }
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    retryLink,
    authLink,
    interfaceLink,
  ]),
  cache: new InMemoryCache({
    possibleTypes: adaptedPossibleTypes,
    dataIdFromObject: (obj) => {
      if (obj.__typename === 'CurationSong' && obj.songInput) {
        // @ts-ignore
        return `CurationSong:${obj.id}:${obj.songInput.id}`;
      }

      if (obj.__typename === 'CurationBlacklistedTitle') {
        return `CurationBlacklistedTitle:${obj.title}_${obj.artist}`;
      }

      if (obj.__typename === 'CurationSongExternalLinksByStore') {
        return `CurationSongExternalLinksByStore:${obj.us ?? obj.gb ?? obj.fr ?? obj.br ?? obj.de ?? obj.es}`;
      }

      if (obj.__typename === 'ContentPackList') {
        return `ContentPackList:${obj.contentPackListId}`;
      }

      if (obj.__typename === 'ImportantEvent') {
        return `ImportantEvent:${obj.type}:${obj.eventKey}`;
      }

      if (obj.id !== undefined) {
        return `${obj.__typename}:${obj.id}`;
      }

      return undefined;
    },
    typePolicies: {
      Query: {
        fields: {
          // For pagination
          getImportantEvents: {
            keyArgs: false,
            merge(existing = [], incoming) {
              return [...existing, ...incoming];
            },
          },
        },
      },
    },
  }),
});
