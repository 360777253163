import Badge from 'react-bootstrap/Badge';
import './Tag.css';

type Props = {
  value: string;
  visible?: boolean;
  onRemove: () => void;
  disabled?: boolean;
  variant?: string;
};

export default function Tag({ value, visible, onRemove, disabled, variant }: Props) {
  if (!visible) {
    return null;
  }
  return (
    /* @ts-ignore */
    <Badge pill variant={variant ?? 'success'} className="tag-pill" disabled={!!disabled}>
      {value}
      <i role="link" tabIndex={0} className="fas fa-times m-1" onClick={onRemove} />
    </Badge>
  );
}
