import { ChainedOfferPackRewardConfig, LevelRewardInput, LevelRewardInputType } from "../../../../../__gqltypes__";

export function convertCOEPackRewardToLevelRewardInput(reward: ChainedOfferPackRewardConfig): LevelRewardInput {
  return  {
      type: Object.values(LevelRewardInputType)
      .find((val) => val === reward.type.toString()) ?? LevelRewardInputType.CURRENCY_COIN,
      amount: reward.amount,
      itemId: reward.itemId,
  };
}

export function convertCOEPackRewardsToLevelRewardInputs(
  rewards: Array<ChainedOfferPackRewardConfig>
): Array<LevelRewardInput> {
  return rewards.map((reward) => convertCOEPackRewardToLevelRewardInput(reward));
}