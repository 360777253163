import DashboardContainer from '../../../../../../utils/dashboards/components/DashboardContainer';
import { SimpleDateSlicing } from '../../../../../../utils/dashboards/components/slicers';
// import { DefaultSlicing } from '../../../../../../utils/dashboards/components/slicers';
import BarChart from '../../../../../../utils/dashboards/components/widgets/charts/BarChart';
import LineChart from '../../../../../../utils/dashboards/components/widgets/charts/LineChart';
import StackedAreaChart from '../../../../../../utils/dashboards/components/widgets/charts/StackedAreaChart';
import BaseTable from '../../../../../../utils/dashboards/components/widgets/tables/BaseTable';
import WidgetContainer from '../../../../../../utils/dashboards/components/widgets/WidgetContainer';
import Processing from '../../../../../../utils/dashboards/models/Processing';

const queries = {
  general: { fillZeroes: true },
};

const Tuneology = () => (
  <>
    <DashboardContainer queries={queries} id="tuneology" title="Tuneology">
      <SimpleDateSlicing id="SimpleDateSlicing" />
      <WidgetContainer id="WidgetContainer">
        <BaseTable
          id="general"
          title="Test Table"
        />
      </WidgetContainer>
    </DashboardContainer>
  </>
);

export default Tuneology;
