import { RouteType } from '../../../types';
import { UserRole } from '../../../__gqltypes__';
import AdminContentModeration from './screens/AdminContentModeration/AdminContentModeration';
import ContentModeration from './screens/ContentModeration/ContentModeration';
import NaughtyWords from './screens/ContentModeration/NaugtyWords';
import ContentPackListList from './screens/ContentPackList/ContentPackListList';
import DDEXJobs from './screens/DDEXJobs';
import Catalog from './screens/Hurdle/Catalog';
import LegalConfig from './screens/LegalConfig';
import MusicReporting from './screens/MusicReporting/MusicReporting';
import PlaylistInfo from './screens/PlaylistInfo';
import Playlists from './screens/Playlists';
import Samples from './screens/Samples';
import SongSearch from './screens/SongSearch';
import Sp2Playlists from './screens/Sp2Playlists/index';
import Sp2Releases from './screens/Sp2Releases';
import SPPContent from './screens/SppContent/SPPContent';

export const PREFIX = 'sp3';

// const availableOnlyTest = (Comp) => {
//   if (process.env.REACT_APP_NAMESPACE === 'test') {
//     return Comp;
//   }
//   return null;
// };

export default [
  {
    path: `/${PREFIX}/playlist/`,
    component: Playlists,
    name: 'Curation',
    exact: true,
    permission: [UserRole.ADMIN, UserRole.CURATOR],
  },
  {
    path: `/${PREFIX}/playlist/hurdle-catalog`,
    component: Catalog,
    name: 'Tuneology catalog',
    exact: true,
    permission: [UserRole.ADMIN, UserRole.CURATOR],
  },
  {
    path: `/${PREFIX}/playlist/legal`,
    component: LegalConfig,
    name: 'Legal Config',
    exact: true,
    permission: [UserRole.ADMIN, UserRole.CURATOR],
  },
  {
    path: `/${PREFIX}/playlist/sp2Releases/`,
    component: Sp2Releases,
    name: 'SP2 Releases',
    exact: true,
    permission: [UserRole.ADMIN, UserRole.CURATOR],
  },

  {
    path: `/${PREFIX}/playlist/sp2Playlists/`,
    component: Sp2Playlists,
    name: 'SP2 Playlists',
    exact: true,
    permission: [UserRole.ADMIN, UserRole.CURATOR],
  },

  {
    path: `/${PREFIX}/playlist/samples`,
    component: Samples,
    name: 'Samples',
    exact: true,
    permission: [UserRole.ADMIN, UserRole.CURATOR],
  },
  {
    path: `/${PREFIX}/playlist/spp-content/:id?`,
    href: `/${PREFIX}/playlist/spp-content`,
    component: SPPContent,
    name: 'SongPop Party',
    exact: true,
    permission: [UserRole.ADMIN, UserRole.CURATOR],
  },
  {
    path: `/${PREFIX}/playlist/song-search`,
    component: SongSearch,
    name: 'Song Search',
    permission: [UserRole.ADMIN, UserRole.CURATOR],
  },
  {
    path: `/${PREFIX}/playlist/contentpacklists/:id?`,
    href: `/${PREFIX}/playlist/contentpacklists`,
    name: 'Content Pack Lists',
    component: ContentPackListList,
    permission: [UserRole.ADMIN, UserRole.CURATOR],
  },
  {
    path: `/${PREFIX}/playlist/moderation`,
    component: ContentModeration,
    name: 'Content Moderation',
    exact: true,
    permission: [UserRole.ADMIN, UserRole.CURATOR, UserRole.CONTENT_MODERATOR],
  },
  {
    path: `/${PREFIX}/playlist/moderation/words`,
    component: NaughtyWords,
    exact: true,
    permission: [UserRole.ADMIN, UserRole.CURATOR, UserRole.CONTENT_MODERATOR],
  },
  {
    path: `/${PREFIX}/playlist/admin-moderation/:id?`,
    href: `/${PREFIX}/playlist/admin-moderation`,
    component: AdminContentModeration,
    name: 'Admin Content Moderation',
    exact: true,
    permission: [UserRole.ADMIN, UserRole.CURATOR],
  },

  {
    path: `/${PREFIX}/playlist/ddex-jobs/:id?`,
    href: `/${PREFIX}/playlist/ddex-jobs`,
    component: DDEXJobs,
    name: 'DDEX Jobs',
    permission: [UserRole.ADMIN, UserRole.CURATOR],
  },
  {
    path: `/${PREFIX}/playlist/music-reporting/:id?`,
    href: `/${PREFIX}/playlist/music-reporting`,

    component: MusicReporting,
    name: 'Music Reporting',
    permission: [UserRole.ADMIN, UserRole.CURATOR],
  },

  {
    path: `/${PREFIX}/playlist/:originid/:version`,
    component: PlaylistInfo,
    permission: [UserRole.ADMIN, UserRole.CURATOR],
  },
  {
    path: `/${PREFIX}/playlist/:originid`,
    component: PlaylistInfo,
    permission: [UserRole.ADMIN, UserRole.CURATOR],
  },
] as RouteType[];
