import React from 'react';

import { SimpleDateSlicing } from '../../../../../../utils/dashboards/components/slicers';
import WidgetContainer from '../../../../../../utils/dashboards/components/widgets/WidgetContainer';
import DashboardContainer from '../../../../../../utils/dashboards/components/DashboardContainer';
import LineChart from '../../../../../../utils/dashboards/components/widgets/charts/LineChart';
import Processing from '../../../../../../utils/dashboards/models/Processing';

const queries = {
  crashlyticsFatalPerDAU: { fillZeroes: true },
  crashlyticsFatalUsers: { fillZeroes: true },
  crashlyticsFatalSessions: { fillZeroes: true },
  crashlyticsNonFatalPerDAU: { fillZeroes: true },
  crashlyticsNonFatalUsers: { fillZeroes: true },
  crashlyticsNonFatalSessions: { fillZeroes: true },
  games_loading_time: { fillZeroes: true },
  percentageCrashFatalSessions: { fillZeroes: true },
  percentageCrashNonFatalSessions: { fillZeroes: true },
};

const Performance = () => (
  <DashboardContainer queries={queries} id="performance" title="Performance">
    <SimpleDateSlicing id="SimpleDateSlicing" />
    <WidgetContainer id="WidgetContainer">
      <LineChart
        id="crashlyticsFatalPerDAU"
        title="Fatal Crashes per DAU"
        processing={Processing.pivot('crashlyticsFatalPerDAU', 'platform', 'crashesPerDAU', 'date')}
      />
      <LineChart
        id="crashlyticsFatalUsers"
        title="Number of unique users that experienced a fatal crash"
        processing={Processing.pivot('crashlyticsFatalUsers', 'platform', 'nbUsersCrashes', 'date')}
      />
      <LineChart
        id="crashlyticsFatalSessions"
        title="Number of session ids that experienced a fatal crash"
        processing={Processing.pivot('crashlyticsFatalSessions', 'platform', 'nbSessionIds', 'date')}
      />
      <LineChart
        id="percentageCrashFatalSessions"
        title="Percentage of session ids that experienced a fatal crash" percentage
        processing={Processing.pivot('percentageCrashFatalSessions', 'platform', 'percentage', 'date')}
      />
      <LineChart
        id="crashlyticsNonFatalPerDAU"
        title="Non Fatal Crashes per DAU"
        processing={Processing.pivot('crashlyticsNonFatalPerDAU', 'platform', 'crashesPerDAU', 'date')}
      />
      <LineChart
        id="crashlyticsNonFatalUsers"
        title="Number of unique users that experienced a non fatal crash"
        processing={Processing.pivot('crashlyticsNonFatalUsers', 'platform', 'nbUsersCrashes', 'date')}
      />
      <LineChart
        id="crashlyticsNonFatalSessions"
        title="Number of session ids that experienced a non fatal crash"
        processing={Processing.pivot('crashlyticsNonFatalSessions', 'platform', 'nbSessionIds', 'date')}
      />
      <LineChart
        id="percentageCrashNonFatalSessions"
        title="Percentage of session ids that experienced a non fatal crash" percentage
        processing={Processing.pivot('percentageCrashNonFatalSessions', 'platform', 'percentage', 'date')}
      />
      <LineChart
        id="gamesLoadingTime"
        title="Average Seconds to load a game per game type"
        processing={Processing.pivot('games_loading_time', 'type', 'avg_diff_seconds', 'date')}
      />
      <LineChart
        id="gamesPercentageNoStart"
        title="Percentage of games that don't start when loading per game type"
        processing={Processing.pivot('games_loading_time', 'type', 'perc_no_start', 'date')}
      />
    </WidgetContainer>
  </DashboardContainer>
);

export default Performance;


