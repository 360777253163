import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { CurationSongSearchTerm } from '../../../../../../__gqltypes__';

export const SearchType = {
  Database: 'DATABASE',
  DDEX: 'DDEX',
};

type Props = {
  defaultAlbum?: string | null;
  defaultArtist?: string | null;
  defaultEndYear?: number | null;
  defaultIsrc?: string | null;
  defaultLabel?: string | null;
  defaultStartYear?: number | null;
  defaultSubLabel?: string | null;
  defaultTitle?: string | null;
  defaultType?: typeof SearchType[keyof typeof SearchType] | null;
  songSearchState?: null | 'SHOW' | 'SHOW_DDEX' | 'LOADING';
  onSearch: (
    searchType: typeof SearchType[keyof typeof SearchType],
    term: CurationSongSearchTerm,
    includesContentPacks: boolean
  ) => void;
};

export default function SearchForm({
  defaultAlbum,
  defaultArtist,
  defaultEndYear,
  defaultIsrc,
  defaultLabel,
  defaultStartYear,
  defaultSubLabel,
  defaultTitle,
  defaultType,
  songSearchState,
  onSearch,
}: Props) {
  const [searchType, setSearchType] = useState(defaultType ?? SearchType.DDEX);
  useEffect(() => {
    if (songSearchState === 'SHOW_DDEX') {
      setSearchType(SearchType.DDEX);
    } else {
      setSearchType(defaultType ?? SearchType.DDEX);
    }
  }, [defaultType]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const term: CurationSongSearchTerm = {
      // we want to make sure empty fields are being passed as null instead of "" or 0
      title: (e.currentTarget.elements.namedItem('title') as HTMLInputElement | null)?.value || null,
      artist: (e.currentTarget.elements.namedItem('artist') as HTMLInputElement | null)?.value || null,
      album: (e.currentTarget.elements.namedItem('album') as HTMLInputElement | null)?.value || null,
      label: (e.currentTarget.elements.namedItem('label') as HTMLInputElement | null)?.value || null,
      subLabel: (e.currentTarget.elements.namedItem('subLabel') as HTMLInputElement | null)?.value || null,
      startYear: Number((e.currentTarget.elements.namedItem('startYear') as HTMLInputElement | null)?.value) || null,
      endYear: Number((e.currentTarget.elements.namedItem('endYear') as HTMLInputElement | null)?.value) || null,
      isrc: (e.currentTarget.elements.namedItem('isrc') as HTMLInputElement | null)?.value || null,
      fuzzySearch: (e.currentTarget.elements.namedItem('fuzzy-search') as HTMLInputElement | null)?.checked ?? false,
    };

    const includesContentPacks =
      (e.currentTarget.elements.namedItem('includes-contentpack') as HTMLInputElement | null)?.checked ?? false;
    onSearch(searchType, term, includesContentPacks);
  };

  const _setSearchType = (x: React.ChangeEvent<HTMLInputElement>) => setSearchType(x.currentTarget.value);

  return (
    <Form className="mb-2" onSubmit={handleSubmit}>
      <Form.Row>
        <Form.Group as={Col} controlId="isrc">
          <Form.Label sm={2} className="font-weight-bold small">
            Isrc
          </Form.Label>
          <Form.Control type="text" placeholder="Enter song isrc" defaultValue={defaultIsrc ?? undefined} />
        </Form.Group>

        <Form.Group as={Col} controlId="title">
          <Form.Label sm={2} className="font-weight-bold small">
            Title
          </Form.Label>
          <Form.Control type="text" placeholder="Enter song title" defaultValue={defaultTitle ?? undefined} />
        </Form.Group>

        <Form.Group as={Col} controlId="artist">
          <Form.Label sm={2} className="font-weight-bold small">
            Artist
          </Form.Label>
          <Form.Control type="text" placeholder="Enter artist name" defaultValue={defaultArtist ?? undefined} />
        </Form.Group>
        <Form.Group as={Col} controlId="album">
          <Form.Label sm={2} className="font-weight-bold small">
            Album
          </Form.Label>
          <Form.Control type="text" placeholder="Enter album name" defaultValue={defaultAlbum ?? undefined} />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} className="col-1" controlId="startYear">
          <Form.Label sm={1} className="font-weight-bold small">
            From year
          </Form.Label>
          <Form.Control size="sm" type="text" placeholder="YYYY" defaultValue={defaultStartYear ?? undefined} />
        </Form.Group>
        <Form.Group as={Col} className="col-1" controlId="endYear">
          <Form.Label sm={1} className="font-weight-bold small">
            Until year
          </Form.Label>
          <Form.Control size="sm" type="text" placeholder="YYYY" defaultValue={defaultEndYear ?? undefined} />
        </Form.Group>
        <Form.Group as={Col} className="col-2" controlId="label">
          <Form.Label sm={2} className="font-weight-bold small">
            Label
          </Form.Label>
          <Form.Control size="sm" type="text" placeholder="Enter main label" defaultValue={defaultLabel ?? undefined} />
        </Form.Group>
        <Form.Group as={Col} className="col-2" controlId="subLabel">
          <Form.Label sm={2} className="font-weight-bold small">
            Sub Label
          </Form.Label>
          <Form.Control
            size="sm"
            type="text"
            placeholder="Enter sublabel"
            defaultValue={defaultSubLabel ?? undefined}
          />
        </Form.Group>
        <Form.Group as={Row} className="align-items-end ml-2">
          <ButtonGroup toggle>
            {(_.isNil(songSearchState) || songSearchState === 'SHOW') && (
              <>
                <ToggleButton
                  type="radio"
                  variant={searchType === SearchType.Database ? 'success' : 'outline-secondary'}
                  name="radio"
                  value={SearchType.Database}
                  checked={searchType === SearchType.Database}
                  onChange={_setSearchType}
                >
                  <i className="fas fa-compact-disc mr-2" />
                  SP3 Database
                </ToggleButton>
              </>
            )}

            {(_.isNil(songSearchState) || songSearchState === 'SHOW' || songSearchState === 'SHOW_DDEX') && (
              <ToggleButton
                type="radio"
                variant={searchType === SearchType.DDEX ? 'success' : 'outline-secondary'}
                name="radio"
                value={SearchType.DDEX}
                checked={searchType === SearchType.DDEX}
                onChange={_setSearchType}
              >
                <i className="fab fa-itunes-note mr-2" />
                DDEX Database
              </ToggleButton>
            )}
          </ButtonGroup>
        </Form.Group>
      </Form.Row>
      {searchType === SearchType.Database && (
        <Form.Row>
          <Form.Group>
            <Form.Check type="checkbox" id="includes-contentpack" label="search in committed playlists too" />
            <Form.Check type="checkbox" id="fuzzy-search" label="use fuzzy search" />
          </Form.Group>
        </Form.Row>
      )}
      <Form.Row>
        <Button type="submit">
          <i className="fas fa-search mr-2" />
          Search in {searchType === SearchType.DDEX ? 'DDEX' : 'SP3'}
        </Button>
      </Form.Row>
    </Form>
  );
}
