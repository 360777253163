import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Accordion, Col, Form, Modal, ModalProps, Row, Table } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Card from 'react-bootstrap/Card';

import {
  CompleteUserHurdleQuizMutation,
  CompleteUserHurdleQuizMutationVariables,
  CurrentHurdleQuizzesQuery,
  CurrentHurdleQuizzesQueryVariables,
  ResetHurdleQuizMutation,
  ResetHurdleQuizMutationVariables,
  StreakHistoryQuery,
  StreakHistoryQueryVariables,
  UpdateUserHurdleQuizMutation,
  UpdateUserHurdleQuizMutationVariables,
} from '../../../../../../__gqltypes__';
import {
  COMPLETE_USER_HURDLE_QUIZ,
  GET_CURRENT_HURDLE_QUIZZES,
  GET_USER_STREAK_HISTORY,
  RESET_USER_HURDLE_QUIZ,
  UPDATE_USER_HURDLE_QUIZ,
} from '../../../graphql';

type HurdleQuizData = {
  id: string;
  startDate: number;
  question?: {
    asset?: string;
    offset?: number;
    song?: {
      displayTitle?: string;
      displayArtist?: string;
    } | null;
  } | null;
};

type UpdateModalProps = {
  hurdleQuiz?: HurdleQuizData;
  updateQuiz: (hurdleQuizId: string, currentStreak: number) => Promise<void>;
  updateLoading: boolean;
} & ModalProps;

const UpdateModal = ({ show, close, hurdleQuiz, updateQuiz, updateLoading }: UpdateModalProps) => {
  const [newCurrentStreak, setNewCurrentStreak] = useState(0);

  // @ts-ignore
  const onSubmit = (event) => {
    event.preventDefault();

    if (event.currentTarget.checkValidity() && hurdleQuiz) {
      updateQuiz(hurdleQuiz.id, newCurrentStreak);
    }
  };

  return (
    <Modal show={show} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>Update</Modal.Title>
      </Modal.Header>
      <Form noValidate onSubmit={onSubmit}>
        <Modal.Body>
          <Form.Group as={Row}>
            <Form.Label column sm="5">
              New currentStreak:
            </Form.Label>
            <Col>
              <Form.Control
                type="number"
                min="0"
                step="1"
                value={newCurrentStreak}
                // @ts-ignore
                onChange={(e) => setNewCurrentStreak(e.target.value)}
              />
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={close}>
            Close
          </Button>
          <Button type="submit" variant="primary" disabled={updateLoading}>
            Update
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

type Props = {
  userId: string;
};

export const HurdleQuizDisplay = ({ userId }: Props) => {
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [currentHurdleQuiz, setCurrentHurdleQuiz] = useState<HurdleQuizData>();
  const [expanded, setExpanded] = useState(false);
  const [hurdleQuizzes, setHurdleQuizzes] = useState<HurdleQuizData[]>([]);
  const closeUpdateModal = () => setShowUpdateModal(false);
  const triggerShowUpdateModal = (hurdleQuiz: HurdleQuizData) => {
    setCurrentHurdleQuiz(hurdleQuiz);
    setShowUpdateModal(true);
  };

  const { data, loading: getLoading } = useQuery<CurrentHurdleQuizzesQuery, CurrentHurdleQuizzesQueryVariables>(
    GET_CURRENT_HURDLE_QUIZZES,
    {
      variables: { input: { pagination: { offset: 0, limit: 2 } } },
    }
  );
  const { data: userStreakHistory, loading: userStreakHistoryLoading } = useQuery<
    StreakHistoryQuery,
    StreakHistoryQueryVariables
  >(GET_USER_STREAK_HISTORY, {
    variables: { input: { userId } },
    skip: !userId,
  });
  const [resetHurdleQuiz, { loading: resetLoading }] = useMutation<
    ResetHurdleQuizMutation,
    ResetHurdleQuizMutationVariables
  >(RESET_USER_HURDLE_QUIZ);
  const [updateUserHurdleQuiz, { loading: updateLoading }] = useMutation<
    UpdateUserHurdleQuizMutation,
    UpdateUserHurdleQuizMutationVariables
  >(UPDATE_USER_HURDLE_QUIZ);
  const [completeHurdleQuiz, { loading: completeLoading }] = useMutation<
    CompleteUserHurdleQuizMutation,
    CompleteUserHurdleQuizMutationVariables
  >(COMPLETE_USER_HURDLE_QUIZ);

  const updateQuiz = async (hurdleQuizId: string, currentStreak: number | string) => {
    await updateUserHurdleQuiz({
      variables: {
        input: {
          userId,
          id: hurdleQuizId,
          currentStreak: +currentStreak,
        },
      },
    }).catch((e) => {
      alert(e);
    });
    closeUpdateModal();
  };
  const resetQuiz = (id: string) => {
    if (window.confirm("Are you sure you want to reset this user's Tuneology quiz?")) {
      resetHurdleQuiz({ variables: { input: { userId, id } } }).catch((e) => {
        alert(e);
      });
    }
  };
  const completeQuiz = (id: string) => {
    if (window.confirm("Are you sure you want to complete this user's Tuneology quiz?")) {
      completeHurdleQuiz({ variables: { input: { userId, id } } }).catch((e) => {
        alert(e);
      });
    }
  };

  useEffect(() => {
    if (data && data.currentHurdleQuizzes) {
      const fetchedHurdleQuizzes = data.currentHurdleQuizzes.list;

      setHurdleQuizzes(fetchedHurdleQuizzes);
    }
  }, [data]);

  const loading = getLoading || resetLoading || updateLoading || completeLoading || userStreakHistoryLoading;

  return (
    <Card className="mb-4">
      <Card.Body>
        <Accordion>
          <Accordion.Toggle as={Card.Title} variant="link" eventKey="0" onClick={() => setExpanded(!expanded)}>
            <div style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}>
              Tuneology
              <Button variant="link">
                {!expanded && <i className="fas fa-chevron-down" />}
                {expanded && <i className="fas fa-chevron-up" />}
              </Button>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <>
              <h5>
                Current streak:{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {!userStreakHistoryLoading && userStreakHistory?.streakHistory?.currentStreak}
                </span>
              </h5>
              <br />
              <h5>Streak History</h5>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Previous Streak</th>
                    <th>Date lost</th>
                    <th>Title</th>
                    <th>Artist</th>
                  </tr>
                </thead>
                <tbody>
                  {!userStreakHistoryLoading &&
                    userStreakHistory?.streakHistory?.lostGames.map((lostGame) => (
                      <tr key={lostGame.hurdleQuizId}>
                        <td>{lostGame.previousStreak}</td>
                        <td>{moment(lostGame.lostDate).format('DD/MM/YYYY')}</td>
                        <td>{lostGame.songTitle}</td>
                        <td>{lostGame.songArtist}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              <br />
              <h5>Quizzes</h5>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Start at</th>
                    <th>Title</th>
                    <th>Artist</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {hurdleQuizzes.map((hurdleQuiz) => (
                    <tr key={hurdleQuiz.id}>
                      <td>
                        {moment(hurdleQuiz.startDate).format('DD/MM/YYYY')} -{' '}
                        {moment(hurdleQuiz.startDate).format('LT')}
                      </td>
                      <td>{hurdleQuiz.question?.song?.displayTitle}</td>
                      <td>{hurdleQuiz.question?.song?.displayArtist}</td>
                      <td>
                        <ButtonGroup>
                          <Button
                            variant="danger"
                            onClick={() => resetQuiz(hurdleQuiz.id)}
                            disabled={loading || moment(hurdleQuiz.startDate) > moment()}
                          >
                            Reset
                          </Button>
                          <Button
                            variant="primary"
                            onClick={() => triggerShowUpdateModal(hurdleQuiz)}
                            disabled={loading || moment(hurdleQuiz.startDate) > moment()}
                          >
                            Update
                          </Button>
                          <Button
                            variant="success"
                            onClick={() => completeQuiz(hurdleQuiz.id)}
                            disabled={loading || moment(hurdleQuiz.startDate) > moment()}
                          >
                            Complete
                          </Button>
                        </ButtonGroup>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          </Accordion.Collapse>
        </Accordion>

        <UpdateModal
          show={showUpdateModal}
          close={closeUpdateModal}
          hurdleQuiz={currentHurdleQuiz}
          updateQuiz={updateQuiz}
          updateLoading={updateLoading}
        />
      </Card.Body>
    </Card>
  );
};
