import React from 'react';

const SectionDisplay = ({ notes }) => (
  <>
    <hr />
    <h5>{notes}</h5>
  </>
);

export default SectionDisplay;
