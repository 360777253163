import { useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import { LinkContainer } from 'react-router-bootstrap';
import { Redirect, Route, Switch, useRouteMatch, useHistory } from 'react-router-dom';
import { LoadingLogo } from '../../../../devtools/components/Modal';
import { CREATE_BADGE, GET_BADGES } from '../../../components/apolloQueries';
import BadgeView from '../components/BadgeView';
import CreateBadgeModal from '../components/CreateBadgeModal';
import { BadgeType } from '../../../../../../__gqltypes__';

function indexItems<T extends { id: string }>(list: T[]) {
  const indexedItems: Record<string, T> = {};

  list.forEach((item) => {
    indexedItems[item.id] = item;
  });

  return indexedItems;
}

const BadgesView = <T extends string>({ type }: { type: T }) => {
  const match = useRouteMatch();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [items, setItems] = useState<Record<string, any> | null>(null);
  const { data, loading, refetch } = useQuery(GET_BADGES, { fetchPolicy: 'no-cache' });
  const history = useHistory();

  useEffect(() => {
    if (data) {
      setItems(indexItems(data?.liveOps?.badgeConfigs?.list));
    }
  }, [data]);

  const amountOfBadges = data?.liveOps?.badgeConfigs?.list?.length;

  const [createItem, { loading: createLoading }] = useMutation(CREATE_BADGE, { onCompleted: () => refetch() });

  const handleCreate = (name: string, type: BadgeType) => {
    createItem({
      variables: {
        input: {
          name,
          type,
        },
      },
    })
      .then(({ data }) => {
        setShowCreateModal(false);
        refetch().then(() => history.push(`/sp3/liveops/items/badges/${data.createBadge.badge.id}`));
      })
      .catch(() => {
        alert('Something went wrong :(');
      });
  };

  // sort items by name
  const sortedItems = _.sortBy(items, ['name']);

  return (
    <>
      <LoadingLogo show={loading || createLoading} />
      <CreateBadgeModal
        show={(!items || amountOfBadges === 0 || showCreateModal) && !loading}
        loading={loading || createLoading}
        onHide={() => setShowCreateModal(false)}
        onAdd={handleCreate}
      />
      {items && (
        <Row className="d-flex">
          <Col>
            <h1 className="text-center">{type}</h1>
            <Button className="w-100 mb-3" variant="success" onClick={() => setShowCreateModal(true)}>
              Add a new {type}
            </Button>
            <Nav variant="pills" className="mb-3" style={{ maxHeight: '75vh', overflowY: 'auto' }}>
              {Object.values(sortedItems).map((item) => (
                <Nav.Item key={item.id} style={{ width: '100%' }} className="text-center">
                  <LinkContainer to={`${match.path}/${item.id}`}>
                    <Nav.Link>{item.name}</Nav.Link>
                  </LinkContainer>
                </Nav.Item>
              ))}
            </Nav>
          </Col>
          <Col className="col-10">
            <Switch>
              <Route
                path={`${match.path}/:assetId`}
                render={({ match: _match }) => {
                  return !!amountOfBadges && <BadgeView item={items[_match.params.assetId]} refetch={refetch} />;
                }}
              />
              <Redirect to={`${match.path}/${Object.keys(items)[0]}`} />
            </Switch>
          </Col>
        </Row>
      )}
    </>
  );
};

export default BadgesView;
