import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

import { useQuery } from '@apollo/client';
import { ParameterTypes } from '../tasks';
import AddContentPack from '../../../../curation/screens/ContentPackList/AddContentPack';
import { GetTasksQuery, ContentItemsQuery, ContentItemsQueryVariables } from '../../../../../../__gqltypes__';
import { GET_SONG_QUERY } from '../graphql';

type Props = {
  // The parameter type
  type: GetTasksQuery['liveOps']['missionTasks'][0]['params'][0]['__typename'];
  // The value of this field
  value: string | number;
  name: string;
  onChange: (newValue: string | number) => void;
  availableCategories: Array<{
    id: string;
    name: string;
  }>;
  availableContentPacks: Array<{
    id: string;
    name: string;
  }>;
};

function songIdsStringToArray(songIdsString: string) {
  if (typeof songIdsString === 'string') {
    return songIdsString.split(',').map((id) => id.trim());
  }
  return songIdsString;
}

/**
 * Turn a parameter into an edit field adapted to its type (string=> text, int => number, category => selectBox)
 * onChange returns directly the updated value
 *
 * If given value is null, onChange is first triggered with the default value for the required field
 *
 */
export function TaskParameter({ type, value, name, onChange, availableCategories, availableContentPacks }: Props) {
  const [songs, setSongs] = useState<ContentItemsQuery['getSongSampleByIds']>([]);
  function useGetSongs() {
    const { data, error } = useQuery<ContentItemsQuery, ContentItemsQueryVariables>(GET_SONG_QUERY, {
      variables: { songIds: songIdsStringToArray(value as string) },
      skip: type !== ParameterTypes.MissionParamSong,
    });
    return data?.getSongSampleByIds;
  }
  const newSongs = useGetSongs();
  useEffect(() => {
    if (newSongs) {
      setSongs(newSongs);
    }
  }, [newSongs]);

  switch (type) {
    case ParameterTypes.MissionParamInt:
    case ParameterTypes.MissionParamHour24:
    case ParameterTypes.MissionParamFloat:
      return (
        <Form.Group>
          <Form.Label>{name}</Form.Label>
          <Form.Control type="number" onChange={(event) => onChange(Number(event.target.value))} value={value ?? 0} />
        </Form.Group>
      );
    case ParameterTypes.MissionParamString:
      return (
        <Form.Group>
          <Form.Label>{name}</Form.Label>
          <Form.Control type="text" onChange={(event) => onChange(event.target.value)} value={value} />
        </Form.Group>
      );
    case ParameterTypes.MissionParamContentCategory:
      return (
        <Form.Group>
          <Form.Label>{name}</Form.Label>{' '}
          <Form.Control as="select" onChange={(event) => onChange(event.target.value)} value={value}>
            {availableCategories.map((category, index) => (
              <option key={`edit-task-modal-category-${index}`} value={category.id}>
                {category.name} : {category.id}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      );
    case ParameterTypes.MissionParamSong:
      return (
        <Form.Group>
          <Form.Label>{name}</Form.Label>
          <Form.Control as="textarea" onChange={(event) => onChange(event.target.value)} value={value} />
          {songs.map((songSample, index) => {
            if (songSample) {
              return (
                <div key={index}>
                  <span>
                    {songSample.song.title} - {songSample.song.artist}
                  </span>
                </div>
              );
            }
            return (
              <div key={index}>
                No song found with condition &#123; &quot;id&quot; : &quot;
                {songIdsStringToArray(value as string)[index]}&quot; &#125;
              </div>
            );
          })}
        </Form.Group>
      );
    case ParameterTypes.MissionParamContentPack:
      return (
        <Form.Group>
          <div>
            <Form.Label>{name}: </Form.Label>
            {value == null ? (
              <span> No Content pack selected </span>
            ) : (
              <span>
                {availableContentPacks.find((contentPack) => contentPack.id === value)?.name} : {value}
              </span>
            )}
          </div>

          <AddContentPack
            title="Select a Content Pack"
            add={(selectedContentPack) => onChange(selectedContentPack.id)}
            curPacks={[]}
          />
        </Form.Group>
      );
    default:
      console.error(
        'This MissionParam type is not supported, please update the getEditFieldForType function: type',
        type
      );
      return null;
  }
}
