import { useMutation } from '@apollo/client';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useDropzone } from 'react-dropzone';
import Loader from '../components/Loader';
import mutations from '../utils/mutations';

type Props = {
  onCSVFile: (content: string) => void;
};

const ENCODINGS = ['ogg', 'mp3', 'aac', 'wav', 'sp2'];

const DropCSVFile = ({ onCSVFile }: Props) => {
  const [selectedFilename, setSelectedFilename] = useState<string | null>(null);

  const onDrop = async (files: File[]) => {
    if (files.length > 0) {
      const contentText = await files[0].text();
      console.log('te:', contentText);
      onCSVFile(contentText);
      setSelectedFilename(files[0].name);
    }
  };

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  return (
    <section className="container">
      <div
        {...getRootProps({ className: 'dropzone text-center' })}
        style={
          isDragActive
            ? {
                border: '2px solid white',
                backgroundColor: '#4e73df',
                color: 'white',
                borderRadius: '10px',
              }
            : { border: selectedFilename ? '2px solid #50C878' : '2px solid #4e73df', borderRadius: '10px' }
        }
      >
        <input {...getInputProps()} />
        {selectedFilename ? <p>Current selection: {selectedFilename}</p> : <p>Drag a csv file here</p>}
      </div>
    </section>
  );
};

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const Samples = () => {
  const [formats, setFormats] = useState<string[]>([]);
  const [contentPackIds, setContentPackIds] = useState<string[]>([]);

  const [convertSamples, { loading: loadingSample, data: dataSample }] = useMutation(mutations.CONVERT_SAMPLES);
  const [convertSamplesForSP2, { loading, data }] = useMutation(mutations.CONVERT_TO_SP2_SAMPLES);

  const handleCSVFile = async (content: string) => {
    const plIds = content.split('\n');
    setContentPackIds(plIds);
  };

  const handleStartConversion = async () => {
    let index = 0;
    const toFormats = formats;

    for (const cpId of contentPackIds) {
      for (const f of toFormats) {
        if (f === 'sp2') {
          await convertSamplesForSP2({ variables: { input: { contentPackId: cpId } } });
        } else {
          await convertSamples({ variables: { input: { contentPackId: contentPackIds[0], format: f } } });
        }
      }
      await sleep(1000 + Math.random() * 10000);
      index++;
      console.log('done', index);
    }
  };

  return (
    <>
      <Row className="justify-content-between mb-2">
        <Col lg={4}>
          <h1>Samples</h1>
          <p>Convert all the samples to a sp2 format:</p>
        </Col>
      </Row>
      <Row className="d-flex align-items-end">
        <Col xs={6} lg={3}>
          <>
            Select a playlist
            <Form.Control placeholder="playlistId" onChange={(event) => setContentPackIds([event.target.value])} />
          </>
          <hr />
          <div className="mt-2">
            Or select a csv file
            <DropCSVFile onCSVFile={handleCSVFile} />
          </div>
          <hr />

          <div key="inline-checkbox" className="mb-3">
            Select encoding
            <br />
            {ENCODINGS.map((label) => {
              return (
                <Form.Check
                  inline
                  label={label}
                  onChange={(e) => {
                    const newValue = e.target.checked;
                    const selectedFormat = label;

                    const newFormats = [...formats].filter((f) => f !== label);
                    if (newValue) {
                      newFormats.push(selectedFormat);
                    }
                    setFormats(newFormats);
                  }}
                  name="group1"
                  type="checkbox"
                  id={`inline-checkbox-${label}`}
                />
              );
            })}
          </div>
        </Col>

        <Col xs={6} lg={3}>
          {contentPackIds.length > 0 && formats.length > 0 && (
            <Button style={{ width: '100%' }} onClick={handleStartConversion}>
              <i className="fas fa-rocket mr-2" />
              Start the conversion!
            </Button>
          )}
        </Col>
        {(loading || loadingSample || dataSample || data) && (
          <Col xs={12} lg={3}>
            {loading || loadingSample ? (
              <Loader />
            ) : data.convertToSp2Samples.success ? (
              'The conversion has started!'
            ) : (
              data.convertToSp2Samples.message || 'Problem while starting the conversion'
            )}
          </Col>
        )}
      </Row>
    </>
  );
};

export default Samples;
