import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import Table from 'react-bootstrap/Table';
import { widgetDefaultProps, widgetPropTypes } from '../widgetPropTypes';

const BaseTable = ({ id, data, title }) => {
  const sanitize = (label) => {
    const _label = label.endsWith('_') ? label.slice(0, -1) : label;
    const sanitizedLabel = _label ? _label.replace(/_/g, ' ').toLowerCase().split(' ') : '';
    for (let i = 0; i < sanitizedLabel.length; i++) {
      sanitizedLabel[i] = sanitizedLabel[i][0].toUpperCase() + sanitizedLabel[i].slice(1);
    }
    return sanitizedLabel.join(' ');
  };

  if (!Object.keys(data.data).length) {
    return (
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>No data provided</th>
          </tr>
        </thead>
      </Table>
    );
  }

  let keys = Object.keys(data.data);
  const labels = data.getLabelColumn();
  const rows = {};
  // weird thing here, date are returned as {value: "YYYY-MM-DD"}
  // coming from the backend
  keys.forEach((col) => {
    if (col !== data.indexColLabel) {
      rows[col] = data.data[col].map((value) => (
        <td key={col.toLowerCase()}>{_.isString(value) ? value : value?.value ?? value}</td>
      ));
    }
  });

  // workaround, put index always first
  if (keys.includes('index')) {
    const keyIndex = keys.indexOf('index');
    keys.splice(keyIndex, 1);
    keys = ['index', ...keys];
  }

  return (
    <div className="p-3">
      <div style={{ fontWeight: 'bold', color: '#666666', textAlign: 'center' }}>{title}</div>
      <br />
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            {keys.map((col) => (
              <th key={col.toLowerCase()}>{sanitize(col)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {labels.map((label, index) => (
            <tr key={`item_${index}`}>
              <td>{_.isString(label) && moment(label).isValid() ? moment(label).format('MMM D') : label}</td>
              {keys.map((col) => rows[col] && rows[col][index])}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

BaseTable.propTypes = widgetPropTypes;
BaseTable.defaultProps = widgetDefaultProps;

export default BaseTable;
