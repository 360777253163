import Image from 'react-bootstrap/Image';
import { AppInformationQuery } from '../../../../../../__gqltypes__';
import SelectAdd from './SelectAdd';

type Props = {
  categories: AppInformationQuery['app']['contentCategories'];
  onChange: (fragment: { id: string; amount: number; category: { id: string; name: string } }, value: boolean) => void;
};

export default function MusicFragmentAdd({ categories, onChange }: Props) {
  const options: Record<string, { value: any; searchValue: string }> = {};
  categories.forEach((category) => {
    options[category.id] = {
      value: (
        <>
          <Image roundedCircle className="img-profile mr-3" src={category.icon} style={{ width: 20, height: 20 }} />
          {category.name}
        </>
      ),
      searchValue: category.name,
    };
  });

  const onAdd = (key: string) => {
    const category = categories.find((v) => v.id === key);
    if (category) {
      onChange(
        {
          id: category.id,
          amount: 0,
          category: { id: category.id, name: category.name },
        },
        true
      );
    }
  };

  return <SelectAdd buttonTitle="Add fragments" title="Select fragment to add" options={options} onAdd={onAdd} />;
}
