import moment from 'moment';
import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

export interface DatetimeInputProps {
  datetime: Date | undefined;
  setDatetime: React.Dispatch<React.SetStateAction<Date | undefined>>;
}

export default function DatetimeInput({ datetime, setDatetime }: DatetimeInputProps) {
  const [date, setDate] = useState<string | undefined>((datetime ? moment(datetime) : moment()).format('YYYY-MM-DD'));
  const [time, setTime] = useState<string | undefined>((datetime ? moment(datetime) : moment()).format('HH:mm:ss'));

  useEffect(() => {
    const newMoment = moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm:ss');
    if (newMoment.isValid()) {
      setDatetime(newMoment.toDate());
    } else {
      setDatetime(undefined);
    }
  }, [date, time, setDatetime]);

  return (
    <Row className="mt-3">
      <Col>
        <Form.Group>
          <Form.Label>Date</Form.Label>
          <Form.Control
            type="date"
            value={date}
            onChange={(e) => {
              setDate(e.target.value || undefined);
            }}
          />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Label>Time</Form.Label>
          <Form.Control
            type="time"
            value={time}
            onChange={(e) => {
              setTime(e.target.value || undefined);
            }}
          />
        </Form.Group>
      </Col>
    </Row>
  );
}
