import DashboardContainer from '../../../../../../utils/dashboards/components/DashboardContainer';
import { ABTestSlicing } from '../../../../../../utils/dashboards/components/slicers';
import LineChart from '../../../../../../utils/dashboards/components/widgets/charts/LineChart';
// import PieChart from '../../../../../../utils/dashboards/components/widgets/charts/PieChart';
// import BarChart from '../../../../../../utils/dashboards/components/widgets/charts/BarChart';
import { SectionDisplay } from '../../../../../../utils/dashboards/components/widgets';
import BaseTable from '../../../../../../utils/dashboards/components/widgets/tables/BaseTable';
import WidgetContainer from '../../../../../../utils/dashboards/components/widgets/WidgetContainer';
import Processing from '../../../../../../utils/dashboards/models/Processing';

const queries = {
  globalResultsNewUsers: { fillZeroes: true },
  dailyResultsNewUsers: { fillZeroes: true },
  globalResultsAllUsers: { fillZeroes: true },
  dailyResultsAllUsers: { fillZeroes: true },
};

const UserABTest = () => (
  <DashboardContainer queries={queries} id="ab_test" title="User ABTest">
    <ABTestSlicing id="ABTestSlicing" />
    <WidgetContainer
      id="WidgetContainer"
      notes="List of AB tests and results : https://docs.google.com/spreadsheets/d/1qJBRwfDu04gdTyp4mhgdJFJPoGMQqr_Sg4A4kVNfWdo/edit#gid=0"
    >
      <BaseTable id="globalResultsNewUsers" title="Global Results New Users" />
      <BaseTable id="globalResultsAllUsers" title="Global Results All Users" />
      <SectionDisplay notes="New Users" />
      <LineChart
        id="installs"
        title="New App Installs"
        processing={Processing.pivot('dailyResultsNewUsers', 'variation', 'nb_installs', 'date')}
      />
      <LineChart
        id="CRInstallToRegister"
        title="CR Install to Register"
        processing={Processing.pivot('dailyResultsNewUsers', 'variation', 'CR_install_to_register', 'date')}
      />
      <LineChart
        id="retention_d1"
        title="Retention D1"
        processing={Processing.pivot('dailyResultsNewUsers', 'variation', 'retention_D1', 'date')}
      />
      <LineChart
        id="retention_d3"
        title="Retention D3"
        processing={Processing.pivot('dailyResultsNewUsers', 'variation', 'retention_D3', 'date')}
      />
      <LineChart
        id="retention_d7"
        title="Retention D7"
        processing={Processing.pivot('dailyResultsNewUsers', 'variation', 'retention_D7', 'date')}
      />
      <LineChart
        id="retention_d15"
        title="Retention D15"
        processing={Processing.pivot('dailyResultsNewUsers', 'variation', 'retention_D15', 'date')}
      />
      <LineChart
        id="retention_d30"
        title="Retention D30"
        processing={Processing.pivot('dailyResultsNewUsers', 'variation', 'retention_D30', 'date')}
      />
      <LineChart
        id="retention_d60"
        title="Retention D60"
        processing={Processing.pivot('dailyResultsNewUsers', 'variation', 'retention_D60', 'date')}
      />
      <LineChart
        id="trial_d1"
        title="Trial CR D1"
        processing={Processing.pivot('dailyResultsNewUsers', 'variation', 'trial_cr_d1', 'date')}
      />
      <LineChart
        id="trial_d3"
        title="Trial CR D3"
        processing={Processing.pivot('dailyResultsNewUsers', 'variation', 'trial_cr_d3', 'date')}
      />
      <LineChart
        id="trial_d7"
        title="Trial CR D7"
        processing={Processing.pivot('dailyResultsNewUsers', 'variation', 'trial_cr_d7', 'date')}
      />
      <LineChart
        id="paying_d7"
        title="Paying CR D7"
        processing={Processing.pivot('dailyResultsNewUsers', 'variation', 'paying_cr_d7', 'date')}
      />
      <LineChart
        id="paying_d10"
        title="Paying CR D10"
        processing={Processing.pivot('dailyResultsNewUsers', 'variation', 'paying_cr_d10', 'date')}
      />
      <LineChart
        id="ltv_iap_d1"
        title="LTV IAP D1"
        processing={Processing.pivot('dailyResultsNewUsers', 'variation', 'ltv_iap_d1', 'date')}
      />
      <LineChart
        id="ltv_iap_d3"
        title="LTV IAP D3"
        processing={Processing.pivot('dailyResultsNewUsers', 'variation', 'ltv_iap_d3', 'date')}
      />
      <LineChart
        id="ltv_iap_d7"
        title="LTV IAP D7"
        processing={Processing.pivot('dailyResultsNewUsers', 'variation', 'ltv_iap_d7', 'date')}
      />
      <LineChart
        id="ltv_ads_d1"
        title="LTV Ads D1"
        processing={Processing.pivot('dailyResultsNewUsers', 'variation', 'ltv_ads_d1', 'date')}
      />
      <LineChart
        id="ltv_ads_d3"
        title="LTV Ads D3"
        processing={Processing.pivot('dailyResultsNewUsers', 'variation', 'ltv_ads_d3', 'date')}
      />
      <LineChart
        id="ltv_ads_d7"
        title="LTV Ads D7"
        processing={Processing.pivot('dailyResultsNewUsers', 'variation', 'ltv_ads_d7', 'date')}
      />
      <LineChart
        id="nb_games_d1"
        title="Nb Games D1"
        processing={Processing.pivot('dailyResultsNewUsers', 'variation', 'nb_games_d1', 'date')}
      />
      <LineChart
        id="nb_games_d3"
        title="Nb Games D3"
        processing={Processing.pivot('dailyResultsNewUsers', 'variation', 'nb_games_d3', 'date')}
      />
      <LineChart
        id="nb_games_d7"
        title="Nb Games D7"
        processing={Processing.pivot('dailyResultsNewUsers', 'variation', 'nb_games_d7', 'date')}
      />
      <SectionDisplay notes="All Users" />
      <LineChart
        id="dau"
        title="DAU"
        processing={Processing.pivot('dailyResultsAllUsers', 'variation', 'dau', 'date')}
      />
      <LineChart
        id="total_arpdau"
        title="Avg Revenue Per DAU (includes IAP, VIP and ADS revenue)"
        processing={Processing.pivot('dailyResultsAllUsers', 'variation', 'total_arpdau', 'date')}
      />
      <LineChart
        id="vip_arpdau"
        title="VIP Avg Revenue Per DAU"
        processing={Processing.pivot('dailyResultsAllUsers', 'variation', 'vip_arpdau', 'date')}
      />
      <LineChart
        id="perc_vip_paying_users"
        title="Percentage of VIP Paying Users"
        processing={Processing.pivot('dailyResultsAllUsers', 'variation', 'perc_vip_paying_users', 'date')}
      />
      <LineChart
        id="iap_arpdau"
        title="IAP Avg Revenue Per DAU"
        processing={Processing.pivot('dailyResultsAllUsers', 'variation', 'iap_arpdau', 'date')}
      />
      <LineChart
        id="perc_iap_paying_users"
        title="Percentage of IAP Paying Users"
        processing={Processing.pivot('dailyResultsAllUsers', 'variation', 'perc_iap_paying_users', 'date')}
      />
      <LineChart
        id="gamesPerDAU"
        title="Games Played Per DAU"
        processing={Processing.pivot('dailyResultsAllUsers', 'variation', 'gamesPerDAU', 'date')}
      />
      <LineChart
        id="turn_gamesPerDAU"
        title="Turn Games Played Per DAU"
        processing={Processing.pivot('dailyResultsAllUsers', 'variation', 'turn_gamesPerDAU', 'date')}
      />
      <LineChart
        id="tournament_gamesPerDAU"
        title="Tournament Games Played Per DAU"
        processing={Processing.pivot('dailyResultsAllUsers', 'variation', 'tournament_gamesPerDAU', 'date')}
      />
      <LineChart
        id="rewarded_adpdau"
        title="Avg Rewarded Ads Seen Per DAU"
        processing={Processing.pivot('dailyResultsAllUsers', 'variation', 'rewarded_adpdau', 'date')}
      />
      <LineChart
        id="interstitial_adpdau"
        title="Avg Interstitial Ads Seen Per DAU"
        processing={Processing.pivot('dailyResultsAllUsers', 'variation', 'interstitial_adpdau', 'date')}
      />
      <LineChart
        id="rewarded_adrpdau"
        title="Avg Rewarded Ads Revenue Per DAU"
        processing={Processing.pivot('dailyResultsAllUsers', 'variation', 'rewarded_adrpdau', 'date')}
      />
      <LineChart
        id="interstitial_adrpdau"
        title="Avg Interstitial Ads Revenue Per DAU"
        processing={Processing.pivot('dailyResultsAllUsers', 'variation', 'interstitial_adrpdau', 'date')}
      />
    </WidgetContainer>
  </DashboardContainer>
);

export default UserABTest;
