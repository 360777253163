import { useMutation } from '@apollo/client';
import { Dropdown, DropdownButton, Form, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import {
  ContentPackType,
  CurationContentPackQuery,
  ToggleCurationContentPackAdultOnlyTagMutation,
  ToggleCurationContentPackAdultOnlyTagMutationVariables,
  ToggleSp2OnlyTagMutation,
  ToggleSp2OnlyTagMutationVariables,
  UpdateCurationContentPackTypeMutation,
  UpdateCurationContentPackTypeMutationVariables,
} from '../../../../../../__gqltypes__';
import PlaylistExplicitIcon from '../../../components/PlaylistExplicitIcon';
import mutations from '../../../utils/mutations';

const Timestamp = ({ title, time }: { title: string; time: number | null }) => {
  if (!time) return null;
  return (
    <>
      {`${title} on: ${new Date(time).toLocaleString('en-US')}`}
      <br />
    </>
  );
};

type Props = {
  curationContentPack: CurationContentPackQuery['curationContentPack'];
};

export default function PlaylistDetails({ curationContentPack }: Props) {
  const [updatePlaylistCategories, { loading }] = useMutation<
    UpdateCurationContentPackTypeMutation,
    UpdateCurationContentPackTypeMutationVariables
  >(mutations.UPDATE_CURATION_CONTENT_PACK_TYPE);
  const [updateSP2Only, { loading: checkLoading }] = useMutation<
    ToggleSp2OnlyTagMutation,
    ToggleSp2OnlyTagMutationVariables
  >(mutations.TOGGLE_SP2_TAG);
  const [updateAdultOnly, { loading: checkAdultLoading }] = useMutation<
    ToggleCurationContentPackAdultOnlyTagMutation,
    ToggleCurationContentPackAdultOnlyTagMutationVariables
  >(mutations.TOGGLE_ADULT_TAG);

  const selectPlaylistType = (newType: ContentPackType) => {
    updatePlaylistCategories({ variables: { input: { id: curationContentPack.id, type: newType } } });
  };

  const updateSP2OnlyTag = () => {
    updateSP2Only({ variables: { input: { id: curationContentPack.id } } });
  };

  const updateAdultOnlyTag = () => {
    updateAdultOnly({ variables: { input: { id: curationContentPack.id } } });
  };

  return (
    <Card>
      <Card.Body>
        <div>
          <Timestamp title="Created" time={curationContentPack.created} />
          <Timestamp title="Committed" time={curationContentPack.committed} />
          {/* @ts-ignore */}
          <Timestamp title="Outdated" time={curationContentPack.outdated} />
          <Timestamp title="Deleted" time={curationContentPack.deleted} />
          {curationContentPack.externalURI && (
            <div>
              {curationContentPack.externalURI.startsWith('spotify')
                ? 'Spotify URI: '
                : curationContentPack.externalURI.startsWith('artists')
                ? 'Spotify artists: '
                : curationContentPack.externalURI.startsWith('sp2')
                ? 'SP2: '
                : 'Invalid URI'}
              <code>
                <span className="text-sm">{curationContentPack.externalURI}</span>
              </code>
            </div>
          )}
          {`${curationContentPack.contentItems.length} songs`}
          <div>
            {'Explicit status: '}
            <PlaylistExplicitIcon explicitStatus={curationContentPack.explicitContentStatus} showClean />
          </div>
          <div>
            <Row className="mx-0">
              {'Playlist Type: '}
              {loading ? (
                'Updating...'
              ) : (
                <DropdownButton
                  size="sm"
                  className="mx-2"
                  defaultValue={curationContentPack.type}
                  title={curationContentPack.type}
                  // @ts-ignore
                  onSelect={selectPlaylistType}
                >
                  {[ContentPackType.ARTIST, ContentPackType.PLAYLIST].map((type) => (
                    <Dropdown.Item
                      eventKey={type}
                      key={`dropdown-type-${type}`}
                      active={curationContentPack.type === type}
                    >
                      {type}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              )}
            </Row>
          </div>
          <div>
            <Row className="mx-0">
              {checkLoading ? (
                'Updating...'
              ) : (
                <Form.Check
                  type="checkbox"
                  label="SP2 Only"
                  checked={!!curationContentPack.sp2Only}
                  onChange={updateSP2OnlyTag}
                />
              )}
            </Row>
            <Row className="mx-0">
              {checkAdultLoading ? (
                'Updating...'
              ) : (
                <Form.Check
                  type="checkbox"
                  label="Adult Only"
                  checked={!!curationContentPack.adultOnly}
                  onChange={updateAdultOnlyTag}
                />
              )}
            </Row>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}
