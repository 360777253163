import moment from 'moment';
import { useEffect, useState } from 'react';
import { Alert, Card, Spinner } from 'react-bootstrap';
import { MetricType } from '../../../../../../__gqltypes__';
import useInteractiveMonitoringQuery from '../hooks/useInteractiveMonitoringQuery';
import buildRequest from '../logic/buildRequest';
import { decodeUrl } from '../logic/buildUrl';
import { DownloadCsvButton } from './DownloadCsvButton';
import TimeLineGraph from './TimeLineGraph';

interface MonitorProps {
  search: string;
}

function getBilledMegaBytesFromSerie(bytesProcessed?: number) {
  if (!bytesProcessed) return 0;
  if (bytesProcessed < 10485760) return 10;
  return Math.round(bytesProcessed / 1048576);
}
function getBill(serie: { bytesProcessed: number } | null, previousSerie: { bytesProcessed: number } | null) {
  const MBBilled =
    getBilledMegaBytesFromSerie(serie?.bytesProcessed) + getBilledMegaBytesFromSerie(previousSerie?.bytesProcessed);
  return `${MBBilled}MB billed (${((MBBilled / 1048576) * 5).toPrecision(2)}$)`;
}

export default function Monitor({ search }: MonitorProps) {
  const {
    serie,
    previousSerie,
    importantEvents,
    refetch,
    computedIn,
    serieLoading,
    previousSerieLoading,
    getFullSerie,
    fullSerieLoading,
  } = useInteractiveMonitoringQuery();
  const [error, setError] = useState<string | null>();
  const [type, setType] = useState<MetricType>(MetricType.GQLREQUEST);

  useEffect(() => {
    const decoded = decodeUrl(search);
    if (decoded.errors.length > 0) {
      setError(decoded.errors.join(', '));
      return;
    }

    const request = buildRequest(decoded.validatedDecoded);
    setError(request.error);

    if (request.error === null) {
      setType(decoded.validatedDecoded.type);
      refetch(request.query, request.variables, request.comparisonQuery, request.getComparisonVariables);
    }
  }, [search, refetch]);

  if (error) {
    return (
      <Alert className="mt-3" variant="danger">
        {error}
      </Alert>
    );
  }

  return (
    <Card className="mt-3 mb-3">
      <Card.Body style={{ height: 'calc(70vh + 1.5rem + 20px)' }}>
        {serie && <TimeLineGraph serie={serie} previousSerie={previousSerie} events={importantEvents} type={type} />}
      </Card.Body>
      <Card.Footer>
        <div className="d-flex justify-content-between">
          <div>
            <small>
              Computed on {moment().format('YYYY-MM-DD HH:mm:ss')}, in {computedIn}ms, {getBill(serie, previousSerie)}
            </small>
          </div>
          <div>
            <DownloadCsvButton getFullSerie={getFullSerie} fullSerieLoading={fullSerieLoading} />
          </div>
          <div>
            {serieLoading && (
              <>
                <Spinner animation="grow" size="sm" className="mr-2 ml-4" />
                Main serie loading...
              </>
            )}
            {previousSerieLoading && (
              <>
                <Spinner animation="grow" size="sm" className="mr-2 ml-4" />
                Comparison serie loading...
              </>
            )}
          </div>
        </div>
      </Card.Footer>
    </Card>
  );
}
