import { useLazyQuery, useMutation } from '@apollo/client';
import { useState } from 'react';
import { Accordion, Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import { GET_SP2_LINK_STATE, LINK_TO_SP2_ID, UNLINK_ID_PAIR } from '../../../graphql';

const Sp2LinkDisplay = ({ userId }: { userId: string }) => {
  const [expanded, setExpanded] = useState(false);
  const [loadLinkState, queryResult] = useLazyQuery(GET_SP2_LINK_STATE, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });
  const [unlinkIdPair, unlinkResult] = useMutation(UNLINK_ID_PAIR, { notifyOnNetworkStatusChange: true });
  const [linkToSp2Id, linkResult] = useMutation(LINK_TO_SP2_ID, { notifyOnNetworkStatusChange: true });
  const toggleExpanded = () => {
    if (!expanded) {
      loadLinkState({ variables: { input: { sp3id: userId } } });
    }
    setExpanded(!expanded);
  };

  const unlinkClicked = (sp2id: string) => {
    if (window.confirm('This cannot be undone, are you sure?')) {
      unlinkIdPair({ variables: { input: { sp3id: userId, sp2id } } }).then(() => {
        loadLinkState({ variables: { input: { sp3id: userId } } });
      });
    }
  };

  const linkClicked = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const target = event.target as HTMLFormElement;
    const sp2id = (target.elements.namedItem('idInput') as HTMLInputElement).value.trim();
    if (sp2id.length === 0) {
      return;
    }
    linkToSp2Id({ variables: { input: { sp3id: userId, sp2id } } }).then(() => {
      loadLinkState({ variables: { input: { sp3id: userId } } });
    });
  };

  return (
    <Card className="mb-4">
      <Card.Body>
        <Accordion>
          <Accordion.Toggle as={Card.Title} variant="link" eventKey="0" onClick={toggleExpanded}>
            <div style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}>
              SP2 Link
              <Button variant="link">
                {!expanded && <i className="fas fa-chevron-down" />}
                {expanded && <i className="fas fa-chevron-up" />}
              </Button>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <>
              {linkResult?.data?.linkToSp2Admin?.error && (
                <Row>
                  Link error: {linkResult.data.linkToSp2Admin.error} : {linkResult.data.linkToSp2Admin.errorMessage}
                </Row>
              )}
              {unlinkResult?.data?.unlinkIdPair?.error && (
                <Row>
                  Link error: {linkResult.data.unlinkIdPair.error} : {linkResult.data.unlinkIdPair.errorMessage}
                </Row>
              )}
              {queryResult?.loading || unlinkResult?.loading || linkResult?.loading ? (
                <Col className="m-2">
                  <Spinner animation="border" />
                </Col>
              ) : (
                <>
                  {queryResult.data && (
                    <Col className="m-2">
                      <Row>
                        <b>Link state: </b> {queryResult.data.getLinkStateAdmin.state}
                      </Row>
                      <Row>
                        <b>SP2 id: </b> {queryResult.data.getLinkStateAdmin.sp2id}
                      </Row>
                      {queryResult.data.getLinkStateAdmin.sp2id ? (
                        <Row>
                          <Button onClick={() => unlinkClicked(queryResult.data.getLinkStateAdmin.sp2id)}>
                            Unlink
                          </Button>
                        </Row>
                      ) : (
                        <Form inline onSubmit={linkClicked}>
                          <Form.Label>Link to SP2 id: </Form.Label>
                          <Form.Control id="idInput" type="input" />
                          <Button type="submit">Link</Button>
                        </Form>
                      )}
                    </Col>
                  )}
                </>
              )}
            </>
          </Accordion.Collapse>
        </Accordion>
      </Card.Body>
    </Card>
  );
};

export default Sp2LinkDisplay;
