import { useMutation } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal, { ModalProps } from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import type {
  ResetLoyaltyCardMutation,
  ResetLoyaltyCardMutationVariables,
  ReviveLoyaltyCardMutation,
  ReviveLoyaltyCardMutationVariables,
  ShiftLoyaltyCardMutation,
  ShiftLoyaltyCardInput,
  ShiftLoyaltyCardMutationVariables,
  UpdateLoyaltyCardMutation,
  UpdateLoyaltyCardMutationVariables,
  SearchUserQuery,
} from '../../../../../../__gqltypes__';
import { RESET_LOYALTY_CARD, REVIVE_LOYALTY_CARD, SHIFT_LOYALTY_CARD, UPDATE_LOYALTY_CARD } from '../../../graphql';

type Loyalty = NonNullable<SearchUserQuery['user']>['loyalty'];
type FutureCard = NonNullable<Loyalty['futureCard']>;
type DailyReward = FutureCard['days'][0]['rewards'][0];
type PreviousCard = NonNullable<Loyalty['previousCard']>;

const describeReward = ({
  __typename,
  // @ts-ignore
  amount,
  // @ts-ignore
  boostType,
  // @ts-ignore
  unlimitedBoostType,
}: DailyReward) => {
  let desc: string = __typename;
  if (amount) {
    desc = `${amount} ${desc}`;
  }
  const type = boostType ?? unlimitedBoostType;
  if (type) {
    desc = `${desc}.${type}`;
  }
  return desc;
};

const formatTimestamp = (timestamp: number) => new Date(timestamp).toISOString();

const describeDay = (
  dayIdx: number,
  day: any,
  currentDayIndex: number,
  nextClaimableDayIndex: number,
  isLatest: boolean
) => {
  const parts = [];
  if (dayIdx === currentDayIndex && isLatest) {
    parts.push('current');
    if (dayIdx === nextClaimableDayIndex) {
      parts.push('claimable');
    }
  }
  if (day.isClaimed) {
    parts.push(`claimed x${day.claimedMultiplier} at ${formatTimestamp(day.claimedDate)}`);
  }
  if (parts.length > 0) {
    return `(${parts.join(', ')})`;
  }
  return '';
};

// Need it on beta during the test phase, we will block it on test later
// const isSetExpiredActivated = (process.env.REACT_APP_NAMESPACE ?? 'test') === 'test';
const isSetExpiredActivated = true;

type CardProps =
  | {
      title: string;
      card: PreviousCard;
      isLatest: false;
    }
  | {
      title: string;
      card: FutureCard;
      isLatest: true;
      shiftLoading: boolean;
      shiftLoyalty: ({ newCurrentDayIdx, newNextClaimableDayIdx }: Omit<ShiftLoyaltyCardInput, 'userId'>) => void;
    };

// @ts-ignore
const CardDisplay = ({ title, card, isLatest, shiftLoyalty, shiftLoading }: CardProps) => {
  return (
    <Card>
      <Card.Body className="p-2">
        <Card.Title className="mb-0">
          {title}
          {card.isExpired && <b> (expired)</b>}
        </Card.Title>
        <div className="mb-2">
          <small>({card.id})</small>
        </div>
        <div className="mb-2">
          <div>
            Start: {formatTimestamp(card.days[0].start)} / End: {formatTimestamp(card.days[card.days.length - 1].end)}
          </div>
          {isLatest && !card.isCompleted && card.nextClaimableDayIndex === card.currentDayIndex ? (
            <div>
              {/* @ts-ignore */}
              Claim for day {card.nextClaimableDayIndex + 1} possible from {/* @ts-ignore */}
              {formatTimestamp(card.days[card.nextClaimableDayIndex].start)} to {/* @ts-ignore */}
              {formatTimestamp(card.days[card.nextClaimableDayIndex].end)}.
            </div>
          ) : (
            <div>No claim possible.</div>
          )}
        </div>
        <ListGroup>
          {card.days.map((day, dayIdx) => (
            <ListGroup.Item className="mb-0 px-2 py-1 d-flex justify-content-between" key={dayIdx}>
              <span>
                <b>Day {dayIdx + 1}: </b>
                {day.rewards.map(describeReward).join(', ')}
                {/* @ts-ignore */}
                <b> {describeDay(dayIdx, day, card.currentDayIndex, card.nextClaimableDayIndex, isLatest)}</b>
              </span>
              {isLatest && (
                <ButtonGroup>
                  <Button
                    variant="outline-danger"
                    size="sm"
                    className="py-0"
                    onClick={() => shiftLoyalty({ newCurrentDayIdx: dayIdx, newNextClaimableDayIdx: dayIdx })}
                    disabled={shiftLoading}
                  >
                    Set current
                  </Button>
                  {isSetExpiredActivated && (
                    <Button
                      variant="outline-danger"
                      size="sm"
                      className="py-0"
                      onClick={() => shiftLoyalty({ newCurrentDayIdx: dayIdx + 1, newNextClaimableDayIdx: dayIdx })}
                      disabled={shiftLoading}
                    >
                      Set expired
                    </Button>
                  )}
                </ButtonGroup>
              )}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Card.Body>
    </Card>
  );
};

type UpdateModalProps = {
  loyalty: Loyalty;
  updateLoyalty: (newCardStreak: string | number, newTradeableCardCount: string | number) => Promise<void>;
  updateLoading: boolean;
} & ModalProps;

const UpdateModal = ({ show, close, loyalty, updateLoyalty, updateLoading }: UpdateModalProps) => {
  const [newCardStreak, setNewCardStreak] = useState(loyalty.cardStreak);
  const [newTradeableCardCount, setNewTradeableCardCount] = useState(loyalty.tradeableCardCount);
  const [validated, setValidated] = useState(false);

  // @ts-ignore
  const onSubmit = (event) => {
    event.preventDefault();
    setValidated(true);
    if (event.currentTarget.checkValidity()) {
      updateLoyalty(newCardStreak, newTradeableCardCount);
    }
  };

  useEffect(() => {
    if (show) {
      setValidated(false);
    }
  }, [show]);

  useEffect(() => {
    if (newCardStreak < newTradeableCardCount) {
      setNewTradeableCardCount(newCardStreak);
    }
  }, [newCardStreak]);

  return (
    <Modal show={show} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>Update current streak</Modal.Title>
      </Modal.Header>
      <Form noValidate validated={validated} onSubmit={onSubmit}>
        <Modal.Body>
          <Form.Group as={Row}>
            <Form.Label column sm="4">
              New card streak:
            </Form.Label>
            <Col>
              <Form.Control
                type="number"
                min="0"
                step="1"
                value={newCardStreak}
                // @ts-ignore
                onChange={(e) => setNewCardStreak(e.target.value)}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="4">
              New tradeable card count:
            </Form.Label>
            <Col>
              <Form.Control
                type="number"
                min="0"
                step="1"
                max={newCardStreak}
                value={newTradeableCardCount}
                // @ts-ignore
                onChange={(e) => setNewTradeableCardCount(e.target.value)}
              />
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={close}>
            Close
          </Button>
          <Button type="submit" variant="primary" disabled={updateLoading}>
            Update
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

type Props = {
  userId: string;
  loyalty: Loyalty;
};

const LoyaltyDisplay = ({ userId, loyalty }: Props) => {
  const [updateLoyaltyCard, { loading: updateLoading }] = useMutation<
    UpdateLoyaltyCardMutation,
    UpdateLoyaltyCardMutationVariables
  >(UPDATE_LOYALTY_CARD);
  const [resetLoyaltyCard, { loading: resetLoading }] = useMutation<
    ResetLoyaltyCardMutation,
    ResetLoyaltyCardMutationVariables
  >(RESET_LOYALTY_CARD);
  const [reviveLoyaltyCard, { loading: reviveLoading }] = useMutation<
    ReviveLoyaltyCardMutation,
    ReviveLoyaltyCardMutationVariables
  >(REVIVE_LOYALTY_CARD);
  const [shiftLoyaltyCard, { loading: shiftLoading }] = useMutation<
    ShiftLoyaltyCardMutation,
    ShiftLoyaltyCardMutationVariables
  >(SHIFT_LOYALTY_CARD);

  // @ts-ignore
  const latestCard: searchUser_user_loyalty_futureCard = useMemo(
    () => loyalty.futureCard ?? loyalty.currentCard,
    [loyalty]
  );

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const closeUpdateModal = () => setShowUpdateModal(false);

  const updateLoyalty = async (newCardStreak: string | number, newTradeableCardCount: string | number) => {
    await updateLoyaltyCard({
      variables: {
        input: {
          userId,
          cardId: latestCard.id,
          cardStreak: +newCardStreak,
          tradeableCardCount: +newTradeableCardCount,
        },
      },
    });
    closeUpdateModal();
  };
  const resetLoyalty = () => {
    if (window.confirm("Are you sure you want to reset this user's streak?")) {
      resetLoyaltyCard({ variables: { input: { userId } } });
    }
  };
  const reviveLoyalty = () => {
    if (window.confirm("Are you sure you want to revive this user's streak for free?")) {
      reviveLoyaltyCard({ variables: { input: { userId } } });
    }
  };
  const shiftLoyalty = ({ newCurrentDayIdx, newNextClaimableDayIdx }: Omit<ShiftLoyaltyCardInput, 'userId'>) => {
    if (window.confirm('Are you sure you want to force the current day for this user?\nNo rewards will be obtained.')) {
      shiftLoyaltyCard({ variables: { input: { userId, newCurrentDayIdx, newNextClaimableDayIdx } } });
    }
  };

  return (
    <Card className="mb-4">
      <Card.Body>
        <Accordion>
          <Accordion.Toggle as={Card.Title} variant="link" eventKey="0" onClick={() => setExpanded(!expanded)}>
            <div style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}>
              Loyalty
              <Button variant="link">
                {!expanded && <i className="fas fa-chevron-down" />}
                {expanded && <i className="fas fa-chevron-up" />}
              </Button>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <>
              <div className="d-flex justify-content-between">
                <ButtonGroup>
                  <Button variant="danger" onClick={resetLoyalty} disabled={resetLoading || !!loyalty.futureCard}>
                    Reset streak
                  </Button>
                  <Button variant="primary" onClick={() => setShowUpdateModal(true)} disabled={!latestCard}>
                    Update streak
                  </Button>
                  <Button variant="success" onClick={reviveLoyalty} disabled={reviveLoading || !latestCard?.isExpired}>
                    Revive streak
                  </Button>
                </ButtonGroup>
              </div>
              <p>
                Current streak:{' '}
                <b>
                  {loyalty.cardStreak} cards, {loyalty.dayStreak} days
                </b>
                <br />
                Tradable card count: <b>{loyalty.tradeableCardCount} cards</b>
                <br />
                Previous streak: <b>{loyalty.previousCardStreak} cards</b>
              </p>
              {loyalty.currentCard ? (
                <CardDisplay
                  title="Current loyalty card"
                  card={loyalty.currentCard}
                  isLatest={latestCard === loyalty.currentCard}
                  shiftLoyalty={shiftLoyalty}
                  shiftLoading={shiftLoading}
                />
              ) : (
                <p>No active loyalty card.</p>
              )}
              {loyalty.futureCard && (
                <CardDisplay
                  title="Future loyalty card"
                  card={loyalty.futureCard}
                  isLatest={latestCard === loyalty.futureCard}
                  shiftLoyalty={shiftLoyalty}
                  shiftLoading={shiftLoading}
                />
              )}
              {loyalty.previousCard && (
                <CardDisplay title="Previous loyalty card" card={loyalty.previousCard} isLatest={false} />
              )}
            </>
          </Accordion.Collapse>
        </Accordion>
      </Card.Body>

      <UpdateModal
        show={showUpdateModal}
        close={closeUpdateModal}
        loyalty={loyalty}
        updateLoyalty={updateLoyalty}
        updateLoading={updateLoading}
      />
    </Card>
  );
};

export default LoyaltyDisplay;
