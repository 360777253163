import { useMutation } from '@apollo/client';
import * as React from 'react';
import { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import type { SetSubscriptionTierMutation, SetSubscriptionTierMutationVariables } from '../../../../__gqltypes__';
import { UserSubscriptionTier } from '../../../../__gqltypes__';
import { SET_USER_SUBSCRIPTION_TIER } from '../graphql';

export default () => {
  const [searchString, setSearchString] = useState('');
  const [subscriptionTier, setSubscriptionTier] = useState(UserSubscriptionTier.BASIC);
  const [success, setSuccess] = useState(false);

  const [setUserSubscriptionTier, { error, loading }] = useMutation<
    SetSubscriptionTierMutation,
    SetSubscriptionTierMutationVariables
  >(SET_USER_SUBSCRIPTION_TIER);

  useEffect(() => {
    if (success) setTimeout(() => setSuccess(false), 2000);
  }, [success]);

  const resetForm = () => {
    setSearchString('');
    setSubscriptionTier(UserSubscriptionTier.BASIC);
  };

  const handleSearchStringChange = (event: React.ChangeEvent<HTMLInputElement>) => setSearchString(event.target.value);
  const handleTierChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSubscriptionTier(event.target.value as UserSubscriptionTier);
  const handleSuccess = () => {
    setSuccess(true);
    resetForm();
  };
  const handleSearch = () => {
    // the search string can be an email or a userId
    const input: { subscriptionTier: UserSubscriptionTier; email?: string; userId?: string } = { subscriptionTier };
    if (searchString.includes('@')) {
      input.email = searchString;
    } else {
      input.userId = searchString;
    }

    setUserSubscriptionTier({ variables: { input } }).then(handleSuccess).catch(console.error);
  };
  const handleKeyPressed = (target: React.KeyboardEvent<HTMLInputElement>) => {
    if (target.charCode === 13) {
      target.preventDefault();
      handleSearch();
    }
  };

  return (
    <Card className="shadow mb-4" body>
      <Form.Group>
        <Form.Row>
          <Col>
            <Form.Control
              name="searchString"
              value={searchString}
              placeholder="Enter email or UserId"
              onChange={handleSearchStringChange}
              onKeyPress={handleKeyPressed}
            />
          </Col>
          <Col>
            <Form.Control name="role" required as="select" value={subscriptionTier} onChange={handleTierChange}>
              {Object.entries(UserSubscriptionTier).map(([key, value]) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Row>
      </Form.Group>
      <div className="d-flex justify-content-center">
        <Button onClick={handleSearch} disabled={loading}>
          {loading ? 'Updating...' : 'Update'}
        </Button>
      </div>
      {error && (
        <div className="py-2">
          <Alert className="mb-1" variant="warning">
            An error has occurred
          </Alert>
        </div>
      )}
      {success && (
        <div className="py-2">
          <Alert className="mb-1" variant="success">
            User successfully updated!
          </Alert>
        </div>
      )}
    </Card>
  );
};
