import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import FormControl from 'react-bootstrap/FormControl';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import {
  AbTestType,
  GetAbTestsConfigsQuery,
  GetUserLastSessionQuery,
  SearchUserQuery,
} from '../../../../../../__gqltypes__';

type Props = {
  account: NonNullable<SearchUserQuery['user']>;
  abTestConfigsData: GetAbTestsConfigsQuery['liveOps']['ABTestConfigs'];
  lastSession: {
    data: GetUserLastSessionQuery['analytics']['userLastSession'];
    loading: boolean;
    called: boolean;
  };
  onBanUser: () => void;
  onUnbanUser: () => void;
  onResetUserSubscriptions: () => void;
  resetSubscriptionsLoading: boolean;
  onForceTest: (testId: string, variation: string) => void;
  forceTestLoading: boolean;
};

export default function AccountDisplay({
  account,
  abTestConfigsData,
  lastSession,
  onBanUser,
  onUnbanUser,
  onResetUserSubscriptions,
  resetSubscriptionsLoading,
  onForceTest,
  forceTestLoading,
}: Props) {
  const handleResetUserSubscriptions = () => {
    if (window.confirm("Are you sure you want to reset this user's subscriptions?")) {
      onResetUserSubscriptions();
    }
  };

  const { loading, called, data } = lastSession;

  const isTestEnv = process.env.REACT_APP_NAMESPACE === 'test';

  const forcedTests = useMemo(() => new Set(account.ABTestForcedList.map(({ id }) => id)), [account.ABTestForcedList]);
  const abTestList = useMemo(
    () =>
      new Map([...account.ABTestList, ...account.ABTestLastAppInstallList].map(({ id, ...config }) => [id, config])),
    [account.ABTestList, account.ABTestLastAppInstallList]
  );

  const [editedTest, setEditedTest] = useState<string | null>(null);
  const [expanded, setExpanded] = useState(false);
  const forceTest = useCallback(
    async (testId, variation) => {
      await onForceTest(testId, variation === 'unforced' ? null : variation);
      setEditedTest(null);
    },
    [onForceTest, setEditedTest]
  );
  return (
    <>
      <Card className="mb-4">
        <Card.Body>
          {/* @ts-ignore */}
          <Row fluid>
            <Col md="auto" className="ml-3">
              <Image
                roundedCircle
                className="img-profile"
                src={account.picture ?? undefined}
                style={{ width: 100, height: 100 }}
              />
            </Col>
            <Col>
              <Row>
                <Col>
                  <p>
                    <b>User Name:</b> {account.displayName}
                  </p>
                </Col>
                <Col>
                  {called && !loading ? (
                    data ? (
                      <p>
                        <b>Last Session Date:</b> {moment(Math.floor(data.date / 1000)).format('lll')}
                      </p>
                    ) : (
                      <p>No session found in the last 7 days</p>
                    )
                  ) : (
                    <Spinner animation="border" />
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>
                    <b>User ID: </b>
                    <Link
                      className="text-decoration-none p-0 m-0 text-left"
                      to={`/sp3/devtools/table/sessions/${account.id}`}
                    >
                      {account.id}
                    </Link>
                  </p>
                </Col>
                <Col>
                  {called && !loading && data && (
                    <p>
                      <b>Last Session ID: </b>
                      <Link
                        className="text-decoration-none p-0 m-0 text-left"
                        to={`/sp3/devtools/table/session/${data.id}`}
                      >
                        {data.id}
                      </Link>
                    </p>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>
                    <b>Subscription Status:</b> {account.subscriptionTier}
                    {!!account.subscriptionDetails && (
                      <Card className="w-50">
                        <Row>
                          <Col className="text-right">
                            <b>Active Since:</b>
                          </Col>
                          <Col>{new Date(account.subscriptionDetails.activeSince).toLocaleString()}</Col>
                        </Row>
                        <Row>
                          <Col className="text-right">
                            <b>Auto Renews:</b>
                          </Col>
                          <Col>{account.subscriptionDetails.autoRenews ? 'true' : 'false'}</Col>
                        </Row>
                        <Row>
                          <Col className="text-right">
                            <b>Expires:</b>
                          </Col>
                          <Col>{new Date(account.subscriptionDetails.expires).toLocaleString()}</Col>
                        </Row>
                        <Row>
                          <Col className="text-right">
                            <b>Free trial:</b>
                          </Col>
                          <Col>{account.subscriptionDetails.isFreeTrial ? 'true' : 'false'}</Col>
                        </Row>
                        <Row>
                          <Col className="text-right">
                            <b>Product ID:</b>
                          </Col>
                          <Col>{account.subscriptionDetails.sku}</Col>
                        </Row>
                      </Card>
                    )}
                  </p>
                </Col>
                <Col>
                  <p>
                    <b>Email address: </b> {account.emailCredential}
                    {account.testCredential && (
                      <>
                        <br />
                        <b>Test token: </b> {account.testCredential}
                      </>
                    )}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <>
                    {account.role === 'BANNED' ? (
                      <>
                        <p>
                          This user has been <b>BANNED</b>
                        </p>
                        <Button variant="primary" className="mx-2" onClick={onUnbanUser}>
                          Unban User
                        </Button>
                      </>
                    ) : (
                      <Button variant="danger" className="mx-2" onClick={onBanUser}>
                        Ban User
                      </Button>
                    )}
                    {isTestEnv &&
                      (resetSubscriptionsLoading ? (
                        <Spinner animation="border" />
                      ) : (
                        <Button variant="warning" className="mx-2" onClick={handleResetUserSubscriptions}>
                          Reset subscriptions
                        </Button>
                      ))}
                  </>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="mb-4">
        <Card.Body>
          <Accordion>
            <Accordion.Toggle as={Card.Title} variant="link" eventKey="0" onClick={() => setExpanded(!expanded)}>
              <div style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}>
                AB TEST
                <Button variant="link">
                  {!expanded && <i className="fas fa-chevron-down" />}
                  {expanded && <i className="fas fa-chevron-up" />}
                </Button>
              </div>
            </Accordion.Toggle>

            <Accordion.Collapse eventKey="0">
              <Table size="sm" bordered className="mb-0">
                <thead>
                  <tr>
                    <th>AB Test</th>
                    <th>Type</th>
                    <th>Status</th>
                    <th>Current variation</th>
                  </tr>
                </thead>
                <tbody>
                  {abTestConfigsData.map(({ id, variationsWithPercentage, status, type }) => {
                    const forced = forcedTests.has(id);
                    const config = abTestList.get(id);
                    return (
                      <tr key={id} className="py-1 px-2 my-0">
                        <td>{id}</td>
                        <td>{type}</td>
                        <td>
                          {/* @ts-ignore */}
                          <Badge variant={{ TESTING: 'success', WINNER: 'secondary' }[status] ?? 'dark'}>
                            {/* @ts-ignore */}
                            {{ TESTING: 'Testing', WINNER: 'Winner' }[status] ?? 'Unknown'}
                          </Badge>
                        </td>
                        <td className="p-0 pl-2">
                          <div className="d-flex justify-content-between align-items-center">
                            {editedTest === id ? (
                              forceTestLoading ? (
                                <div className="flex-grow-1 text-center">
                                  <Spinner animation="border" size="sm" className="p-2 m-1" />
                                </div>
                              ) : (
                                <>
                                  <FormControl
                                    as="select"
                                    size="sm"
                                    className="flex-grow-1 mx-0"
                                    value={forced ? config?.variation : 'unforced'}
                                    onChange={(e) => forceTest(id, e.target.value)}
                                  >
                                    <option key="unforced" value="unforced">
                                      [unforced]
                                    </option>
                                    {(variationsWithPercentage ?? [config?.variation]).map((variation2) => (
                                      <option key={variation2.name} value={variation2.name}>
                                        {variation2.name}
                                      </option>
                                    ))}
                                  </FormControl>
                                  <Button
                                    size="sm"
                                    variant="danger"
                                    onClick={() => setEditedTest(null)}
                                    className="ml-2"
                                  >
                                    <i className="fas fa-times" />
                                  </Button>
                                </>
                              )
                            ) : (
                              <>
                                <span>
                                  {config !== undefined ? config.variation : 'Not started yet...'}
                                  {forced && <b> (forced)</b>}
                                </span>
                                <Button
                                  variant={forced ? 'warning' : 'primary'}
                                  size="sm"
                                  disabled={type === AbTestType.APP_INSTALL}
                                  onClick={() => setEditedTest(id)}
                                  className="ml-2"
                                >
                                  {forced ? 'Edit' : 'Force'}
                                </Button>
                              </>
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Accordion.Collapse>
          </Accordion>
        </Card.Body>
      </Card>
    </>
  );
}
