import { Modal } from 'react-bootstrap';
import LiveShowLogsConsole from './console';
import { LiveShowLog } from './types';

export default function LiveShowLogsConsolePopup({
  showId,
  totalNumberOfLogs,
  show,
  onHide,
  onDisplayLogDetails,
}: {
  showId: string;
  totalNumberOfLogs: number;
  show: boolean;
  onHide: () => void;
  onDisplayLogDetails: (log: LiveShowLog) => void;
}) {
  return (
    <Modal show={show} onHide={onHide} dialogClassName="modal-90w" centered>
      <LiveShowLogsConsole
        onDisplayLogDetails={onDisplayLogDetails}
        showId={showId}
        totalNumberOfLogs={totalNumberOfLogs}
      />
    </Modal>
  );
}
