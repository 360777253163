import _ from 'lodash';
import React, { useState } from 'react';

import { Button, Card, Col, Row, Table } from 'react-bootstrap';
import { ItemAvailability } from '../../../../../../__gqltypes__';
import Chip from '../../../components/Chip';
import EditRewardsModal from '../../../components/EditRewardsModal';
import EditValue from '../../../components/EditValue';

/**
 * Component to display rankgroups of a tournament
 */

/**
  type RewardValue = {
    id: string;
    type: string;
    amount: number;
  };
  */

export type RankGroupValue = {
  minRank: string | number;
  maxRank: string | number;
  rewards: any[];
};

type Props = {
  disabled: boolean;
  canSave?: boolean;
  enableContentPack?: boolean;
  rankgroups: RankGroupValue[];
  onChangedRankgroups: (value: RankGroupValue[]) => void;
  onUpload: (rankGroups: RankGroupValue[]) => void;
};

export function RankGroup({ disabled, canSave, rankgroups, onChangedRankgroups, onUpload, enableContentPack}: Props) {
  const [showEditReward, setShowEditReward] = useState(false);
  const [editingRewardIndex, setEditingRewardIndex] = useState<number | null>(null);
  const [editingRankgroupIndex, setEditingRankgroupIndex] = useState<number | null>(null);

  const handleRemoveRankgroup = () =>
    rankgroups.length === 0 ? onChangedRankgroups([]) : onChangedRankgroups(rankgroups.slice(0, -1));
  const handleAddRankgroup = () =>
    onChangedRankgroups([
      ...rankgroups,
      {
        minRank: 0,
        maxRank: 0,
        rewards: [],
      },
    ]);

  const handleUpload = () => {
    onUpload(rankgroups);
  };

  // Helpers function to update rewards in rankgroups
  /**
   * Return an updated version of the rankgroups by only modifying one reward for one rankgroup
   */
  const updateReward = (
    rankgroupIndex: number | null,
    rewardIndex: number | null,
    updates: Record<string, any>
  ): RankGroupValue[] => {
    return rankgroups.map((_rankgroup, _rankgroupIndex) =>
      rankgroupIndex === _rankgroupIndex
        ? {
            ..._rankgroup,
            rewards: _rankgroup.rewards.map((_reward, _rewardIndex) =>
              rewardIndex === _rewardIndex ? { ..._reward, ...updates } : _reward
            ),
          }
        : _rankgroup
    );
  };
  /**
   * Return an updated version of the rankgroups by only adding one reward for the selected rankgroup
   */
  const addReward = (rankgroupIndex: number): RankGroupValue[] => {
    return rankgroups.map((_rankgroup, _rankgroupIndex) =>
      rankgroupIndex === _rankgroupIndex
        ? {
            ..._rankgroup,
            rewards: [..._rankgroup.rewards, { type: '', amount: 0 }],
          }
        : _rankgroup
    );
  };

  /**
   * Return an updated version of the rankgroups by only adding one reward for the selected rankgroup
   */
  const removeReward = (rankgroupIndex: number): RankGroupValue[] => {
    return rankgroups.map((_rankgroup, _rankgroupIndex) =>
      rankgroupIndex === _rankgroupIndex
        ? {
            ..._rankgroup,
            rewards: _rankgroup.rewards.length === 0 ? [] : _rankgroup.rewards.slice(0, -1),
          }
        : _rankgroup
    );
  };

  /**
   * On reward selected, save to state curent selected reward and allow edit reward to be displayed
   */
  const handleShowEditReward = (rankgroupIndex: number, rewardIndex: number) => {
    setEditingRankgroupIndex(rankgroupIndex);
    setEditingRewardIndex(rewardIndex);
    setShowEditReward(true);
  };

  return (
    <Card className="mt-3 mb-3">
      <Card.Body className="text-center">
        <Card.Title>Rank Groups</Card.Title>
        <EditRewardsModal
          disableMonthlyBuff
          disableContentPackReward={!enableContentPack}
          isEdit={
            !_.isNil(editingRankgroupIndex) &&
            !_.isNil(editingRewardIndex) &&
            rankgroups[editingRankgroupIndex].rewards[editingRewardIndex].type !== ''
          }
          show={showEditReward}
          tier={null}
          level={0}
          reward={
            !_.isNil(editingRankgroupIndex) && !_.isNil(editingRewardIndex)
              ? rankgroups[editingRankgroupIndex]?.rewards[editingRewardIndex]
              : ''
          }
          handleClose={() => setShowEditReward(false)}
          handleSave={(newReward) => {
            setShowEditReward(false);
            onChangedRankgroups(updateReward(editingRankgroupIndex, editingRewardIndex, newReward));
          }}
          liveOpsAvailabilities={[ItemAvailability.EARN]}
        />
        <Table bordered responsive hover>
          <thead className="text-center">
            <tr>
              <th>Rank group</th>
              <th>min rank</th>
              <th>max rank</th>
              <th>Rewards</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {rankgroups.map((rankgroup, rankgroupIndex) => (
              <tr key={rankgroupIndex}>
                <td className="align-middle">{rankgroupIndex + 1}</td>
                <td className="align-middle">
                  <EditValue
                    name=""
                    value={rankgroup.minRank}
                    handleValueChange={(minRank) =>
                      onChangedRankgroups(
                        rankgroups.map((element, i) => (i === rankgroupIndex ? { ...element, minRank } : element))
                      )
                    }
                  />
                </td>
                <td className="align-middle">
                  <EditValue
                    name=""
                    value={rankgroup.maxRank}
                    handleValueChange={(maxRank) =>
                      onChangedRankgroups(
                        rankgroups.map((element, i) => (i === rankgroupIndex ? { ...element, maxRank } : element))
                      )
                    }
                  />
                </td>

                <td className="align-middle">
                  <Col>
                    {rankgroup.rewards.map((reward, rewardIndex) => (
                      <Row key={`rank group reward${rewardIndex}`}>
                        {reward?.type ? (
                          <Chip
                            reward={reward}
                            canDelete={false}
                            btnAction={() => {}}
                            globalAction={() => handleShowEditReward(rankgroupIndex, rewardIndex)}
                          />
                        ) : (
                          <Chip
                            isAdd
                            outlined
                            btnAction={() => handleShowEditReward(rankgroupIndex, rewardIndex)}
                            globalAction={() => handleShowEditReward(rankgroupIndex, rewardIndex)}
                          />
                        )}
                      </Row>
                    ))}
                  </Col>
                  <Row className="mb-3">
                    <Button
                      disabled={disabled}
                      variant="success"
                      className="align-self-center"
                      onClick={() => onChangedRankgroups(addReward(rankgroupIndex))}
                    >
                      +
                    </Button>
                    <Button
                      disabled={disabled}
                      variant="danger"
                      className="align-self-center"
                      onClick={() => onChangedRankgroups(removeReward(rankgroupIndex))}
                    >
                      X
                    </Button>
                  </Row>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <Button disabled={disabled} variant="success" className="align-self-center" onClick={handleAddRankgroup}>
          Add a rankgroup
        </Button>
        <Button disabled={disabled} variant="danger" className="align-self-center ml-1" onClick={handleRemoveRankgroup}>
          Remove a rankgroup
        </Button>
        <Button disabled={disabled || canSave === false} className="align-self-center ml-1" onClick={handleUpload}>
          Save Changes
        </Button>
      </Card.Body>
    </Card>
  );
}
