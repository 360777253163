import { GraphQLError } from 'graphql';
import { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import { emitter } from '../apollo';
import { AppRouteType } from '../types';
import { UserRole } from '../__gqltypes__';
import { KEY_TO_NAME } from './config';
import Sidebar from './Sidebar';
import Topbar, { DisplayedProfile } from './Topbar';

type Props = {
  children?: JSX.Element;
  profile: DisplayedProfile | null;
  routes: AppRouteType[];
  app: keyof typeof KEY_TO_NAME;
  role: UserRole;
};

const Layout = ({ children, profile, routes, app, role }: Props) => {
  const [expanded, setExpanded] = useState(false);
  const [errors, setErrors] = useState<GraphQLError[] | null>(null);

  useEffect(() => {
    emitter.addListener('error', (err: GraphQLError[]) => setErrors(err));
    return () => emitter.removeAllListeners();
  }, []);

  const onSidebarToggle = () => setExpanded(!expanded);

  const onErrorClose = () => setErrors(null);

  const getPageNameFromRoute = (route: string) => {
    if (route.split('/').length > 2) {
      return routes.filter((_route) => _route.sideRoutes[0].path.includes(route.split('/')[2]))[0].name;
    }
    return '';
  };

  return (
    <div id="wrapper" className="flex-column">
      <div id="content-wrapper" className="min-vh-100">
        {!!profile && (
          <Topbar
            profile={profile}
            app={app}
            visible={window.location.pathname !== '/'}
            expanded={expanded}
            onSidebarToggle={onSidebarToggle}
            getPageNameFromRoute={getPageNameFromRoute}
            role={role}
          />
        )}
        <div id="content" className="d-flex" style={{ paddingTop: '4.375rem', height: '100vh' }}>
          <Sidebar role={role} expanded={expanded} routes={routes} app={app} onSidebarToggle={onSidebarToggle} />
          <div className="pt-4 overflow-auto w-100">
            {errors && (
              <Alert className="alert-popup fixed-top" dismissible key="fds" variant="danger" onClose={onErrorClose}>
                {errors.map(({ message, locations }, index) => (
                  <div key={index}>
                    {`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}`}
                  </div>
                ))}
              </Alert>
            )}
            <Container fluid className="h-100">
              {children}
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
