import React from 'react';
import { Button, Col, Row, Spinner } from 'react-bootstrap';

const LTVUpdate = () => {
  const [loading, setLoading] = React.useState(false);

  const refreshLTV = () => {
    setLoading(true);
    fetch('https://us-central1-songpop3.cloudfunctions.net/ltv_prediction', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ secret: 'MjdkYzRkMDUyYjYwMTg2YjRjMDQ4MTE2' }),
    }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <>
      <Row className="justify-content-between mb-2">
        <Col lg={4}>
          <h1>LTV Update</h1>
        </Col>
      </Row>
      <Row className="px-4 pt-2">
        Clicking this button will generate new LTV models, based on the table located in&nbsp;
        <a
          target="blank"
          href="https://console.cloud.google.com/bigquery?project=songpop2-prod&ws=!1m5!1m4!4m3!1ssongpop2-prod!2sanalytics!3ssp3_prog_ltv_dataset"
        >
          sp3_prog_ltv_dataset
        </a>
        &nbsp; and upload the result in&nbsp;
        <a
          target="blank"
          href="https://console.cloud.google.com/bigquery?project=songpop2-prod&ws=!1m5!1m4!4m3!1ssongpop2-prod!2sanalytics!3ssp3_prog_ltv_result"
        >
          sp3_prog_ltv_results
        </a>
        .
      </Row>
      <hr />

      <Row className="px-4">
        <Button disabled={loading} onClick={refreshLTV}>
          Update LTV Predictions
        </Button>
        {!!loading && <Spinner animation="border" />}
      </Row>
    </>
  );
};

export default LTVUpdate;
