import _ from 'lodash';
import { useEffect } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { FulllMetricsFragment } from '../../../../../../__gqltypes__';

interface DownloadCsvButtonProps {
  getFullSerie: () => Promise<FulllMetricsFragment | undefined>;
  fullSerieLoading: boolean;
}

function downloadCsv(fullSerie: FulllMetricsFragment) {
  const colums = 'type,name,datetime,timestamp,average,P0,P10,P20,P30,P40,P50,P71,P85,P95,P99,P100,count,std';
  const records = fullSerie.records.map((record) =>
    Object.entries(record)
      .filter(([key, value]) => key !== '__typename')
      .map(([key, value]) => value)
      .join(',')
  );
  const filename = `monitoring-export-${fullSerie.timestamp}-${fullSerie.start}-${fullSerie.end}.csv`;
  const csv = [colums, ...records].join('\n');
  if (_.isEmpty(csv)) return;

  const data = encodeURI(`data:text/csv;charset=utf-8,${csv}`);

  const link = document.createElement('a');
  link.setAttribute('href', data);
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function DownloadCsvButton({ getFullSerie, fullSerieLoading }: DownloadCsvButtonProps) {
  const downloadFullSerie = async () => {
    const fullSerie = await getFullSerie();
    console.log(fullSerie);
    if (!fullSerie) return;
    downloadCsv(fullSerie);
  };

  return (
    <>
      <Button size="sm" onClick={downloadFullSerie}>
        {fullSerieLoading ? (
          <Spinner animation="border" size="sm" className="mr-2" />
        ) : (
          <i className="fa fa-download mr-2" />
        )}
        Download CSV
      </Button>
    </>
  );
}
