import { useMemo } from 'react';
import colors from '../../../models/widgets/colors.json';
import { widgetDefaultProps, widgetPropTypes } from '../widgetPropTypes';
import BaseChart from './BaseChart';

const BarChart = ({ id, height, width, data, options, title }) => {
  const datasets = useMemo(() => {
    const keys = Object.keys(data.data);
    if (!keys.length) {
      return [];
    }
    return keys
      .filter((label) => label !== data.indexColLabel)
      .map((label, index) => {
        return {
          data: data.data[label],
          label,
          borderColor: colors[index],
          backgroundColor: colors[index],
          fill: false,
        };
      });
  }, [data.data]);

  return (
    <BaseChart
      id={id}
      type="bar"
      height={height}
      width={width}
      options={options}
      labels={data.getLabelColumn()}
      datasets={datasets}
      title={title}
    />
  );
};

BarChart.propTypes = widgetPropTypes;
BarChart.defaultProps = widgetDefaultProps;

export default BarChart;
