import { useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import DateTimePicker from 'react-datetime';
import { applyChangeIfDateValid } from '../../../../../../utils/datepicker';
import type {
  ContentCategoriesQuery,
  CopyTournamentMutation,
  CopyTournamentMutationVariables,
  RemoveTournamentMutation,
  RemoveTournamentMutationVariables,
  TournamentQuery,
  UpdateTournamentMutation,
  UpdateTournamentMutationVariables,
} from '../../../../../../__gqltypes__';
import ContentPackListEditor from '../../../../curation/screens/ContentPackList/ContentPackListEditor';
import { LoadingLogo } from '../../../../devtools/components/Modal';
import { GET_CONTENT_CATEGORIES } from '../../../components/apolloQueries';
import AssetDisplay from '../../Items/components/AssetDisplay';
import DragUpload from '../../Items/components/DragUpload';
import {
  COPY_TOURNAMENT,
  GET_TOURNAMENT,
  REMOVE_TOURNAMENT,
  UPDATE_TOURNAMENT,
  UPDATE_TOURNAMENT_CONTENT_PACKS,
  UPDATE_TOURNAMENT_MILESTONES,
  UPDATE_TOURNAMENT_RANKGROUPS,
  UPLOAD_CHARACTER_ASSET,
  UPLOAD_HEADER_ASSET,
} from '../graphql';
import { checkRankGroupErrors, getReward } from '../utils';
import CopyTournamentModal from './CopyTournamentModal';
import MileStone, { MilestoneValue } from './MileStones';
import { RankGroup, RankGroupValue } from './RankGroups';
import SpecialEventSelector from './SpecialEventSelector';

type CopiedTournament = CopyTournamentMutation['copyTournament']['tournament'];
type UpdatedTournament = UpdateTournamentMutation['updateTournament']['tournament'];
type AssetsConfig = NonNullable<TournamentQuery['tournament']>['assetsConfig'];

/**
 * A component showing the data of a tournament
 */

type Props = {
  tournamentId: string;
  onUpsertTournament: (data: UpdatedTournament) => void;
  onRemoveTournament: (tournamentId: string) => void;
  onCopiedTournament: (data: CopiedTournament) => void;
};

const TournamentData = ({ tournamentId, onUpsertTournament, onRemoveTournament, onCopiedTournament }: Props) => {
  /**
   * State
   */

  // Currently displayed tournament
  /**
   * @type {[Tournament, Function]} Currently displayed Tournament
   */
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [ticketCost, setTicketCost] = useState(1);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  // @ts-ignore
  const [assetsConfig, setAssetsConfig] = useState<AssetsConfig>({});
  // selected category
  const [categoryId, setCategoryId] = useState<string | null>(null);
  /**
   * @type {[MileStone[], Function]}
   */
  const [milestones, setMilestones] = useState<MilestoneValue[]>([]);
  /**
   * @type {[RankGroup[], Function]}
   */
  const [rankgroups, setRankgroups] = useState<RankGroupValue[]>([]);

  // Error detected in rankgroup
  const [rankGroupErrors, setRankGroupErrors] = useState<string[]>([]);

  // pop-up state management ----------
  const [showHeaderImageDeletionWarning, setShowHeaderImageDeletionWarning] = useState(false);
  const [showCharacterImageDeletionWarning, setShowCharacterImageDeletionWarning] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [showCopyModal, setShowCopyModal] = useState(false);

  /**
   * FETCHING
   */

  const {
    data,
    loading: loadingTournament,
    refetch,
  } = useQuery<TournamentQuery>(GET_TOURNAMENT, {
    fetchPolicy: 'no-cache',
    variables: { tournamentId },
  });
  // Getting available categories
  const { loading: loadingCategories, data: dataCategories } = useQuery<ContentCategoriesQuery>(GET_CONTENT_CATEGORIES);
  const categories = dataCategories?.app.contentCategories ?? [];

  const [updateTournament, { loading: updateLoading }] = useMutation<
    UpdateTournamentMutation,
    UpdateTournamentMutationVariables
  >(UPDATE_TOURNAMENT, {
    onCompleted: ({ updateTournament: _updateTournament }) => {
      const updatedTournament = _updateTournament.tournament;
      onUpsertTournament(updatedTournament);
      refetch();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const [updateTournamentMilestones, { loading: updateMilestonesLoading }] = useMutation(UPDATE_TOURNAMENT_MILESTONES, {
    onCompleted: ({ updateTournamentMilestones: { tournament } }) => {
      setAndSanitizeMilestones(tournament.milestones);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const [updateTournamentRankGroups, { loading: updateRankGroupLoading }] = useMutation(UPDATE_TOURNAMENT_RANKGROUPS, {
    onCompleted: ({ updateTournamentRankGroups: { tournament } }) => {
      setAndSanitizeRankGroups(tournament.rankGroups);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const [updateTournamentContentPacks] = useMutation(UPDATE_TOURNAMENT_CONTENT_PACKS);

  const [removeTournament, { loading: removeLoading }] = useMutation<
    RemoveTournamentMutation,
    RemoveTournamentMutationVariables
  >(REMOVE_TOURNAMENT, {
    onCompleted: ({ removeTournament: _removeTournament }) => {
      if (!_removeTournament.isRemoved) {
        alert("ERROR: Couldn't remove the current tournament");
        return;
      }
      onRemoveTournament(tournamentId);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const [uploadHeaderAsset, { loading: uploadHeaderLoading }] = useMutation(UPLOAD_HEADER_ASSET, {
    onCompleted: ({ uploadTournamentHeaderAsset: { tournament } }) => {
      setAssetsConfig({ ...assetsConfig, headerImage: tournament.assetsConfig.headerImage });
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const [uploadCharacterAsset, { loading: uploadCharacterLoading }] = useMutation(UPLOAD_CHARACTER_ASSET, {
    onCompleted: ({ uploadTournamentCharacterAsset: { tournament } }) => {
      setAssetsConfig({ ...assetsConfig, characterImage: tournament.assetsConfig.characterImage });
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const [copyTournamentShow, { loading: copyLoading }] = useMutation<
    CopyTournamentMutation,
    CopyTournamentMutationVariables
  >(COPY_TOURNAMENT, {
    onCompleted: ({ copyTournament: { tournament } }) => {
      setShowCopyModal(false);
      onCopiedTournament(tournament);
    },
  });

  const loading = loadingTournament || loadingCategories || updateLoading || removeLoading || copyLoading;

  const readonly = loading;

  /**
   * STATE AUTO UPDATE FUNCTIONS
   */
  // set current tournament to the one fetched
  useEffect(() => {
    if (data && data.tournament) {
      /**
       * @type {Tournament}
       */

      const fetchedTournament = data.tournament;
      setName(fetchedTournament.name);
      setDescription(fetchedTournament.description);
      setCategoryId(fetchedTournament.category.id);
      setStartDate(new Date(fetchedTournament.startDate));
      setEndDate(new Date(fetchedTournament.endDate));
      setTicketCost(fetchedTournament.ticketCost);
      const newAssetsConfig = fetchedTournament.assetsConfig;
      // removing unwanted auto added key by apollo
      setAssetsConfig(newAssetsConfig);

      // Convert reward format from graphql one
      setAndSanitizeMilestones(fetchedTournament.milestones);
      setAndSanitizeRankGroups(fetchedTournament.rankGroups);
    }
  }, [data]);

  // whenever rankgroups are updated, we check if there are mistakes
  useEffect(() => {
    setRankGroupErrors(checkRankGroupErrors(rankgroups));
  }, [rankgroups]);

  useEffect(() => {
    if (showRemoveModal) {
      setShowCopyModal(false);
    }
  }, [showRemoveModal]);

  useEffect(() => {
    if (showCopyModal) {
      setShowRemoveModal(false);
    }
  }, [showCopyModal]);

  /**
   * CHANGES HANDLERS FUNCTIONS
   */
  const handleCopyTournament = (startDateCopy: number) => {
    copyTournamentShow({ variables: { input: { id: tournamentId, startDate: startDateCopy } } });
  };
  const handleSave = () => {
    setShowHeaderImageDeletionWarning(false);
    setShowCharacterImageDeletionWarning(false);

    updateTournament({
      variables: {
        input: {
          id: tournamentId,
          // @ts-ignore
          categoryId,
          name,
          description,
          // @ts-ignore
          startDate,
          // @ts-ignore
          endDate,
          ticketCost,
          assetsConfig: {
            primaryColor: assetsConfig.primaryColor,
            secondaryColor: assetsConfig.secondaryColor,
            secondaryDarkColor: assetsConfig.secondaryDarkColor,
            buttonColor: assetsConfig.buttonColor,
            characterImage: assetsConfig.characterImage,
            headerImage: assetsConfig.headerImage,
            contrast: assetsConfig.contrast,
            contrastGradient1: assetsConfig.contrastGradient1,
            contrastGradient2: assetsConfig.contrastGradient2,
          },
        },
      },
    });
  };

  const handleSaveMilestones = (newMilestones: MilestoneValue[]) => {
    updateTournamentMilestones({
      variables: {
        input: {
          id: tournamentId,
          milestones: newMilestones.map((milestone) => ({
            score: _.toNumber(milestone.score),
            rewards: milestone.rewards.map((reward) => ({
              type: reward.type,
              amount: _.toNumber(reward.amount),
              itemId: reward.itemId,
            })),
          })),
        },
      },
    });
  };

  const setAndSanitizeMilestones = (newMilestones: MilestoneValue[]) => {
    const sanitizedMilestones = newMilestones.map((milestone) => ({
      ...milestone,
      rewards: milestone.rewards.map((reward) => getReward(reward)),
    }));
    setMilestones(_.orderBy(sanitizedMilestones, ['score'], ['asc']));
  };

  const setAndSanitizeRankGroups = (newRankGroups: RankGroupValue[]) => {
    const sanitizedRankGroups = newRankGroups.map((rankGroups) => ({
      ...rankGroups,
      rewards: rankGroups.rewards.map((reward) => getReward(reward)),
    }));
    setRankgroups(sanitizedRankGroups);
  };

  const handleSaveRankGroups = (newRankgroups: RankGroupValue[]) => {
    updateTournamentRankGroups({
      variables: {
        input: {
          id: tournamentId,
          rankGroups: newRankgroups.map((rankgroup) => ({
            minRank: _.toNumber(rankgroup.minRank),
            maxRank: _.toNumber(rankgroup.maxRank),
            rewards: rankgroup.rewards.map((reward) => ({
              type: reward.type,
              amount: _.toNumber(reward.amount),
              itemId: reward.itemId,
            })),
          })),
        },
      },
    });
  };

  const handleSaveContentPacks = async (contentPackIds: string[]) => {
    await updateTournamentContentPacks({
      variables: {
        input: {
          id: tournamentId,
          contentPackIds,
        },
      },
    });
    refetch();
  };

  // @ts-ignore
  const handleChangeCategory = (event) => {
    setCategoryId(event.target.value);
  };

  const handleRemoveTournament = () => {
    removeTournament({
      variables: {
        input: {
          id: tournamentId,
        },
      },
    });
  };

  const handleHeaderImageUpload = useCallback(
    (file) => {
      uploadHeaderAsset({
        variables: {
          input: {
            file,
            id: tournamentId,
          },
        },
      });
    },
    [tournamentId, uploadHeaderAsset]
  );
  const handleCharacterImageUpload = useCallback(
    (file) => {
      uploadCharacterAsset({
        variables: {
          input: {
            file,
            id: tournamentId,
          },
        },
      });
    },
    [tournamentId, uploadCharacterAsset]
  );

  const handleChangeTicketCost = useCallback((event) => {
    setTicketCost(Math.max(1, +event.target.value));
  }, []);

  const handleChangeName = useCallback((event) => {
    setName(event.target.value);
  }, []);

  const handleChangeDescription = useCallback((event) => {
    setDescription(event.target.value);
  }, []);

  /**
   * RENDER
   */
  return loading ? (
    <LoadingLogo show />
  ) : (
    <Container className="pb-4">
      <Row className="mb-3">
        <Col md={4}>Start date:</Col>
        <Col md={4}>
          <DateTimePicker
            // For the moment, only 00:00 formats are expects
            dateFormat="YYYY-MM-DD"
            timeFormat={false}
            onChange={applyChangeIfDateValid(setStartDate)}
            value={startDate ?? undefined}
          />
        </Col>
        <Col className="d-flex justify-content-end">
          <Button
            className="mr-2"
            onClick={() => {
              setShowRemoveModal(!showRemoveModal);
            }}
            variant="danger"
            disabled={readonly}
          >
            <i className="fas fa-trash mr-2" />
            Delete
          </Button>
          {readonly || (
            <Button onClick={handleSave} variant="success" className="mr-2">
              Save
            </Button>
          )}
          <Button onClick={() => setShowCopyModal(true)} variant="primary">
            <i className="fas fa-copy mr-2" />
            Copy
          </Button>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col md={4}>End date:</Col>
        <Col md={4}>
          <DateTimePicker
            // For the moment, only 00:00 formats are expects
            dateFormat="YYYY-MM-DD"
            timeFormat={false}
            onChange={applyChangeIfDateValid(setEndDate)}
            value={endDate ?? undefined}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>Category:</Col>
        <Col md={4}>
          <Form.Control value={categoryId ?? ''} onChange={handleChangeCategory} as="select">
            {categories.map(({ id, name: categoryName }) => (
              <option key={id} value={id}>
                {categoryName}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Row>
      <Row>
        <Col md={4}> Ticket Cost:</Col>
        <Col md={4}>
          <Form.Control disabled={readonly} value={ticketCost ?? 1} onChange={handleChangeTicketCost} type="number" />
        </Col>
      </Row>

      <Row>
        <Col md={4}> Name:</Col>
        <Col md={4}>
          <Form.Control disabled={readonly} value={name} onChange={handleChangeName} type="text" />
        </Col>
      </Row>

      <Row>
        <Col md={4}> Description:</Col>
        <Col md={4}>
          <Form.Control
            disabled={readonly}
            value={description}
            onChange={handleChangeDescription}
            type="text"
            as="textarea"
            // @ts-ignore
            rows="3"
          />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col lg={2}>
          <Form.Label> Primary Color</Form.Label>
        </Col>
        <Col lg={1}>
          <input type="color" name="primaryColor" value={assetsConfig.primaryColor || '#000000'} />
        </Col>
        <Col lg={2}>
          <Form.Control
            disabled={readonly}
            type="text"
            placeholder="#RRGGBB"
            name="primaryColorHex"
            value={assetsConfig.primaryColor || '#000000'}
            onChange={(e) => setAssetsConfig({ ...assetsConfig, primaryColor: e.target.value || '#000000' })}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={2}>
          <Form.Label> Secondary Color</Form.Label>
        </Col>
        <Col lg={1}>
          <input type="color" name="secondaryColor" value={assetsConfig.secondaryColor || '#000000'} />
        </Col>
        <Col lg={2}>
          <Form.Control
            disabled={readonly}
            type="text"
            placeholder="#RRGGBB"
            name="secondaryColorHex"
            value={assetsConfig.secondaryColor || '#000000'}
            onChange={(e) => setAssetsConfig({ ...assetsConfig, secondaryColor: e.target.value || '#000000' })}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={2}>
          <Form.Label> Secondary Dark Color</Form.Label>
        </Col>
        <Col lg={1}>
          <input type="color" name="secondaryDarkColor" value={assetsConfig.secondaryDarkColor || '#000000'} />
        </Col>
        <Col lg={2}>
          <Form.Control
            disabled={readonly}
            type="text"
            placeholder="#RRGGBB"
            name="secondaryDarkColorHex"
            value={assetsConfig.secondaryDarkColor || '#000000'}
            onChange={(e) => setAssetsConfig({ ...assetsConfig, secondaryDarkColor: e.target.value || '#000000' })}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={2}>
          <Form.Label> Button Color</Form.Label>
        </Col>
        <Col lg={1}>
          <input type="color" name="buttonColor" value={assetsConfig.buttonColor || '#000000'} />
        </Col>
        <Col lg={2}>
          <Form.Control
            disabled={readonly}
            type="text"
            placeholder="#RRGGBB"
            name="buttonColorHex"
            value={assetsConfig.buttonColor || '#000000'}
            onChange={(e) => setAssetsConfig({ ...assetsConfig, buttonColor: e.target.value || '#000000' })}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={2}>
          <Form.Label> Contrast</Form.Label>
        </Col>
        <Col lg={1}>
          <input type="color" name="contrast" value={assetsConfig.contrast || '#000000'} />
        </Col>
        <Col lg={2}>
          <Form.Control
            disabled={readonly}
            type="text"
            placeholder="#RRGGBB"
            name="contrastHex"
            value={assetsConfig.contrast || '#000000'}
            onChange={(e) => setAssetsConfig({ ...assetsConfig, contrast: e.target.value || '#000000' })}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={2}>
          <Form.Label> Contrast Gradient 1</Form.Label>
        </Col>
        <Col lg={1}>
          <input type="color" name="contrastGradient1" value={assetsConfig.contrastGradient1 || '#000000'} />
        </Col>
        <Col lg={2}>
          <Form.Control
            disabled={readonly}
            type="text"
            placeholder="#RRGGBB"
            name="contrastGradient1Hex"
            value={assetsConfig.contrastGradient1 || '#000000'}
            onChange={(e) => setAssetsConfig({ ...assetsConfig, contrastGradient1: e.target.value || '#000000' })}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={2}>
          <Form.Label> Contrast Gradient 2</Form.Label>
        </Col>
        <Col lg={1}>
          <input type="color" name="contrastGradient2" value={assetsConfig.contrastGradient2 || '#000000'} />
        </Col>
        <Col lg={2}>
          <Form.Control
            disabled={readonly}
            type="text"
            placeholder="#RRGGBB"
            name="contrastGradient2Hex"
            value={assetsConfig.contrastGradient2 || '#000000'}
            onChange={(e) => setAssetsConfig({ ...assetsConfig, contrastGradient2: e.target.value || '#000000' })}
          />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <Form.Label> Header Image </Form.Label>
          {assetsConfig && assetsConfig.headerImage && <AssetDisplay assetUrl={assetsConfig.headerImage} />}
          <DragUpload onUpload={handleHeaderImageUpload} />
          <LoadingLogo show={uploadHeaderLoading} />
          <Button
            disabled={readonly}
            className="mt-2"
            onClick={() => {
              setAssetsConfig({ ...assetsConfig, headerImage: '' });
              setShowHeaderImageDeletionWarning(true);
            }}
            variant="danger"
          >
            <i className="fas fa-trash mr-2" />
            Delete
          </Button>
          {showHeaderImageDeletionWarning && (
            <Alert variant="danger">Click on Save to confirm the deletion of an image</Alert>
          )}
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <Form.Label> Character Image </Form.Label>
          {assetsConfig && assetsConfig.characterImage && <AssetDisplay assetUrl={assetsConfig.characterImage} />}
          <DragUpload onUpload={handleCharacterImageUpload} />
          <LoadingLogo show={uploadCharacterLoading} />
          <Button
            disabled={readonly}
            className="mt-2"
            onClick={() => {
              setAssetsConfig({ ...assetsConfig, characterImage: '' });
              setShowCharacterImageDeletionWarning(true);
            }}
            variant="danger"
          >
            <i className="fas fa-trash mr-2" />
            Delete
          </Button>
          {showCharacterImageDeletionWarning && (
            <Alert variant="danger">Click on Save to confirm the deletion of an image</Alert>
          )}
        </Col>
      </Row>
      <hr />
      {data && data.tournament && (
        <SpecialEventSelector
          refetch={refetch}
          tournamentId={tournamentId}
          specialEvent={data.tournament.adminSpecialEvent || null}
        />
      )}
      <hr />
      {data && (
        <ContentPackListEditor
          // @ts-ignore
          contentPacks={data.tournament.contentPacks}
          updatePackIds={handleSaveContentPacks}
          enableImport
        />
      )}
      <hr />
      {data &&
        (updateMilestonesLoading ? (
          <LoadingLogo show />
        ) : (
          <MileStone
            disabled={readonly}
            milestones={milestones}
            onChangedMilestones={(newMilestones) => setMilestones(_.orderBy(newMilestones, ['score'], ['asc']))}
            onUpload={handleSaveMilestones}
          />
        ))}

      <hr />
      {data &&
        (updateRankGroupLoading ? (
          <LoadingLogo show />
        ) : (
          <>
            <RankGroup
              disabled={readonly}
              rankgroups={rankgroups}
              onChangedRankgroups={(newRankgroups) => {
                setRankgroups(_.orderBy(newRankgroups, ['newRankgroups'], ['desc']));
              }}
              onUpload={handleSaveRankGroups}
            />
            {rankGroupErrors.map((error) => (
              <Alert key={error} variant="danger">
                {error}
              </Alert>
            ))}
          </>
        ))}

      <Modal show={showRemoveModal} onHide={() => setShowRemoveModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Stop the current</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>This cannot be undone. Are you sure to want to remove the current Tournament?</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowRemoveModal(false)}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={async () => {
              handleRemoveTournament();
              setShowRemoveModal(false);
            }}
          >
            Remove Tournament
          </Button>
        </Modal.Footer>
      </Modal>
      <CopyTournamentModal
        show={showCopyModal}
        onHide={() => setShowCopyModal(false)}
        onCopy={handleCopyTournament}
        loading={copyLoading}
      />
    </Container>
  );
};
export default TournamentData;
