import { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { CurationSongDatabaseSearchQuery, CurationSongDdexSearchQuery } from '../../../../__gqltypes__';
import SongSearchContent from '../screens/SongSearch/components/SongSearchContent';
import DDEXSongList from '../screens/SongSearch/components/DdexSongList';

/**
 * A popup component to search DDEX song and select them in an ordered list
 */
type CurationSongDatabaseSearch = CurationSongDatabaseSearchQuery['curationSongDatabaseSearch'][0];
type CurationSongDDEXSearch = CurationSongDdexSearchQuery['curationSongDDEXSearch'][0];
type Props = {
  show: boolean;
  onHide: () => void;
  onConfirm: (songs: CurationSongDDEXSearch[]) => void;
};

export default function SearchDDEXSongsPopup({ onHide, onConfirm, ...rest }: Props) {
  const [selectedSongs, setSelectedSongs] = useState<CurationSongDDEXSearch[]>([]);

  const unselectSong = (song: CurationSongDDEXSearch) => {
    setSelectedSongs(selectedSongs.filter((s) => s.id !== song.id));
  };

  const addSong = (song: CurationSongDatabaseSearch | CurationSongDDEXSearch) => {
    if ('sample' in song && song.sample && song.__typename === 'DDEXSong') {
      setSelectedSongs(selectedSongs.concat([song]));
    }
  };

  return (
    <Modal {...rest} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton onHide={onHide}>
        <Modal.Title id="contained-modal-title-vcenter-">Search DDEX Songs</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card className="p-4">
          <Card.Title>
            {selectedSongs.length > 0 ? 'Selected Songs' : 'No song selected'}{' '}
            <Button
              className="float-right"
              disabled={selectedSongs.length === 0}
              onClick={() => onConfirm(selectedSongs)}
            >
              Submit
            </Button>
          </Card.Title>
          {selectedSongs.length > 0 ? (
            <Card.Body>
              <DDEXSongList songs={selectedSongs} onSelectSong={unselectSong} actionButton="Remove" />
            </Card.Body>
          ) : null}
        </Card>
        <br />
        <Card className="p-4">
          <Card.Title>Select Songs</Card.Title>
          <Card.Body>
            <SongSearchContent onSongSelected={addSong} actionButton="Select" />
          </Card.Body>
        </Card>
      </Modal.Body>
    </Modal>
  );
}
