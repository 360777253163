import Dashboards from './screen/Dashboards';
import dashboardsRoutes from './screen/Dashboards/views';
import ImportantEvents from './screen/ImportantEvents';
import Monitoring from './screen/Monitoring';
import LTVUpdate from './screen/Programmatic/LTVUpdate';

const PREFIX = 'sp3';

export default [
  {
    path: `/${PREFIX}/analytics/dashboards`,
    name: 'Dashboards',
    component: Dashboards,
    underRoutes: dashboardsRoutes,
  },
  {
    path: `/${PREFIX}/analytics/dashboards/:id`,
    component: Dashboards,
  },
  {
    path: `/${PREFIX}/analytics/programmatic`,
    component: LTVUpdate,
    name: 'Programmatic',
  },
  {
    path: `/${PREFIX}/analytics/monitoring`,
    component: Monitoring,
    name: 'Monitoring',
  },
  {
    path: `/${PREFIX}/analytics/important-events`,
    component: ImportantEvents,
    name: 'Important Events',
  },
];
