import PropTypes from 'prop-types';
import React from 'react';
import Form from 'react-bootstrap/Form';

type Props<T> = {
  value: T;
  onChange: (value: T) => void;
  title: string;
  children: any;
};

const RadioSelector = <T extends string>({ value, onChange, title, children }: Props<T>) => (
  <Form.Group>
    <Form.Label>{title}</Form.Label>
    {React.Children.map(children, (child) =>
      React.cloneElement(child, {
        checked: child.props.value === value,
        onChange: ({ target }: { target: HTMLInputElement }) => {
          if (target.checked) {
            onChange(child.props.value);
          }
        },
      })
    )}
  </Form.Group>
);

RadioSelector.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export const RadioSelectorOption = ({ value, ...rest }: { value: string; label?: string; id?: string }) => (
  <Form.Check type="radio" {...rest} />
);

export default RadioSelector;
