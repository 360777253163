import { OperationVariables, useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { DeleteCurationContentPackMutation } from '../../../../../../__gqltypes__';
import mutations from '../../../utils/mutations';

type Props<T> = {
  listId: string;
  listName?: string;
  refetchLists: (variables?: Partial<OperationVariables> | undefined) => Promise<T>;
};

const SPPDeletePackList = <T,>({ listId, listName, refetchLists }: Props<T>) => {
  const [deletePack] = useMutation<DeleteCurationContentPackMutation>(mutations.DELETE_CURATION_CONTENT_PACK_LIST);
  const [deleting, setDeleting] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const askDelete = useCallback(() => setDeleting(true), [setDeleting]);
  const cancelDelete = useCallback(() => setDeleting(false), [setDeleting]);

  const doDelete = useCallback(async () => {
    setSubmitting(true);
    try {
      await deletePack({
        variables: {
          id: listId,
        },
      });
    } catch (err) {
      console.error(err);
      return;
    }
    setDeleting(false);
    await refetchLists();
  }, [listId, deletePack, refetchLists]);

  return (
    <>
      <Button variant="danger" onClick={askDelete} className="square-icon">
        <i className="fas fa-trash" />
      </Button>

      <Modal show={deleting} onHide={cancelDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Pack List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete &quot;{listName}&quot;?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cancelDelete} disabled={submitting}>
            Cancel
          </Button>
          <Button variant="danger" onClick={doDelete} disabled={submitting}>
            {submitting && <Spinner as="span" animation="border" size="sm" className="mr-2" />}
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SPPDeletePackList;
