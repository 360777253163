import { useLazyQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import { useState } from 'react';
import { Accordion, Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import {
  UserTransactionsQuery,
  QuizLiveShowUserQuery,
  RefundLiveShowMutation,
  RefundLiveShowMutationVariables,
} from '../../../../../../__gqltypes__';
import { USER_TRANSACTIONS, GET_QUIZ_LIVE_SHOW_USER, REFUND_LIVE_SHOW } from '../../../graphql';

const COLUMNS = ['LiveShow id', 'Won', 'Ghosted', 'Reward', 'Used boosts', 'Refund boosts'];

interface IBoosts {
  [k: string]: number;
}

interface ILiveShowBoosts {
  [key: string]: {
    text: string;
    boosts: IBoosts;
  };
}

interface ILiveShowGhosted {
  [key: string]: string;
}

const LiveShowDisplay = ({ userId }: { userId: string }) => {
  const [runQuery, queryResult] = useLazyQuery<UserTransactionsQuery>(USER_TRANSACTIONS, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      usedBoostColumnResult();
    },
  });
  const [expanded, setExpanded] = useState(false);
  const [liveShowDate, setLiveShowDate] = useState(() => moment().format('YYYY-MM-DD'));

  const [liveShowBoosts, setLiveShowBoosts] = useState<ILiveShowBoosts>({});
  const [liveShowGhosted, setLiveShowGhosted] = useState<ILiveShowGhosted>({});

  const [modalInfo, setModalInfo] = useState<{ title: string; error: string | undefined }>({
    title: 'Boosts refunded',
    error: undefined,
  });
  const [liveShowId, setLiveShowId] = useState<string>('');
  const [showRefundModal, setShowRefundModal] = useState<boolean>(false);

  const [fetchLiveShowUser, fetchLiveShowUserResult] = useLazyQuery<QuizLiveShowUserQuery>(GET_QUIZ_LIVE_SHOW_USER, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      ghostedColumnResult();
      runQuery({
        variables: {
          userId,
          startDate: liveShowDate,
          endDate: liveShowDate,
        },
      });
    },
  });
  const [refundLiveShow, { loading: refundLoading }] = useMutation<
    RefundLiveShowMutation,
    RefundLiveShowMutationVariables
  >(REFUND_LIVE_SHOW, {
    onCompleted: () => {
      setModalInfo({
        title: 'Boosts refunded',
        error: undefined,
      });
      setShowRefundModal(true);
    },
    onError: (error) => {
      setModalInfo({
        title: 'Some Error',
        error: error.message,
      });
      setShowRefundModal(true);
    },
  });

  // @ts-ignore
  const searchLiveShowUsers = (e) => {
    e.preventDefault();
    fetchLiveShowUser({
      variables: {
        userId,
        playDate: liveShowDate,
      },
    });
  };

  const ghostedColumnResult = (): void => {
    const result: ILiveShowGhosted = {};

    fetchLiveShowUserResult.data?.quizLiveShowUser?.forEach((liveShowUser, index) => {
      let ghostedRound: number | undefined;
      let ghostedQuestion: number | undefined;
      liveShowUser?.show.rounds.every((round, idx) => {
        round.questions.every((q, i) => {
          if (q.id === liveShowUser?.ghostQuestionId) {
            ghostedRound = idx;
            ghostedQuestion = i;

            return false;
          }

          return true;
        });
        return ghostedRound === undefined;
      });

      result[liveShowUser?.id ?? index] = liveShowUser?.ghostQuestionId
        ? `Round: ${ghostedRound}, Question: ${ghostedQuestion}, Question id: ${liveShowUser.ghostQuestionId}`
        : `Not ghosted`;
    });

    setLiveShowGhosted(result);
  };

  const usedBoostColumnResult = (): void => {
    const result: ILiveShowBoosts = {};

    fetchLiveShowUserResult.data?.quizLiveShowUser?.forEach((liveShowUser, index) => {
      const roundsLength: number = (liveShowUser?.show.rounds.length ?? 1) - 1;
      const showDate = moment(liveShowUser?.show.startDate);
      const lastShowDate = moment(
        liveShowUser?.show.lastQuestionStartDate + liveShowUser?.show.rounds[roundsLength].questionDuration
      );

      const transactions =
        queryResult.data?.analytics.userTransactions.filter((tr) => {
          return (
            tr.transaction_name?.includes('use.boost.live_show') &&
            moment(tr.timestamp).isBetween(showDate, lastShowDate)
          );
        }) ?? [];

      const boosts: IBoosts = {};

      transactions.forEach((tr) => {
        boosts[tr.resource_name] = boosts[tr.resource_name] ?? 0;
        boosts[tr.resource_name] += tr.amount;
      });

      let text = '';

      if (Object.keys(boosts).length > 0) {
        Object.keys(boosts).forEach((key, idx) => {
          text += `${boosts[key]} ${key}`;
          text += idx !== Object.keys(boosts).length - 1 ? ', ' : '';
        });
      } else {
        text = `No boosts`;
      }

      result[liveShowUser?.id ?? index] = { text, boosts };
    });

    setLiveShowBoosts(result);
  };

  return (
    <Card className="mb-4">
      <Card.Body>
        <Accordion>
          <Accordion.Toggle as={Card.Title} variant="link" eventKey="0" onClick={() => setExpanded(!expanded)}>
            <div
              style={{
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              LiveShow Refund
              <Button variant="link">
                {!expanded && <i className="fas fa-chevron-down" />}
                {expanded && <i className="fas fa-chevron-up" />}
              </Button>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <>
              <Form onSubmit={searchLiveShowUsers} className="m-2">
                <div className="d-flex">
                  <Col className="pl-0">
                    <Form.Control
                      type="date"
                      size="sm"
                      value={liveShowDate}
                      onChange={(e) => setLiveShowDate(e.target.value)}
                    />
                  </Col>
                  <Button
                    type="submit"
                    size="sm"
                    disabled={fetchLiveShowUserResult.loading || queryResult.loading || refundLoading}
                  >
                    Search
                  </Button>
                </div>
              </Form>
              {(fetchLiveShowUserResult.loading || queryResult.loading || refundLoading) && (
                <div className="m-2">
                  <Spinner animation="border" />
                </div>
              )}
              {fetchLiveShowUserResult.error && (
                <div className="m-1 text-danger">{fetchLiveShowUserResult.error.message}</div>
              )}
              {queryResult.error && <div className="m-1 text-danger">{queryResult.error.message}</div>}
              {fetchLiveShowUserResult.data &&
                queryResult.data &&
                Object.keys(queryResult.data).length > 0 &&
                (fetchLiveShowUserResult.data.quizLiveShowUser?.length ? (
                  <div className="table-responsive" style={{ maxHeight: '100vh' }}>
                    <div>
                      <Table striped bordered size="sm" id="transactionsTable">
                        <thead>
                          <tr>
                            {COLUMNS.map((column) => (
                              <th key={column}>{column}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {fetchLiveShowUserResult.data.quizLiveShowUser?.map((liveShowUser, index) => (
                            <tr key={index}>
                              <td key={COLUMNS[0]}>{liveShowUser?.show.id}</td>
                              <td key={COLUMNS[1]}>
                                {liveShowUser?.show.winners.find((w) => w.id === userId) ? 'Yes' : 'No'}
                              </td>
                              <td key={COLUMNS[2]}>{liveShowGhosted[liveShowUser?.id ?? index]}</td>
                              <td key={COLUMNS[3]}>
                                {liveShowUser?.diamondReward
                                  ? `${liveShowUser.diamondReward} diamonds`
                                  : `No. Reward was: ${liveShowUser?.show.diamondPrize} diamonds`}
                              </td>
                              <td key={COLUMNS[4]}>{liveShowBoosts[liveShowUser?.id ?? index]?.text}</td>
                              <td key={COLUMNS[5]}>
                                <Button
                                  size="sm"
                                  disabled={
                                    Object.keys(liveShowBoosts[liveShowUser?.id ?? index]?.boosts ?? {}).length === 0 ||
                                    refundLoading
                                  }
                                  onClick={() => {
                                    setLiveShowId(liveShowUser?.id ?? '');
                                    refundLiveShow({ variables: { userId, showId: liveShowUser?.show.id ?? '' } });
                                  }}
                                >
                                  Refund
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <Modal show={showRefundModal} centered={true}>
                        <div style={{ display: 'flex', flexDirection: 'column', padding: '20px' }}>
                          <h2 style={{ textAlign: 'center' }}>{modalInfo.title}</h2>
                          {modalInfo.error ? (
                            <p>{modalInfo.error}</p>
                          ) : (
                            <p>
                              <b>{liveShowBoosts[liveShowId]?.text}</b> have been added to the user account
                            </p>
                          )}
                          <Button size="sm" onClick={() => setShowRefundModal(false)} style={{ width: '100%' }}>
                            OK
                          </Button>
                        </div>
                      </Modal>
                    </div>
                  </div>
                ) : (
                  <div className="m-1">Player did not participate in the live show on that day.</div>
                ))}
            </>
          </Accordion.Collapse>
        </Accordion>
      </Card.Body>
    </Card>
  );
};

export default LiveShowDisplay;
