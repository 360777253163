import { gql } from '@apollo/client';

export const ROLES_LIST = gql`
  query getUsers($role: UserRole!) {
    liveOps {
      usersWithRoles(role: $role) {
        id
        role
        displayName
        picture
        emailCredential
      }
    }
  }
`;

export const UPDATE_ROLE = gql`
  mutation updateAccountRole($input: UpdateUserRoleInput!) {
    updateUserRole(input: $input) {
      user {
        id
        role
      }
    }
  }
`;

export const SET_USER_SUBSCRIPTION_TIER = gql`
  mutation setSubscriptionTier($input: SetSubscriptionTierInput!) {
    setSubscriptionTier(input: $input) {
      user {
        id
      }
    }
  }
`;
