import { LazyQueryResult, QueryLazyOptions, QueryResult, useLazyQuery } from '@apollo/client';
import _ from 'lodash';
import moment from 'moment';
import { useMemo, useRef, useState } from 'react';
import { JsonObjectExpression } from 'typescript';
import { DashboardQueryType, GetDashboardDataQuery, GetDashboardDataQueryVariables } from '../../../__gqltypes__';
import DataTable from '../models/DataTable';
import FilterManager, { QueryTemplates } from '../models/FilterManager';
import { GET_DASHBOARD_DATA } from '../services/apolloQueries';
import { Filter } from '../services/filters';
import { SlicingClause } from '../services/slicingClauses';

const dataReducer = (
  backResponse: GetDashboardDataQuery,
  startDate = moment().subtract(14, 'days').format('L'),
  endDate = moment().format('L')
) => {
  const requestResponses: Record<string, DataTable> = {};
  backResponse.analytics.dashboardQueries.forEach((response) => {
    const data = response.data?.map((v) => JSON.parse(v));
    // @ts-ignore
    requestResponses[response.id] = DataTable.fromBackEndResponse(data, startDate, endDate);
  });
  return requestResponses;
};

// helper method to fetch dashboard data with some kind of pagination
const fetchDataboardData = async (
  chunkQueries: { id: string; query: string; type: DashboardQueryType }[][],
  fetchData: (
    options?: QueryLazyOptions<GetDashboardDataQueryVariables>
  ) => Promise<LazyQueryResult<GetDashboardDataQuery, GetDashboardDataQueryVariables>>,
  fetchMoreData: QueryResult<GetDashboardDataQuery, GetDashboardDataQueryVariables>['fetchMore']
) => {
  for (let i = 0; i < chunkQueries.length; i++) {
    if (i === 0) {
      await fetchData({ variables: { sqlQueries: chunkQueries[i] } });
    } else {
      await fetchMoreData({
        variables: { sqlQueries: chunkQueries[i] },
        // this will merge the previously fetched data with these new pages
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) return previousResult;
          const newQueries = [
            ...previousResult.analytics.dashboardQueries,
            ...fetchMoreResult.analytics.dashboardQueries,
          ];
          return {
            ...previousResult,
            analytics: {
              ...previousResult.analytics,
              dashboardQueries: newQueries,
            },
          };
        },
      });
    }
  }
};

const useDashboard = (queries: QueryTemplates, id: string, filters: Record<string, Filter>) => {
  const [slicerValues, setSlicerValues] = useState<Record<string, string>>({});
  const filterManager = useRef(new FilterManager(id, queries, filters));
  const [refetching, setRefetching] = useState(false);

  const [getDashboardDataFn, { loading, data, fetchMore }] = useLazyQuery<
    GetDashboardDataQuery,
    GetDashboardDataQueryVariables
  >(GET_DASHBOARD_DATA);

  const metadataList = useMemo(() => {
    if (!data || !data.analytics.dashboardQueries) {
      return {};
    }
    const m: Record<string, JsonObjectExpression> = {};
    const {
      analytics: { dashboardQueries },
    } = data;
    dashboardQueries.forEach(({ id: key, metadata }) => {
      if (metadata) {
        m[key] = JSON.parse(metadata);
      }
    });
    return m;
  }, [data]);

  const errors = useMemo(() => {
    if (!data || !data.analytics.dashboardQueries) {
      return {};
    }
    const err: Record<string, JsonObjectExpression> = {};
    const {
      analytics: { dashboardQueries },
    } = data;
    dashboardQueries.forEach(({ id: key, error }) => {
      if (error) {
        err[key] = JSON.parse(error);
      }
    });
    console.log('Querydata', dashboardQueries, err);
    return err;
  }, [data]);

  const onRefresh = (values: Record<string, string>, clauses: Record<string, SlicingClause>) => {
    setSlicerValues(values);
    setRefetching(true);
    filterManager.current.handleRefresh(values, clauses).then((renderedQueries) => {
      const allSqlQueries = Object.keys(renderedQueries).map((queryId) => ({
        id: queryId,
        query: renderedQueries[queryId],
        type: queries[queryId].source ?? DashboardQueryType.BIGQUERY,
      }));
      console.log('Queries preview', allSqlQueries);

      // we ran into a "Payload Too Big" issue.
      // We now split the sqlQueries into smaller chunks
      const chunkQueries = _.chunk(allSqlQueries, 20);
      fetchDataboardData(chunkQueries, getDashboardDataFn, fetchMore).then(() => setRefetching(false));
    });
  };

  return {
    loading: loading || refetching,
    data: data
      ? dataReducer(
          data,
          slicerValues ? slicerValues.startDate : undefined,
          slicerValues ? slicerValues.endDate : undefined
        )
      : {},
    errors: errors ?? {},
    metadataList: metadataList ?? {},
    onRefresh,
  };
};

export default useDashboard;
