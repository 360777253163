import _ from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { Button, Card, Spinner, Table } from 'react-bootstrap';
import EditRewardsModal from '../../../components/EditRewardsModal';
import { ItemAvailability, LevelRewardInput } from '../../../../../../__gqltypes__';
import Chip from '../../../components/Chip';

export type TeamHeatIterationValue = {
  milestonesReward: Array<any>;
};

type Props = {
  loading: boolean;
  iterationData: Array<TeamHeatIterationValue>;
  onChangeIteration: (newData: Array<TeamHeatIterationValue>) => void;
  onUpload: () => void,
};

export function TeamHeatIteration({loading, iterationData, onChangeIteration, onUpload}: Props) {
  const [showEditReward, setShowEditReward] = useState(false);
  const [editingIterationIndex, setEditingIterationIndex] = useState<number>(-1);
  const [editingMilestoneIndex, setEditingMilestoneIndex] = useState<number>(-1);
  const iterationAmount = 9;
  const milestoneAmount = 4;

  const iterations: Array<Array<LevelRewardInput>> = useMemo(() => {
    const iterations_ = new Array(iterationAmount);
    for (let i  = 0; i < iterationAmount; ++i) {
      iterations_[i] = new Array(milestoneAmount);
      for (let j = 0; j < milestoneAmount; ++j) {
        iterations_[i][j] = null;
      }
    }

    iterationData.forEach((iteration, itIndex) => {
      if (iteration) {
        iteration.milestonesReward.forEach((milestone, mIndex) => {
          if (milestone !== undefined) {
            iterations_[itIndex][mIndex] = milestone;
          }
        });
      }
    });

    return iterations_;
  }, [iterationData]);

  /**
   * On reward selected, save to state curent selected reward and allow edit reward to be displayed
   */
  const handleShowEditReward = useCallback((iterationIndex: number, milestoneIndex: number) => {
    setEditingIterationIndex(iterationIndex);
    setEditingMilestoneIndex(milestoneIndex);
    setShowEditReward(true);
  }, []);

  const onUpdateIteration = useCallback((
    iterationIndex: number,
    milestoneIndex: number,
    newReward: LevelRewardInput | null
  ): Array<TeamHeatIterationValue> => {
    if (iterationIndex !== -1 && milestoneIndex !== -1) {
      iterationData[iterationIndex].milestonesReward[milestoneIndex] = newReward;

    }
    return [...iterationData];
  }, [iterationData]);

  const handleUpload = useCallback(() => {

  }, []);

  const currentReward = 
    editingIterationIndex !== -1 && 
    editingMilestoneIndex !== -1
      ? iterations[editingIterationIndex][editingMilestoneIndex]
      : null;

  return (
    <Card className="mt-3 mb-3" >
      <Card.Body className="text-center">
        <Card.Title>Team Heat Iterations</Card.Title>
        {loading ? (
          <Spinner animation="border"/>
        ): (
          <>
          <EditRewardsModal 
            disableMonthlyBuff
            disableContentPackReward
            disableCurrencySpecialEvent
            show={showEditReward}
            tier={null}
            level={0}
            isEdit={
              editingIterationIndex !== -1 && 
              editingMilestoneIndex !== -1 &&
              !!iterations[editingIterationIndex][editingMilestoneIndex]
            }
            handleClose={() => setShowEditReward(false)}
            handleSave={(newReward) => {
              setShowEditReward(false);
              onChangeIteration(onUpdateIteration(editingIterationIndex, editingMilestoneIndex, newReward));
            }}
            reward={currentReward}
            liveOpsAvailabilities={[ItemAvailability.EARN]}
          />
          <Table bordered responsive hover>
            <thead className="text-center">
              <tr>
                <th>Iteration</th>
                <th>Milestone #1</th>
                <th>Milestone #2</th>
                <th>Milestone #3</th>
                <th>Milestone #4</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {iterations.map((itVal, index) => (
                <tr key={index}>
                  <td className="align-middle">{index + 1}</td>
                  {itVal.map((milestone, mlIndex) => (
                    <td className="align-middle">
                      {milestone?.type ? (
                        <Chip
                          reward={milestone}
                          canDelete={true}
                          btnAction={() => {
                            onChangeIteration(onUpdateIteration(index, mlIndex, null));
                          }}
                          globalAction={() => handleShowEditReward(index, mlIndex)}
                         />
                      ) : (
                        <Chip
                          isAdd
                          outlined
                          btnAction={() => handleShowEditReward(index, mlIndex)}
                          globalAction={() => handleShowEditReward(index, mlIndex)}
                        />
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
          <Button className="align-self-center ml-1" onClick={onUpload}>
            Save Changes
          </Button>
          </>
        )}
      </Card.Body>
    </Card>
  );
}