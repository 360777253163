import { useLazyQuery } from '@apollo/client';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { Accordion, Dropdown, DropdownButton } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import { UserTransactionsQuery } from '../../../../../../__gqltypes__';
import { USER_TRANSACTIONS } from '../../../graphql';

const COLUMNS = [
  'date',
  'timestamp',
  'install_id',
  'user_id',
  'user_subscription_tier',
  'user_level',
  'transaction_name',
  'transaction_id',
  'transaction_type',
  'resource_type',
  'resource_name',
  'amount',
  'session_id',
];

const TransactionDisplay = ({ userId }: { userId: string }) => {
  const [runQuery, queryResult] = useLazyQuery<UserTransactionsQuery>(USER_TRANSACTIONS, {
    fetchPolicy: 'no-cache',
  });
  const [expanded, setExpanded] = useState(false);
  const [startDate, setStartDate] = useState(() => moment().subtract(1, 'month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(() => moment().format('YYYY-MM-DD'));

  // @ts-ignore
  const searchLogs = (e) => {
    e.preventDefault();
    runQuery({
      variables: { userId, startDate, endDate },
    });
  };

  const [filterState, setFilterState] = useState('');
  const filteredTransactions = useMemo(() => {
    if (filterState.length === 0) {
      return queryResult.data?.analytics.userTransactions;
    }
    return queryResult.data?.analytics.userTransactions.filter((tr) =>
     tr.resource_type.toUpperCase().includes(filterState.toUpperCase()));
  }, [queryResult.data?.analytics.userTransactions, filterState]);

  const resourceTypes:string[] = useMemo(() => {
    if(queryResult.data)
    {
      const arr = queryResult.data.analytics.userTransactions.map(tr => {
        return String(tr.resource_type);
      });
      return Array.from(new Set(arr));
    }
    return [];
  }, [queryResult.data?.analytics.userTransactions]);

  return (
    <Card className="mb-4">
      <Card.Body>
        <Accordion>
          <Accordion.Toggle as={Card.Title} variant="link" eventKey="0" onClick={() => setExpanded(!expanded)}>
            <div style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}>
              Transaction logs
              <Button variant="link">
                {!expanded && <i className="fas fa-chevron-down" />}
                {expanded && <i className="fas fa-chevron-up" />}
              </Button>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <>
              <Form onSubmit={searchLogs} className="m-2">
                <div className="d-flex">
                  <Col className="pl-0">
                    <Form.Control
                      type="date"
                      size="sm"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </Col>
                  <Col xs="auto">to</Col>
                  <Col>
                    <Form.Control type="date" size="sm" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                  </Col>
                  <Button type="submit" size="sm" disabled={queryResult.loading}>
                    Search
                  </Button>
                </div>
              </Form>
              {queryResult.loading && (
                <div className="m-2">
                  <Spinner animation="border" />
                </div>
              )}
              {queryResult.error && <div className="m-1 text-danger">{queryResult.error.message}</div>}
              {queryResult.data &&
                (queryResult.data.analytics?.userTransactions?.length ? (
                  <div className="table-responsive" style={{ maxHeight: '100vh' }}>
                    <div className="m-2">
                      <input type="text" value={filterState} onChange={e => setFilterState(e.target.value)} placeholder="Filter by resource_type.." />
                      <DropdownButton as="span" id="dropdown-item-button" size="sm" title="Resource types">
                        {resourceTypes.map((f) => (
                          <Dropdown.Item key={f} as="button" onClick={() => setFilterState(f)}>
                            {f}
                          </Dropdown.Item>
                        ))}
                      </DropdownButton>                      
                    </div>
                    <div>
                      <Table striped bordered size="sm" id="transactionsTable">
                        <thead>
                          <tr>
                            {COLUMNS.map((column) => (
                              <th key={column}>{column}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {filteredTransactions?.map((trans) => (
                            <tr>
                              {COLUMNS.map((column) => (
                                // @ts-ignore
                                <td key={column}>{trans[column]}</td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                ) : (
                  <div className="m-1">No results.</div>
                ))}
            </>
          </Accordion.Collapse>
        </Accordion>
      </Card.Body>
    </Card>
  );
};

export default TransactionDisplay;
