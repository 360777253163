import { ApolloCache, Reference, useMutation } from '@apollo/client';
import { Button, Modal, ModalBody, ModalTitle, Row } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import {
  DeleteImportantEventMutation,
  DeleteImportantEventMutationVariables,
  DeleteImportantEventPayload,
  ImportantEvent,
} from '../../../../../../__gqltypes__';
import { DELETE_IMPORTANT_EVENT } from '../graphql';

interface ConfirmDeleteImportantEventProps {
  event: ImportantEvent | null;
  setEventToDelete: (event: ImportantEvent | null) => void;
}

export default function ConfirmDeleteImportantEvent({ event, setEventToDelete }: ConfirmDeleteImportantEventProps) {
  // Callback to add the new event
  const onDeleteCompleted = (cache: ApolloCache<unknown>, deletedImportantEvent?: DeleteImportantEventPayload) => {
    if (!deletedImportantEvent) return;
    cache.modify({
      fields: {
        getImportantEvents(existingImportantEventRefs = [], { readField }) {
          return existingImportantEventRefs.filter(
            (ref: Reference) =>
              `ImportantEvent:${deletedImportantEvent.deletedEventType}:${deletedImportantEvent.deletedEventKey}` !==
              ref.__ref
          );
        },
      },
    });
  };

  // Mutations
  const [deleteImportantEvent, { loading }] = useMutation<
    DeleteImportantEventMutation,
    DeleteImportantEventMutationVariables
  >(DELETE_IMPORTANT_EVENT, {
    onCompleted: () => setEventToDelete(null),
    update(cache, { data }) {
      onDeleteCompleted(cache, data?.deleteImportantEvent);
    },
  });

  // Submitter
  const handleConfirm = () => {
    if (event) {
      deleteImportantEvent({
        variables: {
          input: { type: event.type, eventKey: event.eventKey },
        },
      });
    }
  };

  return (
    <>
      <Modal show={event !== null} onHide={() => setEventToDelete(null)}>
        <ModalHeader>
          <ModalTitle>Please confirm</ModalTitle>
        </ModalHeader>
        <ModalBody>
          Are you sure you want to delete this event:
          <ul>
            <li>
              <b>Event type:</b> {event?.type}
            </li>
            <li>
              <b>Event key:</b> {event?.eventKey}
            </li>
            <li>
              <b>Event description:</b> {event?.description}
            </li>
            <li>
              <b>Event notes:</b> {event?.notes}
            </li>
            <li>
              <b>Important:</b> {event?.important}
            </li>
          </ul>
          <Button onClick={() => handleConfirm()}>{loading ? 'Loading...' : 'Delete'}</Button>
        </ModalBody>
      </Modal>
    </>
  );
}
