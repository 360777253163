import sourceMap from 'source-map';

sourceMap.SourceMapConsumer.initialize({
  'lib/mappings.wasm': 'https://unpkg.com/source-map@0.7.3/lib/mappings.wasm',
});

const STACK_LINE_MATCHERS = [
  { regex: /^(.*)@(\d+):(\d+)$/, idx: [1, 2, 3] }, // Format: someFun@13:12
  { regex: /^at (.*):(\d+):(\d+)$/, idx: [1, 2, 3] }, // Format: at filename:13:12
  { regex: /^at (.*) \((.*):(\d+):(\d+)\)$/, idx: [1, 3, 4] }, // Format: at someFun (filename:13:12)
  { regex: /^at (.*):(\d+)$/, idx: [1, 2, 3] }, // Format: at filename:13
  { regex: /^at .(.+)\(address at index.android.bundle:(\d+):(\d+)\)$/, idx: [1, 2, 3] }, // Format: at .filename(address at index.android.bundle:13:12)
];

function processMatchedLine(match, sourceMapConsumer) {
  return sourceMapConsumer.originalPositionFor({
    line: Number(match.line),
    column: Number(match.column || 0),
    name: match.name,
  });
}

function matchStackLine(line) {
  const found = STACK_LINE_MATCHERS.find((m) => {
    return m.regex.test(line);
  });
  if (found) {
    const match = line.match(found.regex);
    return {
      name: match[found.idx[0]],
      line: match[found.idx[1]],
      column: match[found.idx[2]],
    };
  }
  return null;
}

function processStack(lines, sourceMapConsumer) {
  const result = [];
  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];
    const match = matchStackLine(line);
    if (!match) {
      result.push({ text: line });
    } else {
      result.push(processMatchedLine(match, sourceMapConsumer));
    }
  }
  return result;
}

function formatStack(lines, shorten) {
  let replacePrefix = '';
  if (shorten) {
    const sources = lines.filter((r) => r.source).map((r) => r.source);
    if (sources.length > 1) {
      let prefix = sources[0];
      sources.forEach((s) => {
        while (prefix !== s.slice(0, prefix.length) || prefix.indexOf('node_modules') !== -1) {
          prefix = prefix.slice(0, -1);
        }
      });
      if (prefix !== sources[0]) {
        replacePrefix = prefix;
      }
    }
  }
  /* eslint-disable */
  return lines
    .map((r) => {
      if (r.text) {
        return r.text;
      } else if (!r.source) {
        return '  at <unknown>';
      } else {
        const source =
          replacePrefix && r.source.startsWith(replacePrefix) ? './' + r.source.slice(replacePrefix.length) : r.source;
        if (r.name) {
          return `  at ${r.name} (${source}:${r.line}:${r.column})`;
        } else {
          return `  at ${source}:${r.line}:${r.column}`;
        }
      }
    })
    .join('\n');
  /*  eslint-enable */
}

/**
 * Split one giant line with carriage returns into multiple lines
 */
function formatLine(line) {
  if (line.length === 0) return [line];
  const values = line.split('\n');
  const index = values.findIndex((l) => l.includes('stack:'));
  if (index === -1) {
    return values;
  }
  const lastLine = values[index];
  // eslint-disable-next-line
  let [beforeStack, afterStack] = lastLine.split('stack:');
  if (!afterStack) return values;
  beforeStack += 'stack:';
  afterStack.split(' ');
  const output = afterStack.split(' ');
  output.shift();
  values[index] = beforeStack;
  return values.concat(output);
}

export async function generateOutput(line, rawSourceMap) {
  const result = await sourceMap.SourceMapConsumer.with(rawSourceMap, null, (consumer) => {
    const lines = formatLine(line);
    console.log('lines to process', lines);
    const stack = processStack(lines, consumer);
    console.log('stack processed', stack);
    return formatStack(stack, true);
  });

  return result;
}
