import _ from 'lodash';
import { useState } from 'react';
import { Button, Card, Col, Row, Table } from 'react-bootstrap';
import { ItemAvailability } from '../../../../../../__gqltypes__';
import Chip from '../../../components/Chip';
import EditRewardsModal from '../../../components/EditRewardsModal';
import Editvalue from '../../../components/EditValue';

/**
 * Component to display milestones of a tournament
 */

export type MilestoneValue = {
  score: number | string;
  rewards: any[];
};

type Props = {
  disabled: boolean;
  milestones: MilestoneValue[];
  onChangedMilestones: (m: MilestoneValue[]) => void;
  onUpload: (m: MilestoneValue[]) => void;
};

function MileStone({ disabled, milestones, onChangedMilestones, onUpload }: Props) {
  /**
   * STATE
   */
  // whether of not displaying the edit reward modal
  const [showEditReward, setShowEditReward] = useState(false);
  // the index of the currently edited milestone
  const [editingMilestoneIndex, setEditingMilestoneIndex] = useState<number | null>(null);
  // the index of the currently edited reward
  const [editingRewardIndex, setEditingRewardIndex] = useState<number | null>(null);

  const handleRemoveMilestone = () =>
    milestones.length === 0 ? onChangedMilestones([]) : onChangedMilestones(milestones.slice(0, -1));
  const handleAddMilestone = () => {
    let score: string | number = 0;
    if (!_.isNil(milestones) && milestones.length > 0) {
      score = milestones[milestones.length - 1].score;
      if (_.isNumber(score)) {
        score += 1;
      }
    }
    onChangedMilestones([
      ...milestones,
      {
        score,
        rewards: [],
      },
    ]);
  };

  const handleUpload = () => {
    onUpload(milestones);
  };

  /**
   * Return an updated version of the milestones by only modifying one reward for one milestone
   * @param {Record<any, any>} updates
   * @param {Milestone[]}
   */
  const updateReward = (
    milestoneIndex: number | null,
    rewardIndex: number | null,
    updates: Record<string, any>
  ): MilestoneValue[] => {
    return milestones.map((_milestone, _milestoneIndex) =>
      milestoneIndex === _milestoneIndex
        ? {
            ..._milestone,
            rewards: _milestone.rewards.map((_reward, _rewardIndex) =>
              rewardIndex === _rewardIndex ? { ..._reward, ...updates } : _reward
            ),
          }
        : _milestone
    );
  };
  /**
   * Return an updated version of the milestones by only adding one reward for the selected milestone
   */
  const addReward = (milestoneIndex: number) => {
    return milestones.map((_milestone, _milestoneIndex) =>
      milestoneIndex === _milestoneIndex
        ? {
            ..._milestone,
            rewards: [..._milestone.rewards, { type: '', amount: 0 }],
          }
        : _milestone
    );
  };

  /**
   * Return an updated version of the milestones by only adding one reward for the selected milestone
   */
  const removeReward = (milestoneIndex: number) => {
    return milestones.map((_milestone, _milestoneIndex) =>
      milestoneIndex === _milestoneIndex
        ? {
            ..._milestone,
            rewards: _milestone.rewards.length === 0 ? [] : _milestone.rewards.slice(0, -1),
          }
        : _milestone
    );
  };

  const handleShowEditReward = (milestoneIndex: number, rewardIndex: number) => {
    setEditingMilestoneIndex(milestoneIndex);
    setEditingRewardIndex(rewardIndex);
    setShowEditReward(true);
  };

  return (
    <Card className="mt-3 mb-3">
      <Card.Body className="text-center">
        <Card.Title>Milestones</Card.Title>
        <EditRewardsModal
          disableMonthlyBuff
          disableContentPackReward
          disableCurrencySpecialEvent
          isEdit={
            !_.isNil(editingMilestoneIndex) &&
            !_.isNil(editingRewardIndex) &&
            milestones[editingMilestoneIndex].rewards[editingRewardIndex].type !== ''
          }
          show={showEditReward}
          tier={null}
          level={0}
          reward={
            !_.isNil(editingMilestoneIndex) && !_.isNil(editingRewardIndex)
              ? milestones[editingMilestoneIndex].rewards[editingRewardIndex]
              : ''
          }
          handleClose={() => setShowEditReward(false)}
          handleSave={(newReward) => {
            setShowEditReward(false);
            onChangedMilestones(updateReward(editingMilestoneIndex, editingRewardIndex, newReward));
          }}
          liveOpsAvailabilities={[ItemAvailability.EARN]}
        />
        <Table bordered responsive hover>
          <thead className="text-center">
            <tr>
              <th>Milestone</th>
              <th>Required Score</th>
              <th>Rewards</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {milestones.map((milestone, milestoneIndex) => (
              <tr key={milestoneIndex}>
                <td className="align-middle">{milestoneIndex + 1}</td>
                <td className="align-middle">
                  <Editvalue
                    name="Score"
                    value={milestone.score}
                    handleValueChange={(score) =>
                      onChangedMilestones(
                        milestones.map((element, i) => (i === milestoneIndex ? { ...element, score } : element))
                      )
                    }
                  />
                </td>
                <td className="align-middle">
                  <Col>
                    {milestone.rewards.map((reward, rewardIndex) => (
                      <Row key={`milestone reward${rewardIndex}`}>
                        {reward.type ? (
                          <Chip
                            reward={reward}
                            canDelete={false}
                            globalAction={() => handleShowEditReward(milestoneIndex, rewardIndex)}
                          />
                        ) : (
                          <Chip
                            isAdd
                            outlined
                            btnAction={() => handleShowEditReward(milestoneIndex, rewardIndex)}
                            globalAction={() => handleShowEditReward(milestoneIndex, rewardIndex)}
                          />
                        )}
                      </Row>
                    ))}
                  </Col>
                  <Row className="mb-3">
                    <Button
                      disabled={disabled}
                      variant="success"
                      className="align-self-center"
                      onClick={() => onChangedMilestones(addReward(milestoneIndex))}
                    >
                      +
                    </Button>
                    <Button
                      disabled={disabled}
                      variant="danger"
                      className="align-self-center"
                      onClick={() => onChangedMilestones(removeReward(milestoneIndex))}
                    >
                      X
                    </Button>
                  </Row>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <Button disabled={disabled} variant="success" className="align-self-center" onClick={handleAddMilestone}>
          Add a milestone
        </Button>
        <Button disabled={disabled} variant="danger" className="align-self-center ml-1" onClick={handleRemoveMilestone}>
          Remove a milestone
        </Button>
        <Button disabled={disabled} className="align-self-center ml-1" onClick={handleUpload}>
          Save Changes
        </Button>
      </Card.Body>
    </Card>
  );
}

export default MileStone;
