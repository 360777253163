import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { Alert, Button, Card, Col, Modal, Row } from 'react-bootstrap';
import {
  UpdateHurdleCatalogItemMutation,
  UpdateHurdleCatalogItemMutationVariables,
  DeleteHurdleCatalogItemMutation,
  DeleteHurdleCatalogItemMutationVariables,
} from '../../../../../__gqltypes__';
import mutations from '../../utils/mutations';
import CategoryFields from './CategoryField';
import UpdateOffsetForm from './UpdateOffset';

export default function CatalogItemActions({
  catalogItem: {
    id,
    categoryId,
    excludeFromAutogeneration,
    excludeFromCheck,
    offset,
    item: { asset: sample },
  },
  onUpdate,
  disabled = false,
}: {
  catalogItem: {
    id: string;
    categoryId?: string | null;
    excludeFromAutogeneration?: boolean | null;
    excludeFromCheck?: boolean | null;
    offset: number;
    item: { asset: string };
  };
  onUpdate: () => void;
  disabled?: boolean;
}) {
  const [showModify, setShowModify] = useState(false);

  const [update, { loading: updateLoading, error: updateError }] = useMutation<
    UpdateHurdleCatalogItemMutation,
    UpdateHurdleCatalogItemMutationVariables
  >(mutations.UPDATE_HURDLE_CATALOG_ITEM, {
    onCompleted: () => {
      onUpdate();
      // setShowModify(false);
    },
    onError: () => {},
  });
  const [deleteItem, { error: deleteError }] = useMutation<
    DeleteHurdleCatalogItemMutation,
    DeleteHurdleCatalogItemMutationVariables
  >(mutations.DELETE_HURDLE_CATALOG_ITEM, {
    onCompleted: () => {
      onUpdate();
      setShowModify(false);
    },
  });

  const updateCategory = (newCategory: string) => {
    update({
      variables: {
        input: {
          catalogItemId: id,
          categoryId: newCategory,
        },
      },
    });
  };

  const updateOffset = (newOffset: number) => {
    update({
      variables: {
        input: {
          catalogItemId: id,
          offset: newOffset,
        },
      },
    });
  };

  const updateExcludeFromAutogeneration = (newExcludeFromAutogeneration: boolean) => {
    update({
      variables: {
        input: {
          catalogItemId: id,
          excludeFromAutogeneration: newExcludeFromAutogeneration,
        },
      },
    });
  };

  const updateExcludeFromCheck = (newExcludeFromCheck: boolean) => {
    update({
      variables: {
        input: {
          catalogItemId: id,
          excludeFromCheck: newExcludeFromCheck,
        },
      },
    });
  };

  const deleteCatalogItem = () => {
    deleteItem({ variables: { input: { catalogItemId: id } } });
  };

  return (
    <>
      <Button variant="primary" onClick={() => setShowModify(true)} disabled={disabled}>
        <i className="fas fa-wrench" />
      </Button>
      <Modal show={showModify} onHide={() => setShowModify(false)} size="lg" centered>
        <Modal.Body>
          <h4>Update catalog item</h4>
          <p>You are updating the catalog item {id}</p>
          <ul>
            <li>
              Currently <b>{excludeFromAutogeneration ? 'excluded from' : 'included in'}</b> quiz autogeneration
            </li>
            <li>Current category: {categoryId ?? 'Not set'}</li>
          </ul>

          <hr />

          {updateError && <Alert variant="danger">{updateError.message}</Alert>}
          {deleteError && <Alert variant="danger">{deleteError.message}</Alert>}

          <Row>
            <Col>
              <Card className="p-3">
                <h5 className="mb-3">Update category</h5>
                <CategoryFields category={categoryId ?? 'unknown'} onSave={updateCategory} />
              </Card>
              <Card className="p-3 mt-4">
                <h5 className="mb-3">Danger zone</h5>
                <Button variant="danger" onClick={deleteCatalogItem}>
                  Delete catalog item
                </Button>
                (No additional confirmation)
              </Card>
              <Card className="p-3 mt-3">
                <h5 className="mb-3">Exclude from daily checks</h5>
                <Row>
                  <Col>
                    This item is currently <b>{excludeFromCheck ? 'excluded from' : 'included in'}</b> daily checks to
                    avoid explicit or banned content
                  </Col>
                  <Col className="d-flex">
                    <Button
                      variant="danger"
                      className="m-auto"
                      onClick={() => updateExcludeFromCheck(!excludeFromCheck)}
                    >
                      {excludeFromCheck ? 'Include' : 'Exclude'}
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col>
              <Card className="p-3">
                <h5 className="mb-3">Exclude from autogeneration</h5>
                <Row>
                  <Col>
                    This item is currently <b>{excludeFromAutogeneration ? 'excluded from' : 'included in'}</b> quiz
                    autogeneration
                  </Col>
                  <Col className="d-flex">
                    <Button
                      variant="warning"
                      className="m-auto"
                      onClick={() => updateExcludeFromAutogeneration(!excludeFromAutogeneration)}
                    >
                      {excludeFromAutogeneration ? 'Include' : 'Exclude'}
                    </Button>
                  </Col>
                </Row>
              </Card>

              <Card className="p-3 mt-3">
                <UpdateOffsetForm
                  currentOffset={offset}
                  update={updateOffset}
                  sample={sample}
                  loading={updateLoading}
                />
              </Card>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
