import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { Link } from 'react-router-dom';
import { SearchUserQuery } from '../../../../../../__gqltypes__';

const UserReportDisplay = ({ reports }: { reports: NonNullable<SearchUserQuery['user']>['reports'] }) => {
  return (
    <Card className="mb-4">
      <Card.Body>
        <h4>Reports</h4>
        <ListGroup className="mt-1">
          {reports.map((report) => (
            <ListGroup.Item key={report.reporter.id} className="mb-0 px-2 py-1">
              <b>Reported by: </b>
              <Link to={`/sp3/devtools/userview/${report.reporter.id}`}>{report.reporter.id}</Link>
              <br />
              <b>Date: </b>
              {new Date(report.created).toISOString()}
              <br />
              <b>Comment: </b>
              {report.comment ?? 'N/A'}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Card.Body>
    </Card>
  );
};

export default UserReportDisplay;
