import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Accordion, Button } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { ContentPackAvailabilityType, ContentSearchType, SearchUserQuery } from '../../../../../../__gqltypes__';
import ContentPackListEditor from '../../../../curation/screens/ContentPackList/ContentPackListEditor';

type OwnedContentPack = NonNullable<SearchUserQuery['user']>['ownedContentPacks']['list'][0];
type ContentPack = OwnedContentPack['contentPack'];

const SecretContentPackIdsDisplay = ({
  earnedContentPacks,
  onChange,
}: {
  earnedContentPacks: OwnedContentPack[];
  onChange: (data: string[]) => Promise<void>;
}) => {
  const [currentContentPacks, setCurrentContentPacks] = useState<ContentPack[]>([]);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (!_.isNil(earnedContentPacks)) {
      const _earnedContentPacks = earnedContentPacks.map((earnedContentPack) => earnedContentPack.contentPack);
      setCurrentContentPacks(_earnedContentPacks);
    }
  }, [earnedContentPacks]);

  return (
    <Card className="mb-4">
      <Card.Body>
        <Accordion>
          <Accordion.Toggle as={Card.Title} variant="link" eventKey="0" onClick={() => setExpanded(!expanded)}>
            <div style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}>
              Secret ContentPackIds
              <Button variant="link">
                {!expanded && <i className="fas fa-chevron-down" />}
                {expanded && <i className="fas fa-chevron-up" />}
              </Button>
            </div>
          </Accordion.Toggle>

          <Accordion.Collapse eventKey="0">
            <ContentPackListEditor
              defaultSearchType={ContentSearchType.BY_AVAILABILITY_TYPE}
              defaultAvailabilityType={ContentPackAvailabilityType.EARN}
              contentPacks={currentContentPacks}
              updatePackIds={onChange}
              enableImport
            />
          </Accordion.Collapse>
        </Accordion>
      </Card.Body>
    </Card>
  );
};

export default SecretContentPackIdsDisplay;
