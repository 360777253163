import React, { useState } from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';

import { LevelRewardInput, MissionInput, MissionTaskInput, MissionTaskType, UserSubscriptionTier } from '../../../../../__gqltypes__';
import EditValue from '../../components/EditValue';
import EditTask from './components/EditTask';
import EditTaskModal from './components/EditTaskModal';
import { FilledMissionTaskInput, FilledMissionInput } from './typedef';
import EditRewardsModal from '../../components/EditRewardsModal';
import Chip from '../../components/Chip';

// Fixtures
const defaultTaskInput: MissionTaskInput = {
  type: MissionTaskType.USE_STICKER,
  params: [],
};

const defaultMissionInput: MissionInput = {
  requiredTier: UserSubscriptionTier.BASIC,
  rewardXp: 0,
  tasks: [],
};

type Props = {
  loading: boolean;
  missions: Array<Array<FilledMissionInput>>;
  onChange: (missions: Array<Array<FilledMissionInput>>) => void;
  hasGroups: boolean;
  isTeamMissions: boolean;
};

// missions are are an array of array of missions
// missions[x] is a group of missions that will be displayed under the same numbered row
// missions[x][y] is a mission
export function MonthlyPassMissionTable({ loading, missions, onChange, hasGroups, isTeamMissions }: Props) {
  const [selectedTask, setSelectedTask] = useState<{
    missionGroupIndex: number;
    missionIndex: number;
    taskIndex: number;
    task: FilledMissionTaskInput;
  } | null>(null);
  const [showEditTaskModal, setShowEditTaskModal] = useState(false);
  const [rewardIndex, setRewardIndex] = useState<{missionGroupIndex: number; missionIndex: number;} | null>(null);
  const [selectedReward, setSelectedReward] = useState<LevelRewardInput | null>(null);
  const [showEditReward, setShowEditReward] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const handleRequiredTierChange = (
    event: React.FormEvent<EventTarget>,
    missionGroupIndex: number,
    missionIndex: number
  ) => {
    const newRequiredTier = (event.target as HTMLInputElement).value as UserSubscriptionTier;

    missions[missionGroupIndex][missionIndex] = {
      ...missions[missionGroupIndex][missionIndex],
      requiredTier: newRequiredTier,
    };
    onChange(missions);
  };

  const handleRewardXPChange = (rewardXp: string, missionGroupIndex: number, missionIndex: number) => {
    missions[missionGroupIndex][missionIndex] = {
      ...missions[missionGroupIndex][missionIndex],
      rewardXp: Number(rewardXp),
    };
    onChange(missions);
  };

  const handleTaskChange = (updatedTask: FilledMissionTaskInput) => {
    if (selectedTask != null) {
      missions[selectedTask.missionGroupIndex][selectedTask.missionIndex].tasks[selectedTask.taskIndex] = updatedTask;
      onChange(missions);
    }
  };

  const addMissionGroup = () => {
    // the new mission is by default added to a group (the doubled array)
    onChange(missions.concat([[{ ...defaultMissionInput, tasks: [] }]]));
  };

  const handleAddMissionToMissionGroup = (missionGroupIndex: number) => {
    missions[missionGroupIndex].push({ ...defaultMissionInput, tasks: [] });
    onChange(missions);
  };

  const fillMissingMissionGroup = (missionGroupIndex: number) => {
    missions[missionGroupIndex] = [{ ...defaultMissionInput, tasks: [] }];
    onChange(missions);
  };

  const handleDeleteMissionFromMissionGroup = (missionGroupIndex: number, missionIndex: number) => {
    missions[missionGroupIndex] = [
      ...missions[missionGroupIndex].slice(0, missionIndex),
      ...missions[missionGroupIndex].slice(missionIndex + 1),
    ];
    onChange(missions);
  };

  const handleDeleteMissionGroup = (missionGroupIndex: number) => {
    missions = [...missions.slice(0, missionGroupIndex), ...missions.slice(missionGroupIndex + 1)];
    onChange(missions);
  };

  const moveMissionGroupUp = (missionGroupIndex: number) => {
    [missions[missionGroupIndex], missions[missionGroupIndex - 1]] = [
      missions[missionGroupIndex - 1],
      missions[missionGroupIndex],
    ];
    onChange(missions);
  };

  const moveMissionGroupDown = (missionGroupIndex: number) => {
    [missions[missionGroupIndex], missions[missionGroupIndex + 1]] = [
      missions[missionGroupIndex + 1],
      missions[missionGroupIndex],
    ];
    onChange(missions);
  };

  const addTaskToMission = (missionGroupIndex: number, missionIndex: number) => {
    missions[missionGroupIndex][missionIndex].tasks.push({ ...defaultTaskInput });
    onChange(missions);
  };

  const removeTaskFromMission = (missionGroupIndex: number, missionIndex: number, taskIndex: number) => {
    missions[missionGroupIndex][missionIndex].tasks = missions[missionGroupIndex][missionIndex].tasks
      .slice(0, taskIndex)
      .concat(missions[missionGroupIndex][missionIndex].tasks.slice(taskIndex + 1));
    onChange(missions);
  };

  const handleShowEditReward = (missionGroupIndex: number, missionIndex: number, isEdit: boolean) => {
    setIsEditMode(isEdit);
    if (isEdit) {
      setSelectedReward(missions[missionGroupIndex][missionIndex]?.reward ?? null);
    } else {
      setSelectedReward(null);
    }
    setRewardIndex({missionGroupIndex, missionIndex});
    setShowEditReward(true);
  };

  const addRewardToMission = ( newReward: LevelRewardInput, missionGroupIndex: number, missionIndex: number) => {
    missions[missionGroupIndex][missionIndex].reward = newReward;
    onChange(missions);
    setShowEditReward(false);
  };

  const onRemoveRewardFromMission = (missionGroupIndex: number, missionIndex: number) => {
    missions[missionGroupIndex][missionIndex].reward = undefined;
    onChange(missions);
  };

  return (
    <>

      {isTeamMissions && showEditReward &&  (
        <EditRewardsModal
          isEdit={isEditMode}
          tier={null}
          show={true}
          level={0}
          handleSave={
            (newReward) => addRewardToMission(
              newReward, 
              rewardIndex?.missionGroupIndex ?? 0,
              rewardIndex?.missionIndex ?? 0
            )}
          handleClose={() => setShowEditReward(false)}
          reward={selectedReward}
          disableContentPackReward
          disableCurrencySpecialEvent
          disableMonthlyBuff
        />
      )}

      {selectedTask ? (
        <EditTaskModal
          task={selectedTask.task}
          show={showEditTaskModal}
          handleClose={() => setShowEditTaskModal(false)}
          handleConfirm={(updatedTask) => {
            setShowEditTaskModal(false);
            handleTaskChange(updatedTask);
          }}
        />
      ) : null}

      <Table bordered responsive hover size="sm">
        <thead className="text-center">
          <tr>
            <th>N°</th>
            <th>required Tier</th>
            <th>reward XP</th>
            <th>Tasks</th>
            {/* actions buttons are only meaningfull for groups of missions */}
            {hasGroups ? <th>Actions</th> : null}
          </tr>
        </thead>
        <tbody className="text-center">
          {missions.map((missionGroup, missionGroupIndex: number) => (
            <>
              {missionGroup.length === 0 ? (
                <tr key={`missing-${missionGroupIndex}`}>
                  <td colSpan={5}>
                    <span>No mission created for this day </span>
                    <Button onClick={() => fillMissingMissionGroup(missionGroupIndex)}> Add Missions </Button>
                  </td>
                </tr>
              ) : (
                missionGroup.map((mission, missionIndex: number) => (
                  <tr key={`${missionGroupIndex}-${missionIndex}`}>
                    {missionIndex === 0 ? (
                      <td style={{ verticalAlign: 'middle' }} rowSpan={missions[missionGroupIndex].length}>
                        <Col className="d-flex flex-column">
                          {missionGroupIndex === 0 ? null : (
                            <Button size="sm" variant="light" onClick={() => moveMissionGroupUp(missionGroupIndex)}>
                              <i className="fas fa-angle-up" />
                            </Button>
                          )}
                          {missionGroupIndex + 1}
                          {missionGroupIndex === missions.length - 1 ? null : (
                            <Button size="sm" variant="light" onClick={() => moveMissionGroupDown(missionGroupIndex)}>
                              <i className="fas fa-angle-down" />
                            </Button>
                          )}
                        </Col>
                        <Col className="d-flex flex-column">
                          {hasGroups ? (
                            <Button
                              size="sm"
                              variant="success"
                              onClick={() => handleAddMissionToMissionGroup(missionGroupIndex)}
                            >
                              <i className="fas fa-plus mr-2" />
                              Add Mission
                            </Button>
                          ) : null}

                          <Button
                            size="sm"
                            variant="danger"
                            onClick={() => handleDeleteMissionGroup(missionGroupIndex)}
                          >
                            <i className="fas fa-trash mr-2" />
                            {hasGroups ? 'Delete All Missions' : 'Delete Mission'}
                          </Button>
                        </Col>
                      </td>
                    ) : null}

                    <td style={{ verticalAlign: 'middle' }}>
                      <Form.Control
                        name="tier"
                        as="select"
                        value={mission.requiredTier}
                        onChange={(event) => handleRequiredTierChange(event, missionGroupIndex, missionIndex)}
                      >
                        {Object.entries(UserSubscriptionTier).map(([key, value]) => (
                          <option key={`${missionGroupIndex}-${missionIndex}-tiers-${key}`} value={key}>
                            {value}
                          </option>
                        ))}
                      </Form.Control>
                    </td>
                    <td style={{ verticalAlign: 'middle' }}>
                      <EditValue
                        name="XP"
                        value={mission.rewardXp}
                        handleValueChange={(xp) => handleRewardXPChange(xp, missionGroupIndex, missionIndex)}
                      />
                    </td>
                    {isTeamMissions && 
                     <td style={{ verticalAlign: 'middle', width: 200 }}>
                      <Chip 
                        reward={mission?.reward ?? undefined}
                        canDelete={!!mission?.reward}
                        isAdd={!mission?.reward}
                        btnAction={() => onRemoveRewardFromMission(missionGroupIndex, missionIndex)}
                        globalAction={() => handleShowEditReward(missionGroupIndex, missionIndex, !!mission?.reward)}/>
                      </td>
                    }
                    <td style={{ verticalAlign: 'middle' }}>
                      <Row className="m-2 align-items-center">
                        <Col className="d-flex flex-column align-items-center">
                          {mission.tasks.length === 0 ? <span> No task selected for this mission</span> : null}
                          {mission.tasks.map((task, taskIndex: number) => (
                            <EditTask
                              key={`${missionGroupIndex}-${missionIndex}-tasks-${taskIndex}`}
                              task={task}
                              onClick={() => {
                                setShowEditTaskModal(true);
                                setSelectedTask({ missionGroupIndex, missionIndex, taskIndex, task });
                              }}
                              onDelete={() => removeTaskFromMission(missionGroupIndex, missionIndex, taskIndex)}
                            />
                          ))}
                        </Col>
                        <Button size="sm" onClick={() => addTaskToMission(missionGroupIndex, missionIndex)}>
                          Add Task
                        </Button>
                      </Row>
                    </td>

                    {hasGroups ? (
                      <td style={{ verticalAlign: 'middle' }}>
                        <Button
                          size="sm"
                          variant="danger"
                          onClick={() => handleDeleteMissionFromMissionGroup(missionGroupIndex, missionIndex)}
                        >
                          <i className="fas fa-trash mr-2" />
                          Delete This Mission
                        </Button>
                      </td>
                    ) : null}
                  </tr>
                ))
              )}
            </>
          ))}
        </tbody>
      </Table>
      <Button onClick={addMissionGroup}>
        <i className="fas fa-plus mr-2" /> {hasGroups ? 'Add Mission Group' : 'Add Mission'}
      </Button>
    </>
  );
}
