import { gql } from "@apollo/client";

export const IMPORTANT_EVENT_FRAGMENT = gql`
  fragment CompleteImportantEvent on ImportantEvent {
    type
    eventKey
    notes
    description
    datetime
    important
  }
`;

export const CREATE_CUSTOM_IMPORTANT_EVENT = gql`
  mutation createCustomImportantEvent($input: CreateImportantEventInput!) {
    createCustomImportantEvent(input: $input) {
      ...CompleteImportantEvent
    }
  }

  ${IMPORTANT_EVENT_FRAGMENT}
`;

export const CREATE_BACKEND_DEPLOYMENT_IMPORTANT_EVENT = gql`
  mutation createBackendDeploymentImportantEvent($input: CreateBackendDeploymentImportantEventInput!) {
    createBackendDeploymentImportantEvent(input: $input) {
      ...CompleteImportantEvent
    }
  }

  ${IMPORTANT_EVENT_FRAGMENT}
`;

export const CREATE_FRONTEND_DEPLOYMENT_IMPORTANT_EVENT = gql`
  mutation createFrontendDeploymentImportantEvent($input: CreateFrontendDeploymentImportantEventInput!) {
    createFrontendDeploymentImportantEvent(input: $input) {
      ...CompleteImportantEvent
    }
  }

  ${IMPORTANT_EVENT_FRAGMENT}
`;

export const UPDATE_IMPORTANT_EVENT = gql`
  mutation updateImportantEvent($input: UpdateImportantEventInput!) {
    updateImportantEvent(input: $input) {
      ...CompleteImportantEvent
    }
  }

  ${IMPORTANT_EVENT_FRAGMENT}
`;

export const GET_IMPORTANT_EVENTS_BETWEEN = gql`
  query getImportantEvents($input: GetImportantEventsInput!) {
    getImportantEvents(input: $input) {
      ...CompleteImportantEvent
    }
  }

  ${IMPORTANT_EVENT_FRAGMENT}
`;

export const DELETE_IMPORTANT_EVENT = gql`
  mutation deleteImportantEvent($input: DeleteImportantEventInput!) {
    deleteImportantEvent(input: $input) {
      deletedEventType
      deletedEventKey
    }
  }
`;
