import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';

type Props = {
  show: boolean;
  title: string;
  children: React.ReactChild;
  onClose?: () => void;
  buttons?: (React.ReactChild & { key: string; variant: string; onClick: () => void; title: string })[];
  closeButton?: boolean;
  size?: 'sm' | 'lg' | 'xl' | undefined;
};

export const BaseModal = ({
  show,
  title,
  children,
  onClose,
  buttons = [],
  closeButton = false,
  size = undefined,
}: Props) => (
  <Modal show={show} onHide={onClose} size={size}>
    {title && (
      <Modal.Header closeButton={closeButton}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
    )}
    <Modal.Body>{children}</Modal.Body>
    {buttons.length > 0 && (
      <Modal.Footer>
        {buttons.map((button, index) => (
          <Button key={`${button.title}-${index}`} variant={button.variant || 'primary'} onClick={button.onClick}>
            {button.title}
          </Button>
        ))}
      </Modal.Footer>
    )}
  </Modal>
);

export const LoadingModal = ({ show }: { show: boolean }) => (
  <BaseModal show={show} title="Loading ...">
    <div className="d-flex justify-content-center">
      <Spinner animation="border" />
    </div>
  </BaseModal>
);

export const LoadingLogo = ({ show, className }: { show: boolean; className?: string }) =>
  show ? <Spinner className={className} animation="border" /> : null;
