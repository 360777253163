import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { Accordion, Alert, Badge, Dropdown, DropdownButton } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import {
  NotificationPreset,
  SendNotificationMutation,
  SendNotificationMutationVariables,
} from '../../../../../../__gqltypes__';
import { ALL_LOCALES, displayLocaleWithFlag, SUPPORTED_LOCALE } from '../../../../curation/utils/locales';
import { SEND_NOTIFICATION } from '../../../graphql';

const NOTIFICATION_PRESETS = {
  CUSTOM: `{"data":{"deeplink":"turnQuiz/123"},"body":"hey have a good day :)","rich":{"category":"REMINDER","profilePicture":"superPicture","summaryArgument":"someone"}} `,
};

const NotificationResultAlert = ({
  notificationString,
  onClose,
}: {
  notificationString: string | null;
  onClose: () => void;
}) => {
  if (!notificationString) return null;

  const json = JSON.parse(notificationString);

  return (
    <Alert className="mt-4" variant="success" onClose={onClose} dismissible>
      <Alert.Heading>Notifications sent to {json.appInstalls.length} device(s)</Alert.Heading>
      <div>
        <h5>App Installs</h5>
        <ul>
          {json.appInstalls.map((appInstall: any) => {
            return <li>{JSON.stringify(appInstall)}</li>;
          })}
        </ul>
      </div>
      <div>
        <h5>Rich Data</h5>
        {JSON.stringify(json.extraData)}
      </div>
      {json.overwriteInstruction &&
        <div>
          <br />
          <h5>Overwrite Instruction</h5>
          {JSON.stringify(json.overwriteInstruction)}
        </div>
      }

    </Alert>
  );
};

const NotificationToolDisplay = ({ userId }: { userId: string }) => {
  const [sendNotification, { loading: sendNotificationLoading }] = useMutation<
    SendNotificationMutation,
    SendNotificationMutationVariables
  >(SEND_NOTIFICATION, {
    onCompleted: (res) => {
      createAlertResult(res);
    },
  });

  const [expanded, setExpanded] = useState(false);
  const [contentTextAreaValue, setContentTextAreaValue] = useState(NOTIFICATION_PRESETS.CUSTOM);
  const [overwriteTextAreaValue, setOverwriteTextAreaValue] = useState('');
  const [presetSelected, setPresetSelected] = useState(NotificationPreset.CUSTOM);
  const [forcedLocale, setForcedLocale] = useState<null | SUPPORTED_LOCALE>(null);
  const [alert, setAlert] = useState<string | null>(null);

  const createAlertResult = (res: SendNotificationMutation) => {
    setAlert(res.sendNotification as string);
  };

  const handleLoadNotificationPreset = async (preset: NotificationPreset) => {
    setPresetSelected(preset);
    if (preset === NotificationPreset.CUSTOM) {
      setContentTextAreaValue(NOTIFICATION_PRESETS.CUSTOM);
    }
  };

  const handleSendNotification = () => {
    if (presetSelected === NotificationPreset.CUSTOM) {
      sendNotification({
        variables: {
          input: { userId, preset: presetSelected, content: contentTextAreaValue, overwriteMessage: overwriteTextAreaValue },
        },
      });
    }
    if ([NotificationPreset.LIVE_SHOW_START_SOON, NotificationPreset.SONG_OF_THE_DAY].includes(presetSelected)) {
      sendNotification({
        variables: {
          input: { userId, preset: presetSelected, userLang: forcedLocale, overwriteMessage: overwriteTextAreaValue },
        },
      });
    }
  };

  const handleContentTextAreaChange = (e: React.ChangeEvent<HTMLInputElement>) => setContentTextAreaValue(e.target.value);
  const handleOverwriteTextAreaChange = (e: React.ChangeEvent<HTMLInputElement>) => setOverwriteTextAreaValue(e.target.value);

  const handleForcedLocale = (e: SUPPORTED_LOCALE) => setForcedLocale(e);
  const handleRemoveForcedLocale = () => setForcedLocale(null);

  const onAlertClose = () => {
    setAlert(null);
  };

  return (
    <Card className="mb-4">
      <Card.Body>
        <Accordion>
          <Accordion.Toggle as={Card.Title} variant="link" eventKey="0" onClick={() => setExpanded(!expanded)}>
            <div style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}>
              Notification Tool
              <Button variant="link">
                {!expanded && <i className="fas fa-chevron-down" />}
                {expanded && <i className="fas fa-chevron-up" />}
              </Button>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <>
              <DropdownButton
                size="sm"
                title="Preset"
                onSelect={(e) => handleLoadNotificationPreset(e as NotificationPreset)}
              >
                {Object.keys(NotificationPreset).map((type) => (
                  <Dropdown.Item active={type === presetSelected} eventKey={type} key={`dropdown-type-${type}`}>
                    {type}
                  </Dropdown.Item>
                ))}
              </DropdownButton>

              {presetSelected === NotificationPreset.CUSTOM && (
                <Form.Control
                  className="mt-4"
                  as="textarea"
                  rows={10}
                  value={contentTextAreaValue}
                  onChange={handleContentTextAreaChange}
                />
              )}

              {/* it will be used later */}
              {presetSelected !== NotificationPreset.CUSTOM && (
                <>
                  <br />
                  <DropdownButton
                    //   disabled={disabled}
                    as="span"
                    id="dropdown-item-button"
                    size="sm"
                    title="Add Locale"
                  >
                    {ALL_LOCALES.map((f) => (
                      <Dropdown.Item key={f} as="button" onClick={() => handleForcedLocale(f)}>
                        {displayLocaleWithFlag(f)}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                  {forcedLocale && (
                    <Badge pill variant="success" className="tag-pill ml-2">
                      {displayLocaleWithFlag(forcedLocale)}
                      <i role="link" tabIndex={0} className="fas fa-times m-1" onClick={handleRemoveForcedLocale} />
                    </Badge>
                  )}
                </>
              )}

              <Form.Control
                  className="mt-4"
                  as="textarea"
                  rows={10}
                  value={overwriteTextAreaValue}
                  onChange={handleOverwriteTextAreaChange}
                  placeholder={`Complete here to overwrite the notification message (e.g., {"notification":{"title":"This is my new title!","body":"And here's my new body!"},"android":{"notification":{"imageUrl":"https://song.pop/my-new-image.png"}}})`}
              />

              <br />
              <Button disabled={sendNotificationLoading} onClick={handleSendNotification} className="mt-2">
                {sendNotificationLoading ? 'Sending...' : 'Send notification'}
              </Button>
              {alert && <NotificationResultAlert notificationString={alert} onClose={onAlertClose} />}
            </>
          </Accordion.Collapse>
        </Accordion>
      </Card.Body>
    </Card>
  );
};

export default NotificationToolDisplay;
