import { OperationVariables, useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import {
  CreateBatchContentModerationMutation,
  CreateBatchContentModerationMutationVariables,
} from '../../../../../../__gqltypes__';
import mutations from '../../../utils/mutations';

type Props<T> = {
  refetchLists: (variables?: Partial<OperationVariables> | undefined) => Promise<T>;
  setActiveList: (listId: string) => void;
};

const AdminContentModerationCreateBatch = <T,>({ refetchLists, setActiveList }: Props<T>) => {
  const [createBatch] = useMutation<
    CreateBatchContentModerationMutation,
    CreateBatchContentModerationMutationVariables
  >(mutations.CREATE_BATCH_CONTENT_MODERATION);

  const [name, setName] = useState('');
  const [creating, setCreating] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const askCreate = useCallback(() => setCreating(true), [setCreating]);
  const cancelCreate = useCallback(() => setCreating(false), [setCreating]);
  const doCreate = useCallback(
    async (e) => {
      e.preventDefault();
      setSubmitting(true);
      let res;
      try {
        res = await createBatch({
          variables: {
            input: {
              name,
            },
          },
        });
      } catch (err) {
        console.error(err);
        setSubmitting(false);
        return;
      }
      setCreating(false);
      await refetchLists();
      setSubmitting(false);
      if (res.data) {
        setActiveList(res.data.createBatchContentModeration.id);
      }
    },
    [createBatch, refetchLists, setActiveList, name]
  );

  return (
    <>
      <Button className="w-100" variant="success" onClick={askCreate}>
        <i className="fas fa-plus mr-2" />
        New Batch
      </Button>

      <Modal show={creating} onHide={cancelCreate}>
        <Modal.Header closeButton>
          <Modal.Title>Create Batch list</Modal.Title>
        </Modal.Header>
        <Form onSubmit={doCreate}>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control autoFocus value={name} onChange={(e) => setName(e.target.value)} />
              <span>This must be unique.</span>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={cancelCreate} disabled={submitting}>
              Cancel
            </Button>
            <Button variant="success" type="submit" disabled={submitting}>
              {submitting && <Spinner as="span" animation="border" size="sm" className="mr-2" />}
              Create
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default AdminContentModerationCreateBatch;
