import React from 'react';
import { LevelRewardInput } from '../../../../__gqltypes__';
import './assets/Chip.css';
import { formatReward } from './formatReward';

type Props = {
  reward?: LevelRewardInput;
  canDelete?: boolean;
  btnAction?: () => void;
  globalAction: () => void;
  isAdd?: boolean;
  outlined?: boolean;
};

const Chip = ({ reward, isAdd = false, canDelete, outlined = false, btnAction, globalAction }: Props) => (
  <div className={outlined ? 'chip chip-outlined px-3' : 'chip px-3'}>
    <span role="link" tabIndex={0} onClick={globalAction}>
      {isAdd ? 'Add a reward' : formatReward(reward)}
    </span>

    {(isAdd || canDelete) && (
      <button type="button" className="button" onClick={btnAction}>
        {isAdd ? '+' : '×'}
      </button>
    )}
  </div>
);

export default Chip;
