import { gql, useLazyQuery, useMutation } from '@apollo/client';
import firebase from 'firebase/app';
import 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { firebaseConfig } from '../config';
import {
  CredentialSignUpType,
  PracticeQuizQuery,
  PracticeQuizQueryVariables,
  SignInMutation,
  SignInMutationVariables,
  SignUpMutation,
  SignUpMutationVariables,
  UpdateUserPictureMutation,
  UpdateUserPictureMutationVariables,
} from '../__gqltypes__';

let firebaseApp = null;
try {
  firebaseApp = firebase.initializeApp(firebaseConfig);
} catch (e) {
  firebaseApp = firebase.app();
}
const firebaseAppAuth = firebaseApp.auth();

const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
};

let recaptchaVerifier: firebase.auth.RecaptchaVerifier | null = null;

/** GQL QUERIES/MUTATIONS */
const SIGN_IN_MUTATION = gql`
  mutation signIn($input: SignUpInput!) {
    signIn(input: $input) {
      authToken
    }
  }
`;

const SIGN_UP_MUTATION = gql`
  mutation signUp($input: SignUpInput!) {
    signUp(input: $input) {
      authToken
    }
  }
`;

const PRACTICE_QUIZ_QUESTIONS = gql`
  query practiceQuiz($categoryIds: [ID!]!, $explicitContent: Boolean) {
    practiceQuiz(categoryIds: $categoryIds, explicitContent: $explicitContent) {
      id
      contentPack {
        id
      }
      questions {
        id
        correctChoiceIndex
        song {
          id
        }
      }
    }
  }
`;

const UPDATE_PROFILE_PICTURE_MUTATION = gql`
  mutation updateUserPicture($input: UpdateUserPictureInput!) {
    updateUserPicture(input: $input) {
      user {
        id
        picture
      }
    }
  }
`;

type Props = {
  onSignedIn: ({ googleUser, authToken }: { googleUser: firebase.User; authToken: string }) => void;
};

const Auth = ({ onSignedIn }: Props) => {
  const [displayPhoneModal, setDisplayPhoneModal] = useState<boolean | null>(null);
  const [phoneConfirmation, setPhoneConfirmation] = useState<firebase.auth.ConfirmationResult | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [user, setUser] = useState(firebaseAppAuth.currentUser);
  const [prevUser, setPrevUser] = useState<firebase.User | null>(null);

  const [emailProcessStarted, setStartEmailProcess] = useState(false);
  const [phoneProcessStarted, setStartPhoneProcess] = useState(false);
  const [signInMutation] = useMutation<SignInMutation, SignInMutationVariables>(SIGN_IN_MUTATION);
  const [signUpMutation] = useMutation<SignUpMutation, SignUpMutationVariables>(SIGN_UP_MUTATION);
  const [updateUserPictureMutation] = useMutation<UpdateUserPictureMutation, UpdateUserPictureMutationVariables>(
    UPDATE_PROFILE_PICTURE_MUTATION
  );
  const [practiceQuizFn] = useLazyQuery<PracticeQuizQuery, PracticeQuizQueryVariables>(PRACTICE_QUIZ_QUESTIONS);

  const startSignUp = async (signInVars: SignInMutationVariables) => {
    const data = await practiceQuizFn({ variables: { categoryIds: ['rock'] } });
    const quiz = data.data?.practiceQuiz[0];
    if (!quiz) {
      console.error('null data received from practice quiz');
      return;
    }
    const metadata = {
      genreId: 'rock',
      decadeId: 'decades.1980',
      playlistId: quiz.contentPack.id,
      birthyear: 1980,
      answers: quiz.questions.map((q) => ({
        id: q.id,
        answerIndex: q.correctChoiceIndex,
        answerTime: 1000,
        isCorrect: true,
        contentItemId: q.song?.id ?? '',
      })),
      displayName: null,
    };
    const variables = { input: { ...signInVars.input, metadata } };
    signUpMutation({ variables })
      .then(({ data: signUpData }) => {
        if (signUpData && user) {
          updateUserPictureMutation({
            variables: { input: { picture: 'https://storage.googleapis.com/songpop3-test-assets/bot.png' } },
          });
          onSignedIn({ googleUser: user, authToken: signUpData.signUp.authToken });
        } else {
          console.error('null data received in Auth');
        }
      })
      .catch((e) => {
        setErrorMessage(e.message);
        setStartEmailProcess(false);
      });
  };

  useEffect(() => {
    recaptchaVerifier = new firebase.auth.RecaptchaVerifier('phone-sign-in', { size: 'invisible' });
    firebaseAppAuth.onAuthStateChanged((_user) => setUser(_user));
    return firebaseAppAuth.onAuthStateChanged(() => {});
  }, []);

  useEffect(() => {
    console.log('isSignInWithEmailLink', window.location.href);
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt('Please provide your email for confirmation');
      }
      // The client SDK will parse the code from the link for you.
      if (email) {
        firebase
          .auth()
          .signInWithEmailLink(email, window.location.href)
          .then((result) => {
            // Clear email from storage.
            window.localStorage.removeItem('emailForSignIn');
            console.log('setting user', result.user);
            setUser(result.user);

            // You can access the new user via result.user
            // Additional user info profile not available via:
            // result.additionalUserInfo.profile == null
            // You can check if the user is new or existing:
            // result.additionalUserInfo.isNewUser
          })
          .catch((error) => {
            // Some error occurred, you can inspect the code: error.code
            // Common errors could be invalid email and invalid or expired OTPs.
          });
      }
    }
  }, []);

  useEffect(() => {
    // @TODO: "validEmail" makes sure the email address has been verified
    // and prevents login into the application if not. it's disabled for now.
    // const validEmail = newProps.user && Boolean(newProps.user.email) === newProps.user.emailVerified;
    if (/* validEmail && */ user && !prevUser) {
      user.getIdToken(true).then((token) => {
        const variables = {
          input: {
            token,
            credentialType: CredentialSignUpType.FIREBASE,
          },
        };

        signInMutation({ variables })
          .then(({ data }) => {
            if (data) {
              onSignedIn({ googleUser: user, authToken: data.signIn.authToken });
            } else {
              console.error('null data received in Auth');
            }
          })
          .catch((e) => {
            if (e.message === 'Account not found') {
              startSignUp(variables);
            } else {
              setStartEmailProcess(false);
              setErrorMessage(e.message);
            }
            console.error('error', e.message);
          });
      });
    }

    setPrevUser(user);
    // @TODO: see comment about "validEmail" above
    // else if (!validEmail) {
    //   console.log('you must validate your email address before signing in');
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, signInMutation, onSignedIn]);

  const onPhoneLogin = (event: React.MouseEvent<HTMLInputElement>) => {
    event.preventDefault();
    const phoneNumber = (document.getElementById('formPhone') as HTMLInputElement).value.trim();

    if (phoneNumber.length === 0) {
      setErrorMessage('Please provide a valid Phone number');
      return;
    }

    if (recaptchaVerifier) {
      setStartPhoneProcess(true);
      firebaseAppAuth
        .signInWithPhoneNumber(phoneNumber, recaptchaVerifier)
        .then((res) => {
          setDisplayPhoneModal(true);
          setPhoneConfirmation(res);
        })
        .catch((err) => {
          console.error('error during the phone validation process:', err);
          setErrorMessage(err.message);
          setStartPhoneProcess(false);
        });
    }
  };

  const onPhoneVerified = (code: string) => {
    if (phoneConfirmation) {
      phoneConfirmation
        .confirm(code)
        .then((res) => setUser(res.user))
        .catch((err) => {
          console.error('phone confirmation failed', err);
          setErrorMessage(err.message);
        });
    }

    setDisplayPhoneModal(null);
    setPhoneConfirmation(null);
    setStartPhoneProcess(false);
  };

  const onEmailSignin = (event: React.MouseEvent<HTMLInputElement>) => {
    event.preventDefault();
    const email = (document.getElementById('formEmail') as HTMLInputElement).value.trim();

    if (email.length === 0) {
      setErrorMessage('Please provide a valid email');
      return;
    }

    setStartEmailProcess(true);

    const actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be in the authorized domains list in the Firebase Console.
      url: window.location.href,
      // This must be true.
      handleCodeInApp: true,
    };

    firebaseAppAuth
      .sendSignInLinkToEmail(email, actionCodeSettings)
      .then(() => {
        window.localStorage.setItem('emailForSignIn', email);
        alert(`A link has been sent to ${email}, please click on it to continue`);
      })
      .catch((err) => {
        console.error('Email sign in failed,', err);
        setErrorMessage(err.message);
        setStartEmailProcess(false);
      });
  };

  const onSignInWithGoogle = () => {
    firebaseAppAuth
      .signInWithPopup(providers.googleProvider)
      .then((res) => setUser(res.user))
      .catch((err) => {
        console.log('Google sign in failed', err);
        setErrorMessage(err.message);
        setStartEmailProcess(false);
      });
  };

  const handleModalShow = () => {
    (document.getElementById('verificationCode') as HTMLInputElement).focus();
  };

  const handleModalClose = (event?: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (event) {
      event.preventDefault();
      const isOk = (event.currentTarget as HTMLInputElement).id === 'modalOk';
      if (isOk) {
        const code = (document.getElementById('verificationCode') as HTMLInputElement).value;
        onPhoneVerified(code);
        return;
      }
    }

    setDisplayPhoneModal(null);
  };

  const hideError = () => setErrorMessage(null);

  return (
    <Container>
      <Card className="o-hidden border-0 shadow-lg my-5">
        <Card.Body className="p-0">
          <Row>
            <Col className="col-lg-7">
              <div className="p-5">
                <Card.Title>Welcome!</Card.Title>
                <Card.Subtitle>Please register before continuing</Card.Subtitle>

                <hr />
                <Button
                  className="btn-google btn-user"
                  type="submit"
                  onClick={onSignInWithGoogle}
                  disabled={emailProcessStarted || phoneProcessStarted}
                >
                  <i className="fab fa-google fa-fw mr-2" />
                  Register with Google
                </Button>
                <hr />
                <Form className="user">
                  <Form.Group controlId="formPhone">
                    <Form.Control
                      type="phone"
                      required
                      className="form-control-user"
                      placeholder="Enter phone number (with country code, like +1, +33, etc)"
                      disabled={emailProcessStarted || phoneProcessStarted}
                    />
                  </Form.Group>
                  {phoneProcessStarted && <Spinner animation="border" />}
                  <Button
                    type="submit"
                    id="phone-sign-in"
                    style={{ display: 'block', marginTop: '5px' }}
                    onClick={onPhoneLogin}
                    disabled={emailProcessStarted || phoneProcessStarted}
                  >
                    <i className="fas fa-mobile-alt mr-2" />
                    Register with phone number
                  </Button>
                </Form>
                <hr />
                <Form className="user">
                  <Form.Group controlId="formEmail">
                    <Form.Control
                      className="form-control-user"
                      type="email"
                      placeholder="Enter email address"
                      required
                      disabled={emailProcessStarted || phoneProcessStarted}
                    />
                  </Form.Group>
                  {emailProcessStarted && <Spinner animation="border" />}
                  <Button
                    type="submit"
                    variant="primary"
                    onClick={onEmailSignin}
                    disabled={emailProcessStarted || phoneProcessStarted}
                  >
                    <i className="fas fa-envelope mr-2" />
                    Register with Email
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Modal show={!!displayPhoneModal} onShow={handleModalShow} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Phone number verification</Modal.Title>
        </Modal.Header>
        <Form>
          <Modal.Body>
            <Form.Group controlId="verificationCode">
              <Form.Label>Enter the verification code you received by SMS</Form.Label>
              <Form.Control type="tel" autoFocus />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button type="button" variant="secondary" onClick={handleModalClose}>
              Cancel
            </Button>
            <Button type="submit" variant="primary" onClick={handleModalClose} id="modalOk">
              OK
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal show={!!errorMessage} onHide={hideError}>
        <Alert variant="danger" onClose={hideError} dismissible style={{ marginBottom: '0px' }}>
          <Alert.Heading>Error</Alert.Heading>
          <p>{errorMessage}</p>
        </Alert>
      </Modal>
    </Container>
  );
};

export default Auth;
