/* eslint-disable max-len */
import _ from 'lodash';
import last from 'lodash/last';
import moment from 'moment';
import { extractUnique, generateDateRangeIndexColumn, generateIndex, generateRanges } from '../services/utils';

type ColLabel = string;
type ColValue = string | number | null | undefined;

// array of {label -> value}
// for instance
/**
 * [
 *   {date: "xxx", ad_type: "yyy", fill_rate: 0.2}
 *   {date: "xxx", ad_type: "yyy", fill_rate: 0.3}
 *   {date: "xxx", ad_type: "yyy", fill_rate: 0.4}
 *   {date: "xxx", ad_type: "yyy", fill_rate: 0.5}
 *   {date: "xxx", ad_type: "yyy", fill_rate: 0.1}
 *   ...
 *   {date: "xxx", ad_type: "yyy", fill_rate: 0.8}
 * ]
 *
 */
type InputDataWithLabel = Record<ColLabel, ColValue>[];

/**
 * [
 *   ["xxx", "yyy", 0.2]
 *   ["xxx", "yyy", 0.3]
 *   ["xxx", "yyy", 0.3]
 *   ["xxx", "yyy", 0.2]
 *   ...
 *   ["xxx", "yyy", 0.8]
 * ]
 *
 */
type InputData = ColValue[][];

/**
 * {
 *  date: ["xxx", "xxx", "xxx", "xxx"],
 *  ad_type: ["yyy", "yyy", "yyy", "yyy"],
 *  fill_rate: [0.2, 0.3, 0.4, 0.1]
 * }
 */
type InternalData = Record<ColLabel, ColValue[]>;

type IndexValuePositionMap = Record<string | number, number>;

// check if a column has all unique values
function isColUnique(inputData: InputDataWithLabel, index: ColLabel) {
  const colValues = inputData.map((elem) => elem[index]);
  return _.uniq(colValues).length === colValues.length;
}

class DataTable {
  /* Store column label -> array of data */
  data: InternalData;

  /* Store column index label */
  indexColLabel: ColLabel;

  /* Store value -> array position for the index col */
  index: IndexValuePositionMap;

  static fromBackEndResponse(inputData: InputDataWithLabel, startDate: string, endDate: string) {
    if (inputData.length === 0) {
      return new DataTable(null);
    }
    const allLabels = Object.keys(inputData[0]);
    // take the first column as index
    const firstLabel = allLabels[0];
    // index is the label used for x-axis
    const indexLabel = isColUnique(inputData, firstLabel) ? firstLabel : null; // Object.keys(inputData[0])[0];

    let completedData = null;

    if (startDate && endDate && indexLabel && ['day', 'date'].includes(indexLabel)) {
      // if x-axis is a date, we fill up the blanks between the two dates (if any)
      const indexDate = moment(startDate);
      const stopDate = moment(endDate);

      // generate complete index
      const completeIndex = [];
      while (indexDate.isBefore(stopDate)) {
        completeIndex.push(indexDate.format('YYYY-MM-DD'));
        indexDate.add(1, 'day');
      }
      completeIndex.push(stopDate.format('YYYY-MM-DD'));

      // Generate blank row
      const blank = { ...inputData[0] };
      Object.keys(blank).forEach((key) => {
        blank[key] = 0;
      });

      const previousIndexTable = inputData.map((row) => moment(row[indexLabel]).format('YYYY-MM-DD'));

      completedData = completeIndex.map((date) => {
        const dateIndex = previousIndexTable.indexOf(date);
        // if this date already has data
        if (dateIndex >= 0) {
          return inputData[dateIndex];
        }
        // else fill the with a blank
        return { ...blank, [indexLabel]: date };
      });
    }

    // data by rows (array of arrays)
    const data = (completedData || inputData).map((row) => Object.values(row));

    return new DataTable(data, indexLabel, allLabels);
  }

  /**
   * @param {{ [x: string]: number[] } | number[][]} data The input data
   * @param {string} indexCol The name of the index column
   * @param {string[]} colNames The name of the columns (if the data is formatted by row)
   * @param {{ [x: string | number]: number }} index An object to use as the index of the DataTable. Makes the initialization of the DataTable faster
   */
  constructor(
    data: InputData | InternalData | null,
    indexCol: ColLabel | null = null,
    colNames: ColLabel[] | null = null,
    index: IndexValuePositionMap | null = null
  ) {
    if (!data || (Array.isArray(data) && data.length === 0) || Object.keys(data).length === 0) {
      this.data = {};
      this.indexColLabel = '';
      this.index = {};
    } else if (Array.isArray(data)) {
      // data is given by rows

      // column names
      const names = colNames || _.range(data[0].length).map((idx) => `column${idx}`);

      // fill data
      this.data = {};
      names.forEach((col) => {
        this.data[col] = [];
      });
      data.forEach((item) => {
        item.forEach((value, idx) => {
          this.data[names[idx]].push(value);
        });
      });

      // index
      if (!indexCol) {
        this.data.index = _.range(data.length);
        this.indexColLabel = 'index';
      } else {
        this.indexColLabel = names[0];
      }
      this.index = generateIndex(this.data[this.indexColLabel]);
    } else if (_.isObject(data)) {
      // data is given by columns

      // the data is already in the good format
      this.data = data;

      // index
      if (!indexCol) {
        // create a new column named "index"
        this.data.index = _.range(Object.values(data)[0].length);
        this.indexColLabel = 'index';
        this.index = generateIndex(data[this.indexColLabel]);
      } else {
        this.indexColLabel = indexCol || Object.keys(data)[0];
        this.index = index || generateIndex(data[this.indexColLabel]);
      }
    } else {
      throw new Error('Invalid format');
    }
  }

  private getClonedData() {
    const clonedData: InternalData = {};
    Object.keys(this.data).forEach((col) => {
      clonedData[col] = [...this.data[col]];
    });
    return clonedData;
  }

  clone() {
    const clonedData = this.getClonedData();
    return new DataTable(clonedData, this.indexColLabel, null, { ...this.index });
  }

  labelDataSplit(clone = false) {
    const { [this.indexColLabel]: labels, ...data } = clone ? this.getClonedData() : this.data;
    return { labels, data };
  }

  private getColNames(includeIndex = false) {
    const names = includeIndex ? [this.indexColLabel] : [];
    Object.keys(this.data).forEach((col) => {
      if (col !== this.indexColLabel) {
        names.push(col);
      }
    });
    return names;
  }

  getLabelColumn() {
    return this.data[this.indexColLabel] ?? [];
  }

  /**
   * Column is a list of all values for a specific labels
   * for instance all the values for a specific label for all days
   */
  getColumn(colLabel: ColLabel) {
    return this.data[colLabel];
  }

  getValue(id: ColValue, colLabel: string) {
    const pos = this.index[id ?? ''];
    if (pos === undefined) {
      return undefined;
    }
    return this.getColValueAtRow(pos, colLabel);
  }

  private getColValueAtRow(pos: number, colLabel: string) {
    return this.data[colLabel][pos];
  }

  /**
   * Creates a new DataTable by adding the first non-index column of another DataTable
   * @param {DataTable} other The DataTable to join
   * @return {DataTable} The joint DataTable
   */
  joinFirst(other: DataTable) {
    const indexValues = this.data[this.indexColLabel];
    const otherNames = other.getColNames();
    const colToCopy = otherNames[0];
    const newCol = indexValues.map((idxVal) => other.getValue(idxVal, colToCopy));
    return new DataTable({ ...this.data, [colToCopy]: newCol }, this.indexColLabel, null, this.index);
  }

  /**
   * Creates a new DataTable by adding the columns of another DataTable. The join is made on the index.
   * @param {DataTable} other The DataTable to join
   * @return {DataTable} The joint DataTable
   */
  join(other: DataTable) {
    const indexValues = this.data[this.indexColLabel];
    const otherNames = other.getColNames();
    const dataToJoin: InternalData = {};
    otherNames.forEach((otherLabel) => {
      dataToJoin[otherLabel] = indexValues.map((id) => other.getValue(id, otherLabel));
    });
    return new DataTable({ ...this.data, ...dataToJoin }, this.indexColLabel, null, this.index);
  }

  /**
   * Creates a new DataTable by applying an operation on some columns, with values from another DataTable as second argument
   * @param {DataTable} other The other DataTable
   * @param {string[]} colLabels The names of the columns where the operation will be applied
   * @param {string[]} otherColLabels The names of the columns of the other DataFrame
   * @param {string[]} newLabels Labels to rename the columns where the operation is applied
   * @param {(num1: number, num2: number) => number} func The function describing the operation
   */
  private applyOperation(
    other: DataTable,
    colLabels: string[],
    otherColLabels: string[],
    newLabels: string[],
    func: (val1: number, val2: number) => number
  ) {
    const newData: InternalData = {};
    const _cols = colLabels.includes('all') ? this.getColNames() : colLabels;
    const _otherCols =
      otherColLabels.length < _cols.length
        ? otherColLabels.concat(Array(_cols.length - otherColLabels.length).fill(last(otherColLabels)))
        : otherColLabels;
    _cols.forEach((col, idx) => {
      const otherCol = other.getColumn(_otherCols[idx]);
      const key = newLabels ? newLabels[idx] : col;
      newData[key] = this.data[col].map((val, pos) => {
        if (!_.isNumber(val)) {
          return 0;
        }
        const otherVal = otherCol[pos];
        if (!_.isNumber(otherVal)) {
          return 0;
        }
        return func(val, otherVal);
      });
    });
    return new DataTable({ ...this.data, ...newData }, this.indexColLabel, null, this.index);
  }

  /**
   * Creates a new DataTable where some columns have been divided (row by row) by the values of some columns of another DataTable
   * @param {DataTable} other The other DataTable
   * @param {string[]} colsToDivide The names of the columns which will be divided
   * @param {string[]} otherCols The names of the columns of the other DataTable to divide by
   * @param {string[]} newLabels Labels to rename the columns that have been divided
   */
  divide(other: DataTable, colsToDivide: string[], otherCols: string[], newLabels: string[]) {
    return this.applyOperation(other, colsToDivide, otherCols, newLabels, (x, y) => x / y);
  }

  /**
   * Creates a new DataTable where some columns have been added (row by row) with the values of some columns of another DataTable
   * @param {DataTable} other The other DataTable
   * @param {string[]} colsToAdd The names of the columns which will be divided
   * @param {string[]} otherCols The names of the columns of the other DataTable to divide by
   * @param {string[]} newLabels Labels to rename the columns that have been divided
   */
  add(other: DataTable, colsToAdd: string[], otherCols: string[], newLabels: string[]) {
    return this.applyOperation(other, colsToAdd, otherCols, newLabels, (x, y) => x + y);
  }

  /**
   * Returns a new DataTable where the index is `baseColumn` and the only column is labelled `label`
   * and the values are the sum of the columns listed in `columnsToSum`
   * @param {string} baseColLabel The column to use as an index for the new DataTable
   * @param {string[]} columnsToSum The list of columns to sum
   * @param {string} label The name of the new column
   */
  sumColumns(baseColLabel: ColLabel, columnsToSum: ColLabel[], label: ColLabel) {
    const sumColumn: number[] = [];
    const baseColValues = this.data[baseColLabel];
    baseColValues.forEach((val, idx) => {
      let sum = 0;
      columnsToSum.forEach((col) => {
        const value = this.getColValueAtRow(idx, col);
        if (_.isNumber(value)) {
          sum += value;
        }
      });
      sumColumn.push(sum);
    });
    const data = {
      [baseColLabel]: baseColValues,
      [label || 'sum']: sumColumn,
    };
    return new DataTable(data, baseColLabel);
  }

  private sortByPattern(list: string[], pattern: string): string[] {
    // we sort the index values (day_0, day_1, day_2, ... , day_10)
    return list.sort((id1, id2) => {
      if (id1.length < id2.length) {
        return -1;
      }
      if (id1.length > id2.length) {
        return 1;
      }
      return parseInt(id1.replace(pattern, '')) - parseInt(id2.replace(pattern, ''));
    });
  }

  /**
   * Creates a pivot table. Works only if the index of the DataTable is a list of dates
   * @param {string} pivotColumn The column to pivot the data: each different value of this column will generate a new column
   * @param {string} dataColumn The column where the data is taken to fill the pivot table
   * @param {boolean} fillZero If `true`, entries will be generated in the index in order to make sure that all the dates are there
   */
  pivot(pivotColumn: ColLabel, dataColumn: ColLabel, newIndexCol: ColLabel | null = null, fillZero = true) {
    const names = this.getColNames();
    const _pivotColumn = pivotColumn || names[0];
    const _dataColumn = dataColumn || names[1];

    // Compute the columns that will be added
    const columns = extractUnique(this.data[_pivotColumn]);

    // Create the index
    let indexValues;
    let newIndex;
    if (newIndexCol) {
      indexValues = extractUnique(this.data[newIndexCol]);
      if (fillZero && !moment(indexValues[0]).isValid()) {
        fillZero = false;
      }
      if (indexValues[0].startsWith('day_')) {
        indexValues = this.sortByPattern(indexValues, 'day_');
      }
      newIndex = fillZero ? generateDateRangeIndexColumn(indexValues[0], last(indexValues)) : indexValues;
    } else {
      indexValues = this.data[this.indexColLabel];
      newIndex = fillZero ? generateDateRangeIndexColumn(indexValues[0], last(indexValues)) : indexValues;
    }
    const indexMapping = generateIndex(newIndex);

    // Create the empty table
    const pivotTable: InternalData = {};
    columns.forEach((col) => {
      pivotTable[col] = Array(newIndex.length).fill(0);
    });

    // Fill the table
    if (newIndexCol) {
      this.data[this.indexColLabel].forEach((oldId) => {
        const newIndexValue = this.getValue(oldId, newIndexCol);
        // @ts-ignore
        pivotTable[this.getValue(oldId, _pivotColumn)][indexMapping[newIndexValue]] += this.getValue(
          oldId,
          _dataColumn
        );
      });
    } else {
      newIndex.forEach((id, pos) => {
        // @ts-ignore
        if (this.index[id] !== undefined) {
          // @ts-ignore
          pivotTable[this.getValue(id, _pivotColumn)][pos] = this.getValue(id, _dataColumn);
        }
      });
    }

    return new DataTable({ index: newIndex, ...pivotTable }, 'index');
  }

  transpose(pivotColumn: ColLabel, dataColumn: ColLabel) {
    const names = this.getColNames();
    const _pivotColumn = pivotColumn || names[0];
    const _dataColumn = dataColumn || names[1];

    // Compute the columns that will be added
    const columns = this.getColumn(_pivotColumn);

    const newIndex = [0]; // TODO??

    // Create the empty table
    const pivotTable: InternalData = {};
    columns.forEach((col, pos) => {
      if (col) {
        pivotTable[col] = [this.getValue(pos, _dataColumn)];
      }
    });

    return new DataTable({ index: newIndex, ...pivotTable }, 'index');
  }

  rangeCount(
    rangeColumn: ColLabel,
    pivotColumn: ColLabel,
    rangeNumber: number,
    filter: { type: 'median'; align?: 'right' | 'left'; threshold: number } | null
  ) {
    const filterFunction = (
      array: ColValue[],
      filterFn: { type: 'median'; align?: 'right' | 'left'; threshold: number }
    ) => {
      let sorted;
      switch (filterFn.type) {
        case 'median':
          // @ts-ignore
          sorted = array.sort((a, b) => a - b);
          switch (filterFn.align) {
            case 'right':
              return array.slice(Math.floor(sorted.length * filterFn.threshold), sorted.length);
            case 'left':
              return array.slice(0, Math.floor(sorted.length * (1 - filterFn.threshold)));
            default:
              return array.slice(
                Math.floor((sorted.length * filterFn.threshold) / 2),
                Math.floor(sorted.length * (1 - filterFn.threshold / 2))
              );
          }
        default:
          return array;
      }
    };
    const names = this.getColNames();
    const _rangeColumn = rangeColumn || names[0];

    // Compute the columns that will be added
    const columns = pivotColumn ? extractUnique(this.data[pivotColumn]) : ['count'];
    const ranges = generateRanges(
      filter ? filterFunction(this.data[_rangeColumn], filter) : this.data[_rangeColumn],
      rangeNumber
    );

    // Create the index
    const newIndex = ranges.map((bounds) => `${bounds[0]}-${bounds[1]}`);

    // Create the empty table
    const pivotTable: InternalData = {};
    columns.forEach((col) => {
      pivotTable[col] = Array(newIndex.length).fill(0);
    });

    // Fill the table
    ranges.forEach((bounds, i) => {
      columns.forEach((colName) => {
        let inBoundCount;
        if (pivotColumn) {
          if (i === ranges.length - 1) {
            inBoundCount = this.data[_rangeColumn].filter(
              (value, j) => !!value && value <= bounds[1] && value >= bounds[0] && this.data[pivotColumn][j] === colName
            ).length;
          } else {
            inBoundCount = this.data[_rangeColumn].filter(
              (value, j) => !!value && value < bounds[1] && value >= bounds[0] && this.data[pivotColumn][j] === colName
            ).length;
          }
        } else if (i === ranges.length - 1) {
          inBoundCount = this.data[_rangeColumn].filter(
            (value) => !!value && value <= bounds[1] && value >= bounds[0]
          ).length;
        } else {
          inBoundCount = this.data[_rangeColumn].filter(
            (value) => !!value && value < bounds[1] && value >= bounds[0]
          ).length;
        }
        pivotTable[colName][i] = inBoundCount;
      });
    });
    return new DataTable({ index: newIndex, ...pivotTable }, 'index');
  }

  /**
   * Aggregates the data by summing by week or month and returns a new DataTable. Works only if the index of the DataTable is a list of dates
   * @param {"week" | "month"} type Aggregate the data by week or month
   * @param {string[]} columns The columns to aggregate
   */
  aggregate(type: 'week' | 'month', columns: ColLabel[]) {
    // Prepare the aggregated data
    const agg: InternalData = {};
    columns.forEach((col) => {
      agg[col] = [];
    });
    const newIndex: ColValue[] = [];

    const indexValues = this.data[this.indexColLabel];
    let currentDate = moment(indexValues[0]);
    indexValues.forEach((id) => {
      if (moment(id).isBefore(currentDate)) {
        columns.forEach((col) => {
          // @ts-ignore
          agg[col][-1] += this.getValue(id, col);
        });
      } else {
        newIndex.push(moment(id).format('YYYY-MM-DD'));
        columns.forEach((col) => {
          agg[col].push(this.getValue(id, col));
        });
        currentDate = currentDate.add(1, type);
      }
    });

    return new DataTable({ [this.indexColLabel]: newIndex, ...agg }, this.indexColLabel);
  }

  cumulate() {
    const cumulated: InternalData = {};
    Object.keys(this.data).forEach((col) => {
      if (col !== this.indexColLabel) {
        cumulated[col] = [];
        let sum = 0;
        this.data[col].forEach((val) => {
          if (_.isNumber(val)) {
            sum += val;
          }
          cumulated[col].push(sum);
        });
      }
    });

    return new DataTable(
      { [this.indexColLabel]: this.data[this.indexColLabel], ...cumulated },
      this.indexColLabel,
      null,
      this.index
    );
  }

  /**
   * Returns a new DataTable by concatenation: all the DataTables must have the same columns (number and name)
   * @param  {...DataTable} others The DataTables to concatenate
   */
  concat(...others: DataTable[]) {
    const resultData: InternalData = {};
    Object.keys(this.data).forEach((col) => {
      resultData[col] = this.data[col].concat(...others.map((dataTable) => dataTable.getColumn(col)));
    });
    return new DataTable(resultData, this.indexColLabel);
  }

  /**
   * Creates a new DataTable, with rows sorted according to `sortColumns`
   * @param {string[]} sortColumns The names of the columns which will be used to sort
   */
  sort(sortColumns: string[]) {
    const sortCols = sortColumns || [this.indexColLabel];
    const newIndex = [...this.data[this.indexColLabel]];
    const sortPolicy = (id1: ColValue, id2: ColValue) => {
      for (const col of sortCols) {
        // @ts-ignore
        const diff = this.getValue(id1, col) - this.getValue(id2, col);
        if (diff !== 0) {
          return diff;
        }
      }
      return 0;
    };
    newIndex.sort(sortPolicy);
    const newData: InternalData = {};
    this.getColNames().forEach((col) => {
      newData[col] = newIndex.map((id) => this.getValue(id, col));
    });
    newData[this.indexColLabel] = newIndex;
    return new DataTable(newData, this.indexColLabel);
  }

  /**
   * Filters the DataTable according to filter functions
   * @param {{ [x: string]: (val: number) => boolean }} filters Object containing the filter functions. The keys have to be columns of the DataTable
   */
  private filterWithFunctions(filters: Record<string, (val: number) => boolean>) {
    const newData: InternalData = {};
    const newIndexValues: ColValue[] = [];
    this.data[this.indexColLabel].forEach((id) => {
      // @ts-ignore
      const check = Object.keys(filters).every((col) => filters[col](this.getValue(id, col)));
      if (check) {
        newIndexValues.push(id);
      }
    });
    this.getColNames().forEach((col) => {
      newData[col] = newIndexValues.map((id) => this.getValue(id, col));
    });
    newData[this.indexColLabel] = newIndexValues;
    return new DataTable(newData, this.indexColLabel);
  }

  /**
   * Returns a DataTable only containing the rows where the `column` field is equal to `value`
   * @param {string} column The column name to filter on
   * @param {number | string} value The value to keep
   */
  filter(column: string, value: ColValue) {
    return this.filterWithFunctions({
      [column]: (val) => val === value,
    });
  }

  /**
   * Keeps only the rows where the date is less than one hour after or before `timestamp`. The date column has to be the index column
   * @param {number} timestamp Timestamp in milliseconds
   */
  filterAroundTime(timestamp: number) {
    return this.filterWithFunctions({
      [this.indexColLabel]: (val) =>
        new Date(val).getTime() <= timestamp + 3600 * 1000 && new Date(val).getTime() >= timestamp - 3600 * 1000,
    });
  }

  /**
   * Returns a DataTable where the rows with the index field equal to zero have been removed
   */
  cleanZeros() {
    return this.filterWithFunctions({
      [this.indexColLabel]: (val) => val !== 0,
    });
  }

  /**
   * Returns a DataTable where columns in `columns` have been added and filled with zeros if not already present
   * @param {string[]} columns List of column names
   */
  completeWithZeros(columns: string[]) {
    const data = this.getClonedData();
    const { length } = this.data[this.indexColLabel];
    columns.forEach((col) => {
      if (!data[col]) {
        data[col] = Array(length).fill(0);
      }
    });
    return new DataTable(data, this.indexColLabel, null, { ...this.index });
  }

  /**
   * Filters
   * @param {string[] | Date[]} days The days to keep
   * @param {boolean} keepLast Whether to keep the last day present in the DataTable or not
   */
  filterDays(days: string[] | Date[], keepLast = false) {
    const daysToKeep = keepLast ? [...days, this.data[this.indexColLabel][-1]] : days;
    return this.filterWithFunctions({
      [this.indexColLabel]: (val) => daysToKeep.includes(val),
    });
  }

  /**
   * Creates a DataTable where all the values of the index column have been converted to strings.
   * @param {string} newIndexLabel The new name of the index column (optional)
   */
  indexToString(newIndexLabel: string) {
    const newName = newIndexLabel || this.indexColLabel;
    const newIndex = this.data[this.indexColLabel].map((val) => String(val));
    const data = this.getClonedData();
    delete data[this.indexColLabel];
    data[newName] = newIndex;
    return new DataTable(data, newName);
  }

  colorPartialData(column: string, endDate: string) {
    const newData: InternalData = {
      [`${column}_complete`]: [],
      [`${column}_partial`]: [],
    };
    const data = this.getClonedData();
    const end = moment(endDate);
    data[this.indexColLabel].forEach((id) => {
      const value = this.getValue(id, column);
      if (moment(id).isBefore(end)) {
        newData[`${column}_complete`].push(value);
        newData[`${column}_partial`].push(0);
      } else {
        newData[`${column}_complete`].push(0);
        newData[`${column}_partial`].push(value);
      }
    });
    delete data[column];
    return new DataTable({ ...data, ...newData }, this.indexColLabel, null, this.index);
  }

  normalize() {
    const { labels, data } = this.labelDataSplit(true);
    const cols = Object.keys(data);
    labels.forEach((id, idx) => {
      // @ts-ignore
      const sum = cols.reduce((acc, col) => acc + this.getValue(id, col), 0);
      cols.forEach((col) => {
        // @ts-ignore
        data[col][idx] /= sum;
      });
    });
    data[this.indexColLabel] = labels;
    return new DataTable(data, this.indexColLabel, null, { ...this.index });
  }

  /**
   *
   * @param {DataTable} newDataTable The more recent version of the DataTable to compute the variation
   */
  variation(newDataTable: DataTable) {
    const oldValue = this.getColValueAtRow(0, this.indexColLabel);
    const value = newDataTable.getColValueAtRow(0, this.indexColLabel);
    return {
      oldValue,
      value,
      // @ts-ignore
      variation: (value - oldValue) / oldValue,
    };
  }

  percentage() {
    const { labels, data } = this.labelDataSplit(true);
    const cols = Object.keys(data);
    labels.forEach((l, idx) => {
      cols.forEach((col) => {
        // @ts-ignore
        this.data[col][idx] = (this.data[col][idx] * 100).toFixed(2);
      });
    });
    data[this.indexColLabel] = labels;
    return new DataTable(data, this.indexColLabel, null, { ...this.index });
  }

  isolate(columnToIsolate: string, newName: string) {
    return new DataTable({ [newName || columnToIsolate]: [...this.data[columnToIsolate]] }, newName || columnToIsolate);
  }

  /**
   * @param {string[]} labels
   */
  rotate(labels: string[]) {
    const rotated: InternalData = {};
    labels.forEach((label) => {
      rotated[label] = [];
    });
    const rotatedKeys = Object.keys(rotated);
    Object.keys(this.data).forEach((_col) => {
      // parse unit
      let col = _col;
      let unit = '';
      const parts = col.match(/(.*)_unit_(.*)/);
      if (parts) {
        col = parts[1];
        unit = parts[2];
        if (unit === 'pct') unit = '%';
        if (unit === 'cts') unit = '\u00A2';
      }

      // fill a line of the rotated DataTable with the values of the column of the old DataTable
      rotatedKeys.forEach((newCol, idx) => {
        if (idx === 0) {
          rotated[newCol].push(col);
        } else {
          rotated[newCol].push(this.data[_col][idx - 1] === -1 ? '-' : this.data[_col][idx - 1] + unit);
        }
      });
    });
    return new DataTable(rotated, labels[0]);
  }

  /**
   * Returns a DataTable where the specified columns have been casted into the specified type
   * @param {string[]} columnsToCast The names of the columns to cast
   * @param {'number' | 'string'} type The type to cast into
   */
  cast(columnsToCast: string[], type: 'number' | 'string') {
    const newData: InternalData = {};
    const data = this.getClonedData();

    let mapFunc = (val: any) => val;
    switch (type) {
      case 'number':
        mapFunc = (val) => Number(val);
        break;
      case 'string':
        mapFunc = (val) => String(val);
        break;
      default:
        break;
    }

    columnsToCast.forEach((col) => {
      if (data[col]) {
        newData[col] = data[col].map(mapFunc);
      }
    });

    return new DataTable({ ...data, ...newData }, this.indexColLabel);
  }

  /**
   * Creates a new DataTable by extracting the specified columns
   * @param {string[]} columns The columns to extract
   * @param {string[]} newLabels Labels to rename the columns (optional)
   */
  break(columns: string[], newLabels: string[]) {
    const newData: InternalData = {};
    columns.forEach((col, idxCol) => {
      newData[newLabels ? newLabels[idxCol] : col] = [...this.data[col]];
    });

    let indexCol = '';
    if (columns.includes(this.indexColLabel)) {
      if (newLabels) {
        indexCol = newLabels[columns.indexOf(this.indexColLabel)];
      } else {
        indexCol = this.indexColLabel;
      }
    } else if (newLabels) {
      indexCol = newLabels[0];
    } else {
      indexCol = columns[0];
    }

    return new DataTable(newData, indexCol);
  }

  exportAsCSV(columnDelimiter = ',', lineDelimiter = '\n') {
    const keys = this.getColNames(true);
    let result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    _.range(this.data[keys[0]].length).forEach((cnt) => {
      keys.forEach((key, i) => {
        if (i > 0) result += columnDelimiter;
        result += this.data[key][cnt];
      });
      result += lineDelimiter;
    });
    return result;
  }

  /**
   * Returns a DataTable where the index is a RangeDateIndex,
   * and rows full of zeros have been added to fill the missing data
   */
  private fillZeros() {
    const from = this.data[this.indexColLabel][0];
    const to = this.data[this.indexColLabel][-1];

    // create date range index
    const newIndex = generateDateRangeIndexColumn(from, to);

    // initialize new data
    const newData: InternalData = {};
    const names = this.getColNames();
    names.forEach((name) => {
      newData[name] = [];
    });

    // fill new data
    newIndex.forEach((date) => {
      names.forEach((name) => {
        const val = this.index[date] ? this.getValue(date, name) : 0;
        newData[name].push(val);
      });
    });
    newData[this.indexColLabel] = newIndex;

    // create datatable
    return new DataTable(newData, this.indexColLabel);
  }
}

export default DataTable;
