import moment from 'moment';
import React from 'react';
import DateSlicer from './components/DateSlicer';
import RangeSlicer from './components/RangeSlicer';
import TextSlicer from './components/TextSlicer';
// Slicers
import SlicerContainer from './SlicerContainer';
// PropTypes
import { defaultSlicingPropTypes } from './slicerPropTypes';

const SPPartySlicing = ({ defaultStartDate, defaultEndDate, defaultUserLevel, onRefresh }) => (
  <SlicerContainer onRefresh={onRefresh}>
    <DateSlicer name="DateSlicer" id="startDate" title="Start Date" defaultValue={defaultStartDate} />
    <DateSlicer name="DateSlicer" id="endDate" title="End Date" defaultValue={defaultEndDate} />
    <TextSlicer id='app_version' title="App Version" name='TextSlicer' />
  </SlicerContainer>
);

SPPartySlicing.propTypes = defaultSlicingPropTypes;

SPPartySlicing.defaultProps = {
  defaultStartDate: moment().subtract(15, 'days').format('YYYY-MM-DD'),
  defaultEndDate: moment().subtract(2, 'day').format('YYYY-MM-DD'),
};

export default SPPartySlicing;