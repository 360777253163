/* eslint-disable max-len */
import { GetMonthlyPassesQuery, GetTasksQuery } from '../../../../../__gqltypes__';

type MonthlyPassMissions = NonNullable<GetMonthlyPassesQuery['liveOps']['monthlyPasses']['list'][0]['missions']>;
type DailyMissionsTaskParams = MonthlyPassMissions['dailyMissions'][0]['tasks'][0]['params'][0];
type ExtraMissionsTaskParams = MonthlyPassMissions['extraMissions'][0]['tasks'][0]['params'][0];
type MonthlyMissionsTaskParams = MonthlyPassMissions['monthlyMissions'][0]['tasks'][0]['params'][0];
type MissionParamContentCategory = Extract<DailyMissionsTaskParams, { __typename: 'MissionParamContentCategory' }>;
type MissionParamContentPack = Extract<DailyMissionsTaskParams, { __typename: 'MissionParamContentPack' }>;
type MissionParamFloat = Extract<DailyMissionsTaskParams, { __typename: 'MissionParamFloat' }>;
type MissionParamInt = Extract<DailyMissionsTaskParams, { __typename: 'MissionParamInt' }>;
type MissionParamHour24 = Extract<DailyMissionsTaskParams, { __typename: 'MissionParamHour24' }>;
type MissionParamSong = Extract<DailyMissionsTaskParams, { __typename: 'MissionParamSong' }>;
type MissionParamString = Extract<DailyMissionsTaskParams, { __typename: 'MissionParamString' }>;
type MissionTaskParamTypename = GetTasksQuery['liveOps']['missionTasks'][0]['params'][0]['__typename'];

export const ParameterTypes: Record<MissionTaskParamTypename, MissionTaskParamTypename> = {
  MissionParamInt: 'MissionParamInt',
  MissionParamHour24: 'MissionParamHour24',
  MissionParamString: 'MissionParamString',
  MissionParamFloat: 'MissionParamFloat',
  MissionParamContentCategory: 'MissionParamContentCategory',
  MissionParamSong: 'MissionParamSong',
  MissionParamContentPack: 'MissionParamContentPack',
  MissionParamType: 'MissionParamType',
};

/**
 * A file containing constants and helper functions to deal with backend task
 */
export function getTypeAndValueFromTaskParameter(
  parameter: DailyMissionsTaskParams | MonthlyMissionsTaskParams | ExtraMissionsTaskParams
): {
  type: keyof typeof ParameterTypes;
  value: number | string;
} {
  const type = parameter.__typename;
  let value: string | number;
  switch (type) {
    case ParameterTypes.MissionParamContentPack:
      value = (parameter as MissionParamContentPack).contentPack.id;
      break;
    case ParameterTypes.MissionParamContentCategory:
      value = (parameter as MissionParamContentCategory).category.id;
      break;
    case ParameterTypes.MissionParamSong:
      value = (parameter as MissionParamSong).variantIds.join(',\n');
      break;
    case ParameterTypes.MissionParamInt:
      value = (parameter as MissionParamInt).intValue;
      break;
    case ParameterTypes.MissionParamHour24:
      value = (parameter as MissionParamHour24).intValue;
      break;
    case ParameterTypes.MissionParamFloat:
      value = (parameter as MissionParamFloat).floatValue;
      break;
    case ParameterTypes.MissionParamString:
      value = (parameter as MissionParamString).stringValue;
      break;
    default:
      throw new Error('Unhandled ParameterType');
  }
  return { type, value };
}
