import React from 'react';
import DashboardContainer from '../../../../../../utils/dashboards/components/DashboardContainer';
import { SimpleDateSlicing } from '../../../../../../utils/dashboards/components/slicers';
// import { DefaultSlicing } from '../../../../../../utils/dashboards/components/slicers';
import { SectionDisplay } from '../../../../../../utils/dashboards/components/widgets';
import LineChart from '../../../../../../utils/dashboards/components/widgets/charts/LineChart';
// import PieChart from '../../../../../../utils/dashboards/components/widgets/charts/PieChart';
// import BarChart from '../../../../../../utils/dashboards/components/widgets/charts/BarChart';
import BaseTable from '../../../../../../utils/dashboards/components/widgets/tables/BaseTable';
import WidgetContainer from '../../../../../../utils/dashboards/components/widgets/WidgetContainer';
import Processing from '../../../../../../utils/dashboards/models/Processing';

const queries = {
  playlistsInfo: { fillZeroes: true },
};

const Playlists = () => (
  <>
    <DashboardContainer queries={queries} id="playlists" title="Playlists">
      <SimpleDateSlicing id="SimpleDateSlicing" />
      <WidgetContainer id="WidgetContainer">
        <BaseTable id="playlistsInfo" title="Playlists Statistics" />
      </WidgetContainer>
    </DashboardContainer>
  </>
);

export default Playlists;
