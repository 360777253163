import moment from 'moment';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import { handleDowload, idToRoute, isValidID } from '../models';

const styles = {
  cardWithHeight: {
    minHeight: '4em',
    maxHeight: '12em',
    overflowY: 'scroll',
  },
  buttonsGroup: {
    marginRight: '1em',
  },
};

export const BasicDisplay = ({ title, value, match, showModal }) => {
  let display = null;
  if (title.match(/(^id$|.*(_id|_ID|_Id)$)/g) && isValidID(value)) {
    display = (
      <Link
        className="text-decoration-none p-0 m-0 text-left"
        to={`${match.path.split('/').slice(0, -2).join('/')}/${idToRoute(value)}/${value}`}
      >
        {value}
      </Link>
    );
  } else if (title.match(/(Date|date)/g)) {
    display = (
      <h6 className="h6 mb-0 text-gray-800">{moment(parseInt(value, 10)).format('YYYY-MM-DD HH:mm:ss (Z)')}</h6>
    );
  } else if (title === 'app_memory_usage') {
    display = <h6 className="h6 mb-0 text-gray-800">{Math.round(value * 0.0001) / 100} MB</h6>;
  } else if (title === 'response_data') {
    display = (
      <div>
        <Button style={styles.buttonsGroup} onClick={() => showModal(value)}>
          Show Data
        </Button>
        <Button onClick={() => handleDowload(value, 'response_data.json')}>Download JSON</Button>
      </div>
    );
  }
  return (
    <Col className="col-xl-6 mb-2 p-0 pr-2">
      <Card className="card shadow" style={styles.cardWithHeight}>
        <Card.Body className="px-3 py-2">
          <Row className="no-gutters align-items-center">
            <Col>
              <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">{title}</div>
              {display || <h6 className="h6 mb-0 text-gray-800">{value}</h6>}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
};
