import { ApolloCache, useMutation } from '@apollo/client';
import { FormEvent, useState } from 'react';
import { Button, Col, Form, Modal, ModalBody, ModalTitle, Row } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import {
  CreateBackendDeploymentImportantEventMutation,
  CreateBackendDeploymentImportantEventMutationVariables,
  CreateCustomImportantEventMutation,
  CreateCustomImportantEventMutationVariables,
  CreateFrontendDeploymentImportantEventMutation,
  CreateFrontendDeploymentImportantEventMutationVariables,
  ImportantEvent,
  ImportantEventType,
  UpdateImportantEventMutation,
  UpdateImportantEventMutationVariables,
} from '../../../../../../__gqltypes__';
import { ChoiceButtonGroup } from '../../../../../../utils/components/inputs/ChoiceButtonGroup';
import DatetimeInput from '../../../../../../utils/components/inputs/DatetimeInput';
import NumberInput from '../../../../../../utils/components/inputs/NumberInput';
import { useChoiceButtonGroupProps, useNumberInputProps } from '../../Monitoring/hooks/inputHooks';
import {
  CREATE_BACKEND_DEPLOYMENT_IMPORTANT_EVENT,
  CREATE_CUSTOM_IMPORTANT_EVENT,
  CREATE_FRONTEND_DEPLOYMENT_IMPORTANT_EVENT,
  IMPORTANT_EVENT_FRAGMENT,
  UPDATE_IMPORTANT_EVENT,
} from '../graphql';
import { eventTypeLabels } from '../utils';

const platformChoices = {
  iOS: { label: 'iOS' },
  Android: { label: 'Android' },
};

interface ImportantEventFormProps {
  OpenDialogButton: React.FC<{ onClick: () => void }>;
  eventKey?: string;
  initialValues?: {
    type?: ImportantEventType;
    major?: number;
    minor?: number;
    patch?: number;
    platform?: 'iOS' | 'Android';
    datetime?: string;
    description?: string;
    notes?: string;
    important?: boolean;
  };
}

export default function ImportantEventForm({ initialValues, eventKey, OpenDialogButton }: ImportantEventFormProps) {
  const [open, setOpen] = useState(false);
  const isUpdate = eventKey !== undefined && !!initialValues?.type !== undefined;

  // Callback to add the new event
  const onCreateCompleted = (cache: ApolloCache<unknown>, createdImportantEvent?: ImportantEvent) => {
    if (!createdImportantEvent) return;
    cache.modify({
      fields: {
        getImportantEvents(existingImportantEventRefs = []) {
          const newCommentRef = cache.writeFragment({
            data: createdImportantEvent,
            fragment: IMPORTANT_EVENT_FRAGMENT,
          });
          return [...existingImportantEventRefs, newCommentRef];
        },
      },
    });
  };

  // Mutations
  const [createBackendDeployment, { loading: loadingBEDeployment }] = useMutation<
    CreateBackendDeploymentImportantEventMutation,
    CreateBackendDeploymentImportantEventMutationVariables
  >(CREATE_BACKEND_DEPLOYMENT_IMPORTANT_EVENT, {
    onCompleted: () => setOpen(false),
    update(cache, { data }) {
      onCreateCompleted(cache, data?.createBackendDeploymentImportantEvent);
    },
  });
  const [createFrontendDeployment, { loading: loadingFEDeployment }] = useMutation<
    CreateFrontendDeploymentImportantEventMutation,
    CreateFrontendDeploymentImportantEventMutationVariables
  >(CREATE_FRONTEND_DEPLOYMENT_IMPORTANT_EVENT, {
    onCompleted: () => setOpen(false),
    update(cache, { data }) {
      onCreateCompleted(cache, data?.createFrontendDeploymentImportantEvent);
    },
  });
  const [createCustom, { loading: loadingCustom }] = useMutation<
    CreateCustomImportantEventMutation,
    CreateCustomImportantEventMutationVariables
  >(CREATE_CUSTOM_IMPORTANT_EVENT, {
    onCompleted: () => setOpen(false),
    update(cache, { data }) {
      onCreateCompleted(cache, data?.createCustomImportantEvent);
    },
  });
  const [update, { loading: loadingUpdate }] = useMutation<
    UpdateImportantEventMutation,
    UpdateImportantEventMutationVariables
  >(UPDATE_IMPORTANT_EVENT, {
    onCompleted: (data) => {
      setOpen(false);
    },
  });
  const loading = loadingBEDeployment || loadingFEDeployment || loadingCustom || loadingUpdate;

  // Form state
  const typeProps = useChoiceButtonGroupProps<ImportantEventType>(
    eventTypeLabels,
    initialValues?.type || ImportantEventType.CUSTOM
  );
  const platformProps = useChoiceButtonGroupProps(platformChoices, initialValues?.platform || 'Android');
  const majorProps = useNumberInputProps(initialValues?.major || 0);
  const minorProps = useNumberInputProps(initialValues?.minor || 0);
  const patchProps = useNumberInputProps(initialValues?.patch || 0);
  const [description, setDescription] = useState(initialValues?.description || '');
  const [notes, setNotes] = useState(initialValues?.notes || '');
  const [important, setImportant] = useState(initialValues?.important || false);
  const [datetime, setDatetime] = useState<Date | undefined>(
    initialValues?.datetime ? new Date(initialValues.datetime) : new Date()
  );

  // Submitter
  const descriptionRequired = isUpdate || typeProps.choice === ImportantEventType.CUSTOM;
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isUpdate) {
      return update({
        variables: {
          input: {
            type: initialValues?.type as ImportantEventType,
            eventKey,
            description,
            notes,
            datetime,
            important,
          },
        },
      });
    }

    if (typeProps.choice === ImportantEventType.CUSTOM) {
      return createCustom({
        variables: {
          input: {
            datetime,
            description,
            important,
            notes,
          },
        },
      });
    }

    const deploymentProps = {
      datetime,
      major: majorProps.intValue,
      minor: minorProps.intValue,
      patch: patchProps.intValue,
      important,
      notes,
    };
    if (typeProps.choice === ImportantEventType.BACKENDDEPLOYMENT) {
      return createBackendDeployment({ variables: { input: deploymentProps } });
    }

    return createFrontendDeployment({ variables: { input: { ...deploymentProps, platform: platformProps.choice } } });
  };

  return (
    <>
      <OpenDialogButton onClick={() => setOpen(true)} />
      <Modal show={open} onHide={() => setOpen(false)}>
        <ModalHeader>
          <ModalTitle>New important event</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <Form className="px-3 pb-4" onSubmit={handleSubmit}>
            {isUpdate && (
              <Form.Group className="mt-3">
                <Form.Label>Key</Form.Label>
                <Form.Control type="string" value={eventKey} disabled />
              </Form.Group>
            )}

            <ChoiceButtonGroup title="Event type" {...typeProps} fill disabled={isUpdate} />

            {typeProps.choice === ImportantEventType.FRONTENDDEPLOYMENT && (
              <ChoiceButtonGroup title="Platform" {...platformProps} fill />
            )}

            {!isUpdate &&
              (typeProps.choice === ImportantEventType.BACKENDDEPLOYMENT ||
                typeProps.choice === ImportantEventType.FRONTENDDEPLOYMENT) && (
                <Row>
                  <Col>
                    <NumberInput title="Major" {...majorProps} />
                  </Col>
                  <Col>
                    <NumberInput title="Minor" {...minorProps} />
                  </Col>
                  <Col>
                    <NumberInput title="Patch" {...patchProps} />
                  </Col>
                </Row>
              )}

            {(isUpdate || typeProps.choice === ImportantEventType.CUSTOM) && (
              <Form.Group className="mt-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form.Group>
            )}

            <Form.Group className="mt-3">
              <Form.Label>Notes</Form.Label>
              <Form.Control as="textarea" rows={3} value={notes} onChange={(e) => setNotes(e.target.value)} />
            </Form.Group>

            <DatetimeInput datetime={datetime} setDatetime={setDatetime} />

            <Form.Check
              type="switch"
              id="important"
              label="Mark as especially important"
              className="mt-1 mb-3"
              checked={important}
              onChange={(e) => setImportant(e.target.checked)}
            />

            <Button
              type="submit"
              disabled={loading || datetime === undefined || (descriptionRequired && description === '')}
            >
              {loading ? 'Loading...' : 'Save'}
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
}
