import { Badge, Button, Form, Image } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import {
  BatchContentModerationErrorContentItemsQuery,
  ModeratedContentItemState,
  ModerationContentItemsQuery,
} from '../../../../../../__gqltypes__';
import ExplicitIcon from '../../../components/ExplicitIcon';
import PlayButton from '../../../components/PlayButton';

type ErrorReportedModeratedContentItem =
  BatchContentModerationErrorContentItemsQuery['batchContentModeration']['errorReportedContentItems']['list'][0];
type ModeratedContentItemCurationSong = Extract<
  NonNullable<ModerationContentItemsQuery['moderationContentItems']>['list'][0]['contentItem'],
  { __typename: 'CurationSong' }
>;
type Props = {
  item: ErrorReportedModeratedContentItem & {
    curationContentPack?: { id: string; originId: string; name: string } | null;
  };
  onStatusUpdate: (id: string, type: ModeratedContentItemState) => void;
  moderator?: string;
  onShowLyrics: (title: string, artist: string) => void;
  onShowBigPicture: (picture: string) => void;
};

function AdminContentModerationContentItem({ item, onStatusUpdate, moderator, onShowLyrics, onShowBigPicture }: Props) {
  const song = item.contentItem as ModeratedContentItemCurationSong;
  const curationContentPack = item.curationContentPack;

  return (
    <tr>
      <td>
        <Image width={100} src={song.picture} onClick={() => onShowBigPicture(song.picture)} />
      </td>
      <td>
        {song.id}
        <hr />
        ISRC: {song.isrc}
        <hr />
        Itunes: {song.itunesId}
      </td>

      <td>
        <span className="font-weight-bold">{song.displayTitle}</span>
        <ExplicitIcon explicit={song.isExplicit} />
        <br />
        <span className="text-muted">{song.displayArtist}</span>
        <br />
        {curationContentPack && (
          <>
            <hr />
            <LinkContainer to={`/sp3/playlist/${curationContentPack.id}`}>
              <Button variant="link">{curationContentPack.name}</Button>
            </LinkContainer>
          </>
        )}
      </td>
      <td>
        <PlayButton sample={song.defaultSample} />
        <hr />
        <Button onClick={() => onShowLyrics(song.title, song.artist)}>Lyrics</Button>
      </td>
      <td>
        {!!moderator && <span>{moderator}</span>}
        <hr />
        {!!item.noteType && (
          <Badge pill variant="primary">
            {item.noteType}
          </Badge>
        )}

        <Form.Control
          as="textarea"
          rows={2}
          value={item.note ?? undefined}
          placeholder={item.note ?? undefined}
          disabled={true}
        />
      </td>

      {!!onStatusUpdate &&
        (item.state === ModeratedContentItemState.ERROR && !item.adminState ? (
          <td className="align-middle">
            <Button
              variant="success"
              className="mr-4 mb-2"
              onClick={() => {
                onStatusUpdate(item.id, ModeratedContentItemState.CLEAN);
              }}
            >
              <i className="fas fa-check mr-1" />
              Override as Clean
            </Button>
            <Button
              variant="danger"
              className="mb-2"
              onClick={() => {
                onStatusUpdate(item.id, ModeratedContentItemState.QUESTIONABLE);
              }}
            >
              <i className="fas fa-times-circle mr-1" />
              Override as Questionable
            </Button>
            <br />
            {curationContentPack && (
              <LinkContainer to={`/sp3/playlist/${curationContentPack.id}?songId=${song.id}`}>
                <Button variant="warning">
                  <i className="fas fa-edit  mr-1" />
                  Edit Song
                </Button>
              </LinkContainer>
            )}
          </td>
        ) : (
          <td className="justify-content-center">
            <Badge pill variant={item.adminState === ModeratedContentItemState.QUESTIONABLE ? 'danger' : 'success'}>
              {item.adminState}
            </Badge>
          </td>
        ))}
    </tr>
  );
}

export default AdminContentModerationContentItem;
