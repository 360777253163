import { useCallback, useEffect, useMemo, useState } from 'react';
import { Badge, Button, ButtonGroup, Image, Spinner, Table } from 'react-bootstrap';
import { GetCurationContentPackListQuery } from '../../../../../../__gqltypes__';
import ToggleButtonSelector, { ToggleButtonOption } from '../../../components/ToggleButtonSelector';
import SPPAddPack from './SPPAddPack';

/**
 * List of CurationContentPack used by SPP for one category
 * includes PLAYLIST and ARTIST type
 * - Display explicitness of each contentpack
 * - Display local content
 * You can use the different filter to see what a user finally see at the end
 */

type Props = {
  id?: string;
  contentPacks: NonNullable<GetCurationContentPackListQuery['curationContentPackList']>['curationContentPacks'];
  updatePackIds: (packIds: string[]) => Promise<void>;
};

const SPPContentPackListEditor = ({ id, contentPacks, updatePackIds }: Props) => {
  const [curPacks, setCurPacks] = useState(contentPacks);

  const [filterType, setFilterType] = useState<'PLAYLIST' | 'ARTIST'>('PLAYLIST');
  const [filterAge, setFilterAge] = useState<'KID' | 'ALL'>('KID');
  const [filterLocale, setFilterLocale] = useState<string>('ALL');

  useEffect(() => {
    setCurPacks(contentPacks);
  }, [id, contentPacks]);

  const modified = useMemo(() => {
    return contentPacks.length !== curPacks.length || contentPacks.some((pack, idx) => curPacks[idx].id !== pack.id);
  }, [contentPacks, curPacks]);

  const onAdd = useCallback(
    (pack) => {
      console.log('onAdd', curPacks, pack, curPacks.concat([pack]));
      setCurPacks(curPacks.concat([pack]));
    },
    [curPacks]
  );

  const moveUp = useCallback(
    (cpId) => {
      const newPacks = [...curPacks];
      const idx = newPacks.findIndex((p) => p.id === cpId);
      const [pack] = newPacks.splice(idx, 1);
      if (pack) {
        newPacks.splice(idx - 1, 0, pack);
      }
      setCurPacks(newPacks);
    },
    [curPacks]
  );
  const moveDown = useCallback(
    (cpId) => {
      const newPacks = [...curPacks];
      const idx = newPacks.findIndex((p) => p.id === cpId);
      const [pack] = newPacks.splice(idx, 1);
      if (pack) {
        newPacks.splice(idx + 1, 0, pack);
      }
      setCurPacks(newPacks);
    },
    [curPacks]
  );

  const removeIdx = useCallback(
    (cpId) => {
      const newPacks = [...curPacks];
      const idx = newPacks.findIndex((p) => p.id === cpId);
      newPacks.splice(idx, 1);
      setCurPacks(newPacks);
    },
    [curPacks]
  );

  const [submitting, setSubmitting] = useState(false);
  const save = useCallback(async () => {
    setSubmitting(true);
    try {
      await updatePackIds(curPacks.map((pack) => pack.originId));
    } catch (err) {
      console.error(err);
    }
    setSubmitting(false);
  }, [curPacks, updatePackIds]);

  const filteredContentPacks = useMemo(() => {
    let filtered = curPacks.filter((cp) => cp.type === filterType);
    if (filterAge === 'KID') {
      // TODO
      filtered = filtered.filter((cp) => !cp.adultOnly);
    }
    if (filterLocale !== 'ALL') {
      if (filterType === 'PLAYLIST') {
        // @ts-ignore
        filtered = filtered.map((pack) => {
          return !pack.localeReplacements || pack.localeReplacements.length === 0
            ? pack
            : pack.localeReplacements.find((r) => r.locale === filterLocale) ?? pack;
        });
      } else {
        filtered = filtered.filter((pack) => {
          return pack.locale === filterLocale || pack.locale === 'en-US';
        });
      }
    }
    return filtered;
  }, [curPacks, filterType, filterAge, filterLocale]);

  return (
    <div className="cp-list">
      <hr />
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <h4>Filters</h4>
        {modified ? (
          <Button variant="primary" disabled={submitting} onClick={save}>
            {submitting ? (
              <Spinner as="span" animation="border" size="sm" className="mr-2" />
            ) : (
              <i className="fas fa-save mr-2" />
            )}
            Save
          </Button>
        ) : (
          <Button variant="success" disabled>
            <i className="fas fa-check mr-2" />
            Saved
          </Button>
        )}
      </div>

      {/** @ts-ignore */}
      <ToggleButtonSelector className="my-2" value={filterAge} defaultValue="KID" onChange={setFilterAge}>
        <ToggleButtonOption value="KID">Kid</ToggleButtonOption>
        <ToggleButtonOption value="ALL">All (adult included)</ToggleButtonOption>
      </ToggleButtonSelector>
      <br />
      <ToggleButtonSelector className="my-2" value={filterLocale} defaultValue="ALL" onChange={setFilterLocale}>
        <ToggleButtonOption value="ALL">All</ToggleButtonOption>
        <ToggleButtonOption value="en-US">en-US</ToggleButtonOption>
        <ToggleButtonOption value="de-DE">de-DE</ToggleButtonOption>
        <ToggleButtonOption value="en-AU">en-AU</ToggleButtonOption>
        <ToggleButtonOption value="en-CA">en-CA</ToggleButtonOption>
        <ToggleButtonOption value="en-GB">en-GB</ToggleButtonOption>
        <ToggleButtonOption value="es-ES">es-ES</ToggleButtonOption>
        <ToggleButtonOption value="fr-FR">fr-FR</ToggleButtonOption>
        <ToggleButtonOption value="it-IT">it-IT</ToggleButtonOption>
      </ToggleButtonSelector>
      <br />
      {/** @ts-ignore */}
      <ToggleButtonSelector className="my-2" value={filterType} defaultValue="PLAYLIST" onChange={setFilterType}>
        <ToggleButtonOption value="PLAYLIST">Playlist</ToggleButtonOption>
        <ToggleButtonOption value="ARTIST">Artist</ToggleButtonOption>
      </ToggleButtonSelector>
      <hr />

      <Table bordered hover>
        <thead>
          <tr>
            <th className="text-center">#</th>
            <th>Picture</th>
            <th>Name</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {filteredContentPacks.map((cp, idx) => (
            <tr key={`${cp.id}.${idx}`}>
              <td className="text-center">{idx + 1}</td>
              <td>
                {/** @ts-ignore */}
                <Image width={100} src={cp.picture} />
              </td>
              <td>
                <a href={`/sp3/playlist/${cp.originId}`}>{cp.name}</a>
                {cp.adultOnly && (
                  <Badge className="ml-2" variant="danger">
                    Adult
                  </Badge>
                )}
                {cp.localeReplacements && (
                  <ul>
                    {(cp.localeReplacements ?? []).map((r) => (
                      <li key={r.originId}>
                        <a href={`/sp3/playlist/${r.originId}`}>{r.name}</a>
                      </li>
                    ))}
                  </ul>
                )}
              </td>
              <td className="p-0 align-middle text-center">
                <ButtonGroup size="sm">
                  <Button variant="info" onClick={() => moveUp(cp.id)} disabled={idx === 0}>
                    <i className="fas fa-arrow-up" />
                  </Button>
                  <Button variant="info" onClick={() => moveDown(cp.id)} disabled={idx === curPacks.length - 1}>
                    <i className="fas fa-arrow-down" />
                  </Button>
                </ButtonGroup>
                <Button variant="danger" onClick={() => removeIdx(cp.id)} className="square-icon icon-sm">
                  <i className="fas fa-times" />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="text-center">
        <SPPAddPack add={onAdd} />
      </div>
    </div>
  );
};

export default SPPContentPackListEditor;
