import { useState } from 'react';
import { Badge, Button } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Row from 'react-bootstrap/Row';

const ITEM_DISPLAY_NAME = {
  'decades.pre1950': 'Pre 1950s',
  'decades.1950': '50s',
  'decades.1960': '60s',
  'decades.1970': '70s',
  'decades.1980': '80s',
  'decades.1990': '90s',
  'decades.2000': '2000s',
  'decades.2010': '2010s',
  'decades.2020': '2020s',
  blues: 'Blues',
  classical: 'Classical',
  country: 'Country',
  dance: 'Dance',
  disco: 'Disco',
  electronic: 'Electronic',
  expert: 'Expert',
  jester: 'Jester',
  family: 'Family',
  folk: 'Folk',
  indie: 'Indie',
  jazz: 'Jazz',
  latin: 'Latin',
  love: 'Love Or Not',
  mix: 'Mixtapes',
  metal: 'Metal',
  'movies-tv': 'Soundtrack',
  'new-age': 'New Age',
  pop: 'Pop',
  punk: 'Punk',
  praise: 'Praise',
  'hip-hop': 'Hip Hop',
  reggae: 'Reggae',
  rnb: 'R&B',
  rock: 'Rock',
  'top-hits': 'Top Hits',
  workout: 'Workout',
  world: 'World',
};

type Props = {
  category: string;
  onSave: (c: string) => void;
};

export default function CategoryFields({ category: catalogItemCategory, onSave }: Props) {
  const [category, setCategory] = useState(catalogItemCategory);
  const disabled = false;
  const loading = false;

  console.log(category);

  return (
    <>
      <Row className="mx-2 mb-2">
        <div>
          {loading ? (
            'Updating...'
          ) : (
            <>
              <Badge pill variant="success" className="tag-pill">
                {category in ITEM_DISPLAY_NAME
                  ? ITEM_DISPLAY_NAME[category as keyof typeof ITEM_DISPLAY_NAME]
                  : category}
              </Badge>
              <DropdownButton
                disabled={disabled}
                as="span"
                id="dropdown-item-button"
                className="m-2"
                size="sm"
                title="Change Category"
              >
                {(Object.keys(ITEM_DISPLAY_NAME) as Array<keyof typeof ITEM_DISPLAY_NAME>)
                  .filter((f) => f !== category)
                  .map((f) => (
                    <Dropdown.Item key={f} as="button" onClick={() => setCategory(f)}>
                      {ITEM_DISPLAY_NAME[f]}
                    </Dropdown.Item>
                  ))}
              </DropdownButton>
            </>
          )}
        </div>
        <div style={{ flex: 1 }} />
        <div>
          <Button onClick={() => onSave(category)}>Save</Button>
        </div>
      </Row>
    </>
  );
}
