import CodeMirror from '@uiw/react-codemirror';
// eslint-disable-next-line
import 'codemirror/mode/javascript/javascript';
import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { generateOutput } from './api';
import './index.css';

export default () => {
  const [showAlert, setShowAlert] = useState(null);

  const [code, setCode] = useState(
    "Fatal Exception: RCTFatalException: Unhandled JS Exception: TypeError: undefined is not an object (evaluating 'v.picture') This error is located at: in Unknown in RCTView in RCTView in RCTScrollContentView in RCTScrollView in v in NativeViewGestureHandler in ComponentWrapper in RCTView in C in ForwardRef in O in ForwardRef in RCTView in RCTModalHostView in y in Q in RCTView in S in RCTView in ForwardRef in ForwardRef in ForwardRef in RCTView in w in RCTView in v in Unknown in Unknown in Unknown in Unknown in Unknown in RCTView in w in RCTView in RCTView in V in RCTView in f in RCTView in Unknown in x in Unknown in ne in Unknown in Unknown in Unknown in Unknown in RCTView in RCTView in RCTView in ForwardRef in RCTView in C in ForwardRef in PanGestureHandler in Unknown in RCTView in C in ForwardRef in RCTView in G in Unknown in RCTView in Unknown in RCTView in Unknown in w in h in Unknown in RCTView in G in Unknown in Unknown in ForwardRef in Unknown in Unknown in ForwardRef in Unknown in ForwardRef in Unknown in RNCSafeAreaProvider in Unknown in Unknown in Unknown in Unknown in c in Unknown in $ in RCTView in RCTView in C\nUnhandled JS Exception: TypeError: undefined is not an object (evaluating 'v.picture') This error is located at: in Unknown in RCTView in RCTView in RCTScroll..., stack: <unknown>@2220:872 Pr@99:40737 Oi@99:81520 Ol@99:71486 Dl@99:71411 Rl@99:68705 Rl@(null):(null) <unknown>@99:24961 <unknown>@186:2992 cn@99:24908 sn@99:24843 Vl@99:79815 <unknown>@186:2992 <unknown>@99:79302 Y@186:2374 n@186:243 C@131:655 callTimers@131:2585 value@40:3544 <unknown>@40:840 value@40:2798 value@40:812 value@(null):(null)"
  );
  const [beautifiedText, setBeautifyText] = useState('');
  const [platform, setPlatform] = useState('ios.js.map'); // android.bundle.map
  const [version, setVersion] = useState('001.008.000');
  const [build, setBuild] = useState('104');
  const startConversion = async () => {
    const url = `https://songpop3-test-builds.storage.googleapis.com/sourcemaps/prod/${version}_${build}.${platform}`;
    // const url = 'https://songpop3-test-builds.storage.googleapis.com/sourcemaps/test/001.008.000_46.ios.js';

    let response = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });

    console.log(response);
    if (response.status !== 200) {
      response = await fetch(url.replace('/prod/', '/beta/'), {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
    }
    console.log(response);

    if (response.status !== 200) {
      response = await fetch(url.replace('/prod/', '/test/'), {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
    }
    console.log(response);

    if (response.status !== 200) {
      setShowAlert(`Fail to download source map ${url}`);
      return;
    }

    const jsonData = await response.json();
    const newText = await generateOutput(code, jsonData);
    setBeautifyText(newText);
  };

  return (
    <>
      <h1>Stack Trace Debugger</h1>
      {!!showAlert && (
        <Alert variant="danger" onClose={() => setShowAlert(null)} dismissible>
          <Alert.Heading>{showAlert}</Alert.Heading>
        </Alert>
      )}

      <Container fluid className="stack-strace-container">
        <Row className="h-100">
          <Col className="col-5 p-0">
            <CodeMirror
              value={code}
              lazyLoadMode={false}
              onChange={(editor, data, value) => setCode(editor.getValue())}
              options={{
                tabSize: 2,
                mode: 'js',
                lineWrapping: true,
              }}
            />
          </Col>
          <Col className="col-2 p-0">
            <Form className="p-2">
              <Form.Group>
                <Form.Label>Platform</Form.Label>
                <Form.Control as="select" onChange={(event) => setPlatform(event.target.value)}>
                  <option value="ios.js.map">iOS</option>
                  <option value="android.bundle.map">Android</option>
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Version number</Form.Label>
                <Form.Control type="text" defaultValue={version} onChange={(event) => setVersion(event.target.value)} />
              </Form.Group>
              <Form.Group>
                <Form.Label>Build number</Form.Label>
                <Form.Control type="text" defaultValue={build} onChange={(event) => setBuild(event.target.value)} />
              </Form.Group>

              <Button onClick={startConversion}>Convert</Button>
            </Form>
          </Col>
          <Col className="col-5 p-0">
            <CodeMirror
              value={beautifiedText}
              lazyLoadMode={false}
              options={{
                tabSize: 2,
                mode: 'js',
                lineWrapping: true,
                readOnly: true,
                lineNumbers: false,
              }}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};
