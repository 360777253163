import { useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import type { ContentCategoriesQuery } from '../../../../../../__gqltypes__';
import { LoadingLogo } from '../../../../devtools/components/Modal';
import { GET_CONTENT_CATEGORIES, UPDATE_ITEM } from '../../../components/apolloQueries';
import { CURRENCY_TYPES, getQuery } from '../utils';
import ItemForm from './ItemForm';
import { Item, ItemsRefetch } from './typedef';
import UpgradeForm from './UpgradeForm';

type Props = {
  item: Item;
  type: string;
  refetch: ItemsRefetch;
};

export default function ItemView({ item, type, refetch }: Props) {
  const [itemData, setItemData] = useState(item);
  const { data } = useQuery<ContentCategoriesQuery>(GET_CONTENT_CATEGORIES);
  useEffect(() => {
    setItemData(item);
  }, [item]);
  const [updateItem, { loading: updateLoading }] = useMutation(UPDATE_ITEM, {
    onCompleted: () => refetch(),
  });

  const handleUpdate = () => {
    const itemCost = itemData.cost
      ? {
          // @ts-ignore
          amount: itemData.cost.amount === '' ? 0 : parseInt(itemData.cost.amount, 10),
          // @ts-ignore
          type: Object.keys(CURRENCY_TYPES).find((key) => CURRENCY_TYPES[key] === itemData.cost.__typename),
          // @ts-ignore
          category: itemData.cost.category ? itemData.cost.category.id : null,
        }
      : null;
    const levelItems =
      itemData.nextLevelItems && itemData.nextLevelItems.length
        ? // @ts-ignore
          itemData.nextLevelItems.map(({ asset, cost }) => ({
            asset,
            cost: {
              // @ts-ignore
              amount: cost?.amount === '' || cost === null ? 0 : parseInt(cost.amount, 10),
              type: itemCost ? itemCost.type : 'CURRENCY_DIAMOND',
            },
          }))
        : null;
    updateItem({
      variables: {
        input: {
          id: itemData.id,
          name: itemData.name,
          cost: itemCost,
          asset: itemData.asset,
          type: getQuery(type).typeQ,
          availability: itemData.liveOpsAvailability,
          levelItems,
          // @ts-ignore
          iconAsset: itemData.iconAsset || '',
          // @ts-ignore
          iconPrimaryColor: itemData.iconPrimaryColor || '',
          // @ts-ignore
          iconBackgroundColor: itemData.iconBackgroundColor || '',
        },
      },
    });
  };

  const handleAddLevel = () => {
    const { nextLevelItems } = itemData;
    nextLevelItems.push({
      id: `${itemData.id}.${nextLevelItems.length + 1}`,
      level: nextLevelItems.length + 2,
      // @ts-ignore
      cost: { amount: 0 },
      asset: '',
      isNewUpgrade: true,
    });
    setItemData({ ...itemData, nextLevelItems });
  };

  const handleDeleteLevel = () => {
    const { nextLevelItems } = itemData;
    nextLevelItems.pop();
    setItemData({ ...itemData, nextLevelItems });
  };

  const iterate = (root: Record<string, string> | string, acc: string[]) => {
    if (_.isString(root)) {
      if (root.startsWith('https')) {
        acc.push(root);
      }
      return;
    }
    if (_.isArray(root)) {
      root.forEach((r) => iterate(r, acc));
      return;
    }

    if (!_.isObject(root)) {
      return;
    }

    const val = Object.values(root);
    val.forEach((v) => iterate(v, acc));
  };

  const handleValidateJsonItem = (f: Blob): Promise<string[]> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = async (evt) => {
        let jsonObj = {};
        const validationErrors = [];
        try {
          // @ts-ignore
          jsonObj = JSON.parse(evt.target.result);
        } catch (e) {
          console.error('not valid JSON file', e);
          validationErrors.push('not valid JSON file');
        }

        const acc: string[] = [];
        iterate(jsonObj, acc);
        for (const url of acc) {
          try {
            const response = await fetch(url);
            if (response.status !== 200) {
              validationErrors.push(`${url} not found`);
            }
          } catch (e) {
            validationErrors.push(`${url} not found`);
          }
        }
        resolve(validationErrors);
      };

      reader.readAsText(f);
    });
  };

  const { contentCategories } = data ? data.app : { contentCategories: [] };
  const isVinyl = itemData.__typename === 'AppSkin';

  return (
    <>
      <Card className="mt-3">
        <LoadingLogo show={updateLoading} />
        <Card.Header className="text-center d-flex justify-content-between">
          <h4>{itemData.name}</h4>
          <Button onClick={() => handleUpdate()}>Save</Button>
        </Card.Header>
        <Card.Body style={{ height: '100%' }}>
          <ItemForm
            itemData={itemData}
            setItemData={setItemData}
            categories={contentCategories}
            refetch={refetch}
            onValidate={isVinyl ? handleValidateJsonItem : undefined}
          />
          {itemData.nextLevelItems.map((upgrade) => (
            <UpgradeForm
              upgrade={upgrade}
              itemData={itemData}
              setItemData={setItemData}
              refetch={refetch}
              key={upgrade.id}
              // @ts-ignore
              isNewUpgrade={upgrade.isNewUpgrade}
              onValidate={isVinyl ? handleValidateJsonItem : undefined}
            />
          ))}
          <br />
          <div className="d-flex justify-content-between">
            <Button variant="success" onClick={handleAddLevel}>
              <i className="fas fa-plus-circle mr-2" />
              Add an upgrade
            </Button>
            <Button variant="danger" onClick={handleDeleteLevel}>
              <i className="fas fa-minus-circle mr-2" /> Remove an upgrade
            </Button>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}
