import moment from 'moment';
import React from 'react';
import DateSlicer from './components/DateSlicer';
import TextSlicer from './components/TextSlicer';
// Slicers
import SlicerContainer from './SlicerContainer';
// PropTypes
import { defaultSlicingPropTypes } from './slicerPropTypes';
import RangeSlicer from './components/RangeSlicer';

const MarketingSlicing = ({ defaultStartDate, defaultEndDate, onRefresh }) => (
  <SlicerContainer onRefresh={onRefresh}>
    <DateSlicer name="DateSlicer" id="startDate" title="Start Date" defaultValue={defaultStartDate} />
    <DateSlicer name="DateSlicer" id="endDate" title="End Date" defaultValue={defaultEndDate} />
    <TextSlicer name="TextListSlicer" id="networkName" title="Network Name"/>
    <TextSlicer name="TextListSlicer" id="campaignName" title="Campaign Name"/>
    <TextSlicer name="TextListSlicer" id="adSetName" title="adSet Name"/>
  </SlicerContainer>
);

MarketingSlicing.propTypes = defaultSlicingPropTypes;

MarketingSlicing.defaultProps = {
  defaultStartDate: moment().subtract(15, 'days').format('YYYY-MM-DD'),
  defaultEndDate: moment().subtract(2, 'day').format('YYYY-MM-DD'),
};

export default MarketingSlicing;
