import * as React from 'react';
import _ from 'lodash';
import moment from 'moment';

import { useQuery, useMutation } from '@apollo/client';
import { useHistory, useParams } from 'react-router';
import { Badge, Button, Card, Col, Nav, Row, Spinner, Tab } from 'react-bootstrap';
import { 
  ChainedOfferEventConfig, 
  ChainedOfferEventStatus, 
  CreateChainedOfferEventInput, 
  CreateChainedOfferEventMutation, 
  CreateChainedOfferEventMutationVariables, 
  DeleteChainedOfferEventMutation, 
  DeleteChainedOfferEventMutationVariables, 
  GetLiveOpsChainedOfferEventConfigsQuery, 
  RedisCacheModel} from '../../../../../__gqltypes__';
import { CREATE_CHAINED_OFFER_EVENT, DELETE_CHAINED_OFFER_EVENT, LIVEOPS_CHAINED_OFFER_EVENT_CONFIGS_QUERY } from './graphql';
import { LoadingLogo } from '../../../devtools/components/Modal';
import AddChainedOfferModal from './components/AddChainedOfferModal';
import FlushButton from '../../../devtools/screens/Cache/FlushButton';
import { ChainedOfferEventData } from './components/ChainedOfferEventData';


const baseURL = '/sp3/liveops/chained-offers';

function ChainedOffer() {
    /**
   * GETTING ENV DATA
   */
  const { id: eventId } = useParams<{ id: string }>();
  const history = useHistory();

  /**
   * GRAPHQL
   */
  const { data: chainedOfferData, loading: fetchingLoading} = useQuery<GetLiveOpsChainedOfferEventConfigsQuery>(
    LIVEOPS_CHAINED_OFFER_EVENT_CONFIGS_QUERY,
    {
      fetchPolicy: 'no-cache'
    }
  );

  const [createChainedOffer, { loading: createChainedOfferLoading}] = useMutation<
    CreateChainedOfferEventMutation, 
    CreateChainedOfferEventMutationVariables
    >(CREATE_CHAINED_OFFER_EVENT);

  const [chainedOffers, setChainedOffers] = React.useState<Array<ChainedOfferEventConfig>>([]);
  const [showAddModal, setShowAddModal] = React.useState(false);

  const handleUpsertChainedOffer = React.useCallback((offer: ChainedOfferEventConfig) => {
    const other = chainedOffers.filter(({id}) => id !== offer.id);
    const all = [...other, offer];
    setChainedOffers(_.orderBy(all, 'startDate', 'desc'));
  }, [chainedOffers, setChainedOffers]);

  const handleRemoveChainedOffer = React.useCallback((chainedOfferId: string) => {
    const leftChainedOffer = chainedOffers.filter(({id}) => id !== chainedOfferId);
    setChainedOffers(leftChainedOffer);
  }, [chainedOffers, setChainedOffers]);

  const isChainedOfferSelected = React.useCallback(() => {
    return !_.isEmpty(eventId) && chainedOffers.find(({id}) => id === eventId);
  }, [eventId, chainedOffers]);

  const selectChainedOffer = React.useCallback((newChainedOfferId) => {
    history.push(`${baseURL}/${newChainedOfferId}`);
  }, [history]);

  const handleAddChainedOffer = React.useCallback((chainedOfferInput: CreateChainedOfferEventInput) => {
    setShowAddModal(false);
    createChainedOffer({
      variables: {
        input: chainedOfferInput
      }
    }).then(({data}) => {
      const newChainedOffer = data?.createChainedOfferEvent?.chainedOfferEvent;
      if (newChainedOffer) {
        // @ts-ignore
        handleUpsertChainedOffer(newChainedOffer);
        selectChainedOffer(newChainedOffer.id);
      }

    }).catch((error) => {
      console.error(error);
    });
  }, [setShowAddModal, createChainedOffer, selectChainedOffer, handleUpsertChainedOffer]);

  React.useEffect(() => {
    if (chainedOfferData && chainedOfferData.liveOps) {
      const _chainedOfferData = _.orderBy(chainedOfferData.liveOps.chainedOfferEvents, 'startDate', 'desc');
      // @ts-ignore
      setChainedOffers(_chainedOfferData);
    }
  }, [chainedOfferData]);

  React.useEffect(() => {
    if (eventId === ':id') {
      selectChainedOffer('');
    }
    if (!isChainedOfferSelected() && chainedOffers.length > 0) {
      selectChainedOffer(chainedOffers[0].id);
    }

  }, [isChainedOfferSelected, selectChainedOffer, eventId, chainedOffers]);

  const loading = createChainedOfferLoading || fetchingLoading;
  const _now = moment();
  return (
    <>
      <LoadingLogo show={fetchingLoading} />

      {
        isChainedOfferSelected() && (
        // @ts-ignore
        <Tab.Container className="h-100" activeKey={eventId} onSelect={selectChainedOffer} >
          <Row className="mb-5 h-100">
            <Col className="mb-3">
            <Nav.Item className="mb-3">
            { loading ? (
              <Spinner animation="border" />
            ): (
              <>
                <Button className="w-100" variant='success' onClick={() => setShowAddModal(true)}>
                  <i className="fas fa-plus mr-2" />
                  create Chained Offer
                </Button>
              </>
            )}
            </Nav.Item>
              <Nav.Item className="mb-3">
                <FlushButton model={RedisCacheModel.CHAINED_OFFER} />
              </Nav.Item>
              <Nav variant='pills'>
                {chainedOffers.map((chainedOffer) => (
                  <Nav.Item key={chainedOffer.id} className="w-100 position-relative">
                    <Nav.Link
                      eventKey={chainedOffer.id}
                      className='d-flex justify-content-between text-break align-items-center'
                     >
                      <span>{chainedOffer.name}</span>
                      <Badge
                        className="m-1"
                        pill
                        variant={
                          chainedOffer.status === ChainedOfferEventStatus.DRAFT
                           ? 'danger'
                           : moment(chainedOffer.startDate).isAfter(_now)
                           ? 'warning'
                           : moment(chainedOffer.stopDate).isBefore(_now)
                           ? 'secondary'
                           : 'success'
                        }>
                          {
                          chainedOffer.status === 'DRAFT'
                          ? 'Inactive'
                          : moment(chainedOffer.startDate).isAfter(_now)
                          ? 'Pending'
                          : moment(chainedOffer.stopDate).isBefore(_now)
                          ? 'Expired'
                          : 'Running'}
                      </Badge>
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Col>

            <Col className="col-10">
              <Tab.Content className='h-100'>
                <Card>
                  <Card.Body>
                    <ChainedOfferEventData 
                      chainedOfferId={eventId}
                      onUpserChainedOffer={handleUpsertChainedOffer}
                      onRemoveChainedOffer={handleRemoveChainedOffer}/>
                  </Card.Body>

                </Card>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
        )
      }
      <AddChainedOfferModal 
        show={showAddModal || (!fetchingLoading && chainedOffers.length === 0)}
        loading={loading}
        onHide={() => setShowAddModal(false)}
        onAdd={handleAddChainedOffer}
        />
    </>
  );
}

export default ChainedOffer;