import { Form } from 'react-bootstrap';

export interface StartEndInputProps {
  start: string | undefined;
  end: string | undefined;
  setStart: React.Dispatch<React.SetStateAction<string | undefined>>;
  setEnd: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export default function StartEndInput({ start, end, setStart, setEnd }: StartEndInputProps) {
  return (
    <>
      <Form.Group className="mt-3">
        <Form.Label>Start</Form.Label>
        <Form.Control
          type="date"
          value={start}
          onChange={(e) => {
            setStart(e.target.value || undefined);
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>End</Form.Label>
        <Form.Control
          type="date"
          value={end}
          onChange={(e) => {
            setEnd(e.target.value || undefined);
          }}
        />
      </Form.Group>
    </>
  );
}
