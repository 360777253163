import React, { useMemo } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import EditableParam from './EditableParam';

type Props = {
  playlist: {
    id: string;
    level: number;
    progressToNextLevel: number;
    contentPack: { id: string; name: string; picture: string };
  };
  canDelete: boolean;
  onChange: (newPlaylist: { id: string; level: number; progressToNextLevel: number }) => void;
};

export default function PlaylistEditor({ playlist, canDelete = false, onChange }: Props) {
  const { level, progressToNextLevel } = useMemo(
    () => ({
      level: playlist.level,
      progressToNextLevel: playlist.progressToNextLevel,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // @ts-ignore
  const handleChangePlaylist = (key: string) => (event) => {
    const { value } = event.target;
    if (onChange) {
      onChange({ ...playlist, [key]: value });
    }
  };

  if (!playlist) return null;

  return (
    <Card>
      <Card.Header>
        {canDelete && (
          <Row>
            <Button variant="danger">X</Button>
          </Row>
        )}
        <Row>{playlist.contentPack.name}</Row>
      </Card.Header>
      <Card.Body>
        <Row className="align-items-center">
          <Col md="auto" className="m-2">
            <Image
              roundedCircle
              className="img-profile"
              src={playlist.contentPack.picture}
              style={{ width: 150, height: 150 }}
            />
          </Col>
          <Col>
            <EditableParam
              key="level"
              title="level"
              value={playlist.level}
              onChange={handleChangePlaylist('level')}
              placeholder={`${level}`}
            />
            <EditableParam
              key="progressToNextLevel"
              title="progressToNextLevel"
              value={playlist.progressToNextLevel}
              onChange={handleChangePlaylist('progressToNextLevel')}
              placeholder={`${progressToNextLevel}`}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
