import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

type Props = {
  onSubmit: (value: string) => void;
  placeholder: string;
  buttonText?: string;
};

export default function SearchBar({ onSubmit, placeholder, buttonText }: Props) {
  const [searchInput, setSearchInput] = useState('');

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => setSearchInput(event.target.value);

  const onPressEnterInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSubmit(searchInput);
    }
  };

  const handleSubmit = () => {
    onSubmit(searchInput);
  };

  return (
    <InputGroup className="mb-2 mr-4">
      <Form.Control
        value={searchInput}
        placeholder={placeholder}
        onChange={onChangeInput}
        onKeyDown={onPressEnterInput}
      />
      <InputGroup.Append>
        <Button onClick={handleSubmit}>
          <i className="fas fa-search mr-2" />
          {buttonText}
        </Button>
      </InputGroup.Append>
    </InputGroup>
  );
}
