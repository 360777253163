import { gql, useApolloClient } from '@apollo/client';
import { GraphQLVoyager } from 'graphql-voyager';
import React from 'react';
import Button from 'react-bootstrap/Button';
import './GraphQLVoyagerView.css';

const convertReturnedType = (returnedType) => {
  if (!returnedType.ofType) {
    return returnedType.name;
  }
  if (returnedType.kind === 'NON_NULL') {
    return `${convertReturnedType(returnedType.ofType)}!`;
  }
  if (returnedType.kind === 'LIST') {
    return `[${convertReturnedType(returnedType.ofType)}]`;
  }
  return null;
};

const convertField = (field) => {
  const { name } = field;
  const returned = convertReturnedType(field.type);
  let args = null;
  if (field.args && field.args.length) {
    args = field.args.map(convertField).join(', ');
  }
  if (args) {
    return `${name}(${args}):${returned}`;
  }
  return `${name}:${returned}`;
};

const GraphQLVoyagerView = () => {
  const client = useApolloClient();

  const cachedTypes = [];

  const introspectionProvider = (query) => {
    console.log('query', query);

    const _query = gql`
      ${query}
    `;

    return client.query({ query: _query, fetchPolicy: 'no-cache' }).then((result) => {
      const explored = result.data.__schema;

      console.log('explored', explored);

      explored.types.forEach((element) => {
        if (element.kind === 'OBJECT' && !element.name.startsWith('__')) {
          const title = `Type ${element.name}`;
          const fields = element.fields.map(convertField).join('\n\t');

          cachedTypes.push(`\n ${title}\n\t${fields} \n`);
        }
      });

      return result;
    });
  };

  const onExport = () => {
    const filename = 'graphql-schema.txt';
    // if (!csv.match(/^data:text\/csv/i)) {
    //   csv = `data:text/csv;charset=utf-8,${csv}`;
    // }
    const data = `data:text/plain;charset-utf-8,${encodeURI(cachedTypes.join(''))}`;
    const link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <GraphQLVoyager introspection={introspectionProvider} workerURI={`${process.env.PUBLIC_URL}/voyager.worker.js`}>
      <GraphQLVoyager.PanelHeader>
        <div className="voyager-panel">
          <Button onClick={onExport}>Export Schema</Button>
        </div>
      </GraphQLVoyager.PanelHeader>
    </GraphQLVoyager>
  );
};

export default GraphQLVoyagerView;
