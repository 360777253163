export type SelectedPlaylists = { [key: string]: boolean };

export const filterSelected = (sel: SelectedPlaylists = {}) =>
  Object.fromEntries(Object.entries(sel).filter(([_, v]) => v));

export type ImportState = 'NONE' | 'CONFIRM' | 'IMPORTING';

export type Action =
  | { type: 'SET_START'; startDate: string }
  | { type: 'SET_END'; endDate: string }
  | { type: 'SET_PAGE_SIZE'; pageSize: number }
  | { type: 'SET_SELECTED'; selectedPlaylists: SelectedPlaylists }
  | { type: 'SET_SELECTED_ALL'; selectedPlaylists: SelectedPlaylists; allSelected: boolean }
  | { type: 'SET_IMPORT_STATE'; importState: ImportState }
  | { type: 'SET_MISSING_ONLY'; missingOnly: boolean };

type State = {
  startDate: string;
  endDate: string;
  pageSize: number;
  selectedPlaylists: SelectedPlaylists;
  allSelected: boolean;
  importState: ImportState;
  missingOnly: boolean;
};

export default function sp2Reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'SET_START':
      return { ...state, startDate: action.startDate };
    case 'SET_END':
      return { ...state, endDate: action.endDate };
    case 'SET_PAGE_SIZE':
      return { ...state, pageSize: action.pageSize };
    case 'SET_SELECTED':
      return {
        ...state,
        selectedPlaylists: filterSelected(Object.assign(state.selectedPlaylists, action.selectedPlaylists)),
      };
    case 'SET_SELECTED_ALL':
      return {
        ...state,
        selectedPlaylists: filterSelected(action.selectedPlaylists),
        allSelected: action.allSelected,
      };
    case 'SET_IMPORT_STATE':
      return {
        ...state,
        importState: action.importState,
      };
    case 'SET_MISSING_ONLY':
      return {
        ...state,
        missingOnly: action.missingOnly,
      };
    default:
      throw new Error('oops');
  }
}
