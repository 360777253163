import { useMutation } from '@apollo/client';
import { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import { CurationContentPackQuery } from '../../../../../../__gqltypes__';
import Loader from '../../../components/Loader';
import PlaylistMiniCard from '../../../components/PlaylistMiniCard';
import TooltipButton from '../../../components/TooltipButton';
import SearchPlaylistPopup from '../../../popups/SearchPlaylistPopup';
import mutations from '../../../utils/mutations';

type Props = {
  curationContentPack: CurationContentPackQuery['curationContentPack'];
};

export default function ReplacingPlaylist({ curationContentPack }: Props) {
  const [replacingPlaylistModalShow, setReplacingPlaylistModalShow] = useState(false);

  const modalClose = () => setReplacingPlaylistModalShow(false);
  const modalOpen = () => setReplacingPlaylistModalShow(true);

  const [updateReplacingPlaylist, { loading: replacingPlaylistLoading }] = useMutation(
    mutations.UPDATE_REPLACING_CURATION_CONTENT_PACK
  );

  const handleUpdateReplacingPlaylist = ({ id }: { id: string }) => {
    updateReplacingPlaylist({
      variables: { input: { id: curationContentPack.id, replacingId: id } },
    });
    modalClose();
  };

  const handleDeleteReplacingPlaylist = () => {
    updateReplacingPlaylist({
      variables: { input: { id: curationContentPack.id, replacingId: null } },
    });
  };

  const disabled = curationContentPack.state !== 'VALIDATING' || replacingPlaylistLoading;

  return (
    <>
      <Card className="mt-2">
        <Card.Body>
          {curationContentPack.replacingContentPack ? (
            <>
              <Row className="mx-1">Replacing Playlist:</Row>
              <Row className="mx-2 mb-2">
                {replacingPlaylistLoading ? (
                  <Loader />
                ) : (
                  <>
                    <PlaylistMiniCard playlist={curationContentPack.replacingContentPack} className="mb-2" />
                    <TooltipButton
                      className="mr-2"
                      onClick={modalOpen}
                      disabled={disabled}
                      disabledReason="Playlist is not in state VALIDATING"
                    >
                      Edit
                    </TooltipButton>
                    <TooltipButton
                      onClick={handleDeleteReplacingPlaylist}
                      disabled={disabled}
                      disabledReason="Playlist is not in state VALIDATING"
                      variant="outline-danger"
                    >
                      Delete
                    </TooltipButton>
                  </>
                )}
              </Row>
            </>
          ) : (
            <TooltipButton
              onClick={modalOpen}
              disabled={disabled}
              disabledReason={replacingPlaylistLoading ? null : 'Playlist is not in state VALIDATING'}
            >
              {replacingPlaylistLoading ? 'Adding a replacing playlist...' : 'Add a replacing playlist'}
            </TooltipButton>
          )}
        </Card.Body>
      </Card>
      <SearchPlaylistPopup
        show={!!replacingPlaylistModalShow}
        onHide={modalClose}
        onSelectPlaylist={handleUpdateReplacingPlaylist}
      />
    </>
  );
}
