import { useLazyQuery } from '@apollo/client';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { GetImportantEventsQuery, GetImportantEventsQueryVariables, ImportantEvent } from '../../../../../__gqltypes__';
import ConfirmDeleteImportantEvent from './components/ConfirmDeleteImportantEvent';
import ImportantEventCard from './components/ImportantEventCard';
import ImportantEventForm from './components/ImportantEventForm';
import { GET_IMPORTANT_EVENTS_BETWEEN } from './graphql';

export default function ImportantEvents() {
  const [eventToDelete, setEventToDelete] = useState<ImportantEvent | null>(null);
  const [monthIndex, setMonthIndex] = useState<number>(1);
  const [focusedEvents, setFocusedEvents] = useState<string[]>([]);
  const [fetchImportantEvents, { data, fetchMore }] = useLazyQuery<
    GetImportantEventsQuery,
    GetImportantEventsQueryVariables
  >(GET_IMPORTANT_EVENTS_BETWEEN);

  useEffect(() => {
    setMonthIndex(2);
    fetchImportantEvents({
      variables: { input: { start: moment().subtract(1, 'month').startOf('month'), end: moment() } },
    });
  }, [fetchImportantEvents]);

  const eventsByMonth = _.groupBy([...(data?.getImportantEvents || [])], (event) =>
    moment(event.datetime).format('YYYY-MM')
  );

  return (
    <div className="p-1">
      <div className="d-flex mb-3" style={{ justifyContent: 'right' }}>
        <ImportantEventForm OpenDialogButton={({ onClick }) => <Button onClick={onClick}>Create new event</Button>} />
      </div>

      {Array.from({ length: monthIndex }, (_v, m) => {
        const month = moment().subtract(m, 'month').format('YYYY-MM');
        const eventsThisMonth = eventsByMonth[month] || [];
        return (
          <div key={month}>
            <div className="w-100 text-center my-3">
              <b>{month}</b>
            </div>
            {eventsThisMonth
              .sort((ev1, ev2) => ev2.datetime - ev1.datetime)
              .map((importantEvent) => (
                <ImportantEventCard
                  event={importantEvent}
                  key={importantEvent.eventKey}
                  setEventToDelete={setEventToDelete}
                  displayUpdateButton
                  open={focusedEvents.includes(importantEvent.eventKey)}
                  onOpen={() => setFocusedEvents((pFocusedEvents) => [...pFocusedEvents, importantEvent.eventKey])}
                  onClose={() =>
                    setFocusedEvents((pFocusedEvents) =>
                      pFocusedEvents.filter((eventKey) => eventKey !== importantEvent.eventKey)
                    )
                  }
                />
              ))}

            {eventsThisMonth.length === 0 && <div className="w-100 text-center my-3">No event this month</div>}
          </div>
        );
      })}

      <div className="w-100 text-center my-5">
        <Button
          onClick={() => {
            fetchMore({
              variables: {
                input: {
                  start: moment().subtract(monthIndex, 'month').startOf('month'),
                  end: moment().subtract(monthIndex, 'month').endOf('month'),
                },
              },
            });
            setMonthIndex((pMonthIndex) => pMonthIndex + 1);
          }}
        >
          Load events of month {moment().subtract(monthIndex, 'month').format('YYYY-MM')}
        </Button>
      </div>

      <ConfirmDeleteImportantEvent event={eventToDelete} setEventToDelete={setEventToDelete} />
    </div>
  );
}
