import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Accordion, InputGroup } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { UPDATE_USER_INFLUENCER } from '../../../graphql';

const isTestEnv = process.env.REACT_APP_NAMESPACE === 'test';

const FIREBASE_ADMIN = isTestEnv
  ? 'https://console.firebase.google.com/project/songpop3-test/durablelinks/links/https:~2F~2Flink.test.songpop3.com'
  : 'https://console.firebase.google.com/project/songpop3/durablelinks/links/https:~2F~2Flink.songpop3.com';
const SHORT_LINK = isTestEnv ? 'https://link.test.songpop3.com' : 'https://link.songpop3.com';
const DEEP_LINK = isTestEnv
  ? 'https://test.songpop3.com/app/influencer'
  : 'https://default.songpop3.com/app/influencer';

const CopyLinkButton = ({ value }: { value: string }) => {
  return (
    <InputGroup>
      {/* @ts-ignore */}
      <Form.Control readOnly value={value} />
      <InputGroup.Append>
        <Button onClick={() => navigator.clipboard.writeText(value)}>Copy Link</Button>
      </InputGroup.Append>
    </InputGroup>
  );
};

const getDeepLinkUrl = (userId: string, influencerName: string, influencerPicture?: string) => {
  return `${DEEP_LINK}/${userId}?from=${influencerName}&picture=${influencerPicture}`;
};

const getShortLinkUrl = (influencerName: string) => {
  return `${SHORT_LINK}/${influencerName}`;
};

const trimInfluencerName = (influencerName: string) => {
  return influencerName.trim().replace(/\s+/g, '-');
};

const InfluencerDisplay = ({
  userId,
  isInfluencer,
  username,
  influencerPicture,
}: {
  userId: string;
  isInfluencer: boolean;
  username: string;
  influencerPicture?: string;
}) => {
  const [updateUserInfluencer, { loading: updateUserInfluencerLoading }] = useMutation(UPDATE_USER_INFLUENCER);

  useEffect(() => {
    setIsInfluencer(isInfluencer);
  }, [isInfluencer]);

  const [expanded, setExpanded] = useState(false);
  const [isInfluencerInput, setIsInfluencer] = useState(isInfluencer);

  const handleIsInfluencer = () => {
    setIsInfluencer(!isInfluencerInput);
  };

  const influencerName = trimInfluencerName(username);

  const handleSave = () => {
    updateUserInfluencer({
      variables: { input: { userId, isInfluencer: isInfluencerInput, influencerId: influencerName } },
    }).catch((error) => {
      console.error(error);
    });
  };

  return (
    <Card className="mb-4">
      <Card.Body>
        <Accordion>
          <Accordion.Toggle as={Card.Title} variant="link" eventKey="0" onClick={() => setExpanded(!expanded)}>
            <div style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}>
              Influencer
              <Button variant="link">
                {!expanded && <i className="fas fa-chevron-down" />}
                {expanded && <i className="fas fa-chevron-up" />}
              </Button>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <>
              <Form.Check
                type="checkbox"
                label="Is Influencer"
                checked={isInfluencerInput}
                onChange={handleIsInfluencer}
              />

              <div className="d-flex justify-content-end align-items-baseline mb-3">
                <Button disabled={updateUserInfluencerLoading} variant="primary" onClick={handleSave}>
                  Save
                </Button>
              </div>
              <hr />
              <Form.Group>
                <Form.Label>Generate Link</Form.Label>
                <Card>
                  <Card.Body>
                    <Form.Text>
                      Go to the firebase admin (<a href={FIREBASE_ADMIN}>link</a>)
                    </Form.Text>
                    <Form.Text>Create New Dynamic Link</Form.Text>
                    <Form.Text>
                      Short URL name: <strong>{influencerName}</strong>
                    </Form.Text>
                    <Form.Text>
                      Link preview: <strong>{getShortLinkUrl(influencerName)}</strong>
                    </Form.Text>
                    <Form.Text>Deep link URL:</Form.Text>
                    <CopyLinkButton value={getDeepLinkUrl(userId, influencerName, influencerPicture)} />
                    <Form.Text>Check: Open the deep link in your Apple App</Form.Text>
                    <Form.Text>Check: Open the deep link in your Android App</Form.Text>
                    <Form.Text>Skip social: No Social Media/No tracking campaign</Form.Text>
                    <Form.Text>Save</Form.Text>
                  </Card.Body>
                </Card>
                <br />
                <Form.Label>Final link</Form.Label>
                <CopyLinkButton value={getShortLinkUrl(influencerName)} />
              </Form.Group>
            </>
          </Accordion.Collapse>
        </Accordion>
      </Card.Body>
    </Card>
  );
};

export default InfluencerDisplay;
