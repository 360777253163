import { useApolloClient } from '@apollo/client';
import React, { useCallback, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import mutations from '../../utils/mutations';

type Props = {
  listId: string;
  listName?: string;
  refetchLists: (value?: any) => Promise<any>;
};

const DeleteContentPackList = ({ listId, listName, refetchLists }: Props) => {
  const client = useApolloClient();

  const [deleting, setDeleting] = useState(false);
  const askDelete = useCallback(() => setDeleting(true), [setDeleting]);
  const cancelDelete = useCallback(() => setDeleting(false), [setDeleting]);

  const [submitting, setSubmitting] = useState(false);

  const doDelete = useCallback(async () => {
    setSubmitting(true);
    try {
      await client.mutate({
        mutation: mutations.DELETE_CONTENT_PACK_LIST,
        variables: {
          contentPackListId: listId,
        },
      });
    } catch (err) {
      console.error(err);
      return;
    }
    setDeleting(false);
    await refetchLists();
  }, [client, listId, refetchLists]);

  return (
    <>
      <Button variant="danger" onClick={askDelete} className="square-icon">
        <i className="fas fa-trash" />
      </Button>

      <Modal show={deleting} onHide={cancelDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Delete content pack list</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete &quot;{listName}&quot;?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cancelDelete} disabled={submitting}>
            Cancel
          </Button>
          <Button variant="danger" onClick={doDelete} disabled={submitting}>
            {submitting && <Spinner as="span" animation="border" size="sm" className="mr-2" />}
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteContentPackList;
