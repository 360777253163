import Mustache from 'mustache';
import { DashboardQueryType } from '../../../__gqltypes__';
import { buildFilters, createFilterSubquery, Filter, getFilterSubqueriesPaths } from '../services/filters';
import { fetchFilterTemplate, fetchQueryTemplate } from '../services/queries';
import { SlicingClause } from '../services/slicingClauses';

export type QueryTemplates = Record<string, { fillZeroes?: boolean; preRender?: string; source?: DashboardQueryType }>;

class FilterManager {
  config: Record<string, string>;

  dashboardId: string;

  filters: Record<string, Filter>;

  loadedFilterTemplates: boolean;

  loadedQueryTemplates: boolean;

  queries: QueryTemplates = {};

  queryTemplates: Record<string, string> = {};

  constructor(dashboardId: string, queries: QueryTemplates, filters: Record<string, Filter>) {
    this.loadedQueryTemplates = false;
    this.loadedFilterTemplates = false;
    this.dashboardId = dashboardId;
    this.queries = queries;
    this.filters = filters;

    const prod =
      process.env.REACT_APP_NAMESPACE === 'beta' ||
      process.env.REACT_APP_NAMESPACE === 'default' ||
      process.env.REACT_APP_NAMESPACE === 'prod';

    this.config = {
      songpop1Project: prod ? 'wam-mobile' : 'wam-test',
      songpop2Project: prod ? 'songpop2-prod' : 'songpop2-test',
      songpop3Project: prod ? 'songpop3' : 'songpop3-test',
      megapopProject: prod ? 'megapop-prod' : 'megapop-test',
      songpopTVProject: prod ? 'songpoptv-prod' : 'songpoptv-test',
      stage: prod ? 'prod' : 'test',
      analyticsDataset: prod ? 'analytics_198289266.events_' : 'analytics_198289266.events_',
      analyticsDailyDataset: 'analytics_daily.fe_events',
      analyticsDailyDAUDataset: 'analytics_daily.dau',
      analyticsDailyInstallDataset: 'analytics_daily.installs',
      analyticsDailyPurchaseDataset: 'analytics_daily.purchases',
      economyBackendDataset: 'analytics_backend.economy_events_',
      backendDataset: 'analytics_backend.events_',
    };
  }

  /**
   * Loads the query templates, and renders those which have a `preRender` property
   */
  async loadQueryTemplates() {
    const queryPromises = Object.keys(this.queries).map((key) => fetchQueryTemplate(this.dashboardId, key));
    const resQueries = await Promise.all(queryPromises);

    const queryTemplates: Record<string, string> = {};
    Object.keys(this.queries).forEach((key, index) => {
      queryTemplates[key] = resQueries[index];
      if (this.queries[key].preRender) {
        queryTemplates[key] = Mustache.render(resQueries[index], this.queries[key].preRender);
      } else {
        queryTemplates[key] = resQueries[index];
      }
    });

    this.queryTemplates = queryTemplates;
    this.loadedQueryTemplates = true;
  }

  /**
   * Loads the filter templates, according to the structure and the input data of the slicing form
   * @param {Record<string, string>} input The input data from the slicing form
   * @param {Record<string, SlicingClause>} slicing The slicing clauses
   */
  async loadFilterTemplates(input: Record<string, string>, slicing: Record<string, SlicingClause>) {
    const filterPaths = getFilterSubqueriesPaths(this.filters, slicing)(input);

    const filterPromises = Object.keys(filterPaths).map((key) => fetchFilterTemplate(filterPaths[key]));
    const resFilters = await Promise.all(filterPromises);

    const filterTemplates: Record<string, string> = {};
    Object.keys(this.filters).forEach((key, index) => {
      filterTemplates[key] = resFilters[index];
    });

    Object.keys(filterTemplates).forEach((key) => {
      this.filters[key].query = filterTemplates[key];
    });

    this.loadedFilterTemplates = true;
  }

  /**
   * Renders the queries templates, filling the Mustache fields with the data from the slicing form
   * @param {Record<string, string>} input The input data from the slicing form
   * @param {Record<string, SlicingClause>} slicing The slicing clauses
   */
  renderQueriesTemplates(input: Record<string, string>, slicing: Record<string, SlicingClause>) {
    const filterJoin = createFilterSubquery(this.filters);
    // const queries =
    //  renderQueriesTemplates(this.filters, slicing, this.config)(this.queryTemplates, filterJoin, input);

    // We gather all the data we want to render in the object context
    const context: Record<string, string> & {
      filters: string;
      filters_having: string;
      filters_and: string;
      filtersNoDay: string;
    } = {
      ...this.config,
      filters: buildFilters(slicing)(input, 'where'),
      filters_having: buildFilters(slicing)(input, 'having'),
      filters_and: buildFilters(slicing)(input, 'and'),
      filtersNoDay: buildFilters(slicing)(input, 'where', true),
    };

    Object.keys(input).forEach((inputId) => {
      context[inputId] = input[inputId];
    });

    Object.values(this.filters).forEach((filter) => {
      Object.keys(filter.fields).forEach((field) => {
        context[field] = filter.fields[field];
      });
    });

    // We replace the mustache expressions in filter subqueries first and we add those complete subqueries to context
    context.filterJoin = Mustache.render(filterJoin, context);

    const filterTemplateNoDay = filterJoin
      .replace(/\n(on main\.userId = .+) and main\.day = .+/g, '\n$1')
      .replace(
        /(table_date_range\(\[{{songpop2Project}}:users\.{{stage}}_dau_\]), timestamp\("{{startDate}}"\)/g,
        "$1, date_add(timestamp('{{startDate}}'),-1,'MONTH')"
      );

    context.filterJoinNoDay = Mustache.render(filterTemplateNoDay, context);

    // Finally, we insert subqueries in queries and replace remaining mustache expressions in principal queries
    const result: Record<string, string> = {};
    Object.keys(this.queryTemplates).forEach((key) => {
      const queryTemplate = this.queryTemplates[key];
      result[key] = Mustache.render(queryTemplate, context);
    });

    return result;
  }

  /**
   * Returns new queries, updated based on the data from the slicing form
   * @param {Record<string, string>} input The input data from the slicing form
   * @param {Record<string, SlicingClause>} slicing The slicing clauses
   */
  async handleRefresh(input: Record<string, string>, slicing: Record<string, SlicingClause>) {
    if (!this.loadedQueryTemplates) {
      await this.loadQueryTemplates();
    }

    if (!this.loadedFilterTemplates) {
      await this.loadFilterTemplates(input, slicing);
    }

    return this.renderQueriesTemplates(input, slicing);
  }
}

export default FilterManager;
