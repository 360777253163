import moment from 'moment';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import Row from 'react-bootstrap/Row';
import { Link, useRouteMatch } from 'react-router-dom';
import { getBasePath } from '../models';

export const SessionDisplay = ({ session }) => {
  const match = useRouteMatch();
  const link = `${getBasePath(match.path)}/session/${session.session_id}`;
  return (
    <Card className="mb-4 border-left-info shadow">
      <Card.Body>
        <Card.Title className="text-info">{session.session_id}</Card.Title>
        <Card.Subtitle>
          <Row>
            <Col>Date: {moment(Number(session.session_startDate)).format('YYYY-MM-DD HH:mm:ss (Z)')}</Col>
            <Col>Sessions since install: {session.session_number || 0}</Col>
          </Row>
        </Card.Subtitle>
        <Row className="d-flex justify-content-end">
          <Link to={link}>
            <Button>Load Session</Button>
          </Link>
        </Row>
      </Card.Body>
      <ListGroup className="list-group-flush">
        <ListGroupItem className="small font-weight-bold">
          Device: {session.manufacturer || 'Freshplanet'} {session.device_model || 'Fresh Phone'} (
          {session.os_name || 'FreshOS'} {session.os_version})
        </ListGroupItem>
        <ListGroupItem className="small font-weight-bold">
          Version: {session.app_version || '0.0.0'} ({session.build_number || 0})
        </ListGroupItem>
        <ListGroupItem className="small font-weight-bold">Install ID: {session.install_id || 'mockID'}</ListGroupItem>
      </ListGroup>
    </Card>
  );
};
