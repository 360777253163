import { useMemo } from 'react';
import colors from '../../../models/widgets/colors.json';
import { widgetDefaultProps, widgetPropTypes } from '../widgetPropTypes';
import BaseChart from './BaseChart';

// round label to 3 decimals
const labelFn = (tooltipItem, data) => {
  let label = data.datasets[tooltipItem.datasetIndex].label ?? '';

  if (label) {
    label += ': ';
  }
  label += Math.round(tooltipItem.yLabel * 1000) / 1000;
  return label;
};

const percentageScale = {
  scales: {
    yAxes: [
      {
        stacked: true,
        ticks: {
          beginAtZero: true,
          max: 1,
          callback: function cb(value) {
            return `${value * 100}%`;
          },
        },
      },
    ],
  },
};

const StackedAreaChart = ({ id, height, width, data, options, title, percentage }) => {
  const datasets = useMemo(() => {
    // const sumData = SumDatasets(data.data);
    const sumData = data.data;
    return Object.keys(sumData)
      .filter((label) => label !== data.indexColLabel)
      .map((label, index) => {
        return {
          data: sumData[label],
          label,
          borderColor: colors[index],
          backgroundColor: `${colors[index]}47`, // Add transparency for background
          fill: index > 0 ? '-1' : 'origin', // Fills the background down to the previous line (or the origin for the first line),
        };
      });
  }, [data.data, data.indexColLabel]);

  options = {
    tooltips: {
      mode: 'index',
      enabled: true,
      intersect: false,
      callbacks: {
        label: labelFn,
      },
    },
    ...options,
    ...(percentage
      ? percentageScale
      : {
          scales: { yAxes: [{ stacked: true }] },
        }),
  };

  return (
    <BaseChart
      id={id}
      type="line"
      height={height}
      width={width}
      options={options}
      labels={data.getLabelColumn()}
      datasets={datasets}
      title={title}
    />
  );
};

StackedAreaChart.propTypes = widgetPropTypes;
StackedAreaChart.defaultProps = widgetDefaultProps;

export default StackedAreaChart;
