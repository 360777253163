import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { RouteChildrenProps } from 'react-router-dom';
import {
  ContentPackAvailabilityType,
  CreateCurationContentPackNewDraftInput,
  CreateCurationContentPackNewDraftMutation,
  CreateCurationContentPackNewDraftMutationVariables,
  CurationSongDdexSearchQuery,
} from '../../../../../__gqltypes__';
import Loader from '../../components/Loader';
import SongList from '../../components/SongList';
import CommitPlaylistPopup from '../../popups/CommitPlaylistPopup';
import UpdatePlaylistPopup from '../../popups/UpdatePlaylistPopup';
import { AddPlaylistValues } from '../../popups/utils';
import { downloadSongsCsv } from '../../utils/csvExport';
import mutations from '../../utils/mutations';
import queries from '../../utils/queries';
import CategoryFields from './components/CategoryFields';
import ForcedFields from './components/ForcedFields';
import PlaylistContentPackDetails from './components/PlaylistContentPackDetails';
import PlaylistDetails from './components/PlaylistDetails';
import PlaylistHeader from './components/PlaylistHeader';
import PlaylistLocale from './components/PlaylistLocale';
import PlaylistPicture from './components/PlaylistPicture';
import PlaylistVersion from './components/PlaylistVersion';
import ReplacingPlaylist from './components/ReplacingPlaylist';
import SP2Info from './components/SP2Info';
import './PlaylistInfo.css';
import { InputType } from './utils';

type DDEXSongs = CurationSongDdexSearchQuery['curationSongDDEXSearch'];

type Props = {
  match: { params: { originid: string; version: string } };
  history: RouteChildrenProps['history'];
  location: { pathname: string; search: string; hash: string; state: any; key: string };
};

const PlaylistInfo = ({ match, history, location }: Props) => {
  const [modalUpdateShow, setModalUpdateShow] = useState(false);
  const [modalCommitShow, setModalCommitShow] = useState(false);
  const [downloadingCSV, setDownloadingCSV] = useState(false);

  const client = useApolloClient();

  const selectedSongId = new URLSearchParams(location.search)?.get('songId');

  // use to get the versionId from originId and version (if any)
  const { loading: firstLoading, data: firstData } = useQuery(queries.CURATION_CONTENT_PACK_BY_ORIGIN_ID, {
    variables: {
      originId: match.params.originid,
      version: match.params.version ? parseInt(match.params.version, 10) : null,
    },
    fetchPolicy: 'network-only',
  });

  const curationContentPackId = firstData?.curationContentPack?.id;

  const { loading, data, refetch } = useQuery(queries.CURATION_CONTENT_PACK, {
    variables: {
      id: curationContentPackId,
    },
    skip: firstLoading,
  });
  const [deletePlaylist, { loading: deletingLoading }] = useMutation(mutations.DELETE_CURATION_CONTENT_PACK);
  const [removeBatchSongs] = useMutation(mutations.REMOVE_SONGS_FROM_PLAYLIST);
  const [addDDEXSongs, { loading: addingDDEXLoading }] = useMutation(mutations.ADD_DDEX_SONGS_TO_CONTENT_PACK);

  const [updatePlaylist, { loading: updatingLoading }] = useMutation<
    CreateCurationContentPackNewDraftMutation,
    CreateCurationContentPackNewDraftMutationVariables
  >(mutations.CREATE_CURATION_CONTENT_PACK_NEW_DRAFT);
  const [commitPlaylist, { loading: committingLoading }] = useMutation(mutations.COMMIT_CURATION_CONTENT_PACK);

  const { curationContentPack } = data || { curationContentPack: null };
  const handleCommitPlaylist = async ({
    availabilityType = undefined,
    availabilityDate = undefined,
  }: {
    availabilityDate?: Date;
    availabilityType?: ContentPackAvailabilityType;
  }) => {
    const { id } = curationContentPack;
    modalCommitClose();
    await commitPlaylist({ variables: { input: { id, availabilityDate, availabilityType } } });
    refetch();
  };

  const handleUpdatePlaylist = (updateType: keyof typeof InputType, updateData: AddPlaylistValues) => {
    console.log('updating playlist', updateType, updateData);
    const { id } = curationContentPack;

    const input: CreateCurationContentPackNewDraftInput = { id };

    if (updateType === InputType.CSV && updateData && 'file' in updateData) {
      input.file = updateData.file;
    } else if (updateType === InputType.SPOTIFY && updateData && 'spotifyURI' in updateData) {
      input.spotifyURI = updateData.spotifyURI;
    } else if (updateType === InputType.SP2 && updateData && 'sp2PlaylistId' in updateData) {
      input.sp2PlaylistId = updateData.sp2PlaylistId;
    } else if (updateType === InputType.SPOTIFY_ARTISTS && updateData && 'artistIds' in updateData) {
      input.spotifyArtistsIds = updateData.artistIds;
      input.includeExtras = updateData.includeExtras;
    }

    updatePlaylist({ variables: { input } }).then(async (res) => {
      const { version } = res.data?.createCurationContentPackNewDraft.curationContentPack ?? {
        version: 1,
      };
      if (!id) return;
      history.push(`/sp3/playlist/${match.params.originid}/${version}`);

      await refetch();
    });

    modalClose();
  };

  const handleDeletePlaylist = () => {
    const { id, sp2ExportId } = curationContentPack;
    deletePlaylist({ variables: { input: { id, sp2ExportId } } });
  };

  const showUpdatePlaylist = () => setModalUpdateShow(true);

  const showCommitPlaylist = () => {
    if (curationContentPack.version > 1) {
      console.log('version > 1');
      handleCommitPlaylist({});
    } else {
      setModalCommitShow(true);
    }
  };

  const goBack = () => {
    // if previous history is /sp3/playlist/ (!== POP)
    // otherwise we push it to history and then we go back
    if (history.action === 'POP') {
      history.push(`/sp3/playlist/`);
    } else {
      history.goBack();
    }
  };

  const onDownloadCSV = async () => {
    setDownloadingCSV(true);
    try {
      const _data = await client.query({
        query: queries.CURATION_CONTENT_PACK_WITH_EXTERNAL_LINKS,
        variables: {
          id: curationContentPackId,
        },
        fetchPolicy: 'network-only',
      });
      const _curationContentPack = _data.data.curationContentPack;
      console.log('_curationContentPack', _curationContentPack);
      console.log('data', _data);
      downloadSongsCsv(_curationContentPack);
      setDownloadingCSV(false);
    } catch (e) {
      setDownloadingCSV(false);
    }
  };

  const onRemoveBatchSongs = async (songInputIds: string[]) => {
    const hasConfirmedAction = window.confirm(
      `Do you want to remove ${songInputIds.length} songs from ${curationContentPack.name}?`
    );
    // ask first, if yes mutation
    if (hasConfirmedAction) {
      removeBatchSongs({
        variables: {
          id: curationContentPack.id,
          songInputIds,
        },
      });
    }
  };

  const handleAddDDEXSong = (ddexSongs: DDEXSongs) => {
    const input = { id: curationContentPack.id, ddexSongIds: ddexSongs.map((song) => song.id) };
    console.log(input);
    addDDEXSongs({
      variables: { input },
    }).then(async (res) => {
      const { id: playlistId } = res.data.addDDEXSongToCurationContentPack.curationContentPack;
      await refetch();
    });
  };

  const modalClose = () => {
    setModalUpdateShow(false);
  };
  const modalCommitClose = () => {
    setModalCommitShow(false);
  };

  const updating = updatingLoading || addingDDEXLoading || deletingLoading || committingLoading;

  if (loading || !data) {
    return <Loader />;
  }

  return (
    <div>
      <Row className="justify-content-between mb-2">
        <span className="back-button text-primary" onClick={goBack} role="button" tabIndex={0}>
          ← back
        </span>
        <Form inline>
          {curationContentPack.state !== 'DELETED' && curationContentPack.state !== 'PROCESSING' && (
            <Button
              variant="outline-danger"
              disabled={updating}
              className="m-1"
              size="sm"
              onClick={handleDeletePlaylist}
            >
              <i className="fas fa-trash mr-2" />
              {deletingLoading ? 'Deleting...' : 'Delete'}
            </Button>
          )}
          {curationContentPack.state !== 'PROCESSING' && (
            <Button variant="success" disabled={updating} className="m-1" onClick={showUpdatePlaylist}>
              <i className="fas fa-wrench mr-2" />
              {updatingLoading ? 'Updating...' : 'Update'}
            </Button>
          )}
        </Form>
      </Row>
      <Row>
        <Col xl={4} className="mb-2">
          <PlaylistPicture curationContentPack={curationContentPack} />
          <PlaylistDetails curationContentPack={curationContentPack} />
          {curationContentPack.contentPack && (
            <PlaylistContentPackDetails contentPack={curationContentPack.contentPack} />
          )}

          <Card className="mt-2">
            <Card.Body>
              <ForcedFields curationContentPack={curationContentPack} />
              <CategoryFields curationContentPack={curationContentPack} />
              <PlaylistLocale curationContentPack={curationContentPack} />
            </Card.Body>
          </Card>
          <ReplacingPlaylist curationContentPack={curationContentPack} />
          <PlaylistVersion curationContentPack={curationContentPack} />
          <SP2Info curationContentPack={curationContentPack} />
        </Col>
        <Col>
          <PlaylistHeader
            curationContentPack={curationContentPack}
            updating={updating}
            onCommitPlaylist={showCommitPlaylist}
            committingLoading={committingLoading}
          />
          <SongList
            addingDDEXSongs={addingDDEXLoading}
            curationContentPack={curationContentPack}
            downloadingCSV={downloadingCSV}
            onDownloadCSV={onDownloadCSV}
            onRemoveBatchSongs={onRemoveBatchSongs}
            onAddDDEXSongs={handleAddDDEXSong}
            selectedSongId={selectedSongId}
          />
        </Col>
      </Row>
      <UpdatePlaylistPopup
        show={modalUpdateShow}
        onHide={modalClose}
        onUpdatePlaylist={handleUpdatePlaylist}
        externalURI={curationContentPack ? curationContentPack.externalURI : null}
      />
      <CommitPlaylistPopup
        show={modalCommitShow}
        onHide={modalCommitClose}
        handleCommitPlaylist={handleCommitPlaylist}
      />
    </div>
  );
};

export default PlaylistInfo;
