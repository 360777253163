import PropTypes from 'prop-types';

export const slicerPropTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  title: PropTypes.string,
};

export const selectorSlicerPropTypes = {
  ...slicerPropTypes,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  disableAll: PropTypes.bool,
};

export const defaultSlicingPropTypes = {
  defaultStartDate: PropTypes.string.isRequired,
  defaultEndDate: PropTypes.string.isRequired,
};
