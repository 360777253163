import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Button, { ButtonProps } from 'react-bootstrap/Button';

type Props = {
  disabledReason?: string | null;
} & ButtonProps;

export default function TooltipButton(props: Props) {
  const { children, disabledReason, ...rest } = props;
  const { disabled } = rest;
  if (disabled && disabledReason) {
    return (
      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{disabledReason}</Tooltip>}>
        <span className="d-inline-block">
          <Button {...rest} style={{ pointerEvents: 'none' }}>
            {children}
          </Button>
        </span>
      </OverlayTrigger>
    );
  }

  return <Button {...rest}>{children}</Button>;
}
