import { gql } from '@apollo/client';

export const CHAINED_OFFER_CONFIG_FRAGMENT = gql`
  fragment ChainedOfferConfigFragment on ChainedOfferEventConfig {
    id
    startDate
    stopDate
    packs {
      rewards {
        type
        amount
        itemId
      }
      cost
      sku
      claimType
    }
    status
    name
    backgroundImage

    filterUserIds
    filterSubscriptionTier
    filterUserMinCreationDate
    filterUserMaxCreationDate
    filterAppVersion
    filterUserLevel
  }
`;

export const LIVEOPS_CHAINED_OFFER_EVENT_CONFIG_QUERY = gql`
  query getLiveOpsChainedOfferEventConfig($id: ID!) {
    liveOps {
      chainedOfferEvent(id: $id) {
        ...ChainedOfferConfigFragment
      }
    }
  }
  ${CHAINED_OFFER_CONFIG_FRAGMENT}
`;

export const LIVEOPS_CHAINED_OFFER_EVENT_CONFIGS_QUERY = gql`
  query getLiveOpsChainedOfferEventConfigs {
    liveOps {
      chainedOfferEvents {
        ...ChainedOfferConfigFragment
      }
    }
  }
  ${CHAINED_OFFER_CONFIG_FRAGMENT}
`;

export const CREATE_CHAINED_OFFER_EVENT = gql`
  mutation createChainedOfferEvent($input: CreateChainedOfferEventInput) {
    createChainedOfferEvent(input: $input) {
      chainedOfferEvent {
        ...ChainedOfferConfigFragment
      }
    }
  }
  ${CHAINED_OFFER_CONFIG_FRAGMENT}
`;

export const UPDATE_CHAINED_OFFER_EVENT = gql`
  mutation updateChainedOfferEvent($input: UpdateChainedOfferEventInput) {
    updateChainedOfferEvent(input: $input) {
      chainedOfferEvent {
        ...ChainedOfferConfigFragment
      }
    }
  }
  ${CHAINED_OFFER_CONFIG_FRAGMENT}
`;

export const DELETE_CHAINED_OFFER_EVENT = gql`
  mutation deleteChainedOfferEvent($input: DeleteChainedOfferEventInput) {
    deleteChainedOfferEvent(input: $input) {
      deletedEventId
    }
  }
`;

export const UPLOAD_CHAINED_OFFER_EVENT_ASSET = gql`
  mutation uploadChainedOfferEventAsset($input: UploadChainedOfferEventAssetInput) {
    uploadChainedOfferEventAsset(input: $input) {
      ...ChainedOfferConfigFragment
    }
  }
  ${CHAINED_OFFER_CONFIG_FRAGMENT}
`;
