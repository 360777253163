import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';

type Props = {
  buttonTitle?: string;
  buttonAllTitle?: string;
  title: string;
  options: Record<string, { value: string; searchValue: string }>;
  onAdd: (value: string) => void;
  onAddAll?: () => void;
  canAll?: boolean;
};

export default function SelectAdd({
  buttonTitle,
  buttonAllTitle,
  title,
  options,
  onAdd: onAddProps,
  onAddAll: onAddAllProps,
  canAll = false,
}: Props) {
  const [adding, setAdding] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [search, setSearch] = useState('');

  const onSelect = (value: string) => setSelectedOption(value);

  // @ts-ignore
  const onSearch = (event) => setSearch(event.target.value);

  const optionsToDisplay = Object.keys(options).filter((key) =>
    options[key].searchValue.toLowerCase().match(new RegExp(search.toLowerCase()))
  );

  const onAdd = () => {
    if (selectedOption) {
      onAddProps(selectedOption);
      setSelectedOption(null);
      setSearch('');
      setAdding(false);
    }
  };

  const onAddAll = () => {
    if (onAddAllProps) {
      onAddAllProps();
    }
    setSelectedOption(null);
    setSearch('');
    setAdding(false);
  };

  return (
    <div className="d-flex justify-content-end align-items-baseline mb-3">
      {adding ? (
        <>
          {/* @ts-ignore */}
          <Dropdown onSelect={onSelect}>
            <Dropdown.Toggle variant="light" style={{ width: 300, textAlign: 'left' }}>
              {selectedOption ? options[selectedOption].value : title}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.ItemText style={{ width: 300 }}>
                <Form.Control placeholder="Search" onChange={onSearch} />
              </Dropdown.ItemText>
              <Dropdown.Divider />
              {optionsToDisplay.map((key, index) => (
                <Dropdown.Item key={index} eventKey={key}>
                  {options[key].value}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          <Button className="ml-3" variant="success" onClick={onAdd}>
            Add
          </Button>

          {canAll && (
            <Button className="ml-3" onClick={onAddAll}>
              {buttonAllTitle}
            </Button>
          )}
        </>
      ) : (
        <Button onClick={() => setAdding(true)}>{buttonTitle}</Button>
      )}
    </div>
  );
}
