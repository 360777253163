import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

type Props = {
  onClose: () => void;
  title: string;
  size: 'sm' | 'lg' | 'xl' | null;
  children: React.ReactChild[];
  buttons: (React.ReactChild & { key: string; variant: string; onClick: () => void; title: string })[];
  show: boolean;
};

const CustomModal = ({ onClose, title, children, size, buttons, show }: Props) => (
  <Modal size={size || 'lg'} onHide={onClose} show={show}>
    {title && (
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
    )}
    <Modal.Body>{children}</Modal.Body>
    {buttons && (
      <Modal.Footer>
        {buttons.map((button) => (
          <Button key={button.key} variant={button.variant} onClick={button.onClick}>
            {button.title}
          </Button>
        ))}
      </Modal.Footer>
    )}
  </Modal>
);

export default CustomModal;
