import moment from 'moment';
import { useState } from 'react';
import { Button, Dropdown, DropdownButton, Modal, Row } from 'react-bootstrap';
import DateTimePicker from 'react-datetime';
import { applyChangeIfDateValid } from '../../../../utils/datepicker';
import { ContentPackAvailabilityType } from '../../../../__gqltypes__';

type Props = {
  show: boolean;
  onHide: () => void;
  handleCommitPlaylist: ({
    availabilityDate,
    availabilityType,
  }: {
    availabilityDate: Date;
    availabilityType: ContentPackAvailabilityType;
  }) => Promise<void>;
};

const CommitPlaylistPopup = ({ handleCommitPlaylist, onHide, show }: Props) => {
  const [availabilityType, setAvailabilityType] = useState<ContentPackAvailabilityType>(
    ContentPackAvailabilityType.ALL
  );
  const [availabilityDate, setAvailabilityDate] = useState<Date>(
    moment().minutes(0).seconds(0).milliseconds(0).toDate()
  );

  return (
    <Modal show={show} onHide={onHide} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title>Last step</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Row className="mx-0">
            {'Availability Type: '}

            <DropdownButton
              size="sm"
              className="mx-2"
              defaultValue={availabilityType}
              title={availabilityType}
              onSelect={(value) => {
                if (value) {
                  setAvailabilityType(value as ContentPackAvailabilityType);
                }
              }}
            >
              {Object.keys(ContentPackAvailabilityType).map((type) => (
                <Dropdown.Item eventKey={type} key={`dropdown-type-${type}`} active={availabilityType === type}>
                  {type}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </Row>
        </div>
        <br />
        <div>
          <Row className="mx-0">
            Availability Date:{' '}
            <DateTimePicker
              dateFormat="YYYY-MM-DD"
              className="ml-2"
              onChange={applyChangeIfDateValid(setAvailabilityDate)}
              initialValue={availabilityDate}
              timeConstraints={{ minutes: { min: 0, max: 0, step: 0 }, seconds: { min: 0, max: 0, step: 0 } }}
            />
          </Row>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={() => handleCommitPlaylist({ availabilityType, availabilityDate })}>
          Confirm Commit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CommitPlaylistPopup;
