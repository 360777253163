import moment from 'moment';
import { MutableRefObject } from 'react';

type DateSelectionHandler = (selection: { start: Date; end: Date }) => void;

export class SliderController {
  canvas: MutableRefObject<HTMLCanvasElement | null>;

  canvasX = 0;

  initialX: number | null = null;

  currentX: number | null = null;

  paddingLeft = 0;

  currentTimeBoundaries: { start: string; end: string };

  onSelectionChange: DateSelectionHandler;

  constructor({
    canvas,
    currentTimeBoundaries,
    onSelectionChange,
  }: {
    canvas: MutableRefObject<HTMLCanvasElement | null>;
    currentTimeBoundaries: { start: string; end: string };
    onSelectionChange: DateSelectionHandler;
  }) {
    this.canvas = canvas;
    this.currentTimeBoundaries = currentTimeBoundaries;
    this.onSelectionChange = onSelectionChange;
    window?.addEventListener('resize', this.init);
    this.init();
  }

  init() {
    if (!this.canvas?.current) return;
    const rect = this.canvas.current.getBoundingClientRect();
    this.canvas.current.width = this.canvas?.current?.parentElement?.clientWidth ?? 100;
    this.canvas.current.height = 50;
    this.canvasX = rect.x;

    this.drawLayout();

    this.canvas.current.addEventListener('mousedown', this.onMouseDown);
    this.canvas.current.addEventListener('mousemove', this.onMouseMove);
    this.canvas.current.addEventListener('mouseup', this.onMouseUp);
  }

  computeSelectionDates() {
    if (!this.canvas?.current) return null;
    let startProportion = 0;
    let endProportion = 1;
    if (this.initialX && this.currentX) {
      const startX = Math.min(this.initialX, this.currentX);
      const endX = Math.max(this.initialX, this.currentX);
      startProportion = startX / (this.canvas.current.width - this.paddingLeft);
      endProportion = endX / (this.canvas.current.width - this.paddingLeft);
    }

    // Selection bar
    const diff = moment(this.currentTimeBoundaries.end).diff(this.currentTimeBoundaries.start);
    const startSelectionDate = moment(this.currentTimeBoundaries.start).add(diff * startProportion);
    const endSelectionDate = moment(this.currentTimeBoundaries.start).add(diff * endProportion);

    return {
      start: startSelectionDate,
      end: endSelectionDate,
      proportion: endProportion - startProportion,
    };
  }

  drawLayout() {
    if (!this.canvas?.current) return;
    const ctx = this.canvas.current.getContext('2d');
    if (!ctx) return;

    ctx.clearRect(0, 0, this.canvas.current.width, 40);

    ctx.fillStyle = '#f5f5f5';
    ctx.fillRect(this.paddingLeft, 0, this.canvas.current.width - this.paddingLeft, 40);

    ctx.fillStyle = '#cddaff';
    ctx.fillRect(this.paddingLeft, 15, this.canvas.current.width - this.paddingLeft, 10);

    if (this.initialX && this.currentX) {
      ctx.fillStyle = '#4e73df';
      const startX = Math.min(this.initialX, this.currentX);
      const endX = Math.max(this.initialX, this.currentX);
      ctx.fillRect(startX, 15, endX - startX, 10);
      ctx.beginPath();
      ctx.arc(startX, 20, 7, 0, 2 * Math.PI);
      ctx.arc(endX, 20, 7, 0, 2 * Math.PI);
      ctx.closePath();
      ctx.fill();
    }

    const selection = this.computeSelectionDates();
    if (!selection) return;

    // Legend : dates of selection
    ctx.fillStyle = '#000';
    ctx.fillText(selection.start.format('YY-MM-DD HH:00'), 0, 12, this.paddingLeft - 5);
    ctx.fillText('-', this.paddingLeft / 2, 22, this.paddingLeft - 5);
    ctx.fillText(selection.end.format('YY-MM-DD HH:00'), 0, 32, this.paddingLeft - 5);
  }

  adjustPaddings(paddingLeft: number) {
    this.paddingLeft = paddingLeft;
    this.destroy();
    this.init();
  }

  onMouseDown = (event: MouseEvent) => {
    if (event.clientX - this.canvasX > this.paddingLeft) this.initialX = event.clientX - this.canvasX;
  };

  onMouseMove = (event: MouseEvent) => {
    if (this.initialX !== null && event.clientX - this.canvasX > this.paddingLeft) {
      this.currentX = event.clientX - this.canvasX;
      this.drawLayout();
    }
  };

  onMouseUp = (event: MouseEvent) => {
    if (this.initialX !== null) {
      const selection = this.computeSelectionDates();
      if (selection) {
        if (selection.proportion > 0.05) {
          this.onSelectionChange({
            start: selection.start.toDate(),
            end: selection.end.toDate(),
          });
          this.initialX = null;
          this.currentX = null;
          return;
        }
      }

      this.onSelectionChange({
        start: moment(this.currentTimeBoundaries.start).toDate(),
        end: moment(this.currentTimeBoundaries.end).toDate(),
      });
      this.initialX = null;
      this.currentX = null;
      this.drawLayout();
    }
  };

  onResize = () => {
    if (!this.canvas?.current) return;
    console.log(this.canvas.current.parentElement?.clientWidth);
  };

  destroy() {
    if (!this.canvas?.current) return;
    this.canvas.current.removeEventListener('pointerdown', this.onMouseDown);
    this.canvas.current.removeEventListener('pointermove', this.onMouseMove);
    this.canvas.current.removeEventListener('pointerup', this.onMouseUp);
    window?.addEventListener('resize', this.onResize);
  }
}
