import React from 'react';
import Badge from 'react-bootstrap/Badge';

import { ContentPackExplicitContentStatus } from '../../../../../__gqltypes__';

const SYMBOL = {
  [ContentPackExplicitContentStatus.EXPLICIT]: 'E',
  [ContentPackExplicitContentStatus.CAN_BE_FILTERED]: 'F',
  [ContentPackExplicitContentStatus.CLEAN]: 'C',
};
const VARIANT = {
  [ContentPackExplicitContentStatus.EXPLICIT]: 'danger',
  [ContentPackExplicitContentStatus.CAN_BE_FILTERED]: 'info',
  [ContentPackExplicitContentStatus.CLEAN]: 'success',
};

type Props = {
  explicitStatus: ContentPackExplicitContentStatus | null;
  hideClean?: boolean;
};

const ContentPackExplicitBadge = ({ explicitStatus, hideClean }: Props) => {
  if (explicitStatus == null || (hideClean && explicitStatus === 'CLEAN')) {
    return null;
  }
  const formattedStatus = explicitStatus.charAt(0) + explicitStatus.substring(1).toLowerCase().replaceAll('_', ' ');
  return (
    <span className="ml-2 user-select-none" title={formattedStatus}>
      <Badge variant={VARIANT[explicitStatus] ?? 'warning'}>{SYMBOL[explicitStatus] ?? '?'}</Badge>
    </span>
  );
};

export default ContentPackExplicitBadge;
