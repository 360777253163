import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

type Props = {
  active: number;
  num: number;
  onClick: (value: number) => void;
};

export default function CustomPagination({ active, num, onClick }: Props) {
  const handlePrev = () => {
    if (active === 1) return;
    onClick(active - 1);
  };
  const handleNext = () => {
    if (active === num) return;
    onClick(active + 1);
  };

  if (num <= 1) return null;

  const items = [];
  for (let i = active - 2; i <= active + 2; i++) {
    if (i > 1 && i < num) {
      items.push(
        <Pagination.Item key={i} active={i === active} onClick={() => onClick(i)}>
          {i}
        </Pagination.Item>
      );
    }
  }

  return (
    <Pagination>
      <Pagination.First onClick={() => onClick(1)} />
      <Pagination.Prev onClick={handlePrev} />
      <Pagination.Item key={1} active={active === 1} onClick={() => onClick(1)}>
        1
      </Pagination.Item>
      {active - 2 > 2 && <Pagination.Ellipsis />}
      {items}
      {active + 2 < num - 1 && <Pagination.Ellipsis />}
      <Pagination.Item key={num} active={num === active} onClick={() => onClick(num)}>
        {num}
      </Pagination.Item>
      <Pagination.Next onClick={handleNext} />
      <Pagination.Last onClick={() => onClick(num)} />
    </Pagination>
  );
}
