import { useMemo } from 'react';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import { AppInformationQuery } from '../../../../../../__gqltypes__';
import EditableParam from './EditableParam';

type Props = {
  fragment: { id: string; amount: number; category: { id: string } };
  title: string;
  onChange: (newFragment: { id: string; amount: number }, isNew: boolean) => void;
  contentCategories: AppInformationQuery['app']['contentCategories'];
};

export default function MusicFragmentEditor({ fragment, title, onChange, contentCategories }: Props) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const amount = useMemo(() => fragment.amount, []);

  // @ts-ignore
  const handleChangeFragment = (key: string) => (event) => {
    const { value } = event.target;
    if (onChange) {
      onChange({ ...fragment, [key]: value }, false);
    }
  };

  const category = contentCategories.find((c) => c.id === fragment.category.id);
  if (!category) return null;
  return (
    <Card>
      <Card.Header>
        <Image roundedCircle className="img-profile mr-3" src={category.icon} style={{ width: 30, height: 30 }} />
        {category.name}
      </Card.Header>
      <Card.Body>
        <Form.Label>{title}</Form.Label>
        <EditableParam
          key="amount"
          title="amount"
          value={fragment.amount ?? undefined}
          onChange={handleChangeFragment('amount')}
          placeholder={`${amount}`}
        />
      </Card.Body>
    </Card>
  );
}
