import { useQuery } from '@apollo/client';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Form, ListGroup, Modal, Spinner, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import {
  ContentCategoriesQuery,
  ContentPackAvailabilityType,
  ContentSearchType,
  SearchContentPacksQuery,
  SearchContentPacksQueryVariables,
} from '../../../../../__gqltypes__';
import { GET_CONTENT_CATEGORIES } from '../../../liveops/components/apolloQueries';
import Pagination from '../../components/Pagination';
import queries from '../../utils/queries';
import ContentPackExplicitBadge from './ContentPackExplicitBadge';

type ContentPack = SearchContentPacksQuery['searchContentPacks']['list'][0];

type Props = {
  title?: string;
  add: (pack: Omit<ContentPack, '__typename'>) => void;
  remove?: (id: string) => void;
  curPacks: Omit<ContentPack, '__typename'>[];
  defaultSearchType?: ContentSearchType;
  defaultAvailabilityType?: ContentPackAvailabilityType;
};

const searchDefaultLimit = 20;
const searchByNameLimit = 30;

const AddContentPack = ({
  title = 'Add content packs to list',
  add,
  remove,
  curPacks,
  defaultSearchType = ContentSearchType.BY_CATEGORY,
  defaultAvailabilityType = ContentPackAvailabilityType.ALL,
}: Props) => {
  const [adding, setAdding] = useState(false);
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false);
  const [page, setPage] = useState(1);
  const askAdd = useCallback(() => setAdding(true), [setAdding]);
  const cancelAdd = useCallback(() => setAdding(false), [setAdding]);

  const curPackSet = useMemo(() => new Set(curPacks.map((pack) => pack.id)), [curPacks]);

  const [searchMode, setSearchMode] = useState<ContentSearchType>(defaultSearchType);

  const { data: categoryData } = useQuery<ContentCategoriesQuery>(GET_CONTENT_CATEGORIES, {
    skip: !adding || searchMode !== ContentSearchType.BY_CATEGORY,
  });
  const categories = useMemo(() => categoryData?.app?.contentCategories, [categoryData]);
  const [curCategory, setCategory] = useState('');
  const [curAvailabilityType, setAvailabilityType] = useState<ContentPackAvailabilityType | string>(
    defaultAvailabilityType
  );
  const [nameSearch, setNameSearch] = useState('');

  const debouncedSearch = useMemo(() => _.debounce(setNameSearch, 500), [setNameSearch]);
  const [nameSearchField, setNameSearchField] = useState('');
  useEffect(() => {
    debouncedSearch(nameSearchField);
  }, [debouncedSearch, nameSearchField]);
  const {
    data: packData,
    loading: fetchLoading,
    refetch,
  } = useQuery<SearchContentPacksQuery, SearchContentPacksQueryVariables>(queries.SEARCH_CONTENT_PACKS, {
    fetchPolicy: 'no-cache',
    skip:
      (searchMode === ContentSearchType.BY_CATEGORY && curCategory === '') ||
      (searchMode === ContentSearchType.BY_NAME && nameSearch === ''),
    variables: {
      offset: 0,
      limit: searchMode === ContentSearchType.BY_NAME ? searchByNameLimit : searchDefaultLimit,

      search:
        searchMode === ContentSearchType.BY_CATEGORY
          ? curCategory
          : searchMode === ContentSearchType.BY_NAME
          ? nameSearch
          : curAvailabilityType,
      searchType:
        searchMode === ContentSearchType.BY_CATEGORY
          ? ContentSearchType.BY_CATEGORY
          : searchMode === ContentSearchType.BY_NAME
          ? ContentSearchType.BY_NAME
          : ContentSearchType.BY_AVAILABILITY_TYPE,
    },
  });

  useEffect(() => {
    if (adding) {
      setSearchMode(defaultSearchType);
      setCategory('');
    }
  }, [adding]);
  const packs = useMemo(() => {
    if (!packData) {
      return null;
    }

    return packData.searchContentPacks.list;
  }, [packData]);

  const onClickPagination = (index: number) => {
    setFetchMoreLoading(true);

    const offset = (index - 1) * searchDefaultLimit;
    refetch({ offset }).then(() => setFetchMoreLoading(false));

    setPage(index);
  };

  const handleSearchMode = (value: ContentSearchType) => {
    setSearchMode(value);
    setPage(1);
  };

  const loading = fetchLoading || fetchMoreLoading;

  const maxPage = packData?.searchContentPacks
    ? packData?.searchContentPacks.totalCount % searchDefaultLimit === 0
      ? Math.floor(packData?.searchContentPacks.totalCount / searchDefaultLimit)
      : Math.floor(packData?.searchContentPacks.totalCount / searchDefaultLimit) + 1
    : 1;

  return (
    <>
      <Button variant="success" onClick={askAdd}>
        <i className="fas fa-plus mr-2" />
        {title}
      </Button>

      <Modal show={adding} onHide={cancelAdd} className="cp-list">
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label className="mr-3">Search by</Form.Label>
            <ToggleButtonGroup type="radio" name="searchMode" value={searchMode} onChange={handleSearchMode} size="sm">
              <ToggleButton name={ContentSearchType.BY_CATEGORY} value={ContentSearchType.BY_CATEGORY}>
                Category
              </ToggleButton>
              <ToggleButton name={ContentSearchType.BY_NAME} value={ContentSearchType.BY_NAME}>
                Name
              </ToggleButton>
              <ToggleButton
                name={ContentSearchType.BY_AVAILABILITY_TYPE}
                value={ContentSearchType.BY_AVAILABILITY_TYPE}
              >
                Availability
              </ToggleButton>
            </ToggleButtonGroup>
          </Form.Group>
          <Form.Group>
            {searchMode === ContentSearchType.BY_CATEGORY && (
              <Form.Control
                as="select"
                value={curCategory}
                onChange={(e) => {
                  setPage(1);
                  setCategory(e.target.value);
                }}
              >
                <option value="">Choose a category</option>
                {categories &&
                  categories.map((cat) => (
                    <option key={cat.id} value={cat.id}>
                      {cat.name}
                    </option>
                  ))}
              </Form.Control>
            )}
            {searchMode === ContentSearchType.BY_NAME && (
              <>
                <Form.Control
                  placeholder="Filter packs by name..."
                  className="my-1"
                  value={nameSearchField}
                  onChange={(e) => setNameSearchField(e.target.value)}
                />
                {packData?.searchContentPacks.hasMore && (
                  <ListGroup.Item variant="dark" className="py-1 px-2">
                    there is at least {searchDefaultLimit} results
                  </ListGroup.Item>
                )}
              </>
            )}
            {searchMode === ContentSearchType.BY_AVAILABILITY_TYPE && (
              <Form.Control
                as="select"
                value={curAvailabilityType}
                onChange={(e) => {
                  setPage(1);
                  setAvailabilityType(e.target.value);
                }}
              >
                <option value="">Select an availability type</option>
                {Object.keys(ContentPackAvailabilityType).map((availabilityType) => (
                  <option key={availabilityType} value={availabilityType}>
                    {availabilityType}
                  </option>
                ))}
              </Form.Control>
            )}
          </Form.Group>
          <Form.Group>
            {loading ? (
              <div className="p-3">
                <Spinner animation="border" />
              </div>
            ) : (
              <>
                <Form.Label className="d-flex justify-content-between">
                  <div>Content packs</div>
                  <div>(Popularity)</div>
                </Form.Label>
                <ListGroup>
                  {packs && packs.length > 0 ? (
                    packs.map((pack) => {
                      const alreadyAdded = curPackSet.has(pack.id);
                      return (
                        <ListGroup.Item key={pack.id} className="py-1 px-2 d-flex mb-0">
                          <div className="flex-grow-1">
                            {pack.name ? pack.name : pack.id}
                            <ContentPackExplicitBadge explicitStatus={pack.explicitContentStatus || null} />
                          </div>
                          <div className="px-2">({pack.popularity})</div>
                          {alreadyAdded ? (
                            <Button
                              variant="success"
                              className="square-icon icon-tiny icon-remove"
                              onClick={() => remove && remove(pack.id)}
                            >
                              <i className="fas fa-check" />
                            </Button>
                          ) : (
                            <Button variant="primary" className="square-icon icon-tiny" onClick={() => add(pack)}>
                              <i className="fas fa-plus" />
                            </Button>
                          )}
                        </ListGroup.Item>
                      );
                    })
                  ) : (
                    <ListGroup.Item variant="dark" className="py-1 px-2">
                      No content packs found.
                    </ListGroup.Item>
                  )}
                </ListGroup>
              </>
            )}
          </Form.Group>
        </Modal.Body>
        {(searchMode === ContentSearchType.BY_CATEGORY || searchMode === ContentSearchType.BY_AVAILABILITY_TYPE) && (
          <div className="d-flex justify-content-end mt-4">
            <Pagination active={page} num={maxPage} onClick={onClickPagination} />
          </div>
        )}
        <Modal.Footer>
          <Button variant="primary" onClick={cancelAdd}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddContentPack;
