import { useMutation } from '@apollo/client';
import React from 'react';
import Image from 'react-bootstrap/Image';
import Dropzone from 'react-dropzone';
import Loader from '../../../components/Loader';
import mutations from '../../../utils/mutations';
import './PlaylistPicture.css';

type Props = {
  sp2CurationPlaylistId: string;
  artwork: string | null;
  disabled?: boolean;
};

export default function SP2PlaylistPicture({ sp2CurationPlaylistId, artwork, disabled }: Props) {
  const [updateCurationContentPackSP2Picture, { loading }] = useMutation(
    mutations.UPDATE_CURATION_CONTENT_PACK_SP2_PICTURE
  );

  const onDrop = (files: Blob[]) => {
    if (disabled) {
      alert('Only validating playlists can be updated. Please use the Update Button');
      return;
    }

    if (files.length > 0) {
      updateCurationContentPackSP2Picture({
        variables: {
          input: {
            id: sp2CurationPlaylistId,
            file: files[0],
          },
        },
      });
    }
  };

  return (
    <Dropzone onDrop={onDrop} disabled={disabled || loading}>
      {({ getRootProps, getInputProps, isDragActive }) =>
        loading ? (
          <div className="w-100 justify-content-center">
            <Loader />
          </div>
        ) : (
          <div {...getRootProps()} className={`position-relative droping ${isDragActive ? 'droping--isActive' : ''}`}>
            <input {...getInputProps()} />
            <Image height="100px" width="100px" src={artwork ?? undefined} thumbnail />
            <div className="edit-img-container">
              <div className="edit-img">
                <i className="fas fa-pen" />
              </div>
            </div>
          </div>
        )
      }
    </Dropzone>
  );
}
