import { GET_FRAMES, GET_SKINS, GET_STICKERS } from '../../components/apolloQueries';

export const CURRENCY_TYPES = {
  CURRENCY_COIN: 'CurrencyCoin',
  CURRENCY_DIAMOND: 'CurrencyDiamond',
  CURRENCY_TOURNAMENT_TICKET: 'CurrencyTournamentTicket',
  CURRENCY_CATEGORY_FRAGMENT: 'CurrencyCategoryFragment',
};

export const AVAILABILITY_TYPES = {
  SHOP: ['CURRENCY_DIAMOND'],
  CRAFT: ['CURRENCY_CATEGORY_FRAGMENT'],
  UNAVAILABLE: ['CURRENCY_CATEGORY_FRAGMENT', 'CURRENCY_DIAMOND', 'CURRENCY_COIN'],
  EARN: [],
  EARN_EACH_LEVEL: [],
};

export const getQuery = (type: string) => {
  switch (type) {
    case 'Frame':
      return { query: GET_FRAMES, key: 'profileFrames', typeQ: 'PROFILE_FRAME' };
    case 'Vinyl':
      return { query: GET_SKINS, key: 'appskins', typeQ: 'APP_SKIN' };
    case 'Sticker':
    default:
      return { query: GET_STICKERS, key: 'stickers', typeQ: 'STICKER' };
  }
};
