import clsx from 'classnames';
import { useEffect } from 'react';
import _ from 'lodash';
import { Button, Media } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import Table from 'react-bootstrap/Table';
import { CurationSongDdexSearchQuery } from '../../../../../../__gqltypes__';
import ExplicitIcon from '../../../components/ExplicitIcon';
import PlayButton from '../../../components/PlayButton';
import { ClipboardButton } from './ClipboardButton';
import { WorldWideTerritoryCodes } from './config';
import './ItunesSongList.css';
import useSortReducer from './useSortReducer';

type DDexSong = CurationSongDdexSearchQuery['curationSongDDEXSearch'][0];
type Props = {
  songs: DDexSong[];
  onSelectSong?: (song: DDexSong) => void;
  actionButton?: string;
};

export default function DDEXSongList({ songs, actionButton, onSelectSong }: Props) {
  const { orderedSongs, orderBy, ascending, changeOrder, handleChangeOrder } = useSortReducer<DDexSong>();

  const displayWorldWideInfo = (song: DDexSong): string => {
    let territoryCodes = [...song.territoryCodes];
    if (song.alternativeSongs.length > 0) {
      const alternativeSongsTerritoryCodes = _.uniq(
        _.flatten(song.alternativeSongs.map((alternativeSong) => alternativeSong.territoryCodes))
      );
      territoryCodes = _.uniq([...song.territoryCodes, ...alternativeSongsTerritoryCodes]);
    }
    if (territoryCodes.length <= 3) {
      return territoryCodes.join(',');
    }
    const worldWide = WorldWideTerritoryCodes.every((territoryCode) => territoryCodes.includes(territoryCode));
    if (worldWide) {
      return 'WorldWide';
    }
    const missingTerritoryCodes = WorldWideTerritoryCodes.filter(
      (territoryCode) => !territoryCodes.includes(territoryCode)
    );
    if (missingTerritoryCodes.length <= 3) {
      return `missing in ${missingTerritoryCodes.join(',')}`;
    }
    return 'not available in enough countries';
  };

  useEffect(() => {
    changeOrder({ type: 'RESET', payload: songs });
  }, [changeOrder, songs]);

  return (
    <Table className="itunes" striped bordered size="sm">
      <thead>
        <tr>
          <th>Song ID/Album ID/ISRC</th>
          <th
            className={clsx('sortable', {
              sorted_asc: orderBy === 'TITLE' && !ascending,
              sorted_desc: orderBy === 'TITLE' && ascending,
              desc: !ascending,
              asc: !!ascending,
            })}
            onClick={() => handleChangeOrder('TITLE')}
          >
            Title
          </th>
          <th
            className={clsx('sortable', { sorted: orderBy === 'ARTIST', desc: !ascending, asc: !!ascending })}
            onClick={() => handleChangeOrder('ARTIST')}
          >
            Artist
          </th>
          <th
            className={clsx('sortable', { sorted: orderBy === 'ALBUM', desc: !ascending, asc: !!ascending })}
            onClick={() => handleChangeOrder('ALBUM')}
          >
            Album
          </th>
          <th
            className={clsx('sortable', { sorted: orderBy === 'LABEL', desc: !ascending, asc: !!ascending })}
            onClick={() => handleChangeOrder('LABEL')}
          >
            Label - SubLabel
          </th>
          <th
            className={clsx('sortable', { sorted: orderBy === 'WHITELISTED', desc: !ascending, asc: !!ascending })}
            onClick={() => handleChangeOrder('WHITELISTED')}
          >
            Whitelisted
          </th>
          <th>Territory Codes</th>
          <th>Sample</th>
          {!!onSelectSong && !!actionButton && <th>Action</th>}
        </tr>
      </thead>
      <tbody>
        {orderedSongs.map((song) => {
          return (
            <tr key={song.id}>
              <td>
                <span className="text-xs">{song.id}</span>
                <br />
                <span className="text-xs">{song.albumId}</span>
                <br />
                <span className="text-xs">{song.isrc}</span>
              </td>
              <td>
                {song.title} <ExplicitIcon explicit={song.isExplicit} />
              </td>
              <td>{song.artist}</td>
              <td>
                <Media>
                  <img width={100} height={100} src={song.artwork ?? undefined} alt={song.album} className="mr-2" />
                  <Media.Body>
                    {song.album}{' '}
                    <div>
                      <ClipboardButton title="Copy Artwork Url" textToCopy={song.artwork} />
                    </div>
                  </Media.Body>
                </Media>
              </td>
              <td className="itunes-label">
                <span className="text-xs">
                  {song.label}
                  {song.subLabel && ` - ${song.subLabel}`}
                </span>
              </td>
              <td>
                {song.isWhitelisted ? (
                  <Badge pill variant="success">
                    yes
                  </Badge>
                ) : (
                  <Badge pill variant="danger">
                    blacklisted
                  </Badge>
                )}
              </td>
              <td>{displayWorldWideInfo(song)}</td>
              <td>
                <PlayButton sample={song.sample} />
                <ClipboardButton title="Copy Sample Url" textToCopy={song.sample} />
              </td>
              {!!onSelectSong && !!actionButton && (
                <td>
                  <Button
                    variant="outline-success"
                    size="sm"
                    onClick={() => {
                      onSelectSong(song);
                    }}
                  >
                    {actionButton}
                  </Button>
                </td>
              )}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}
