import { useEffect, useRef, useState } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import Nav from 'react-bootstrap/Nav';
import { LinkContainer } from 'react-router-bootstrap';
import { AppRouteType, RouteType } from '../types';
import { UserRole } from '../__gqltypes__';
import { KEY_TO_NAME } from './config';
import SideToolbar from './SideToolbar';

type SideProps = {
  name: string;
  sideRoutes: RouteType[];
  onSidebarToggle: () => void;
};

const SideBarItem = ({ name, sideRoutes, onSidebarToggle }: SideProps) => {
  const { path } = sideRoutes[0];
  return (
    <LinkContainer activeClassName="active" to={path} onClick={onSidebarToggle}>
      <Nav.Item as="li">
        <LinkContainer to={path}>
          <Nav.Link>{name}</Nav.Link>
        </LinkContainer>
      </Nav.Item>
    </LinkContainer>
  );
};

const compStyles = {
  sideToolContainer: { height: '5vh', minHeight: '5vh' },
  absoluteSidebar: { position: 'absolute', zIndex: 2, height: '93%' },
  sideBarOverride: { minHeight: '0', height: '100%' },
  icon: { color: 'white' },
  botButton: { zIndex: 4 },
};

type Props = {
  role: UserRole;
  expanded: boolean;
  routes: AppRouteType[];
  app: 'SP3';
  onSidebarToggle: () => void;
};

const Sidebar = ({ role, expanded, routes, app, onSidebarToggle }: Props) => {
  const isFirstTime = useRef(true);
  const [show, setShow] = useState(true);
  const [showSideToolBar, setShowSideToolBar] = useState(false);

  useEffect(() => {
    if (isFirstTime.current) {
      isFirstTime.current = false;
      return;
    }
    setShowSideToolBar((s) => !s);
  }, [expanded]);

  const children = routes
    .filter(({ permission }) => permission.includes(role))
    .map(({ name, sideRoutes }) => (
      <SideBarItem key={name} name={name} sideRoutes={sideRoutes} onSidebarToggle={onSidebarToggle} />
    ));
  return (
    <>
      <Collapse dimension="width" in={show}>
        <div>
          {/* @ts-ignore */}
          <Collapse dimension="width" in={showSideToolBar && show} style={compStyles.absoluteSidebar}>
            <div className="d-flex flex-column justify-content-between">
              <ul
                className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
                style={compStyles.sideBarOverride}
              >
                <div>
                  <a className="sidebar-brand d-flex align-items-center justify-content-center" href="/">
                    <div className="sidebar-brand-icon rotate-n-15">
                      <i className="fas fa-laugh-wink" />
                    </div>
                    <div className="sidebar-brand-text mx-3">Freshplanet Admin</div>
                  </a>
                  <hr className="sidebar-divider" />
                  <div className="sidebar-heading">{KEY_TO_NAME[app]}</div>

                  {children}
                </div>
              </ul>
            </div>
          </Collapse>
          <div className="d-flex flex-column justify-content-between" style={compStyles.sideBarOverride}>
            <SideToolbar routes={routes} role={role} />
            <div
              className="bg-secondary d-flex align-items-center justify-content-center"
              role="button"
              tabIndex={0}
              onClick={() => setShow(false)}
              style={compStyles.botButton}
            >
              <span className="text-white text-center">Hide</span>
              <i className="fas fa-chevron-left m-2" style={compStyles.icon} />
            </div>
          </div>
        </div>
      </Collapse>
      {!show && (
        <div
          role="button"
          tabIndex={0}
          style={compStyles.sideToolContainer}
          className="d-flex align-self-end flex-column justify-content-center fixed-left bg-primary rounded-right mb-4"
          onClick={() => setShow(!show)}
        >
          <i className="fas fa-chevron-right m-2" style={compStyles.icon} />
        </div>
      )}
    </>
  );
};

export default Sidebar;
