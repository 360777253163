import _ from 'lodash';
import moment from 'moment';
import { Card } from 'react-bootstrap';
import { MetricType } from '../../../../../../__gqltypes__';

type PartialNumericAggregatedData = {
  datetime: Date;
  average: number;
  P95: number;
  P99: number;
  P100: number;
  count: number;
  std: number;
};

function formatMs(ms: number) {
  if (ms < 1_000) return `${ms.toPrecision(3)}ms`;
  if (ms < 60_000) return `${(ms / 1000).toPrecision(2)}s`;

  const s = Math.round(ms / 1_000);
  if (s < 3600) return `${(s / 60).toFixed(0)}min ${s % 60}s`;

  const m = Math.round(s / 60);
  return `${(m / 60).toFixed(0)}h${m % 60}`;
}

function formatSize(c: number) {
  if (c < 1024) return `${c.toFixed(0)}B`; // 0B - 1023B
  if (c < 10_240) return `${(c / 1024).toFixed(2)}kB`; // 1.00 to 9.99 kB
  if (c < 102_400) return `${(c / 1024).toFixed(1)}kB`; // 10.0 to 99.9 kB
  if (c < 1_048_576) return `${(c / 1024).toFixed(0)}kB`; // 100 to 1023 kB
  if (c < 10_485_760) return `${(c / 1_048_576).toFixed(2)}MB`; // 1.00 - 9.99 MB
  if (c < 104_857_600) return `${(c / 1_048_576).toFixed(1)}MB`; // 10.0 - 99.9 MB
  return `${(c / 1_048_576).toFixed(0)}MB`; // 100+ MB
}

function formatValue(value: number, type: MetricType) {
  if (_.isNaN(value)) {
    return '-';
  }
  if (type === MetricType.SPANNERRESPONSESIZE) {
    return formatSize(value);
  }
  return formatMs(value);
}

function formatCount(c: number) {
  if (_.isNaN(c)) return '-';
  if (c < 1000) return c.toFixed(0);
  if (c < 10_000) return `${(c / 1000).toFixed(2)}k`;
  if (c < 100_000) return `${(c / 1000).toFixed(1)}k`;
  if (c < 1_000_000) return `${(c / 1000).toFixed(0)}k`;
  if (c < 10_000_000) return `${(c / 1_000_000).toFixed(2)}M`;
  if (c < 100_000_000) return `${(c / 1_000_000).toFixed(1)}M`;
  return `${(c / 1_000_000).toFixed(0)}M`;
}

function StatCard({ label, value, color }: { label: string; value: string; color: string }) {
  return (
    <Card className="p-2 my-2 mr-2" style={{ flex: 1, backgroundColor: `${color}55`, color: 'black' }}>
      <div className="m-auto text-center">{label}</div>
      <div className="m-auto text-center">
        <b>{value}</b>
      </div>
    </Card>
  );
}

export default function RequestSummary({
  requestDataset,
  color,
  type,
  timeSelection,
}: {
  requestDataset: { label: string; data: PartialNumericAggregatedData[] };
  timeSelection: { start: Date; end: Date };
  color: string;
  type: MetricType;
}) {
  const start = moment(timeSelection.start);
  const end = moment(timeSelection.end);
  const data = requestDataset.data.filter((d) => moment(d.datetime).isBetween(start, end));
  const max = _.max(data.map((d) => d.P100)) ?? NaN;
  const sum = _.sum(data.map((d) => d.average * d.count)) || NaN;
  const count = _.sum(data.map((d) => d.count)) || NaN;
  const avg = sum / count;

  const aggStd = Math.sqrt(
    _.sumBy(data, ({ average, count: c, std }) => c * (std * std + average * average)) / count - avg * avg
  );

  return (
    <div>
      {start.format('YYYY-MM-DD HH:00')} - {end.format('YYYY-MM-DD HH:00')}:
      <div className="d-flex">
        <StatCard label="Max" value={formatValue(max, type)} color={color} />
        <StatCard label="Avg" value={formatValue(avg, type)} color={color} />
        <StatCard label="Std" value={`±${formatValue(aggStd, type)}`} color={color} />
        <StatCard label="Cumulated" value={formatValue(sum, type)} color={color} />
        <StatCard label="Total count" value={formatCount(count)} color={color} />
      </div>
    </div>
  );
}
