import {useState, useCallback, useEffect} from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Col, Container, Row, Button, Modal, Form, Alert, Card, Spinner } from 'react-bootstrap';
import DateTimePicker from 'react-datetime';

import { 
  LIVEOPS_CHAINED_OFFER_EVENT_CONFIG_QUERY,
  DELETE_CHAINED_OFFER_EVENT,
  UPLOAD_CHAINED_OFFER_EVENT_ASSET,
  UPDATE_CHAINED_OFFER_EVENT } from '../graphql';
import { LoadingLogo } from '../../../../devtools/components/Modal';
import { 
  ChainedOfferEventConfig,
  GetLiveOpsChainedOfferEventConfigQuery,
  GetLiveOpsChainedOfferEventConfigQueryVariables,
  DeleteChainedOfferEventMutation,
  DeleteChainedOfferEventMutationVariables, 
  ChainedOfferEventStatus,
  UserSubscriptionTier,
  UploadChainedOfferEventAssetMutation,
  UploadChainedOfferEventAssetMutationVariables,
  UpdateChainedOfferEventMutation,
  UpdateChainedOfferEventMutationVariables,
  ChainedOfferPackConfig,
  ChainedOfferClaimType } from '../../../../../../__gqltypes__';
import { applyChangeIfDateValid, validIfSameDayOrAfter } from '../../../../../../utils/datepicker';
import { stringToEnum } from '../../SaleEvent/utils';
import DragUpload from '../../Items/components/DragUpload';
import AssetDisplay from '../../Items/components/AssetDisplay';
import { ChainedOfferPack } from './ChainedOfferPack';
import { convertCOEPackRewardsToLevelRewardInputs } from '../utils';

type Props = {
  chainedOfferId: string;
  onUpserChainedOffer: (value: ChainedOfferEventConfig) => void;
  onRemoveChainedOffer: (chainedOfferId: string) => void;
};

export function ChainedOfferEventData({chainedOfferId, onUpserChainedOffer, onRemoveChainedOffer}: Props) {

  const [name, setName] = useState('');
  const [status, setStatus] = useState(ChainedOfferEventStatus.DRAFT);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [stopDate, setStopDate] = useState<Date | null>(null);
  const [backgroundImage, setBackgroundImage] = useState('');
  const [packs, setPacks] = useState<Array<ChainedOfferPackConfig>>([]);

  // filters
  const [filterSubscriptionTier, setFilterSubscriptionTier] = useState<UserSubscriptionTier | undefined>(
    UserSubscriptionTier.BASIC
  );
  const [filterUserIds, setFilterUserIds] = useState<string>('');
  const [filterUserMinCreationDate, setFilterUserMinCreationDate] = useState<Date | undefined>();
  const [filterUserMaxCreationDate, setFilterUserMaxCreationDate] = useState<Date | undefined>();
  const [filterAppVersion, setFilterAppVersion] = useState<string | undefined>();
  const [filterUserLevel, setFilterUserLevel] = useState<number | undefined>();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showBackgroundImageDeletionWarning, setShowBackgroundImageDeletionWarning] = useState(false);

  const {data: chainedOfferData, loading: chainedOfferLoading, refetch} = useQuery<
  GetLiveOpsChainedOfferEventConfigQuery, 
  GetLiveOpsChainedOfferEventConfigQueryVariables
  >(LIVEOPS_CHAINED_OFFER_EVENT_CONFIG_QUERY,
    {
      fetchPolicy: 'no-cache',
      variables: { id: chainedOfferId },
    }
  );

  const [updateChainedOffer, {loading: updateLoading}] = useMutation<
    UpdateChainedOfferEventMutation,
    UpdateChainedOfferEventMutationVariables
    >(UPDATE_CHAINED_OFFER_EVENT, {
      onCompleted: ({updateChainedOfferEvent}) => {
        const updatedChainedOffer = updateChainedOfferEvent?.chainedOfferEvent;
        // @ts-ignore
        onUpserChainedOffer(updatedChainedOffer);
        setShowBackgroundImageDeletionWarning(false);
        refetch();
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );
  
  const [deleteChainedOffer, { loading: deleteLoading}] = useMutation<
  DeleteChainedOfferEventMutation,
  DeleteChainedOfferEventMutationVariables
  >(DELETE_CHAINED_OFFER_EVENT,
    {
      onCompleted: ({deleteChainedOfferEvent}) => {
        if (!deleteChainedOfferEvent?.deletedEventId) {
          alert("Error: couldn't remove the current Chained Offer");
          return;
        }
        onRemoveChainedOffer(deleteChainedOfferEvent?.deletedEventId);
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const [uploadBackgroundAsset, { loading: uploadBackgroundLoading }] = useMutation<
    UploadChainedOfferEventAssetMutation,
    UploadChainedOfferEventAssetMutationVariables
    >(UPLOAD_CHAINED_OFFER_EVENT_ASSET, {
      onCompleted: ({uploadChainedOfferEventAsset : { backgroundImage: backImage }}) => {
        setBackgroundImage(backImage ?? '');
      },
      onError: (error) => {
        console.log(error);
      },
    });
    

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const resetMinutes = (date: any) => {
    const newDate = new Date(date);
    newDate.setMinutes(0);
    return newDate;
  };

  function userIdsStringToArray(songIdsString: string): string[] | undefined {
    if (typeof songIdsString === 'string' && songIdsString.trim().length > 0) {
      return songIdsString.split(',').map((id) => id.trim());
    }
    return undefined;
  }

  function userIdsArrayToString(userIdsArray: string[] | string): string {
    if (Array.isArray(userIdsArray)) {
      return userIdsArray.join(', ');
    }
    return userIdsArray;
  }

  useEffect(() => {
    if (chainedOfferData && chainedOfferData.liveOps.chainedOfferEvent) {
      const offer = chainedOfferData.liveOps.chainedOfferEvent;
      setName(offer.name);
      setStatus(offer.status);
      setStartDate(resetMinutes(offer.startDate));
      setStopDate(resetMinutes(offer.stopDate));
      setBackgroundImage(offer.backgroundImage ?? '');
      // @ts-ignore
      setPacks(offer.packs);

      setFilterUserLevel(offer.filterUserLevel ?? undefined);
      setFilterUserIds(userIdsArrayToString(offer.filterUserIds ?? []));
      setFilterAppVersion(offer.filterAppVersion ?? undefined);
      setFilterSubscriptionTier(offer.filterSubscriptionTier ?? undefined);
      setFilterUserMinCreationDate(offer.filterUserMinCreationDate ? 
        resetMinutes(offer.filterUserMinCreationDate) : undefined);
      setFilterUserMaxCreationDate(offer.filterUserMaxCreationDate ? 
          resetMinutes(offer.filterUserMaxCreationDate) : undefined);
    }
  }, [chainedOfferData]);

  const handleSaveChainedOffer = () => {
    updateChainedOffer({
      variables: {
        input: {
          id: chainedOfferId,
          name,
          startDate,
          stopDate,
          status,
          packs: packs.map((pack) => {
            return {
              claimType: pack.claimType,
              cost: pack.cost,
              sku: pack.sku,
              rewards: convertCOEPackRewardsToLevelRewardInputs(pack.rewards)
            };
          }),
          backgroundImage,
          filterAppVersion,
          filterUserIds: userIdsStringToArray(filterUserIds),
          filterSubscriptionTier,
          filterUserLevel,
          filterUserMinCreationDate,
          filterUserMaxCreationDate,
        },
      },
    });
  };

  const handleDeleteChainedOffer = useCallback(() => {
    deleteChainedOffer({
      variables: {
        input: {
          id: chainedOfferId
        },
      },
    });
  }, [deleteChainedOffer, chainedOfferId]);

  const handleChangeName = useCallback((event) => {
    setName(event.target.value);
  }, []);

  const handleChangeStatus = useCallback((event) => {
    setStatus(event.currentTarget.value as ChainedOfferEventStatus);
  }, []);

  const handleChangeAppVersion = useCallback((event) => {
    setFilterAppVersion(event.target.value);
  }, []);

  const handleChangeUserLevel = useCallback((event) => {
    setFilterUserLevel(!Number.isNaN(+event.target.value) ? +event.target.value : undefined);
  }, []);

  const handleChangeSubscriberStatus = useCallback((event) => {
    setFilterSubscriptionTier(
      event.currentTarget.value === 'All' ? undefined : (event.currentTarget.value as UserSubscriptionTier)
    );
  }, []);

  const handleChangePlayerIds = useCallback((event) => {
    setFilterUserIds(event.target.value);
  }, []);

  const handleBackgroundImageUpload = useCallback(
    (file) => {
      uploadBackgroundAsset({
        variables: {
          input: {
            file,
            id: chainedOfferId,
          },
        },
      });
    },
    [chainedOfferId, uploadBackgroundAsset]
  );

  const handleAddPack = useCallback(() => {
    const newPack: ChainedOfferPackConfig = {
      __typename: 'ChainedOfferPackConfig',
      cost: 0,
      sku: '',
      claimType: ChainedOfferClaimType.FREE,
      rewards: []
    };
    setPacks(prev => ([...prev, newPack]));
  }, [setPacks]);

  const handlePackUpdate = useCallback((updatedPack: ChainedOfferPackConfig, idx: number) => {
    const newPacks = [...packs];
    newPacks[idx] = updatedPack;
    setPacks(newPacks);
    return updatedPack;
  }, [packs]);

  const handleRemovePack = useCallback((idx: number) => {
    const newPacks = packs.filter((val, index) => idx !== index);
    setPacks(newPacks);
  }, [packs, setPacks]);

  const handleMovePack = useCallback((idx: number, isUp: boolean) => {
    const inc = isUp ? -1 : 1;
    [packs[idx], packs[idx + inc]] = [
      packs[idx + inc], packs[idx]
    ];
    const newPacks = [...packs];
    setPacks(newPacks);
  }, [packs]);

  const loading = chainedOfferLoading || deleteLoading;
  if (loading) {
    return (<LoadingLogo show />);
  }

  return (
    <>
      <Container className="pb-4">

        <Row className="mb-3">
          <Col md={3}>Id:</Col>
          <Col md={5}>{chainedOfferId}</Col>
          <Col className="d-flex justify-content-end">
            <Button
              className="mr-2"
              onClick={() => {
                setShowDeleteModal(true);
              }}
              variant="danger"
              disabled={false}
            >
              <i className="fas fa-trash mr-2" />
              Delete
            </Button>
            <Button onClick={() => handleSaveChainedOffer()} variant="success" type="submit">
                Save
            </Button>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={3}>Name:</Col>
          <Col md={5}>
            <Form.Control value={name} onChange={handleChangeName} type="text" />
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={3}>Start date:</Col>
          <Col md={5}>
           <DateTimePicker
              dateFormat="YYYY-MM-DD"
              timeFormat={true}
              timeConstraints={{ minutes: { min: 0, max: 0, step: 0 } }}
              // @ts-ignore
              onChange={applyChangeIfDateValid(setStartDate)}
              isValidDate={validIfSameDayOrAfter}
              // @ts-ignore
              value={startDate ?? undefined}
            />
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={3}>Stop date:</Col>
          <Col md={5}>
            <DateTimePicker
              dateFormat="YYYY-MM-DD"
              timeFormat={true}
              timeConstraints={{ minutes: { min: 0, max: 0, step: 0 } }}
              // @ts-ignore
              onChange={applyChangeIfDateValid(setStopDate)}
              isValidDate={validIfSameDayOrAfter}
              // @ts-ignore
              value={stopDate ?? undefined}
            />
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={3}>Status:</Col>
          <Col md={2}>
            <Form.Group controlId="sale-event-status">
              <Form.Control as="select" type="text" value={status} onChange={handleChangeStatus}>
                {Object.keys(ChainedOfferEventStatus).map((option) => (
                  <option key={option} value={stringToEnum(option, ChainedOfferEventStatus)}>
                    {option}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <hr />
        <h4>Matchers:</h4>

        <Row className="mb-3">
          <Col md={3}>Minimum Registration Date:</Col>
          <Col md={5}>
           <DateTimePicker
              dateFormat="YYYY-MM-DD"
              timeFormat={true}
              timeConstraints={{ minutes: { min: 0, max: 0, step: 0 } }}
              // @ts-ignore
              onChange={applyChangeIfDateValid(setFilterUserMinCreationDate)}
              // @ts-ignore
              value={filterUserMinCreationDate ?? undefined}
            />
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={3}>Maximum Registration Date:</Col>
          <Col md={5}>
            <DateTimePicker
              dateFormat="YYYY-MM-DD"
              timeFormat={true}
              timeConstraints={{ minutes: { min: 0, max: 0, step: 0 } }}
              // @ts-ignore
              onChange={applyChangeIfDateValid(setFilterUserMaxCreationDate)}
              // @ts-ignore
              value={filterUserMaxCreationDate ?? undefined}
            />
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={3}>Min App Version:</Col>
          <Col md={5}>
            <Form.Control type="text" value={filterAppVersion} onChange={handleChangeAppVersion} />
          </Col>
        </Row>
        
        <Row className="mb-3">
          <Col md={3}>Min User Level:</Col>
          <Col md={5}>
            <Form.Control type="text" value={filterUserLevel} onChange={handleChangeUserLevel} />
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={3}>User Subscription Status:</Col>
          <Col md={2}>
            <Form.Group controlId="sale-event-subscriber-status">
              <Form.Control
                as="select"
                type="text"
                value={filterSubscriptionTier}
                onChange={handleChangeSubscriberStatus}
              >
                <option>All</option>
                {Object.keys(UserSubscriptionTier).map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={3}>
            User Ids: <br />
            (separated by commas &quot;,&quot;)
          </Col>
          <Col md={8}>
            <Form.Control
              as="textarea"
              value={filterUserIds.toString()}
              onChange={handleChangePlayerIds}
            />
          </Col>
        </Row>
        <hr />

        <Row>
          <Col>
            <Form.Label> Background Image </Form.Label>
            {backgroundImage.length > 0 && <AssetDisplay assetUrl={backgroundImage} />}
            <DragUpload onUpload={handleBackgroundImageUpload} />
            <LoadingLogo show={uploadBackgroundLoading} />
            <Button
              className="mt-2"
              onClick={() => {
                setBackgroundImage('');
                setShowBackgroundImageDeletionWarning(true);
              }}
              variant="danger"
            >
              <i className="fas fa-trash mr-2" />
              Delete
            </Button>
            {showBackgroundImageDeletionWarning && (
              <Alert variant="danger">Click on Save to confirm the deletion of an image</Alert>
            )}
          </Col>
        </Row>
        <hr />
        
        <Row>
          <Card style={{width: '100%'}}>
            <Card.Header className="text-left">
              <Card.Title>Packs</Card.Title>
              <Button variant="success" className='align-self-center' onClick={handleAddPack}>
                Add pack
              </Button>
              <Button className='align-self-center ml-1' onClick={handleSaveChainedOffer}>
                Save Changes
              </Button>
            </Card.Header>
            <Card.Body>
              {updateLoading ? (
                <Spinner animation="border" />
              ) : (
                packs.map((pack, index) => (
                  <Card bg="light">
                    <Card.Body>
                      <ChainedOfferPack 
                        pack={pack} 
                        myIndex={index} 
                        packsSize={packs.length}
                        onRemovePack={handleRemovePack} 
                        onUpdatePack={handlePackUpdate}
                        onMovePack={handleMovePack}/>
                      </Card.Body>
                  </Card>
                ))
              )
              }
            </Card.Body>
          </Card>
        </Row>
      </Container>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Chained Offer Removal</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>This cannot be undone. Are you sure to want to remove this Chained Offer Event?</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              setShowDeleteModal(false);
              handleDeleteChainedOffer();
            }}
          >
            Remove Chained Offer Event
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}