import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import {
  GenerateCombinedMusicReportMutation,
  GenerateCombinedMusicReportMutationVariables,
  GenerateSppReportingTableMutation,
  GenerateSppReportingTableMutationVariables,
} from '../../../../../__gqltypes__';
import GenerateReportPopup from '../../popups/GenerateReportPopup';
import mutations from '../../utils/mutations';

const MusicReportingCreateBatch = () => {
  const [showSPPModal, setShowSPPModal] = useState(false);
  const [showV2Modal, setShowV2Modal] = useState(false);

  const [generateSPPReport, { loading: generatingSPP }] = useMutation<
    GenerateSppReportingTableMutation,
    GenerateSppReportingTableMutationVariables
  >(mutations.GENERATE_SPP_REPORT);

  const [generateReportV2, { loading: generatingV2 }] = useMutation<
    GenerateCombinedMusicReportMutation,
    GenerateCombinedMusicReportMutationVariables
  >(mutations.GENERATE_REPORT_V2);

  const onGenerateSPPReport = (month: number, year: number, appId: string) => {
    const input = { month, year, appId, country: 'JP' };
    generateSPPReport({ variables: { input } });
  };

  const onGenerateV2Report = (month: number, year: number, appId: string) => {
    const input = appId === 'ALL' ? { month, year } : { month, year, appId };
    generateReportV2({ variables: { input } });
  };

  return (
    <>
      <div className="my-4">
        <Row className="d-flex">
          <Button variant="success" disabled={generatingSPP} onClick={() => setShowSPPModal(true)}>
            <i className="fas fa-file-csv mr-2" />
            Generate Reporting for Labels (SPP Japan Only)
          </Button>
        </Row>
        <hr />
        <Row className="d-flex">
          <Button variant="success" disabled={generatingV2} onClick={() => setShowV2Modal(true)}>
            <i className="fas fa-file-csv mr-2" />
            Generate Combined Reporting for Labels (SPC+SP) v2
          </Button>
        </Row>
      </div>

      <GenerateReportPopup
        show={showSPPModal}
        appIds={['SPP']}
        onHide={() => setShowSPPModal(false)}
        onGenerateReport={onGenerateSPPReport}
      />
      <GenerateReportPopup
        appIds={['ALL', 'SP3', 'SPP']}
        show={showV2Modal}
        onHide={() => setShowV2Modal(false)}
        onGenerateReport={onGenerateV2Report}
      />
    </>
  );
};

export default MusicReportingCreateBatch;
