import DashboardContainer from '../../../../../../utils/dashboards/components/DashboardContainer';
// import { SimpleDateSlicing } from '../../../../../../utils/dashboards/components/slicers';
// import { DefaultSlicing } from '../../../../../../utils/dashboards/components/slicers';
import { SPPartySlicing } from '../../../../../../utils/dashboards/components/slicers';
import { SectionDisplay } from '../../../../../../utils/dashboards/components/widgets';
import LineChart from '../../../../../../utils/dashboards/components/widgets/charts/LineChart';
// import PieChart from '../../../../../../utils/dashboards/components/widgets/charts/PieChart';
import BarChart from '../../../../../../utils/dashboards/components/widgets/charts/BarChart';
// import BaseTable from '../../../../../../utils/dashboards/components/widgets/tables/BaseTable';
import WidgetContainer from '../../../../../../utils/dashboards/components/widgets/WidgetContainer';
import Processing from '../../../../../../utils/dashboards/models/Processing';
import { DashboardQueryType } from '../../../../../../__gqltypes__';

const queries = {
  session_start_total: {fillZeroes: true, source: DashboardQueryType.ATHENA},
  session_start_new_users: {fillZeroes: true, source: DashboardQueryType.ATHENA},
  session_start_dau: {fillZeroes: true, source: DashboardQueryType.ATHENA},
  screen_entered: {fillZeroes: true, source: DashboardQueryType.ATHENA},
  screen_entered_last_screen: {fillZeroes: true, source: DashboardQueryType.ATHENA},
  game_start: {fillZeroes: true, source: DashboardQueryType.ATHENA},
  game_done: {fillZeroes: true, source: DashboardQueryType.ATHENA},
  ftue_step_start: {fillZeroes: true, source: DashboardQueryType.ATHENA},
  currency_earned_category_xp: {fillZeroes: true, source: DashboardQueryType.ATHENA},
  currency_earned_keys: {fillZeroes: true, source: DashboardQueryType.ATHENA},
  currency_earned_keys_daily_bonus: {fillZeroes: true, source: DashboardQueryType.ATHENA},
  currency_used_keys: {fillZeroes: true, source: DashboardQueryType.ATHENA},
  currency_used_unlock_playlist_items: {fillZeroes: true, source: DashboardQueryType.ATHENA},
  currency_used_unlock_character_items: {fillZeroes: true, source: DashboardQueryType.ATHENA},
  item_unlocked_by_type: {fillZeroes: true, source: DashboardQueryType.ATHENA},
  item_unlocked_achievement: {fillZeroes: true, source: DashboardQueryType.ATHENA},
  item_unlocked_category_level: {fillZeroes: true, source: DashboardQueryType.ATHENA},
  item_unlocked_character: {fillZeroes: true, source: DashboardQueryType.ATHENA},
};

const SongPopParty = () => (
  <DashboardContainer queries={queries} id="song_pop_party" title="SongPopParty">
    <SPPartySlicing id="SPPartySlicing" />
    <WidgetContainer id="WidgetContainer">
      <SectionDisplay notes="Play" />
      <LineChart id="session_start_total" title="Number of distinct Session IDs Start per day"/>
      <LineChart id="session_start_new_users" title="Number of distinct first ever Session IDs Start per day - Proxy for New Users"/>
      <LineChart id="session_start_dau" title="Number of distinct first Session IDs Start per day - Proxy for DAU"/>
      <LineChart
        id="screen_entered"
        title="Number of session ids per screen viewed per day"
        processing={Processing.pivot('screen_entered', 'screen_name', 'nb_session_ids', 'date')}
      />
      <LineChart
        id="screen_entered_last_screen"
        title="Last screen viewed for each session ids per day"
        processing={Processing.pivot('screen_entered_last_screen', 'screen_name', 'nb_session_ids', 'date')}
      />
      <SectionDisplay notes="Engagement" />
      <LineChart
        id="game_start"
        title="Number of games started per game mode per day"
        processing={Processing.pivot('game_start', 'game_mode', 'nb_events', 'date')}
      />
      <LineChart
        id="game_done"
        title="Percentage of games done per game mode per day" percentage
        processing={Processing.pivot('game_done', 'game_mode', 'nb_events', 'date')}
      />
      <SectionDisplay notes="FTUE" />
      <LineChart
        id="ftue_step_start"
        title="Last FTUE Step done for each session ids per day"
        processing={Processing.pivot('ftue_step_start', 'last_ftue_step', 'nb_session_ids', 'date')}
      />
      <SectionDisplay notes="Economy" />
      <LineChart
        id="currency_earned_category_xp"
        title="Number of category XP earned per type"
        processing={Processing.pivot('currency_earned_category_xp', 'currency_reason', 'nb_category_xp_earned', 'date')}
      />
      <LineChart
        id="currency_earned_keys"
        title="Number of Keys earned per type"
        processing={Processing.pivot('currency_earned_keys', 'currency_reason', 'nb_keys_earned', 'date')}
      />
      <LineChart id="currency_earned_keys_daily_bonus" title="Percentage of DAU that claimed the daily bonus" percentage/>
      <LineChart
        id="currency_used_keys"
        title="Number of Keys used per type"
        processing={Processing.pivot('currency_used_keys', 'currency_reason', 'nb_keys_used', 'date')}
      />
      <BarChart id="currency_used_unlock_playlist_items" title="Most popular category unlocked" />
      <BarChart id="currency_used_unlock_character_items" title="Most popular character unlocked" />
      <LineChart
        id="item_unlocked_by_type"
        title="Number of items unlocked per type"
        processing={Processing.pivot('item_unlocked_by_type', 'item_type', 'nb_events', 'date')}
      />
      <BarChart id="item_unlocked_achievement" title="Number of sessions ids per category of achievement unlocked" />
      <BarChart id="item_unlocked_category_level" title="Number of sessions ids per category unlocked" />
      <BarChart id="item_unlocked_character" title="Number of sessions ids per character unlocked" />
    </WidgetContainer>
  </DashboardContainer>
);

export default SongPopParty;
