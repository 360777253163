import { useMutation } from '@apollo/client';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Button, Card, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import {
  RemoveSpecialEventTournamentMutation,
  RemoveSpecialEventTournamentMutationVariables,
  TournamentFragmentFragment,
} from '../../../../../../__gqltypes__';
import { REMOVE_SPECIAL_EVENT_TOURNAMENT } from '../../Tournament/graphql';

type Props = {
  specialEventId: string;
  tournaments: TournamentFragmentFragment[];
};

const SpecialEventTournaments = ({ specialEventId, tournaments }: Props) => {
  const [currentTournaments, setCurrentTournaments] = useState<TournamentFragmentFragment[] | null>(null);

  // Mutation
  const [removeTournament, { loading: removeTournamentLoading }] = useMutation<
    RemoveSpecialEventTournamentMutation,
    RemoveSpecialEventTournamentMutationVariables
  >(REMOVE_SPECIAL_EVENT_TOURNAMENT);

  const unlinkTournament = async (tournamentId: string) => {
    await removeTournament({ variables: { input: { specialEventId, tournamentId } } });
    if (!_.isNil(currentTournaments)) {
      const _currentTournaments = [...currentTournaments];
      const index = _currentTournaments.findIndex((tournament) => tournament.id === tournamentId);
      _currentTournaments.splice(index, 1);
      setCurrentTournaments(_currentTournaments);
    }
  };

  useEffect(() => {
    setCurrentTournaments(tournaments);
  }, [tournaments]);

  return (
    <Card className="mt-3 mb-3">
      <Card.Body className="text-center">
        <Card.Title>Tournaments</Card.Title>
        <Table bordered responsive hover>
          <thead className="text-center">
            <tr>
              <th>Id</th>
              <th>StartDate</th>
              <th>Unlink</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {currentTournaments ? (
              currentTournaments.map((tournament, tournamentIndex) => (
                <tr key={tournamentIndex}>
                  <td className="align-middle">
                    <Link to={`/sp3/liveops/tournaments/${tournament.id}`}>{tournament.id}</Link>
                  </td>
                  <td className="align-middle">{new Date(tournament.startDate).toLocaleString()}</td>

                  <td className="p-0 align-middle text-center">
                    <Button
                      disabled={removeTournamentLoading}
                      variant="danger"
                      onClick={() => unlinkTournament(tournament.id)}
                      className="square-icon icon-sm"
                    >
                      <i className="fas fa-times" />
                    </Button>
                  </td>
                </tr>
              ))
            ) : (
              <div>no tournament linked</div>
            )}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default SpecialEventTournaments;
