import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import { SongInputMatchType, CurationContentItemQuery, CurationContentPackQuery } from '../../../../../../__gqltypes__';

const MATCHTYPE_MAPPING: Record<SongInputMatchType | 'UNKNOWN', string> = {
  ISRC_ALBUM: 'Same ISRC and album',
  ISRC: 'Same ISRC',
  TITLE_ARTIST_ALBUM: 'Same title, artist and album',
  TITLE_ARTIST: 'Same title and artist',
  SIMILARITY: 'Approximate match with the same artist and a close title',
  MANUAL: 'Manual',
  UNKNOWN: 'Unknown',
  OUTDATED: 'Outdated',
};

type CurationSongSongInput = Extract<
  CurationContentItemQuery['curationContentItem'],
  { __typename: 'CurationSong' }
>['songInput'];
type CurationSongInput = Extract<
  CurationContentPackQuery['curationContentPack']['contentItems'][0],
  { __typename: 'CurationSongInput' }
>;
type Props = {
  songInput: CurationSongSongInput | CurationSongInput;

  showDDEXSongSearch?: () => void;
  songSearchState: null | 'SHOW' | 'SHOW_DDEX' | 'LOADING';
};

export default function SongInputInfo({ songInput, showDDEXSongSearch, songSearchState }: Props) {
  let pillVariant = 'success';
  const songMatchType = 'matchType' in songInput ? songInput.matchType ?? 'UNKNOWN' : 'UNKNOWN';

  if (songMatchType === 'SIMILARITY' || songMatchType === 'TITLE_ARTIST') {
    pillVariant = 'warning';
  } else if (songMatchType === 'MANUAL' || songMatchType === 'UNKNOWN') {
    pillVariant = 'info';
  }

  console.log('songInput', songInput);

  return (
    <>
      <div>
        Title: {songInput.title}
        <br />
        Artist: {songInput.artist}
        <br />
        Album: {songInput.album}
        <br />
        Isrc:{' '}
        {'isrc' in songInput
          ? songInput.isrc
          : 'songInputIsrc' in songInput
          ? songInput.songInputIsrc
          : 'Not found Isrc'}
        <div className="mt-4">
          Input ID: <span className="font-italic">{songInput.id}</span>
          <br />
          Matching:{' '}
          <Badge pill variant={pillVariant}>
            {MATCHTYPE_MAPPING[songMatchType]}
          </Badge>
          {!songSearchState && (
            <>
              <Button className="ml-2" size="sm" onClick={showDDEXSongSearch}>
                <i className="fas fa-wrench mr-2" />
                Update DDEX Match
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
}
