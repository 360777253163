import React, { useMemo, useState } from 'react';
import { Modal, ModalProps } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import RadioSelector, { RadioSelectorOption } from '../components/RadioSelector';
import { InputType } from '../screens/PlaylistInfo/utils';
import { AddPlaylistValues, onUpdatePlaylistOptionChosen } from './utils';

function extractExternalURI(externalURI: string) {
  if (!externalURI) {
    return {};
  }

  if (externalURI.startsWith('spotify')) {
    return {
      spotifyURI: externalURI,
    };
  }

  if (externalURI.startsWith('sp2')) {
    return {
      sp2Id: externalURI.split(':')[1],
    };
  }

  if (externalURI.startsWith('artists')) {
    return {
      artistIds: externalURI.split(':')[1],
    };
  }

  return {};
}

type Props = {
  externalURI: string;
  onUpdatePlaylist: (inputType: keyof typeof InputType, values: AddPlaylistValues) => void;
} & ModalProps;

const UpdatePlaylistPopup = ({ externalURI, onUpdatePlaylist, ...rest }: Props) => {
  const [inputType, setInputType] = useState<keyof typeof InputType>('CSV');

  const { spotifyURI, sp2Id } = useMemo(() => extractExternalURI(externalURI), [externalURI]);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    onUpdatePlaylistOptionChosen(event, inputType, onUpdatePlaylist);
  };

  return (
    <Modal {...rest} size="lg" aria-labelledby="contained-modal-title-vcenter" centered onSubmit={onSubmit}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Update Playlist</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {/* @ts-ignore */}
          <RadioSelector title="Input type" value={inputType} onChange={setInputType}>
            <RadioSelectorOption value={InputType.CSV} label="Upload a CSV file" id="csv-input" />
            <RadioSelectorOption
              value={InputType.SPOTIFY}
              label="Provide a Spotify playlist URI or URL"
              id="spotify-input"
            />
            <RadioSelectorOption
              value={InputType.SP2}
              id="sp2-input"
              label="Provide the production playlist ID of a SongPop 2 playlist"
            />
            <RadioSelectorOption
              value={InputType.SPOTIFY_ARTISTS}
              label="Provide one or more Spotify artist IDs/URLs/URIs (comma-separated)"
              id="auto-artist-type"
            />
            <RadioSelectorOption
              value={InputType.NEW_VERSION}
              label="Just create a new version of the playlist"
              id="new-input"
            />
          </RadioSelector>
          {inputType === InputType.CSV ? (
            <Form.Group controlId="csvPlaylistFile">
              <Form.Label>Upload a CSV file</Form.Label>
              <Form.Control type="file" accept=".csv" />
            </Form.Group>
          ) : inputType === InputType.SPOTIFY ? (
            <Form.Group controlId="spotifyPlaylistURI">
              <Form.Label>Spotify Playlist URI or URL</Form.Label>
              <Form.Control
                required
                type="text"
                defaultValue={spotifyURI}
                placeholder="spotify:user:billyjoelmusic:playlist:2WgQ96x6mlX6RMS2yuSmvp"
              />
            </Form.Group>
          ) : inputType === InputType.SPOTIFY_ARTISTS ? (
            <>
              <Form.Group controlId="artistIds">
                <Form.Label>Spotify artist IDs/URLs/URIs (comma-separated)</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="7bcbShaqKdcyjnmv4Ix8j6,6M2wZ9GZgrQXHCFfjv46we,66CXWjxzNUsdJxJ2JdwvnR"
                />
              </Form.Group>

              <Form.Group controlId="includeExtras">
                <Form.Label>Include Extras </Form.Label>
                <Form.Check label="Add extra versions of the same songs (remix, live, etc )" />
              </Form.Group>
            </>
          ) : inputType === InputType.SP2 ? (
            <Form.Group controlId="sp2id">
              <Form.Label>SongPop 2 Playlist ID</Form.Label>
              <Form.Control required type="text" placeholder="5754333678665728" defaultValue={sp2Id} />
            </Form.Group>
          ) : null}
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default UpdatePlaylistPopup;
