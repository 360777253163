import { useEffect, useState } from 'react';
import { Badge, Button, Form, Image } from 'react-bootstrap';
import { ModeratedContentItemState, ModerationContentItemsQuery } from '../../../../../../__gqltypes__';
import ExplicitIcon from '../../../components/ExplicitIcon';
import PlayButton from '../../../components/PlayButton';
import Tag from '../../../components/Tag';

type ModeratedContentItem = NonNullable<ModerationContentItemsQuery['moderationContentItems']>['list'][0];
type ModeratedContentItemCurationSong = Extract<ModeratedContentItem['contentItem'], { __typename: 'CurationSong' }>;

function ContentItem({
  item,
  onStatusUpdate,
  onLocaleUpdate,
  onShowLyrics,
  onShowBigPicture,
}: {
  item: ModeratedContentItem;
  onStatusUpdate: (id: string, type: ModeratedContentItemState) => void;
  onLocaleUpdate: (id: string) => void;
  onShowLyrics: (title: string, artist: string) => void;
  onShowBigPicture: (picture: string) => void;
}) {
  const song = item.contentItem as ModeratedContentItemCurationSong;

  const [itemState, setItemState] = useState(item.state);

  useEffect(() => {
    setItemState(item.state);
  }, [item.state]);

  return (
    <tr>
      <td>
        <Image width={100} src={song.picture} onClick={() => onShowBigPicture(song.picture)} />
      </td>
      <td>
        {song.id}
        <hr />
        ISRC: {song.isrc}
        <hr />
        Itunes: {song.itunesId}
      </td>

      <td>
        <span className="font-weight-bold">{song.displayTitle}</span>
        <ExplicitIcon explicit={song.isExplicit} />
        <br />
        <span className="text-muted">{song.displayArtist}</span>
      </td>
      <td>
        <PlayButton sample={song.defaultSample} />
        <hr />
        <Button onClick={() => onShowLyrics(song.title, song.artist)}>Lyrics</Button>
      </td>

      {itemState === ModeratedContentItemState.TO_BE_REVIEWED ? (
        <td className="align-middle">
          <Button
            variant="success"
            className="mr-4 mb-2"
            onClick={() => {
              onStatusUpdate(item.id, ModeratedContentItemState.CLEAN);
            }}
          >
            <i className="fas fa-check mr-1" />
            Mark as Clean
          </Button>
          <Button
            variant="danger"
            className="mr-4 mb-2"
            onClick={() => {
              onStatusUpdate(item.id, ModeratedContentItemState.QUESTIONABLE);
            }}
          >
            <i className="fas fa-times-circle mr-1" />
            Mark as Questionable
          </Button>
          <hr />
          <Button
            variant="danger"
            className="mr-4 mb-2"
            size="sm"
            onClick={() => {
              onStatusUpdate(item.id, ModeratedContentItemState.EXPLICIT);
            }}
          >
            <i className="fas fa-times-circle mr-1" />
            Mark as Explicit
          </Button>

          <Button
            variant="warning"
            className="mr-4 mb-2"
            size="sm"
            onClick={() => {
              onLocaleUpdate(item.id);
            }}
          >
            <i className="fas fa-times-circle mr-1" />
            Wrong locale
          </Button>
          <Button
            variant="dark"
            className="mb-2"
            size="sm"
            onClick={() => {
              onStatusUpdate(item.id, ModeratedContentItemState.ERROR);
            }}
          >
            <i className="fas fa-times-circle mr-1" />
            Report Error
          </Button>
        </td>
      ) : (
        <td className="justify-content-center">
          {song.isExplicit ? (
            <Badge pill variant="danger">
              EXPLICIT
            </Badge>
          ) : (
            <>
              <Tag
                value={item.state}
                visible
                variant={item.state === ModeratedContentItemState.CLEAN ? 'success' : 'danger'}
                onRemove={() => setItemState(ModeratedContentItemState.TO_BE_REVIEWED)}
              />
              {item.state === ModeratedContentItemState.QUESTIONABLE && (
                <>
                  <hr />
                  <div>
                    <span>
                      Reason:{' '}
                      <Badge pill variant="primary">
                        {item.noteType}
                      </Badge>
                    </span>
                    <Form.Control as="textarea" rows={2} value={item.note ?? undefined} disabled={true} />
                  </div>
                </>
              )}
            </>
          )}
        </td>
      )}
    </tr>
  );
}

export default ContentItem;
