import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// PropTypes
import { selectorSlicerPropTypes } from '../slicerPropTypes';

const MultipleSelectionSlicer = ({ id, options, value, onChange, disableAll }) => (
  <Form.Group as={Row} controlId={id}>
    <Form.Label column>{id}</Form.Label>
    <Col>
      <select className="form-control" multiple value={value} onChange={(e) => onChange(e.target.value || null)}>
        {!disableAll && <option value="All">All</option>}
        {options.map((opt) => (
          <option key={opt.value} value={opt.value}>
            {opt.text}
          </option>
        ))}
      </select>
    </Col>
  </Form.Group>
);

MultipleSelectionSlicer.propTypes = selectorSlicerPropTypes;

MultipleSelectionSlicer.defaultProps = {
  onChange: () => {},
};

export default MultipleSelectionSlicer;
