import { gql } from '@apollo/client';

const ABTEST_FRAGMENT = gql`
  fragment ABTestFragment on ABTestConfig {
    id
    variationsWithPercentage {
      name
      percentage
    }
    status
    type
    audience
    defaultVariation
    description
    isNewAudienceOnly
    isHardCoded
    startDate
    winnerVariation
  }
`;

export const GET_ABTESTS_CONFIGS = gql`
  query getABTestsConfigs {
    liveOps {
      ABTestConfigs {
        ...ABTestFragment
      }
    }
  }
  ${ABTEST_FRAGMENT}
`;

export const CREATE_ABTEST = gql`
  mutation createABTest($input: CreateABTestInput!) {
    createABTest(input: $input) {
      abTest {
        ...ABTestFragment
      }
    }
  }
  ${ABTEST_FRAGMENT}
`;

export const UPDATE_ABTEST = gql`
  mutation updateABTest($input: UpdateABTestInput!) {
    updateABTest(input: $input) {
      abTest {
        ...ABTestFragment
      }
    }
  }
  ${ABTEST_FRAGMENT}
`;

export const DELETE_ABTEST = gql`
  mutation deleteABTest($input: DeleteABTestInput!) {
    deleteABTest(input: $input) {
      testIdDeleted
    }
  }
`;
