import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// PropTypes
import { selectorSlicerPropTypes } from '../slicerPropTypes';

const BaseSelectorSlicer = ({ id, options, value, onChange, disableAll, defaultValue }) => (
  <Form.Group as={Row} controlId={id}>
    <Form.Label column>{id}</Form.Label>
    <Col>
      <Form.Control as="select" value={value || defaultValue} onChange={(e) => onChange(e.target.value || null)}>
        {!disableAll && <option>All</option>}
        {options.map((opt) => (
          <option key={opt.value} value={opt.value}>
            {opt.text}
          </option>
        ))}
      </Form.Control>
    </Col>
  </Form.Group>
);

BaseSelectorSlicer.propTypes = selectorSlicerPropTypes;

BaseSelectorSlicer.defaultProps = {
  onChange: () => {},
};

export default BaseSelectorSlicer;
