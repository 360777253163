import { RouteType } from '../../../types';
import AdminPremium from './screens/AdminPremium';
import AdminUsers from './screens/AdminUsers';

export const PREFIX = 'sp3';

const routes: RouteType[] = [
  {
    path: `/${PREFIX}/admin/user/`,
    component: AdminUsers,
    name: 'Users',
  },
  {
    path: `/${PREFIX}/admin/premium/`,
    name: 'Premium',
    component: AdminPremium,
  },
  {
    path: `/${PREFIX}/admin/user/:id`,
    component: AdminUsers,
  },
];

export default routes;
