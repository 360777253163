import { countBy, groupBy, max, min, sortedIndexBy } from 'lodash';

export function SimpleBarGraph({ data, steps, unit }: { data: number[]; steps: number; unit: string }) {
  const step = ((max(data) ?? 0) - (min(data) ?? 0)) / steps;
  const minStep = Math.floor((min(data) ?? 0) / step) * step;
  const bins = countBy(
    data,
    (d) => `${(Math.floor(d / step) * step).toFixed(2)} - ${((Math.floor(d / step) + 1) * step).toFixed(2)}`
  );
  const completedBins = [
    ...Object.entries(bins),
    ...Array.from({ length: steps }, (v, i): [string, number] => [
      `${(minStep + step * i).toFixed(2)} - ${(minStep + step * (i + 1)).toFixed(2)}`,
      0,
    ]).filter(([k]) => !Object.keys(bins).includes(k)),
  ];
  const maxCount = max(Object.values(bins)) || 1;

  console.log(bins);

  return (
    <div style={{ display: 'flex', width: '100%', height: 'calc(100% - 50px)' }}>
      {completedBins
        .sort(([v1], [v2]) => (v1 < v2 ? -1 : 1))
        .map(([v, c]) => {
          const proportion = ((100 * c) / data.length).toFixed(1);
          const height = c / maxCount;
          return (
            <div
              style={{
                flex: 1,
              }}
              key={v}
            >
              <div
                style={{
                  backgroundColor: 'transparent',
                  height: `calc(${100 * (1 - height)}% - ${40 * (1 - height)}px)`,
                }}
              />
              <div style={{ height: 20, textAlign: 'center', fontSize: '12px' }}>{proportion}%</div>
              <div
                style={{
                  backgroundColor: '#37b8cc',
                  borderRadius: '5px',
                  margin: '0 10%',
                  height: `calc(${100 * height}% - ${40 * height}px)`,
                }}
              />
              <div style={{ height: 20, margin: '0 10%', textAlign: 'center', fontSize: '10px' }}>
                {v}
                {unit}
              </div>
            </div>
          );
        })}
    </div>
  );
}
