import React from 'react';

import BaseSelectorSlicer from '../components/BaseSelectorSlicer';
import defaultBackendModule from '../../../models/slicers/defaultBackendModule.json';
import { slicerPropTypes } from '../slicerPropTypes';

const options = Object.keys(defaultBackendModule.queries).map((key) => ({
  text: key,
  value: key,
}));

const DefaultBackendModuleSlicer = ({ id, value, onChange }) => (
  <BaseSelectorSlicer id={id} value={value} onChange={onChange} options={options} />
);

DefaultBackendModuleSlicer.propTypes = slicerPropTypes;

DefaultBackendModuleSlicer.defaultProps = {
  id: defaultBackendModule.defaultId,
  onChange: () => {},
};

export default DefaultBackendModuleSlicer;
