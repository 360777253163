export default function parseMessage(
  inputMessage: string,
  search: string
): { id: string; type: string; parts: { text: string; search: boolean }[] }[] {
  const parts = [];
  let message = inputMessage;

  // Search start "title - ..." and extract it to a single part
  if (/^([a-zA-Z]+) - /.test(message)) {
    const [title, ...rest] = message.split(' - ');
    parts.push({ text: title, type: 'title' });
    parts.push({ text: ' - ', type: 'separator' });
    message = rest.join(' - ');
  }

  // Search string like "pod:id" => info
  if (
    /([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}[^\s]*)|([A-Z_]{3,100})|([a-zA-Z]+:[^\n) :]+)/.test(
      message
    )
  ) {
    const splited = message.split(
      /([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}[^\s]*)|([A-Z_]{3,100})|([a-zA-Z]+:[^\n) :]+)/
    );
    parts.push(
      ...splited
        .map((s) => ({
          text: s,
          type: /(user:[^\n) :]+)/.test(s)
            ? 'user'
            : /(pod:[^\n) :]+)/.test(s)
            ? 'pod'
            : /([a-zA-Z]+:[^\n) :]+)/.test(s)
            ? 'info'
            : /([A-Z_]{3,100})/.test(s)
            ? 'constant'
            : /([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}[^\s]*)/.test(s)
            ? 'id'
            : 'message',
        }))
        .filter((s) => s.text && s.text.length > 0)
    );
  } else {
    parts.push({ text: message, type: 'message' });
  }

  // Search the search and cut parts to include it
  if (search.length > 0) {
    const i = inputMessage.indexOf(search);
    let j = 0;

    let firstPart = 0;
    let lastPart = 0;
    let startIndexInFirstPart = 0;
    let endIndexInLastPart = 0;
    let firstPartFound = false;
    for (let k = 0; k < parts.length; k++) {
      const element = parts[k];
      if (j + element.text.length > i && !firstPartFound) {
        firstPart = k;
        startIndexInFirstPart = i - j;
        firstPartFound = true;
      }
      if (j + element.text.length > i + search.length) {
        lastPart = k;
        endIndexInLastPart = i + search.length - j;
        break;
      }
      j += element.text.length;
    }

    const centralParts =
      firstPart < lastPart
        ? [
            {
              parts: [
                { text: parts[firstPart].text.slice(0, startIndexInFirstPart), search: false },
                { text: parts[firstPart].text.slice(startIndexInFirstPart), search: true },
              ],
              type: parts[firstPart].type,
            },
            ...parts
              .slice(firstPart + 1, lastPart)
              .map((p) => ({ parts: [{ text: p.text, search: true }], type: p.type })),
            {
              parts: [
                { text: parts[lastPart].text.slice(0, endIndexInLastPart), search: true },
                { text: parts[lastPart].text.slice(endIndexInLastPart), search: false },
              ],
              type: parts[firstPart].type,
            },
          ]
        : [
            {
              parts: [
                { text: parts[firstPart].text.slice(0, startIndexInFirstPart), search: false },
                { text: parts[firstPart].text.slice(startIndexInFirstPart, endIndexInLastPart), search: true },
                { text: parts[firstPart].text.slice(endIndexInLastPart), search: false },
              ],
              type: parts[firstPart].type,
            },
          ];

    return [
      ...parts.slice(0, firstPart).map((p) => ({ parts: [{ text: p.text, search: false }], type: p.type })),
      ...centralParts,
      ...parts.slice(lastPart + 1).map((p) => ({ parts: [{ text: p.text, search: false }], type: p.type })),
    ].map((part, k) => ({ ...part, id: `${part.type}-${k}` }));
  }

  return parts.map((p, i) => ({ id: `${p.type}-${i}`, parts: [{ text: p.text, search: false }], type: p.type }));
}
