import { useMutation } from '@apollo/client';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import { ContentCategoriesQuery } from '../../../../../../__gqltypes__';
import { LoadingLogo } from '../../../../devtools/components/Modal';
import { UPLOAD_ASSET, UPLOAD_ICON_ASSET } from '../../../components/apolloQueries';
import SelectDropdown from '../../../components/SelectDropdown';
import { AVAILABILITY_TYPES, CURRENCY_TYPES } from '../utils';
import AssetDisplay from './AssetDisplay';
import DragUpload from './DragUpload';
import { Item, OnValidateItem, SetItemData } from './typedef';

type Props = {
  itemData: Item;
  setItemData: SetItemData;
  refetch: () => void;
  categories: ContentCategoriesQuery['app']['contentCategories'];
  onValidate?: OnValidateItem;
};

export default function ItemForm({ itemData, setItemData, refetch, categories, onValidate }: Props) {
  const isVinyl = itemData.__typename === 'AppSkin';

  const [uploadAsset, { loading: uploadLoading }] = useMutation(UPLOAD_ASSET, {
    onCompleted: () => refetch(),
  });
  const [uploadIconAsset, { loading: uploadIconAssetLoading }] = useMutation(UPLOAD_ICON_ASSET, {
    onCompleted: () => refetch(),
  });

  const handleUpload = (file: Blob) => {
    uploadAsset({
      variables: {
        input: {
          file,
          id: itemData.id,
        },
      },
    });
  };
  const handleIconAssetUpload = (file: Blob) => {
    uploadIconAsset({
      variables: {
        input: {
          file,
          id: itemData.id,
        },
      },
    });
  };

  // @ts-ignore
  const onAcquisitionSelect = (e) => {
    // @ts-ignore
    setItemData({
      ...itemData,
      liveOpsAvailability: e.target.value,
      cost:
        // @ts-ignore
        AVAILABILITY_TYPES[e.target.value].length > 0
          ? e.target.value === 'UNAVAILABLE'
            ? // @ts-ignore
              itemData.cost || { __typename: 'CurrencyCategoryFragment', cost: 0 }
            : // @ts-ignore
              { ...itemData.cost, __typename: CURRENCY_TYPES[AVAILABILITY_TYPES[e.target.value][0]] }
          : null,
    });
  };

  const options: Record<string, { value: React.ReactNode; searchValue: string }> = {};
  categories.forEach((category) => {
    options[category.id] = {
      value: (
        <>
          <Image roundedCircle className="img-profile mr-3" src={category.icon} style={{ width: 20, height: 20 }} />
          {category.name}
        </>
      ),
      searchValue: category.name,
    };
  });

  return (
    <Form>
      <Form.Group>
        <Form.Label>Id</Form.Label>
        <Form.Control placeholder={itemData.id} disabled />
      </Form.Group>
      <Form.Group>
        <Form.Label>Name</Form.Label>
        <Form.Control
          placeholder=""
          value={itemData.name}
          onChange={(e) => setItemData({ ...itemData, name: e.target.value })}
        />
      </Form.Group>
      {isVinyl && (
        <Form.Group>
          <Form.Label>Icon Asset</Form.Label>
          <DragUpload className="m-2" onUpload={handleIconAssetUpload} />
          <LoadingLogo show={uploadIconAssetLoading} />

          <>
            {itemData.iconAsset && <AssetDisplay className="m-2" assetUrl={itemData.iconAsset} />}
            <Row>
              <Col lg={2}>
                <Form.Label>Icon Primary Color</Form.Label>
              </Col>
              <Col lg={1}>
                <input type="color" name="primaryColor" value={itemData.iconPrimaryColor || ''} readOnly disabled />
              </Col>
              <Col lg={2}>
                <Form.Control
                  type="text"
                  placeholder="#RRGGBB"
                  name="primaryColorHex"
                  value={itemData.iconPrimaryColor || ''}
                  onChange={(e) => setItemData({ ...itemData, iconPrimaryColor: e.target.value })}
                />
              </Col>
            </Row>
            <br />

            <Row>
              <Col lg={2}>
                <Form.Label>Icon Background Color</Form.Label>
              </Col>
              <Col lg={1}>
                <input
                  type="color"
                  name="backgroundColor"
                  value={itemData.iconBackgroundColor || ''}
                  readOnly
                  disabled
                />
              </Col>
              <Col lg={2}>
                <Form.Control
                  type="text"
                  placeholder="#RRGGBB"
                  name="backgroundColorHex"
                  value={itemData.iconBackgroundColor || ''}
                  onChange={(e) => setItemData({ ...itemData, iconBackgroundColor: e.target.value })}
                />
              </Col>
            </Row>
          </>
        </Form.Group>
      )}
      <Form.Group>
        <Form.Label>Acquisition</Form.Label>
        <Form.Control as="select" value={itemData.liveOpsAvailability} onChange={onAcquisitionSelect}>
          {Object.keys(AVAILABILITY_TYPES).map((type) => (
            <option value={type} key={type}>
              {type}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
      <hr />
      <h5> Item level1 (base item) </h5>
      <DragUpload onUpload={handleUpload} onValidate={onValidate} />
      <LoadingLogo show={uploadLoading} />
      <Form.Group>
        <Form.Label>Asset</Form.Label>
        <Form.Control value={itemData.asset} disabled />
      </Form.Group>
      {!isVinyl && <AssetDisplay assetUrl={itemData.asset} />}
      {itemData.cost && (
        <div>
          <p>Cost</p>
          <Row>
            <Col>
              {/* @ts-ignore */}
              {AVAILABILITY_TYPES[itemData.liveOpsAvailability].length > 1 ? (
                <Form.Group>
                  <FormControl
                    as="select"
                    value={itemData.cost.__typename}
                    onChange={(e) =>
                      // @ts-ignore
                      setItemData({ ...itemData, cost: { ...itemData.cost, __typename: e.target.value } })
                    }
                  >
                    {/* @ts-ignore */}
                    {AVAILABILITY_TYPES[itemData.liveOpsAvailability].map((key) => (
                      // @ts-ignore
                      <option value={CURRENCY_TYPES[key]} key={key}>
                        {/* @ts-ignore */}
                        {CURRENCY_TYPES[key]}
                      </option>
                    ))}
                  </FormControl>
                </Form.Group>
              ) : (
                // @ts-ignore
                <p>{CURRENCY_TYPES[AVAILABILITY_TYPES[itemData.liveOpsAvailability][0]]}: </p>
              )}
            </Col>
            {itemData.cost.__typename === CURRENCY_TYPES.CURRENCY_CATEGORY_FRAGMENT && (
              <Col>
                <SelectDropdown
                  title="Select fragment currency"
                  options={options}
                  // @ts-ignore
                  value={itemData.cost?.category ? itemData.cost.category.id : null}
                  onSelect={(v) =>
                    setItemData({
                      ...itemData,
                      // @ts-ignore
                      cost: { ...itemData.cost, category: { id: v } },
                    })
                  }
                />
              </Col>
            )}
            <Col>
              <Form.Group>
                <FormControl
                  // @ts-ignore
                  value={itemData.cost?.amount ?? undefined}
                  // @ts-ignore
                  onChange={(e) => setItemData({ ...itemData, cost: { ...itemData.cost, amount: e.target.value } })}
                />
              </Form.Group>
            </Col>
          </Row>
        </div>
      )}
    </Form>
  );
}
