import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { Button, Card, Col, Modal, Nav, Row, Spinner, Tab } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import {
  CreateTournamentInput,
  CreateTournamentMutation,
  CreateTournamentMutationVariables,
  TournamentQuery,
  TournamentsQuery,
} from '../../../../../__gqltypes__';
import { LoadingLogo } from '../../../devtools/components/Modal';
import AddTournamentModal from './components/AddTournamentModal';
import TournamentData from './components/TournamentData';
import { CREATE_TOURNAMENT, GET_TOURNAMENT, GET_TOURNAMENTS } from './graphql';

const baseURL = '/sp3/liveops/tournaments';

type CreatedTournament = CreateTournamentMutation['createTournament']['tournament'];

/**
 * Component/Page to create or edit Tournaments
 * @returns
 */
const Tournament = () => {
  /**
   * GETTING ENV DATA
   */
  const { id: tournamentId } = useParams<{ id: string }>();
  const history = useHistory();

  /**
   * GRAPHQL
   */
  // Tournament Fetching logic
  const {
    data: tournamentsData,
    fetchMore,
    loading: tournamentLoading,
  } = useQuery<TournamentsQuery>(GET_TOURNAMENTS, {
    fetchPolicy: 'no-cache',
  });

  const [fetchTournament] = useLazyQuery<TournamentQuery>(GET_TOURNAMENT);

  // Tournament mutation Logic
  // creating a new tournament
  const [addTournament, { loading: addTournamentLoading }] = useMutation<
    CreateTournamentMutation,
    CreateTournamentMutationVariables
  >(CREATE_TOURNAMENT);

  /**
   * STATE
   */
  const [tournaments, setTournaments] = React.useState<
    {
      id: string;
      startDate: number;
    }[]
  >([]);
  const [showAddModal, setShowAddModal] = React.useState(false);
  const [showStopModal, setShowStopModal] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(1);
  const [paginationLoading, setPaginationLoading] = React.useState(false);
  const [lastElementRef, setLastElementRef] = React.useState<HTMLDivElement | null>(null);

  /**
   * Helpers function
   */

  const isTournamentSelected = React.useCallback(() => {
    return !_.isEmpty(tournamentId) && tournaments.find(({ id }) => id === tournamentId);
  }, [tournamentId, tournaments]);

  const selectTournament = React.useCallback(
    (newTournamentId) => {
      history.push(`${baseURL}/${newTournamentId}`);
    },
    [history]
  );

  // Tournament data methods
  /**
   * Update the list of tournaments after an upsert
   * @param {Tournament} quizTournament - the newly created tournament
   */
  const handleUpsertTournament = (tournament: CreatedTournament) => {
    // Remove the updated live show from the list if it was already in and add it with the updated value
    const otherTournaments = tournaments.filter(({ id }) => id !== tournament.id);
    const totalTournaments = [...otherTournaments, { id: tournament.id, startDate: tournament.startDate }];
    // Then sort the array
    setTournaments(_.orderBy(totalTournaments, 'startDate', 'desc'));
  };

  const handleRemoveTournament = (_tournamentId: string) => {
    const remainingTournaments = tournaments.filter(({ id }) => id !== _tournamentId);
    setTournaments(_.orderBy(remainingTournaments, 'startDate', 'desc'));
  };

  const handleCopiedTournament = (tournament: CreatedTournament) => {
    handleUpsertTournament(tournament);
    selectTournament(tournament.id);
  };

  // Tournament modal methods
  const handleAdd = (tournamentData: CreateTournamentInput) => {
    setShowAddModal(false);
    addTournament({ variables: { input: tournamentData } })
      // @ts-ignore
      .then(({ data: { createTournament } }) => {
        const newTournament: CreatedTournament = createTournament.tournament;
        handleUpsertTournament(newTournament);
        selectTournament(newTournament.id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const tryToFetchTournaments = async () => {
    if (tournamentId) {
      const res = await fetchTournament({ variables: { tournamentId } });
      const tournament = res?.data?.tournament;

      if (!_.isNil(tournament)) {
        const { id, startDate, name } = tournament;
        const _updatedTournaments = [{ id, startDate, name }, ...tournaments];
        setTournaments(_updatedTournaments);
        selectTournament(id);
      } else {
        selectTournament(tournaments[0].id);
      }
    } else {
      selectTournament(tournaments[0].id);
    }
  };

  /**
   * STATE UPDATE LOGIC
   */
  React.useEffect(() => {
    if (tournamentId === ':id') {
      selectTournament('');
    }
    if (!isTournamentSelected() && tournaments.length > 0) {
      // If tournamentId from url is not yet loaded in the tournament list (left menu)
      // We try to add this one to the list
      tryToFetchTournaments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTournamentSelected, selectTournament, tournamentId, tournaments]);

  React.useEffect(() => {
    if (tournamentsData && tournamentsData.liveOps) {
      const _tournamentData = _.orderBy(tournamentsData.liveOps.tournaments.list, 'startDate', 'desc');
      setTournaments(_tournamentData);
    }
  }, [tournamentsData]);

  React.useEffect(() => {
    if (
      tournamentsData &&
      tournamentsData.liveOps &&
      !isTournamentSelected() &&
      tournaments.length > 0 &&
      tournaments.length === tournamentsData.liveOps.tournaments.list.length
    ) {
      tryToFetchTournaments();
    }
  }, [isTournamentSelected, selectTournament, tournaments, tournamentsData]);

  /**
   * Pagination
   */
  const tournamentsLimit = 20;
  const maxPage = tournamentsData?.liveOps.tournaments.list
    ? tournamentsData.liveOps.tournaments.totalCount % tournamentsLimit === 0
      ? Math.floor(tournamentsData.liveOps.tournaments.totalCount / tournamentsLimit)
      : Math.floor(tournamentsData.liveOps.tournaments.totalCount / tournamentsLimit) + 1
    : 1;

  const observer = React.useRef(
    new IntersectionObserver((entries) => {
      const first = entries[0];
      if (first.isIntersecting) {
        setPageCount((no) => no + 1);
      }
    })
  );

  const loadNextPage = async () => {
    setPaginationLoading(true);
    const res = await fetchMore({
      variables: {
        offset: tournamentsLimit * (pageCount - 1),
        limit: tournamentsLimit,
      },
    });
    const data = res.data.liveOps.tournaments.list;
    const _updatedTournaments = _.uniqBy([...tournaments, ...data], 'id');
    setTournaments(_updatedTournaments);
    setPaginationLoading(false);
  };

  React.useEffect(() => {
    if (pageCount - 1 <= maxPage && pageCount > 1) {
      loadNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCount]);

  React.useEffect(() => {
    const currentElement = lastElementRef;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElementRef]);

  /**
   * RENDERING
   */

  return (
    <>
      <LoadingLogo show={tournamentLoading} />
      {isTournamentSelected() && (
        // @ts-ignore
        <Tab.Container className="h-100" activeKey={tournamentId} onSelect={(e) => selectTournament(e)}>
          <Row className="mb-5 h-100">
            <Col
              style={{
                maxHeight: 'calc(100vh - 5.875rem)',
                overflow: 'scroll',
              }}
            >
              <Nav.Item className="mb-3">
                {tournamentLoading || addTournamentLoading ? (
                  <Spinner animation="border" />
                ) : (
                  <>
                    <Button className="w-100" variant="success" onClick={() => setShowAddModal(true)}>
                      <i className="fas fa-calendar-day mr-2" />
                      Schedule a new tournament
                    </Button>
                  </>
                )}
              </Nav.Item>
              <Nav variant="pills">
                {tournaments.map((tournament, index) => (
                  <Nav.Item
                    ref={index === tournaments.length - 1 && !paginationLoading ? setLastElementRef : null}
                    key={`${tournament.id}-${index}date`}
                    className="w-100 text-center position-relative"
                  >
                    <Nav.Link
                      eventKey={tournament.id}
                      className={`justify-content-center ${
                        moment(tournament.startDate).isBefore(moment())
                          ? 'liveops-nav-link-old'
                          : 'liveops-nav-link-current'
                      }`}
                    >
                      {moment(tournament.startDate).format('DD/MM/YYYY')}
                      <br />
                      {moment(tournament.startDate).format('LT')}
                    </Nav.Link>
                  </Nav.Item>
                ))}
                {paginationLoading && <Spinner animation="border" />}
              </Nav>
            </Col>
            <Col
              style={{
                maxHeight: 'calc(100vh - 5.875rem)',
                overflow: 'scroll',
              }}
              className="col-10"
            >
              <Tab.Content className="h-100">
                <Card>
                  <Card.Body>
                    <TournamentData
                      tournamentId={tournamentId}
                      onUpsertTournament={handleUpsertTournament}
                      onRemoveTournament={handleRemoveTournament}
                      onCopiedTournament={handleCopiedTournament}
                    />
                  </Card.Body>
                </Card>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      )}

      <AddTournamentModal
        show={(!tournamentLoading && tournaments.length === 0) || showAddModal}
        loading={addTournamentLoading}
        onHide={() => setShowAddModal(false)}
        onAdd={handleAdd}
      />
      <Modal show={showStopModal} onHide={() => setShowStopModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Stop the current</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>This cannot be undone. Are you sure to want to stop the current show?</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowStopModal(false)}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              // stopCurrentTournament();
              setShowStopModal(false);
            }}
          >
            Stop show
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Tournament;
