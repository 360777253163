import React from 'react';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import Media from 'react-bootstrap/Media';
import { Link } from 'react-router-dom';
import { CurationContentPackQuery } from '../../../../__gqltypes__';

type Props = {
  playlist: NonNullable<CurationContentPackQuery["curationContentPack"]["replacingContentPack"]>;
} & React.HTMLAttributes<HTMLDivElement>;

const PlaylistMiniCard = ({ playlist, ...rest }: Props) => {
  return (
    <Card {...rest}>
      <Card.Body>
        <Media>
          <Image className="mr-3" height="64px" width="64px" src={playlist.picture} />
          <Media.Body>
            <Link to={`/sp3/playlist/${playlist.originId}`}>
              <h5>{playlist.name}</h5>
            </Link>
            {playlist.description}
          </Media.Body>
        </Media>
      </Card.Body>
    </Card>
  );
};

export default PlaylistMiniCard;
