import { useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import {
  Alert,
  Badge,
  Button,
  Col,
  Container,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from 'react-bootstrap';
import DateTimePicker from 'react-datetime';
import { applyChangeIfDateValid, validIfSameDayOrAfter } from '../../../../../utils/datepicker';
import type {
  ContentCategoriesQuery,
  CopyQuizLiveShowMutation,
  CopyQuizLiveShowMutationVariables,
  QuizLiveShowQuery,
  QuizLiveShowQueryVariables,
  RescheduleQuizLiveShowMutation,
  ResetQuizLiveShowRoundQuestionsMutation,
  ResetQuizLiveShowRoundQuestionsMutationVariables,
  ScheduleQuizLiveShowRoundEncryptionMutation,
  UploadLiveShowCharacterAssetMutation,
  UploadLiveShowHeaderAssetMutation,
  UpsertQuizLiveShowMutation,
  UpsertQuizLiveShowMutationVariables,
} from '../../../../../__gqltypes__';
import ContentPackListEditor from '../../../curation/screens/ContentPackList/ContentPackListEditor';
import { LoadingLogo } from '../../../devtools/components/Modal';
import { GET_CONTENT_CATEGORIES } from '../../components/apolloQueries';
import AssetDisplay from '../Items/components/AssetDisplay';
import DragUpload from '../Items/components/DragUpload';
import CopyLiveShowModal from './components/CopyLiveShowModal';
import LiveShowLogs from './components/LiveShowLogs';
import SpecialEventSelector from './components/SpecialEventSelector';
import {
  COPY_LIVE_SHOW,
  GET_QUIZ_LIVE_SHOW,
  RESCHEDULE_LIVE_SHOW,
  RESET_QUIZ_LIVE_SHOW_ROUND_QUESTIONS,
  SCHEDULE_LIVE_SHOW_ROUND_ENCRYPTION,
  UPDATE_LIVE_SHOW_CONTENT_PACKS,
  UPLOAD_CHARACTER_ASSET,
  UPLOAD_HEADER_ASSET,
  UPSERT_QUIZ_LIVE_SHOW,
} from './graphql';

type UpsertedQuizLiveShow = UpsertQuizLiveShowMutation['upsertQuizLiveShow'];
type CopiedQuizLiveShow = CopyQuizLiveShowMutation['copyQuizLiveShow'];
type QuizLiveShow = NonNullable<QuizLiveShowQuery['quizLiveShow']>;
type QuizLiveShowExtraConfig = QuizLiveShow['extraConfig'];
type QuizLiveShowRound = QuizLiveShow['rounds'][0];

export const RoundStatus = {
  OK: 'success', // The text is a Bootstrap property, do not change
  PENDING: 'warning',
  WRONG: 'danger',
};

// Status of 1 round of a liveshow
export const getRoundStatus = ({
  encryptedRoundAssetUrl,
  liveShowLastUpdateDate,
}: {
  encryptedRoundAssetUrl: string;
  liveShowLastUpdateDate?: number;
}) => {
  if (!_.isEmpty(encryptedRoundAssetUrl)) {
    // The round data has been encrypted after the last update
    return RoundStatus.OK;
  }
  if (moment(liveShowLastUpdateDate).isAfter(moment().subtract(5, 'minutes'))) {
    // The show has not been scheduled yet but it has been updated in the last 5 minutes: we mark it as pending
    return RoundStatus.PENDING;
  }
  return RoundStatus.WRONG;
};

type RoundDataProps = {
  round: QuizLiveShowRound;
  onResetRoundQuestions: (question: { questionDuration: number }) => void;
  onScheduleRoundEncryption: (question: { questionDuration: number }) => void;
  liveShowStartDate: number;
  liveShowLastUpdateDate: number;
  index: number;
  readonly: boolean;
};

const RoundData = ({
  round,
  onResetRoundQuestions,
  onScheduleRoundEncryption,
  liveShowStartDate,
  liveShowLastUpdateDate,
  index,
  readonly,
}: RoundDataProps) => {
  const handleResetQuestions = () => {
    onResetRoundQuestions({ questionDuration: round.questionDuration });
  };

  const handleScheduleEncryption = () => {
    onScheduleRoundEncryption({ questionDuration: round.questionDuration });
  };

  const getEncryptionStatus = ({ encryptedRoundAssetUrl }: { encryptedRoundAssetUrl: string }) => {
    if (moment(liveShowStartDate).isSameOrBefore(moment())) {
      return <></>;
    }
    const variant = getRoundStatus({ encryptedRoundAssetUrl, liveShowLastUpdateDate });
    return (
      <OverlayTrigger overlay={<Tooltip id="tooltip-scheduling-status">Encryption status</Tooltip>}>
        <Badge pill variant={variant} className={`text-${variant} ml-3 user-select-none`}>
          .
        </Badge>
      </OverlayTrigger>
    );
  };

  return (
    <>
      <Row className="mt-3 mb-3">
        <Col md={1}>{getEncryptionStatus(round)}</Col>
        <Col md={4}>Round {index + 1}:</Col>
        <Col md={4} className=" text-right align-self-center">
          {round.contentPack.name}
        </Col>
        <Col md={1} className="d-flex justify-content-right">
          {readonly || (
            <>
              <OverlayTrigger overlay={<Tooltip id="tooltip-reset-round">Reset round questions</Tooltip>}>
                <Button onClick={handleResetQuestions} variant="warning">
                  <i className="fas fa-redo" />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger overlay={<Tooltip id="tooltip-schedule-round-encryption">Re-encrypt round</Tooltip>}>
                <Button onClick={handleScheduleEncryption}>
                  <i className="fas fa-file-archive" />
                </Button>
              </OverlayTrigger>
            </>
          )}
        </Col>
      </Row>
      {round.liveOpsQuestions.map((question, questionIndex) => (
        <Row key={question.id}>
          <Col md={4}>Song {questionIndex + 1}: </Col>
          <Col md={4}>{question.song?.title}</Col>
          <Col md={4}>{question.song?.artist}</Col>
        </Row>
      ))}
    </>
  );
};

type LiveShowProps = {
  showId: string;
  onUpsertShow: (show: UpsertedQuizLiveShow) => void;
  removeLiveShow: () => Promise<void>;
  onShowCopied: (show: CopiedQuizLiveShow) => void;
};

const LiveShowData = ({ showId, onUpsertShow, removeLiveShow, onShowCopied }: LiveShowProps) => {
  // @ts-ignore
  const [liveShow, setLiveShow] = React.useState<QuizLiveShow>({});
  const [category, setCategory] = React.useState<string | null>(null);
  const [diamondPrize, setDiamondPrize] = React.useState<number | null>(null);
  const [startDate, setStartDate] = React.useState<Date | null>(null);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [showCopyModal, setShowCopyModal] = React.useState(false);
  const [showHeaderImageDeletionWarning, setShowHeaderImageDeletionWarning] = React.useState(false);
  const [showCharacterImageDeletionWarning, setShowCharacterImageDeletionWarning] = React.useState(false);
  // @ts-ignore
  const [extraConfig, setExtraConfig] = React.useState<QuizLiveShowExtraConfig>({
    primaryColor: '',
    secondaryColor: '',
    secondaryDarkColor: '',
    buttonColor: '',
    contrastGradient1: '',
    contrastGradient2: '',
    headerImage: '',
    characterImage: '',
  });

  React.useEffect(() => {
    if (showDeleteModal) {
      setShowCopyModal(false);
    }
  }, [showDeleteModal]);

  React.useEffect(() => {
    if (showCopyModal) {
      setShowDeleteModal(false);
    }
  }, [showCopyModal]);

  const {
    data,
    loading: loadingShow,
    refetch,
  } = useQuery<QuizLiveShowQuery, QuizLiveShowQueryVariables>(GET_QUIZ_LIVE_SHOW, {
    fetchPolicy: 'no-cache',
    variables: { showId },
  });

  const { loading: loadingCategories, data: dataCategories } = useQuery<ContentCategoriesQuery>(GET_CONTENT_CATEGORIES);
  const categories = dataCategories?.app.contentCategories ?? [];
  const [updateLiveShow, { loading: updateLoading }] = useMutation<
    UpsertQuizLiveShowMutation,
    UpsertQuizLiveShowMutationVariables
  >(UPSERT_QUIZ_LIVE_SHOW, {
    onCompleted: ({ upsertQuizLiveShow }) => {
      setLiveShow({ ...upsertQuizLiveShow });
      onUpsertShow(upsertQuizLiveShow);
    },
    onError: (error) => {
      console.log(error);
      // @ts-ignore
      setLiveShow({ ...data.quizLiveShow });
    },
  });
  const [resetRoundQuestions, { loading: resetQuestionsLoading }] = useMutation<
    ResetQuizLiveShowRoundQuestionsMutation,
    ResetQuizLiveShowRoundQuestionsMutationVariables
  >(RESET_QUIZ_LIVE_SHOW_ROUND_QUESTIONS, {
    onCompleted: ({ resetQuizLiveShowRoundQuestions }) => setLiveShow({ ...resetQuizLiveShowRoundQuestions }),
    onError: (error) => {
      console.log(error);
      // @ts-ignore
      setLiveShow({ ...data.quizLiveShow });
    },
  });
  const [scheduleRoundEncryption, { loading: scheduleRoundEncryptionLoading }] =
    useMutation<ScheduleQuizLiveShowRoundEncryptionMutation>(SCHEDULE_LIVE_SHOW_ROUND_ENCRYPTION, {
      onCompleted: ({ scheduleQuizLiveShowRoundEncryption }) => setLiveShow({ ...scheduleQuizLiveShowRoundEncryption }),
      onError: (error) => {
        console.log(error);
        // @ts-ignore
        setLiveShow({ ...data.quizLiveShow });
      },
    });
  const [uploadHeaderAsset, { loading: uploadHeaderLoading }] = useMutation<UploadLiveShowHeaderAssetMutation>(
    UPLOAD_HEADER_ASSET,
    {
      onCompleted: () => refetch(),
    }
  );
  const [uploadCharacterAsset, { loading: uploadCharacterLoading }] = useMutation<UploadLiveShowCharacterAssetMutation>(
    UPLOAD_CHARACTER_ASSET,
    {
      onCompleted: () => refetch(),
    }
  );

  const [rescheduleLiveShow, { loading: rescheduleLoading }] = useMutation<RescheduleQuizLiveShowMutation>(
    RESCHEDULE_LIVE_SHOW,
    {
      variables: { input: { showId } },
    }
  );

  const [copyQuizLiveShow, { loading: copyLoading }] = useMutation<
    CopyQuizLiveShowMutation,
    CopyQuizLiveShowMutationVariables
  >(COPY_LIVE_SHOW, {
    onCompleted: ({ copyQuizLiveShow }) => {
      setShowCopyModal(false);
      onShowCopied(copyQuizLiveShow);
    },
  });

  const [updateQuizLiveShowContentPacks, { loading: updateContentPacksLoading }] = useMutation(
    UPDATE_LIVE_SHOW_CONTENT_PACKS,
    {
      onCompleted: () => refetch(),
    }
  );

  React.useEffect(() => {
    if (data && data.quizLiveShow) {
      setLiveShow(data.quizLiveShow);
    }
  }, [data]);

  React.useEffect(() => {
    if (liveShow.category && liveShow.diamondPrize) {
      setCategory(liveShow.category.id);
      setDiamondPrize(liveShow.diamondPrize);
      setStartDate(new Date(liveShow.startDate));
    }
  }, [liveShow]);

  React.useEffect(() => {
    if (liveShow.extraConfig) {
      setExtraConfig(liveShow.extraConfig);
    }
  }, [liveShow]);

  const loading =
    loadingShow ||
    loadingCategories ||
    updateLoading ||
    resetQuestionsLoading ||
    scheduleRoundEncryptionLoading ||
    updateContentPacksLoading;

  // @ts-ignore
  const handleChangeCategory = (event) => {
    setCategory(event.target.value);
  };
  // @ts-ignore
  const handleChangeDiamondPrize = (event) => {
    setDiamondPrize(Math.max(1, +event.target.value));
  };
  // @ts-ignore
  const handleHeaderImageUpload = (file) => {
    uploadHeaderAsset({
      variables: {
        input: {
          file,
          id: showId,
        },
      },
    });
  };
  // @ts-ignore
  const handleCharacterImageUpload = (file) => {
    uploadCharacterAsset({
      variables: {
        input: {
          file,
          id: showId,
        },
      },
    });
  };

  const sanitizeDate = () => setStartDate((rawDate) => moment(rawDate).startOf('minute').toDate());

  const handleSave = () => {
    setShowHeaderImageDeletionWarning(false);
    setShowCharacterImageDeletionWarning(false);
    sanitizeDate();
    if (moment(startDate).isSameOrBefore(moment())) {
      alert('Invalid start date');
      return;
    }
    updateLiveShow({
      variables: {
        input: {
          showId,
          // @ts-ignore
          category,
          // @ts-ignore
          start: startDate,
          // @ts-ignore
          diamondPrize,
          extraConfig: {
            primaryColor: extraConfig.primaryColor,
            secondaryColor: extraConfig.secondaryColor,
            secondaryDarkColor: extraConfig.secondaryDarkColor,
            buttonColor: extraConfig.buttonColor,
            contrastGradient1: extraConfig.contrastGradient1,
            contrastGradient2: extraConfig.contrastGradient2,
            headerImage: extraConfig.headerImage,
            characterImage: extraConfig.characterImage,
          },
        },
      },
    });
  };

  const handleReschedule = () => {
    rescheduleLiveShow();
  };

  const handleResetRoundQuestions = (roundId: string) => () => {
    resetRoundQuestions({
      variables: {
        input: {
          showId,
          roundId,
        },
      },
    });
  };

  const handleScheduleRoundEncryption = (roundId: string) => () => {
    scheduleRoundEncryption({ variables: { input: { showId, roundId } } });
  };

  const handleCopyLiveShow = (startDate: number | Date) => {
    // @ts-ignore
    copyQuizLiveShow({ variables: { input: { showId, startDate } } });
  };

  const handleSaveContentPacks = (contentPackIds: string[]) => {
    updateQuizLiveShowContentPacks({ variables: { input: { showId, contentPackIds } } });
    return Promise.resolve();
  };

  const startedOrPast = React.useMemo(() => moment(liveShow.startDate).isBefore(moment()), [liveShow.startDate]);

  const readonly = loading || startedOrPast;

  return loading ? (
    <LoadingLogo show />
  ) : (
    <Container className="pb-4">
      <Row className="mb-3">
        <Col md={4}>
          {moment(liveShow.startDate).isAfter(moment().add(1, 'h')) ? (
            <DateTimePicker
              onChange={applyChangeIfDateValid(setStartDate)}
              isValidDate={validIfSameDayOrAfter}
              value={startDate ?? undefined}
            />
          ) : (
            new Date(liveShow.startDate).toLocaleString()
          )}
        </Col>
        <Col className="d-flex justify-content-end">
          {readonly ||
            (rescheduleLoading ? (
              <Spinner animation="border" />
            ) : (
              <Button className="mr-2" onClick={handleReschedule} variant="warning">
                <i className="fas fa-sync mr-2" />
                Reschedule
              </Button>
            ))}
          <Button className="mr-2" onClick={() => setShowDeleteModal(true)} variant="danger">
            <i className="fas fa-trash mr-2" />
            Delete
          </Button>
          {readonly || (
            <Button className="mr-2" onClick={handleSave} variant="success">
              Save
            </Button>
          )}
          <Button onClick={() => setShowCopyModal(true)} variant="primary">
            <i className="fas fa-copy mr-2" />
            Copy
          </Button>
        </Col>
      </Row>

      <Row>
        <Col md={4}>{liveShow.playerCount} Players</Col>
      </Row>

      <br />

      {startedOrPast && <LiveShowLogs showId={showId} />}

      <Row>
        <Col md={4}>Category:</Col>
        <Col md={4}>
          <Form.Control disabled={readonly} value={category ?? ''} onChange={handleChangeCategory} as="select">
            {categories.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Row>
      <Row>
        <Col md={4}>Diamond prize:</Col>
        <Col md={4}>
          <Form.Control
            disabled={readonly}
            value={diamondPrize ?? 1}
            onChange={handleChangeDiamondPrize}
            type="number"
          />
        </Col>
      </Row>
      <Row>
        <Col lg={2}>
          <Form.Label> Primary Color</Form.Label>
        </Col>
        <Col lg={1}>
          <input type="color" name="primaryColor" value={extraConfig.primaryColor || ''} />
        </Col>
        <Col lg={2}>
          <Form.Control
            type="text"
            placeholder="#RRGGBB"
            name="primaryColorHex"
            value={extraConfig.primaryColor || ''}
            onChange={(e) => setExtraConfig({ ...extraConfig, primaryColor: e.target.value || '' })}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={2}>
          <Form.Label> Secondary Color</Form.Label>
        </Col>
        <Col lg={1}>
          <input type="color" name="secondaryColor" value={extraConfig.secondaryColor || ''} />
        </Col>
        <Col lg={2}>
          <Form.Control
            type="text"
            placeholder="#RRGGBB"
            name="secondaryColorHex"
            value={extraConfig.secondaryColor || ''}
            onChange={(e) => setExtraConfig({ ...extraConfig, secondaryColor: e.target.value || '' })}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={2}>
          <Form.Label> Secondary Dark Color</Form.Label>
        </Col>
        <Col lg={1}>
          <input type="color" name="secondaryDarkColor" value={extraConfig.secondaryDarkColor || ''} />
        </Col>
        <Col lg={2}>
          <Form.Control
            type="text"
            placeholder="#RRGGBB"
            name="secondaryDarkColorHex"
            value={extraConfig.secondaryDarkColor || ''}
            onChange={(e) => setExtraConfig({ ...extraConfig, secondaryDarkColor: e.target.value || '' })}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={2}>
          <Form.Label> Button Color</Form.Label>
        </Col>
        <Col lg={1}>
          <input type="color" name="buttonColor" value={extraConfig.buttonColor || ''} />
        </Col>
        <Col lg={2}>
          <Form.Control
            type="text"
            placeholder="#RRGGBB"
            name="buttonColorHex"
            value={extraConfig.buttonColor || ''}
            onChange={(e) => setExtraConfig({ ...extraConfig, buttonColor: e.target.value || '' })}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={2}>
          <Form.Label> Contrast Gradient 1</Form.Label>
        </Col>
        <Col lg={1}>
          <input type="color" name="contrastGradient1" value={extraConfig.contrastGradient1 || '#000000'} />
        </Col>
        <Col lg={2}>
          <Form.Control
            disabled={readonly}
            type="text"
            placeholder="#RRGGBB"
            name="contrastGradient1Hex"
            value={extraConfig.contrastGradient1 || '#000000'}
            onChange={(e) => setExtraConfig({ ...extraConfig, contrastGradient1: e.target.value || '#000000' })}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={2}>
          <Form.Label> Contrast Gradient 2</Form.Label>
        </Col>
        <Col lg={1}>
          <input type="color" name="contrastGradient2" value={extraConfig.contrastGradient2 || '#000000'} />
        </Col>
        <Col lg={2}>
          <Form.Control
            disabled={readonly}
            type="text"
            placeholder="#RRGGBB"
            name="contrastGradient2Hex"
            value={extraConfig.contrastGradient2 || '#000000'}
            onChange={(e) => setExtraConfig({ ...extraConfig, contrastGradient2: e.target.value || '#000000' })}
          />
        </Col>
      </Row>
      <hr />

      <Row>
        <Col>
          <Form.Label> Header Image </Form.Label>
          {liveShow.extraConfig && liveShow.extraConfig.headerImage && (
            <AssetDisplay assetUrl={liveShow.extraConfig.headerImage} />
          )}
          <DragUpload onUpload={handleHeaderImageUpload} />
          <LoadingLogo show={uploadHeaderLoading} />
          <Button
            className="mt-2"
            onClick={() => {
              setExtraConfig({ ...extraConfig, headerImage: '' });
              setShowHeaderImageDeletionWarning(true);
            }}
            variant="danger"
          >
            <i className="fas fa-trash mr-2" />
            Delete
          </Button>
          {showHeaderImageDeletionWarning && (
            <Alert variant="danger">Click on Save to confirm the deletion of an image</Alert>
          )}
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <Form.Label> Character Image </Form.Label>
          {liveShow.extraConfig && liveShow.extraConfig.characterImage && (
            <AssetDisplay assetUrl={liveShow.extraConfig.characterImage} />
          )}
          <DragUpload onUpload={handleCharacterImageUpload} />
          <LoadingLogo show={uploadCharacterLoading} />
          <Button
            className="mt-2"
            onClick={() => {
              setExtraConfig({ ...extraConfig, characterImage: '' });
              setShowCharacterImageDeletionWarning(true);
            }}
            variant="danger"
          >
            <i className="fas fa-trash mr-2" />
            Delete
          </Button>
          {showCharacterImageDeletionWarning && (
            <Alert variant="danger">Click on Save to confirm the deletion of an image</Alert>
          )}
          <hr />
        </Col>
      </Row>
      {liveShow && <SpecialEventSelector quizLiveShowId={showId} specialEvent={liveShow.adminSpecialEvent} />}
      {(readonly && !loading) || (
        <Row className="mb-2">
          <Col>
            <ContentPackListEditor
              id={liveShow.id}
              contentPacks={liveShow?.rounds ? liveShow.rounds.map(({ contentPack }) => contentPack) : []}
              updatePackIds={handleSaveContentPacks}
              enableImport
              enableContinuousUpdate
            />
          </Col>
        </Row>
      )}
      {liveShow.rounds && (
        <Row className="mb-2">
          <Col>
            <h4>Rounds</h4>
            {liveShow.rounds.map((round, index) => (
              <RoundData
                key={`${index}-${round.contentPack.id}`}
                index={index}
                round={round}
                onResetRoundQuestions={handleResetRoundQuestions(round.id)}
                onScheduleRoundEncryption={handleScheduleRoundEncryption(round.id)}
                liveShowStartDate={liveShow.startDate}
                liveShowLastUpdateDate={liveShow.lastUpdateDate}
                readonly={readonly}
              />
            ))}
            <hr />
          </Col>
        </Row>
      )}

      {!startedOrPast && <LiveShowLogs showId={showId} />}

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete the show</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>This cannot be undone. Are you sure to want to remove the show?</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={async () => {
              await removeLiveShow();
              setShowDeleteModal(false);
            }}
          >
            Remove show
          </Button>
        </Modal.Footer>
      </Modal>
      <CopyLiveShowModal
        show={showCopyModal}
        onHide={() => setShowCopyModal(false)}
        onCopy={handleCopyLiveShow}
        loading={copyLoading}
      />
    </Container>
  );
};

export default LiveShowData;
