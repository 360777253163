import { useQuery } from '@apollo/client';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Badge, Col, Container, Row, Table } from 'react-bootstrap';
import DateTimePicker from 'react-datetime';

import { applyChangeIfDateValid, validIfSameDayOrAfter } from '../../../../../utils/datepicker';
import { LoadingLogo } from '../../../devtools/components/Modal';
import { GET_DDEX_JOB, GET_DDEX_JOB_TASKS } from './graphql';

import {
  DdexJobQuery,
  DdexJobQueryVariables,
  DdexJobTasksQuery,
  DdexJobTasksQueryVariables,
  JobState,
  TaskState,
} from '../../../../../__gqltypes__';
import DDEXJobLogs from './components/DDEXJobLogs';

type DDexJob = NonNullable<DdexJobQuery['ddexJob']>;
type DDEXJobProps = {
  jobId: string;
};

const DDEXJobData = ({ jobId }: DDEXJobProps) => {
  // @ts-ignore
  const [ddexJob, setDDEXJob] = useState<DDexJob>({});
  const [startDate, setStartDate] = useState<Date | null>(null);

  const { data, loading: loadingJobData } = useQuery<DdexJobQuery, DdexJobQueryVariables>(GET_DDEX_JOB, {
    fetchPolicy: 'no-cache',
    variables: { jobId },
  });

  const { data: tasksData, loading: loadingTasksData } = useQuery<DdexJobTasksQuery, DdexJobTasksQueryVariables>(
    GET_DDEX_JOB_TASKS,
    {
      fetchPolicy: 'no-cache',
      variables: { jobId },
    }
  );

  const getTaskStateStatus = (state: TaskState) => {
    let variant;
    switch (state) {
      case TaskState.DONE:
        variant = 'success';
        break;
      case TaskState.COPY_ASSET:
        variant = 'info';
        break;
      case TaskState.FAILED:
        variant = 'danger';
        break;
      default:
        variant = 'secondary';
    }
    return <Badge variant={variant}>{state}</Badge>;
  };

  const getJobStateStatus = (state: JobState) => {
    let variant;
    switch (state) {
      case JobState.DONE:
        variant = 'success';
        break;
      case JobState.COPY_XML:
      case JobState.IN_PROGRESS:
      case JobState.PARSING_XML:
      case JobState.TASKS_CREATED:
      case JobState.XML_PARSED:
        variant = 'info';
        break;
      case JobState.FAILED:
        variant = 'warning';
        break;
      case JobState.PAUSED:
        variant = 'danger';
        break;
      default:
        variant = 'secondary';
    }
    return <Badge variant={variant}>{state}</Badge>;
  };

  useEffect(() => {
    if (data && data.ddexJob) {
      setDDEXJob(data.ddexJob);
    }
  }, [data]);
  useEffect(() => {
    if (ddexJob.created) {
      setStartDate(new Date(ddexJob.created));
    }
  }, [ddexJob]);

  const loading = loadingJobData || loadingTasksData;

  return loading ? (
    <LoadingLogo show />
  ) : (
    <Container className="pb-4">
      <Row className="mb-3">
        <Col md={4}>
          {moment(ddexJob.created).isAfter(moment().add(1, 'h')) ? (
            <DateTimePicker
              onChange={applyChangeIfDateValid(setStartDate)}
              isValidDate={validIfSameDayOrAfter}
              value={startDate ?? undefined}
            />
          ) : (
            new Date(ddexJob.created).toLocaleString()
          )}
        </Col>
        <hr />
      </Row>
      <Row className="mb-3">
        <Col md={6}>Job ID: {ddexJob.jobId}</Col>
        <Col md={4}>State: {getJobStateStatus(ddexJob.state)}</Col>
        <hr />
      </Row>
      <DDEXJobLogs jobId={jobId} />
      {tasksData?.ddexJobTasks && (
        <Row className="mb-2">
          <Col>
            <h4>Tasks</h4>
            {tasksData.ddexJobTasks.length > 0 ? (
              <Table bordered responsive hover>
                <thead className="text-center">
                  <tr>
                    <th>TaskId</th>
                    <th>State</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {tasksData.ddexJobTasks.map((task, taskIndex) => (
                    <tr key={taskIndex}>
                      <td className="align-middle">{task.taskId}</td>
                      <td className="align-middle">{getTaskStateStatus(task.state)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <div>There is no task for this Job</div>
            )}
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default DDEXJobData;
