import { useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import DateTimePicker from 'react-datetime';
import {
  SpecialEventQuery,
  UpdateSpecialEventMilestonesMutation,
  UpdateSpecialEventMilestonesMutationVariables,
  UpdateSpecialEventPlusMilestonesMutation,
  UpdateSpecialEventPlusMilestonesMutationVariables,
  UpdateSpecialEventRankGroupsMutation,
  UpdateSpecialEventRankGroupsMutationVariables,
} from '../../../../../../__gqltypes__';
import { applyChangeIfDateValid } from '../../../../../../utils/datepicker';
import ContentPackListEditor from '../../../../curation/screens/ContentPackList/ContentPackListEditor';
import { LoadingLogo } from '../../../../devtools/components/Modal';
import AssetDisplay from '../../Items/components/AssetDisplay';
import DragUpload from '../../Items/components/DragUpload';
import { MilestoneValue } from '../../Tournament/components/MileStones';
import { RankGroupValue } from '../../Tournament/components/RankGroups';
import {
  GET_SPECIAL_EVENT,
  REMOVE_SPECIAL_EVENT,
  UPDATE_SPECIAL_EVENT,
  UPDATE_SPECIAL_EVENT_CONTENT_PACKS,
  UPDATE_SPECIAL_EVENT_MILESTONES,
  UPDATE_SPECIAL_EVENT_PLUS_MILESTONES,
  UPDATE_SPECIAL_EVENT_RANKGROUPS,
  UPLOAD_SPECIAL_EVENT_ASSET,
} from '../graphql';
import '../typedef';
import { checkRankGroupErrors, getReward, toGameCenterId } from '../utils';
import MileStone from './MileStones';
import SpecialEventMissions from './Missions';
import { RankGroup } from './RankGroups';
import SpecialEventQuizLiveShow from './SpecialEventQuizLiveShow';
import SpecialEventTournaments from './SpecialEventTournaments';

/**
 * A component showing the data of a specialEvent
 */
type Props = {
  specialEventId: string;
  onUpsertSpecialEvent: (data: any) => void;
  onRemoveSpecialEvent: (data: any) => void;
};

export enum SpecialEventAssetType {
  BACKGROUND = 'BACKGROUND',
  HEADER = 'HEADER',
  ICON = 'ICON',
}

const SpecialEventData = ({ specialEventId, onUpsertSpecialEvent, onRemoveSpecialEvent }: Props) => {
  /**
   * State
   */

  // Currently displayed specialEvent
  /**
   * @type {[SpecialEvent, Function]} Currently displayed SpecialEvent
   */
  const [name, setName] = useState('');
  const [gameCenterId, setGameCenterId] = useState('');
  const [description, setDescription] = useState('');

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [isDraft, setIsDraft] = useState(false);
  const [configWarnings, setConfigWarnings] = useState<string[]>([]);
  // @ts-ignore
  const [assetsConfig, setAssetsConfig] = useState<NonNullable<SpecialEventQuery['specialEvent']>['assetsConfig']>({
    __typename: 'SpecialEventAssetsConfig',
  });
  const [readonly, setReadonly] = useState(true);
  // selected category
  /**
   * @type {[MileStone[], Function]}
   */
  const [milestones, setMilestones] = useState<any[]>([]);
  const [plusMilestones, setPlusMilestones] = useState<any[]>([]);
  /**
   * @type {[RankGroup[], Function]}
   */
  const [rankgroups, setRankgroups] = useState<any[]>([]);

  // Milestones exceed maximum amount suported
  const [hasExceededMaxMilestones, setHasExceededMaxMilestones] = useState<boolean>(false);

  // Error detected in rankgroup
  const [rankGroupErrors, setRankGroupErrors] = useState<string[]>([]);

  // pop-up state management ----------
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [showHeaderImageDeletionWarning, setShowHeaderImageDeletionWarning] = useState(false);
  const [showBackgroundImageDeletionWarning, setShowBackgroundImageDeletionWarning] = useState(false);
  const [showIconDeletionWarning, setShowIconDeletionWarning] = useState(false);

  /**
   * FETCHING
   */
  const {
    data,
    loading: loadingSpecialEvent,
    refetch,
  } = useQuery(GET_SPECIAL_EVENT, {
    fetchPolicy: 'no-cache',
    variables: { specialEventId },
  });

  const [updateSpecialEvent, { loading: updateLoading }] = useMutation(UPDATE_SPECIAL_EVENT, {
    onCompleted: ({ updateSpecialEvent: _updateSpecialEvent }) => {
      const updatedSpecialEvent = _updateSpecialEvent.specialEvent;
      onUpsertSpecialEvent(updatedSpecialEvent);
      refetch();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const [uploadHeaderAsset, { loading: uploadHeaderLoading }] = useMutation(UPLOAD_SPECIAL_EVENT_ASSET, {
    onCompleted: ({ uploadSpecialEventAsset: { specialEvent } }) => {
      setAssetsConfig({ ...assetsConfig, headerImage: specialEvent.assetsConfig.headerImage });
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const [uploadBackgroundAsset, { loading: uploadBackgroundLoading }] = useMutation(UPLOAD_SPECIAL_EVENT_ASSET, {
    onCompleted: ({ uploadSpecialEventAsset: { specialEvent } }) => {
      setAssetsConfig({ ...assetsConfig, backgroundImage: specialEvent.assetsConfig.backgroundImage });
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const [uploadIconAsset, { loading: uploadIconLoading }] = useMutation(UPLOAD_SPECIAL_EVENT_ASSET, {
    onCompleted: ({ uploadSpecialEventAsset: { specialEvent } }) => {
      setAssetsConfig({ ...assetsConfig, icon: specialEvent.assetsConfig.icon });
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const [uploadSpecialEventAsset, { loading: uploadSpecialEventAssetLoading }] = useMutation(
    UPLOAD_SPECIAL_EVENT_ASSET,
    {
      onCompleted: ({ uploadSpecialEventAsset: { specialEvent } }) => {
        setAssetsConfig({ ...assetsConfig, headerImage: specialEvent.assetsConfig.headerImage });
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const [updateSpecialEventMilestones, { loading: updateMilestonesLoading }] = useMutation<
    UpdateSpecialEventMilestonesMutation,
    UpdateSpecialEventMilestonesMutationVariables
  >(UPDATE_SPECIAL_EVENT_MILESTONES, {
    onCompleted: ({ updateSpecialEventMilestones: { specialEvent } }) => {
      setAndSanitizeMilestones(specialEvent.milestones);
      setConfigWarnings(specialEvent.configWarnings);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const [updateSpecialEventPlusMilestones, { loading: updatePlusMilestonesLoading }] = useMutation<
    UpdateSpecialEventPlusMilestonesMutation,
    UpdateSpecialEventPlusMilestonesMutationVariables
  >(UPDATE_SPECIAL_EVENT_PLUS_MILESTONES, {
    onCompleted: ({ updateSpecialEventPlusMilestones: { specialEvent } }) => {
      setAndSanitizePlusMilestones(specialEvent.plusMilestones);
      setConfigWarnings(specialEvent.configWarnings);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const [updateSpecialEventRankGroups, { loading: updateRankGroupLoading }] = useMutation<
    UpdateSpecialEventRankGroupsMutation,
    UpdateSpecialEventRankGroupsMutationVariables
  >(UPDATE_SPECIAL_EVENT_RANKGROUPS, {
    onCompleted: ({ updateSpecialEventRankGroups: { specialEvent } }) => {
      setAndSanitizeRankGroups(specialEvent.rankGroups);
      setConfigWarnings(specialEvent.configWarnings);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const [updateSpecialEventContentPacks] = useMutation(UPDATE_SPECIAL_EVENT_CONTENT_PACKS);

  const [removeSpecialEvent, { loading: removeLoading }] = useMutation(REMOVE_SPECIAL_EVENT, {
    onCompleted: ({ removeSpecialEvent: _removeSpecialEvent }) => {
      if (!_removeSpecialEvent.isRemoved) {
        alert("ERROR: Couldn't remove the current specialEvent");
        return;
      }
      onRemoveSpecialEvent(specialEventId);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const loading = loadingSpecialEvent || updateLoading || removeLoading;

  /**
   * STATE AUTO UPDATE FUNCTIONS
   */
  // set current specialEvent to the one fetched
  useEffect(() => {
    if (data && data.specialEvent) {
      const fetchedSpecialEvent = data.specialEvent;
      setName(fetchedSpecialEvent.name);
      setGameCenterId(toGameCenterId(fetchedSpecialEvent.name));
      setDescription(fetchedSpecialEvent.description);
      setStartDate(new Date(fetchedSpecialEvent.startDate));
      setEndDate(new Date(fetchedSpecialEvent.endDate));
      setIsDraft(fetchedSpecialEvent.isDraft);
      setConfigWarnings(fetchedSpecialEvent.configWarnings);
      const newAssetsConfig = fetchedSpecialEvent.assetsConfig;
      // removing unwanted auto added key by apollo
      setAssetsConfig(newAssetsConfig);
      // Convert reward format from graphql one
      setAndSanitizeMilestones(fetchedSpecialEvent.milestones);
      setAndSanitizePlusMilestones(fetchedSpecialEvent.plusMilestones);
      setAndSanitizeRankGroups(fetchedSpecialEvent.rankGroups);
    } else if (!data || !data.specialEvent) {
      setName('');
      setGameCenterId('');
      setDescription('');
      setStartDate(null);
      setEndDate(null);
      setIsDraft(false);
      setConfigWarnings([]);
      setAssetsConfig({ __typename: 'SpecialEventAssetsConfig' });
      const newMilestones: MilestoneValue[] = [];
      const rankGroup: RankGroupValue[] = [];
      setAndSanitizeMilestones(newMilestones);
      setAndSanitizePlusMilestones(newMilestones);
      setAndSanitizeRankGroups(rankGroup);
    }
  }, [data]);

  // whenever rankgroups are updated, we check if there are mistakes
  useEffect(() => {
    setRankGroupErrors(checkRankGroupErrors(rankgroups));
  }, [rankgroups]);

  useEffect(() => {
    const read = loading;
    setReadonly(read);
  }, [loading, data]);

  /**
   * CHANGES HANDLERS FUNCTIONS
   */
  const handleSave = () => {
    updateSpecialEvent({
      variables: {
        input: {
          specialEventId,
          name,
          description,
          startDate,
          endDate,
          isDraft,
          assetsConfig: {
            primaryColor: assetsConfig.primaryColor,
            secondaryColor: assetsConfig.secondaryColor,
            secondaryDarkColor: assetsConfig.secondaryDarkColor,
            buttonColor: assetsConfig.buttonColor,
            contrast: assetsConfig.contrast,
            contrastGradient1: assetsConfig.contrastGradient1,
            contrastGradient2: assetsConfig.contrastGradient2,
            headerImage: assetsConfig.headerImage,
            backgroundImage: assetsConfig.backgroundImage,
            icon: assetsConfig.icon,
          },
        },
      },
    });
  };

  const handleSaveMilestones = (newMilestones: MilestoneValue[]) => {
    updateSpecialEventMilestones({
      variables: {
        input: {
          specialEventId,
          milestones: newMilestones.map((milestone) => ({
            score: _.toNumber(milestone.score),
            rewards: milestone.rewards.map((reward) => ({
              type: reward.type,
              amount: _.toNumber(reward.amount),
              itemId: reward.itemId,
            })),
          })),
        },
      },
    });
  };

  const handleSavePlusMilestones = (newPlusMilestones: MilestoneValue[]) => {
    updateSpecialEventPlusMilestones({
      variables: {
        input: {
          specialEventId,
          plusMilestones: newPlusMilestones.map((plusMilestone) => ({
            score: _.toNumber(plusMilestone.score),
            rewards: plusMilestone.rewards.map((reward) => ({
              type: reward.type,
              amount: _.toNumber(reward.amount),
              itemId: reward.itemId,
            })),
          })),
        },
      },
    });
  };

  const setAndSanitizeMilestones = (newMilestones: MilestoneValue[]) => {
    const sanitizedMilestones = newMilestones.map((milestone) => ({
      ...milestone,
      rewards: milestone.rewards.map((reward) => getReward(reward)),
    }));
    setMilestones(_.orderBy(sanitizedMilestones, ['score'], ['asc']));
    setHasExceededMaxMilestones(plusMilestones.length + sanitizedMilestones.length > 16);
  };

  const setAndSanitizePlusMilestones = (newPlusMilestones: MilestoneValue[]) => {
    const sanitizedPlusMilestones = newPlusMilestones.map((plusMilestone) => ({
      ...plusMilestone,
      rewards: plusMilestone.rewards.map((reward) => getReward(reward)),
    }));
    setPlusMilestones(_.orderBy(sanitizedPlusMilestones, ['score'], ['asc']));
    setHasExceededMaxMilestones(milestones.length + sanitizedPlusMilestones.length > 16);
  };

  const setAndSanitizeRankGroups = (newRankGroups: RankGroupValue[]) => {
    const sanitizedRankGroups = newRankGroups.map((rankGroups) => ({
      ...rankGroups,
      rewards: rankGroups.rewards.map((reward) => getReward(reward)),
    }));
    setRankgroups(sanitizedRankGroups);
  };

  const handleSaveRankGroups = (newRankgroups: RankGroupValue[]) => {
    updateSpecialEventRankGroups({
      variables: {
        input: {
          specialEventId,
          rankGroups: newRankgroups.map((rankgroup) => ({
            minRank: _.toNumber(rankgroup.minRank),
            maxRank: _.toNumber(rankgroup.maxRank),
            rewards: rankgroup.rewards.map((reward) => ({
              type: reward.type,
              amount: _.toNumber(reward.amount),
              itemId: reward.itemId,
            })),
          })),
        },
      },
    });
  };

  const handleSaveContentPacks = async (contentPackIds: string[]) => {
    await updateSpecialEventContentPacks({
      variables: {
        input: {
          specialEventId,
          contentPackIds,
        },
      },
    });
    refetch();
  };

  const handleRemoveSpecialEvent = () => {
    removeSpecialEvent({
      variables: {
        input: {
          specialEventId,
        },
      },
    });
  };

  const handleChangeName = useCallback((event) => {
    setName(event.target.value);
    setGameCenterId(toGameCenterId(event.target.value));
  }, []);

  const handleChangeDescription = useCallback((event) => {
    setDescription(event.target.value);
  }, []);

  const handleHeaderImageUpload = useCallback(
    (file) => {
      uploadHeaderAsset({
        variables: {
          input: {
            file,
            specialEventId,
            type: SpecialEventAssetType.HEADER,
          },
        },
      });
    },
    [specialEventId, uploadHeaderAsset]
  );

  const handleBackgroundImageUpload = useCallback(
    (file) => {
      uploadBackgroundAsset({
        variables: {
          input: {
            file,
            specialEventId,
            type: SpecialEventAssetType.BACKGROUND,
          },
        },
      });
    },
    [specialEventId, uploadBackgroundAsset]
  );

  const handleIconUpload = useCallback(
    (file) => {
      uploadIconAsset({
        variables: {
          input: {
            file,
            specialEventId,
            type: SpecialEventAssetType.ICON,
          },
        },
      });
    },
    [specialEventId, uploadIconAsset]
  );

  /**
   * RENDER
   */
  return loading ? (
    <LoadingLogo show />
  ) : (
    <Container className="pb-4">
      {configWarnings.length > 0 ? (
        <div className="alert alert-danger" role="alert">
          <ul>
            {configWarnings.map((configWarning: string, index: number) => (
              <li key={index}>{configWarning}</li>
            ))}
          </ul>
        </div>
      ) : (
        <div className="alert alert-success" role="alert">
          Configuration seems ok
        </div>
      )}
      <Row className="mb-3">
        <Col md={4}>Start date UTC:</Col>
        <Col md={4}>
          <DateTimePicker
            // For the moment, only 00:00 formats are expects
            dateFormat="YYYY-MM-DD"
            utc={true}
            timeFormat={true}
            timeConstraints={{ minutes: { min: 0, max: 0, step: 0 } }}
            onChange={applyChangeIfDateValid(setStartDate)}
            value={startDate ?? undefined}
          />
        </Col>
        <Col className="d-flex justify-content-end">
          <Button
            className="mr-2"
            onClick={() => {
              setShowRemoveModal(!showRemoveModal);
            }}
            variant="danger"
            disabled={readonly}
          >
            <i className="fas fa-trash mr-2" />
            Delete
          </Button>
          {readonly || (
            <>
              <Button onClick={handleSave} variant="success">
                Save
              </Button>
            </>
          )}
        </Col>
      </Row>

      <Row className="mb-3">
        <Col md={4}>End date UTC:</Col>
        <Col md={4}>
          <DateTimePicker
            // For the moment, only 00:00 formats are expects
            dateFormat="YYYY-MM-DD"
            utc={true}
            timeFormat={true}
            timeConstraints={{ minutes: { min: 0, max: 0, step: 0 } }}
            onChange={applyChangeIfDateValid(setEndDate)}
            value={endDate ?? undefined}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col md={4}> Name:</Col>
        <Col md={4}>
          <Form.Control disabled={readonly} value={name} onChange={handleChangeName} type="text" />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col md={4}> GameCenter Id:</Col>
        <Col md={4}>
          <Form.Control disabled value={gameCenterId} type="text" />
        </Col>
        <Col md={4}>
          <Button
            onClick={() => {
              navigator.clipboard.writeText(gameCenterId);
            }}
            variant="primary"
          >
            <i className="fas fa-copy mr-2" />
            Copy
          </Button>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col md={4}> Description:</Col>
        <Col md={4}>
          <Form.Control
            disabled={readonly}
            value={description}
            onChange={handleChangeDescription}
            type="text"
            as="textarea"
            // @ts-ignore
            rows="3"
          />
        </Col>
      </Row>

      <Row>
        <Col md={4}>Is Draft</Col>
        <Col md={4}>
          <Form.Check
            type="checkbox"
            checked={isDraft}
            onChange={({ target }) => {
              setIsDraft(target.checked);
            }}
          />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col lg={2}>
          <Form.Label> Primary Color</Form.Label>
        </Col>
        <Col lg={1}>
          <input type="color" name="primaryColor" value={assetsConfig.primaryColor || '#000000'} />
        </Col>
        <Col lg={2}>
          <Form.Control
            disabled={readonly}
            type="text"
            placeholder="#RRGGBB"
            name="primaryColorHex"
            value={assetsConfig.primaryColor || '#000000'}
            onChange={(e) => setAssetsConfig({ ...assetsConfig, primaryColor: e.target.value || '#000000' })}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={2}>
          <Form.Label> Secondary Color</Form.Label>
        </Col>
        <Col lg={1}>
          <input type="color" name="secondaryColor" value={assetsConfig.secondaryColor || '#000000'} />
        </Col>
        <Col lg={2}>
          <Form.Control
            disabled={readonly}
            type="text"
            placeholder="#RRGGBB"
            name="secondaryColorHex"
            value={assetsConfig.secondaryColor || '#000000'}
            onChange={(e) => setAssetsConfig({ ...assetsConfig, secondaryColor: e.target.value || '#000000' })}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={2}>
          <Form.Label> Secondary Dark Color</Form.Label>
        </Col>
        <Col lg={1}>
          <input type="color" name="secondaryDarkColor" value={assetsConfig.secondaryDarkColor || '#000000'} />
        </Col>
        <Col lg={2}>
          <Form.Control
            disabled={readonly}
            type="text"
            placeholder="#RRGGBB"
            name="secondaryDarkColorHex"
            value={assetsConfig.secondaryDarkColor || '#000000'}
            onChange={(e) => setAssetsConfig({ ...assetsConfig, secondaryDarkColor: e.target.value || '#000000' })}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={2}>
          <Form.Label> Button Color</Form.Label>
        </Col>
        <Col lg={1}>
          <input type="color" name="buttonColor" value={assetsConfig.buttonColor || '#000000'} />
        </Col>
        <Col lg={2}>
          <Form.Control
            disabled={readonly}
            type="text"
            placeholder="#RRGGBB"
            name="buttonColorHex"
            value={assetsConfig.buttonColor || '#000000'}
            onChange={(e) => setAssetsConfig({ ...assetsConfig, buttonColor: e.target.value || '#000000' })}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={2}>
          <Form.Label> Contrast</Form.Label>
        </Col>
        <Col lg={1}>
          <input type="color" name="contrast" value={assetsConfig.contrast || '#000000'} />
        </Col>
        <Col lg={2}>
          <Form.Control
            disabled={readonly}
            type="text"
            placeholder="#RRGGBB"
            name="contrastHex"
            value={assetsConfig.contrast || '#000000'}
            onChange={(e) => setAssetsConfig({ ...assetsConfig, contrast: e.target.value || '#000000' })}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={2}>
          <Form.Label> Contrast Gradient 1</Form.Label>
        </Col>
        <Col lg={1}>
          <input type="color" name="contrastGradient1" value={assetsConfig.contrastGradient1 || '#000000'} />
        </Col>
        <Col lg={2}>
          <Form.Control
            disabled={readonly}
            type="text"
            placeholder="#RRGGBB"
            name="contrastGradient1Hex"
            value={assetsConfig.contrastGradient1 || '#000000'}
            onChange={(e) => setAssetsConfig({ ...assetsConfig, contrastGradient1: e.target.value || '#000000' })}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={2}>
          <Form.Label> Contrast Gradient 2</Form.Label>
        </Col>
        <Col lg={1}>
          <input type="color" name="contrastGradient2" value={assetsConfig.contrastGradient2 || '#000000'} />
        </Col>
        <Col lg={2}>
          <Form.Control
            disabled={readonly}
            type="text"
            placeholder="#RRGGBB"
            name="contrastGradient2Hex"
            value={assetsConfig.contrastGradient2 || '#000000'}
            onChange={(e) => setAssetsConfig({ ...assetsConfig, contrastGradient2: e.target.value || '#000000' })}
          />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <Form.Label> Header Image </Form.Label>
          {assetsConfig && assetsConfig.headerImage && <AssetDisplay assetUrl={assetsConfig.headerImage} />}
          <DragUpload onUpload={handleHeaderImageUpload} />
          <LoadingLogo show={uploadHeaderLoading} />
          <Button
            disabled={readonly}
            className="mt-2"
            onClick={() => {
              setAssetsConfig({ ...assetsConfig, headerImage: '' });
              setShowHeaderImageDeletionWarning(true);
            }}
            variant="danger"
          >
            <i className="fas fa-trash mr-2" />
            Delete
          </Button>
          {showHeaderImageDeletionWarning && (
            <Alert variant="danger">Click on Save to confirm the deletion of an image</Alert>
          )}
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <Form.Label> Background Image </Form.Label>
          {assetsConfig && assetsConfig.backgroundImage && <AssetDisplay assetUrl={assetsConfig.backgroundImage} />}
          <DragUpload onUpload={handleBackgroundImageUpload} />
          <LoadingLogo show={uploadBackgroundLoading} />
          <Button
            disabled={readonly}
            className="mt-2"
            onClick={() => {
              setAssetsConfig({ ...assetsConfig, backgroundImage: '' });
              setShowBackgroundImageDeletionWarning(true);
            }}
            variant="danger"
          >
            <i className="fas fa-trash mr-2" />
            Delete
          </Button>
          {showBackgroundImageDeletionWarning && (
            <Alert variant="danger">Click on Save to confirm the deletion of an image</Alert>
          )}
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <Form.Label> Icon </Form.Label>
          {assetsConfig && assetsConfig.icon && <AssetDisplay assetUrl={assetsConfig.icon} />}
          <DragUpload onUpload={handleIconUpload} />
          <LoadingLogo show={uploadIconLoading} />
          <Button
            disabled={readonly}
            className="mt-2"
            onClick={() => {
              setAssetsConfig({ ...assetsConfig, icon: '' });
              setShowIconDeletionWarning(true);
            }}
            variant="danger"
          >
            <i className="fas fa-trash mr-2" />
            Delete
          </Button>
          {showIconDeletionWarning && <Alert variant="danger">Click on Save to confirm the deletion of an image</Alert>}
        </Col>
      </Row>
      <hr />

      {data && data.specialEvent && (
        <SpecialEventTournaments specialEventId={specialEventId} tournaments={data.specialEvent.tournaments} />
      )}

      {data && data.specialEvent && (
        <SpecialEventQuizLiveShow specialEventId={specialEventId} quizLiveShows={data.specialEvent.quizLiveShows} />
      )}

      {data && data.specialEvent && (
        // @ts-ignore
        <ContentPackListEditor
          contentPacks={data.specialEvent.contentPacks}
          updatePackIds={handleSaveContentPacks}
          enableImport
        />
      )}
      <hr />
      {data &&
        (updateMilestonesLoading ? (
          <LoadingLogo show />
        ) : (
          <MileStone
            disabled={readonly}
            hasExceededMaxMilestones={hasExceededMaxMilestones}
            milestones={milestones}
            onChangedMilestones={(newMilestones) => {
              setMilestones(_.orderBy(newMilestones, ['score'], ['asc']));
              setHasExceededMaxMilestones(plusMilestones.length + newMilestones.length > 16);
            }}
            onUpload={handleSaveMilestones}
          />
        ))}

      <hr />
      {data &&
        (updatePlusMilestonesLoading ? (
          <LoadingLogo show />
        ) : (
          <MileStone
            isPlus
            disabled={readonly}
            hasExceededMaxMilestones={hasExceededMaxMilestones}
            milestones={plusMilestones}
            onChangedMilestones={(newPlusMilestones) => {
              setPlusMilestones(_.orderBy(newPlusMilestones, ['score'], ['asc']));
              setHasExceededMaxMilestones(milestones.length + newPlusMilestones.length > 16);
            }}
            onUpload={handleSavePlusMilestones}
          />
        ))}

      <hr />
      {data &&
        data.specialEvent &&
        (updateRankGroupLoading ? (
          <LoadingLogo show />
        ) : (
          <>
            <RankGroup
              disabled={readonly}
              rankgroups={rankgroups}
              onChangedRankgroups={(newRankgroups) => {
                setRankgroups(_.orderBy(newRankgroups, ['newRankgroups'], ['desc']));
              }}
              onUpload={handleSaveRankGroups}
            />
            {rankGroupErrors.map((error) => (
              <Alert key={error} variant="danger">
                {error}
              </Alert>
            ))}
          </>
        ))}

      {data && data.specialEvent && (
        <>
          <SpecialEventMissions
            missions={data.specialEvent.missions}
            specialEventId={specialEventId}
            startDate={data.specialEvent.startDate}
            endDate={data.specialEvent.endDate}
            refetch={refetch}
          />
          {rankGroupErrors.map((error) => (
            <Alert key={error} variant="danger">
              {error}
            </Alert>
          ))}
        </>
      )}

      <Modal show={showRemoveModal} onHide={() => setShowRemoveModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Stop the current</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>This cannot be undone. Are you sure to want to remove the current SpecialEvent?</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowRemoveModal(false)}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={async () => {
              handleRemoveSpecialEvent();
              setShowRemoveModal(false);
            }}
          >
            Remove Special Event
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};
export default SpecialEventData;
