import { useQuery } from '@apollo/client';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useRouteMatch } from 'react-router-dom';
import { LoadingLogo } from '../../components/Modal';
import { GET_SESSION_LOGS, GET_USER_FROM_SESSION } from '../../graphql';
import LiveLogsTable from './LiveLogsTable';
import { groupApolloEvents, idToTimestamp } from './models';

export default () => {
  const match = useRouteMatch();
  const liveSessionIDs = match.params.ids;
  const [sessionID1, sessionID2] = liveSessionIDs.split('_');
  const [processedLogs, setProcessedLogs] = useState([]);

  const { data: user1Logs, loading: loading1 } = useQuery(GET_SESSION_LOGS, {
    fetchPolicy: 'no-cache',
    variables: { sessionID: sessionID1, sessionDate: moment(idToTimestamp(sessionID1)) },
  });
  const { data: user2Logs, loading: loading2 } = useQuery(GET_SESSION_LOGS, {
    fetchPolicy: 'no-cache',
    variables: { sessionID: sessionID2, sessionDate: moment(idToTimestamp(sessionID2)) },
  });

  const { data: user1 } = useQuery(GET_USER_FROM_SESSION, {
    variables: { property: sessionID1, type: 'BY_SESSION' },
    fetchPolicy: 'no-cache',
  });

  const { data: user2 } = useQuery(GET_USER_FROM_SESSION, {
    variables: { property: sessionID2, type: 'BY_SESSION' },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (user1Logs?.analytics.appLogsBySession) {
      const prLogs = [];
      const _errors = [];
      ['frontendLogs', 'backendLogs', 'crashLogs'].forEach((key) => {
        const { data: logsData, error } = user1Logs.analytics.appLogsBySession[key];
        logsData.forEach((log) => {
          prLogs.push(JSON.parse(log));
        });
        if (error) {
          _errors.push(JSON.parse(error));
        }
      });
      setProcessedLogs((log) => log.concat(groupApolloEvents(prLogs, true)));
    }
  }, [user1Logs]);

  useEffect(() => {
    if (user2Logs?.analytics.appLogsBySession) {
      const prLogs = [];
      const _errors = [];
      ['frontendLogs', 'backendLogs', 'crashLogs'].forEach((key) => {
        const { data: logsData, error } = user2Logs.analytics.appLogsBySession[key];
        logsData.forEach((log) => {
          prLogs.push(JSON.parse(log));
        });
        if (error) {
          _errors.push(JSON.parse(error));
        }
      });
      setProcessedLogs((log) => log.concat(groupApolloEvents(prLogs)));
    }
  }, [user2Logs]);

  return (
    <>
      <LoadingLogo show={loading1 || loading2} />
      <Row>
        <Col sm="8">
          {processedLogs ? (
            <LiveLogsTable
              processedLogs={processedLogs}
              hasFilters
              sessionID1={sessionID1}
              sessionID2={sessionID2}
              user1={user1}
              user2={user2}
            />
          ) : (
            <p>No logs found</p>
          )}
        </Col>
      </Row>
    </>
  );
};
