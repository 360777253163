import React from 'react';
import { Button } from 'react-bootstrap';

type Props = {
  title: string;
  textToCopy: string | null;
};

export function ClipboardButton({ title, textToCopy }: Props) {
  const [copied, setCopied] = React.useState(false);

  return (
    <Button
      size="sm"
      disabled={copied}
      variant="link"
      onClick={() => {
        navigator.clipboard.writeText(textToCopy ?? '').then(() => {
          setCopied(true);
          setTimeout(() => {
            setCopied(false);
          }, 2000);
        });
      }}
    >
      <i className="fas fa-clipboard mr-2" />
      {copied ? 'Copied' : title}
    </Button>
  );
}
