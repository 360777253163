import Table from 'react-bootstrap/Table';
import { CurationSongDetailsQuery } from '../../../../../../__gqltypes__';

type STORE_KEY = 'us' | 'gb' | 'fr' | 'br' | 'de' | 'es';

const STORES: Record<STORE_KEY, string> = {
  us: 'US',
  gb: 'GB',
  fr: 'FR',
  br: 'BR',
  de: 'DE',
  es: 'ES',
};

type ExternalLinks = Extract<
  CurationSongDetailsQuery['curationContentItem'],
  { __typename: 'CurationSong' }
>['externalLinks'];
type Props = {
  externalLinks: ExternalLinks;
};

export default function ExternalLinksTable({ externalLinks }: Props) {
  const { itunes, spotify } = externalLinks;

  const tbody = (Object.keys(STORES) as STORE_KEY[]).map((key) => (
    <tr key={key}>
      <td>{STORES[key]}</td>
      <td>{itunes[key] || 'No link found'}</td>
      <td>{spotify[key] || 'No link found'}</td>
    </tr>
  ));

  return (
    <Table striped bordered responsive>
      <thead>
        <tr>
          <td>Store</td>
          <td>ITunes ID</td>
          <td>Spotify URI</td>
        </tr>
      </thead>
      <tbody>{tbody}</tbody>
    </Table>
  );
}
