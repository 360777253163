import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { Button, Card, Col, Modal, Nav, Row, Spinner, Tab } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import {
  SpecialEventsQuery,
  SpecialEventQuery,
  SpecialEvent as SpecialEventType,
  RedisCacheModel,
} from '../../../../../__gqltypes__';
import { LoadingLogo } from '../../../devtools/components/Modal';
import FlushButton from '../../../devtools/screens/Cache/FlushButton';
import AddSpecialEventModal from './components/AddSpecialEventModal';
import SpecialEventData from './components/SpecialEventData';
import { CREATE_SPECIAL_EVENT, GET_SPECIAL_EVENTS, GET_SPECIAL_EVENT } from './graphql';

const baseURL = '/sp3/liveops/specialevent';

/**
 * Component/Page to create or edit SpecialEvent
 * @returns
 */
const SpecialEvent = () => {
  /**
   * GETTING ENV DATA
   */
  const { id: specialEventId }: { id: string } = useParams();
  const history = useHistory();

  /**
   * GRAPHQL
   */

  const {
    data: specialEventsData,
    fetchMore,
    loading: specialEventLoading,
  } = useQuery<SpecialEventsQuery>(GET_SPECIAL_EVENTS, {
    fetchPolicy: 'no-cache',
  });

  const [fetchSpecialEvent] = useLazyQuery<SpecialEventQuery>(GET_SPECIAL_EVENT);

  // SpecialEvent mutation Logic
  // creating a new specialEvent
  const [addSpecialEvent, { loading: addSpecialEventLoading }] = useMutation(CREATE_SPECIAL_EVENT);

  /**
   * STATE
   */
  /**
   * @type {[SpecialEvent[], Function]} Loading
   */
  const [specialEvents, setSpecialEvents] = React.useState<Partial<SpecialEventType>[]>([]);
  const [showAddModal, setShowAddModal] = React.useState(false);
  const [showStopModal, setShowStopModal] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(1);
  const [paginationLoading, setPaginationLoading] = React.useState(false);
  const [lastElementRef, setLastElementRef] = React.useState<HTMLDivElement | null>(null);
  /**
   * Helpers function
   */

  const isSpecialEventSelected = React.useCallback(() => {
    return !_.isEmpty(specialEventId) && specialEvents.find(({ id }) => id === specialEventId);
  }, [specialEventId, specialEvents]);

  const selectSpecialEvent = React.useCallback(
    (newSpecialEventId) => {
      history.push(`${baseURL}/${newSpecialEventId}`);
    },
    [history]
  );

  // SpecialEvent data methods
  /**
   * Update the list of specialEvents after an upsert
   * @param {SpecialEvent} quizSpecialEvent - the newly created specialEvent
   */
  const handleUpsertSpecialEvent = (specialEvent: NonNullable<SpecialEventQuery['specialEvent']>) => {
    // Remove the updated specialEvent from the list if it was already in and add it with the updated value
    const otherSpecialEvents = specialEvents.filter(({ id }) => id !== specialEvent.id);
    const totalSpecialEvents = [...otherSpecialEvents, { id: specialEvent.id, startDate: specialEvent.startDate }];
    // Then sort the array
    setSpecialEvents(_.orderBy(totalSpecialEvents, 'startDate', 'desc'));
  };

  const handleRemoveSpecialEvent = (_specialEventId: string) => {
    const remainingSpecialEvents = specialEvents.filter(({ id }) => id !== _specialEventId);
    setSpecialEvents(_.orderBy(remainingSpecialEvents, 'startDate', 'desc'));
  };

  // SpecialEvent modal methods
  const handleAdd = (specialEventData: { startDate: Date; endDate: Date }) => {
    setShowAddModal(false);
    addSpecialEvent({ variables: { input: specialEventData } })
      .then(({ data: { createSpecialEvent } }) => {
        const newSpecialEvent = createSpecialEvent.specialEvent;
        handleUpsertSpecialEvent(newSpecialEvent);
        selectSpecialEvent(newSpecialEvent.id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const tryToFetchSpecialEvent = async () => {
    if (specialEventId) {
      const res = await fetchSpecialEvent({ variables: { specialEventId } });
      const specialEvent = res?.data?.specialEvent;

      if (!_.isNil(specialEvent)) {
        const { id, startDate, name } = specialEvent;
        const _updatedSpecialEvents = [{ id, startDate, name }, ...specialEvents];
        setSpecialEvents(_updatedSpecialEvents);
        selectSpecialEvent(id);
      } else {
        selectSpecialEvent(specialEvents[0].id);
      }
    } else {
      selectSpecialEvent(specialEvents[0].id);
    }
  };

  /**
   * STATE UPDATE LOGIC
   */
  React.useEffect(() => {
    if (specialEventId === ':id') {
      selectSpecialEvent('');
    }
    if (!isSpecialEventSelected() && specialEvents.length > 0) {
      // If specialEventId from url is not yet loaded in the specialEvent list (left menu)
      // We try to add this one to the list
      tryToFetchSpecialEvent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSpecialEventSelected, selectSpecialEvent, specialEventId, specialEvents]);

  React.useEffect(() => {
    if (specialEventsData && specialEventsData.liveOps) {
      const _specialEventData = _.orderBy(specialEventsData.liveOps.specialEvents.list, 'startDate', 'desc');
      setSpecialEvents(_specialEventData);
    }
  }, [specialEventsData]);

  React.useEffect(() => {
    if (
      specialEventsData &&
      specialEventsData.liveOps &&
      !isSpecialEventSelected() &&
      specialEvents.length > 0 &&
      specialEvents.length === specialEventsData.liveOps.specialEvents.list.length
    ) {
      tryToFetchSpecialEvent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSpecialEventSelected, selectSpecialEvent, specialEvents, specialEventsData]);

  /**
   * Pagination
   */
  const specialEventsLimit = 20;
  const maxPage = specialEventsData?.liveOps.specialEvents.list
    ? specialEventsData.liveOps.specialEvents.totalCount % specialEventsLimit === 0
      ? Math.floor(specialEventsData.liveOps.specialEvents.totalCount / specialEventsLimit)
      : Math.floor(specialEventsData.liveOps.specialEvents.totalCount / specialEventsLimit) + 1
    : 1;

  const observer = React.useRef(
    new IntersectionObserver((entries) => {
      const first = entries[0];
      if (first.isIntersecting) {
        setPageCount((no) => no + 1);
      }
    })
  );

  const loadNextPage = async () => {
    setPaginationLoading(true);
    const res = await fetchMore({
      variables: {
        pagination: { offset: specialEventsLimit * (pageCount - 1), limit: specialEventsLimit },
      },
    });
    const data = res.data.liveOps.specialEvents.list;
    const _updatedSpecialEvents = _.uniqBy([...specialEvents, ...data], 'id');
    setSpecialEvents(_updatedSpecialEvents);
    setPaginationLoading(false);
  };

  React.useEffect(() => {
    if (pageCount - 1 <= maxPage && pageCount > 1) {
      loadNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCount]);

  React.useEffect(() => {
    const currentElement = lastElementRef;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElementRef]);

  /**
   * RENDERING
   */

  return (
    <>
      <LoadingLogo show={specialEventLoading} />
      {isSpecialEventSelected() && (
        <Tab.Container activeKey={specialEventId} onSelect={(e) => selectSpecialEvent(e)}>
          <Row className="mb-5 h-100">
            <Col
              style={{
                maxHeight: 'calc(100vh - 5.875rem)',
                overflow: 'scroll',
              }}
            >
              <Nav.Item className="mb-3">
                {specialEventLoading || addSpecialEventLoading ? (
                  <Spinner animation="border" />
                ) : (
                  <>
                    <Button className="w-100" variant="success" onClick={() => setShowAddModal(true)}>
                      <i className="fas fa-calendar-day mr-2" />
                      Schedule a new special event
                    </Button>
                  </>
                )}
              </Nav.Item>

              <Nav.Item className="mb-3">
                <FlushButton model={RedisCacheModel.SPECIAL_EVENT} />
              </Nav.Item>

              <Nav variant="pills">
                {specialEvents.map((specialEvent, index) => (
                  <Nav.Item
                    ref={index === specialEvents.length - 1 && !paginationLoading ? setLastElementRef : null}
                    key={`${specialEvent.id}-${index}date`}
                    className="w-100 text-center position-relative"
                  >
                    <Nav.Link
                      eventKey={specialEvent.id}
                      className={`justify-content-center ${
                        moment(specialEvent.startDate).isBefore(moment())
                          ? 'liveops-nav-link-old'
                          : 'liveops-nav-link-current'
                      }`}
                    >
                      {moment(specialEvent.startDate).format('DD/MM/YYYY')}
                      <br />
                      {moment(specialEvent.startDate).format('LT Z')}
                    </Nav.Link>
                  </Nav.Item>
                ))}
                {paginationLoading && <Spinner animation="border" />}
              </Nav>
            </Col>
            <Col
              style={{
                maxHeight: 'calc(100vh - 5.875rem)',
                overflow: 'scroll',
              }}
              className="col-10"
            >
              <Tab.Content className="h-100">
                <Card>
                  <Card.Body>
                    <SpecialEventData
                      specialEventId={specialEventId}
                      onUpsertSpecialEvent={handleUpsertSpecialEvent}
                      onRemoveSpecialEvent={handleRemoveSpecialEvent}
                    />
                  </Card.Body>
                </Card>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      )}

      <AddSpecialEventModal
        show={(!specialEventLoading && specialEvents.length === 0) || showAddModal}
        loading={addSpecialEventLoading}
        onHide={() => setShowAddModal(false)}
        onAdd={handleAdd}
      />
      <Modal show={showStopModal} onHide={() => setShowStopModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Stop the current</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>This cannot be undone. Are you sure to want to stop the current show?</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowStopModal(false)}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              setShowStopModal(false);
            }}
          >
            Stop show
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SpecialEvent;
