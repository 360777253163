import React, { useState } from 'react';
import _ from 'lodash';
import Collapse from 'react-bootstrap/Collapse';
import { useRouteMatch } from 'react-router-dom';
import { IconTypes, logsParamsMap } from '../config';
import { parseTimestamp, SourceIcon } from '../models';
import { ApolloOperationLog } from './ApolloOperationLog';
import { BasicDisplay } from './ParamDisplay';

export const CollapseLog = ({ log, os = null, showModal, defaultColor = '#F3F3F3' }) => {
  const [show, setShow] = useState(false);
  const match = useRouteMatch();

  const handleCollapse = () => {
    setShow((lastShow) => !lastShow);
  };

  const getIconType = (_os) => {
    if (log.event_name === 'apollo_operation') {
      return IconTypes.isApollo;
    }
    if (log.instance_id || log.event_params.runtime_id) {
      return IconTypes.isBackend;
    }
    if (log.event_name === 'crash') {
      return IconTypes.isCrash;
    }
    return _os;
  };

  const flagDebug = log.event_name.includes('debug');

  const flagError =
    ['error', 'failed', 'crash'].includes(log.event_name) ||
    (log.event_name.includes('apollo') && log.event_params.failed_date);
  let logName = null;
  let logParam = null;
  if (logsParamsMap[log.event_name] && logsParamsMap[log.event_name].display) {
    logName = logsParamsMap[log.event_name].display;
    const mainParamKey = logsParamsMap[log.event_name].mainParam;
    if (mainParamKey && log.event_params[mainParamKey]) {
      logParam = log.event_params[mainParamKey];
    }
    const secondaryParamKey = logsParamsMap[log.event_name].secondaryParam;
    if (secondaryParamKey && log.event_params[secondaryParamKey] && logParam) {
      logParam += `: ${log.event_params[secondaryParamKey]}`;
    }
  }

  return (
    <>
      <tr
        onClick={handleCollapse}
        bgcolor={flagError ? '#FE6B6B' : defaultColor}
        className={flagError ? 'text-white' : flagDebug ? 'border-left-info' : ''}
      >
        <td>
          {show ? <i className="fas fa-chevron-right m-2 fa-rotate-90" /> : <i className="fas fa-chevron-right m-2" />}
        </td>
        <td>
          <SourceIcon iconType={getIconType(os)} />
        </td>
        <td>{parseTimestamp(log.event_timestamp)}</td>
        <td>{logName || log.event_name}</td>
        <td>{logParam || ''}</td>
      </tr>
      <tr>
        <td className={show ? '' : 'pt-0 pb-0'} colSpan={5}>
          <Collapse in={show}>
            <div>
              <div className="d-flex flex-wrap" style={{ maxWidth: '65vw' }}>
                {log.event_name === 'apollo_operation' ? (
                  <ApolloOperationLog params={log.event_params} showModal={showModal} />
                ) : log.event_params ? (
                  _.sortBy(Object.entries(log.event_params), (o) => {
                    return o[0];
                  }).map((item) => (
                    <BasicDisplay key={item[0]} title={item[0]} value={item[1]} match={match} showModal={showModal} />
                  ))
                ) : (
                  <p>No params to display</p>
                )}
              </div>
            </div>
          </Collapse>
        </td>
      </tr>
    </>
  );
};
