import React from 'react';
import Badge from 'react-bootstrap/Badge';

const BadgeExplicit = ({ variant, text }: { variant: string; text: string }) => (
  <Badge pill className="m-1" variant={variant}>
    {text.toLowerCase().replace(/_/g, ' ')}
  </Badge>
);

type Props = {
  showClean?: boolean;
  explicitStatus?: 'CLEAN' | 'EXPLICIT' | 'CAN_BE_FILTERED';
};

export default function PlaylistExplicitIcon({ explicitStatus, showClean }: Props) {
  if (!explicitStatus) {
    return null;
  }
  if (explicitStatus === 'CLEAN') {
    if (showClean) return <BadgeExplicit variant="success" text={explicitStatus} />;
    return null;
  }
  return <BadgeExplicit variant={explicitStatus === 'EXPLICIT' ? 'danger' : 'info'} text={explicitStatus} />;
}
