import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import Image from 'react-bootstrap/Image';
import Media from 'react-bootstrap/Media';
import Modal from 'react-bootstrap/Modal';
import type {
  CurationContentItemDdexQuery,
  CurationContentItemDdexQueryVariables,
  CurationContentItemItunesQuery,
  CurationContentItemItunesQueryVariables,
  CurationContentItemQuery,
  CurationContentItemQueryVariables,
  CurationContentPackQuery,
  DdexSong,
  ItunesSong,
  RemoveSongFromCurationContentPackMutation,
  RemoveSongFromCurationContentPackMutationVariables,
  UpdateCurationSongInputType,
  UpdateCurationSongMutation,
  UpdateCurationSongMutationVariables,
  UpdateCurationSongSampleInputType,
  UpdateCurationSongSampleMutation,
  UpdateCurationSongSampleMutationVariables,
  UpdatePlaylistCustomDisplayMutation,
  UpdatePlaylistCustomDisplayMutationVariables,
} from '../../../../../__gqltypes__';
import ExplicitIcon from '../../components/ExplicitIcon';
import Loader from '../../components/Loader';
import QuestionableIcon from '../../components/QuestionableIcon';
import TooltipButton from '../../components/TooltipButton';
import { SubmitPayload } from '../../components/UploadSong';
import { SearchType } from '../../screens/SongSearch/components/SearchForm';
import SongSearchContent from '../../screens/SongSearch/components/SongSearchContent';
import mutations from '../../utils/mutations';
import queries from '../../utils/queries';
import { getFirstArtist } from '../utils';
import AlternativeItunesSong from './AlternativeItunesSong';
import EditCustomFields from './components/EditCustomFields';
import ExternalLinksTable from './components/ExternalLinksTable';
import SongInputInfo from './components/SongInputInfo';
import useManualMatch from './useManualMatch';

type CurationContentPack = CurationContentPackQuery['curationContentPack'];
type CurationSong = Extract<CurationContentItemQuery['curationContentItem'], { __typename: 'CurationSong' }>;
type CurationDDEXSong = Extract<CurationContentItemDdexQuery['curationContentItem'], { __typename: 'CurationSong' }>;
type CurationItunesSong = Extract<
  CurationContentItemItunesQuery['curationContentItem'],
  { __typename: 'CurationSong' }
>;

// validate sample size
const validateSamplePromise = (file: Blob | MediaSource) =>
  new Promise((resolve, reject) => {
    const audio = document.createElement('audio');
    audio.onloadedmetadata = () => {
      if (audio.duration < 29) {
        alert(`Validation failed, input should be 30s, given: ${audio.duration}s`);
        reject(audio.duration);
      } else {
        resolve(audio.duration);
      }
    };
    audio.src = URL.createObjectURL(file);
  });

type Props = {
  show: boolean;
  songId: string | null | undefined;
  contentPack: CurationContentPack;
  songInputId: string;
  onHide: () => void;
  onSelectPrevious: () => void;
  onSelectNext: () => void;
};

export default function PlaylistSongPopup({
  show,
  songId,
  contentPack,
  songInputId,
  onHide,
  onSelectPrevious,
  onSelectNext,
}: Props) {
  const { loading, data } = useQuery<CurationContentItemQuery, CurationContentItemQueryVariables>(
    queries.CURATION_SONG,
    {
      // @ts-ignore
      variables: { id: songId, contentPackId: contentPack.id, songInputId },
      skip: !songId || !show,
    }
  );
  const { loading: ddexLoading, data: ddexData } = useQuery<
    CurationContentItemDdexQuery,
    CurationContentItemDdexQueryVariables
  >(queries.CURATION_DDEX_SONG, {
    // @ts-ignore
    variables: { id: songId, songInputId },
    skip: !songId || !show,
  });

  const [fetchItunesSong, { data: itunesData, loading: itunesLoading }] = useLazyQuery<
    CurationContentItemItunesQuery,
    CurationContentItemItunesQueryVariables
  >(queries.CURATION_ITUNES_SONG, {
    // @ts-ignore
    variables: { id: songId, songInputId },
    skip: !songId || !show,
  });

  const song = data?.curationContentItem as CurationSong;

  const ddexSong = (ddexData?.curationContentItem as Partial<CurationDDEXSong>)?.ddexSong as DdexSong;
  const itunesSong = (itunesData?.curationContentItem as Partial<CurationItunesSong>)?.itunesSong as ItunesSong;
  const [updateCurationSong] = useMutation<UpdateCurationSongMutation, UpdateCurationSongMutationVariables>(
    mutations.UPDATE_CURATION_SONG
  );

  const [updateCurationSongSample] = useMutation<
    UpdateCurationSongSampleMutation,
    UpdateCurationSongSampleMutationVariables
  >(mutations.UPDATE_CURATION_SONG_SAMPLE);
  const [updatePlaylistCustomDisplay] = useMutation<
    UpdatePlaylistCustomDisplayMutation,
    UpdatePlaylistCustomDisplayMutationVariables
  >(mutations.UPDATE_PLAYLIST_CUSTOM_DISPLAY);

  const [removeSongFromPlaylist, { loading: removing }] = useMutation<
    RemoveSongFromCurationContentPackMutation,
    RemoveSongFromCurationContentPackMutationVariables
  >(mutations.REMOVE_SONG_FROM_PLAYLIST);

  const [songSearchState, setSongSearchState] = useState<null | 'SHOW' | 'SHOW_DDEX' | 'LOADING'>(null);
  const [showItunes, setShowItunes] = useState(false);

  useEffect(() => {
    if (!show) {
      setSongSearchState(null);
      setShowItunes(false);
    }
  }, [show]);

  useEffect(() => {
    if (show) {
      const downHandler = (event: KeyboardEvent) => {
        const { key } = event;
        if (key === 'ArrowLeft') {
          onSelectPrevious();
        } else if (key === 'ArrowRight') {
          onSelectNext();
        }
      };

      window.addEventListener('keydown', downHandler);

      return () => {
        window.removeEventListener('keydown', downHandler);
      };
    }
    return undefined;
  }, [onSelectNext, onSelectPrevious, show]);

  const [suggestedReplacedSong, handleReplacingSong] = useManualMatch({
    songId: songId ?? '',
    songInputId,
    contentPack,
  });

  const handleRemoveSong = () => {
    if (
      window.confirm(`Are you sure you want to remove this song from ${contentPack.name}? There is no coming back.`)
    ) {
      removeSongFromPlaylist({ variables: { songInputId, id: contentPack.id } }).then(() => {
        onHide();
      });
    }
  };

  const onUpdateCurationSong = (value: string, type: UpdateCurationSongInputType) => {
    const { id } = song;
    return updateCurationSong({
      variables: {
        id,
        contentPackId: contentPack.id,
        type,
        value,
        songInputId,
      },
    });
  };

  const onUpdateCurationSongSample = async (payload: SubmitPayload, type: UpdateCurationSongSampleInputType) => {
    if ('file' in payload) {
      await validateSamplePromise(payload.file);
    }

    return updateCurationSongSample({
      variables: {
        id: song.id,
        contentPackId: contentPack.id,
        file: 'file' in payload ? payload.file : null,
        url: 'url' in payload ? payload.url : null,
        startingTime: 'startingTime' in payload ? payload.startingTime : null,
        type,
        songInputId,
      },
    });
  };

  const onUpdatePlaylistCustomDisplay = (value: string) => {
    return updatePlaylistCustomDisplay({
      variables: {
        id: song.id,
        contentPackId: contentPack.id,
        songInputId,
        value,
      },
    });
  };

  if (!songId) return null;

  if (loading || !song || !song.externalLinks) {
    return (
      <Modal show={show} onHide={onHide} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
        <Loader />
      </Modal>
    );
  }

  const showDDEXSongSearch = () => {
    setSongSearchState('SHOW_DDEX');
  };

  const onReplacedSongSelected = (
    replacingItunesSong: { id: string },
    matchType: typeof SearchType[keyof typeof SearchType]
  ) => {
    console.log('replacing', replacingItunesSong, matchType);
    setSongSearchState('LOADING');
    handleReplacingSong(replacingItunesSong.id, matchType).then((success) => {
      if (success) {
        setSongSearchState(null);
        onHide();
      } else {
        setSongSearchState('SHOW_DDEX');
      }
    });
  };

  const onShowItunes = async () => {
    setShowItunes(!showItunes);
    await fetchItunesSong({ variables: { id: songId, songInputId } });
  };

  const { songInput, externalLinks } = song;

  return (
    <Modal show={show} onHide={onHide} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="modal-title-buttons">
          <h3>
            <Button variant="link" onClick={onSelectPrevious}>
              <i className="fas fa-chevron-left" />
            </Button>{' '}
            Song Details{' '}
            <Button variant="link" onClick={onSelectNext}>
              <i className="fas fa-chevron-right" />
            </Button>
          </h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card className="border-left-secondary mb-4">
          <Card.Body>
            <SongInputInfo
              songInput={songInput}
              showDDEXSongSearch={showDDEXSongSearch}
              songSearchState={songSearchState}
            />
          </Card.Body>
        </Card>
        <CardGroup>
          <Card className="border-left-primary ">
            <Card.Body>
              <Card.Title>DDEX result </Card.Title>
              {song.isDDEXSong ? (
                <Media>
                  <Image src={song.picture} thumbnail className="artwork-popup" />
                  <Media.Body>
                    Title: {song.title} <ExplicitIcon explicit={song.isExplicit} />
                    <QuestionableIcon questionable={song.questionableStatus || null} />
                    <br />
                    Artist: {song.artist}
                    <br />
                    Album: {song.album}
                    <br />
                    Isrc: {song.isrc}
                    <br />
                    Release year: {song.albumYear}
                    <div className="mt-2">
                      Song ID: <span className="font-italic">{song.id}</span>
                      <br />
                      {'Legal: '}
                      <Badge pill variant={song.state === 'BLACKLISTED' ? 'danger' : 'success'}>
                        {`${song.label} - ${song.subLabel}`}
                      </Badge>
                      {song.stateDetail && (
                        <Badge pill variant="warning">
                          {song.stateDetail}
                        </Badge>
                      )}
                      <br />
                    </div>
                  </Media.Body>
                </Media>
              ) : (
                <div>no alternative result</div>
              )}
            </Card.Body>
          </Card>
          {showItunes && (
            <Card className="border-left-primary ml-2">
              <Card.Body>
                <Card.Title>Itunes result</Card.Title>
                {ddexLoading || itunesLoading ? (
                  <Loader />
                ) : _.isNil(ddexSong) && _.isNil(itunesSong) ? (
                  <div>no alternative result</div>
                ) : (
                  <>{!_.isNil(itunesSong) && <AlternativeItunesSong song={itunesSong} />}</>
                )}
              </Card.Body>
            </Card>
          )}
        </CardGroup>
        <Button size="sm" onClick={onShowItunes}>
          {!showItunes ? 'show itunes data' : 'hide itunes data'}
        </Button>
        {songSearchState && (
          <Card className="mt-4">
            <Card.Body>
              {songSearchState === 'LOADING' ? (
                <Loader />
              ) : (
                <SongSearchContent
                  defaultTitle={songInput.title}
                  defaultArtist={getFirstArtist(songInput.artist)}
                  onSongSelected={onReplacedSongSelected}
                  actionButton="Replace match"
                  songSearchState={songSearchState}
                />
              )}
              {suggestedReplacedSong && (
                <Alert variant="warning" className="mb-2">
                  A song with the same iTunes ID is already in the database: Song ID {suggestedReplacedSong.id}
                  <br />
                  {suggestedReplacedSong.artist} - {suggestedReplacedSong.title}
                  <Button
                    variant="outline-success"
                    size="sm"
                    onClick={() => {
                      onReplacedSongSelected(suggestedReplacedSong, 'SP3');
                    }}
                  >
                    Replace match
                  </Button>
                </Alert>
              )}
            </Card.Body>
          </Card>
        )}

        <Card className="border-left-success mt-4">
          <Card.Body>
            <EditCustomFields
              song={song}
              contentPack={contentPack}
              onUpdateCurationSong={onUpdateCurationSong}
              onUpdateCurationSongSample={onUpdateCurationSongSample}
              onUpdatePlaylistCustomDisplay={onUpdatePlaylistCustomDisplay}
            />
          </Card.Body>
        </Card>
        <Card className="border-left-dark mt-4">
          <Card.Body>
            <Accordion>
              <Accordion.Toggle as={Button} variant="link" eventKey="0">
                External Links
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <ExternalLinksTable externalLinks={externalLinks} />
              </Accordion.Collapse>
            </Accordion>
          </Card.Body>
        </Card>

        <TooltipButton
          variant="outline-danger"
          className="mt-4"
          onClick={handleRemoveSong}
          disabled={contentPack.state !== 'VALIDATING'}
          disabledReason="Playlist is not in state VALIDATING"
        >
          <i className="fas fa-trash mr-2" />
          {removing ? 'Removing...' : `Remove from playlist ${contentPack.name}`}
        </TooltipButton>
      </Modal.Body>
    </Modal>
  );
}
