import DashboardContainer from '../../../../../../utils/dashboards/components/DashboardContainer';
import { DefaultSlicing } from '../../../../../../utils/dashboards/components/slicers';
import BarChart from '../../../../../../utils/dashboards/components/widgets/charts/BarChart';
import LineChart from '../../../../../../utils/dashboards/components/widgets/charts/LineChart';
import StackedAreaChart from '../../../../../../utils/dashboards/components/widgets/charts/StackedAreaChart';
import BaseTable from '../../../../../../utils/dashboards/components/widgets/tables/BaseTable';
import WidgetContainer from '../../../../../../utils/dashboards/components/widgets/WidgetContainer';
import Processing from '../../../../../../utils/dashboards/models/Processing';

const queries = {
  gamesPerDAU: { fillZeroes: true },
  usersGamesPerDAU: { fillZeroes: true },
  nonPlayerPerDAU: { fillZeroes: true },
  avgSessionsPerUser: { fillZeroes: true },
  avgSessionDuration: { fillZeroes: true },
  avgTimeInAppPerUser: { fillZeroes: true },
  liveShowParticipants: { fillZeroes: true },
  liveShowParticipantsByType: { fillZeroes: true },
  liveShowParticipantsPerRound: { fillZeroes: true },
  boostsBoughtPerDAUPerBoostType: { fillZeroes: true },
  boostsEarnedPerDAUPerBoostType: { fillZeroes: true },
  boostsUsedPerDAUPerBoostType: { fillZeroes: true },
  boostsUsedPerDAUPerGameType: { fillZeroes: true },
  avgPlayersPerTournament: { fillZeroes: true },
  ticketsExchangeTournament: { fillZeroes: true },
  fillRatePerAdZoneType: { fillZeroes: true },
  swapPlaylist: { fillZeroes: true },
  ftue: { fillZeroes: true },
  ftue_levels: { fillZeroes: true },
  ftue_games: { fillZeroes: true },
  ftue_pre_signup: { fillZeroes: true },
  ads_seen_behaviour: { fillZeroes: true },
  ads_seen_duration: { fillZeroes: true },
  notifOptIn: { fillZeroes: true },
  appOpenSource_per_type: { fillZeroes: true },
  emailsSentPerDAU: { fillZeroes: true },
  newAccountSignUpWith: { fillZeroes: true },
  subscriberSource: { fillZeroes: true },
  subscriberSourceV2: { fillZeroes: true },
  dauPerUserLevel: { fillZeroes: true },
  avgDaysToUserLevel: { fillZeroes: true },
  medianDaysToUserLevel: { fillZeroes: true },
  userLevelPerTier: { fillZeroes: true },
};

const GameDeepDive = () => (
  <>
    <DashboardContainer queries={queries} id="game_deep_dive" title="Game Deep Dive">
      <DefaultSlicing id="DefaultSlicing" />
      <WidgetContainer id="WidgetContainer">
        <LineChart
          id="gamesPerDAU"
          title="Number of Games played per DAU by type of games"
          processing={Processing.pivot('gamesPerDAU', 'type', 'games_per_dau', 'date')}
        />
        <LineChart
          id="usersGamesPerDAU"
          title="Number of Users that played a Game by type of game per DAU"
          processing={Processing.pivot('usersGamesPerDAU', 'type', 'users_games_per_dau', 'date')}
          percentage
        />
        <LineChart id="nonPlayerPerDAU" title="Percentage of users that play no game while DAU" percentage/>
        <LineChart id="avgSessionsPerUser" title="Average number of Sessions Per user" />
        <LineChart id="avgSessionDuration" title="Average Session Duration in minutes" />
        <LineChart id="avgTimeInAppPerUser" title="Average Time spent in the App Per user in minutes" />
        <LineChart id="liveShowParticipants" title="Number of Live Show Participants" />
        <LineChart
          id="liveShowParticipantsByType"
          title="Number of Live Show Participants by Type"
          processing={Processing.pivot('liveShowParticipantsByType', 'type', 'nb_participants', 'date')}
        />
        <LineChart
          id="liveShowParticipantsPerRound"
          title="Number of Live Show Participants per Show Round"
          processing={Processing.pivot('liveShowParticipantsPerRound', 'round', 'nb_participants', 'date')}
        />
        <LineChart
          id="boostsBoughtPerDAUPerBoostType"
          title="Number of Boosts bought per DAU by boost type"
          processing={Processing.pivot('boostsBoughtPerDAUPerBoostType', 'boost_type', 'nb_boosts_bought_per_DAU', 'date')}
        />
        <LineChart
          id="boostsEarnedPerDAUPerBoostType"
          title="Number of Boosts earned per DAU by boost type"
          processing={Processing.pivot('boostsEarnedPerDAUPerBoostType', 'boost_type', 'nb_boosts_earned_per_DAU', 'date')}
        />
        <LineChart
          id="boostsUsedPerDAUPerBoostType"
          title="Number of Boosts used per DAU by boost type"
          processing={Processing.pivot('boostsUsedPerDAUPerBoostType', 'boost_type', 'nb_boosts_used_per_DAU', 'date')}
        />
        <LineChart
          id="boostsUsedPerDAUPerGameType"
          title="Number of Boosts used per DAU by game type"
          processing={Processing.pivot('boostsUsedPerDAUPerGameType', 'game_type', 'nb_boosts_used_per_DAU', 'date')}
        />
        <BarChart id="avgPlayersPerTournament" title="Average number of Players per Tournament Category" />
        <BarChart id="ticketsExchangeTournament" title="Average number of Players per Tournament Category" />
        <LineChart
          id="fillRatePerAdZoneType"
          title="Fill Rate per Ad Zone Type"
          processing={Processing.pivot('fillRatePerAdZoneType', 'ad_zone_type', 'fill_rate', 'date')}
          percentage
        />
        <LineChart id="swapPlaylist" title="Percentage of users that swap a playlist with either a rewarded ads or diamonds" />
        <StackedAreaChart
          id="ftue"
          title="FTUE - Last Page View for New Installs (percentage of New Intalls)"
          percentage
        />
        <StackedAreaChart
          id="ftue_levels"
          title="FTUE - Last FTUE Levels for New Installs (percentage of New Intalls)"
          percentage
        />
        <StackedAreaChart
          id="ftue_games"
          title="FTUE - Last FTUE Games for New Installs (percentage of New Intalls)"
          percentage
        />
        <StackedAreaChart
          id="ftue_pre_signup"
          title="FTUE - Last FTUE Action Before Sign UP for New Installs (percentage of New Intalls)"
          percentage
        />
        <StackedAreaChart id="ads_seen_behaviour" title="Users behaviour after seeing an ad" percentage />
        <LineChart
          id="AdsSeenDuration"
          title="Average Seconds between ads seen and next screen seen for users that seen the ad"
          processing={Processing.pivot(
            'ads_seen_duration',
            'ads_seen_behaviour',
            'avg_ads_seen_duration_seconds',
            'date'
          )}
        />
        <LineChart
          id="AdsSeenDurationKill"
          title="Average Seconds between ads seen and next screen seen for users that kill the app after seeing the ad"
          processing={Processing.pivot(
            'ads_seen_duration',
            'ads_seen_behaviour',
            'avg_ads_seen_to_next_screen_duration_seconds',
            'date'
          )}
        />
        <LineChart
          id="notifOptIn"
          title="Percentage of users that opt in to notifications among new installs that finished the first game (iOS Only)"
          percentage
        />
        <LineChart
          id="appOpenSource_per_type"
          title="Number of daily app open events per source reference"
          processing={Processing.pivot('appOpenSource_per_type', 'source_ref', 'nb_events', 'date')}
        />
        <LineChart
          id="emailsSentPerDAU"
          title="Number of users that received an email per DAU by email type"
          processing={Processing.pivot('emailsSentPerDAU', 'email_type', 'nb_users_email_sent_per_DAU', 'date')}
        />
        <LineChart
          id="newAccountSignUpWith"
          title="New Account SignUp With"
          processing={Processing.pivot('newAccountSignUpWith', 'credential_type', 'nb_installs', 'date')}
        />
        <LineChart
          id="subscriberSource"
          title="Where do Subscribers got their subscription from?"
          processing={Processing.pivot('subscriberSource', 'source_page', 'nb_users', 'date')}
        />
        <LineChart
          id="subscriberSourceV2"
          title="Where do Subscribers got their subscription from? (Using Purchase Screen Action event)"
          processing={Processing.pivot('subscriberSourceV2', 'source_page', 'nb_users', 'date')}
        />
        <LineChart
          id="dauPerUserLevel"
          title="Dau Per User Level Buckets"
          processing={Processing.pivot('dauPerUserLevel', 'user_level', 'nb_dau', 'date')}
        />
        <LineChart
          id="avgDaysToUserLevel"
          title="Average Days To Reach each User Level"
          processing={Processing.pivot('avgDaysToUserLevel', 'user_level', 'avg_daysToUserLevel', 'date')}
        />
        <LineChart
          id="medianDaysToUserLevel"
          title="Median Days To Reach each User Level"
          processing={Processing.pivot('medianDaysToUserLevel', 'user_level', 'median_daysToUserLevel', 'date')}
        />
        <BaseTable
          id="userLevelPerTier"
          title="User Level per Subscription Tier, at the end of the selected time range, for DAU during this time range only."
        />
      </WidgetContainer>
    </DashboardContainer>
  </>
);

export default GameDeepDive;
