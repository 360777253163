import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

type Props = {
  value: string | number; // current Value
  handleValueChange: (newValue: string) => void; // callback on value changed
  name: string; // the name of this value (will be displayed as suffix)
};

/**
 * Create an interactive field to edit a value
 */
export default function EditValue({ value, handleValueChange, name }: Props) {
  const [currentValue, setCurrentValue] = useState<string>(value.toString());
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => setCurrentValue(value.toString()), [value]);

  const onEdit = () => {
    handleValueChange(currentValue);
    setIsEditing(false);
  };

  return (
    <div style={{ width: 150 }}>
      {isEditing ? (
        <Form.Group className="d-flex">
          <Form.Control onChange={(e) => setCurrentValue(e.target.value)} value={currentValue} />
          <Button variant="success" className="ml-2" onClick={onEdit}>
            Edit
          </Button>
        </Form.Group>
      ) : (
        <span onClick={() => setIsEditing(true)} className="edit-span" role="button" tabIndex={0}>
          {value} {name}
          <i className="far fa-edit m-2 edit-btn" />
        </span>
      )}
    </div>
  );
}
