// A modal displayed to choose settings of the next Tournament

import moment from 'moment';
import React from 'react';
import { Button, Col, Modal, ModalProps, Row } from 'react-bootstrap';
import DateTimePicker from 'react-datetime';
import { applyChangeIfDateValid, validIfSameDayOrAfter } from '../../../../../../utils/datepicker';

type Props = {
  onCopy: (value: number) => void;
  loading: boolean;
} & ModalProps;

const CopyTournamentModal = ({ show, onHide, onCopy, loading }: Props) => {
  const [startDate, setStartDate] = React.useState(moment().startOf('minute').add(6, 'minutes').toDate());

  // HELPER METHODS
  const sanitizeDate = () => setStartDate((rawDate) => moment(rawDate).startOf('minute').toDate());

  const handleSubmit = () => {
    sanitizeDate();
    const yesterday = moment().subtract(1, 'days');
    if (moment(startDate).isBefore(yesterday)) {
      alert(`Invalid start date: the tournament date can't be in the past`);
      return;
    }

    // @ts-ignore
    onCopy(startDate);
  };

  /**
   * VIEW
   */
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Body>
        <Row>
          <Col xs={3}>Start date</Col>
          <Col xs={6}>
            <DateTimePicker
              onChange={applyChangeIfDateValid(setStartDate)}
              isValidDate={validIfSameDayOrAfter}
              initialValue={startDate}
            />
          </Col>
        </Row>
        <Row className="mt-3 pl-1">
          <Button disabled={loading} onClick={handleSubmit}>
            Copy
          </Button>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default CopyTournamentModal;
