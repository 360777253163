import { useMutation } from '@apollo/client';
import Card from 'react-bootstrap/Card';
import { CurationContentPackQuery } from '../../../../../../__gqltypes__';
import EditComponent from '../../../components/EditComponent';
import PlaylistState from '../../../components/PlaylistState';
import TooltipButton from '../../../components/TooltipButton';
import mutations from '../../../utils/mutations';

type Props = {
  curationContentPack: CurationContentPackQuery['curationContentPack'];
  updating: boolean;
  onCommitPlaylist: () => void;
  committingLoading: boolean;
};

export default function PlaylistHeader({ curationContentPack, updating, onCommitPlaylist, committingLoading }: Props) {
  const [updatePlaylistName] = useMutation(mutations.UPDATE_CURATION_CONTENT_PACK_NAME);
  const [updatePlaylistDescription] = useMutation(mutations.UPDATE_CURATION_CONTENT_PACK_DESCRIPTION);

  const handleUpdatePlaylistName = async (name: string) => {
    const { id } = curationContentPack;
    await updatePlaylistName({ variables: { input: { id, name: name.trim() } } });
  };

  const handleUpdatePlaylistDescription = async (description: string) => {
    const { id } = curationContentPack;
    await updatePlaylistDescription({ variables: { input: { id, description } } });
  };

  return (
    <Card className="mb-4 ">
      <Card.Body>
        <EditComponent
          type="text"
          inline
          disabled={curationContentPack.state !== 'VALIDATING'}
          onSubmit={handleUpdatePlaylistName}
          className="display-4 text-primary"
          value={curationContentPack.name}
        />
        <br />
        <EditComponent
          type="textarea"
          disabled={curationContentPack.state !== 'VALIDATING'}
          onSubmit={handleUpdatePlaylistDescription}
          value={curationContentPack.description}
          className="d-inline text-dark"
        />
        <div className="d-flex pt-2">
          <div className="d-flex flex-fill pt-2 justify-content-end align-items-center">
            <PlaylistState state={curationContentPack.state || null} showDefault />
            {curationContentPack.state === 'VALIDATING' && (
              <TooltipButton
                disabled={updating || !curationContentPack.categories || curationContentPack.categories.length === 0}
                disabledReason={updating ? null : 'no categories set'}
                className="ml-2"
                size="lg"
                onClick={onCommitPlaylist}
              >
                <i className="fas fa-rocket mr-2" />
                {committingLoading ? 'Committing...' : 'Commit'}
              </TooltipButton>
            )}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}
