import { gql } from '@apollo/client';

const QUESTION_FRAGMENT = gql`
  fragment QuestionFragment on QuizQuestion {
    id
    title
    asset
    song {
      id
      title
      artist
      picture
    }
  }
`;

const QUIZ_LIVE_SHOW_ROUND_FRAGMENT = gql`
  fragment QuizLiveShowRoundFragment on QuizLiveShowRound {
    id
    contentPack {
      id
      name
      popularity
      explicitContentStatus
    }
    liveOpsQuestions {
      ...QuestionFragment
    }
    questionDuration
    encryptedRoundAssetUrl
    encryptedRoundAssetUrlV2
  }
  ${QUESTION_FRAGMENT}
`;

const QUIZ_LIVE_SHOW_FRAGMENT = gql`
  fragment QuizLiveShowFragment on QuizLiveShow {
    id
    category {
      id
      name
    }
    startDate
    lastUpdateDate
    lastSchedule {
      isCurrentlyScheduled
    }
    playerCount
    diamondPrize
    adminSpecialEvent {
      id
      name
      startDate
      endDate
    }
    extraConfig {
      primaryColor
      secondaryColor
      secondaryDarkColor
      buttonColor
      contrastGradient1
      contrastGradient2
      headerImage
      characterImage
    }
    rounds {
      ...QuizLiveShowRoundFragment
    }
  }
  ${QUIZ_LIVE_SHOW_ROUND_FRAGMENT}
`;

export const GET_QUIZ_LIVE_SHOWS = gql`
  query quizLiveShows($pagination: PaginationParams) {
    liveOps {
      quizLiveShows(pagination: $pagination) {
        hasMore
        totalCount
        list {
          id
          startDate
          playerCount
          lastUpdateDate
          lastSchedule {
            isCurrentlyScheduled
          }
          rounds {
            encryptedRoundAssetUrl
            encryptedRoundAssetUrlV2
          }
        }
      }
    }
  }
`;

export const GET_QUIZ_LIVE_SHOW = gql`
  query quizLiveShow($showId: ID!) {
    quizLiveShow(showId: $showId) {
      ...QuizLiveShowFragment
    }
  }
  ${QUIZ_LIVE_SHOW_FRAGMENT}
`;

export const UPSERT_QUIZ_LIVE_SHOW = gql`
  mutation upsertQuizLiveShow($input: UpsertQuizLiveShowInput!) {
    upsertQuizLiveShow(input: $input) {
      ...QuizLiveShowFragment
    }
  }
  ${QUIZ_LIVE_SHOW_FRAGMENT}
`;

export const RESET_QUIZ_LIVE_SHOW_ROUND_QUESTIONS = gql`
  mutation resetQuizLiveShowRoundQuestions($input: ResetQuizLiveShowRoundQuestionsInput!) {
    resetQuizLiveShowRoundQuestions(input: $input) {
      ...QuizLiveShowFragment
    }
  }
  ${QUIZ_LIVE_SHOW_FRAGMENT}
`;
export const UPLOAD_HEADER_ASSET = gql`
  mutation uploadLiveShowHeaderAsset($input: UploadAssetInput!) {
    uploadLiveShowHeaderAsset(input: $input) {
      ...QuizLiveShowFragment
    }
  }
  ${QUIZ_LIVE_SHOW_FRAGMENT}
`;
export const UPLOAD_CHARACTER_ASSET = gql`
  mutation uploadLiveShowCharacterAsset($input: UploadAssetInput!) {
    uploadLiveShowCharacterAsset(input: $input) {
      ...QuizLiveShowFragment
    }
  }
  ${QUIZ_LIVE_SHOW_FRAGMENT}
`;

export const STOP_CURRENT_LIVE_SHOW = gql`
  mutation stopCurrentLiveShow {
    stopCurrentLiveShow {
      isStopped
    }
  }
`;

export const REMOVE_LIVE_SHOW = gql`
  mutation removeLiveShow($input: RemoveLiveShowInput!) {
    removeLiveShow(input: $input) {
      isRemoved
      isUnscheduled
    }
  }
`;

export const GET_LIVE_SHOW_LOGS = gql`
  query liveShowLogs($showId: ID!, $pagination: PaginationParams) {
    analytics {
      liveShowLogs(showId: $showId, pagination: $pagination) {
        totalCount
        list {
          timestamp
          message
          namespace
          source
          level
          extraData
        }
      }
    }
  }
`;

export const RESCHEDULE_LIVE_SHOW = gql`
  mutation rescheduleQuizLiveShow($input: RescheduleQuizLiveShowInput!) {
    rescheduleQuizLiveShow(input: $input) {
      ...QuizLiveShowFragment
    }
  }
  ${QUIZ_LIVE_SHOW_FRAGMENT}
`;

export const SCHEDULE_LIVE_SHOW_ROUND_ENCRYPTION = gql`
  mutation scheduleQuizLiveShowRoundEncryption($input: ScheduleQuizLiveShowRoundEncryptionInput!) {
    scheduleQuizLiveShowRoundEncryption(input: $input) {
      ...QuizLiveShowFragment
    }
  }
  ${QUIZ_LIVE_SHOW_FRAGMENT}
`;

export const COPY_LIVE_SHOW = gql`
  mutation copyQuizLiveShow($input: CopyQuizLiveShowInput!) {
    copyQuizLiveShow(input: $input) {
      id
      startDate
      lastUpdateDate
      lastSchedule {
        isCurrentlyScheduled
      }
      rounds {
        encryptedRoundAssetUrl
        encryptedRoundAssetUrlV2
      }
    }
  }
`;

export const UPDATE_LIVE_SHOW_CONTENT_PACKS = gql`
  mutation updateQuizLiveShowContentPacks($input: UpdateQuizLiveShowContentPacksInput!) {
    updateQuizLiveShowContentPacks(input: $input) {
      id
    }
  }
`;

export const ADD_SPECIAL_EVENT_LIVE_SHOW = gql`
  mutation addSpecialEventQuizLiveShow($input: AddSpecialEventQuizLiveShowInput!) {
    addSpecialEventQuizLiveShow(input: $input) {
      specialEvent {
        id
        name
      }
      quizLiveShow {
        id
      }
    }
  }
`;

export const REMOVE_SPECIAL_EVENT_LIVE_SHOW = gql`
  mutation removeSpecialEventQuizLiveShow($input: RemoveSpecialEventQuizLiveShowInput!) {
    removeSpecialEventQuizLiveShow(input: $input) {
      specialEvent {
        id
        name
      }
      quizLiveShow {
        id
      }
    }
  }
`;
