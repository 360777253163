import React, { useMemo, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import { BaseModal } from '../../components/Modal';
import { CollapseLog } from './components/CollapseLog';
import { compareTimestamps, handleDowload } from './models';

const ModalDisplay = ({ show, onClose, dataString }) => (
  <BaseModal
    show={show}
    title="Data Display"
    onClose={onClose}
    closeButton
    buttons={[
      { title: 'Copy to clipboard', onClick: () => navigator.clipboard.writeText(dataString) },
      { title: 'Download JSON', onClick: () => handleDowload(dataString, 'response_data.json') },
    ]}
    size="lg"
  >
    <pre>{dataString}</pre>
  </BaseModal>
);

const LogsTable = ({ processedLogs, hasFilters }) => {
  const [toggleValues, setToggleValues] = useState([0]);
  const [show, setShow] = useState(false);
  const [reponseData, setResponseData] = useState(null);
  const handleToggleChange = (values) => {
    setToggleValues(values);
  };
  const handleShow = (dataString) => {
    setResponseData(dataString);
    setShow(!show);
  };
  const logsFilterCallback = (log) => {
    if (!hasFilters) {
      return true;
    }
    if (toggleValues.includes(0) && toggleValues.includes(1)) {
      return true;
    }
    if (toggleValues.includes(1)) {
      return log.event_name.includes('debug');
    }
    if (toggleValues.includes(0)) {
      return !log.event_name.includes('debug');
    }
    return false;
  };

  const osName = useMemo(() => {
    const sessionStart = processedLogs.find((log) => log.event_name === 'fp_session_start');
    if (sessionStart && sessionStart.event_params) {
      return sessionStart.event_params.os_name || null;
    }
    return null;
  }, [processedLogs]);

  return (
    <>
      <ModalDisplay show={show} onClose={() => setShow(false)} dataString={reponseData} />
      <Card className="shadow mb-4">
        <Card.Header className="m-0 font-weight-bold text-primary">
          {hasFilters ? (
            <Row>
              <div className="d-flex align-items-center pl-2">Logs</div>
              <ToggleButtonGroup type="checkbox" className="ml-auto" onChange={handleToggleChange}>
                <ToggleButton variant={toggleValues.includes(0) ? 'primary' : 'outline-primary'} value={0}>
                  <i className="fas fa-flag m-2" /> Events
                </ToggleButton>
                <ToggleButton variant={toggleValues.includes(1) ? 'primary' : 'outline-primary'} value={1}>
                  <i className="fas fa-code m-2" /> Debug
                </ToggleButton>
              </ToggleButtonGroup>
            </Row>
          ) : (
            <>Logs</>
          )}
        </Card.Header>
        <Card.Body className="p-0">
          <Table>
            <thead>
              <tr>
                <th />
                <th />
                <th>Date</th>
                <th>Event</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {processedLogs
                .filter(logsFilterCallback)
                .sort(compareTimestamps)
                .map((log, index) => (
                  <CollapseLog
                    key={`${log.event_timestamp}-${log.event_name}-${index}`}
                    log={log}
                    os={osName}
                    showModal={handleShow}
                  />
                ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
};

export default LogsTable;
