import { Badge, Image, Media } from 'react-bootstrap';

import { ItunesParentalAdvisory, ItunesSong } from '../../../../../__gqltypes__';
import ExplicitIcon from '../../components/ExplicitIcon';

const AlternativeItunesSong = ({ song }: { song: ItunesSong }) => {
  return (
    <Media>
      {song.albumArtwork && <Image src={song.albumArtwork} thumbnail className="artwork-popup" />}
      <Media.Body>
        Title: {song.title} <ExplicitIcon explicit={song.parentalAdvisory === ItunesParentalAdvisory.EXPLICIT} />
        <br />
        Artist: {song.artist}
        <br />
        Album: {song.album}
        <br />
        Isrc: {song.isrc}
        <br />
        Release year: {song.albumYear}
        <div className="mt-2">
          itunes Song ID: <span className="font-italic">{song.id}</span>
          <br />
          {'Legal: '}
          <Badge pill variant={song.isWhitelisted ? 'success' : 'danger'}>
            {`${song.label} - ${song.subLabel}`}
          </Badge>
        </div>
      </Media.Body>
    </Media>
  );
};

export default AlternativeItunesSong;
