export function parseSpotifyPlaylistId(urlOrUri: string) {
  let match = urlOrUri.match(/^spotify:user:([^:]+):playlist:([^:]+)$/i);

  if (match) {
    return match[2];
  }

  match = urlOrUri.match(/^spotify:playlist:([^:]+)$/i);

  if (match) {
    return match[1];
  }

  try {
    match = new URL(urlOrUri).pathname.match(/\/playlist\/(.+)$/i);

    if (match) {
      return match[1];
    }
  } catch (e) {
    if (!(e instanceof TypeError)) {
      // if it is not a TypeError (given by the parsing of an invalid URL) throw it
      throw e;
    }
  }

  return null;
}

export function parseSpotifyArtistId(value: string) {
  // value can be an ID, a URI or a URL

  let match = value.match(/^spotify:artist:([^:]+)$/i);

  if (match) {
    return match[1];
  }

  try {
    match = new URL(value).pathname.match(/\/artist\/(.+)$/i);

    if (match) {
      return match[1];
    }
  } catch (e) {
    if (!(e instanceof TypeError)) {
      // if it is not a TypeError (given by the parsing of an invalid URL) throw it
      throw e;
    }
  }

  if (value.match(/[A-Za-z0-9]{22}/)) {
    return value;
  }

  return null;
}
