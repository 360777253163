import { useQuery } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';
import Badge from 'react-bootstrap/Badge';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import type { LegalConfigQuery } from '../../../../__gqltypes__';
import Loader from '../components/Loader';
import SearchBar from '../components/SearchBar';
import queries from '../utils/queries';

type CurationLegalConfig = LegalConfigQuery['curation']['legalConfig'];
type WhitelistedLabels = CurationLegalConfig['whitelistedLabels'][0];
type CurationWhiteListedTitle = CurationLegalConfig['whitelistedTitles'][0];
type BlacklistedArtist = { id: string; labels: WhitelistedLabels[] };
type LabelWithSubLabel = { label: string; subLabel: string };

const DEFAULT_LEGAL_CONFIG: CurationLegalConfig = {
  whitelistedLabels: [],
  blacklistedLabels: [],
  blacklistedArtists: [],
  blacklistedTitles: [],
  whitelistedTitles: [],
  __typename: 'CurationLegalConfig',
};

const LegalConfig = () => {
  const { loading, data } = useQuery<LegalConfigQuery>(queries.LEGAL_CONFIG);
  const legalConfig = data && data.curation ? data.curation.legalConfig : DEFAULT_LEGAL_CONFIG;
  const [searchValue, setSearchValue] = useState('');

  const [blacklistedArtists, setBlacklistedArtists] = useState<BlacklistedArtist[]>([]);
  const [allLabelBlacklistedArtists, setAllLabelBlacklistedArtists] = useState<string[]>([]);
  const [whitelistedLabels, setWhitelistedLabels] = useState<string[]>([]);
  const [whitelistedSubLabels, setWhitelistedSubLabels] = useState<LabelWithSubLabel[]>([]);
  const [blacklistedTitles, setBlacklistedTitles] = useState<CurationWhiteListedTitle[]>([]);
  const [whitelistedTitles, setWhitelistedTitles] = useState<CurationWhiteListedTitle[]>([]);
  const [blacklistedSubLabels, setBlacklistedSubLabels] = useState<LabelWithSubLabel[]>([]);

  const allBlacklistedArtists = useMemo(() => {
    const blacklistedArtistsMap: Record<string, BlacklistedArtist> = {};
    legalConfig.whitelistedLabels.forEach((label) => {
      label.blacklistedArtists.forEach((artist) => {
        if (blacklistedArtistsMap[artist]) {
          blacklistedArtistsMap[artist].labels.push(label);
        } else {
          blacklistedArtistsMap[artist] = { id: artist, labels: [label] };
        }
      });
    });

    return Object.values(blacklistedArtistsMap).sort((a, b) => a.id.localeCompare(b.id));
  }, [legalConfig.whitelistedLabels]);

  useEffect(() => {
    if (searchValue.length > 0) {
      const lookup = searchValue.toUpperCase();
      setBlacklistedArtists(allBlacklistedArtists.filter(({ id }) => id.includes(lookup)));
    } else {
      setBlacklistedArtists(allBlacklistedArtists);
    }
  }, [searchValue, allBlacklistedArtists]);

  useEffect(() => {
    if (searchValue.length > 0) {
      const lookup = searchValue.toUpperCase();
      setAllLabelBlacklistedArtists(legalConfig.blacklistedArtists.filter((artist) => artist.includes(lookup)));
    } else {
      setAllLabelBlacklistedArtists(legalConfig.blacklistedArtists);
    }
  }, [searchValue, legalConfig.blacklistedArtists]);

  const allWhitelistedLabels = useMemo(
    () => legalConfig.whitelistedLabels.map((label) => label.id).sort((a, b) => a.localeCompare(b)),
    [legalConfig.whitelistedLabels]
  );

  useEffect(() => {
    if (searchValue.length > 0) {
      const lookup = searchValue.toLowerCase();
      setWhitelistedLabels(allWhitelistedLabels.filter((label) => label.includes(lookup)));
    } else {
      setWhitelistedLabels(allWhitelistedLabels);
    }
  }, [searchValue, allWhitelistedLabels]);

  const allWhitelistedSubLabels = useMemo(() => {
    const res: LabelWithSubLabel[] = [];
    legalConfig.blacklistedLabels.forEach(({ id, whitelistedSubLabels: subLabels }) => {
      subLabels.forEach((subLabel) => {
        res.push({ label: id, subLabel });
      });
    });

    return res;
  }, [legalConfig.blacklistedLabels]);

  useEffect(() => {
    if (searchValue.length > 0) {
      const lookup = searchValue.toLowerCase();
      setWhitelistedSubLabels(
        allWhitelistedSubLabels.filter(
          ({ label, subLabel }) => label.includes(lookup) || subLabel.toLowerCase().includes(lookup)
        )
      );
    } else {
      setWhitelistedSubLabels(allWhitelistedSubLabels);
    }
  }, [searchValue, allWhitelistedSubLabels]);

  useEffect(() => {
    if (searchValue.length > 0) {
      const lookup = searchValue.toLowerCase();
      setWhitelistedTitles(
        legalConfig.whitelistedTitles.filter(
          ({ title, artist }) => title.toLowerCase().includes(lookup) || artist.toLowerCase().includes(lookup)
        )
      );
    } else {
      setWhitelistedTitles(legalConfig.whitelistedTitles);
    }
  }, [searchValue, legalConfig.whitelistedTitles]);

  useEffect(() => {
    if (searchValue.length > 0) {
      const lookup = searchValue.toLowerCase();
      setBlacklistedTitles(
        legalConfig.blacklistedTitles.filter(
          ({ title, artist }) => title.toLowerCase().includes(lookup) || artist.toLowerCase().includes(lookup)
        )
      );
    } else {
      setBlacklistedTitles(legalConfig.blacklistedTitles);
    }
  }, [searchValue, legalConfig.blacklistedTitles]);

  const allBlacklistedSubLabels = useMemo(() => {
    const res: LabelWithSubLabel[] = [];

    legalConfig.whitelistedLabels.forEach(({ id, blacklistedSubLabels: subLabels }) => {
      subLabels.forEach((subLabel) => {
        res.push({ label: id, subLabel });
      });
    });

    return res;
  }, [legalConfig.whitelistedLabels]);

  useEffect(() => {
    if (searchValue.length > 0) {
      const lookup = searchValue.toLowerCase();
      setBlacklistedSubLabels(
        allBlacklistedSubLabels.filter(
          ({ label, subLabel }) => label.includes(lookup) || subLabel.toLowerCase().includes(lookup)
        )
      );
    } else {
      setBlacklistedSubLabels(allBlacklistedSubLabels);
    }
  }, [searchValue, allBlacklistedSubLabels]);

  if (loading || !data) {
    return <Loader />;
  }

  return (
    <>
      <Row className="justify-content-between mb-2">
        <Col lg={4}>
          <h1>Legal Config</h1>
        </Col>
        <Col className="d-flex mb-2 align-items-baseline">
          <SearchBar placeholder="Search labels, artists, songs" onSubmit={setSearchValue} />
        </Col>
      </Row>
      <h3 className="mt-2">Whitelisted Labels</h3>
      {whitelistedLabels.length > 0 ? (
        <ListGroup>
          {whitelistedLabels.map((label) => (
            <ListGroup.Item key={label} className="listgroup-small">
              {label}
            </ListGroup.Item>
          ))}
        </ListGroup>
      ) : (
        <p>No results</p>
      )}
      <h3 className="mt-2">Whitelisted Sublabels in Blacklisted Labels</h3>
      {whitelistedSubLabels.length > 0 ? (
        <ListGroup>
          {whitelistedSubLabels.map(({ label, subLabel }) => (
            <ListGroup.Item key={subLabel} className="listgroup-small">
              {subLabel}
              <Badge pill variant="danger" className="m-1">
                {label}
              </Badge>
            </ListGroup.Item>
          ))}
        </ListGroup>
      ) : (
        <p>No results</p>
      )}
      <h3 className="mt-2">Blacklisted Artists</h3>
      {allLabelBlacklistedArtists.length + blacklistedArtists.length > 0 ? (
        <ListGroup>
          {allLabelBlacklistedArtists.map((artist, index) => (
            <ListGroup.Item key={index} className="listgroup-small">
              {artist}
              <Badge pill variant="dark" className="m-1">
                All labels
              </Badge>
            </ListGroup.Item>
          ))}
          {blacklistedArtists.map((artist, index) => (
            <ListGroup.Item key={index} className="listgroup-small">
              <>
                {artist.id}
                {artist.labels.map((label) => (
                  <Badge pill variant="danger" className="m-1" key={label.id}>
                    {label.id}
                  </Badge>
                ))}
              </>
            </ListGroup.Item>
          ))}
        </ListGroup>
      ) : (
        <p>No results</p>
      )}
      <h3 className="mt-2">Blacklisted Songs</h3>
      {blacklistedTitles.length > 0 ? (
        <ListGroup>
          {blacklistedTitles.map((song, index) => (
            <ListGroup.Item key={index} className="listgroup-small">
              {`${song.title} - ${song.artist}`}
              <Badge pill variant="dark" className="m-1">
                All labels
              </Badge>
            </ListGroup.Item>
          ))}
        </ListGroup>
      ) : (
        <p>No results</p>
      )}
      <h3 className="mt-2">Whitelisted Songs</h3>
      {whitelistedTitles.length > 0 ? (
        <ListGroup>
          {whitelistedTitles.map((song, index) => (
            <ListGroup.Item key={index} className="listgroup-small">
              {`${song.title} - ${song.artist}`}
            </ListGroup.Item>
          ))}
        </ListGroup>
      ) : (
        <p>No results</p>
      )}
      <h3 className="mt-2">Blacklisted sub labels</h3>
      {blacklistedSubLabels.length > 0 ? (
        <ListGroup>
          {blacklistedSubLabels.map(({ label, subLabel }) => (
            <ListGroup.Item key={label + subLabel} className="listgroup-small">
              <>
                {subLabel}
                <Badge pill variant="danger" className="m-1">
                  {label}
                </Badge>
              </>
            </ListGroup.Item>
          ))}
        </ListGroup>
      ) : (
        <p>No results</p>
      )}
    </>
  );
};

export default LegalConfig;
