import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';

type Props = {
  startDate: string;
  endDate: string;
  pageSize: number;
  loading: boolean;
  numSelected: number;
  missingOnly: boolean;
  onToggleMissingOnly: () => void;
  onSetStart: (start: string) => void;
  onSetEnd: (end: string) => void;
  onSetPageSize: (size: number) => void;
  onImport: () => void;
  onSubmit: () => void;
};

export default function Sp2SearchForm({
  startDate,
  endDate,
  pageSize,
  loading,
  numSelected,
  missingOnly,
  onToggleMissingOnly,
  onSetStart,
  onSetEnd,
  onSetPageSize,
  onSubmit,
  onImport,
}: Props) {
  return (
    <Card>
      <Card.Header>SP2 Releases:</Card.Header>
      <Card.Body>
        <Form>
          <Form.Group>
            <Form.Label>Missing Only:</Form.Label>
            <Form.Check checked={missingOnly} onChange={onToggleMissingOnly} />
          </Form.Group>
          <Form.Label>From:</Form.Label>
          <Form.Group>
            <Form.Control type="date" size="sm" value={startDate} onChange={(e) => onSetStart(e.target.value)} />
          </Form.Group>
          <Form.Group>
            <Form.Label>To:</Form.Label>
            <Form.Control type="date" size="sm" value={endDate} onChange={(e) => onSetEnd(e.target.value)} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Results per page:</Form.Label>
            <Form.Control
              size="sm"
              as="select"
              value={pageSize.toString()}
              onChange={(e) => onSetPageSize(parseInt(e.target.value, 10))}
            >
              <option value="50">50</option>
              <option value="200">100</option>
              <option value="350">200</option>
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Button onClick={onSubmit} size="sm" disabled={loading}>
              Load
            </Button>
            <Button onClick={onImport} size="sm" disabled={!numSelected} className="float-right" variant="danger">
              Import
            </Button>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
}
