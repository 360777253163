import { SaleType } from "./typedef";

export function stringToEnum<T>(enumString: string, enumType: T): T[keyof T] {
  return (enumType as any)[enumString];
}

export function getSaleType(saleProductId: string): SaleType {
  const enumString = saleProductId.split('.', 1)[0].toUpperCase();
  return stringToEnum(enumString, SaleType);
}

export function getSaleDiscount(saleProductId: string): number {
  return parseInt(saleProductId.split('.')[2]) as number;
}
