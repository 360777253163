import React from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
// PropTypes
import { slicerPropTypes } from '../slicerPropTypes';

const parseRange = (value, defaultValue) => {
  let [from, to] = ['', ''];
  if (value && value.split('-').length === 2) {
    [from, to] = value.split('-');
  } else if (defaultValue && defaultValue.split('-').length === 2) {
    [from, to] = defaultValue.split('-');
  }
  return [from, to];
};

const RangeSlicer = ({ id, value, onChange, defaultValue, title }) => {
  const [from, to] = parseRange(value, defaultValue);
  const handleChange = (field) => {
    switch (field) {
      case 'from':
        return (e) => onChange(`${e.target.value}-${to}`);
      case 'to':
        return (e) => onChange(`${from}-${e.target.value}`);
      default:
        return () => {};
    }
  };
  return (
    <Row className="form-group">
      <Form.Label column>{title ?? id}</Form.Label>
      <Col>
        <Row>
          <Col>
            <Form.Group controlId={`${id}From`}>
              <Form.Label>From</Form.Label>
              <Form.Control type="number" value={from} onChange={handleChange('from')} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId={`${id}To`}>
              <Form.Label>To</Form.Label>
              <Form.Control type="number" value={to} onChange={handleChange('to')} />
            </Form.Group>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

RangeSlicer.propTypes = slicerPropTypes;

RangeSlicer.defaultProps = {
  onChange: () => {},
};

export default RangeSlicer;
