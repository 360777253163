import React, { Children, ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { baseSlicersClauses, defaultSlicersClauses, SlicingClause } from '../services/slicingClauses';

// Hooks

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

const useSlicer = (children: ReactElement[], onRefresh: (values: any, refs: any) => void) => {
  const query = useQuery();
  // initialize values
  const [values, setValues] = useState<Record<string, any>>({});
  const clauses: React.MutableRefObject<Record<string, SlicingClause>> = useRef({});
  const history = useHistory();
  const location = useLocation();

  // component did mount
  useEffect(() => {
    const initialValues: Record<string, any> = {};
    const params = new URLSearchParams();
    Children.forEach(children, (child) => {
      const { id, defaultValue } = child.props;
      if (defaultValue) {
        params.set(id, defaultValue);
      }
      initialValues[id] = query.get(id) || defaultValue || null;
      clauses.current[id] = baseSlicersClauses[child.props.name]
        ? baseSlicersClauses[child.props.name](id)
        : defaultSlicersClauses[child.props.name];
    });
    history.push({ search: params.toString() });
    setValues(initialValues);
    onRefresh(initialValues, clauses.current);
  }, []);

  const handleChangeValues = (keyValue: Record<string, any>) => {
    const params = new URLSearchParams(location.search);
    const key = Object.keys(keyValue)[0];
    const value = keyValue[key];
    if (key && value) {
      params.set(key, value);
    } else {
      params.delete(key);
    }
    history.push({ search: params.toString() });
    setValues((oldValues) => ({ ...oldValues, ...keyValue }));
  };

  const handleRefresh = () => {
    onRefresh(values, clauses.current);
  };

  return {
    values,
    handleChangeValues,
    handleRefresh,
  };
};

export default useSlicer;
