import moment from 'moment';
import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Carousel from 'react-bootstrap/Carousel';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Pagination from 'react-bootstrap/Pagination';
import Row from 'react-bootstrap/Row';
import { Link, useRouteMatch } from 'react-router-dom';
import { getBasePath, idToRoute, isValidID } from '../models';

const useInfoBloc = (params) => {
  const match = useRouteMatch();
  const [index, setIndex] = useState(0);
  const handleChange = (next) => {
    if (next) {
      if (index < params.length - 1) {
        setIndex(index + 1);
      } else {
        setIndex(0);
      }
    } else if (index > 0) {
      setIndex(index - 1);
    } else {
      setIndex(params.length - 1);
    }
  };
  const basicDisplay = (title, value) => {
    if (!value && value !== 0) {
      return null;
    }
    if (title.match(/(^id$|.*(_id|_ID|_Id)$|^userId)/g) && isValidID(value)) {
      return (
        <p key={title}>
          <i>{title}: </i>
          <Link
            className="text-decoration-none text-left"
            to={`${getBasePath(match.path)}/${idToRoute(value)}/${value}`}
          >
            {value}
          </Link>
          {title === 'userId' && (
            <Link className="ml-3 text-decoration-none" to={`/sp3/devtools/userview/${value}`}>
              (See in User View)
            </Link>
          )}
        </p>
      );
    }
    if (title === 'picture') {
      return (
        <div className="d-flex flex-column" key={title}>
          <i>{title}:</i>
          <Col xs={6} md={4}>
            <Image roundedCircle className="img-profile" src={value} fluid />
          </Col>
        </div>
      );
    }
    if (title.match(/(Date|date|created)/g)) {
      return (
        <p key={title}>
          <i>{title}:</i> <b>{moment(parseInt(value, 10)).format('YYYY-MM-DD HH:mm:ss (Z)')}</b>
        </p>
      );
    }
    return (
      <p key={title}>
        <i>{title}:</i> <b>{value}</b>
      </p>
    );
  };
  const formatResultDisplay = (input) => {
    let item = Object.entries(input);
    if (item.length > 1) {
      return item.map((subItem) => formatResultDisplay({ [subItem[0]]: subItem[1] }));
    }
    item = item[0];
    if (item[0] === '__typename') {
      return null;
    }
    if (typeof item[1] === 'number' || typeof item[1] === 'string' || item[1] === null) {
      return basicDisplay(item[0], item[1]);
    }
    if (Array.isArray(item[1])) {
      return (
        <Row key={item[0]}>
          <Col>
            <div className="font-weight-bold text-primary text-uppercase mb-1">{item[0]}</div>
            <div>{item[1].map((arrayItem) => formatResultDisplay(arrayItem))}</div>
          </Col>
        </Row>
      );
    }
    if (typeof item[1] === 'object' && item[1]) {
      return (
        <Row key={item[0]}>
          <Col>
            <div className="font-weight-bold text-primary text-uppercase mb-1">{item[0]}</div>
            <div className="ml-4">
              {Object.entries(item[1]).map((objectItem) => formatResultDisplay({ [objectItem[0]]: objectItem[1] }))}
            </div>
          </Col>
        </Row>
      );
    }
    console.log('Wrong input', input);
    return null;
  };
  return { index, handleChange, basicDisplay, formatResultDisplay };
};

const InfoBloc = ({ title, params, color = 'primary', match }) => {
  const { index, handleChange, formatResultDisplay } = useInfoBloc(params, match);
  if (title === 'Players') {
    return params ? (
      <Card className={`card border-left-${color} shadow mb-4`}>
        <Card.Header className="m-0 font-weight-bold text-primary">
          <Row className="justify-content-between align-items-center">
            <Col>
              {params.length === 1 && Object.keys(params[0])[0] === 'error'
                ? `${title} (0)`
                : `${title} (${params.length})`}
            </Col>
            {params.length > 1 && (
              <Pagination>
                <Pagination.Prev onClick={() => handleChange(false)} disabled={params.length < 1} />
                <Pagination.Next onClick={() => handleChange(true)} disabled={params.length < 1} />
              </Pagination>
            )}
          </Row>
        </Card.Header>
        <Card.Body>
          <Carousel
            indicators={false}
            interval={null}
            controls={false}
            activeIndex={index}
            onSelect={() => {
              /* Empty just to avoid error because of custom controls */
            }}
            wrap={false}
          >
            {params.map((player, _index) => (
              <Carousel.Item key={player.id ?? _index}>{formatResultDisplay(player)}</Carousel.Item>
            ))}
          </Carousel>
        </Card.Body>
      </Card>
    ) : (
      <></>
    );
  }
  return params ? (
    <Card className={`card border-left-${color} shadow mb-4`}>
      <Card.Header className="m-0 font-weight-bold text-primary">{title}</Card.Header>
      <Card.Body>{formatResultDisplay(params)}</Card.Body>
    </Card>
  ) : (
    <></>
  );
};

export const InfoDisplay = ({ data = [] }) => (
  <Col sm="4">
    {data.map((bloc) => (
      <InfoBloc key={bloc.title} title={bloc.title} params={bloc.params} color={bloc.color} />
    ))}
  </Col>
);
