import { useLazyQuery } from '@apollo/client';
import React, { useState } from 'react';
import { Modal, ModalProps } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import InputGroup from 'react-bootstrap/InputGroup';
import ListGroup from 'react-bootstrap/ListGroup';
import Media from 'react-bootstrap/Media';
import { CurationContentPacksByNameQuery, CurationContentPacksByNameQueryVariables } from '../../../../__gqltypes__';
import Loader from '../components/Loader';
import queries from '../utils/queries';

type Props = {
  onSelectPlaylist: (playlist: CurationContentPacksByNameQuery['curationContentPacksByName'][0]) => void;
  title?: string;
} & ModalProps;

const SearchPlaylistPopup = ({ onSelectPlaylist, title = 'Search Playlist', ...rest }: Props) => {
  const [searchInput, setSearchInput] = useState('');

  const [getCurationContentPackByName, { loading, data }] = useLazyQuery<
    CurationContentPacksByNameQuery,
    CurationContentPacksByNameQueryVariables
  >(queries.CURATION_CONTENT_PACKS_BY_NAME);

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => setSearchInput(event.target.value ?? '');

  const onPressEnterInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') onSearchPlaylist();
  };

  const onSearchPlaylist = () => {
    getCurationContentPackByName({ variables: { name: searchInput } });
  };

  const contentPacks = data ? data.curationContentPacksByName : [];

  return (
    <Modal {...rest} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputGroup className="mb-2">
          <Form.Control
            placeholder="Playlist or artist to search"
            onChange={onChangeInput}
            onKeyDown={onPressEnterInput}
          />
          <InputGroup.Append>
            <Button onClick={onSearchPlaylist}>Search</Button>
          </InputGroup.Append>
        </InputGroup>
        {loading ? (
          <Loader />
        ) : (
          <ListGroup variant="flush">
            {contentPacks.map((playlist) => (
              <ListGroup.Item
                key={playlist.id}
                className="listgroup-small"
                action
                onClick={() => onSelectPlaylist(playlist)}
              >
                <Media>
                  <Image src={playlist.picture} />
                  <Media.Body>{playlist.name}</Media.Body>
                </Media>
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default SearchPlaylistPopup;
