import React from 'react';
import DashboardContainer from '../../../../../../utils/dashboards/components/DashboardContainer';
import { MarketingSlicing } from '../../../../../../utils/dashboards/components/slicers';
import LineChart from '../../../../../../utils/dashboards/components/widgets/charts/LineChart';
// import PieChart from '../../../../../../utils/dashboards/components/widgets/charts/PieChart';
// import BarChart from '../../../../../../utils/dashboards/components/widgets/charts/BarChart';
import BaseTable from '../../../../../../utils/dashboards/components/widgets/tables/BaseTable';
import WidgetContainer from '../../../../../../utils/dashboards/components/widgets/WidgetContainer';
import Processing from '../../../../../../utils/dashboards/models/Processing';
import { SectionDisplay } from '../../../../../../utils/dashboards/components/widgets';

const queries = {
  prog_final_data: { fillZeroes: true },
  sp3_prog_final_data: { fillZeroes: true },
  prog_final_data_automatic: { fillZeroes: true },
  sp3_prog_final_data_automatic: { fillZeroes: true },
};

const Marketing = () => (
  <DashboardContainer queries={queries} id="marketing" title="Marketing">
    <MarketingSlicing id="MarketingSlicing" />
    <WidgetContainer
      id="WidgetContainer"
      notes="Those data are manually pulled, be careful with the updated date info, there is no data available beyond this date."
    >
      <BaseTable id="prog_final_data" title="SP2 Data" />
      <BaseTable id="sp3_prog_final_data" title="SP3 Data need action to be updated" />
      <BaseTable id="prog_final_data_automatic" title="SP2 Data Automatically updated" />
      <BaseTable id="sp3_prog_final_data_automatic" title="SP3 Data Automatically updated" />
    </WidgetContainer>
  </DashboardContainer>
);

export default Marketing;
