import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

const NoAccess = () => (
  <Container>
    <Card className="o-hidden border-0 shadow-lg my-5">
      <Card.Body className="p-0">
        <Row>
          <Col className="col-lg-9">
            <div className="p-5">
              <Card.Title>Permission Denied</Card.Title>
              <hr />
              Please contact an admin before accessing any resource
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  </Container>
);

export default NoAccess;
