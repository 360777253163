import { Button, Card } from 'react-bootstrap';
import { MetricType } from '../../../../../../__gqltypes__';
import { AdditionalMeasureName } from '../logic/utils';
import LegendItem, { Variant } from './LegendItem';
import RequestSummary from './RequestSummary';

type PartialNumericAggregatedData = {
  datetime: Date;
  average: number;
  P95: number;
  P99: number;
  P100: number;
  count: number;
  std: number;
};
interface RequestCardProps {
  color: string;
  requestDataset: { label: string; data: PartialNumericAggregatedData[] };
  timeSelection: { start: Date; end: Date };
  focus?: boolean;
  isPrevious: boolean;
  onFocus: () => void;
  onBlur: () => void;
  additionalMeasuresActivated: AdditionalMeasureName[];
  onCheckMeasureChanges: (name: AdditionalMeasureName, checked: boolean) => void;
  type: MetricType;
}

export default function RequestCard({
  color,
  requestDataset,
  timeSelection,
  focus,
  onFocus,
  onBlur,
  isPrevious,
  additionalMeasuresActivated,
  onCheckMeasureChanges,
  type,
}: RequestCardProps) {
  return (
    <Card className="w-100 mw-100 mb-2">
      <Card.Header className="d-flex p-1">
        <div className="rounded mr-2 ml-2 mt-auto mb-auto" style={{ width: 20, height: 20, backgroundColor: color }} />
        <strong className="mt-auto mr-auto mb-auto">{requestDataset.label}</strong>
        <Button onClick={focus ? onBlur : onFocus} className="p-1" variant="outline-primary">
          {focus ? <div className="fas fa-search-minus" /> : <div className="fas fa-search-plus" />}
        </Button>
      </Card.Header>
      {focus && (
        <Card.Body className="pb-1 pt-1">
          <LegendItem
            variant={Variant.MAX}
            color={color}
            checkValue={additionalMeasuresActivated.includes(AdditionalMeasureName.P100)}
            checkOnChange={(checked) => onCheckMeasureChanges(AdditionalMeasureName.P100, checked)}
          />
          <LegendItem
            variant={Variant.P99}
            color={color}
            checkValue={additionalMeasuresActivated.includes(AdditionalMeasureName.P99)}
            checkOnChange={(checked) => onCheckMeasureChanges(AdditionalMeasureName.P99, checked)}
          />
          <LegendItem
            variant={Variant.P95}
            color={color}
            checkValue={additionalMeasuresActivated.includes(AdditionalMeasureName.P95)}
            checkOnChange={(checked) => onCheckMeasureChanges(AdditionalMeasureName.P95, checked)}
          />
          <LegendItem variant={Variant.AVG} color={color} checkValue checkDisabled />
          {isPrevious && <LegendItem variant={Variant.PREVIOUS_AVG} color={color} checkValue checkDisabled />}
        </Card.Body>
      )}
      {focus && (
        <Card.Body className="pb-1 pt-1 border-top">
          <LegendItem variant={Variant.COUNT} color={color} checkValue checkDisabled />
          {isPrevious && <LegendItem variant={Variant.PREVIOUS_COUNT} color={color} checkValue checkDisabled />}
        </Card.Body>
      )}
      {focus && (
        <Card.Body className="pb-1 pt-1 border-top">
          <RequestSummary requestDataset={requestDataset} color={color} type={type} timeSelection={timeSelection} />
        </Card.Body>
      )}
    </Card>
  );
}
