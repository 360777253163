import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { Button, Card, Modal, Row, Spinner } from 'react-bootstrap';
import SheetUploader from '../../components/SheetUploader';

// eslint-disable-next-line import/no-cycle
import { MonthlyPassMissionTable } from './MonthlyPassMissionTable';
import { getTypeAndValueFromTaskParameter } from './tasks';
import { FilledMissionInput } from './typedef';
import { GetMonthlyPassesQuery, LevelRewardInput, LevelRewardInputType, UpdateMonthlyPassMissionsInput } from '../../../../../__gqltypes__';
import { getReward, getType } from '../SpecialEvent/utils';

type MonthlyPassMissions = NonNullable<GetMonthlyPassesQuery['liveOps']['monthlyPasses']['list'][0]['missions']>;
type DailyMissions = MonthlyPassMissions['dailyMissions'][0];
type DailyMissionsTaskParams = DailyMissions['tasks'][0]['params'][0];
type ExtraMissions = MonthlyPassMissions['extraMissions'][0];
type MonthlyMissions = MonthlyPassMissions['monthlyMissions'][0];
type TeamMissions = MonthlyPassMissions['teamMissions'][0];

type Props = {
  loading: boolean;
  monthId: string;
  monthlyPassMissions: MonthlyPassMissions;
  onSave: (input: UpdateMonthlyPassMissionsInput) => void;
  refetch: () => void;
};

// A type to handle the structure of missions groups used in MonthlupassMissionTable
export type GroupedUpdateMonthlyPassMissionsInput = {
  id: string;
  dailyMissions: FilledMissionInput[][];
  monthlyMissions: FilledMissionInput[][];
  extraMissions: FilledMissionInput[][];
  teamMissions: FilledMissionInput[][];
};

export function MonthlyPassMissionsData({ loading, monthId, monthlyPassMissions, onSave, refetch }: Props) {
  // monthlyPass changed by the user. Format is adapted to match updateMonthlyMissionsPass mutation input
  // See useEffect below.

  const [showPopup, setShowPopup] = useState(false);

  const convertMonthlyPassMissionsToMonthlyPassMissionsInput = useCallback(
    (_monthlyPassMissions: MonthlyPassMissions): GroupedUpdateMonthlyPassMissionsInput => {
      // Daily Missions must be grouped by day
      // creating an empty array for each day (days) filled with arrays (missions)
      const groupedDailyMissions: DailyMissions[][] = Array.from({ length: 31 }, (e) => []);

      _monthlyPassMissions.dailyMissions.forEach((mission) => {
        const day = new Date(mission.start).getDate();
        // day is between 1 and 31, so a -1 si needed
        groupedDailyMissions[day - 1].push(mission);
      });

      const groupedTeamMissions: TeamMissions[][] = Array.from({ length: 4}, (e) => []);
      _monthlyPassMissions.teamMissions.forEach((mission) => {
        const week = Math.floor(new Date(mission.start).getDate() / 7);
        groupedTeamMissions[week].push(mission);
      });
      // The MonthlyPassMissionTableExpects a MissionInput[][].
      // Thus, we insert the monthlyMissions in a dummy array to have the same structure.
      // This will be flattened when sending this as an input for a mutation,
      // because monthlyMissions and extraMissions inputs are not expected with this nested structure

      return {
        id: _monthlyPassMissions.id,
        dailyMissions: groupedDailyMissions.map((onDayMissions) => onDayMissions.map(missionToFilledMissionInput)),
        monthlyMissions: _monthlyPassMissions.monthlyMissions.map((mission) => [missionToFilledMissionInput(mission)]),
        extraMissions: _monthlyPassMissions.extraMissions.map((mission) => [missionToFilledMissionInput(mission)]),
        teamMissions: groupedTeamMissions.map((weekMissions) => weekMissions.map(missionToFilledMissionInput)), 
      };
    },

    []
  );

  const [currentMonthlyPassMissions, setCurrentMonthlyPassMissions] = useState<GroupedUpdateMonthlyPassMissionsInput>(
    convertMonthlyPassMissionsToMonthlyPassMissionsInput(monthlyPassMissions)
  );

  function missionToFilledMissionInput(
    mission: DailyMissions | MonthlyMissions | ExtraMissions | TeamMissions
  ): FilledMissionInput {
    const tmpReward = mission?.reward ? getReward(mission.reward) : null;
    let reward: LevelRewardInput | undefined;
    if (tmpReward) {
      reward = {
        type: tmpReward?.type as LevelRewardInputType,
        amount: tmpReward?.amount,
        itemId: tmpReward?.itemId,
      };
    }
    return {
      reward,
      rewardXp: mission.rewardXp,
      requiredTier: mission.requiredTier,
      tasks: mission.tasks.map((task) => ({
        type: task.type,
        params: task.params.map((param) => getTypeAndValueFromTaskParameter(param).value),
      })),
    };
  }

  useEffect(() => {
    setCurrentMonthlyPassMissions(convertMonthlyPassMissionsToMonthlyPassMissionsInput(monthlyPassMissions));
  }, [monthlyPassMissions, convertMonthlyPassMissionsToMonthlyPassMissionsInput]);

  const checkIsActive = () => {
    const startDate = new Date(monthId);
    let endDate = new Date(monthId);
    endDate = new Date(endDate.setMonth(endDate.getMonth() + 1));
    const isActive = moment().isBetween(startDate, endDate, undefined, '[)');
    if (!isActive) {
      handleMissionsSave();
    } else {
      setShowPopup(true);
    }
  };

  const handleMissionsSave = () => {
    const stringifyMissionTasksParams = (mission: FilledMissionInput) => {
      return {
        ...mission,
        tasks: mission.tasks.map((task) => ({ ...task, params: task.params.map((param) => param.toString()) })),
      };
    };
    // flattening of monthlyMissions, extraMissions is needed before returning the updated missions
    const flattenedMissions = {
      id: currentMonthlyPassMissions.id,
      dailyMissions: currentMonthlyPassMissions.dailyMissions.map((missionGroup) =>
        missionGroup.map(stringifyMissionTasksParams)
      ),
      monthlyMissions: currentMonthlyPassMissions.monthlyMissions.flat().map(stringifyMissionTasksParams),
      extraMissions: currentMonthlyPassMissions.extraMissions.flat().map(stringifyMissionTasksParams),
      teamMissions: currentMonthlyPassMissions.teamMissions.map((missionGroup) => 
        missionGroup.map(stringifyMissionTasksParams)),
    };
    onSave(flattenedMissions);
    if (showPopup) {
      setShowPopup(false);
    }
  };

  const handleDailyMissionsUpdate = (missions: FilledMissionInput[][]) => {
    setCurrentMonthlyPassMissions({
      ...currentMonthlyPassMissions,
      dailyMissions: missions,
    });
  };

  const handleMonthlyMissionsUpdate = (missions: FilledMissionInput[][]) => {
    setCurrentMonthlyPassMissions({
      ...currentMonthlyPassMissions,
      monthlyMissions: missions,
    });
  };

  const handleExtraMissionsUpdate = (missions: FilledMissionInput[][]) => {
    setCurrentMonthlyPassMissions({
      ...currentMonthlyPassMissions,
      extraMissions: missions,
    });
  };

  const handleTeamMissionsUpdate = (missions: FilledMissionInput[][]) => {
    setCurrentMonthlyPassMissions({
      ...currentMonthlyPassMissions,
      teamMissions: missions,
    });
  };

  return (
    <>
      <Modal
        show={showPopup}
        onHide={() => setShowPopup(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Wait ...</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          The current config is already active. Are you sure you want to edit it? If yes, it should be only to fix some
          params. You should not change the mission order or type.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowPopup(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleMissionsSave}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <Card className="mt-3 mb-3">
        <Card.Header className="text-center">
          <Row className="justify-content-between">
            <Card.Title>Monthly pass missions</Card.Title>
            <Button onClick={checkIsActive}>
              <i className="fas fa-save mr-2" />
              Save Missions
            </Button>
            <div>
              <SheetUploader id={monthId} type="MONTHLYPASS_MISSIONS" refetch={refetch} />
            </div>
          </Row>
        </Card.Header>
        <Card.Body>
          {loading || currentMonthlyPassMissions == null ? (
            <Spinner animation="border" />
          ) : (
            <>
              <h4>Daily Missions</h4>
              <MonthlyPassMissionTable
                loading={loading}
                hasGroups
                isTeamMissions={false}
                missions={currentMonthlyPassMissions.dailyMissions}
                onChange={handleDailyMissionsUpdate}
              />
              <hr />
              <h4>Monthly Missions</h4>
              <MonthlyPassMissionTable
                loading={loading}
                hasGroups={false}
                isTeamMissions={false}
                missions={currentMonthlyPassMissions.monthlyMissions}
                onChange={handleMonthlyMissionsUpdate}
              />

              <hr />
              <h4>Extra Missions</h4>
              <MonthlyPassMissionTable
                loading={loading}
                hasGroups={false}
                isTeamMissions={false}
                missions={currentMonthlyPassMissions.extraMissions}
                onChange={handleExtraMissionsUpdate}
              />
              <h4>Team Missions</h4>
              <MonthlyPassMissionTable
                loading={loading}
                hasGroups={true}
                isTeamMissions={true}
                missions={currentMonthlyPassMissions.teamMissions}
                onChange={handleTeamMissionsUpdate}
              />
            </>
          )}
        </Card.Body>
        <Card.Footer>
          <Row className="justify-content-between">
            <Card.Title>Monthly pass missions</Card.Title>
            <Button onClick={checkIsActive}>
              <i className="fas fa-save mr-2" />
              Save Missions
            </Button>
            <div>
              <SheetUploader id={monthId} type="MONTHLYPASS_MISSIONS" refetch={refetch} />
            </div>
          </Row>
        </Card.Footer>
      </Card>
    </>
  );
}
