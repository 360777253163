import { useMutation } from '@apollo/client';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { Button, Card, Dropdown, DropdownButton, Row } from 'react-bootstrap';
import DateTimePicker from 'react-datetime';
import { applyChangeIfDateValid } from '../../../../../../utils/datepicker';
import { ContentPackAvailabilityType, CurationContentPackQuery } from '../../../../../../__gqltypes__';
import SpecialEventPopup from '../../../popups/SpecialEventPopup';
import mutations from '../../../utils/mutations';

type ContentPack = NonNullable<CurationContentPackQuery['curationContentPack']['contentPack']>;
type AdminSpecialEvents = ContentPack['adminSpecialEvents'][0];
type Props = {
  contentPack: ContentPack;
};

const PlaylistContentPackDetails = ({ contentPack }: Props) => {
  // useState
  const [availabilityType, setAvailabilityType] = useState(
    contentPack.availabilityType ?? ContentPackAvailabilityType.ALL
  );
  const [availabilityDate, setAvailabilityDate] = useState<number | Date | Moment | string>(
    contentPack.availabilityDate ?? moment().minutes(0).seconds(0).milliseconds(0).toDate()
  );
  const [lastUpdateDate, setLastUpdateDate] = useState<number | Date | Moment | string>(
    contentPack.lastUpdateDate ?? moment().minutes(0).seconds(0).milliseconds(0).toDate()
  );
  const [currentSpecialEvents, setCurrentSpecialEvents] = useState<AdminSpecialEvents[] | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);

  // Mutations
  const [updateAvailabilityType, { loading: availabilityTypeLoading }] = useMutation(
    mutations.UPDATE_CONTENT_PACK_AVAILABILITY_TYPE
  );
  const [updateAvailabilityDate] = useMutation(mutations.UPDATE_CONTENT_PACK_AVAILABILITY_DATE);
  const [updateLastUpdateDate] = useMutation(mutations.UPDATE_CONTENT_PACK_LAST_UPDATE_DATE);

  const selectAvailabilityType = async (newType: string | null) => {
    if (newType) {
      await updateAvailabilityType({
        variables: { input: { id: contentPack.id, type: newType as ContentPackAvailabilityType } },
      });
      setAvailabilityType(newType as ContentPackAvailabilityType);
    }
  };

  const selectAvailabilityDate = async (date: string | Moment) => {
    await updateAvailabilityDate({ variables: { input: { id: contentPack.id, date } } });
    setAvailabilityDate(date);
  };

  const selectLastUpdateDate = async (date: string | Moment) => {
    await updateLastUpdateDate({ variables: { input: { id: contentPack.id, date } } });
    setLastUpdateDate(date);
  };

  // useEffect
  useEffect(() => {
    if (!_.isNil(contentPack)) {
      setAvailabilityType(contentPack.availabilityType);
      setAvailabilityDate(contentPack.availabilityDate);
      if (!_.isNil(contentPack.adminSpecialEvents)) {
        setCurrentSpecialEvents(contentPack.adminSpecialEvents);
      }
    }
  }, [contentPack]);

  useEffect(() => {
    setAvailabilityDate(availabilityDate);
  }, [availabilityDate]);

  const updateSpecialEvents = (specialEvents: AdminSpecialEvents[]) => {
    setCurrentSpecialEvents(specialEvents);
  };

  return (
    <Card className="mt-2">
      <Card.Body>
        <div>
          <Row className="mx-0">
            {'Availability Type: '}
            {availabilityTypeLoading ? (
              'Updating...'
            ) : (
              <DropdownButton
                size="sm"
                className="mx-2"
                defaultValue={availabilityType}
                title={availabilityType}
                onSelect={selectAvailabilityType}
              >
                {Object.keys(ContentPackAvailabilityType).map((type) => (
                  <Dropdown.Item eventKey={type} key={`dropdown-type-${type}`} active={availabilityType === type}>
                    {type}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            )}
          </Row>
        </div>
        <div>
          <Row className="mx-0">
            Availability Date:{' '}
            <DateTimePicker
              dateFormat="YYYY-MM-DD"
              className="ml-2"
              onChange={applyChangeIfDateValid(selectAvailabilityDate)}
              initialValue={availabilityDate as Date}
              timeConstraints={{ minutes: { min: 0, max: 0, step: 0 }, seconds: { min: 0, max: 0, step: 0 } }}
            />
          </Row>
        </div>
        <div>
          <Row className="mx-0">
            Last Update Date:{' '}
            <DateTimePicker
              dateFormat="YYYY-MM-DD"
              className="ml-2"
              onChange={applyChangeIfDateValid(selectLastUpdateDate)}
              initialValue={lastUpdateDate as Date}
              timeConstraints={{ minutes: { min: 0, max: 0, step: 0 }, seconds: { min: 0, max: 0, step: 0 } }}
            />
          </Row>
        </div>
        <div>
          <Row className="mt-3 mb-2">
            <Button variant="primary" onClick={() => setShowModal(true)}>
              Special Events ({currentSpecialEvents?.length})
            </Button>

            <SpecialEventPopup
              onUpdateSpecialEvents={updateSpecialEvents}
              contentPackId={contentPack.id}
              specialEvents={currentSpecialEvents}
              show={showModal}
              onHide={() => setShowModal(false)}
            />
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
};

export default PlaylistContentPackDetails;
