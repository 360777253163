import { useApolloClient, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import Select from 'react-select';

import {
  AutocompleteSongQuery,
  AutocompleteSongQueryVariables,
  ContentCategoriesQuery,
  HurdleCatalogItemQuery,
  HurdleCatalogItemQueryVariables,
  HurdleQuizQuery,
  UpdateHurdleQuizMutation,
  UpdateHurdleQuizMutationVariables,
  ValidateHurdleQuizConfigQuery,
  ValidateHurdleQuizConfigQueryVariables,
} from '../../../../../../__gqltypes__';
import PlayButton from '../../../../curation/components/PlayButton';
import CatalogItemActions from '../../../../curation/screens/Hurdle/CatalogItemActions';
import { LoadingLogo } from '../../../../devtools/components/Modal';
import { AUTOCOMPLETE_SONG } from '../../../../devtools/screens/Hurdle/graphql';
import { GET_CONTENT_CATEGORIES } from '../../../components/apolloQueries';
import { GET_HURDLE_CATALOG_ITEM, GET_HURDLE_QUIZ, UPDATE_HURDLE_QUIZ, VALIDATE_HURDLE_QUIZ_CONFIG } from '../graphql';

type Props = {
  hurdleQuizId: string;
  refetchCalendar: () => void;
};

const HurdleQuizData = ({ hurdleQuizId, refetchCalendar }: Props) => {
  /**
   * STATE
   */
  const [categoryId, setCategoryId] = useState<string | null>(null);

  /**
   * QUERIES AND MUTATIONS
   */

  // Config validation
  const [validateConfig, { data: dataConfigWarnings, loading: loadingConfigWarnings }] = useLazyQuery<
    ValidateHurdleQuizConfigQuery,
    ValidateHurdleQuizConfigQueryVariables
  >(VALIDATE_HURDLE_QUIZ_CONFIG);
  const configValid = dataConfigWarnings?.validateHurdleQuizConfig?.isValid ?? false;
  const configWarnings = dataConfigWarnings?.validateHurdleQuizConfig?.messages ?? [];

  // Selected catalog item
  const [getCatalogItem, { loading: loadingCatalogItem, data: dataCatalogItem }] = useLazyQuery<
    HurdleCatalogItemQuery,
    HurdleCatalogItemQueryVariables
  >(GET_HURDLE_CATALOG_ITEM, {
    onCompleted: (data) => {
      if (data?.hurdleCatalogItem?.catalogItem?.id) {
        // if catalog item has been changed, the category is reset to the category of the catalog
        // and we need to revalidate using the new catalog item category
        if (hurdleQuiz?.catalogItemId !== data.hurdleCatalogItem.catalogItem.id) {
          setCategoryId(data.hurdleCatalogItem.catalogItem.categoryId ?? '');
          validateConfig({
            variables: {
              input: {
                hurdleQuizId,
                catalogItemId: data.hurdleCatalogItem.catalogItem.id,
                categoryId: data.hurdleCatalogItem.catalogItem.categoryId ?? '',
              },
            },
          });
        }
      }
    },
  });
  const catalogItem = dataCatalogItem?.hurdleCatalogItem.catalogItem ?? null;
  const selectedCatalogItemOption = catalogItem
    ? { value: catalogItem.id, label: `${catalogItem.item.song?.title} - ${catalogItem.item.song?.artist}` }
    : null;

  // Hurdle quiz
  const { data: dataHurdleQuiz, loading: loadingHurdleQuiz } = useQuery<HurdleQuizQuery>(GET_HURDLE_QUIZ, {
    variables: { input: { id: hurdleQuizId } },
    onCompleted: (data) => {
      if (data.hurdleQuiz.hurdleQuiz?.catalogItemId) {
        getCatalogItem({
          variables: { input: { catalogItemId: data.hurdleQuiz.hurdleQuiz?.catalogItemId } },
        });
      }
      if (data.hurdleQuiz.hurdleQuiz?.categoryId) {
        setCategoryId(data.hurdleQuiz.hurdleQuiz?.categoryId);
      }
      if (data.hurdleQuiz.hurdleQuiz?.categoryId && data.hurdleQuiz.hurdleQuiz?.catalogItemId) {
        validateConfig({
          variables: {
            input: {
              hurdleQuizId,
              catalogItemId: data.hurdleQuiz.hurdleQuiz.catalogItemId,
              categoryId: data.hurdleQuiz.hurdleQuiz.categoryId,
            },
          },
        });
      }
    },
  });
  const hurdleQuiz = dataHurdleQuiz?.hurdleQuiz?.hurdleQuiz ?? null;
  const isPastQuiz = hurdleQuiz?.startDate && moment(hurdleQuiz?.startDate) < moment();

  // Categories
  const { loading: loadingCategories, data: dataCategories } = useQuery<ContentCategoriesQuery>(GET_CONTENT_CATEGORIES);
  const categories = dataCategories?.app.contentCategories ?? [];

  // Autocompletion
  const [autocomplete, { data: dataAutocomplete }] = useLazyQuery<
    AutocompleteSongQuery,
    AutocompleteSongQueryVariables
  >(AUTOCOMPLETE_SONG);
  const catalogItemOptions = (dataAutocomplete?.hurdleAutoComplete?.items ?? []).map(
    ({ title, artist, catalogItemId: id }) => ({ value: id, label: `${title} - ${artist}` })
  );

  // Update
  const [updateHurdleQuiz, { loading: updateLoading }] = useMutation<
    UpdateHurdleQuizMutation,
    UpdateHurdleQuizMutationVariables
  >(UPDATE_HURDLE_QUIZ, {
    onError: (error) => {
      alert(error.message);
    },
  });

  // Loading state
  const loading = loadingHurdleQuiz || loadingCategories || updateLoading || loadingCatalogItem;

  // Handlers (save, change)
  const handleChangeCategory = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newCategoryId = event.target.value;
    setCategoryId(event.target.value);
    if (catalogItem) {
      validateConfig({
        variables: {
          input: {
            hurdleQuizId,
            catalogItemId: catalogItem.id,
            categoryId: newCategoryId,
          },
        },
      });
    }
  };
  const onPress = (value: string) => {
    autocomplete({ variables: { input: { search: value || '', limit: 5 } } });
  };
  const onCatalogItemChange = (newValue: { value: string } | null) => {
    if (newValue?.value) getCatalogItem({ variables: { input: { catalogItemId: newValue.value } } });
  };
  const handleSave = () => {
    updateHurdleQuiz({
      variables: {
        input: {
          hurdleQuizId,
          catalogItemId: selectedCatalogItemOption?.value,
          categoryId,
        },
      },
    });
  };

  const isUpdated =
    selectedCatalogItemOption?.value !== hurdleQuiz?.catalogItemId || categoryId !== hurdleQuiz?.categoryId;

  /**
   * RENDER
   */
  return loadingHurdleQuiz ? (
    <LoadingLogo show />
  ) : (
    <Container className="pb-4">
      {!isPastQuiz &&
        (loading || (!configValid && configWarnings.length === 0) ? (
          <div className="alert alert-info" role="alert">
            Loading...
          </div>
        ) : !configValid ? (
          <div className="alert alert-danger" role="alert">
            <ul className="mb-0">
              {configWarnings.map((configWarning: string, index: number) => (
                <li key={index}>{configWarning}</li>
              ))}
            </ul>
          </div>
        ) : (
          <div className="alert alert-success" role="alert">
            Configuration seems ok
          </div>
        ))}
      <Row className="mb-3">
        <Col md={4}>Start date:</Col>
        <Col md={4}>{hurdleQuiz?.startDate && moment(hurdleQuiz?.startDate).format('DD/MM/YYYY (LT)')}</Col>
      </Row>
      <Row className="mb-3">
        <Col md={4}>End date:</Col>
        <Col md={4}>{hurdleQuiz?.endDate && moment(hurdleQuiz?.endDate).format('DD/MM/YYYY (LT)')}</Col>
      </Row>
      <Row className="mb-3">
        <Col md={4}>Category:</Col>
        <Col md={4}>
          <Form.Control value={categoryId ?? ''} disabled={isPastQuiz} onChange={handleChangeCategory} as="select">
            {categories.map(({ id, name: categoryName }) => (
              <option key={id} value={id}>
                {categoryName}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Row>
      <Row>
        <Col md={4}>Content item:</Col>
        <Col md={4}>
          <Select
            className="basic-single"
            classNamePrefix="select"
            name="catalogItemId"
            onInputChange={onPress}
            options={catalogItemOptions}
            value={selectedCatalogItemOption}
            defaultValue={selectedCatalogItemOption}
            onChange={onCatalogItemChange}
            noOptionsMessage={() => selectedCatalogItemOption?.label}
            isDisabled={isPastQuiz}
          />
        </Col>
        <Col md={4} style={{ fontSize: '25px' }}>
          {catalogItem && (
            <PlayButton sample={catalogItem.item.asset} displayTimestamp startAt={catalogItem.offset ?? 0} />
          )}
        </Col>
      </Row>
      <Col className="d-flex justify-content-end mb-5">
        <Button
          onClick={handleSave}
          disabled={loadingConfigWarnings || isPastQuiz || !isUpdated}
          variant="success"
          className="mr-2"
        >
          {isUpdated ? 'Save' : 'No changes'}
        </Button>
      </Col>
      <Row>
        <Col md={4}>Update catalog item details:</Col>
        <Col md={2}>
          {catalogItem && (
            <CatalogItemActions catalogItem={catalogItem} onUpdate={() => refetchCalendar()} disabled={isUpdated} />
          )}
        </Col>
        <Col md={6}>{isUpdated ? 'Save your change to quiz before updating catalog item' : ''}</Col>{' '}
      </Row>
    </Container>
  );
};
export default HurdleQuizData;
