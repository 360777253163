import { gql } from '@apollo/client';
import {
  BASIC_SONG_INFO_FRAGMENT,
  DDEX_BASIC_SONG_INFO_FRAGMENT,
  HURDLE_CATALOG_ITEM_FRAGMENT,
  ITUNES_BASIC_SONG_INFO_FRAGMENT,
  SONG_CUSTOM_FIELDS_FRAGMENT,
  SONG_DETAILS_FRAGMENT,
} from './fragments';

export default {
  INGEST_STATUS: gql`
    query ingestStatus {
      curation {
        id
        ingestStatus {
          id
          resultUrl
          created
          status
          errorMessage
        }
      }
    }
  `,

  GET_TASKS: gql`
    query getTasks {
      liveOps {
        missionTasks {
          type
          params {
            ... on MissionParamType {
              typeName
            }
          }
          paramsDescriptions
          description
        }
      }
    }
  `,

  GET_CATEGORIES: gql`
    query getCategories {
      app {
        contentCategories {
          id
          name
        }
      }
    }
  `,
  CURATION_CONTENT_PACKS: gql`
    query contentPacks($limit: Int, $offset: Int = 0) {
      curation {
        id
        contentPacks(limit: $limit, offset: $offset) {
          totalCount
          list {
            id
            originId
            name
            picture
            created
            state
            explicitContentStatus
            sp2Only
          }
        }
      }
    }
  `,
  CURATION_CONTENT_PACKS_BY_NAME: gql`
    query curationContentPacksByName($name: String!) {
      curationContentPacksByName(name: $name) {
        id
        originId
        name
        picture
        created
        state
        type
        locale
        explicitContentStatus
      }
    }
  `,
  CONTENT_PACKS_CSV_EXPORT: gql`
    query curationContentPacksCsvExport {
      curation {
        id
        contentPackCsvExport {
          content
        }
      }
    }
  `,
  CATALOG_EXPORT: gql`
    query catalogExport {
      curation {
        id
        catalogExport {
          id
          status
          created
          started
          completed
          available
        }
      }
    }
  `,
  LEGAL_CONFIG: gql`
    query legalConfig {
      curation {
        id
        legalConfig {
          whitelistedLabels {
            id
            blacklistedArtists
            blacklistedSubLabels
          }
          blacklistedArtists
          blacklistedTitles {
            title
            artist
          }
          whitelistedTitles {
            title
            artist
          }
          blacklistedLabels {
            id
            whitelistedSubLabels
          }
        }
      }
    }
  `,
  CURATION_CONTENT_PACK_BY_ORIGIN_ID: gql`
    query curationContentPackByOriginId($originId: ID!, $version: Int) {
      curationContentPack(id: null, version: $version, originId: $originId) {
        id
        originId
      }
    }
  `,
  CURATION_CONTENT_PACK: gql`
    query curationContentPack($id: ID!) {
      curationContentPack(id: $id) {
        id
        name
        picture
        description
        originId
        adultOnly
        contentPack {
          id
          availabilityDate
          availabilityType
          lastUpdateDate
          adminSpecialEvents {
            id
            name
            startDate
            endDate
          }
        }
        sp2Only
        contentItems {
          ... on CurationSongInput {
            id
            title
            artist
            album
            songInputIsrc: isrc
          }
          ... on CurationSong {
            id
            ...BasicSongInfo
            ...SongCustomFields
            playlistCustomDisplay(contentPackId: $id)
            playlistCustomSample(contentPackId: $id)
            songInput {
              id
              matchType
            }
            isInvalid(contentPackId: $id)
          }
        }
        state
        version
        categories {
          id
          name
        }
        locale
        created
        committed
        deleted
        selectedCustomFields
        externalURI
        explicitContentStatus
        type
        replacingContentPack {
          id
          originId
          name
          description
          picture
        }
        sp2ExportId
        sp2LastExportDate
        sp2LastExportedVersion
      }
    }
    ${BASIC_SONG_INFO_FRAGMENT}
    ${SONG_CUSTOM_FIELDS_FRAGMENT}
  `,
  CURATION_CONTENT_PACK_WITH_EXTERNAL_LINKS: gql`
    query curationContentPackWithExternalLinks($id: ID!) {
      curationContentPack(id: $id) {
        id
        name
        contentItems {
          ... on CurationSongInput {
            id
            title
            artist
            album
            songInputIsrc: isrc
          }
          ... on CurationSong {
            id
            ...BasicSongInfo
            ...SongDetails
            ...SongCustomFields
            playlistCustomDisplay(contentPackId: $id)
            songInput {
              id
              matchType
            }
            isInvalid(contentPackId: $id)
            ddexSong {
              ...DDEXBasicSongInfo
            }
          }
        }
        state
        version
        categories {
          id
          name
        }
      }
    }
    ${BASIC_SONG_INFO_FRAGMENT}
    ${SONG_DETAILS_FRAGMENT}
    ${SONG_CUSTOM_FIELDS_FRAGMENT}
    ${DDEX_BASIC_SONG_INFO_FRAGMENT}
  `,
  CURATION_SONG: gql`
    query curationContentItem($id: ID!, $contentPackId: ID!, $songInputId: ID) {
      curationContentItem(id: $id, songInputId: $songInputId) {
        ... on CurationSong {
          id
          ...BasicSongInfo
          ...SongDetails
          ...SongCustomFields
          itunesId
          playlistCustomDisplay(contentPackId: $contentPackId)
          playlistCustomSample(contentPackId: $contentPackId)
          isInvalid(contentPackId: $contentPackId)
          songInput(songInputId: $songInputId) {
            id
            title
            album
            artist
            isrc
            matchType
          }
          stateDetail
          isDDEXSong
          isItunesSong
          ddexFullSample
          ddexSong {
            ...DDEXBasicSongInfo
          }
        }
      }
    }
    ${BASIC_SONG_INFO_FRAGMENT}
    ${SONG_DETAILS_FRAGMENT}
    ${SONG_CUSTOM_FIELDS_FRAGMENT}
    ${DDEX_BASIC_SONG_INFO_FRAGMENT}
  `,
  CURATION_DDEX_SONG: gql`
    query curationContentItemDDEX($id: ID!, $songInputId: ID) {
      curationContentItem(id: $id, songInputId: $songInputId) {
        ... on CurationSong {
          id
          songInput(songInputId: $songInputId) {
            id
          }
          ddexSong {
            ...DDEXBasicSongInfo
          }
        }
      }
    }
    ${DDEX_BASIC_SONG_INFO_FRAGMENT}
  `,

  CURATION_ITUNES_SONG: gql`
    query curationContentItemItunes($id: ID!, $songInputId: ID) {
      curationContentItem(id: $id, songInputId: $songInputId) {
        ... on CurationSong {
          id
          songInput(songInputId: $songInputId) {
            id
          }
          itunesSong {
            ...ItunesBasicSongInfo
          }
        }
      }
    }
    ${ITUNES_BASIC_SONG_INFO_FRAGMENT}
  `,

  SONG_DATABASE_SEARCH: gql`
    query curationSongDatabaseSearch($term: CurationSongSearchTerm!, $includesContentPacks: Boolean!) {
      curationSongDatabaseSearch(term: $term) {
        id
        title
        album
        artist
        isrc
        matchType
        curationSong {
          id
          ...BasicSongInfo
        }
        contentPacks @include(if: $includesContentPacks) {
          id
          name
        }
        curationContentPacks {
          id
          originId
          version
          name
          state
        }
      }
    }
    ${BASIC_SONG_INFO_FRAGMENT}
  `,
  SONG_DDEX_SEARCH: gql`
    query curationSongDDEXSearch($term: CurationSongSearchTerm!) {
      curationSongDDEXSearch(term: $term) {
        id
        label
        subLabel
        isrc
        title
        artist
        album
        albumId
        releaseDate
        isWhitelisted
        artwork
        sample
        isExplicit
        territoryCodes
        alternativeSongs {
          territoryCodes
        }
      }
    }
  `,
  CURATION_SONG_DETAILS: gql`
    query curationSongDetails($id: ID!) {
      curationContentItem(id: $id) {
        ... on CurationSong {
          id
          ...BasicSongInfo
          ...SongDetails
          ...SongCustomFields
        }
      }
    }
    ${BASIC_SONG_INFO_FRAGMENT}
    ${SONG_DETAILS_FRAGMENT}
    ${SONG_CUSTOM_FIELDS_FRAGMENT}
  `,
  GET_CONTENT_PACK_LISTS: gql`
    query getContentPackLists {
      contentPackLists {
        list {
          contentPackListId
          name
        }
      }
    }
  `,
  GET_CONTENT_PACK_LIST: gql`
    query getContentPackList($id: ID!) {
      contentPackList(contentPackListId: $id) {
        contentPackListId
        name
        description
        contentPacks {
          id
          name
          picture
          popularity
          explicitContentStatus
        }
      }
    }
  `,
  GET_CURATION_CONTENT_PACK_LISTS: gql`
    query getCurationContentPackLists {
      curationContentPackLists(appId: "SPP") {
        list {
          id
          name
        }
      }
    }
  `,
  GET_CURATION_CONTENT_PACK_LIST: gql`
    query getCurationContentPackList($id: ID!) {
      curationContentPackList(id: $id) {
        id
        name
        description
        curationContentPacks {
          id
          originId
          name
          type
          picture
          locale
          localeReplacements {
            id
            name
            originId
            picture
            type
            locale
          }
          adultOnly
          explicitContentStatus
        }
      }
    }
  `,
  SEARCH_CONTENT_PACKS: gql`
    query searchContentPacks($search: String!, $searchType: ContentSearchType!, $limit: Int, $offset: Int) {
      searchContentPacks(search: $search, searchType: $searchType, limit: $limit, offset: $offset) {
        totalCount
        hasMore
        list {
          id
          name
          popularity
          explicitContentStatus
        }
      }
    }
  `,
  SEARCH_USER_CONTENT_PACKS: gql`
    query searchUserContentPacks($search: String!, $searchType: ContentSearchType!, $limit: Int, $offset: Int) {
      searchUserContentPacks(search: $search, searchType: $searchType, limit: $limit, offset: $offset) {
        totalCount
        hasMore
        list {
          id
          contentPack {
            id
            name
            popularity
            explicitContentStatus
          }
        }
      }
    }
  `,
  SP2_CURATION_PLAYLIST: gql`
    query sp2CurationPlaylist($id: ID!) {
      sp2CurationPlaylist(id: $id) {
        id
        name
        badges
        price
        masteryRaceStarted
        availableBadges
        artwork
        lastCommitted
        created
        isCommitInProgress
        productionPlaylistId
        pageUrl
      }
    }
  `,
  SP2_RELEASES: gql`
    query sp2ReleaseStatus {
      sp2ReleaseStatus {
        id
        playlists {
          id
          name
          visible
          created
          runningRace
        }
        releases {
          id
          visibleDate
          state
          type
          playlistIds
        }
      }
    }
  `,
  SP2_PLAYLISTS: gql`
    query sp2Playlists($input: Sp2PlaylistsParams!) {
      sp2Playlists(input: $input) {
        startDate
        endDate
        pageSize
        currentCursor
        nextCursor
        playlists {
          name
          id
          productionPlaylistId
          artwork
          releasedAt
          lastCommittedAt
          contentPackId
          exportedAt
          state
        }
      }
    }
  `,
  GET_BATCH_MODERATION_LIST: gql`
    query batchContentModerationList($pagination: PaginationParams) {
      batchContentModerationList(pagination: $pagination) {
        list {
          id
          name
          stats {
            id
            locale
            totalCount
            totalDone
          }
          packs {
            id
            curationContentPack {
              id
              name
              originId
            }
            moderator {
              id
              displayName
              picture
              emailCredential
            }
          }
        }
      }
    }
  `,
  GET_BATCH_MODERATION_STATS: gql`
    query batchContentModerationListStats($pagination: PaginationParams) {
      batchContentModerationList(pagination: $pagination) {
        list {
          id
          name
          stats {
            id
            locale
            totalCount
            totalDone
          }
        }
      }
    }
  `,

  GET_BATCH_MODERATION: gql`
    query batchContentModeration($id: ID!) {
      batchContentModeration(id: $id) {
        id
        name
        state
        curationContentPacks {
          id
          name
          originId
          picture
        }
        stats {
          id
          locale
          totalCount
          totalDone
        }
        moderators {
          id
          user {
            id
            displayName
            picture
            emailCredential
          }
          totalDone
        }
      }
    }
  `,
  GET_BATCH_MODERATION_ERROR_CONTENT_ITEMS: gql`
    query batchContentModerationErrorContentItems($id: ID!, $limit: Int!, $offset: Int!) {
      batchContentModeration(id: $id) {
        id
        errorReportedContentItems(limit: $limit, offset: $offset) {
          hasMore
          totalCount
          list {
            id
            note
            noteType
            locale
            state
            adminState
            moderator {
              id
              emailCredential
            }
            curationContentPack {
              id
              originId
              name
            }
            contentItem {
              ... on CurationSong {
                id
                isrc
                itunesId
                title
                artist
                displayTitle
                displayArtist
                picture
                defaultSample
                isExplicit
              }
            }
          }
        }
      }
    }
  `,

  GET_MODERATION_CONTENT_ITEM: gql`
    query moderationContentItems($batchId: ID!, $locale: String!, $packId: ID, $pagination: PaginationParams) {
      moderationContentItems(batchId: $batchId, locale: $locale, packId: $packId, pagination: $pagination) {
        totalCount
        list {
          id
          note
          noteType
          locale
          state
          adminState
          contentItem {
            ... on CurationSong {
              id
              isrc
              itunesId
              displayTitle
              displayArtist
              title
              artist
              picture
              defaultSample
              isExplicit
            }
          }
        }
      }
    }
  `,

  CHECK_MUSIC_REPORT: gql`
    query checkMusicReportStatus($month: Int!, $year: Int!) {
      checkMusicReportStatus(month: $month, year: $year) {
        exportStatusSPPJP
        exportStatusSP
        exportStatusAll
        exportPostProcess
      }
    }
  `,

  GET_COMBINED_REPORT_BY_DATE: gql`
    query combinedMusicReportStatusByDate($month: Int!, $year: Int!) {
      combinedMusicReportStatusByDate(month: $month, year: $year) {
        report {
          id
          created
          formattedDate
          jobIdStep2
          jobIdStep3
          sp2StatusDone
          sp3StatusDone
          step6StatusDone
          completed
          lastSuccessStep
        }
      }
    }
  `,
  CHECK_COMBINED_REPORT: gql`
    query checkCombinedReportStatus($id: String!) {
      combinedMusicReportStatus(id: $id) {
        report {
          id
          created
          formattedDate
          jobIdStep2
          jobIdStep3
          sp2StatusDone
          sp3StatusDone
          step6StatusDone
          completed
          lastSuccessStep
        }
      }
    }
  `,

  GET_LYRICS: gql`
    query lyrics($title: String!, $artist: String!) {
      lyrics(title: $title, artist: $artist) {
        id
        lyricsData {
          id
          url
          artist
          title
          songImageUrl
          embedScriptUrl
        }
      }
    }
  `,

  HURDLE_CATALOG_ITEM_LIST: gql`
    query hurdleCatalogItemList($input: HurdleCatalogItemListInput!) {
      hurdleCatalogItemList(input: $input) {
        list {
          ...HurdleCatalogItemFragment
        }
        hasMore
        totalCount
      }
    }

    ${HURDLE_CATALOG_ITEM_FRAGMENT}
  `,
};
