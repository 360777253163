import React, { useCallback, useState } from 'react';
import { Badge } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { useDropzone } from 'react-dropzone';
import Loader from '../../../../curation/components/Loader';
import { OnValidateItem } from './typedef';

type Props = {
  onUpload: (file: Blob) => void;
  onValidate?: OnValidateItem;
} & React.HTMLAttributes<HTMLDivElement>;

export default function DragUpload({ onUpload, onValidate }: Props) {
  const [selectedFile, setSelectedFile] = useState<Blob | null>(null);
  const [validationState, setValidationState] = useState<'INIT' | 'VALIDATING' | 'SUCCESS' | 'ERROR'>('INIT'); // INIT | VALIDATING | SUCCESS | ERROR
  const [validationErrors, setValidatationErrors] = useState<string[]>([]);

  const onDrop = useCallback((acceptedFiles) => {
    setValidationState('INIT');
    setValidatationErrors([]);
    if (acceptedFiles) {
      const file = acceptedFiles[0];
      setSelectedFile(file);
    }
  }, []);

  // @ts-ignore
  const handleUpload = () => onUpload(selectedFile);
  const handleValidate = async () => {
    if (!onValidate) return;
    setValidationState('VALIDATING');
    // @ts-ignore
    const errors = await onValidate(selectedFile);
    if (errors && errors.length) {
      setValidationState('ERROR');
      setValidatationErrors(errors);
    } else {
      setValidationState('SUCCESS');
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <>
      <div
        {...getRootProps()}
        style={
          isDragActive
            ? {
                border: '2px solid white',
                backgroundColor: '#4e73df',
                color: 'white',
                borderRadius: '10px',
              }
            : { border: '2px solid #4e73df', borderRadius: '10px' }
        }
        className="text-center"
      >
        <p>Asset Upload</p>
        <input {...getInputProps()} />
        {isDragActive ? <p>Drop Files Here...</p> : <p>Drop files or click here to upload</p>}
      </div>
      {selectedFile && (
        <div className="w-100 text-center">
          <br />
          {!!onValidate && (
            <>
              {validationState === 'INIT' && (
                <Button
                  variant="success"
                  className="mr-4"
                  onClick={() => {
                    handleValidate();
                  }}
                >
                  <i className="fas fa-bug mr-2" />
                  Validate
                </Button>
              )}
              {validationState === 'VALIDATING' && <Loader />}
              {validationState === 'SUCCESS' && (
                <Badge pill variant="success" className="mr-4">
                  Validation Successful <i className="fas fa-check" />
                </Badge>
              )}
              {validationState === 'ERROR' && (
                <Badge className="mr-4" variant="danger" pill>
                  Errors when validating <i className="fas fa-times" />
                </Badge>
              )}
              {validationState === 'ERROR' && validationErrors.length > 0 && (
                <ul>
                  {validationErrors.map((err) => {
                    return <li>{err}</li>;
                  })}
                </ul>
              )}
            </>
          )}
          <Button
            onClick={() => {
              handleUpload();
            }}
          >
            <i className="fas fa-cloud-upload-alt mr-2" />
            Upload
          </Button>
        </div>
      )}
    </>
  );
}
