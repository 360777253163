import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';
import {
  HurdleType,
  ImportSongsFromContentPackListToHurdleCatalogMutation,
  ImportSongsFromContentPackListToHurdleCatalogMutationVariables,
} from '../../../../../__gqltypes__';
import mutations from '../../utils/mutations';
import ContentPackListEditor from '../ContentPackList/ContentPackListEditor';

export default function ImportTool({ onCompleted }: { onCompleted: () => void }) {
  const [showImportTool, setShowImportTool] = useState(false);
  const [importContentPacks, { data, loading, error }] = useMutation<
    ImportSongsFromContentPackListToHurdleCatalogMutation,
    ImportSongsFromContentPackListToHurdleCatalogMutationVariables
  >(mutations.IMPORT_CONTENTPACKS_TO_HURDLE_CATALOG, {
    onCompleted: () => {
      setShowImportTool(false);
      onCompleted();
    },
  });

  const onImport = (contentPackIds: string[], excludeContentPackIdsFromAutogeneration: string[]) => {
    importContentPacks({
      variables: {
        input: {
          contentPackIds,
          excludeContentPackIdsFromAutogeneration,
          type: HurdleType.DEFAULTSONG,
        },
      },
    });
  };

  console.log(data);

  return (
    <div>
      <h3>Import tool</h3>
      <div className="mt-3 mb-3">
        Use this tool to import all songs from several content packs into the tuneology catalog. Songs already in the
        catalog will not be modified. New songs will be added with the category of the first content pack it is in.
      </div>
      <Button variant="primary" onClick={() => setShowImportTool(true)} className="mb-3">
        Import content packs
      </Button>

      {data?.importSongsFromContentPackListToHurdleCatalog && (
        <Alert variant="success">
          Success:
          <ul>{data.importSongsFromContentPackListToHurdleCatalog.newSongsImported} songs imported</ul>
          <ul>
            {data.importSongsFromContentPackListToHurdleCatalog.alreadyInCatalogNumber} songs already in the catalog
          </ul>
          <ul>{data.importSongsFromContentPackListToHurdleCatalog.excludedExplicitItemsNumber} explicit songs</ul>
          <ul>
            {data.importSongsFromContentPackListToHurdleCatalog.excludedQuestionableItemsNumber} questionable songs
          </ul>
          <ul>{data.importSongsFromContentPackListToHurdleCatalog.excludedBannedItemsNumber} banned songs</ul>
          <ul>
            {data.importSongsFromContentPackListToHurdleCatalog.inSeveralNewContentPacksNumber} songs in several
            imported content packs
          </ul>
        </Alert>
      )}

      {error && <Alert variant="error">Error, {error.message}</Alert>}

      <Modal show={showImportTool} onHide={() => (loading ? () => {} : setShowImportTool(false))} size="lg" centered>
        <Modal.Body>
          <ContentPackListEditor
            contentPacks={[]}
            updatePackIds={async (packIds, checked) => {
              onImport(packIds, checked['Exclude from autogeneration'] ?? []);
            }}
            enableImport
            saveLabel="Import"
            checkboxColumnNames={['Exclude from autogeneration']}
            loading={loading}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
