import { LevelConfigMonthlyPassInput, LevelRewardInput, LevelRewardInputType } from '../../../../__gqltypes__';
import { TeamHeatIterationValue } from '../screens/MonthlyPass/components/TeamHeatIteration';
import { RankGroupValue } from '../screens/Tournament/components/RankGroups';

export type State = {
  id: string;
  levels: Array<LevelConfigMonthlyPassInput>;
  rankGroups: Array<RankGroupValue>,
  teamHeatIteration: Array<TeamHeatIterationValue>,
};

export enum tierName {
  basicReward = 'basicReward',
  plusReward = 'plusReward',
}
export type Action =
  | { type: 'addLevel' }
  | { type: 'deleteReward'; level: number; tier: tierName }
  | { type: 'editReward'; level: number; tier: tierName; reward: LevelRewardInput }
  | { type: 'editXp'; level: number; xp: string }
  | { type: 'removeLevel' }
  | { type: 'changedRankGroup', rankGroups: Array<RankGroupValue>}
  | { type: 'changeTeamHeatIteration', teamHeatIteration: Array<TeamHeatIterationValue>};

const monthDataReducer = (state: State, action: Action): State => {
  let newState;
  let levels;
  switch (action.type) {
    default:
      return { ...state };
    case 'addLevel':
      newState = {
        ...state,
        levels: [
          ...state.levels,
          {
            level: state.levels.length + 1,
            xpToNextLevel: -1,
            starts: state.levels[0].starts,
            basicReward: {
              type: LevelRewardInputType.CURRENCY_COIN,
              amount: 100,
              itemId: null,
            },
          },
        ],
      };
      console.log(newState.levels);
      return newState;
    case 'editReward':
      return {
        ...state,
        levels: Object.assign([], state.levels, {
          [action.level]: {
            ...state.levels[action.level],
            [action.tier]: { ...action.reward, amount: action.reward.amount },
          },
        }),
      };
    case 'deleteReward':
      return {
        ...state,
        levels: Object.assign([], state.levels, {
          [action.level]: { ...state.levels[action.level], [action.tier]: null },
        }),
      };
    case 'editXp':
      return {
        ...state,
        levels: Object.assign([], state.levels, {
          [action.level]: { ...state.levels[action.level], xpToNextLevel: parseInt(action.xp, 10) },
        }),
      };
    case 'removeLevel':
      levels = state.levels;
      levels.pop();
      return {
        ...state,
        levels: Object.assign([], levels, {
          [levels.length - 1]: { ...levels[levels.length - 1], xpToNextLevel: -1 },
        }),
      };
    case 'changedRankGroup':
      return {
        ...state,
        rankGroups: action.rankGroups,
      };
    case 'changeTeamHeatIteration':
      return {
        ...state,
        teamHeatIteration: action.teamHeatIteration,
      };
  }
};

export default monthDataReducer;
