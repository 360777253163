import React, { useMemo } from 'react';
import Card from 'react-bootstrap/Card';
import EditableParam from './EditableParam';

type Props<M> = {
  monthly: M;
  canDelete: boolean;
  onChange: (monthly: M) => void;
};

export default function MonthlyEditor({
  monthly,
  canDelete = false,
  onChange,
}: Props<{ level: string; progressToNextLevel: string }>) {
  const { level, progressToNextLevel } = useMemo(
    () => ({
      level: monthly.level,
      progressToNextLevel: monthly.progressToNextLevel,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // @ts-ignore
  const handleChangeMonthly = (key: string) => (event) => {
    const { value } = event.target;
    if (onChange) {
      onChange({ ...monthly, [key]: value });
    }
  };

  if (!monthly) return null;

  return (
    <Card>
      <Card.Body>
        <EditableParam
          key="level"
          title="level"
          value={monthly.level}
          onChange={handleChangeMonthly('level')}
          placeholder={level}
        />
        <EditableParam
          key="progressToNextLevel"
          title="progressToNextLevel"
          value={monthly.progressToNextLevel}
          onChange={handleChangeMonthly('progressToNextLevel')}
          placeholder={progressToNextLevel}
        />
      </Card.Body>
    </Card>
  );
}
