import Words from 'naughty-words';
import { Col, Row } from 'react-bootstrap';

const NaughtyWords = () => {
  const list = Words.en.map((str: string) => {
    return <li id={str}>{str}</li>;
  });

  return (
    <>
      <Row className="justify-content-between mb-2">
        <Col lg={4}>
          <h1>List of Naughty Words</h1>
        </Col>
      </Row>
      <ul>{list}</ul>
    </>
  );
};

export default NaughtyWords;
