import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import { useCallback, useEffect, useMemo } from 'react';
import { Badge, Button, Card, Col, Nav, Row, Spinner, Tab } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import {
  CatalogExportQuery,
  ExportMusicCatalogMutation,
  GetCurationContentPackListsQuery,
  MakeMusicCatalogAvailableMutation,
} from '../../../../../__gqltypes__';
import mutations from '../../utils/mutations';
import queries from '../../utils/queries';
import SPPCreatePackList from './components/SPPCreatePackList';
import SPPPackList from './components/SPPPackList';

const baseUrl = '/sp3/playlist/spp-content';
const isTestEnv = process.env.REACT_APP_NAMESPACE === 'test';

const SPPContent = () => {
  const { data, loading, refetch } = useQuery<GetCurationContentPackListsQuery>(
    queries.GET_CURATION_CONTENT_PACK_LISTS
  );
  const { data: catalogData } = useQuery<CatalogExportQuery>(queries.CATALOG_EXPORT);
  const [exportMusicCatalogFn, { loading: exporting }] = useMutation<ExportMusicCatalogMutation>(
    mutations.EXPORT_MUSIC_CATALOG
  );
  const [makeMusicCatalogAvailableFn, { loading: making }] = useMutation<MakeMusicCatalogAvailableMutation>(
    mutations.MAKE_MUSIC_CATALOG_AVAILABLE
  );

  const { catalogExport: catalogExportData } = catalogData ? catalogData.curation : { catalogExport: null };

  let lastExportDate = null;
  let catalogAvailableDate = null;
  let catalogExportStatus = catalogExportData?.status ?? 'SCHEDULED';
  if (catalogExportStatus === 'FAILED' && !!catalogExportData?.completed) {
    catalogExportStatus = 'COMPLETED';
  }
  if (catalogExportData) {
    switch (catalogExportStatus) {
      case 'PROCESSING':
        lastExportDate = catalogExportData.started;
        break;
      case 'FAILED':
      case 'COMPLETED':
        lastExportDate = catalogExportData.completed;
        break;
      default:
        lastExportDate = catalogExportData.created;
    }
    catalogAvailableDate = catalogExportData?.available;
  }

  const lists = useMemo(() => {
    if (!data?.curationContentPackLists.list) return [];
    const sorted = [...data?.curationContentPackLists.list].sort((packA, packB) => {
      if (packA.name.startsWith('events') && !packB.name.startsWith('events')) {
        return 1;
      }

      if (!packA.name.startsWith('events') && packB.name.startsWith('events')) {
        return -1;
      }

      if (packA.name.startsWith('decades') && !packB.name.startsWith('decades')) {
        return 1;
      }

      if (!packA.name.startsWith('decades') && packB.name.startsWith('decades')) {
        return -1;
      }
      return packA.name < packB.name ? -1 : 1;
    });
    return sorted;
  }, [data?.curationContentPackLists.list]);

  const { id: curListId } = useParams<{ id: string }>();
  const history = useHistory();

  const setActiveList = useCallback(
    (listId) => {
      history.push(`${baseUrl}/${listId}`);
    },
    [history]
  );

  useEffect(() => {
    if (!loading && curListId && !lists.find((list) => list.id === curListId)) {
      setActiveList(lists.length > 0 ? lists[0].id : '');
    }
  }, [lists, loading, curListId, setActiveList]);

  const onExport = () => {
    exportMusicCatalogFn();
  };

  const onMakeAvailable = () => {
    makeMusicCatalogAvailableFn();
  };

  return loading ? (
    <div className="p-4">
      <Spinner animation="border" />
    </div>
  ) : (
    // @ts-ignore
    <Tab.Container className="h-100" activeKey={curListId} onSelect={setActiveList}>
      <Row className="mb-5 h-100 cp-list">
        <Col>
          <Nav.Item className="mb-3">
            <SPPCreatePackList refetchLists={refetch} setActiveList={setActiveList} />
          </Nav.Item>
          <Card className="my-4">
            <Card.Header>Export to SPP</Card.Header>
            <Card.Body className="px-4">
              <Row className="d-flex justify-content-between align-items-center">
                <div>
                  Last beta export: {moment(lastExportDate).format('YYYY/MM/DD')}
                  <Badge className="m-1" pill variant="info">
                    {catalogExportStatus.toLowerCase()}
                  </Badge>
                </div>
                <div>
                  Last prod export:{' '}
                  {catalogAvailableDate ? (
                    moment(catalogAvailableDate).format('YYYY/MM/DD')
                  ) : (
                    <Button size="sm" className="mb-2" disabled={making} onClick={onMakeAvailable}>
                      <i className="fas fa-file-export mr-2" />
                      Deploy to Prod
                    </Button>
                  )}
                </div>
              </Row>
              <hr />
              <Row>
                <Button className="mb-2" disabled={exporting} onClick={onExport}>
                  <i className="fas fa-file-export mr-2" />
                  Export SPP Catalog
                </Button>
                <p>
                  Make sure all content have been moderated and being added to the categories below before exporting
                </p>
                {lastExportDate && (
                  <div>
                    <a
                      href={`https://storage.googleapis.com/${
                        isTestEnv ? 'songpop3-test-catalog' : 'songpop3-catalog'
                      }/${moment(lastExportDate).format('YYYY-MM-DD')}/catalog.spp.json`}
                      target="blank"
                    >
                      Link to exported catalog
                    </a>
                  </div>
                )}
              </Row>
            </Card.Body>
          </Card>

          <Nav variant="pills">
            {lists.map((list) => (
              <Nav.Item key={list.id} className="w-100 text-center position-relative">
                <Nav.Link eventKey={list.id} className="justify-content-center">
                  {list.name}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Col>
        <Col className="col-9">
          <Tab.Content className="h-100">
            {curListId && <SPPPackList listId={curListId} refetchLists={refetch} />}
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
};

export default SPPContent;
