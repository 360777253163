import React from 'react';
import SelectAdd from './SelectAdd';

type Props = {
  type: string;
  items: { id: string; name: string; level: number }[];
  onAdd: (item: { id: string; level: number }, type: string) => void;
  onAddAll: (items: { id: string; level: number }[], type: string) => void;
};

export default function ItemsAdd({ items, type, onAdd: onAddProps, onAddAll: onAddAllProps }: Props) {
  const options: Record<string, { value: string; searchValue: string }> = {};
  items.forEach((item) => {
    options[item.id] = {
      value: item.name,
      searchValue: item.name,
    };
  });

  const onAdd = (key: string) => {
    const item = items.find((v) => v.id === key);
    if (item) {
      onAddProps(item, type);
    }
  };

  const onAddAll = () => onAddAllProps(items, type);

  return (
    <SelectAdd
      buttonTitle={`Add ${type}`}
      buttonAllTitle={`Add all ${type}`}
      canAll
      title={`Select ${type} to add`}
      options={options}
      onAdd={onAdd}
      onAddAll={onAddAll}
    />
  );
}
