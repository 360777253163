import React from 'react';
import BaseSelectorSlicer from '../components/BaseSelectorSlicer';
import { slicerPropTypes } from '../slicerPropTypes';

import source from '../../../models/slicers/accountlinked.json';

const options = source.map(({ name, code }) => ({
  text: name,
  value: code,
}));

const DefaultAccountLinkedSlicer = ({ id, value, onChange }) => (
  <BaseSelectorSlicer id={id} value={value} onChange={onChange} options={options} disableAll />
);

DefaultAccountLinkedSlicer.propTypes = slicerPropTypes;

DefaultAccountLinkedSlicer.defaultProps = {
  id: 'Is Account Linked to SPClassic?',
  onChange: () => {},
};

export default DefaultAccountLinkedSlicer;
