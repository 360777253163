import Chart from 'chart.js';
import * as ChartAnnotation from 'chartjs-plugin-annotation';
import moment from 'moment';
import { useEffect, useRef } from 'react';
import { baseChartDefaultProps, baseChartPropTypes } from '../widgetPropTypes';

const namedChartAnnotation = ChartAnnotation;
namedChartAnnotation.id = 'annotation';
Chart.plugins.register(namedChartAnnotation);

const DATASET_PARAMS = {
  cubicInterpolationMode: 'linear',
  lineTension: 0.1,
  borderJoinStyle: 'round',
  pointRadius: 0,
  borderWidth: 3,
};

const sanitize = (label) => {
  const _label = label.endsWith('_') ? label.slice(0, -1) : label;
  const sanitizedLabel = _label ? _label.replace(/_/g, ' ').toLowerCase().trim().split(' ') : '';
  for (let i = 0; i < sanitizedLabel.length; i++) {
    sanitizedLabel[i] = sanitizedLabel[i][0].toUpperCase() + sanitizedLabel[i].slice(1);
  }
  return sanitizedLabel.join(' ');
};

const formatLabel = (labels, labelFormat) => {
  return labels.map((value) =>
    moment(value).isValid() ? moment(value).format(labelFormat ?? 'MMM D') : sanitize(value)
  );
};

const BaseChart = ({ id, height, width, datasets, labels, type, options, title, labelFormat, annotation }) => {
  const ref = useRef(null);
  const myChart = useRef(null);

  const createChart = () => {
    const chart = new Chart(ref.current, {
      type,
      data: {
        datasets: datasets.map((dataset) => ({
          ...{ fill: false },
          ...dataset,
          ...DATASET_PARAMS,
        })),
        labels: formatLabel(labels, labelFormat),
      },
      options: {
        title: {
          display: true,
          text: title || id,
        },
        tooltips: {
          mode: 'nearest',
        },
        ...options,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                maxTicksLimit: 10,
              },
            },
          ],
          ...options.scales,
        },
        annotation: {
          ...annotation,
        },
      },
    });
    return chart;
  };

  const updateChart = (newDatasets, newLabels) => {
    if (myChart.current) {
      const { current } = myChart;
      if (current.data.datasets.length > newDatasets.length) {
        current.data.datasets = current.data.datasets.slice(0, newDatasets.length);
      }

      newDatasets.forEach((dataset, index) => {
        if (current.data.datasets[index] !== dataset) {
          if (current.data.datasets[index]) {
            current.data.datasets[index].data = dataset.data;
            current.data.datasets[index].label = dataset.label ? sanitize(dataset.label) : dataset.label;
            current.data.datasets[index].borderColor = dataset.borderColor;
          } else {
            current.data.datasets[index] = {
              ...dataset,
              ...DATASET_PARAMS,
            };
          }
        }
      });
      if (newLabels !== current.data.labels) {
        current.data.labels = formatLabel(newLabels, labelFormat);
      }
      current.options.annotation = { ...annotation };
      current.update();
    }
  };

  useEffect(() => {
    myChart.current = createChart();
  }, []);

  useEffect(() => {
    updateChart(datasets, labels);
  }, [datasets, labels, annotation]);

  return <canvas id={id} ref={ref} height={height} width={width} />;
};

BaseChart.propTypes = baseChartPropTypes;
BaseChart.defaultProps = baseChartDefaultProps;

export default BaseChart;
