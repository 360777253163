import { useMutation } from '@apollo/client';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Button, Card, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { QuizLiveShowFragmentFragment } from '../../../../../../__gqltypes__';
import { REMOVE_SPECIAL_EVENT_LIVE_SHOW } from '../../LiveShows/graphql';

type Props = {
  specialEventId: string;
  quizLiveShows: QuizLiveShowFragmentFragment[];
};

const SpecialEventQuizLiveShow = ({ specialEventId, quizLiveShows }: Props) => {
  const [currentQuizLiveShows, setCurrentQuizLiveShows] = useState<QuizLiveShowFragmentFragment[] | null>(null);

  const [removeQuizLiveShow, { loading: removeQuizLiveShowLoading }] = useMutation(REMOVE_SPECIAL_EVENT_LIVE_SHOW);

  const unlinkQuizLiveShow = async (quizLiveShowId: string) => {
    await removeQuizLiveShow({ variables: { input: { specialEventId, quizLiveShowId } } });
    if (!_.isNil(currentQuizLiveShows)) {
      const _currentQuizLiveShows = [...currentQuizLiveShows];
      const index = _currentQuizLiveShows.findIndex((quizLiveShow) => quizLiveShow.id === quizLiveShowId);
      _currentQuizLiveShows.splice(index, 1);
      setCurrentQuizLiveShows(_currentQuizLiveShows);
    }
  };

  useEffect(() => {
    setCurrentQuizLiveShows(quizLiveShows);
  }, [quizLiveShows]);

  return (
    <Card className="mt-3 mb-3">
      <Card.Body className="text-center">
        <Card.Title>QuizLiveShows</Card.Title>
        <Table bordered responsive hover>
          <thead className="text-center">
            <tr>
              <th>Id</th>
              <th>StartDate</th>
              <th>Unlink</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {currentQuizLiveShows ? (
              currentQuizLiveShows.map((quizLiveShow, quizLiveShowIndex) => (
                <tr key={quizLiveShowIndex}>
                  <td className="align-middle">
                    <Link to={`/sp3/liveops/liveshows/${quizLiveShow.id}`}>{quizLiveShow.id}</Link>
                  </td>
                  <td className="align-middle">{new Date(quizLiveShow.startDate).toLocaleString()}</td>

                  <td className="p-0 align-middle text-center">
                    <Button
                      disabled={removeQuizLiveShowLoading}
                      variant="danger"
                      onClick={() => unlinkQuizLiveShow(quizLiveShow.id)}
                      className="square-icon icon-sm"
                    >
                      <i className="fas fa-times" />
                    </Button>
                  </td>
                </tr>
              ))
            ) : (
              <div>no quizLiveShow linked</div>
            )}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default SpecialEventQuizLiveShow;
