import { ImportantEventType } from '../../../../../__gqltypes__';

export const eventTypeLabels = {
  [ImportantEventType.CUSTOM]: { label: 'Custom event' },
  [ImportantEventType.BACKENDDEPLOYMENT]: { label: 'Backend deployment' },
  [ImportantEventType.FRONTENDDEPLOYMENT]: { label: 'Frontend deployment' },
};

export const eventColors: Record<ImportantEventType, string> = {
  [ImportantEventType.CUSTOM]: '#70C1B3',
  [ImportantEventType.BACKENDDEPLOYMENT]: '#F25F5C',
  [ImportantEventType.FRONTENDDEPLOYMENT]: '#247BA0',
};
