import _ from 'lodash';
import React, { useEffect } from 'react';
import './PlayButton.css';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const AudioContext = window.AudioContext || window.webkitAudioContext;

const audioContext = new AudioContext();

function formatTimestamp(seconds: number) {
  const minutes = Math.round(seconds / 60);
  const s = seconds % 60;
  return (
    <span className="text-monospace m-auto" style={{ fontSize: 12 }}>
      {minutes}:{s < 10 ? `0${s.toFixed(1)}` : s.toFixed(1)}
    </span>
  );
}

type Props = {
  sample?: string | null;
  children?: JSX.Element;
  displayTimestamp?: boolean;
  startAt?: number;
};

export default function PlayButton({ sample, children, displayTimestamp, startAt }: Props) {
  const [isPlaying, setIsPlaying] = React.useState(false);
  const audioElement = React.useRef<HTMLAudioElement | null>(null);
  const [timestamp, setTimestamp] = React.useState(startAt ?? 0);
  const timer = React.useRef<NodeJS.Timer | null>(null);

  useEffect(() => {
    if (!isPlaying && !timer.current && _.isNumber(startAt)) {
      setTimestamp(startAt);
      if (audioElement.current) {
        audioElement.current.currentTime = startAt ?? 0;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startAt]);

  const play = (event: { stopPropagation: () => void }) => {
    init();
    if (audioElement.current) {
      // pass it into the audio context
      if (audioContext.state === 'suspended') {
        audioContext.resume();
      }

      timer.current = setInterval(() => {
        setTimestamp(audioElement.current?.currentTime ?? startAt ?? 0);
      }, 100);

      audioElement.current.play();
      setIsPlaying(true);
      event.stopPropagation();
    }
  };

  const pause = (event?: { stopPropagation: () => void }) => {
    if (audioElement.current) {
      // pass it into the audio context
      if (audioContext.state === 'suspended') {
        audioContext.resume();
      }
      if (timer.current) {
        clearInterval(timer.current);
        timer.current = null;
      }
      audioElement.current.pause();
      setIsPlaying(false);
      if (event) {
        event.stopPropagation();
      }
    }
  };

  const init = () => {
    if (!audioElement.current && sample) {
      audioElement.current = new Audio();
      audioElement.current.addEventListener('ended', () => {
        pause();
      });
      audioElement.current.src = sample;
      audioElement.current.controls = false;
      audioElement.current.loop = false;
      audioElement.current.crossOrigin = 'anonymous';
      audioElement.current.autoplay = false;
      audioElement.current.currentTime = startAt ?? 0;

      const track = audioContext.createMediaElementSource(audioElement.current);
      track.connect(audioContext.destination);
    }
  };

  React.useEffect(() => {
    // we want to pause the music when exiting the view
    return function cleanup() {
      pause();
    };
  }, []);

  const innerContainer = isPlaying ? (
    <span onClick={pause} role="button" tabIndex={0} className="m-auto">
      <i className="fas fa-pause-circle play-button" />
    </span>
  ) : (
    <span onClick={play} role="button" tabIndex={0} className="m-auto">
      <i className="fas fa-play-circle play-button" />
    </span>
  );

  if (displayTimestamp) {
    return (
      <div className="d-flex flex-column">
        {innerContainer}
        {displayTimestamp && formatTimestamp(timestamp)}
      </div>
    );
  }
  return innerContainer;
}
