import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// PropTypes
import { slicerPropTypes } from '../slicerPropTypes';

const DateTimeSlicer = ({ id, value, onChange }) => (
  <Form.Group as={Row} controlId={id}>
    <Form.Label column>{id}</Form.Label>
    <Col>
      <Form.Control type="datetime-local" value={value} onChange={(e) => onChange(e.target.value || null)} />
    </Col>
  </Form.Group>
);

DateTimeSlicer.propTypes = slicerPropTypes;

DateTimeSlicer.defaultProps = {
  onChange: () => {},
};

export default DateTimeSlicer;
