import { gql } from '@apollo/client';
import { BASIC_SONG_INFO_FRAGMENT, HURDLE_CATALOG_ITEM_FRAGMENT, SONG_CUSTOM_FIELDS_FRAGMENT } from './fragments';

export default {
  CREATE_CURATION_CONTENT_PACK_BY_URL: gql`
    mutation createCurationContentPackByUrl($input: CreateCurationContentPackByUrlInput!) {
      createCurationContentPackByUrl(input: $input) {
        curationContentPack {
          id
          name
          picture
          created
          state
          explicitContentStatus
        }
      }
    }
  `,
  CREATE_CURATION_CONTENT_PACK_BY_CSV: gql`
    mutation createCurationContentPackByCSV($input: CreateCurationContentPackByCSVInput!) {
      createCurationContentPackByCSV(input: $input) {
        curationContentPack {
          id
          name
          picture
          created
          state
          explicitContentStatus
        }
      }
    }
  `,
  CREATE_CURATION_CONTENT_PACK_FROM_SP2: gql`
    mutation createCurationContentPackFromSp2($input: CreateCurationContentPackFromSp2Input!) {
      createCurationContentPackFromSp2(input: $input) {
        curationContentPack {
          id
          name
          picture
          created
          state
          explicitContentStatus
        }
      }
    }
  `,
  CREATE_CURATION_CONTENT_PACK_BY_ARTISTS: gql`
    mutation createCurationContentPackByArtists($input: CreateCurationContentPackByArtistsInput!) {
      createCurationContentPackByArtists(input: $input) {
        curationContentPack {
          id
          name
          picture
          created
          state
          explicitContentStatus
        }
      }
    }
  `,
  IMPORT_ITUNES_DATABASE: gql`
    mutation importItunesDatabase {
      importItunesDatabase {
        ingestStatus {
          resultUrl
          created
          status
        }
      }
    }
  `,
  EXPORT_MUSIC_CATALOG: gql`
    mutation exportMusicCatalog {
      exportMusicCatalog {
        success
      }
    }
  `,
  MAKE_MUSIC_CATALOG_AVAILABLE: gql`
    mutation makeMusicCatalogAvailable {
      makeMusicCatalogAvailable {
        catalogExport {
          id
          status
          created
          started
          completed
          available
        }
      }
    }
  `,

  GENERATE_REPORT: gql`
    mutation generateSP2ReportingTable($input: GenerateReportInput!) {
      generateSP2ReportingTable(input: $input) {
        success
      }
    }
  `,
  GENERATE_SPP_REPORT: gql`
    mutation generateSPPReportingTable($input: GenerateReportInput!) {
      generateReport(input: $input) {
        success
      }
    }
  `,
  GENERATE_REPORT_V2: gql`
    mutation generateCombinedMusicReport($input: GenerateReportInput!) {
      generateCombinedMusicReport(input: $input) {
        report {
          id
          created
          formattedDate
        }
      }
    }
  `,

  DELETE_CURATION_CONTENT_PACK: gql`
    mutation deleteCurationContentPack($input: DeleteCurationContentPackInput!) {
      deleteCurationContentPack(input: $input) {
        curationContentPack {
          id
          state
          deleted
        }
      }
    }
  `,

  ADD_DDEX_SONGS_TO_CONTENT_PACK: gql`
    mutation addDDEXSongToCurationContentPack($input: AddDDEXSongToCurationContentPackInput!) {
      addDDEXSongToCurationContentPack(input: $input) {
        curationContentPack {
          id
          contentItems {
            ... on CurationSong {
              id
              songInput {
                id
              }
            }
            ... on CurationSongInput {
              id
            }
          }
        }
      }
    }
  `,

  REORDER_SONGS_FROM_CURATION_CONTENT_PACK: gql`
    mutation reorderSongsFromCurationContentPack($input: ReorderSongsFromCurationContentPackInput!, $id: ID!) {
      reorderSongsFromCurationContentPack(input: $input) {
        curationContentPack {
          id
          contentItems {
            ... on CurationSong {
              id
              playlistCustomDisplay(contentPackId: $id)
              songInput {
                id
              }
            }
            ... on CurationSongInput {
              id
            }
          }
        }
      }
    }
  `,

  CREATE_CURATION_CONTENT_PACK_NEW_DRAFT: gql`
    mutation createCurationContentPackNewDraft($input: CreateCurationContentPackNewDraftInput!) {
      createCurationContentPackNewDraft(input: $input) {
        curationContentPack {
          id
          originId
          state
          version
        }
      }
    }
  `,
  COMMIT_CURATION_CONTENT_PACK: gql`
    mutation commitCurationContentPack($input: CommitCurationContentPackInput!) {
      commitCurationContentPack(input: $input) {
        curationContentPack {
          id
          state
          committed
        }
      }
    }
  `,
  UPDATE_CURATION_CONTENT_PACK_NAME: gql`
    mutation updateCurationContentPackName($input: UpdateCurationContentPackNameInput!) {
      updateCurationContentPackName(input: $input) {
        curationContentPack {
          id
          name
        }
      }
    }
  `,
  UPDATE_CURATION_CONTENT_PACK_DESCRIPTION: gql`
    mutation updateCurationContentPackDescription($input: UpdateCurationContentPackDescriptionInput!) {
      updateCurationContentPackDescription(input: $input) {
        curationContentPack {
          id
          description
        }
      }
    }
  `,
  UPDATE_CURATION_CONTENT_PACK_CUSTOM_FIELDS: gql`
    mutation updateCurationContentPackCustomFields($input: UpdateCurationContentPackCustomFieldsInput!, $id: ID!) {
      updateCurationContentPackCustomFields(input: $input) {
        curationContentPack {
          id
          selectedCustomFields
          contentItems {
            ... on CurationSong {
              id
              isInvalid(contentPackId: $id)
              songInput {
                id
              }
            }
          }
        }
      }
    }
  `,
  UPDATE_CURATION_CONTENT_PACK_CATEGORIES: gql`
    mutation updateCurationContentPackCategories($input: UpdateCurationContentPackCategoriesInput!) {
      updateCurationContentPackCategories(input: $input) {
        curationContentPack {
          id
          categories {
            id
            name
          }
        }
      }
    }
  `,
  UPDATE_CURATION_CONTENT_PACK_LOCALE: gql`
    mutation updateCurationContentPackLocale($input: UpdateCurationContentPackLocaleInput!) {
      updateCurationContentPackLocale(input: $input) {
        curationContentPack {
          id
          locale
        }
      }
    }
  `,
  UPDATE_CURATION_CONTENT_PACK_TYPE: gql`
    mutation updateCurationContentPackType($input: UpdateCurationContentPackTypeInput!) {
      updateCurationContentPackType(input: $input) {
        curationContentPack {
          id
          type
        }
      }
    }
  `,

  TOGGLE_SP2_TAG: gql`
    mutation toggleSP2OnlyTag($input: ToggleSP2OnlyTagInput!) {
      toggleSP2OnlyTag(input: $input) {
        curationContentPack {
          id
          sp2Only
        }
      }
    }
  `,
  TOGGLE_ADULT_TAG: gql`
    mutation toggleCurationContentPackAdultOnlyTag($input: ToggleCurationContentPackAdultOnlyTagInput!) {
      toggleCurationContentPackAdultOnlyTag(input: $input) {
        curationContentPack {
          id
          adultOnly
        }
      }
    }
  `,

  UPDATE_CONTENT_PACK_AVAILABILITY_TYPE: gql`
    mutation updateContentPackAvailabilityType($input: UpdateContentPackAvailabilityTypeInput!) {
      updateContentPackAvailabilityType(input: $input) {
        contentPack {
          id
          availabilityType
        }
      }
    }
  `,

  UPDATE_CONTENT_PACK_AVAILABILITY_DATE: gql`
    mutation updateContentPackAvailabilityDate($input: UpdateContentPackAvailabilityDateInput!) {
      updateContentPackAvailabilityDate(input: $input) {
        contentPack {
          id
          availabilityDate
        }
      }
    }
  `,

  UPDATE_CONTENT_PACK_LAST_UPDATE_DATE: gql`
    mutation updateContentPackLastUpdateDate($input: UpdateContentPackLastUpdateDateInput!) {
      updateContentPackLastUpdateDate(input: $input) {
        contentPack {
          id
          lastUpdateDate
        }
      }
    }
  `,

  UPDATE_CURATION_SONG: gql`
    mutation updateCurationSong(
      $id: ID!
      $contentPackId: ID!
      $type: UpdateCurationSongInputType!
      $value: String
      $songInputId: ID!
    ) {
      updateCurationSong(input: { id: $id, contentPackId: $contentPackId, type: $type, value: $value }) {
        curationSong {
          id
          isInvalid(contentPackId: $contentPackId)
          displayTitle
          displayArtist
          movieTitle
          leadSinger
          jesterTitle
          composer
          songInput(songInputId: $songInputId) {
            id
          }
        }
      }
    }
  `,
  UPDATE_PLAYLIST_CUSTOM_DISPLAY: gql`
    mutation updatePlaylistCustomDisplay($id: ID!, $contentPackId: ID!, $songInputId: ID!, $value: String) {
      updateCurationSong(
        input: {
          id: $id
          contentPackId: $contentPackId
          type: PLAYLIST_CUSTOM_FIELD
          songInputId: $songInputId
          value: $value
        }
      ) {
        curationSong {
          id
          isInvalid(contentPackId: $contentPackId)
          playlistCustomDisplay(contentPackId: $contentPackId)
          songInput(songInputId: $songInputId) {
            id
          }
        }
      }
    }
  `,
  UPDATE_CURATION_SONG_SAMPLE: gql`
    mutation updateCurationSongSample(
      $id: ID!
      $contentPackId: ID!
      $file: Upload
      $url: String
      $type: UpdateCurationSongSampleInputType!
      $startingTime: Float
      $songInputId: ID!
    ) {
      updateCurationSongSample(
        input: {
          id: $id
          contentPackId: $contentPackId
          type: $type
          file: $file
          url: $url
          startingTime: $startingTime
          songInputId: $songInputId
        }
      ) {
        curationSong {
          id
          isInvalid(contentPackId: $contentPackId)
          playlistCustomSample(contentPackId: $contentPackId)
          defaultSample
          introSample
          songInput(songInputId: $songInputId) {
            id
          }
        }
      }
    }
  `,
  REMOVE_SONG_FROM_PLAYLIST: gql`
    mutation removeSongFromCurationContentPack($id: ID!, $songInputId: ID!) {
      removeSongFromCurationContentPack(input: { id: $id, songInputId: $songInputId }) {
        curationContentPack {
          id
          contentItems {
            ... on CurationSong {
              id
              playlistCustomDisplay(contentPackId: $id)
              songInput {
                id
              }
            }
            ... on CurationSongInput {
              id
            }
          }
        }
      }
    }
  `,
  REMOVE_SONGS_FROM_PLAYLIST: gql`
    mutation removeSongsFromCurationContentPack($id: ID!, $songInputIds: [ID!]) {
      removeSongFromCurationContentPack(input: { id: $id, songInputIds: $songInputIds }) {
        curationContentPack {
          id
          contentItems {
            ... on CurationSong {
              id
              playlistCustomDisplay(contentPackId: $id)
              songInput {
                id
              }
            }
            ... on CurationSongInput {
              id
            }
          }
        }
      }
    }
  `,
  REMOVE_SONGS_FROM_COMMITTEDPLAYLIST: gql`
    mutation removeSongsFromCommittedContentPack($id: ID!, $songInputIds: [ID!], $updateCommittedPack: Boolean) {
      removeSongFromCurationContentPack(
        input: { id: $id, songInputIds: $songInputIds, updateCommittedPack: $updateCommittedPack }
      ) {
        curationContentPack {
          id
          contentItems {
            ... on CurationSong {
              id
              playlistCustomDisplay(contentPackId: $id)
              songInput {
                id
              }
            }
            ... on CurationSongInput {
              id
            }
          }
        }
      }
    }
  `,

  FORCE_SONG_INPUT_MATCHING: gql`
    mutation forceSongInputMatching(
      $songInputId: ID!
      $songId: ID
      $itunesId: Int
      $ddexSongId: ID
      $contentPackId: ID!
    ) {
      forceSongInputMatching(
        input: { songInputId: $songInputId, songId: $songId, itunesId: $itunesId, ddexSongId: $ddexSongId }
      ) {
        curationSong {
          id
          ...BasicSongInfo
          ...SongCustomFields
          songInput(songInputId: $songInputId) {
            id
            matchType
          }
          isInvalid(contentPackId: $contentPackId)
          playlistCustomDisplay(contentPackId: $contentPackId, songInputId: $songInputId)
        }
        suggestedSong {
          id
          title
          artist
          album
          isrc
        }
      }
    }
    ${BASIC_SONG_INFO_FRAGMENT}
    ${SONG_CUSTOM_FIELDS_FRAGMENT}
  `,
  UPDATE_CURATION_CONTENT_PACK_PICTURE: gql`
    mutation updateCurationContentPackPicture($input: UpdateCurationContentPackPictureInput!) {
      updateCurationContentPackPicture(input: $input) {
        curationContentPack {
          id
          picture
        }
      }
    }
  `,
  UPDATE_REPLACING_CURATION_CONTENT_PACK: gql`
    mutation updateReplacingCurationContentPack($input: UpdateReplacingCurationContentPackInput!) {
      updateReplacingCurationContentPack(input: $input) {
        curationContentPack {
          id
          replacingContentPack {
            id
            originId
            name
            picture
            description
          }
        }
      }
    }
  `,
  CONVERT_SAMPLES: gql`
    mutation convertSamples($input: ConvertSamplesInput!) {
      convertSamples(input: $input) {
        success
        message
      }
    }
  `,
  CONVERT_TO_SP2_SAMPLES: gql`
    mutation convertToSp2Samples($input: ConvertToSp2SamplesInput!) {
      convertToSp2Samples(input: $input) {
        success
        message
      }
    }
  `,

  REFRESH_SPP_CONTENT: gql`
    mutation refreshSppContent($input: RefreshSppContentInput!) {
      refreshSppContent(input: $input) {
        success
        message
      }
    }
  `,
  AUTHENTICATE_GOOGLE_SHEET: gql`
    mutation authenticateGoogleSheet($code: String!) {
      authenticateGoogleSheet(input: { code: $code }) {
        success
        message
      }
    }
  `,
  EXPORT_CCP_TO_SP2: gql`
    mutation exportCurationContentPackToSp2($id: ID!) {
      exportCurationContentPackToSp2(input: { id: $id }) {
        curationContentPack {
          id
          sp2ExportId
        }
      }
    }
  `,
  UPDATE_CURATION_CONTENT_PACK_SP2_PRICE: gql`
    mutation updateCurationContentPackPriceToSp2($input: UpdateCurationContentPackPriceToSp2Input!) {
      updateCurationContentPackPriceToSp2(input: $input) {
        sp2CurationPlaylist {
          id
          price
        }
      }
    }
  `,
  UPDATE_CURATION_CONTENT_PACK_SP2_BADGES: gql`
    mutation updateCurationContentPackBadgesToSp2($input: UpdateCurationContentPackBadgesToSp2Input!) {
      updateCurationContentPackBadgesToSp2(input: $input) {
        sp2CurationPlaylist {
          id
          badges
        }
      }
    }
  `,
  CREATE_CONTENT_PACK_LIST: gql`
    mutation createContentPackList($input: CreateContentPackListInput!) {
      createContentPackList(input: $input) {
        contentPackListId
        name
        description
        contentPacks {
          id
          name
          popularity
          explicitContentStatus
        }
      }
    }
  `,
  UPDATE_CONTENT_PACK_LIST: gql`
    mutation updateContentPackList($input: UpdateContentPackListInput!) {
      updateContentPackList(input: $input) {
        contentPackListId
        name
        description
        contentPacks {
          id
          name
          popularity
          explicitContentStatus
        }
      }
    }
  `,
  DELETE_CONTENT_PACK_LIST: gql`
    mutation deleteContentPackList($contentPackListId: ID!) {
      deleteContentPackList(contentPackListId: $contentPackListId)
    }
  `,
  CREATE_CURATION_CONTENT_PACK_LIST: gql`
    mutation createCurationContentPackList($input: CreateCurationContentPackListInput!) {
      createCurationContentPackList(input: $input) {
        id
        name
        description
        curationContentPacks {
          id
          originId
          name
          type
          picture
          locale
          localeReplacements {
            id
            name
            originId
            picture
            type
            locale
          }
          explicitContentStatus
        }
      }
    }
  `,

  UPDATE_CURATION_CONTENT_PACK_LIST: gql`
    mutation updateCurationContentPackList($input: UpdateCurationContentPackListInput!) {
      updateCurationContentPackList(input: $input) {
        id
        name
        description
        curationContentPacks {
          id
          originId
          name
          type
          picture
          locale
          localeReplacements {
            id
            name
            originId
            picture
            type
            locale
          }
          explicitContentStatus
        }
      }
    }
  `,
  DELETE_CURATION_CONTENT_PACK_LIST: gql`
    mutation deleteCurationContentPackList($id: ID!) {
      deleteCurationContentPackList(id: $id)
    }
  `,

  UPDATE_CURATION_CONTENT_PACK_SP2_PICTURE: gql`
    mutation updateCurationContentPackPictureToSp2($input: UpdateCurationContentPackPictureToSp2Input!) {
      updateCurationContentPackPictureToSp2(input: $input) {
        sp2CurationPlaylist {
          id
          artwork
        }
      }
    }
  `,
  COMMIT_CPP_IN_SP2: gql`
    mutation commitSP2CurationPlaylist($input: CommitSP2CurationPlaylistInput!) {
      commitSP2CurationPlaylist(input: $input) {
        sp2CurationPlaylist {
          id
          isCommitInProgress
        }
      }
    }
  `,
  SAVE_SP2_RELEASE: gql`
    mutation crupdateSP2Release($input: CrupdateSP2ReleaseInput!) {
      crupdateSP2Release(input: $input) {
        sp2Release {
          id
          visibleDate
          state
          type
          playlistIds
        }
      }
    }
  `,
  DELETE_SP2_RELEASE: gql`
    mutation deleteSP2Release($input: DeleteSP2ReleaseInput!) {
      deleteSP2Release(input: $input) {
        sp2Release {
          id
        }
      }
    }
  `,
  SP2_IMPORT_BATCH: gql`
    mutation createCurationContentPackFromSp2Multi($input: CreateCurationContentPackFromSp2MultiInput!) {
      createCurationContentPackFromSp2Multi(input: $input) {
        success
      }
    }
  `,

  CREATE_BATCH_CONTENT_MODERATION: gql`
    mutation createBatchContentModeration($input: CreateBatchContentModerationInput!) {
      createBatchContentModeration(input: $input) {
        id
        name
        state
        curationContentPacks {
          id
          name
          originId
          picture
        }
      }
    }
  `,
  UPDATE_BATCH_CONTENT_MODERATION: gql`
    mutation updateBatchContentModeration($input: UpdateBatchContentModerationInput!) {
      updateBatchContentModeration(input: $input) {
        id
        name
        state
        curationContentPacks {
          id
          name
          originId
        }
      }
    }
  `,
  START_BATCH_CONTENT_MODERATION: gql`
    mutation startBatchContentModeration($input: StartBatchContentModerationInput!) {
      startBatchContentModeration(input: $input) {
        id
        name
        state
      }
    }
  `,
  END_BATCH_CONTENT_MODERATION: gql`
    mutation endBatchContentModeration($input: EndBatchContentModerationInput!) {
      endBatchContentModeration(input: $input) {
        id
        name
        state
      }
    }
  `,
  MODERATE_CONTENT_ITEM: gql`
    mutation moderateContentItem($input: ModerateContentItemInput!) {
      moderateContentItem(input: $input) {
        id
        state
        note
      }
    }
  `,
  ADMIN_MODERATE_CONTENT_ITEM: gql`
    mutation adminModerateContentItem($input: AdminModerateContentItemInput!) {
      adminModerateContentItem(input: $input) {
        id
        state
        adminState
      }
    }
  `,
  UPDATE_CONTENT_ITEM_LOCALE: gql`
    mutation updateLocaleForModerationContentItem($input: UpdateLocaleForModerationContentItemInput!) {
      updateLocaleForModerationContentItem(input: $input) {
        id
        state
        locale
      }
    }
  `,
  SET_MODERATOR_TO_CONTENT_PACK: gql`
    mutation setModeratorToContentPack($input: SetModeratorToContentPack!) {
      setModeratorToContentPack(input: $input) {
        id
        curationContentPack {
          id
        }
        moderator {
          id
        }
      }
    }
  `,
  IMPORT_CONTENTPACKS_TO_HURDLE_CATALOG: gql`
    mutation importSongsFromContentPackListToHurdleCatalog(
      $input: ImportSongsFromContentPackListToHurdleCatalogInput!
    ) {
      importSongsFromContentPackListToHurdleCatalog(input: $input) {
        newSongsImported
        alreadyInCatalogNumber
        excludedExplicitItemsNumber
        excludedQuestionableItemsNumber
        excludedBannedItemsNumber
        inSeveralNewContentPacksNumber
      }
    }
  `,

  UPDATE_HURDLE_CATALOG_ITEM: gql`
    mutation updateHurdleCatalogItem($input: UpdateHurdleCatalogItemInput!) {
      updateHurdleCatalogItem(input: $input) {
        catalogItem {
          ...HurdleCatalogItemFragment
        }
      }
    }

    ${HURDLE_CATALOG_ITEM_FRAGMENT}
  `,

  DELETE_HURDLE_CATALOG_ITEM: gql`
    mutation deleteHurdleCatalogItem($input: DeleteHurdleCatalogItemInput!) {
      deleteHurdleCatalogItem(input: $input) {
        deleted
      }
    }
  `,

  FLUSH_HURDLE_CACHE: gql`
    mutation flushHurdleCache {
      flushHurdleCache {
        flushed
      }
    }
  `,
};
