import { gql } from '@apollo/client';
import { MISSION_FRAGMENT } from '../liveops/components/apolloQueries';
import { HURDLE_QUIZ_FRAGMENT } from '../liveops/screens/HurdleQuiz/graphql';

export const GET_SESSIONS = gql`
  query getLogs($userID: ID, $installID: ID, $startDate: Date!) {
    analytics {
      id
      appSessions(userId: $userID, installId: $installID, startDate: $startDate) {
        data
      }
    }
  }
`;

export const GET_SESSION_LOGS = gql`
  query getSessionLogs($sessionID: ID!, $sessionDate: Date!) {
    analytics {
      id
      appLogsBySession(sessionId: $sessionID, sessionDate: $sessionDate) {
        frontendLogs {
          data
          error
        }
        backendLogs {
          data
          error
        }
        crashLogs {
          data
          error
        }
      }
    }
  }
`;

export const GET_RUNTIME_LOGS = gql`
  query getRuntimeLogs($runtimeID: ID!, $offset: Int!, $limit: Int!, $startDate: Date) {
    analytics {
      id
      backendLogsByRuntime(runtimeId: $runtimeID, offset: $offset, limit: $limit, startDate: $startDate) {
        data
      }
    }
  }
`;

export const GET_USER_FROM_SESSION = gql`
  query getUser($property: String!, $type: UserSearchType!) {
    user(property: $property, type: $type) {
      id
      displayName
      picture
      subscriptionTier
      phoneCredential
      emailCredential
      facebookCredential
      appleCredential
      googleCredential
      userLevel
      userLevelProgressToNextLevel
      coinBalance
      diamondBalance
      energySystems {
        tournamentTicket {
          extraBalance
        }
      }
      basicTierContentPack {
        contentPack {
          name
        }
      }
      ABTestList {
        id
        variation
      }
      stampCards
      totalStampCardsEarned
    }
  }
`;

export const GET_USERS_FROM_SESSION = gql`
  query getUsersFromSession($properties: [String!]!, $type: UserSearchType!) {
    users(properties: $properties, type: $type) {
      id
      displayName
      picture
      subscriptionTier
      phoneCredential
      emailCredential
      facebookCredential
      appleCredential
      googleCredential
      userLevel
      userLevelProgressToNextLevel
      coinBalance
      diamondBalance
      basicTierContentPack {
        contentPack {
          name
        }
      }
      ABTestList {
        id
        variation
      }
    }
  }
`;

export const BAN_USER_MUTATION = gql`
  mutation banUser($input: BanUserInput!) {
    banUser(input: $input) {
      user {
        id
        displayName
        picture
        role
      }
    }
  }
`;

export const UNBAN_USER_MUTATION = gql`
  mutation unbanUser($input: UnbanUserInput!) {
    unbanUser(input: $input) {
      user {
        id
        role
      }
    }
  }
`;

export const FORCE_USER_ABTEST_MUTATION = gql`
  mutation forceUserTest($input: ForceUserABTestInput!) {
    forceUserABTest(input: $input) {
      user {
        id
        ABTestList {
          id
          variation
        }
        ABTestForcedList {
          id
        }
      }
    }
  }
`;

const LOYALTY_FRAGMENT = gql`
  fragment LoyaltyFragment on Loyalty {
    futureCard {
      ...LoyaltyCardFragment
    }
    currentCard {
      ...LoyaltyCardFragment
    }
    previousCard {
      ...LoyaltyCardFragment
    }
    tradeableCardCount
    cardStreak
    dayStreak
    previousCardStreak
  }

  fragment LoyaltyCardFragment on LoyaltyCard {
    id
    isExpired
    isCompleted
    currentDayIndex
    nextClaimableDayIndex
    days {
      rewards {
        __typename
        ... on Currency {
          amount
        }
        ... on CurrencyBoost {
          boostType
        }
        ... on CurrencyUnlimitedBoost {
          unlimitedBoostType
        }
      }
      isClaimed
      claimedMultiplier
      claimedDate
      start
      end
    }
  }
`;

const MONTHLY_PASS_MISSIONS_FRAGMENT = gql`
  fragment MissionProgressFragment on MissionProgress {
    id
    mission {
      ...MissionFragment
    }
    progress
    progressGoal
    completedDate
    claimedDate
    claimedMultiplier
  }
  fragment MonthlyPassMissionsFragment on UserMonthlyPassMissions {
    id
    dailyMissions {
      ...MissionProgressFragment
    }
    monthlyMissions {
      ...MissionProgressFragment
    }
    extraMission {
      ...MissionProgressFragment
    }
    extraMissionNo
  }
  ${MISSION_FRAGMENT}
`;

const USER_FRAGMENT = gql`
  fragment UserViewerFragment on User {
    id
    displayName
    picture
    emailCredential
    subscriptionTier
    subscriptionDetails {
      sku
      autoRenews
      activeSince
      expires
      isFreeTrial
    }
    userLevel
    userLevelProgressToNextLevel
    currentUserMonthlyPass {
      progressToNextLevel
      level
      missions {
        ...MonthlyPassMissionsFragment
      }
    }
    achievementMissions {
      id
      missions {
        ...MissionProgressFragment
      }
    }
    coinBalance
    diamondBalance
    vipDays
    premiumDays
    energySystems {
      tournamentTicket {
        currentCapSupply
        extraBalance
      }
      coin {
        currentCapSupply
      }
      xp {
        currentCapSupply
      }
    }
    boostRemove2Balance
    boostTeamSpiritBalance
    boostReviveBalance
    boostNinjaBalance
    boostBombBalance
    boostSpeedsterBalance
    boostArtistOnlyBalance
    boostHurdleHintBalance
    stampCards
    totalStampCardsEarned
    basicTierContentPack {
      id
      contentPack {
        id
        name
        picture
      }
      level
      progressToNextLevel
    }
    fragmentBalance {
      id
      category {
        id
        name
      }
      amount
    }
    stickers {
      id
    }
    profileFrames {
      id
    }
    appSkins {
      id
    }
    ABTestList {
      id
      variation
    }
    ABTestForcedList {
      id
    }
    ABTestConfigs {
      id
      variations
      status
    }
    ABTestLastAppInstallList {
      id
      variation
    }
    role
    reports {
      reporter {
        id
      }
      created
      comment
    }
    unlimitedBoosts {
      unlimitedBoostRemove2Balance
      unlimitedBoostSpeedsterBalance
    }
    loyalty {
      ...LoyaltyFragment
    }
    ownedContentPacks {
      list {
        contentPack {
          id
          popularity
          explicitContentStatus
          name
        }
      }
    }
    currentSpecialEvent {
      id
      meScoreDetails {
        type
        score
      }
    }
    piggyBank {
      coins
      diamonds
    }
    metaFragments {
      BRONZE {
        amount
      }
      SILVER {
        amount
      }
      GOLD {
        amount
      }
    }
    team {
      id
    }
  }

  ${LOYALTY_FRAGMENT}
  ${MONTHLY_PASS_MISSIONS_FRAGMENT}
  ${MISSION_FRAGMENT}
`;

export const RESET_USER_SUBSCRIPTIONS_MUTATION = gql`
  mutation resetUserSubscriptions($input: ResetUserSubscriptionsInput!) {
    resetUserSubscriptions(input: $input) {
      user {
        ...UserViewerFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const SEARCH_USER = gql`
  query searchUser($input: String!, $type: UserSearchType!) {
    user(property: $input, type: $type) {
      ...UserViewerFragment
      isInfluencer
      influencerId
      testCredential
    }
  }
  ${USER_FRAGMENT}
`;

export const UPDATE_USER = gql`
  mutation updateUser($input: ForceUpdateUserInput!) {
    forceUpdateUser(input: $input) {
      user {
        ...UserViewerFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const FLUSH_USER_CACHE_MUTATION = gql`
  mutation refreshUserRedisCache($input: RefreshUserRedisCacheInput!) {
    refreshUserRedisCache(input: $input) {
      user {
        ...UserViewerFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const FLUSH_REDIS_CACHE_FOR_ONE_USER_MUTATION = gql`
  mutation flushRedisCacheForOneUser($input: FlushRedisCacheForOneUserInput!) {
    flushRedisCacheForOneUser(input: $input) {
      user {
        ...UserViewerFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const GET_USER_LAST_SESSION = gql`
  query getUserLastSession($id: ID!) {
    analytics {
      id
      userLastSession(id: $id) {
        id
        date
      }
    }
  }
`;

export const APP_INFORMATION = gql`
  query appInformation {
    me {
      energySystems {
        coin {
          caps {
            basic {
              maxStorage
            }
            ad_free {
              maxStorage
            }
            premium {
              maxStorage
            }
            vip {
              maxStorage
            }
            plus {
              maxStorage
            }
          }
        }
        xp {
          caps {
            basic {
              maxStorage
            }
            ad_free {
              maxStorage
            }
            premium {
              maxStorage
            }
            vip {
              maxStorage
            }
            plus {
              maxStorage
            }
          }
        }
        tournamentTicket {
          caps {
            basic {
              maxStorage
            }
            ad_free {
              maxStorage
            }
            premium {
              maxStorage
            }
            vip {
              maxStorage
            }
            plus {
              maxStorage
            }
          }
        }
      }
    }
    app {
      contentCategories {
        id
        name
        icon
      }
    }
  }
`;

export const LIVE_OPS_CONTENT = gql`
  query liveOpsContent {
    liveOps {
      stickers {
        list {
          id
          name
          asset
          liveOpsAvailability
          nextLevelItems {
            level
          }
        }
      }
      appskins {
        list {
          id
          name
          iconAsset
          liveOpsAvailability
          nextLevelItems {
            level
          }
        }
      }
      profileFrames {
        list {
          id
          name
          asset
          liveOpsAvailability
          nextLevelItems {
            level
            asset
          }
        }
      }
    }
  }
`;

export const UPDATE_LOYALTY_CARD = gql`
  mutation updateLoyaltyCard($input: UpdateLoyaltyCardInput!) {
    updateLoyaltyCard(input: $input) {
      user {
        id
        loyalty {
          ...LoyaltyFragment
        }
      }
    }
  }
  ${LOYALTY_FRAGMENT}
`;

export const RESET_LOYALTY_CARD = gql`
  mutation resetLoyaltyCard($input: ResetLoyaltyCardInput!) {
    resetLoyaltyCard(input: $input) {
      user {
        id
        loyalty {
          ...LoyaltyFragment
        }
      }
    }
  }
  ${LOYALTY_FRAGMENT}
`;

export const REVIVE_LOYALTY_CARD = gql`
  mutation reviveLoyaltyCard($input: ReviveLoyaltyCardInput!) {
    reviveLoyaltyCard(input: $input) {
      user {
        id
        loyalty {
          ...LoyaltyFragment
        }
      }
    }
  }
  ${LOYALTY_FRAGMENT}
`;

export const SHIFT_LOYALTY_CARD = gql`
  mutation shiftLoyaltyCard($input: ShiftLoyaltyCardInput!) {
    shiftLoyaltyCard(input: $input) {
      user {
        id
        loyalty {
          ...LoyaltyFragment
        }
      }
    }
  }
  ${LOYALTY_FRAGMENT}
`;

export const UPDATE_USER_SPECIAL_EVENT_POINTS = gql`
  mutation updateUserSpecialEventPoints($input: UpdateUserSpecialEventPointsInput!) {
    updateUserSpecialEventPoints(input: $input) {
      user {
        id
        currentSpecialEvent {
          meScoreDetails {
            type
            score
          }
        }
      }
    }
  }
`;

export const UPDATE_USER_INFLUENCER = gql`
  mutation updateUserInfluencer($input: UpdateUserInfluencerInput!) {
    updateUserInfluencer(input: $input) {
      user {
        ...UserViewerFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const SEND_RETENTION_REMINDER = gql`
  mutation sendRetentionReminder($input: SendRetentionReminderInput) {
    sendRetentionReminder(input: $input)
  }
`;

export const SEND_NOTIFICATION = gql`
  mutation sendNotification($input: SendNotificationInput!) {
    sendNotification(input: $input)
  }
`;

export const USER_TRANSACTIONS = gql`
  query userTransactions($userId: ID!, $startDate: String!, $endDate: String!) {
    analytics {
      userTransactions(userId: $userId, startDate: $startDate, endDate: $endDate)
    }
  }
`;

export const GET_MONTH_ID_LIST = gql`
  query getUserMonthIdList($input: GetUserMonthIdListInput!) {
    app {
      getUserMonthIdList(input: $input) {
        list
      }
    }
  }
`;

export const SHOW_MISSION_PROGRESS = gql`
  query getUserMonthlyPass($input: GetUserMonthlyPassInput!) {
    app {
      getUserMonthlyPass(input: $input) {
        userMonthlyPass {
          allMissions {
            id
            dailyMissions {
              id
              mission {
                id
                start
                end
                requiredTier
                tasks {
                  type
                  params {
                    __typename
                  }
                }
                rewardXp
              }
              progress
              progressGoal
              completedDate
              claimedDate
              claimedMultiplier
            }
            monthlyMissions {
              id
              mission {
                id
                start
                end
                requiredTier
                tasks {
                  type
                  params {
                    __typename
                  }
                }
                rewardXp
              }
              progress
              progressGoal
              completedDate
              claimedDate
              claimedMultiplier
            }
            extraMissions {
              id
              mission {
                id
                start
                end
                requiredTier
                tasks {
                  type
                  params {
                    __typename
                  }
                }
                rewardXp
              }
              progress
              progressGoal
              completedDate
              claimedDate
              claimedMultiplier
            }
          }
        }
      }
    }
  }
`;

export const RESET_MISSION_PROGRESS = gql`
  mutation resetMissionProgress($input: ResetMissionProgressInput!) {
    resetMissionProgress(input: $input) {
      user {
        currentUserMonthlyPass {
          missions {
            ...MonthlyPassMissionsFragment
          }
        }
      }
    }
  }
  ${MONTHLY_PASS_MISSIONS_FRAGMENT}
`;

export const GET_SP2_LINK_STATE = gql`
  query getLinkStateAdmin($input: Sp3IdInput!) {
    getLinkStateAdmin(input: $input) {
      state
      sp2id
    }
  }
`;

export const GET_SP2_LINK_STATE_ON_SP2 = gql`
  query getLinkStateBySp3IdFromSp2($input: Sp3IdInput!) {
    getLinkStateBySp3IdFromSp2(input: $input) {
      state
      sp2id
    }
  }
`;

export const LINK_TO_SP2_ID = gql`
  mutation linkToSp2Admin($input: Sp2LinkIdPairInput!) {
    linkToSp2Admin(input: $input) {
      error
      errorMessage
      success
      isPending
    }
  }
`;
export const UNLINK_ID_PAIR = gql`
  mutation unlinkIdPair($input: Sp2LinkIdPairInput!) {
    unlinkIdPair(input: $input) {
      error
      errorMessage
      success
      isPending
    }
  }
`;

export const GET_CURRENT_HURDLE_QUIZZES = gql`
  query currentHurdleQuizzes($input: CurrentHurdleQuizzesInput!) {
    currentHurdleQuizzes(input: $input) {
      id
      hasMore
      totalCount
      list {
        ...HurdleQuizFragment
      }
    }
  }
  ${HURDLE_QUIZ_FRAGMENT}
`;

export const RESET_USER_HURDLE_QUIZ = gql`
  mutation resetHurdleQuiz($input: ResetHurdleQuizInput!) {
    resetHurdleQuiz(input: $input) {
      hurdleQuiz {
        ...HurdleQuizFragment
      }
    }
  }
  ${HURDLE_QUIZ_FRAGMENT}
`;

export const UPDATE_USER_HURDLE_QUIZ = gql`
  mutation updateUserHurdleQuiz($input: UpdateUserHurdleQuizInput!) {
    updateUserHurdleQuiz(input: $input) {
      hurdleQuiz {
        ...HurdleQuizFragment
      }
    }
  }
  ${HURDLE_QUIZ_FRAGMENT}
`;

export const COMPLETE_USER_HURDLE_QUIZ = gql`
  mutation completeUserHurdleQuiz($input: CompleteUserHurdleQuizInput!) {
    completeUserHurdleQuiz(input: $input) {
      hurdleQuiz {
        ...HurdleQuizFragment
      }
    }
  }
  ${HURDLE_QUIZ_FRAGMENT}
`;

export const GET_USER_STREAK_HISTORY = gql`
  query streakHistory($input: StreakHistoryInput!) {
    streakHistory(input: $input) {
      currentStreak
      lostGames {
        hurdleQuizId
        previousStreak
        lostDate
        songTitle
        songArtist
      }
    }
  }
`;

export const GET_ACTIVE_USER_SUBSCRIPTION_UPDATE_LOGS = gql`
  query activeUserSubscriptionUpdateLogs($input: ActiveUserSubscriptionUpdateLogsInput!) {
    activeUserSubscriptionUpdateLogs(input: $input) {
      logs {
        userId
        logId
        store
        type
        sku
        environment
        originalTransactionId
        created
        expires
        purchased
        cancelled
        cancelledReason
        autoRenew
        retrying
        valid
        iosWebOrderLineItemId
      }
    }
  }
`;

export const APPLY_USER_SUBSCRIPTION_UPDATE_LOG = gql`
  mutation applyUserSubscriptionUpdateLog($input: ApplyUserSubscriptionUpdateLogInput!) {
    applyUserSubscriptionUpdateLog(input: $input) {
      user {
        ...UserViewerFragment
        isInfluencer
        influencerId
        testCredential
      }
    }
  }
  ${USER_FRAGMENT}
`;

const QUIZ_LIVE_SHOW_USER_FRAGMENT = gql`
  fragment QuizLiveShowUserFragment on QuizLiveShowUser {
    id
    diamondReward
    ghostQuestionId
    reviveQuestionId
    show {
      id
      startDate
      lastQuestionStartDate
      diamondPrize
      rounds {
        id
        questionDuration
        questions {
          id
        }
      }
      winners {
        id
        user {
          id
        }
      }
    }
  }
`;

export const GET_QUIZ_LIVE_SHOW_USER = gql`
  query quizLiveShowUser($userId: ID!, $playDate: Date!) {
    quizLiveShowUser(userId: $userId, playDate: $playDate) {
      ...QuizLiveShowUserFragment
    }
  }
  ${QUIZ_LIVE_SHOW_USER_FRAGMENT}
`;

export const REFUND_LIVE_SHOW = gql`
  mutation refundLiveShow($userId: ID!, $showId: ID!) {
    refundLiveShowUserBoosts(input: { userId: $userId, showId: $showId }) {
      user {
        id
      }
    }
  }
`;

// Year In Review section
export const YEAR_IN_REVIEW_FRAGMENT = gql`
  fragment YearInReviewFragment on YearInReview {
    id
    year
    seen
    available
  }
`;

export const GET_ALL_YEAR_IN_REVIEW = gql`
  query getAllYearInReview($userId: String!, $type: UserSearchType!) {
    user(property: $userId, type: $type) {
      getAllYearInReview {
        ...YearInReviewFragment
      }
    }
  }
  ${YEAR_IN_REVIEW_FRAGMENT}
`;

export const CHANGE_AVAILABLE_DATE_YIR = gql`
  mutation changeAvailableDateYIR($userId: ID!, $year: Int!, $date: Date!) {
    changeAvailableDateYearInReview(input: { userId: $userId, year: $year, date: $date }) {
      ...YearInReviewFragment
    }
  }
  ${YEAR_IN_REVIEW_FRAGMENT}
`;

export const GET_TEAM_HEAT_INFO = gql`
  query getTeamHeatInfo($property: String!, $type: UserSearchType!) {
    user(property: $property, type: $type) {
      id
      team {
        id
        availableBoosts
        boosts {
          type
          expireTimestamp
        }
        teamHeat {
          id
          currentScore
          lastUpdateScore
          lastUpdateScoreTimestamp
          maxHeatExpireTimestamp
          isMaxHeat
        }
        teamMonthlyPass {
          id
          teamHeatIteration
          availableTeamHeatMilestone
        }
      }
    }
  }
`;

export const UPDATE_TEAM_HEAT_INFO = gql`
  mutation updateTeamHeatInfo($input: TeamHeatUpdateInput!) {
    updateTeamHeat(input: $input) {
      teamHeat {
        id
        currentScore
        lastUpdateScore
        lastUpdateScoreTimestamp
        maxHeatExpireTimestamp
        isMaxHeat
      }
      teamMonthlyPass {
        id
        teamHeatIteration
        availableTeamHeatMilestone
      }
    }
  }
`;

export const UPDATE_TEAM_BOOSTS = gql`
  mutation updateTeamBoosts($input: UpdateTeamBoostsInput!) {
    updateTeamBoosts(input: $input) {
      team {
        id
        availableBoosts
        boosts {
          type
          expireTimestamp
        }
      }
    }
  }
`;

export const RESET_CLAIMED_TEAM_HEAT_MILESTONES = gql`
  mutation resetClaimedTeamHeatMilestones($input: ResetTeamHeatClaimedMilestonesInput!) {
    resetClaimedTeamHeatMilestones(input: $input) {
      userMonthlyPass {
        id
        claimedTeamHeatMilestone
      }
    }
  }
`;
