import { useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { QuizLiveShowQuery, SpecialEventQuery, SpecialEventsQuery } from '../../../../../../__gqltypes__';
import { GET_SPECIAL_EVENTS } from '../../SpecialEvent/graphql';
import { ADD_SPECIAL_EVENT_LIVE_SHOW, REMOVE_SPECIAL_EVENT_LIVE_SHOW } from '../graphql';

type AdminSpecialEvent = NonNullable<QuizLiveShowQuery['quizLiveShow']>['adminSpecialEvent'];
type SpecialEvent = NonNullable<SpecialEventQuery['specialEvent']>;
type Props = {
  quizLiveShowId: string;
  specialEvent: AdminSpecialEvent | null;
};

const SpecialEventSelector = ({ quizLiveShowId, specialEvent }: Props) => {
  const { data } = useQuery<SpecialEventsQuery>(GET_SPECIAL_EVENTS, {
    fetchPolicy: 'no-cache',
  });

  const [addSpecialEventQuizLiveShow, { loading: addSpecialEventLoading }] = useMutation(ADD_SPECIAL_EVENT_LIVE_SHOW, {
    onCompleted: ({ addSpecialEventQuizLiveShow: _data }) => {
      setCurrentSpecialData(_data.specialEvent);
    },
  });
  const [removeSpecialEventQuizLiveShow, { loading: removeSpecialEventLoading }] = useMutation(
    REMOVE_SPECIAL_EVENT_LIVE_SHOW,
    {
      onCompleted: () => {
        setCurrentSpecialData(null);
        selectSpecialEvent(null);
      },
    }
  );

  // useState
  const [currentSpecialEvent, setCurrentSpecialData] = useState<(SpecialEvent | AdminSpecialEvent) | null>(null);
  const [selectedSpecialEvent, selectSpecialEvent] = useState<string | null>(null);

  // useEffect
  useEffect(() => {
    if (!_.isNil(specialEvent)) {
      setCurrentSpecialData(specialEvent);
      selectSpecialEvent(specialEvent.id);
    } else {
      setCurrentSpecialData(null);
    }
  }, [specialEvent]);

  useEffect(() => {
    if (!_.isNil(data) && _.isNil(selectedSpecialEvent) && data.liveOps.specialEvents.list.length > 0) {
      selectSpecialEvent(data.liveOps.specialEvents.list[0].id);
    }
  }, [data, selectedSpecialEvent]);

  const onLink = () => {
    if (!_.isNil(selectedSpecialEvent)) {
      addSpecialEventQuizLiveShow({ variables: { input: { specialEventId: selectedSpecialEvent, quizLiveShowId } } });
    }
  };

  const onUnlink = () => {
    if (!_.isNil(selectedSpecialEvent)) {
      removeSpecialEventQuizLiveShow({
        variables: { input: { specialEventId: selectedSpecialEvent, quizLiveShowId } },
      });
    }
  };

  return (
    <Card className="mt-3 mb-3">
      <Card.Body className="text-center">
        <Card.Title>Special Events</Card.Title>
        {!currentSpecialEvent ? (
          data && data.liveOps.specialEvents && data.liveOps.specialEvents.list.length > 0 ? (
            <>
              <Form.Control
                value={selectedSpecialEvent ?? undefined}
                onChange={(e) => {
                  selectSpecialEvent(e.target.value ?? null);
                }}
                as="select"
                className="mt-3 mb-2"
              >
                {data.liveOps.specialEvents.list.map(({ name, id }) => (
                  <option key={id} value={id}>
                    {name} ({id})
                  </option>
                ))}
              </Form.Control>
              <Button disabled={addSpecialEventLoading} onClick={onLink}>
                Link
              </Button>
            </>
          ) : (
            <div>no special event available</div>
          )
        ) : (
          <>
            <div className="mb-3">
              current special event:{' '}
              <Link to={`/sp3/liveops/specialevent/${currentSpecialEvent.id}`}>
                {currentSpecialEvent.name} ({currentSpecialEvent.id})
              </Link>
            </div>
            <Button variant="danger" disabled={removeSpecialEventLoading} onClick={onUnlink}>
              Unlink
            </Button>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default SpecialEventSelector;
