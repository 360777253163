import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { MonitoringRequestNamesQuery, MonitoringRequestNamesQueryVariables } from '../../../../../../__gqltypes__';
import { MONITORING_ALL_REQUEST_NAMES } from '../graphql';
import usePlaygroundProps from '../hooks/usePlaygroundProps';
import buildUrl from '../logic/buildUrl';
import { InteractiveMonitoringPlaygroundOptions } from '../logic/formOptions';
import { ChoiceButtonGroup } from '../../../../../../utils/components/inputs/ChoiceButtonGroup';
import { ListOfNamesInput } from '../../../../../../utils/components/inputs/ListOfNamesInput';
import NumberInput from '../../../../../../utils/components/inputs/NumberInput';
import StartEndInput from '../../../../../../utils/components/inputs/StartEndInput';

interface InteractiveMonitoringSelectorProps {
  initialValues: InteractiveMonitoringPlaygroundOptions;
  expand: boolean;
}

export default function InteractiveMonitoringSelector({ initialValues, expand }: InteractiveMonitoringSelectorProps) {
  const [open, setOpen] = useState(expand);
  const { props, values } = usePlaygroundProps(initialValues);
  const { data: requestNames } = useQuery<MonitoringRequestNamesQuery, MonitoringRequestNamesQueryVariables>(
    MONITORING_ALL_REQUEST_NAMES,
    { variables: { input: { type: values.type } } }
  );

  const history = useHistory();

  const betaOrProd = process.env.REACT_APP_NAMESPACE === 'beta' || process.env.REACT_APP_NAMESPACE === 'default' || process.env.REACT_APP_NAMESPACE === 'prod';

  const handleSubmit = () => {
    history.push(`?${buildUrl(values)}`);
    setOpen(false);
  };

  return (
    <Card>
      <Card.Header className="d-flex p-1">
        <strong className="mt-auto mr-auto mb-auto ml-3"> Interactive Playground</strong>
        <Button onClick={() => setOpen((pOpen) => !pOpen)} className="px-4 border-0" variant="outline-primary">
          {open ? <div className="fas fa-search-minus" /> : <div className="fas fa-search-plus" />}
        </Button>
      </Card.Header>

      {open && (
        <Card.Body>
          {betaOrProd && <ChoiceButtonGroup title="Server env" {...props.env} />}

          <ChoiceButtonGroup title="Server mode" {...props.mode} />

          <ChoiceButtonGroup title="Request type" {...props.type} />

          <Row>
            <Col sm={12} md={6}>
              <ChoiceButtonGroup title="Request tracking" {...props.requestTracking} fill />
            </Col>
            <Col sm={12} md={6}>
              {values.requestTracking === 'SPECIFIC' && (
                <ListOfNamesInput
                  title="Request names"
                  autocompleteNames={requestNames?.monitoringRequestNames || []}
                  {...props.requestNames}
                />
              )}
              {values.requestTracking === 'MOST_IMPORTANT' && (
                <ChoiceButtonGroup title="Order by" {...props.sortMethod} fill />
              )}
              {values.requestTracking === 'MOST_IMPORTANT' && values.sortMethod !== 'ALL' && (
                <NumberInput title="Limit to" {...props.maxNumberOfRequests} />
              )}
              {values.requestTracking === 'MOST_IMPORTANT' && (
                <Form.Check
                  type="switch"
                  id="includeSubscriptions"
                  label="Include subscriptions"
                  className="mt-3"
                  {...props.includeSubscriptions}
                />
              )}
            </Col>
          </Row>

          <Row>
            <Col sm={12} md={6}>
              <ChoiceButtonGroup title="Time range" {...props.timeSelection} fill />
            </Col>
            <Col sm={12} md={6}>
              <ChoiceButtonGroup title="Aggregate by" {...props.timestamp} fill />
              {values.timeSelection === 'PREVIOUS' && (
                <ChoiceButtonGroup title="Period" {...props.maxNumberOfTimestamps} fill />
              )}
              {values.timeSelection === 'BETWEEN' && <StartEndInput {...props.startEnd} />}

              <Form.Check
                type="switch"
                id="compare"
                label="Compare with a previous period"
                className="mt-3"
                {...props.compare}
              />
              {values.compare}
              {values.compare && <NumberInput title="This many times back:" {...props.compareSkip} />}
            </Col>
          </Row>

          <Button disabled={false} onClick={handleSubmit}>
            Get the graph
          </Button>
        </Card.Body>
      )}
    </Card>
  );
}
