import { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, Col, Form, Row } from 'react-bootstrap';
import { ChainedOfferClaimType, ChainedOfferPackConfig, ItemAvailability, LevelRewardInput, LevelRewardInputType } from '../../../../../../__gqltypes__';
import { stringToEnum } from '../../SaleEvent/utils';
import Chip from '../../../components/Chip';
import EditRewardsModal from '../../../components/EditRewardsModal';
import { convertCOEPackRewardToLevelRewardInput } from '../utils';

type Props = {
  pack: ChainedOfferPackConfig;
  myIndex: number;
  packsSize: number;
  onUpdatePack: (updatedPack: ChainedOfferPackConfig, idx: number) => ChainedOfferPackConfig;
  onRemovePack: (index: number) => void;
  onMovePack: (idx: number, isUp: boolean) => void;
};

const chainOfferSkuPrefix = 'com.freshplanet.sp3.ios.special.offer.';
const chainOfferSkuCount = 14;
const chainOfferCosts = [ 0.99, 1.99, 2.99, 3.99, 4.99, 5.99, 6.99, 7.99, 8.99, 9.99, 12.99, 14.99, 17.99, 19.99];

export function ChainedOfferPack({pack, myIndex, packsSize, onUpdatePack, onRemovePack, onMovePack}: Props) {

  const [pack_, setPack] = useState<ChainedOfferPackConfig>(pack);
  const [showEditReward, setShowEditReward] = useState(false);
  const [selectedReward, setSelectedReward] = useState<LevelRewardInput | null>(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [rewardIndex, setRewardIndex] = useState(0);
  const isBuyClaimType =
    pack_.claimType === ChainedOfferClaimType.BUY ||
    pack_.claimType === ChainedOfferClaimType.PLUS_BUY;
  const checkIfNumber = (val: string): number | undefined => {
    return Number.isNaN(+val) ? undefined : +val;
  };

  useEffect(() => {
    setPack(pack);
  }, [pack]);

  useEffect(() => {
    onUpdatePack(pack_, myIndex);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pack_, myIndex]);

  const chainedOfferSkus: Array<string> = useMemo(() => {
    const res: Array<string> = [];
    for (let i = 1; i <= chainOfferSkuCount; ++i) {
      res.push(`${chainOfferSkuPrefix}chain${i}`);
    }
    return res;
  }, []);

  const handleAddReward = useCallback((newReward: LevelRewardInput) => {
    if (isEditMode) {
      const newPack = {...pack_};
      newPack.rewards[rewardIndex] = ({
        __typename: 'ChainedOfferPackRewardConfig',
        type: newReward.type.toString(),
        amount: newReward.amount,
        itemId: newReward.itemId ?? '',
      });
      onUpdatePack(newPack, myIndex);
    } else {
      const newPack = {...pack_};
      newPack.rewards.push({
        __typename: 'ChainedOfferPackRewardConfig',
        type: newReward.type.toString(),
        amount: newReward.amount,
        itemId: newReward.itemId ?? '',
      });
      onUpdatePack(newPack, myIndex);
    }
    setShowEditReward(false);
  }, [pack_, isEditMode, myIndex, onUpdatePack, rewardIndex]);

  const handleRemoveReward = useCallback((idx: number) => {
    if (idx >= 0 && idx < pack_.rewards.length) {
      const newPack = {...pack_};
      newPack.rewards = newPack.rewards.filter((val, index) => index !== idx);
      onUpdatePack(newPack, myIndex);
    }
  }, [pack_, myIndex, onUpdatePack]);

  const handleShowEditReward = useCallback((idx: number, isEdit: boolean) => {
    setIsEditMode(isEdit);
    if (isEdit) {
      const rew = pack_.rewards[idx];
      const levelReward: LevelRewardInput = {
        amount: rew.amount,
        type: Object.values(LevelRewardInputType)
        .find((val) => val.toString() === rew.type) ?? LevelRewardInputType.CURRENCY_COIN,
        itemId: rew.itemId,
      };
      setSelectedReward(levelReward);
    } else {
      setSelectedReward(null);
    }
    setRewardIndex(idx);
    setShowEditReward(true);
  }, [pack_]);

  return (
    <>
    <EditRewardsModal
      isEdit={isEditMode}
      tier={null}
      show={showEditReward}
      level={0}
      reward={selectedReward}
      handleSave={handleAddReward}
      handleClose={() => setShowEditReward(false)}
      disableCurrencySpecialEvent
      disableMonthlyBuff
      enableItemLevelEdit
      liveOpsAvailabilities={[ItemAvailability.EARN, ItemAvailability.EARN_EACH_LEVEL]}
    />
    <Row className="mb-6">
      <Col className="d-flex flex-column" style={{verticalAlign: 'middle'}} xs={1}>
        <Form.Label className="font-weight-bold">#{myIndex + 1}</Form.Label>
        {myIndex === 0 ? null : (
          <Button 
            size="sm" 
            variant="light" 
            onClick={() => onMovePack(myIndex, true)} 
            style={{borderWidth: 1, borderColor: 'rgba(127, 127, 127, 0.4)', marginBottom: 4}}>
            <i className="fas fa-angle-up" />
          </Button>
        )}
        {myIndex === packsSize - 1 ? null : (
          <Button 
            size="sm"
            variant="light"
            onClick={() => onMovePack(myIndex, false)}
            style={{borderWidth: 1, borderColor: 'rgba(127, 127, 127, 0.4)'}}>
            <i className="fas fa-angle-down" />
          </Button>
        )}
      </Col>
      <Col style={{verticalAlign: 'middle', width: '20%'}}>
        <Form.Label className="font-weight-bold">Cost</Form.Label>
        <Form.Control
          type="text"
          value={pack_.cost ?? 0}
          onChange={(e) => {
            const newCost = checkIfNumber(e.target.value) ?? 0;
            setPack((prev) => ({...prev, cost: newCost}));
          }}
          disabled={!isBuyClaimType}
          />
      </Col>
      <Col style={{verticalAlign: 'middle', width: '40%'}}>
        <Form.Label className="font-weight-bold">Sku</Form.Label>
        <Form.Control
          as="select"
          type="text"
          value={pack_.sku ? `${chainOfferSkuPrefix}${pack.sku}` : ''}
          onChange={(e) => {
            let newSku = e.target.value;
            const costText = newSku.match(/chain(\d+)/); 
            let newCost = 0;
            if (costText) {
              newSku = `${costText[0]}`;
              newCost = chainOfferCosts[Number(costText[1]) - 1];
            }
            setPack((prev) => ({...prev, sku: newSku, cost: newCost}));
          }}
          disabled={!isBuyClaimType}
          style={{width: 370}}
          >
            <option value='' />
            {chainedOfferSkus.map((sku, index) => (
              <option key={index} value={sku}>
                {sku}
              </option>
            ))}
          </Form.Control>
      </Col>
      <Col style={{verticalAlign: 'middle', width: '20%'}}>
        <Form.Label className="font-weight-bold">Claim Type</Form.Label>
        <Form.Control
          as="select"
          type="text"
          value={pack_.claimType}
          onChange={(e) => {
            const newPackProps = {claimType: e.target.value as ChainedOfferClaimType};
            if (newPackProps.claimType !== ChainedOfferClaimType.BUY && 
              newPackProps.claimType !== ChainedOfferClaimType.PLUS_BUY
            ) {
              Object.assign(newPackProps, {cost: 0, sku: ''});
            }
            setPack((prev) => ({...prev, ...newPackProps}));
          }}
          >
            {Object.keys(ChainedOfferClaimType).map((option) => (
              <option key={option} value={stringToEnum(option, ChainedOfferClaimType)}>
                {option}
              </option>
            ))}
          </Form.Control>
      </Col>
      <Col className="d-flex flex-column align-items-center">
          <Form.Label className="font-weight-bold">Add reward</Form.Label>
          <Button variant='success' onClick={() => handleShowEditReward(pack_.rewards.length, false)}>
            <i className='fas fa-plus'/>
          </Button>
        </Col>
      <Col className="d-flex flex-column align-items-center">
        <Form.Label className="font-weight-bold">Delete pack</Form.Label>
        <Button variant='danger' onClick={() => onRemovePack(myIndex)}>
          <i className='fas fa-trash'/>
        </Button>
      </Col>
    </Row>
    <Row className="mb-3">
      <Col className="d-flex flex-column align-items-center">
      <Form.Label className="font-weight-bold">Rewards</Form.Label>
        {pack_.rewards.map((reward, index) => {
            return (
              <Row key={index} className="mt-2 align-items-end">
                <Chip 
                  reward={convertCOEPackRewardToLevelRewardInput(reward)} 
                  canDelete 
                  btnAction={() => handleRemoveReward(index)} 
                  globalAction={() => handleShowEditReward(index, true)}/>
              </Row>
            );
          })}
        </Col>
      </Row>
    </>
  );
}