import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import {
  CurationContentPackQuery,
  CurationSongDatabaseSearchQuery,
  CurationSongDdexSearchQuery,
  ForceSongInputMatchingMutation,
} from '../../../../__gqltypes__';
import Loader from '../components/Loader';
import TooltipButton from '../components/TooltipButton';
import { SearchType } from '../screens/SongSearch/components/SearchForm';
import SongSearchContent from '../screens/SongSearch/components/SongSearchContent';
import mutations from '../utils/mutations';
import SongInputInfo from './PlaylistSongPopup/components/SongInputInfo';
import './PlaylistSongPopup/PlaylistSongPopup.css';
import useManualMatch from './PlaylistSongPopup/useManualMatch';
import { getFirstArtist } from './utils';

type CurationContentPack = CurationContentPackQuery['curationContentPack'];
type CurationSongInput = Extract<CurationContentPack['contentItems'][0], { __typename: 'CurationSongInput' }>;
type CurationSongDatabaseSearch = CurationSongDatabaseSearchQuery['curationSongDatabaseSearch'][0];
type CurationSongDDEXSearch = CurationSongDdexSearchQuery['curationSongDDEXSearch'][0];
type SuggestedSong = NonNullable<ForceSongInputMatchingMutation['forceSongInputMatching']['suggestedSong']>;

type Props = {
  show: boolean;
  songInput: CurationSongInput & { songInputIsrc?: string | null };
  contentPack: CurationContentPack;
  onHide: () => void;
  onSelectPrevious: () => void;
  onSelectNext: () => void;
};

const UnmatchedSongInfoPopup = ({ show, songInput, contentPack, onHide, onSelectPrevious, onSelectNext }: Props) => {
  const [removeSongFromPlaylist, { loading: removing }] = useMutation(mutations.REMOVE_SONG_FROM_PLAYLIST);
  const [songSearchState, setSongSearchState] = useState<null | 'SHOW' | 'SHOW_DDEX' | 'LOADING'>(null); // null | "SHOW" | "LOADING"
  const [suggestedReplacedSong, handleReplacingSong] = useManualMatch({
    songId: null,
    songInputId: songInput?.id,
    contentPack,
  });

  useEffect(() => {
    if (!show) {
      setSongSearchState(null);
    }
  }, [show]);

  useEffect(() => {
    if (show) {
      const downHandler = ({ key }: KeyboardEvent) => {
        if (key === 'ArrowLeft') {
          onSelectPrevious();
        } else if (key === 'ArrowRight') {
          onSelectNext();
        }
      };

      window.addEventListener('keydown', downHandler);

      return () => {
        window.removeEventListener('keydown', downHandler);
      };
    }
    return undefined;
  }, [onSelectNext, onSelectPrevious, show]);

  const handleRemoveSong = () => {
    if (
      window.confirm(`Are you sure you want to remove this song from ${contentPack.name}? There is no coming back.`)
    ) {
      removeSongFromPlaylist({ variables: { songInputId: songInput.id, id: contentPack.id } }).then(() => {
        onHide();
      });
    }
  };

  const showDDEXSongSearch = () => {
    setSongSearchState('SHOW_DDEX');
  };

  const onReplacedSongSelected = (
    replacingSong: SuggestedSong | CurationSongDDEXSearch | CurationSongDatabaseSearch,
    matchType: typeof SearchType[keyof typeof SearchType] | 'SP3'
  ) => {
    console.log('replacing', replacingSong, matchType);
    setSongSearchState('LOADING');
    handleReplacingSong(replacingSong.id, matchType).then((success) => {
      if (success) {
        setSongSearchState(null);
        onHide();
      } else {
        setSongSearchState('SHOW');
      }
    });
  };

  if (!songInput) return null;

  return (
    <Modal show={show} onHide={onHide} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="modal-title-buttons">
          <h3>
            <Button variant="link" onClick={onSelectPrevious}>
              <i className="fas fa-chevron-left" />
            </Button>{' '}
            Song Input Details{' '}
            <Button variant="link" onClick={onSelectNext}>
              <i className="fas fa-chevron-right" />
            </Button>
          </h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Col lg={12}>
          <SongInputInfo
            songInput={songInput}
            showDDEXSongSearch={showDDEXSongSearch}
            songSearchState={songSearchState}
          />

          {songSearchState && (
            <Card className="mt-4">
              <Card.Body>
                {songSearchState === 'LOADING' ? (
                  <Loader />
                ) : (
                  <SongSearchContent
                    defaultIsrc={songInput.songInputIsrc}
                    defaultTitle={songInput.title}
                    defaultArtist={getFirstArtist(songInput.artist)}
                    onSongSelected={onReplacedSongSelected}
                    songSearchState={songSearchState}
                    actionButton="Replace match"
                  />
                )}
                {suggestedReplacedSong && (
                  <Alert variant="warning" className="mb-2">
                    A song with the same iTunes ID is already in the database: Song ID {suggestedReplacedSong.id}
                    <br />
                    {suggestedReplacedSong.artist} - {suggestedReplacedSong.title}
                    <Button
                      variant="outline-success"
                      size="sm"
                      onClick={() => {
                        onReplacedSongSelected(suggestedReplacedSong, 'SP3');
                      }}
                    >
                      Replace match
                    </Button>
                  </Alert>
                )}
              </Card.Body>
            </Card>
          )}

          <TooltipButton
            variant="outline-danger"
            className="mt-2"
            onClick={handleRemoveSong}
            disabled={contentPack.state !== 'VALIDATING'}
            disabledReason="Playlist is not in state VALIDATING"
          >
            <i className="fas fa-trash mr-2" />
            {removing ? 'Removing...' : `Remove from playlist ${contentPack.name}`}
          </TooltipButton>
        </Col>
      </Modal.Body>
    </Modal>
  );
};

export default UnmatchedSongInfoPopup;
