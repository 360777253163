import {
  FullMonitoringBetweenQuery,
  FullMonitoringBetweenQueryVariables,
  FullMonitoringPreviousQuery,
  FullMonitoringPreviousQueryVariables,
  FullMonitoringSpecificRequestsBetweenQuery,
  FullMonitoringSpecificRequestsBetweenQueryVariables,
  FullMonitoringSpecificRequestsPreviousQuery,
  FullMonitoringSpecificRequestsPreviousQueryVariables,
  MonitoringBetweenQuery,
  MonitoringBetweenQueryVariables,
  MonitoringPreviousQuery,
  MonitoringPreviousQueryVariables,
  MonitoringSpecificRequestsBetweenQuery,
  MonitoringSpecificRequestsBetweenQueryVariables,
  MonitoringSpecificRequestsPreviousQuery,
  MonitoringSpecificRequestsPreviousQueryVariables,
} from '../../../../../../__gqltypes__';

export type MonitoringVariables =
  | MonitoringPreviousQueryVariables
  | MonitoringBetweenQueryVariables
  | MonitoringSpecificRequestsPreviousQueryVariables
  | MonitoringSpecificRequestsBetweenQueryVariables;

export type PossibleQuery =
  | MonitoringPreviousQuery
  | MonitoringBetweenQuery
  | MonitoringSpecificRequestsPreviousQuery
  | MonitoringSpecificRequestsBetweenQuery;

export type FullMonitoringVariables =
  | FullMonitoringPreviousQueryVariables
  | FullMonitoringBetweenQueryVariables
  | FullMonitoringSpecificRequestsPreviousQueryVariables
  | FullMonitoringSpecificRequestsBetweenQueryVariables;

export type PossibleFullQuery =
  | FullMonitoringPreviousQuery
  | FullMonitoringBetweenQuery
  | FullMonitoringSpecificRequestsPreviousQuery
  | FullMonitoringSpecificRequestsBetweenQuery;

export enum AdditionalMeasureName {
  P95,
  P99,
  P100,
}
