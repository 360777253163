import { useMutation } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { Button, Spinner } from 'react-bootstrap';
import {
  FlushRedisCacheMutation,
  FlushRedisCacheMutationVariables,
  RedisCacheModel,
} from '../../../../../__gqltypes__';
import { FLUSH_CACHE } from '../../../liveops/graphql';

type FlushButtonProps = {
  model: RedisCacheModel;
  label?: string;
};

export default function FlushButton({ model, label }: FlushButtonProps) {
  const [flushCache, { loading: flushCacheLoading }] = useMutation<
    FlushRedisCacheMutation,
    FlushRedisCacheMutationVariables
  >(FLUSH_CACHE, {
    variables: { input: { model } },
    onCompleted: (data) => {
      console.log(data);
      // Do not block the thread (just notify the admin)
      setTimeout(() => alert(`${data.flushRedisCache.flushedEntities} entities removed from the cache!`), 100);
    },
  });

  const handleFlushCache = () => {
    if (window.confirm(`Are you sure you want to flush the Redis cache for ${model}?`)) {
      flushCache();
    }
  };

  if (!label) {
    return (
      <>
        {flushCacheLoading ? (
          <Spinner animation="border" />
        ) : (
          <Button className="w-100" variant="danger" onClick={handleFlushCache}>
            <>
              <i className="fas fa-trash mr-2" />
              flush cache
            </>
          </Button>
        )}
      </>
    );
  }

  return (
    <div className="d-flex mt-2" style={{ width: 500 }}>
      <div className="my-auto">{label}:</div>
      <div style={{ flex: 1 }} />
      <div>
        {flushCacheLoading ? (
          <Spinner animation="border" />
        ) : (
          <Button className="w-100" variant="danger" onClick={handleFlushCache}>
            <>
              <i className="fas fa-trash mr-2" />
              flush cache
            </>
          </Button>
        )}
      </div>
    </div>
  );
}
