import { Controls, Player } from '@lottiefiles/react-lottie-player';
import React from 'react';
import Row from 'react-bootstrap/Row';

const ASSET_HEIGHT = 300;

export default function AssetDisplay({ assetUrl }: { assetUrl: string } & React.HTMLAttributes<HTMLDivElement>) {
  if (typeof assetUrl !== 'string') return null;

  if (assetUrl.endsWith('.png')) {
    return (
      <Row className="justify-content-center">
        <img
          src={assetUrl}
          alt={`Asset ${assetUrl}`}
          style={{ maxHeight: ASSET_HEIGHT, objectFit: 'contain', width: '100%' }}
        />
      </Row>
    );
  }

  if (assetUrl.endsWith('.json')) {
    return (
      <Row className="justify-content-center">
        <Player autoplay loop src={assetUrl} style={{ height: ASSET_HEIGHT }}>
          <Controls visible buttons={['play', 'repeat', 'frame', 'debug']} />
        </Player>
      </Row>
    );
  }

  return null;
}
