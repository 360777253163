import { useMutation } from '@apollo/client';
import { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Row from 'react-bootstrap/Row';
import { BadgeConfig, BadgeProgressTaskType, BadgeType, MetaFragmentType } from '../../../../../../__gqltypes__';
import { LoadingLogo } from '../../../../devtools/components/Modal';
import { UPLOAD_BADGE_ASSET } from '../../../components/apolloQueries';
import DragUpload from './DragUpload';
import { OnValidateItem } from './typedef';

type Props = {
  upgrade: any;
  itemData: BadgeConfig;
  setItemData: React.Dispatch<React.SetStateAction<BadgeConfig>>;
  onValidate?: OnValidateItem;
};

export default function UpgradeForm({ upgrade, itemData, setItemData, onValidate }: Props) {
  const [uploadAsset, { loading }] = useMutation(UPLOAD_BADGE_ASSET);

  const details = JSON.parse(itemData.details);

  let initialMetaType;
  if (itemData.type === BadgeType.CATEGORY) {
    initialMetaType = Object.entries(details.levelDetails[upgrade.level].metaCost).find((entry) => !!entry[1])?.[0];
  } else {
    initialMetaType = MetaFragmentType.BRONZE;
  }

  const metaFragments = ['BRONZE', 'SILVER', 'GOLD'];

  const [metaType, setMetaType] = useState(metaFragments.indexOf(initialMetaType ?? 'BRONZE'));

  const handleUpload = (file: Blob) => {
    uploadAsset({
      variables: {
        input: {
          file,
          id: itemData.id,
          level: upgrade.level,
        },
      },
    }).then(({ data }) => {
      details.levelDetails[upgrade.level].asset = data.uploadBadgeAssets.asset;
      setItemData({ ...itemData, details: JSON.stringify(details) });
    });
  };

  // @ts-ignore
  const handleAmountChange = (e) => {
    details.levelDetails[upgrade.level][itemData.type === BadgeType.CATEGORY ? 'fragmentsCost' : 'value'] =
      e.target.value;
    setItemData({ ...itemData, details: JSON.stringify(details) });
  };

  // @ts-ignore
  const handleMetaCostChange = (e) => {
    details.levelDetails[upgrade.level].metaCost[metaFragments[metaType]] = +(e.target.value ?? 0);
    if (metaType === 0) {
      details.levelDetails[upgrade.level].metaCost[metaFragments[1]] = 0;
      details.levelDetails[upgrade.level].metaCost[metaFragments[2]] = 0;
    } else if (metaType === 1) {
      details.levelDetails[upgrade.level].metaCost[metaFragments[2]] = 0;
      details.levelDetails[upgrade.level].metaCost[metaFragments[0]] = 0;
    } else {
      details.levelDetails[upgrade.level].metaCost[metaFragments[1]] = 0;
      details.levelDetails[upgrade.level].metaCost[metaFragments[0]] = 0;
    }
    setItemData({ ...itemData, details: JSON.stringify(details) });
  };

  // @ts-ignore
  const handleMetaTypeChange = (e) => {
    setMetaType(metaFragments.indexOf(e.target.value));
    handleMetaCostChange({ target: { value: 0 } });
  };

  return (
    <Form>
      <hr />
      <h5>
        Item level
        {upgrade.level}
      </h5>
      <LoadingLogo show={loading} />

      <DragUpload onUpload={handleUpload} onValidate={onValidate} />
      <Form.Group>
        <Form.Label>Asset</Form.Label>
        <Form.Control value={upgrade.asset} disabled />
      </Form.Group>
      <Row>
        <Col>
          <p>
            {itemData.type === BadgeType.CATEGORY
              ? 'Amount of fragments:'
              : details.progressTaskType === BadgeProgressTaskType.LEVEL
              ? 'Level:'
              : 'Days:'}
          </p>
        </Col>
        <Col md={10}>
          <Form.Group>
            <FormControl
              value={itemData.type === BadgeType.CATEGORY ? upgrade.fragmentsCost : upgrade.value}
              onChange={handleAmountChange}
            />
          </Form.Group>
        </Col>
      </Row>
      {itemData.type === BadgeType.CATEGORY && (
        <Row>
          <Col>
            <p>Meta fragments: </p>
          </Col>
          <Col md={10}>
            <Form.Group>
              <Form.Label>Type: </Form.Label>
              <Form.Control as="select" value={metaFragments[metaType]} onChange={handleMetaTypeChange}>
                {metaFragments.map((type) => (
                  <option value={type} key={type}>
                    {type}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Amount: </Form.Label>
              <FormControl
                value={details.levelDetails[upgrade.level].metaCost[metaFragments[metaType]]}
                onChange={handleMetaCostChange}
              />
            </Form.Group>
          </Col>
        </Row>
      )}
    </Form>
  );
}
