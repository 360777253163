import { RedisCacheModel } from '../../../../../__gqltypes__';
import { FLUSH_CACHE } from '../../../liveops/graphql';
import FlushButton from './FlushButton';

export default function Cache() {
  return (
    <div>
      <FlushButton label="Festivals (Special events)" model={RedisCacheModel.SPECIAL_EVENT} />
      <FlushButton label="A/B tests" model={RedisCacheModel.ABTEST} />
      <FlushButton label="Subscription utils" model={RedisCacheModel.SUBSCRIPTION_UTIL} />
      <FlushButton label="System events (chat)" model={RedisCacheModel.SYSTEM_EVENT} />
      <FlushButton label="Unread count (chat)" model={RedisCacheModel.UNREAD_COUNT} />
    </div>
  );
}
