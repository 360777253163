import { useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { Button, Card, Col, Form, ListGroup, Modal, ModalProps, Nav, Row, Spinner, Tab } from 'react-bootstrap';
import DateTimePicker from 'react-datetime';
import { applyChangeIfDateValid, validIfSameDayOrAfter } from '../../../../utils/datepicker';
import { Sp2ReleaseStatusQuery } from '../../../../__gqltypes__';
import mutations from '../utils/mutations';
import queries from '../utils/queries';

const canUseMinutes = process.env.REACT_APP_NAMESPACE === 'test';

type SP2ReleaseStatusPlaylist = Sp2ReleaseStatusQuery['sp2ReleaseStatus']['playlists'];
type SP2ReleaseStatusRelease = Sp2ReleaseStatusQuery['sp2ReleaseStatus']['releases'][0];
type AddModalProps = {
  playlists: SP2ReleaseStatusPlaylist;
  refetch: () => void;
} & ModalProps;

const AddReleaseModal = ({ show, onHide, playlists, refetch }: AddModalProps) => {
  const release: SP2ReleaseStatusRelease = {
    // @ts-ignore
    id: null,
    visibleDate: canUseMinutes ? moment().unix() : moment().add(1, 'hours').minutes(0).seconds(0).unix(),
    type: 'normal',
    state: 'UNRELEASED',
    playlistIds: [],
  };

  return (
    <Modal size="lg" show={show} onHide={onHide}>
      <Modal.Header closeButton>New Release</Modal.Header>
      <Modal.Body>
        <Card>
          <ReleaseData release={release} playlists={playlists} refetch={refetch} onHide={onHide} />
        </Card>
      </Modal.Body>
    </Modal>
  );
};

type Props = {
  release: SP2ReleaseStatusRelease;
  playlists: SP2ReleaseStatusPlaylist;
  refetch: () => void;
} & ModalProps;

const ReleaseData = ({ release, playlists, refetch, onHide }: Props) => {
  const [playlistIds, setPlaylistIds] = React.useState<string[]>(release.playlistIds);
  const [releaseType, setReleaseType] = React.useState(release.type);
  const [releaseDate, setReleaseDate] = React.useState<number | string | moment.Moment>(release.visibleDate * 1000);
  const [loadingSave, setLoadingSave] = React.useState(false);
  const [loadingDelete, setLoadingDelete] = React.useState(false);

  const [saveRelease] = useMutation(mutations.SAVE_SP2_RELEASE);
  const [deleteRelease] = useMutation(mutations.DELETE_SP2_RELEASE);

  React.useEffect(() => {
    setPlaylistIds(release.playlistIds);
    setReleaseType(release.type);
    setReleaseDate(release.visibleDate * 1000);
    setLoadingSave(false);
    setLoadingDelete(false);
  }, [release]);

  const availablePlaylists = React.useMemo(() => {
    let _availablePlaylists = [];
    if (releaseType === 'normal') {
      _availablePlaylists = playlists.filter((playlist) => !playlist.visible && !playlistIds.includes(playlist.id));
    } else {
      _availablePlaylists = playlists.filter((playlist) => playlist.visible && !playlistIds.includes(playlist.id));
    }

    return _.orderBy(
      _availablePlaylists,
      releaseType === 'normal' ? 'created' : 'name',
      releaseType === 'normal' ? 'desc' : 'asc'
    );
  }, [playlists, releaseType, playlistIds]);

  const handleAdd = (playlistId: string) => {
    setPlaylistIds((previousValue) => {
      return [...previousValue, playlistId];
    });
  };

  const handleRemove = (playlistId: string) => {
    setPlaylistIds((previousValue) => {
      return previousValue.filter((pId) => pId !== playlistId);
    });
  };

  const handleSave = () => {
    setLoadingSave(true);
    const variables = {
      input: {
        playlistIds,
        type: releaseType,
        // @ts-ignore
        releaseTime: Math.round(new Date(releaseDate).getTime() / 1000),
        id: release.id ?? undefined,
      },
    };
    saveRelease({
      variables,
    })
      .then(() => {
        if (!release.id) {
          refetch();
          setLoadingSave(false);
          if (onHide) {
            onHide();
          }
        }
      })
      .catch(() => {
        setLoadingSave(false);
      });
  };

  const handleDelete = () => {
    setLoadingDelete(true);
    deleteRelease({
      variables: { input: { id: release.id } },
    }).then(() => {
      refetch();
    });
  };

  const isAlreadyReleased = release.state === 'RELEASED';
  return (
    <>
      {!!release.id && <Card.Header>Release ID: {release.id}</Card.Header>}
      <Card.Body>
        <Row>
          <Col sm={2}>Release At</Col>
          <Col>
            <DateTimePicker
              disabled={!!isAlreadyReleased}
              // @ts-ignore
              onChange={applyChangeIfDateValid(setReleaseDate)}
              isValidDate={validIfSameDayOrAfter}
              // @ts-ignore
              initialValue={releaseDate}
              timeConstraints={canUseMinutes ? {} : { minutes: { min: 0, max: 0, step: 0 } }}
            />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col sm={2}>Playlists</Col>
          <Col>
            <ListGroup style={{ maxHeight: '30vh', overflowY: 'auto' }}>
              {playlistIds.map((playlistId: string) => {
                const playlist = playlists.find((p) => p.id === playlistId);
                if (!playlist) return null;
                return (
                  <ListGroup.Item key={playlist.id} className="d-flex justify-content-between">
                    <div>{playlist.name}</div>
                    {!isAlreadyReleased && (
                      <Button size="sm" variant="danger" onClick={() => handleRemove(playlist.id)}>
                        Remove
                      </Button>
                    )}
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          </Col>
        </Row>
        <Row className="my-4">
          <Col sm={2}>Type</Col>
          <Col>
            <Form.Control
              as="select"
              value={releaseType}
              disabled={isAlreadyReleased}
              onChange={(e) => setReleaseType(e.target.value)}
            >
              <option value="normal">Unreleased</option>
              <option value="rerelease">Rerelease</option>
            </Form.Control>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col sm={2}>Available Playlists</Col>
          <Col>
            <ListGroup style={{ maxHeight: '30vh', overflowY: 'auto' }}>
              {availablePlaylists.map((playlist) => {
                return (
                  <ListGroup.Item key={playlist.id} className="d-flex justify-content-between">
                    <div>{playlist.name}</div>
                    {!isAlreadyReleased && (
                      <Button size="sm" onClick={() => handleAdd(playlist.id)}>
                        Add
                      </Button>
                    )}
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          </Col>
        </Row>
        <Row className="mt-4 justify-content-between px-4 align-items-end">
          <Button
            size="lg"
            disabled={loadingSave || loadingDelete || isAlreadyReleased || playlistIds.length === 0}
            onClick={handleSave}
          >
            {loadingSave ? 'Saving...' : 'Save'}
          </Button>
          {!!release.id && (
            <div>
              <Button
                variant="danger"
                size="sm"
                className="ml-4"
                disabled={loadingSave || loadingDelete || isAlreadyReleased}
                onClick={handleDelete}
              >
                <i className="fas fa-trash mr-2" />
                {loadingDelete ? 'Deleting...' : 'Delete'}
              </Button>
            </div>
          )}
        </Row>
      </Card.Body>
    </>
  );
};

export default () => {
  const { loading, data, refetch } = useQuery<Sp2ReleaseStatusQuery>(queries.SP2_RELEASES);
  const [releaseSelected, setReleaseSelected] = React.useState(null);
  const [showAddModal, setShowAddModal] = React.useState(false);
  const { releases } = React.useMemo(() => {
    const _r = data?.sp2ReleaseStatus.releases ?? [];

    const _alreadyReleasedPlaylistIds = _r.reduce<string[]>((acc, value) => {
      return acc.concat(value.playlistIds);
    }, []);

    return { releases: _.orderBy(_r, 'visibleDate', 'desc'), alreadyReleasedPlaylistIds: _alreadyReleasedPlaylistIds };
  }, [data?.sp2ReleaseStatus.releases]);

  const playlists = data?.sp2ReleaseStatus.playlists ?? [];

  return (
    <>
      {/* @ts-ignore */}
      <Tab.Container className="h-100" activeKey={releaseSelected ?? undefined} onSelect={(e) => setReleaseSelected(e)}>
        <Row className="mb-5 h-100">
          <Col>
            <Nav.Item className="mb-3">
              {loading ? (
                <Spinner animation="border" />
              ) : (
                <>
                  <Button className="w-100" variant="success" onClick={() => setShowAddModal(true)}>
                    <i className="fas fa-calendar-day mr-2" />
                    Schedule a new release
                  </Button>
                </>
              )}
            </Nav.Item>
            <Nav variant="pills" style={{ maxHeight: '89vh', overflowY: 'auto' }}>
              {releases.map((release) => (
                <Nav.Item key={`${release.id}date`} className="w-100 position-relative">
                  <Nav.Link
                    eventKey={release.id}
                    className={`${
                      moment(release.visibleDate * 1000).isBefore(moment())
                        ? 'tournament-nav-link-old'
                        : 'tournament-nav-link-current'
                    }`}
                  >
                    <Row className="justify-content-between">
                      <div>{moment(release.visibleDate * 1000).format('DD/MM/YYYY LT')}</div>
                      <div>
                        {release.state === 'PARTIALLY_RELEASED' && (
                          <i className="fas fa-exclamation-triangle text-danger mr-1" />
                        )}
                        {release.state === 'RELEASED' && <i className="fas fa-check text-success mr-1" />}
                        {release.type === 'normal' && <i className="fas fa-star" />}
                        {release.type === 'rerelease' && <i className="fas fa-redo-alt" />}
                      </div>
                    </Row>
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Col>
          <Col className="col-10">
            <Tab.Content className="h-100">
              {!!releaseSelected && releases.find((r) => r.id === releaseSelected) && (
                <Card>
                  <ReleaseData
                    // @ts-ignore
                    release={releases.find((r) => r.id === releaseSelected)}
                    playlists={playlists}
                    refetch={refetch}
                  />
                </Card>
              )}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>

      <AddReleaseModal
        show={showAddModal}
        onHide={() => setShowAddModal(false)}
        playlists={playlists}
        refetch={refetch}
      />
    </>
  );
};
