import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import { useState } from 'react';
import { Alert } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import {
  CurationTaskStatus,
  GenerateSp2ReportingTableMutation,
  GenerateSp2ReportingTableMutationVariables,
  GenerateSppReportingTableMutation,
  GenerateSppReportingTableMutationVariables,
  ImportItunesDatabaseMutation,
  IngestStatusQuery,
} from '../../../../__gqltypes__';
import GenerateReportPopup from '../popups/GenerateReportPopup';
import mutations from '../utils/mutations';
import queries from '../utils/queries';

const PlaylistsSidebar = () => {
  const [showModal, setShowModal] = useState(false);
  const [showSPPModal, setShowSPPModal] = useState(false);

  const { data: ingestStatusData } = useQuery<IngestStatusQuery>(queries.INGEST_STATUS);
  const ingestStatus = ingestStatusData ? ingestStatusData.curation.ingestStatus : null;

  const [importDatabase, { loading: importing }] = useMutation<ImportItunesDatabaseMutation>(
    mutations.IMPORT_ITUNES_DATABASE
  );
  const [generateReport, { loading: generating }] = useMutation<
    GenerateSp2ReportingTableMutation,
    GenerateSp2ReportingTableMutationVariables
  >(mutations.GENERATE_REPORT);
  const [generateSPPReport, { loading: generatingSPP }] = useMutation<
    GenerateSppReportingTableMutation,
    GenerateSppReportingTableMutationVariables
  >(mutations.GENERATE_SPP_REPORT);

  const insertAtPosition = (text: string, pos: number, item: string) =>
    `${text.slice(0, pos)}${item}${text.slice(pos)}`;

  const onImport = () => {
    importDatabase();
  };

  const onGenerateReport = (month: number, year: number, appId: string) => {
    const input = appId === 'ALL' ? { month, year } : { month, year, appId };
    generateReport({ variables: { input } });
  };

  const onGenerateSPPReport = (month: number, year: number, appId: string) => {
    const input = { month, year, appId };
    generateSPPReport({ variables: { input } });
  };

  let version = null;
  if (ingestStatus) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    let versionDate = insertAtPosition(ingestStatus.resultUrl, 4, '/');
    versionDate = insertAtPosition(versionDate, 7, '/');
    version = `iTunes version: ${versionDate}`;
  }

  return (
    <>
      <Card>
        <Card.Header>SongPop 3</Card.Header>
        <Card.Body className="px-4">
          <Row className="d-flex justify-content-between align-items-center">
            <div>
              {version}
              <Badge className="m-1" pill variant="info">
                {ingestStatus ? ingestStatus.status.toLowerCase() : 'processing'}
              </Badge>
              {ingestStatus?.status === CurationTaskStatus.FAILED && (
                <Alert variant="warning">{ingestStatus.errorMessage}</Alert>
              )}
            </div>
            <div>
              <Button disabled={importing} onClick={onImport}>
                <i className="fas fa-file-import mr-2" />
                {importing ? 'Importing' : 'Import Itunes DB'}
              </Button>
            </div>
          </Row>
          <hr />
          <Row className="d-flex mt-2">
            <Button variant="success" disabled={generating} onClick={() => setShowModal(true)}>
              <i className="fas fa-file-csv mr-2" />
              Generate Reporting for Labels (SP2 way)
            </Button>
          </Row>
          <hr />
          <Row className="d-flex mt-2 flex-column">
            <Button variant="success" disabled={generatingSPP} onClick={() => setShowSPPModal(true)}>
              <i className="fas fa-file-csv mr-2" />
              Generate Reporting for Labels (Japan Only)
            </Button>
            <div className="pl-2">
              <a
                href={`https://storage.googleapis.com/songpop3-catalog/reports/${moment()
                  .subtract(1, 'month')
                  .format('YYYY-MM')}-SPP-JP.csv`}
                target="blank"
              >
                Link to exported report
              </a>
            </div>
          </Row>
        </Card.Body>
        <GenerateReportPopup
          appIds={['ALL', 'SP3', 'SPP']}
          show={showModal}
          onHide={() => setShowModal(false)}
          onGenerateReport={onGenerateReport}
        />
        <GenerateReportPopup
          show={showSPPModal}
          appIds={['SPP']}
          onHide={() => setShowSPPModal(false)}
          onGenerateReport={onGenerateSPPReport}
        />
      </Card>
    </>
  );
};

export default PlaylistsSidebar;
