import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import {
  CheckMusicReportStatusQuery,
  CheckMusicReportStatusQueryVariables,
  GenerateCombinedMusicReportMutation,
  GenerateCombinedMusicReportMutationVariables,
  GenerateSp2ReportingTableMutation,
  GenerateSp2ReportingTableMutationVariables,
  GenerateSppReportingTableMutation,
  GenerateSppReportingTableMutationVariables,
  MusicReport,
} from '../../../../../__gqltypes__';
import mutations from '../../utils/mutations';
import queries from '../../utils/queries';

const isTestEnv = process.env.REACT_APP_NAMESPACE === 'test';
const gsRootUrl = isTestEnv ? 'songpop-test/labelReport' : 'songpop-internal';

type Props = {
  monthId: number;
  yearId: number;
};

type ComponentProps = {
  combinedStatus: MusicReport | null;
  status: CheckMusicReportStatusQuery['checkMusicReportStatus'] | null | undefined;
  monthId: number;
  yearId: number;
};

function getStatus(report: MusicReport | null): string {
  if (!report) {
    return 'not created';
  }
  if (report.completed) {
    return 'done ✅';
  }
  // if more than 2 days, we mark the status as failed
  const now = moment();
  if (now.diff(moment(report.created)) > 24 * 3600 * 2 * 1000) {
    return 'failed ❌';
  }
  return 'in progress ⌛';
}

function getStorageUrl(report: MusicReport, subfolder: string): string {
  return `https://storage.googleapis.com/songpop3-catalog/reports/${report.formattedDate}/${subfolder}/report-${report.formattedDate}.csv`;
}

const MusicExportStatus = ({ combinedStatus, status, monthId, yearId }: ComponentProps) => {
  const [generateReport, { loading: generating }] = useMutation<
    GenerateSp2ReportingTableMutation,
    GenerateSp2ReportingTableMutationVariables
  >(mutations.GENERATE_REPORT);
  const [generateSPPReport, { loading: generatingSPP }] = useMutation<
    GenerateSppReportingTableMutation,
    GenerateSppReportingTableMutationVariables
  >(mutations.GENERATE_SPP_REPORT);
  const [generateCombinedReport, { loading: generatingCombinedReport }] = useMutation<
    GenerateCombinedMusicReportMutation,
    GenerateCombinedMusicReportMutationVariables
  >(mutations.GENERATE_REPORT_V2);

  const onGenerateReport = (month: number, year: number, appId: string) => {
    const input = appId === 'ALL' ? { month, year } : { month, year, appId };
    generateReport({ variables: { input } });
  };

  const onGenerateSPPReport = (month: number, year: number, appId: string) => {
    const input = { month, year, appId, country: 'JP' };
    return generateSPPReport({ variables: { input } });
  };

  const onGenerateCombinedReport = (month: number, year: number) => {
    const input = { month, year };
    generateCombinedReport({ variables: { input } });
  };

  const startSPPReport = () => {
    return onGenerateSPPReport(monthId, yearId, 'SPP');
  };

  const startSPReport = () => {
    return onGenerateReport(monthId, yearId, 'ALL');
  };

  const startCombinedReport = () => {
    return onGenerateCombinedReport(monthId, yearId);
  };

  return (
    <>
      <Form className="mb-5">
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            BatchId
          </Form.Label>
          <Col sm="2">
            <Form.Control plaintext readOnly defaultValue={`${monthId}-${yearId}`} />
          </Col>
        </Form.Group>
        <hr />

        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            SPP JP Export
          </Form.Label>
          <Col sm="2">
            <Form.Control plaintext readOnly defaultValue={status?.exportStatusSPPJP ? 'created ✅' : 'not created'} />
          </Col>
          <Col>
            {status?.exportStatusSPPJP ? (
              <div>
                You can access the export{' '}
                <a href={status?.exportStatusSPPJP} target="blank">
                  here
                </a>
              </div>
            ) : (
              <>
                <Button onClick={startSPPReport} disabled={generatingSPP}>
                  {generatingSPP ? `Generating SPP JP` : `Generate SPP JP`}
                </Button>
                <span className="ml-4">Estimated duration: &lt; 1min</span>
              </>
            )}
          </Col>
        </Form.Group>
        <hr />
        <div className="rounded p-2" style={{ backgroundColor: '#eee' }}>
          <h3>Legacy V1</h3>

          <Form.Group as={Row}>
            <Form.Label column sm={2}>
              Step 1 - SP Export Status
            </Form.Label>
            <Col sm="2">
              <Form.Control plaintext readOnly defaultValue={status?.exportStatusSP ? 'created ✅' : 'not created'} />
            </Col>
            <Col>
              {status?.exportStatusSP ? (
                <div>
                  Table &nbsp;
                  <code>{status?.exportStatusSP}</code>
                  &nbsp; has been created.
                </div>
              ) : (
                <>
                  <Button onClick={startSPReport} disabled={generating}>
                    {generating ? `Generating SP` : `Generate SP`}
                  </Button>
                  <span className="ml-4">Estimated duration: &lt; 5min</span>
                </>
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={2}>
              Step 2 - SPC + SP Export Status
            </Form.Label>
            <Col sm="2">
              <Form.Control plaintext readOnly defaultValue={status?.exportStatusAll ? 'created ✅' : 'not created'} />
            </Col>
            <Col>
              {!status?.exportStatusSP ? (
                <div>Please Generate the report for labels (SP) first</div>
              ) : status?.exportStatusAll ? (
                <div>
                  Table &nbsp;
                  <code>{status?.exportStatusSP}</code>
                  &nbsp; has been created.
                  <br />
                  Report is ready to be post-processed
                </div>
              ) : (
                <div>
                  <Button
                    variant="success"
                    href="https://admin.freshplanet.com/app/SongPop/prod/app/admin/reporting/music/"
                    target="blank"
                  >
                    <i className="fas fa-file-csv mr-2" />
                    Generate Combined Reporting for Labels (SPC+SP)
                  </Button>
                  <span className="ml-4">Estimated duration: ~ 2h</span>
                </div>
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={2}>
              Step 3 - Post Process
            </Form.Label>
            <Col sm="2">
              <Form.Control plaintext readOnly defaultValue={status?.exportPostProcess ? 'done ✅' : 'not created'} />
            </Col>
            <Col>
              {status?.exportPostProcess ? (
                <div>
                  You can access the export{' '}
                  <a href={status?.exportPostProcess} target="blank">
                    here
                  </a>
                </div>
              ) : status?.exportStatusAll ? (
                <div>
                  Please go to
                  <a href="http://macmini-jks.freshplanet.com:8080/view/Backend/job/Music%20Reporting/" target="blank">
                    &nbsp;Music Reporting Job&nbsp;
                  </a>
                  and use the gs url:
                  <br />
                  <code>
                    gs://{gsRootUrl}/{status.exportStatusAll.split('.')[2]}
                    .csv.gz
                  </code>
                  <br />
                  <span className="ml-4">Estimated duration: 15 mins</span>
                </div>
              ) : (
                <div>&nbsp;</div>
              )}
            </Col>
          </Form.Group>
        </div>

        <hr />
        <h3>V2</h3>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Combined Export
          </Form.Label>
          <Col sm="2">
            <Form.Control plaintext readOnly defaultValue={getStatus(combinedStatus)} />
          </Col>
          <Col>
            {combinedStatus?.completed ? (
              <div>
                You can access the export{' '}
                <a href={getStorageUrl(combinedStatus, 'Whitelisted')} target="blank">
                  here
                </a>
                .
                <br />
                And the blacklisted report{' '}
                <a href={getStorageUrl(combinedStatus, 'Blacklisted')} target="blank">
                  here
                </a>
                .
              </div>
            ) : combinedStatus != null ? (
              <>
                ReportId: {combinedStatus.id}
                <br />
                Started: {moment(combinedStatus.created).format('YYYY-MM-DD LT')}
                <br />
                <span>Estimated duration: &lt; 5hours</span>
                <br />
                {getStatus(combinedStatus) === 'failed ❌' && (
                  <Button onClick={startCombinedReport} disabled={generatingCombinedReport}>
                    {generatingCombinedReport ? `Generating Combined Report` : `Generate Combined Report`}
                  </Button>
                )}
              </>
            ) : (
              <>
                <Button onClick={startCombinedReport} disabled={generatingCombinedReport}>
                  {generatingCombinedReport ? `Generating Combined Report` : `Generate Combined Report`}
                </Button>
                <span className="ml-4">Estimated duration: &lt; 5hours</span>
              </>
            )}
          </Col>
        </Form.Group>
      </Form>
    </>
  );
};

const MusicReportingBatch = ({ monthId, yearId }: Props) => {
  const { data, loading, refetch } = useQuery<CheckMusicReportStatusQuery, CheckMusicReportStatusQueryVariables>(
    queries.CHECK_MUSIC_REPORT,
    {
      variables: { month: monthId, year: yearId },
      fetchPolicy: 'network-only',
    }
  );

  const {
    data: combinedData,
    loading: combinedLoading,
    refetch: refetchLoading,
  } = useQuery(queries.GET_COMBINED_REPORT_BY_DATE, {
    variables: { month: monthId, year: yearId },
    fetchPolicy: 'network-only',
  });

  const refresh = () => {
    refetch();
    refetchLoading();
  };

  return (
    <Card className="cp-list">
      <Card.Body>
        {loading || combinedLoading || (!data && !combinedData) ? (
          <div className="p-3">
            <Spinner animation="border" />
          </div>
        ) : (
          <>
            <MusicExportStatus
              combinedStatus={combinedData?.combinedMusicReportStatusByDate.report}
              status={data?.checkMusicReportStatus}
              monthId={monthId}
              yearId={yearId}
            />
            <Button variant="secondary" onClick={refresh}>
              Refresh
            </Button>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default MusicReportingBatch;
