import React from 'react';
import DashboardContainer from '../../../../../../utils/dashboards/components/DashboardContainer';
import { DefaultSlicing } from '../../../../../../utils/dashboards/components/slicers';
import LineChart from '../../../../../../utils/dashboards/components/widgets/charts/LineChart';
import WidgetContainer from '../../../../../../utils/dashboards/components/widgets/WidgetContainer';
import Processing from '../../../../../../utils/dashboards/models/Processing';

const queries = {
  iapRevenueByProductID: { fillZeroes: true },
  coinsEarnedPerDAUByType: { fillZeroes: true },
  coinsSpentPerDAUByType: { fillZeroes: true },
  diamondEarnedPerDAUByType: { fillZeroes: true },
  diamondsSpentPerDAUByType: { fillZeroes: true },
  stickersEarnedPerDAUByType: { fillZeroes: true },
  profileFramesEarnedPerDAUByType: { fillZeroes: true },
  profileFramesLevelEarnedPerDAUByType: { fillZeroes: true },
  vinylsEarnedPerDAUByType: { fillZeroes: true },
  vinylsLevelEarnedPerDAUByType: { fillZeroes: true },
  ticketsEarnedPerDAUByType: { fillZeroes: true },
  ticketsSpentPerDAUByType: { fillZeroes: true },
  economyBalancePerDAU: { fillZeroes: true },
};

const Economy = () => (
  <DashboardContainer queries={queries} id="economy" title="Economy">
    <DefaultSlicing id="DefaultSlicing" />
    <WidgetContainer id="WidgetContainer">
      <LineChart
        id="iapRevenueByProductID"
        title="IAP Revenue by Product ID"
        processing={Processing.pivot('iapRevenueByProductID', 'product_id', 'revenue', 'date')}
      />
      <LineChart
        id="coinsEarnedPerDAUByType"
        title="Coins Earned per DAU by Type"
        processing={Processing.pivot('coinsEarnedPerDAUByType', 'type', 'coins_earned_per_dau', 'date')}
      />
      <LineChart
        id="coinsSpentPerDAUByType"
        title="Coins Spent per DAU by Type"
        processing={Processing.pivot('coinsSpentPerDAUByType', 'type', 'coins_spent_per_dau', 'date')}
      />
      <LineChart
        id="diamondEarnedPerDAUByType"
        title="Diamonds Earned per DAU by Type"
        processing={Processing.pivot('diamondEarnedPerDAUByType', 'type', 'diamonds_earned_per_dau', 'date')}
      />
      <LineChart
        id="diamondsSpentPerDAUByType"
        title="Diamonds Spent per DAU by Type"
        processing={Processing.pivot('diamondsSpentPerDAUByType', 'type', 'diamonds_spent_per_dau', 'date')}
      />
      <LineChart
        id="ticketsEarnedPerDAUByType"
        title="Tickets Earned per DAU by Type"
        processing={Processing.pivot('ticketsEarnedPerDAUByType', 'type', 'tickets_earned_per_dau', 'date')}
      />
      <LineChart
        id="ticketsSpentPerDAUByType"
        title="Tickets Spent per DAU by Type"
        processing={Processing.pivot('ticketsSpentPerDAUByType', 'type', 'tickets_spent_per_dau', 'date')}
      />
      <LineChart
        id="stickersEarnedPerDAUByType"
        title="Stickers Earned per DAU by Type"
        processing={Processing.pivot('stickersEarnedPerDAUByType', 'type', 'stickers_earned_per_dau', 'date')}
      />
      <LineChart
        id="profileFramesEarnedPerDAUByType"
        title="Profile Frames Earned per DAU by Type"
        processing={Processing.pivot('profileFramesEarnedPerDAUByType', 'type', 'profileFrames_earned_per_dau', 'date')}
      />
      <LineChart
        id="profileFramesLevelEarnedPerDAUByType"
        title="Profile Frames Level Earned per DAU by Type"
        processing={Processing.pivot(
          'profileFramesLevelEarnedPerDAUByType',
          'type',
          'profileFrames_level_earned_per_dau',
          'date'
        )}
      />
      <LineChart
        id="vinylsEarnedPerDAUByType"
        title="Vinyls Earned per DAU by Type"
        processing={Processing.pivot('vinylsEarnedPerDAUByType', 'type', 'vinyls_earned_per_dau', 'date')}
      />
      <LineChart
        id="vinylsLevelEarnedPerDAUByType"
        title="Vinyls Level Earned per DAU by Type"
        processing={Processing.pivot('vinylsLevelEarnedPerDAUByType', 'type', 'vinyls_level_earned_per_dau', 'date')}
      />
      <LineChart id="economyBalancePerDAU" title="Coin and Diamond Balance at the end of the day per DAU" />
    </WidgetContainer>
  </DashboardContainer>
);

export default Economy;
