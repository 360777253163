// Values for the icons
export const IconTypes = {
  isBackend: 'backend',
  isCrash: 'crash',
  isIOS: 'iOS',
  isAndroid: 'Android',
  isWindows: 'windows',
  isApollo: 'apollo',
};

// Maps the event with his display name and param to display
export const logsParamsMap = {
  fp_session_start: {
    display: 'Session Start',
    mainParam: 'session_id',
  },
  user_set: {
    display: 'User Set',
    mainParam: 'user_id',
  },
  fatal_error: {
    display: 'Error (fatal)',
    mainParam: 'message',
  },
  screen_change: {
    display: 'Screen Navigation',
    mainParam: 'page_name',
  },
  apollo_operation_sent: {
    display: 'Apollo Request Start',
    mainParam: 'name',
  },
  apollo_operation_received: {
    display: 'Apollo Request Arrived',
    mainParam: 'name',
  },
  apollo_operation_answered: {
    display: 'Apollo Request Answered',
    mainParam: 'name',
  },
  apollo_operation_response: {
    display: 'Apollo Request Response',
    mainParam: 'name',
  },
  apollo_operation: {
    display: 'Apollo Request',
    mainParam: 'name',
  },
  apollo_operation_failed: {
    display: 'Apollo Request Failed',
    mainParam: 'message',
  },
  connection_start: {
    display: 'User Connection',
    mainParam: 'user_id',
  },
  connection_stop: {
    display: 'Connection Stopped',
    mainParam: 'source',
  },
  user_data_loaded: {
    display: 'User Data Loaded',
    mainParam: 'user_id',
  },
  focus_in: {
    display: 'User Focused In',
    mainParam: 'source',
  },
  focus_out: {
    display: 'User Focused Out',
    mainParam: 'last_page',
  },
  account_log_in: {
    display: 'Account Log In',
    mainParam: 'credentialType',
  },
  account_deleted: {
    display: 'Account Deleted',
    mainParam: 'user_id',
  },
  connection_cut: {
    display: 'Connection Cut',
    mainParam: 'time_since_start',
  },
  account_log_out: {
    display: 'Account Log Out',
  },
  connection_succeed: {
    display: 'Connection Succeed',
    mainParam: 'try_count',
  },
  crash: {
    display: 'App Crashed',
    mainParam: 'issue_title',
  },
  ab_test_info: {
    display: 'AB Test Received',
    mainParam: 'testId',
    secondaryParam: 'variation',
  },
  round_starting: {
    display: 'Round Starting',
    mainParam: 'type',
  },
  round_loading: {
    display: 'Round Loading',
    mainParam: 'type',
  },
  economic_transaction: {
    display: 'Economic Transaction',
    mainParam: 'transaction_name',
  },
  playlist_ready_for_upgrade: {
    display: 'Playlist Ready for Upgrade',
    mainParam: 'playlistId',
  },
  app_install_test_started: {
    display: 'AppInstall AB Test',
    mainParam: 'testId',
    secondaryParam: 'variation',
  },
  search_music: {
    display: 'Search Music',
    mainParam: 'item_searched',
  },
  signup_music_selected: {
    display: 'FTUE Music Selected',
    mainParam: 'music_genre_id',
  },
  signup_playlist_selected: {
    display: 'FTUE Playlist Selected',
    mainParam: 'playlist_name',
  },
  account_created: {
    display: 'New Account Created',
    mainParam: 'credential_type',
  },
  screen_action: {
    display: 'Screen Action',
    mainParam: 'page_name',
    secondaryParam: 'action',
  },
};
