import { useMutation } from '@apollo/client';
import Image from 'react-bootstrap/Image';
import Media from 'react-bootstrap/Media';
import Dropzone from 'react-dropzone';
import { CurationContentPackQuery } from '../../../../../../__gqltypes__';
import Loader from '../../../components/Loader';
import mutations from '../../../utils/mutations';
import './PlaylistPicture.css';

type Props = {
  curationContentPack: CurationContentPackQuery['curationContentPack'];
};

export default function PlaylistPicture({ curationContentPack }: Props) {
  const disabled = curationContentPack.state !== 'VALIDATING';
  const [updateCurationContentPackPicture, { loading }] = useMutation(mutations.UPDATE_CURATION_CONTENT_PACK_PICTURE);

  const onDrop = (files: Blob[]) => {
    if (disabled) {
      alert('Only validating playlists can be updated. Please use the Update Button');
      return;
    }

    if (files.length > 0) {
      updateCurationContentPackPicture({
        variables: {
          input: {
            id: curationContentPack.id,
            file: files[0],
          },
        },
      });
    }
  };

  return (
    <Media className="mb-2">
      <Dropzone onDrop={onDrop} disabled={disabled || loading}>
        {({ getRootProps, getInputProps, isDragActive }) =>
          loading ? (
            <div className="w-100 justify-content-center">
              <Loader />
            </div>
          ) : (
            <div {...getRootProps()} className={`position-relative droping ${isDragActive ? 'droping--isActive' : ''}`}>
              <input {...getInputProps()} />
              <Image className="w-100" src={curationContentPack.picture} thumbnail />
              <div className="edit-img-container">
                <div className="edit-img">
                  <i className="fas fa-pen" />
                </div>
              </div>
            </div>
          )
        }
      </Dropzone>
    </Media>
  );
}
