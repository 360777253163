import { useMutation } from '@apollo/client';
import clsx from 'classnames';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Form, ProgressBar } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Media from 'react-bootstrap/Media';
import Table from 'react-bootstrap/Table';
import {
  CurationSongDatabaseSearchQuery,
  RemoveSongsFromCommittedContentPackMutation,
  RemoveSongsFromCommittedContentPackMutationVariables,
} from '../../../../../../__gqltypes__';
import ExplicitIcon from '../../../components/ExplicitIcon';
import QuestionableIcon from '../../../components/QuestionableIcon';
import mutations from '../../../utils/mutations';
import { ClipboardButton } from './ClipboardButton';
import './ItunesSongList.css';
import useSortReducer from './useSortReducer';

const IS_TEST = process.env.REACT_APP_NAMESPACE === 'test';

type CurationSongInput = CurationSongDatabaseSearchQuery['curationSongDatabaseSearch'][0];
type Props = {
  songInputs: CurationSongDatabaseSearchQuery['curationSongDatabaseSearch'];
};

export default function CurationSongList({ songInputs }: Props) {
  const { orderedSongs, orderBy, ascending, changeOrder, handleChangeOrder } = useSortReducer<CurationSongInput>(true);

  const [deleteProgress, setDeleteProgress] = useState(1);
  const [deleteProgressLabel, setDeleteProgressLabel] = useState('');

  const [selectedForRemoval, setSelectedForRemoval] = useState<string[]>([]);
  const [removeSongsFromCommittedPlaylist] = useMutation<
    RemoveSongsFromCommittedContentPackMutation,
    RemoveSongsFromCommittedContentPackMutationVariables
  >(mutations.REMOVE_SONGS_FROM_COMMITTEDPLAYLIST);

  useEffect(() => {
    changeOrder({ type: 'RESET', payload: songInputs });
  }, [changeOrder, songInputs]);

  const removeAllSongsFromAllPlaylists = useCallback(async () => {
    const songInputIds = selectedForRemoval.length > 0 ? selectedForRemoval : orderedSongs.map(({ id }) => id);
    const playlists = _.flatMap(
      orderedSongs
        .filter(({ id }) => songInputIds.includes(id))
        .map(({ curationContentPacks }) => curationContentPacks.map(({ id, name }) => ({ id, name })))
    );
    const playlistsNameById = _.keyBy(playlists, 'id');

    const playlistIds = _.uniq(playlists.map((p) => p.id));
    const shouldContinue = window.confirm(`Remove ${songInputIds.length} songs in ${playlistIds.length} playlists?`);
    if (!shouldContinue) {
      return;
    }
    let progress = 0;
    const totalProgress = playlistIds.length;
    for (const ccpId of playlistIds) {
      setDeleteProgress(progress / totalProgress);
      setDeleteProgressLabel(`Deleting songs in playlist ${playlistsNameById[ccpId].name}`);
      await removeSongsFromCommittedPlaylist({ variables: { songInputIds, id: ccpId, updateCommittedPack: true } });
      progress += 1;
    }
    setDeleteProgress(1);
    setDeleteProgressLabel('');
    setSelectedForRemoval([]);
  }, [orderedSongs, removeSongsFromCommittedPlaylist, selectedForRemoval]);

  const toggleSelection = (songInputId: string) => {
    setSelectedForRemoval((prevValues) => {
      if (prevValues.includes(songInputId)) {
        return prevValues.filter((sId) => sId !== songInputId);
      }
      return [...prevValues, songInputId];
    });
  };

  return (
    <>
      <hr />
      <div className="d-flex align-items-center mb-2 flex-row-reverse">
        <Button
          variant="danger"
          className="ml-4"
          disabled={!IS_TEST && deleteProgress !== 1}
          onClick={removeAllSongsFromAllPlaylists}
        >
          <i className="fas fa-minus-circle mr-2" />
          {selectedForRemoval.length > 0 ? 'Remove Selected' : 'Remove All'}
        </Button>
        {deleteProgress !== 1 && (
          <div className="w-25">
            <span>{deleteProgressLabel}</span>
            <ProgressBar animated now={deleteProgress * 100} />
          </div>
        )}
      </div>
      <Table className="itunes" striped bordered size="sm">
        <thead>
          <tr>
            <th>Song Input ID/ISRC/Song ID</th>
            <th
              className={clsx('sortable', {
                sorted_asc: orderBy === 'TITLE' && !ascending,
                sorted_desc: orderBy === 'TITLE' && ascending,
                desc: !ascending,
                asc: !!ascending,
              })}
              onClick={() => handleChangeOrder('TITLE')}
            >
              Title
            </th>
            <th
              className={clsx('sortable', { sorted: orderBy === 'ARTIST', desc: !ascending, asc: !!ascending })}
              onClick={() => handleChangeOrder('ARTIST')}
            >
              Artist
            </th>
            <th
              className={clsx('sortable', { sorted: orderBy === 'ALBUM', desc: !ascending, asc: !!ascending })}
              onClick={() => handleChangeOrder('ALBUM')}
            >
              Album
            </th>
            <th
              className={clsx('sortable', { sorted: orderBy === 'LABEL', desc: !ascending, asc: !!ascending })}
              onClick={() => handleChangeOrder('LABEL')}
            >
              Label - SubLabel
            </th>
            <th
              className={clsx('sortable', { sorted: orderBy === 'WHITELISTED', desc: !ascending, asc: !!ascending })}
              onClick={() => handleChangeOrder('WHITELISTED')}
            >
              Whitelisted
            </th>
            <th>Input</th>
            <th>Playlists</th>
          </tr>
        </thead>
        <tbody>
          {orderedSongs.map(
            ({ curationSong: song, curationContentPacks, contentPacks, id, title, album, artist, isrc }) =>
              !song ? null : (
                <tr key={id}>
                  <td>
                    <span className="text-xs">{id}</span>
                    <hr />
                    <span className="text-xs">{isrc}</span>
                    <hr />
                    <span className="text-xs">{song.id}</span>
                  </td>
                  <td>
                    {song.title} <ExplicitIcon explicit={song.isExplicit} />
                    <QuestionableIcon questionable={song.questionableStatus || null} />
                  </td>
                  <td>{song.artist}</td>
                  <td>
                    <Media>
                      <img width={100} height={100} src={song.picture} alt={song.album} className="mr-2" />
                      <Media.Body>
                        <div>
                          {song.album}
                          <ClipboardButton title="Copy Artwork Url" textToCopy={song.picture} />
                        </div>
                      </Media.Body>
                    </Media>
                  </td>
                  <td className="itunes-label">
                    <span className="text-xs">
                      {song.label}
                      {song.subLabel && ` - ${song.subLabel}`}
                    </span>
                  </td>
                  <td>
                    {song.state === 'WHITELISTED' ? (
                      <Badge pill variant="success">
                        yes
                      </Badge>
                    ) : (
                      <Badge pill variant="danger">
                        blacklisted
                      </Badge>
                    )}
                  </td>
                  <td>
                    <span className="text-xs">{title}</span>
                    <br />
                    <span className="text-xs">{album}</span>
                    <br />
                    <span className="text-xs">{artist}</span>
                    <br />
                    <span className="text-xs">{isrc}</span>
                  </td>
                  <td>
                    {curationContentPacks.map((pack) => (
                      <div key={pack.id}>
                        <a href={`/sp3/playlist/${pack.originId}`}>{pack.name}</a>
                        {(contentPacks ?? []).find((cp) => cp.id === pack.originId && pack.state === 'COMMITTED') && (
                          <i className="fas fa-check text-success" />
                        )}
                      </div>
                    ))}
                    {curationContentPacks.length > 0 && (
                      <>
                        <hr />
                        <Form.Group controlId={`formBasicCheckbox-${id}`}>
                          <Form.Check type="checkbox" label="Select For Removal" onClick={() => toggleSelection(id)} />
                        </Form.Group>
                      </>
                    )}
                  </td>
                </tr>
              )
          )}
        </tbody>
      </Table>
    </>
  );
}
