import _ from 'lodash';

const getItemBaseId = (id: string) => id.split('.')[0];

const getItemCurrentLevel = (id: string) => {
  if (!id.includes('.')) return 0;
  return parseInt(id.split('.')[1], 10) ?? 0;
};

const getAvailableItems = (items: any[]) =>
  _.filter(items, ({ liveOpsAvailability }) => liveOpsAvailability !== 'UNAVAILABLE');

export const formatUserItems = (userItems: any[], items: any[]) => {
  const itemsById = _.groupBy(items, 'id');

  return userItems.map(({ id }) => {
    const item = itemsById[getItemBaseId(id)][0];
    return {
      ...item,
      level: getItemCurrentLevel(id) + 1,
    };
  });
};

export const getItemsNotOwnedByUser = (userItems: any[], items: any[]) =>
  _.differenceBy(getAvailableItems(items), userItems, 'id');
