import { useQuery } from '@apollo/client';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useRouteMatch } from 'react-router-dom';
import { LoadingLogo } from '../../components/Modal';
import { GET_SESSION_LOGS, GET_USER_FROM_SESSION } from '../../graphql';
import { InfoDisplay } from './components/InfoDisplay';
import { SessionOverview } from './components/SessionOverview';
import LogsTable from './LogsTable';
import { extractUniqueUsersIds, groupApolloEvents, idToTimestamp } from './models';

export const SessionView = () => {
  const match = useRouteMatch();
  const sessionID = match.params.id;
  const [processedLogs, setProcessedLogs] = useState(null);
  const [errors, setErrors] = useState(null);
  const [isSessionError, setIsSessionError] = useState(false);

  const { data, loading } = useQuery(GET_SESSION_LOGS, {
    fetchPolicy: 'no-cache',
    variables: { sessionID, sessionDate: moment(idToTimestamp(sessionID)) },
  });
  const { data: args } = useQuery(GET_USER_FROM_SESSION, {
    variables: { property: sessionID, type: 'BY_SESSION' },
    fetchPolicy: 'no-cache',
    onError: () => setIsSessionError(true),
  });

  useEffect(() => {
    if (data && data.analytics.appLogsBySession) {
      const prLogs = [];
      const _errors = [];
      ['frontendLogs', 'backendLogs', 'crashLogs'].forEach((key) => {
        const { data: logsData, error } = data.analytics.appLogsBySession[key];
        logsData.forEach((log) => {
          prLogs.push(JSON.parse(log));
        });
        if (error) {
          _errors.push(JSON.parse(error));
        }
      });
      setProcessedLogs(groupApolloEvents(prLogs));
      setErrors(_errors);
    }
  }, [data]);

  const { infoData, overviewData } = useMemo(() => {
    const _infoData = [];
    const _overviewData = {};
    let appInstall = {};
    let device = {};
    if (processedLogs && processedLogs.length > 0) {
      const sessionInfos = processedLogs.find((log) => log.event_name === 'fp_session_start');
      if (sessionInfos && sessionInfos.event_params && args && args.user) {
        const params = sessionInfos.event_params;
        appInstall = { ...params };
        device = {
          idfa: params.idfa,
          manufacturer: params.manufacturer,
          os_name: params.os_name,
          os_version: params.os_version,
          device_model: params.device_model,
          device_id: params.device_id,
        };
        Object.keys(device).forEach((key) => delete appInstall[key]);
        const {
          id,
          displayName,
          picture,
          subscriptionTier,
          phoneCredential,
          emailCredential,
          facebookCredential,
          appleCredential,
          googleCredential,
          userLevel,
          userLevelProgressToNextLevel,
          coinBalance,
          diamondBalance,
          energySystems: {
            tournamentTicket: { extraBalance: tournamentTicketBalance },
          },
          basicTierContentPack: {
            contentPack: { name },
          },
          ABTestList,
        } = args.user;
        const account = {
          userId: id,
          displayName,
          picture,
          subscriptionTier,
          phoneCredential,
          emailCredential,
          facebookCredential,
          appleCredential,
          googleCredential,
        };
        const userInfoData = {
          account,
          user: {
            userLevel,
            userLevelProgressToNextLevel,
            coinBalance,
            diamondBalance,
            tournamentTicketBalance,
            basicTierContentPack: name,
          },
          abtests: ABTestList.map(({ id: abtestid, variation }) => ({ [abtestid]: variation })),
        };
        _infoData.push({
          title: 'Players',
          params: userInfoData ? [userInfoData] : [{ error: 'No user' }],
        });
        _infoData.push({ title: 'App Install', params: appInstall, color: 'warning' });
        _infoData.push({ title: 'Device', params: device, color: 'success' });
        _overviewData.sessionDate = params.session_startDate;
      }
      _overviewData.debugNumber = processedLogs.filter((log) => log.event_name.includes('debug')).length;
      _overviewData.frontNumber = data.analytics.appLogsBySession.frontendLogs.data.length - _overviewData.debugNumber;
      _overviewData.backNumber = data.analytics.appLogsBySession.backendLogs.data.length;
      _overviewData.crashNumber = data.analytics.appLogsBySession.crashLogs.data.length;
      _overviewData.userNumber = extractUniqueUsersIds(processedLogs).length;
    }
    return { infoData: _infoData, overviewData: _overviewData };
  }, [processedLogs, args]);

  return (
    <>
      <LoadingLogo show={loading} />
      {data && data.analytics.appLogsBySession && (
        <Row className="mb-4">
          <Col>
            <SessionOverview
              backNumber={overviewData.backNumber}
              frontNumber={overviewData.frontNumber}
              debugNumber={overviewData.debugNumber}
              crashNumber={overviewData.crashNumber}
              userNumber={overviewData.userNumber}
              sessionDate={overviewData.sessionDate}
              errors={errors || []}
            />
          </Col>
        </Row>
      )}
      {isSessionError && (
        <>
          <Alert className="mb-1" variant="danger">
            The session data hasn&apos;t been exported yet or there is no user for this session
          </Alert>
          <br />
        </>
      )}
      <Row>
        <Col sm="8">
          {processedLogs ? (
            <LogsTable processedLogs={processedLogs} hasFilters />
          ) : loading ? null : (
            <p>No logs found</p>
          )}
        </Col>
        <InfoDisplay data={infoData} />
      </Row>
    </>
  );
};
