import { useApolloClient, useQuery } from '@apollo/client';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Button, Card, Col, Form, InputGroup, Row, Spinner } from 'react-bootstrap';
import { GetContentPackListQuery, GetContentPackListQueryVariables } from '../../../../../__gqltypes__';
import mutations from '../../utils/mutations';
import queries from '../../utils/queries';
import './ContentPackList.css';
import ContentPackListEditor from './ContentPackListEditor';
import DeleteContentPackList from './DeleteContentPackList';

type EditableFieldProps = {
  name: string;
  value: string;
  id?: string;
  update?: (id: string | undefined, fieldValue: string) => Promise<void>;
  removeButton?: JSX.Element;
};

const EditableField = ({ name, value, id, update, removeButton }: EditableFieldProps) => {
  const [state, setState] = useState('show');
  const fieldRef = useRef<HTMLInputElement>(null);
  const [fieldValue, setFieldValue] = useState<string>('');

  const startEditing = useCallback(() => {
    setState('edit');
    setFieldValue(value);
    if (fieldRef.current) {
      fieldRef.current.focus();
    }
  }, [setState, setFieldValue, value]);
  const onChange = useCallback(
    (e) => {
      setFieldValue(e.target.value);
    },
    [setFieldValue]
  );
  const finishEditing = useCallback(async () => {
    if (fieldValue !== value) {
      setState('wait');
      if (update) {
        await update(id, fieldValue);
      }
    }
    setState('show');
  }, [setState, update, id, value, fieldValue]);
  const onKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        finishEditing();
      }
    },
    [finishEditing]
  );

  return (
    <Form.Group as={Row} className="mb-1">
      <Form.Label column md={3}>
        {name}
      </Form.Label>
      <Col>
        <InputGroup>
          {state === 'show' ? (
            <Form.Control readOnly plaintext className="padded-plaintext-control" value={value} ref={fieldRef} />
          ) : (
            <Form.Control
              disabled={state === 'wait'}
              onChange={onChange}
              onKeyDown={onKeyDown}
              value={fieldValue}
              ref={fieldRef}
            />
          )}
          {update && state !== 'show' && (
            <InputGroup.Append>
              <Button variant="primary" disabled={state === 'wait'} onClick={finishEditing} className="square-icon">
                {state === 'edit' ? <i className="fas fa-check" /> : <Spinner as="span" animation="border" size="sm" />}
              </Button>
            </InputGroup.Append>
          )}
          {update && state === 'show' && (
            <Button variant="secondary" onClick={startEditing} className="square-icon">
              <i className="fas fa-edit" />
            </Button>
          )}
          {removeButton}
        </InputGroup>
      </Col>
    </Form.Group>
  );
};

type ContentPackListProps = {
  listId: string;
  refetchLists: (value?: any) => Promise<any>;
};

const ContentPackList = ({ listId, refetchLists }: ContentPackListProps) => {
  const { data, loading } = useQuery<GetContentPackListQuery, GetContentPackListQueryVariables>(
    queries.GET_CONTENT_PACK_LIST,
    { variables: { id: listId } }
  );
  const list = useMemo(() => data?.contentPackList, [data]);
  const client = useApolloClient();

  const update = useCallback(
    async (field, value) => {
      try {
        await client.mutate({
          mutation: mutations.UPDATE_CONTENT_PACK_LIST,
          variables: {
            input: {
              contentPackListId: listId,
              [field]: value,
            },
          },
        });
      } catch (err) {
        console.error(err);
        return;
      }
      if (field === 'name') {
        await refetchLists();
      }
    },
    [client, listId, refetchLists]
  );

  const updatePackIds = useCallback(
    async (contentPackIds) => {
      await client.mutate({
        mutation: mutations.UPDATE_CONTENT_PACK_LIST,
        variables: {
          input: {
            contentPackListId: listId,
            contentPackIds,
          },
        },
      });
    },
    [client, listId]
  );

  const removeButton = <DeleteContentPackList listId={listId} listName={list?.name} refetchLists={refetchLists} />;

  return (
    <Card className="cp-list">
      <Card.Body>
        {loading ? (
          <div className="p-3">
            <Spinner animation="border" />
          </div>
        ) : (
          <>
            <Form className="mb-5">
              <EditableField name="ID" value={listId} removeButton={removeButton} />
              <EditableField name="Name" value={list?.name ?? ''} id="name" update={update} />
              <EditableField name="Description" value={list?.description ?? ''} id="description" update={update} />
            </Form>
            <ContentPackListEditor id={listId} contentPacks={list?.contentPacks ?? []} updatePackIds={updatePackIds} />
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default ContentPackList;
