import _ from 'lodash';
import { Button, ButtonGroup, ButtonToolbar } from 'react-bootstrap';

export interface ChoiceButtonGroupProps<T extends string> {
  title: string;
  choice: T;
  disabled?: boolean;
  disabledChoices?: Partial<Record<T, boolean>>;
  choices: Partial<Record<T, { label: string; group?: number }>>;
  fill?: boolean;
  setChoice: React.Dispatch<React.SetStateAction<T>>;
}

export function ChoiceButtonGroup<T extends string>({
  title,
  choices,
  choice,
  disabled,
  disabledChoices,
  fill = false,
  setChoice,
}: ChoiceButtonGroupProps<T>) {
  const buttonGroups = _.groupBy(
    Object.entries(choices) as [T, { label: string; group?: number }][],
    ([, value]) => value?.group || 0
  );

  return (
    <>
      <p className="mt-3 mb-2">{title}</p>

      <ButtonToolbar className={fill ? 'w-100' : ''}>
        {Object.entries(buttonGroups).map(([key, choicesOfGroup]) => (
          <ButtonGroup key={key} className="m-1">
            {choicesOfGroup
              .filter(([, value]) => value !== undefined)
              .map(([k, { label }]) => (
                <Button
                  key={k}
                  variant={choice === k ? 'primary' : 'outline-primary'}
                  onClick={() => setChoice(k)}
                  disabled={disabled || disabledChoices?.[k]}  
                >
                  {label}
                </Button>
              ))}
          </ButtonGroup>
        ))}
      </ButtonToolbar>
    </>
  );
}
