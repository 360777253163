import { useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { Button, Card, Col, Nav, Row, Tab } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import {
  CreateAbTestMutation,
  CreateAbTestMutationVariables,
  DeleteAbTestMutation,
  DeleteAbTestMutationVariables,
  GetAbTestsConfigsQuery,
  RedisCacheModel,
  UpdateAbTestMutation,
  UpdateAbTestMutationVariables,
  CreateAbTestInput,
  DeleteAbTestInput,
  UpdateAbTestInput,
} from '../../../../../__gqltypes__';
import { LoadingLogo } from '../../../devtools/components/Modal';
import FlushButton from '../../../devtools/screens/Cache/FlushButton';
import { ABTestData } from './components/ABTestData';
import AddABtestModal from './components/AddABTestModal';
import { CREATE_ABTEST, DELETE_ABTEST, GET_ABTESTS_CONFIGS, UPDATE_ABTEST } from './graphql';

const baseURL = '/sp3/liveops/abtests';
type AbTestConfig = GetAbTestsConfigsQuery['liveOps']['ABTestConfigs'][0];

function ABTests() {
  /**
   * GETTING ENV DATA
   */
  const { id: abtestId } = useParams<{ id: string }>();
  const history = useHistory();

  /**
   * GRAPHQL
   */
  // ABTests Fetching logic
  const {
    data: abtestsData,
    loading: fetchingLoading,
    refetch,
  } = useQuery<GetAbTestsConfigsQuery>(GET_ABTESTS_CONFIGS, {
    fetchPolicy: 'no-cache',
  });

  const [createABTest, { loading: createLoading }] = useMutation<CreateAbTestMutation, CreateAbTestMutationVariables>(
    CREATE_ABTEST
  );
  const [updateABTest, { loading: updateLoading }] = useMutation<UpdateAbTestMutation, UpdateAbTestMutationVariables>(
    UPDATE_ABTEST
  );
  const [deleteABTest, { loading: deleteLoading }] = useMutation<DeleteAbTestMutation, DeleteAbTestMutationVariables>(
    DELETE_ABTEST
  );

  const loading = fetchingLoading || createLoading || updateLoading || deleteLoading;

  /**
   * STATE
   */
  const [abtests, setABTests] = React.useState<AbTestConfig[]>([]);
  const [showAddModal, setShowAddModal] = React.useState(false);

  const selectABTest = useCallback(
    (newABTestId) => {
      history.push(`${baseURL}/${newABTestId}`);
    },
    [history]
  );

  const isABTestSelected = () => !_.isEmpty(abtestId) && abtests.find(({ id }) => id === abtestId);

  // Saving queried ab tests
  useEffect(() => {
    if (abtestsData) {
      setABTests(abtestsData.liveOps.ABTestConfigs);
    }
  }, [abtestsData]);

  // Preselecting Ab test if user lands on this page for the first time
  useEffect(() => {
    if (abtestId === ':id') {
      selectABTest('');
    }
    if (!isABTestSelected() && abtests.length > 0) {
      selectABTest(abtests[0].id);
    }
  }, [isABTestSelected, selectABTest, abtestId, abtests]);

  const handleUpdateABTest = (abtestInput: UpdateAbTestInput) => {
    updateABTest({ variables: { input: abtestInput } })
      // @ts-ignore
      .then(({ data: { updateABTest } }) => {
        const newABTest = updateABTest.abTest;
        refetch();
        selectABTest(newABTest.id);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleDeleteABTest = (abtestInput: DeleteAbTestInput) => {
    deleteABTest({ variables: { input: abtestInput } })
      // @ts-ignore
      .then(() => {
        refetch();
        selectABTest(':id');
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleAddABTest = (abtestInput: CreateAbTestInput) => {
    setShowAddModal(false);
    createABTest({ variables: { input: abtestInput } })
      // @ts-ignore
      .then(async ({ data: { createABTest } }) => {
        const newABTest = createABTest.abTest;
        await refetch();
        selectABTest(newABTest.id);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <LoadingLogo show={loading} />

      {isABTestSelected() && (
        // @ts-ignore
        <Tab.Container className="h-100" activeKey={abtestId} onSelect={selectABTest}>
          <Row className="mb-5 h-100">
            <Col className="col-3">
              <Nav.Item className="mb-3">
                <>
                  <Button className="w-100" variant="success" onClick={() => setShowAddModal(true)}>
                    <i className="fas fa-plus mr-2" />
                    create AB Test
                  </Button>
                </>
              </Nav.Item>
              <Nav.Item className="mb-3">
                <FlushButton model={RedisCacheModel.ABTEST} />
              </Nav.Item>
              <Nav variant="pills">
                {abtests.map((abtest) => (
                  <Nav.Item key={`${abtest.id}id`} className="w-100 position-relative">
                    <Nav.Link
                      eventKey={abtest.id}
                      className={`justify-content-start text-break  ${
                        abtest.isHardCoded ? 'liveops-nav-link-old' : ''
                      }`}
                    >
                      {abtest.id}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Col>

            <Col className="col-9">
              <Tab.Content className="h-100">
                <Card>
                  <Card.Body>
                    <ABTestData
                      // @ts-ignore
                      abtest={abtests.find((abtest) => abtest.id === abtestId)}
                      onUpdate={handleUpdateABTest}
                      onDelete={handleDeleteABTest}
                      loading={loading}
                    />
                  </Card.Body>
                </Card>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      )}

      <AddABtestModal
        show={(!loading && abtests.length === 0) || showAddModal}
        loading={createLoading}
        onHide={() => setShowAddModal(false)}
        onAdd={handleAddABTest}
      />
    </>
  );
}

export default ABTests;
