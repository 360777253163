import {
  CurrencyBoostPack,
  CurrencyBoostType,
  CurrencyCoinPack,
  CurrencyDiamondPack,
} from '../../../../../__gqltypes__';

export type TargetedProduct = {
  id: string;
  amount?: number;
  saleConfigs: (
    | CurrencyDiamondPack
    | CurrencyCoinPack
    | CurrencyBoostPack
    | { id: string; amount: number; offerPercentage: number }
  )[];
  boostType?: CurrencyBoostType;
};
export type AppSalesConfig = {
  saleConfigs: (
    | CurrencyDiamondPack
    | CurrencyCoinPack
    | CurrencyBoostPack
    | { id: string; amount: number; offerPercentage: number }
  )[];
  targetedProducts: TargetedProduct[];
};

export enum SaleType {
  DIAMOND = 'DIAMOND',
  COIN = 'COIN',
  BOOST = 'BOOST',
  FRAME = 'FRAME',
  VINYL = 'VINYL',
  STICKER = 'STICKER',
}
