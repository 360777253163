import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import { UpdateUserRoleInput, UserRole } from '../../../../__gqltypes__';
import { KEY_TO_NAME } from '../../../../layout/config';
/**
 * Modal to update user app role
 * Accept emails and userId as input
 */
type Props = {
  searchString: string;
  appId?: string;
  role?: UserRole;
  onHide: () => void;
  updateRole: (role: UpdateUserRoleInput) => Promise<void>;
};

export default function UpdateAppRoleModal({ searchString, appId, role, onHide, updateRole }: Props) {
  const [loading, setLoading] = useState(false);

  const handleSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log('event', event);

    const { searchString: _searchString, role: _role } = {
      searchString: event.target.searchString.value,
      role: event.target.role.value,
    };

    const input = _searchString.includes('@')
      ? { email: _searchString, role: _role }
      : { id: _searchString, role: _role };
    setLoading(true);

    return updateRole(input)
      .then(() => onHide())
      .catch((error) => {
        alert(error.toString()); // eslint-disable-line
      });
  };

  return (
    <Modal.Body>
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Row>
            <Col>
              <Form.Control name="searchString" placeholder="Email/UserId" defaultValue={searchString} />
            </Col>
            <Col>
              <Form.Control name="appId" required disabled as="select" defaultValue={appId || KEY_TO_NAME.SP3}>
                {Object.entries(KEY_TO_NAME).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </Form.Control>
            </Col>
            <Col>
              <Form.Control name="role" required as="select" defaultValue={role || UserRole.USER}>
                {Object.entries(UserRole).map(([key, value]) => (
                  <option key={key} value={value}>
                    {value}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Form.Row>
        </Form.Group>

        <Button type="submit" disabled={loading}>
          {loading ? 'Updating...' : 'Add/Update'}
        </Button>
      </Form>
    </Modal.Body>
  );
}
