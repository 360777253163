import { useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Badge, Button, Card, Form, Spinner } from 'react-bootstrap';
import Media from 'react-bootstrap/Media';
import {
  CommitSp2CurationPlaylistMutation,
  CommitSp2CurationPlaylistMutationVariables,
  CurationContentPackQuery,
  CurationContentPackState,
  ExportCurationContentPackToSp2Mutation,
  ExportCurationContentPackToSp2MutationVariables,
  Sp2CurationPlaylistQuery,
  UpdateCurationContentPackPriceToSp2Mutation,
  UpdateCurationContentPackPriceToSp2MutationVariables,
} from '../../../../../../__gqltypes__';
import EditComponent from '../../../components/EditComponent';
import TooltipButton from '../../../components/TooltipButton';
import mutations from '../../../utils/mutations';
import queries from '../../../utils/queries';
import SP2BadgeFields from './SP2BadgeFields';
import SP2PlaylistPicture from './SP2PlaylistPicture';

const SP2_HOST =
  process.env.REACT_APP_NAMESPACE === 'test'
    ? 'https://wam-test.appspot.com'
    : 'https://stage-dot-wam-mobile.appspot.com';

const SP2_PLAYLIST_URL = `${SP2_HOST}/curation/playlist/`;

type Props = {
  curationContentPack: CurationContentPackQuery['curationContentPack'];
};

export default function SP2Info({ curationContentPack }: Props) {
  const { sp2ExportId, sp2LastExportDate, sp2LastExportedVersion } = curationContentPack;

  const {
    loading: dataLoading,
    data,
    refetch,
  } = useQuery<Sp2CurationPlaylistQuery>(queries.SP2_CURATION_PLAYLIST, {
    variables: { id: sp2ExportId },
    skip: !sp2ExportId,
  });

  const [exportToSp2, { loading }] = useMutation<
    ExportCurationContentPackToSp2Mutation,
    ExportCurationContentPackToSp2MutationVariables
  >(mutations.EXPORT_CCP_TO_SP2);
  const [updateSp2Price] = useMutation<
    UpdateCurationContentPackPriceToSp2Mutation,
    UpdateCurationContentPackPriceToSp2MutationVariables
  >(mutations.UPDATE_CURATION_CONTENT_PACK_SP2_PRICE);
  const [commitInSp2, { loading: commitLoading }] = useMutation<
    CommitSp2CurationPlaylistMutation,
    CommitSp2CurationPlaylistMutationVariables
  >(mutations.COMMIT_CPP_IN_SP2);

  const [useCommitV2, setUseCommitV2] = useState(true);

  useEffect(() => {
    if (sp2ExportId && data && (!data.sp2CurationPlaylist || !!data.sp2CurationPlaylist.isCommitInProgress)) {
      // we try to refetch it every 30s
      const timer = setInterval(() => {
        refetch();
      }, 30000);
      return () => clearInterval(timer);
    }
    return () => {};
  }, [sp2ExportId, data, refetch]);

  const handleExportToSp2 = () => {
    exportToSp2({ variables: { id: curationContentPack.id } });
  };

  const handleCommitInSp2 = () => {
    let variablesInput = { sp2CurationPlaylistId: sp2ExportId };
    variablesInput = { ...variablesInput, ...{ useV2: useCommitV2 } };
    // @ts-ignore
    commitInSp2({ variables: { input: variablesInput } });
  };

  const handleUpdatePrice = (newPrice: string) => {
    const numNewPrice = _.toNumber(newPrice);
    if (Number.isNaN(numNewPrice)) {
      return Promise.reject(new Error('Not a number'));
    }
    // @ts-ignore
    updateSp2Price({ variables: { input: { sp2CurationPlaylistId: sp2ExportId, price: numNewPrice } } });
    return Promise.resolve();
  };

  return (
    <Card className="mt-2">
      <Card.Body>
        <div className="mb-2">
          SP2 Export:{' '}
          {sp2ExportId ? (
            <a href={`${SP2_PLAYLIST_URL}?plid=${sp2ExportId}`} target="_blank" rel="noopener noreferrer">
              {sp2ExportId}
            </a>
          ) : sp2LastExportDate ? (
            'This version has not been exported to SP2 yet'
          ) : (
            'Not exported to SP2 yet'
          )}
          {sp2LastExportDate && (
            <>
              <br />
              Last SP2 Export: {new Date(sp2LastExportDate).toLocaleString('en-US')}{' '}
              <Badge pill variant="info">
                Version: {sp2LastExportedVersion}
              </Badge>
              <br />
            </>
          )}
          <div>
            <TooltipButton
              onClick={handleExportToSp2}
              disabled={
                loading ||
                ![CurationContentPackState.COMMITTED, CurationContentPackState.VALIDATING].includes(
                  // @ts-ignore
                  curationContentPack.state
                )
              }
              disabledReason={loading ? null : 'Playlist is not in state VALIDATING OR COMMITTED'}
              className="mt-2"
            >
              {loading
                ? sp2ExportId
                  ? 'Syncing...'
                  : 'Exporting...'
                : sp2ExportId
                ? 'Sync with SP2'
                : 'Export to SP2'}
            </TooltipButton>
          </div>
          {dataLoading && (
            <div className="mt-2">
              <Spinner animation="border" />
            </div>
          )}
          {!!data && !!data.sp2CurationPlaylist && (
            <div>
              <hr />
              <Media>
                <SP2PlaylistPicture
                  artwork={data.sp2CurationPlaylist.artwork || null}
                  sp2CurationPlaylistId={data.sp2CurationPlaylist.id}
                />
                <Media.Body className="ml-4">
                  Price:{' '}
                  <EditComponent
                    type="text"
                    inline
                    onSubmit={handleUpdatePrice}
                    value={data.sp2CurationPlaylist.price}
                  />
                  <br />
                  <SP2BadgeFields
                    sp2CurationPlaylistId={data.sp2CurationPlaylist.id}
                    badges={data.sp2CurationPlaylist.badges || null}
                    availableBadges={data.sp2CurationPlaylist.availableBadges}
                    disabled={!!data.sp2CurationPlaylist.masteryRaceStarted}
                  />
                </Media.Body>
              </Media>
              <hr />
              <p>
                Production Playlist Id: {data.sp2CurationPlaylist.productionPlaylistId}
                <br />
                Page Url:{' '}
                <a className="small" href={data.sp2CurationPlaylist.pageUrl ?? undefined}>
                  {data.sp2CurationPlaylist.pageUrl}
                </a>
                <br />
                Created:{' '}
                {data.sp2CurationPlaylist.created
                  ? new Date(data.sp2CurationPlaylist.created * 1000).toLocaleString('en-US')
                  : 'Never created in SP2'}
                <br />
                Last SP2 Commit:{' '}
                {data.sp2CurationPlaylist.lastCommitted
                  ? new Date(data.sp2CurationPlaylist.lastCommitted * 1000).toLocaleString('en-US')
                  : 'Never committed to SP2'}
              </p>
              <hr />
            </div>
          )}
        </div>
        {!!data && !!data.sp2CurationPlaylist && (
          <Button disabled={commitLoading || !!data.sp2CurationPlaylist.isCommitInProgress} onClick={handleCommitInSp2}>
            {commitLoading || !!data.sp2CurationPlaylist.isCommitInProgress ? 'Committing in SP2' : 'Commit in SP2'}
          </Button>
        )}
        {!!data && !!data.sp2CurationPlaylist && (
          <Form.Check
            type="switch"
            id="use-v2-checkbox"
            label="Use v2"
            className="mt-1 mb-3"
            checked={useCommitV2}
            onChange={(e) => setUseCommitV2(e.target.checked)}
          />
        )}
      </Card.Body>
    </Card>
  );
}
