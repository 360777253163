import { upperCase } from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { Accordion, Button, Card } from 'react-bootstrap';
import { ImportantEvent } from '../../../../../../__gqltypes__';
import { eventColors } from '../utils';
import ImportantEventForm from './ImportantEventForm';

interface ImportantEventCardProps {
  event: ImportantEvent;
  setEventToDelete?: (event: ImportantEvent | null) => void;
  displayUpdateButton?: boolean;
  fontSize?: 'medium' | 'small';
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
}

export default function ImportantEventCard({
  event,
  setEventToDelete,
  displayUpdateButton,
  fontSize = 'medium',
  open,
  onOpen,
  onClose,
}: ImportantEventCardProps) {
  const handleToggle = () => {
    if (open) onClose();
    else onOpen();
  };

  return (
    <Card className="mb-2">
      <Card.Body className="py-2" style={{ borderRadius: '4px' }}>
        <Accordion>
          <Accordion.Toggle as={Card.Title} variant="link" eventKey="0" onClick={handleToggle}>
            <div style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}>
              <b
                className="my-auto flex-grow-0"
                style={{
                  color: `${eventColors[event.type]}${event.important ? 'ff' : 'aa'}`,
                  width: '14%',
                  minWidth: '100px',
                  fontSize,
                }}
              >
                {event.important ? <i className="fas fa-star mr-2" /> : <i className="far fa-star mr-2" />}
                {upperCase(event.type)}
              </b>
              <span className="my-auto color" style={{ flexGrow: 2, fontSize }}>
                {event.description}
              </span>
              <span className=" my-auto  flex-grow-0" style={{ width: '15%', minWidth: '80px', fontSize }}>
                {moment(event.datetime).format('YYYY-MM-DD HH:mm')}
              </span>

              <Button variant="link  flex-grow-0" className="my-auto">
                {!open && <i className="fas fa-chevron-down" />}
                {open && <i className="fas fa-chevron-up" />}
              </Button>

              {displayUpdateButton && (
                <ImportantEventForm
                  OpenDialogButton={({ onClick }) => (
                    <Button
                      variant="link"
                      onClick={(e) => {
                        e.stopPropagation();
                        onClick();
                      }}
                    >
                      <i className="fas fa-edit" />
                    </Button>
                  )}
                  initialValues={event}
                  eventKey={event.eventKey}
                />
              )}

              {setEventToDelete && (
                <Button variant="danger" color="danger" onClick={() => setEventToDelete(event)}>
                  <i className="fas fa-trash" />
                </Button>
              )}
            </div>
          </Accordion.Toggle>

          {open && (
            <div>
              <>{event.notes || 'No notes'}</>
            </div>
          )}
        </Accordion>
      </Card.Body>
    </Card>
  );
}
