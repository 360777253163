import React from 'react';
import DashboardContainer from '../../../../../../utils/dashboards/components/DashboardContainer';
import { DefaultSlicing } from '../../../../../../utils/dashboards/components/slicers';
import LineChart from '../../../../../../utils/dashboards/components/widgets/charts/LineChart';
// import PieChart from '../../../../../../utils/dashboards/components/widgets/charts/PieChart';
// import BarChart from '../../../../../../utils/dashboards/components/widgets/charts/BarChart';
// import BaseTable from '../../../../../../utils/dashboards/components/widgets/tables/BaseTable';
import WidgetContainer from '../../../../../../utils/dashboards/components/widgets/WidgetContainer';
import Processing from '../../../../../../utils/dashboards/models/Processing';

const queries = {
  dauPerContinent: { fillZeroes: true },
  dauPerTop10CountryEurope: { fillZeroes: true },
  dauPerTop10CountryAmericas: { fillZeroes: true },
  dauPerAge: { fillZeroes: true },
  installsPerTop20Country: { fillZeroes: true },
  installsPerAge: { fillZeroes: true },
};

const Demographics = () => (
  <DashboardContainer queries={queries} id="demographics" title="Demographics">
    <DefaultSlicing id="DefaultSlicing" />
    <WidgetContainer id="WidgetContainer">
      <LineChart
        id="dauPerContinent"
        title="DAU per Continent"
        processing={Processing.pivot('dauPerContinent', 'continent', 'nb_dau', 'date')}
      />
      <LineChart
        id="dauPerTop10CountryEurope"
        title="DAU per top 10 countries in Europe"
        processing={Processing.pivot('dauPerTop10CountryEurope', 'country', 'nb_dau', 'date')}
      />
      <LineChart
        id="dauPerTop10CountryAmericas"
        title="DAU per top 10 countries in Americas"
        processing={Processing.pivot('dauPerTop10CountryAmericas', 'country', 'nb_dau', 'date')}
      />
      <LineChart
        id="dauPerAge"
        title="DAU per Age range"
        processing={Processing.pivot('dauPerAge', 'age_range', 'nb_dau', 'date')}
      />
      <LineChart
        id="installsPerTop20Country"
        title="Installs per top 20 countries"
        processing={Processing.pivot('installsPerTop20Country', 'country', 'nb_installs', 'date')}
      />
      <LineChart
        id="installsPerAge"
        title="Installs per Age range"
        processing={Processing.pivot('installsPerAge', 'age_range', 'nb_installs', 'date')}
      />
    </WidgetContainer>
  </DashboardContainer>
);

export default Demographics;
