import React from 'react';
import { useQuery } from '@apollo/client';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { GET_RUNTIME_LOGS } from '../../graphql';

import LogsTable from './LogsTable';
import { InfoDisplay } from './components/InfoDisplay';
import { GraphHistory } from './components/GraphHistory';
import { LoadingLogo } from '../../components/Modal';
import { groupApolloEvents } from './models';

// eslint-disable-next-line react-hooks/rules-of-hooks
const urlQuery = () => new URLSearchParams(useLocation().search);

export const RuntimeView = () => {
  const match = useRouteMatch();
  const query = urlQuery();
  const startDate = query.get('t');
  const runtimeID = match.params.id;
  const { data, fetchMore, loading } = useQuery(GET_RUNTIME_LOGS, {
    variables: {
      runtimeID,
      offset: 0,
      limit: 100,
      startDate: parseInt(startDate, 10),
    },
    notifyOnNetworkStatusChange: true,
  });
  const handleLoadMore = () => {
    fetchMore({
      variables: {
        offset: data.analytics.backendLogsByRuntime.data.length,
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          analytics: {
            ...prev.analytics,
            backendLogsByRuntime: {
              ...prev.analytics.backendLogsByRuntime,
              data: [
                ...prev.analytics.backendLogsByRuntime.data,
                ...fetchMoreResult.analytics.backendLogsByRuntime.data,
              ],
            },
          },
        };
      },
    });
  };
  let processedLogs = [];
  if (data && data.analytics.backendLogsByRuntime) {
    if (data.analytics.backendLogsByRuntime.data.length > 0) {
      processedLogs = data.analytics.backendLogsByRuntime.data.map((log) => JSON.parse(log));
    } else {
      console.log('No logs found for this input');
    }
  }

  const infosData = [];
  if (processedLogs && processedLogs.length > 0) {
    const runtimeInfos = processedLogs.find((log) => log.event_name === 'new_runtime');
    if (runtimeInfos && runtimeInfos.event_params) {
      infosData.push({ title: 'Runtime', params: runtimeInfos.event_params, color: 'primary' });
    }
  }
  return (
    <>
      <LoadingLogo show={loading} />
      <Row>
        <Col sm="8">
          {processedLogs ? (
            <>
              <GraphHistory logs={processedLogs} />
              <LogsTable processedLogs={groupApolloEvents(processedLogs)} />
              <Button onClick={handleLoadMore}>Load More</Button>
            </>
          ) : (
            <p>No logs found</p>
          )}
        </Col>
        <InfoDisplay data={infosData} />
      </Row>
    </>
  );
};
