import React, { SyntheticEvent, useRef, useState } from 'react';
import { Col, InputGroup, Row } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { CurationContentItemQuery, UpdateCurationSongSampleInput } from '../../../../__gqltypes__';
import SongSearchContent from '../screens/SongSearch/components/SongSearchContent';
import './UploadSong.css';

export type SubmitPayload = { file: GQL_Upload } | { url: string } | UpdateCurationSongSampleInput;
export type SongSubmitFn = (value: SubmitPayload) => Promise<unknown>;
export type CurationSong = Extract<CurationContentItemQuery['curationContentItem'], { __typename: 'CurationSong' }>;

type Props = {
  className?: string;
  onSubmit: SongSubmitFn;
  title: string;
  song?: CurationSong;
};

type Categories = 'song-file' | 'song-url' | 'song-ddex';
const currentOffset = 0;

export default function UploadSong({ className, onSubmit, title, song }: Props) {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [urlDefaultValue, setUrlDefaultValue] = useState<string | null>(null);
  const [offsetM, setOffsetM] = useState(currentOffset.toFixed(0));
  const [offsetS, setOffsetS] = useState(currentOffset.toFixed(1));
  const [isSeeking, setIsSeeking] = useState(false);
  const [category, setCategory] = useState<Categories>('song-file');

  const audioRef = useRef<HTMLAudioElement | null>(null);

  const onTimeUpdate = (event: SyntheticEvent<HTMLAudioElement, Event>) => {
    const audioElement = event.target as HTMLAudioElement;
    if (isSeeking && category === 'song-ddex') {
      const totalSeconds = audioElement.currentTime;
      const mins = Math.floor(totalSeconds / 60);
      const seconds = totalSeconds - mins * 60;
      setOffsetM(mins.toFixed(0));
      setOffsetS(seconds.toFixed(1));
    }
  };
  const handleSeeking = () => {
    setIsSeeking(true);
  };

  const handleSeeked = () => {
    setIsSeeking(false);
  };

  const handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCategory(event.target.value as Categories);
  };

  const handleOffsetChangeS = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newTime = parseFloat(event.target.value);
    if (Number.isNaN(newTime)) {
      newTime = 0;
    }
    setOffsetS(newTime.toFixed(1));
    if (audioRef.current) {
      audioRef.current.currentTime = parseFloat(offsetM) * 60 + newTime;
    }
  };

  const handleOffsetChangeM = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newTime = parseFloat(event.target.value);
    if (Number.isNaN(newTime)) {
      newTime = 0;
    }
    console.log('newTime', newTime, event.target.value);
    setOffsetM(newTime.toFixed(0));
    if (audioRef.current) {
      audioRef.current.currentTime = newTime * 60 + parseFloat(offsetS);
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (!event) return;
    console.log('event', { eventTarget: event.target });
    event.preventDefault();

    const { files } = event.currentTarget.elements.namedItem('song-file') as HTMLInputElement;
    const { value: url } = event.currentTarget.elements.namedItem('song-url') as HTMLInputElement;
    const startingTime = parseFloat(offsetM) * 60 + parseFloat(offsetS) ?? -1;

    let payload;
    if (category === 'song-file' && files && files.length > 0) {
      payload = { file: files[0] };
    } else if (category === 'song-url' && url) {
      payload = { url };
    } else if (category === 'song-ddex' && startingTime >= 0 && song?.ddexFullSample) {
      payload = {
        url: song.ddexFullSample,
        startingTime,
      };
    }

    if (!payload) {
      alert('Provide either a file or a URL');
      return;
    }
    setLoading(true);
    onSubmit(payload)
      .then(() => {
        setLoading(false);
        setError(null);
        setShow(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message);
      });
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement> | React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      event.stopPropagation();
    }
  };

  return (
    <>
      <span className={`upload-song ${className}`}>
        <Button size="sm" as="label" onClick={() => setShow(true)}>
          <i className="fas fa-wrench mr-2" />
          {title}
        </Button>
      </span>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Check
                type="radio"
                label="Upload a file..."
                value="song-file"
                checked={category === 'song-file'}
                onChange={handleCategoryChange}
              />
              <Form.Group controlId="song-file">
                {/* <Form.Label>Upload a file...</Form.Label> */}
                <Form.Control type="file" accept="audio/*" disabled={loading || category !== 'song-file'} />
              </Form.Group>
              <hr />
              <Form.Check
                type="radio"
                label="... or provide a sample URL"
                value="song-url"
                checked={category === 'song-url'}
                onChange={handleCategoryChange}
              />
              <Form.Group controlId="song-url">
                <Form.Control
                  type="text"
                  disabled={loading || category !== 'song-url'}
                  defaultValue={urlDefaultValue ?? undefined}
                />
              </Form.Group>
              <hr />
              {song?.ddexFullSample && (
                <>
                  <Form.Check
                    type="radio"
                    name="song-ddex"
                    label="... or create a custom sample from the full ddex song"
                    value="song-ddex"
                    checked={category === 'song-ddex'}
                    onChange={handleCategoryChange}
                  />
                  <Form.Group controlId="song-ddex">
                    <Row>
                      <Col className="d-flex">
                        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                        <audio
                          ref={audioRef}
                          controls
                          style={{ width: '100%', marginTop: '20px' }}
                          onTimeUpdate={onTimeUpdate}
                          onSeeking={handleSeeking}
                          onSeeked={handleSeeked}
                        >
                          <source src={song?.ddexFullSample} type="audio/aac" />
                          Your browser does not support the audio element.
                        </audio>
                      </Col>
                    </Row>
                    <Form.Group className="mt-3 px-4">
                      <Form.Label>Offset</Form.Label>
                      <Form.Group as={Row}>
                        <Col>
                          <InputGroup className="mb-3">
                            <Form.Control
                              disabled={category !== 'song-ddex'}
                              type="number"
                              value={offsetM}
                              onChange={handleOffsetChangeM}
                              step={1}
                              onKeyDown={handleKeyDown}
                            />
                            <InputGroup.Text id="basic-addon2">m</InputGroup.Text>
                            <Form.Control
                              disabled={category !== 'song-ddex'}
                              type="number"
                              value={offsetS}
                              onChange={handleOffsetChangeS}
                              step={0.1}
                              onKeyDown={handleKeyDown}
                            />
                            <InputGroup.Text id="basic-addon2">s</InputGroup.Text>
                          </InputGroup>
                        </Col>
                      </Form.Group>
                    </Form.Group>
                  </Form.Group>
                  <hr />
                </>
              )}
            </Form.Group>
            <Button type="submit" disabled={loading}>
              {loading ? 'Submitting...' : 'Submit'}
            </Button>
          </Form>

          {error && (
            <Alert variant="danger" className="mt-3">
              {error}
            </Alert>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
