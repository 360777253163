import moment from 'moment';
import { useMemo } from 'react';
import colors from '../../../models/widgets/colors.json';
import { widgetDefaultProps, widgetPropTypes } from '../widgetPropTypes';
import BaseChart from './BaseChart';
import { EVENTS } from './releases';
import { average, rollingAverage, trendline } from './utils';

const lastYear = moment().add('-1', 'year');

// display vertical line on the event date
const releaseAnnotations = Object.keys(EVENTS)
  .filter((v) => moment(v).isAfter(lastYear))
  .map((v) => ({
    type: 'line',
    mode: 'vertical',
    scaleID: 'x-axis-0',
    value: moment(v).format('MMM D'),
    borderWidth: 2,
    borderColor: 'rgba(102, 102, 102, 0.5)',
    borderDash: [5],
  }));

const averageAnnotationTemplate = {
  type: 'line',
  mode: 'horizontal',
  scaleID: 'y-axis-0',
  value: 0,
  borderWidth: 4,
  borderDash: [5],
  label: {
    enabled: true,
    content: 'Average',
    position: 'right',
  },
};

// display event information on the tooltip
const footer = (tooltipItems) => {
  if (tooltipItems.length === 0) return null;
  const itemLabel = tooltipItems[0].label;
  if (EVENTS[itemLabel]) {
    return EVENTS[itemLabel].join('\n');
  }
  return null;
};

// round label to 3 decimals
const labelFn = (tooltipItem, data) => {
  let label = data.datasets[tooltipItem.datasetIndex].label ?? '';

  if (label) {
    label += ': ';
  }
  label += Math.round(tooltipItem.yLabel * 1000) / 1000;
  return label;
};

const percentageScale = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          callback: function cb(value) {
            let strValue = `${value * 100}`;
            if (strValue.length > 5 && value > 0.01) {
              strValue = (value * 100).toFixed(1);
            }
            return `${strValue}%`;
          },
        },
      },
    ],
  },
};

const LineChart = ({
  id,
  height,
  width,
  data,
  options,
  title,
  displayAverage,
  displayAverageRelease,
  displayRollingAverage,
  displayTrendline,
  percentage,
}) => {
  const [datasets, displayedDatasets] = useMemo(() => {
    const keys = Object.keys(data.data);
    if (!keys.length) {
      return [[], []];
    }
    const _datasets = keys
      .filter((label) => label !== data.indexColLabel && label !== '0')
      .map((label, index) => {
        return {
          data: data.data[label],
          label,
          borderColor:
            displayAverage || displayTrendline || displayAverageRelease ? `${colors[index]}50` : colors[index],
          fill: false,
        };
      });

    let displayed = [..._datasets];
    // replace current dataset
    if (displayRollingAverage) {
      displayed = displayed.map((d) => ({ ...d, data: rollingAverage(d) }));
    }

    if (displayAverageRelease) {
      // same as before, but segmented per release
      const labels = data.getLabelColumn(); // YYYY-MM-DD
      const eventDates = Object.keys(EVENTS); // MMD D YYYY
      const lastReleaseDate = eventDates[eventDates.length - 1];
      const last2ReleaseDate = eventDates[eventDates.length - 2];
      const lastFormattedReleaseDate = moment(lastReleaseDate).format('MMM D');
      const last2FormattedReleaseDate = moment(last2ReleaseDate).format('MMM D');

      // 1. figure out segments (create 2 -> current release and previous release)
      let startSegment1 = 0;
      let endSegment1 = -1;
      for (let i = 0; i < labels.length; i++) {
        const labelDate = moment(labels[i]).format('MMM D');
        if (labelDate === last2FormattedReleaseDate) {
          startSegment1 = i;
        }
        if (labelDate === lastFormattedReleaseDate) {
          endSegment1 = i;
        }
      }

      const segments = [
        [startSegment1, endSegment1, 'Before'],
        [endSegment1, labels.length - 1, 'After'],
      ];

      for (const d of _datasets) {
        // 3. calculate average per segments
        for (const segment of segments) {
          const averageSegmentDataset = {
            ...d,
            label: `${d.label} (Avg ${segment[2]})`,
            borderColor: d.borderColor.substring(0, d.borderColor.length - 2),
          };
          const averageValue = average(d, segment);
          averageSegmentDataset.data = [];
          for (let i = 0; i < d.data.length; i++) {
            if (segment[0] <= i && i <= segment[1]) {
              averageSegmentDataset.data.push(averageValue);
            } else {
              averageSegmentDataset.data.push(null);
            }
          }
          displayed.push(averageSegmentDataset);
        }
      }
    }

    // add to current dataset
    if (displayTrendline) {
      displayed = [
        ...displayed,
        ...displayed.map((d) => ({
          ...d,
          data: trendline(d),
          label: `${d.label} (Trend)`,
          borderColor: d.borderColor.substring(0, d.borderColor.length - 2),
        })),
      ];
    }

    return [_datasets, displayed];
  }, [data.data, displayAverage, displayRollingAverage, displayTrendline, displayAverageRelease]);

  const annotations = useMemo(() => {
    if (!displayAverage) {
      return releaseAnnotations;
    }
    const res = [...releaseAnnotations];
    if (displayAverage) {
      for (const d of datasets) {
        const newAnnotation = { ...averageAnnotationTemplate };
        const averageValue = average(d);
        newAnnotation.value = averageValue;
        newAnnotation.borderColor = d.borderColor.substring(0, d.borderColor.length - 2);
        newAnnotation.label = { ...newAnnotation.label, content: `Average: ${averageValue.toFixed(2)}` };
        res.push(newAnnotation);
      }
    }

    return res;
  }, [datasets, displayAverage]);

  const percentageOptions = percentage ? percentageScale : {};

  return (
    <BaseChart
      id={id}
      type="line"
      height={height}
      width={width}
      options={{
        tooltips: {
          mode: 'index',
          enabled: true,
          intersect: false,
          callbacks: {
            footer,
            label: labelFn,
          },
        },
        ...options,
        ...percentageOptions,
      }}
      labels={data.getLabelColumn()}
      datasets={displayedDatasets}
      title={title}
      annotation={{ annotations }}
    />
  );
};

LineChart.propTypes = widgetPropTypes;
LineChart.defaultProps = widgetDefaultProps;

export default LineChart;
