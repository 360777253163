import { useQuery } from '@apollo/client';
import moment from 'moment';
import * as React from 'react';
import { Button, Col, Form, Modal, ModalProps, Row } from 'react-bootstrap';
import DateTimePicker from 'react-datetime';

import { applyChangeIfDateValid, validIfSameDayOrAfter } from '../../../../../../utils/datepicker';
import { GET_CONTENT_CATEGORIES, GET_LIVE_SHOW_PRESETS } from '../../../components/apolloQueries';

import type {
  ContentCategoriesQuery,
  QuizLiveShowPresetsQuery,
  UpsertQuizLiveShowInput,
} from '../../../../../../__gqltypes__';

type Props = {
  loading: boolean;
  onAdd: (liveShowData: UpsertQuizLiveShowInput) => void;
} & ModalProps;

const IS_TEST = process.env.REACT_APP_NAMESPACE === 'test';

const AddLiveShowModal = ({ show, onHide, onAdd, loading }: Props) => {
  const { data } = useQuery<ContentCategoriesQuery>(GET_CONTENT_CATEGORIES);
  const { data: presets } = useQuery<QuizLiveShowPresetsQuery>(GET_LIVE_SHOW_PRESETS);
  const { contentCategories } = data ? data.app : { contentCategories: [] };
  const [category, setCategory] = React.useState<string | void>();
  const [startDate, setStartDate] = React.useState<Date>(
    moment()
      .startOf('minute')
      .add(IS_TEST ? 2 : 8, 'minutes')
      .toDate()
  );
  const [diamondPrize, setDiamondPrize] = React.useState(500);
  const [preset, setPreset] = React.useState<string>('default');
  const presetList = presets?.liveOps?.quizLiveShowPresets ?? [];

  React.useEffect(() => {
    if (data?.app?.contentCategories) {
      setCategory(data.app.contentCategories[0].id);
    }
  }, [data]);

  // @ts-ignore
  const handleChangeCategory = (event) => {
    setCategory(event.target.value);
  };

  // @ts-ignore
  const handleChangeDiamondPrize = (event) => {
    setDiamondPrize(Math.max(1, +event.target.value));
  };

  const sanitizeDate = () => setStartDate((rawDate) => moment(rawDate).startOf('minute').toDate());

  const handleSubmit = () => {
    sanitizeDate();
    const delay = IS_TEST ? 1 : 6;
    if (moment(startDate).isBefore(moment().add(delay, 'minutes'))) {
      alert('Invalid start date: the show date is too soon to be launched on time');
      return;
    }
    if (category) {
      onAdd({
        category,
        // @ts-ignore
        start: startDate,
        diamondPrize,
        preset,
      });
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Body>
        <Row>
          <Col xs={3}>Category</Col>
          <Col xs={6}>
            <Form.Control
              name="category"
              required
              as="select"
              value={category ?? undefined}
              onChange={handleChangeCategory}
            >
              {contentCategories.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Row>
        <Row>
          <Col xs={3}>Start date</Col>
          <Col xs={6}>
            <DateTimePicker
              onChange={applyChangeIfDateValid(setStartDate)}
              isValidDate={validIfSameDayOrAfter}
              initialValue={startDate}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={3}>Diamond prize</Col>
          <Col xs={6}>
            <Form.Control
              name="diamondPrize"
              required
              type="number"
              value={diamondPrize}
              onChange={handleChangeDiamondPrize}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={3}>Preset</Col>
          <Col xs={9}>
            <Form.Group>
              <Form.Control as="select" value={preset} onChange={(e) => setPreset(e.target.value)}>
                {presetList.map((_preset) => (
                  <option value={_preset.id} key={_preset.id}>
                    {`${_preset.id} (${_preset.description})`}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-3 pl-1">
          <Button disabled={loading} onClick={handleSubmit}>
            Add
          </Button>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default AddLiveShowModal;
