export function getURIs() {
  const isProd = !process.env.NODE_ENV || process.env.NODE_ENV === 'production';
  let isSecure = window.location.protocol === 'https:';
  let dnsName = 'localhost:8085';

  if (isProd || process.env.REACT_APP_NAMESPACE === 'test' || process.env.REACT_APP_NAMESPACE === 'beta') {
    isSecure = true;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dnsName = DNS_MAP[process.env.REACT_APP_NAMESPACE] || dnsName;
  }

  const httpProtocol = isSecure ? 'https' : 'http';
  const wsProtocol = isSecure ? 'wss' : 'ws';

  const uri = `${httpProtocol}://${dnsName}/graphql`;
  const wsUri = `${wsProtocol}://${dnsName}/graphql`;

  console.log('namespace', process.env.REACT_APP_NAMESPACE, isSecure, uri, process.env.REACT_APP_COMPILE_TIMESTAMP);

  return { uri, wsUri };
}

export const DNS_MAP = {
  test: 'admin.test.songpop3.com',
  beta: 'admin.beta.songpop3.com',
  prod: 'admin.songpop3.com',
};

export const firebaseConfig = {
  apiKey: 'AIzaSyC8-m3mbsRx6B_TazvA8Sft6nBqHRy0_vE',
  authDomain: 'pop-admin-1c1bd.firebaseapp.com',
  databaseURL: 'https://pop-admin-1c1bd.firebaseio.com',
  projectId: 'pop-admin-1c1bd',
  storageBucket: 'pop-admin-1c1bd.appspot.com',
  messagingSenderId: '104006631339',
  appId: '1:104006631339:web:36d454d4610b4ddc239cee',
};

export const ROLE = Object.freeze({
  ADMIN: 'ADMIN',
  BANNED: 'BANNED',
  CONTENT_MODERATOR: 'CONTENT_MODERATOR',
  CURATOR: 'CURATOR',
  USER: 'USER',
  DASHBOARD: 'DASHBOARD',
  DEVELOPER: 'DEVELOPER',
});
