import { gql, useLazyQuery, useQuery } from '@apollo/client';
import _ from 'lodash';
import { useCallback, useState } from 'react';
import { GetImportantEventsQuery, GetImportantEventsQueryVariables, MetricOrderBy, MetricTimestamp, MetricType, ServerEnv } from '../../../../../../__gqltypes__';
import { GET_IMPORTANT_EVENTS_BETWEEN } from '../../ImportantEvents/graphql';
import { eventTypeLabels } from '../../ImportantEvents/utils';
import { FULL_MONITORING_PREVIOUS, MONITORING_PREVIOUS, MONITORING_SPECIFIC_REQUESTS_PREVIOUS } from '../graphql';
import { QueriesPair } from '../logic/buildRequest';
import { FullMonitoringVariables, MonitoringVariables, PossibleFullQuery, PossibleQuery } from '../logic/utils';

function extractRecords(data: PossibleQuery | undefined) {
  if (!data) return null;
  const { monitoring } = data;
  return {
    title: monitoring.title,
    bytesProcessed: monitoring.bytesProcessed,
    timestamp: monitoring.timestamp,
    start: monitoring.start,
    end: monitoring.end,
    datasets: Object.entries(_.groupBy(monitoring.records, 'name')).map(([name, records]) => ({
      label: name,
      data: records.map((record) => ({ ...record, datetime: new Date(record.datetime as string) })),
    })),
  };
}

function formatEvents(data: GetImportantEventsQuery | undefined) {
  return (data?.getImportantEvents || []).map((event) => ({
    label: eventTypeLabels[event.type].label,
    datetime: new Date(event.datetime),
    description: event.description,
    important: event.important,
    notes: event.notes,
    type: event.type,
    __typename: event.__typename,
    eventKey: event.eventKey,
  }));
}

export default function useInteractiveMonitoringQuery() {
  const [startRequest, setStartRequest] = useState<number>(Date.now());
  const [computedIn, setComputedIn] = useState<number>(0);
  const [env, setEnv] = useState<ServerEnv | null>(null);
  const [request, setRequest] = useState<{
    query: QueriesPair;
    variables: MonitoringVariables;
    comparisonQuery?: QueriesPair;
    getComparisonVariables?: (requestNames: string[]) => MonitoringVariables;
  }>({
    query: { full: FULL_MONITORING_PREVIOUS, essentials: MONITORING_PREVIOUS },
    variables: {
      input: {
        timestamp: MetricTimestamp.HOURLY,
        type: MetricType.GQLREQUEST,
        maxNumberOfTimestamps: 72,
        maxNumberOfRequests: 10,
        orderQuery: MetricOrderBy.WORST_CUMULATED_TIME_SORT,
      },
    },
  });

  const [fetchImportantEvents, { data: eventsData }] = useLazyQuery<
    GetImportantEventsQuery,
    GetImportantEventsQueryVariables
  >(GET_IMPORTANT_EVENTS_BETWEEN, {
    onCompleted: () => {
      setComputedIn(Date.now() - startRequest);
    },
  });

  const [getComparisonSerie, { data: previousSerie, loading: previousSerieLoading }] = useLazyQuery<
    PossibleQuery,
    MonitoringVariables
  >(request.comparisonQuery?.essentials || MONITORING_SPECIFIC_REQUESTS_PREVIOUS, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      setComputedIn(Date.now() - startRequest);
    },
  });

  const [fetchFullSerie, { loading: fullSerieLoading }] = useLazyQuery<PossibleFullQuery, FullMonitoringVariables>(
    request.comparisonQuery?.full || FULL_MONITORING_PREVIOUS,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const { data, loading: serieLoading } = useQuery<PossibleQuery, MonitoringVariables>(
    request.query.essentials || gql``,
    {
      variables: request.variables,
      fetchPolicy: 'no-cache',
      onCompleted: ({ monitoring }) => {
        setComputedIn(Date.now() - startRequest);
        const requestNames = _.uniq(_.map(monitoring.records, 'name'));
        if (request.getComparisonVariables && request.comparisonQuery) {
          getComparisonSerie({ variables: request.getComparisonVariables(requestNames) });
        }
        fetchImportantEvents({
          variables: { input: { start: new Date(monitoring.start), end: new Date(monitoring.end), namespace: env } },
        });
      },
    }
  );

  const getFullSerie = async () => {
    const fullSerie = await fetchFullSerie({ variables: request.variables });
    return fullSerie.data?.monitoring;
  };

  const refetch = useCallback(
    (
      newQuery: QueriesPair,
      newVariables: MonitoringVariables,
      newComparisonQuery?: QueriesPair,
      newGetComparisonVariables?: (requestNames: string[]) => MonitoringVariables
    ) => {
      console.log('refetch', newQuery, newVariables);
      setStartRequest(Date.now());
      setEnv(newVariables.input.env ?? null);
      setRequest({
        query: newQuery,
        variables: newVariables,
        comparisonQuery: newComparisonQuery,
        getComparisonVariables: newGetComparisonVariables,
      });
    },
    []
  );

  return {
    serie: extractRecords(data) || null,
    previousSerie: extractRecords(previousSerie) || null,
    importantEvents: formatEvents(eventsData),
    refetch,
    computedIn,
    serieLoading,
    previousSerieLoading: !!request.comparisonQuery && (serieLoading || previousSerieLoading),

    getFullSerie,
    fullSerieLoading,
  };
}
